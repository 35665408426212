import get from 'lodash/get'
import map from 'lodash/map'

export const dealsAnswers = (questions, values, questionsWithOptions) =>
  map(questions, (q) => ({
    questionId: q.questionId,
    shortName: q.shortName,
    questionAnswerIds: questionsWithOptions.has(q.questionTypeId)
      ? get(values, q.shortName, [])
      : [],
    responseText: !questionsWithOptions.has(q.questionTypeId)
      ? values[q.shortName]
      : null,
  }))

export const halfCols = (question) =>
  question.shortName === 'PublishDate' ||
  question.shortName === 'EndDate' ||
  question.shortName === 'InvestmentType' ||
  question.shortName === 'Vehicle' ||
  question.shortName === 'Sector' ||
  question.shortName === 'Geography' ||
  question.shortName === 'Vertical' ||
  question.shortName === 'MinimumInvestmentSize' ||
  question.shortName === 'OfferSize' ||
  question.shortName === 'CommittedCapital' ||
  question.shortName === 'TargetIrr' ||
  question.shortName === 'Moic' ||
  question.shortName === 'InterestRate' ||
  question.shortName === 'DevidedReturn'

export const dividerCols = (question) =>
  question.shortName === 'Vertical' || question.shortName === 'CommittedCapital'
