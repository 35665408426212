import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import classNames from 'classnames'
import { isEmpty, isNil } from 'lodash'
import set from 'lodash/set'
import SimpleBar from 'simplebar-react'

const ArrayField = ({
  component,
  componentProps = {},
  initialItem = {},
  labelText = '',
  value = JSON.stringify([initialItem]),
  useModalHeight = true,
  maxItems,
  onChange,
}) => {
  if (isNil(value)) value = JSON.stringify([initialItem])

  const Component = component
  const [viewDiv, setViewDiv] = useState(false)
  const [scrollEnd, setScrollEnd] = useState(false)
  const [addButtonDisabled, setAddButtonDisabled] = useState(
    !isNil(maxItems) && JSON.parse(value).length >= maxItems
  )
  const onItemChanged = useCallback(
    (index, key, newValue) => {
      const parsedValue = JSON.parse(value)
      const itemToChange = parsedValue[index]
      set(itemToChange, key, newValue)

      onChange(JSON.stringify(parsedValue))
    },
    [onChange, value]
  )

  const onDeleteClicked = useCallback(
    (index) => {
      const parsedValue = JSON.parse(value)
      const newValue = parsedValue.filter((x, ix) => ix !== index)
      onChange(JSON.stringify(newValue))
    },
    [value, onChange]
  )

  const onAddNewClicked = useCallback(() => {
    setViewDiv(true)
    const parsedValue = JSON.parse(value)
    const newValue = [...parsedValue, initialItem]
    if (!isNil(maxItems) && newValue.length >= maxItems)
      setAddButtonDisabled(true)
    onChange(JSON.stringify(newValue))
    setScrollEnd(true)
  }, [value, initialItem, onChange, maxItems])

  useEffect(() => {
    document.querySelector('#myEl') &&
      document.querySelector('#myEl').scrollIntoView()

    if (scrollEnd) {
      setViewDiv(false)
      setScrollEnd(false)
    }
  }, [scrollEnd])

  const getLabel = () => {
    if (!isNil(labelText) && !isEmpty(labelText)) {
      return (
        <div className={classNames('Field', 'ArrayField')}>
          <label className="Field-label">{labelText}</label>
        </div>
      )
    }
    return null
  }

  return (
    <div>
      <SimpleBar
        style={{
          height: useModalHeight && 'calc(100vh - 410px)',
          overflowX: 'hidden',
        }}
        autoHide={false}
        id="myElement"
      >
        {getLabel()}
        {JSON.parse(value).map((x, index) => (
          <>
            <Component
              onItemChanged={onItemChanged}
              itemIndex={index}
              key={index}
              {...componentProps}
              item={x}
            ></Component>
            {index !== 0 && (
              <div className="ArrayField-delete">
                <Button
                  type="link"
                  onClick={() => {
                    onDeleteClicked(index)
                  }}
                >
                  Delete
                </Button>
              </div>
            )}
          </>
        ))}
        {viewDiv && <div id="myEl" style={{ height: '300px' }}></div>}
      </SimpleBar>

      <div style={{ textAlign: 'center' }}>
        <Tooltip
          title={
            addButtonDisabled && `You may only add up to ${maxItems} items.`
          }
        >
          <Button
            type="link"
            onClick={onAddNewClicked}
            disabled={addButtonDisabled}
          >
            <FontAwesomeIcon className="button-icon" icon={faPlus} />
            Add new
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

ArrayField.propTypes = {
  component: PropTypes.element,
  componentProps: PropTypes.object,
  initialItem: PropTypes.object,
  labelText: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.object,
  useModalHeight: PropTypes.bool,
  maxItems: PropTypes.number,
  onChange: PropTypes.func,
}

export default ArrayField
