import { useEffect, useState } from 'react'
import { faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Col, Dropdown, Menu, Row, message } from 'antd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

/**
 * Moves an item from one list to another list.
 */
const move = (contacts, source, destination, contactSelected) => {
  const currentArrayIndex = source.split('-')[1]
  const destinationArrayIndex = destination.split('-')[1]
  const target = contacts[currentArrayIndex].rows.find(
    (x) => x.contactId == contactSelected
  )
  const targetIndex = contacts[currentArrayIndex].rows.findIndex(
    (x) => x.contactId == contactSelected
  )

  contacts[currentArrayIndex].rows.splice(targetIndex, 1)
  contacts[destinationArrayIndex].rows.push(target)
  return contacts
}

const grid = 3

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#FFFFFF',

  // styles we need to apply on draggables
  ...draggableStyle,
  borderRadius: '5px',
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#EFF1F4',
  padding: grid,
  minWidth: 300,
  margin: '15px',
  marginLeft: '0px',
  borderRadius: '5px',
})

const ArrayDragAndDrop = ({ contacts, sendSelected, participants }) => {
  const [allContacts, setAllContacts] = useState(null)
  const [contactMenu, setContactMenu] = useState(null)

  useEffect(() => {
    setAllContacts(contacts)
  }, [contacts])

  const onDragEnd = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId !== destination.droppableId) {
      sendSelected(
        move(
          allContacts,
          source.droppableId,
          destination.droppableId,
          result.draggableId
        ),
        source.droppableId,
        destination.droppableId,
        result.draggableId
      )
    }
  }

  const showContactsAvailable = (index) => {
    const scheduleContacts = []
    allContacts.map((value) =>
      value.rows.map((row) => scheduleContacts.push(row))
    )
    const currentContacts = participants.filter(
      (x) => !scheduleContacts.some((sc) => sc.contactId == x.contactId)
    )
    if (currentContacts.length <= 0) {
      message.warning('No contacts available to add to this schedule', 3)
      return <></>
    }
    return (
      <Menu
        style={{ maxWidth: '286px' }}
        onClick={(e) => addNewUserFromList(index, parseInt(e.key))}
      >
        {currentContacts.map((item) => (
          <Menu.Item key={item.contactId}>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={3}>
                <Avatar
                  src={item.imageURL}
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    verticalAlign: 'bottom',
                  }}
                  shape="circle"
                  size={32}
                >
                  {item.contactName}
                </Avatar>
              </Col>
              <Col span={21}>
                <Row type="flex" justify="space-between" align="middle">
                  <div className="contactName">{item.contactName}</div>
                </Row>
                <Row type="flex" justify="space-between" align="middle">
                  <div className="contactDetail">
                    {item.jobTitle.length > 30
                      ? `${item.jobTitle.substring(0, 30)}...`
                      : item.jobTitle}
                  </div>
                </Row>
              </Col>
            </Row>
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  const addNewUserFromList = (scheduleId, contactId) => {
    const modifiedArray = [...allContacts]
    modifiedArray[scheduleId].rows.push(
      participants.find((x) => x.contactId == contactId)
    )
    setAllContacts(modifiedArray)
  }

  const removeContactFromSchedule = (index, contactId) => {
    const modifiedArray = [...allContacts]
    const indexToRemove = modifiedArray[index].rows.findIndex(
      (x) => x.contactId === contactId
    )
    modifiedArray[index].rows.splice(indexToRemove, 1)
    setAllContacts(modifiedArray)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {allContacts &&
        allContacts.map((value, key) => (
          <Droppable droppableId={`droppable-${key}`}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <h4
                  style={{ textAlign: 'left', borderBottom: '1px solid white' }}
                >{`Schedule ${key + 1}`}</h4>
                {value.rows.map((item, index) => (
                  <Draggable
                    key={String(item.contactId)}
                    draggableId={String(item.contactId)}
                    index={index}
                    isDragDisabled={true}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <Row type="flex" justify="space-between" align="middle">
                          <Col span={3}>
                            <Avatar
                              src={item.imageURL}
                              style={{
                                fontSize: '14px',
                                textAlign: 'center',
                                verticalAlign: 'bottom',
                              }}
                              shape="circle"
                              size={32}
                            >
                              {item.contactName}
                            </Avatar>
                          </Col>
                          <Col span={17}>
                            <Row
                              type="flex"
                              justify="space-between"
                              align="middle"
                            >
                              <div className="contactName">
                                {item.contactName}
                              </div>
                            </Row>
                            <Row
                              type="flex"
                              justify="space-between"
                              align="middle"
                            >
                              <div className="contactDetail">
                                {item.jobTitle}
                              </div>
                            </Row>
                          </Col>
                          <Col span={3} style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              onClick={() => {
                                removeContactFromSchedule(key, item.contactId)
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <Row>
                  <Dropdown overlay={contactMenu} trigger={['click']}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => {
                        setContactMenu(showContactsAvailable(key))
                        e.preventDefault()
                      }}
                      style={{ fontWeight: 600, paddingTop: '6px' }}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add new
                    </a>
                  </Dropdown>
                </Row>
              </div>
            )}
          </Droppable>
        ))}
    </DragDropContext>
  )
}

export default ArrayDragAndDrop
