import {
  FETCH_FUNDS_BROAD_STRATEGY_GROUPS,
  FETCH_FUNDS_INVESTMENT_VEHICLES,
  FETCH_FUNDS_LIST,
  FETCH_FUNDS_LIST_ATTENDEES,
  FETCH_FUNDS_SUBSTRATEGY_GROUPS,
} from '../constants/types'

const initialState = {
  fundList: [],
  fundListAttendees: [],
  broadStrategyGroups: [],
  subStrategyGroups: [],
  investmentVehicles: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_FUNDS_LIST:
      return { ...state, fundList: action.payload }
    case FETCH_FUNDS_LIST_ATTENDEES:
      return { ...state, fundListAttendees: action.payload }
    case FETCH_FUNDS_BROAD_STRATEGY_GROUPS:
      return { ...state, broadStrategyGroups: action.payload }
    case FETCH_FUNDS_SUBSTRATEGY_GROUPS:
      return { ...state, subStrategyGroups: action.payload }
    case FETCH_FUNDS_INVESTMENT_VEHICLES:
      return { ...state, investmentVehicles: action.payload }

    default:
      return state
  }
}
