import { useCallback, useEffect, useState } from 'react'
import { Empty, Spin } from 'antd'
import isNil from 'lodash/isNil'
import SummitDetails from '../components/SummitDetails/SummitDetails'
import http from '../utils/api'

const SummitDetailsContainer = ({
  match: {
    params: { eventId, searchTerm },
  },
  history: { goBack },
}) => {
  const [attendingEvent, setAttendingEvent] = useState(null)
  const selectedEventId = parseInt(eventId, 10)
  const defaultFilterValue = searchTerm

  const getEvent = useCallback(() => {
    http.get(`/events/${selectedEventId}`).then(function (response) {
      setAttendingEvent(response.data)
    })
  }, [selectedEventId])

  useEffect(() => {
    getEvent()
  }, [getEvent])

  if (isNil(attendingEvent)) {
    return (
      <Spin spinning="true">
        <Empty />
      </Spin>
    )
  }

  return (
    <SummitDetails
      selectedEvent={attendingEvent}
      back={goBack}
      getEvent={getEvent}
      defaultFilterValue={defaultFilterValue}
    />
  )
}

export default SummitDetailsContainer
