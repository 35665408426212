import { Form } from 'antd'
import isNil from 'lodash/isNil'
import CCUpload from '../../CCUpload/CCUpload'

const ImageStep = (props) => {
  const { values, touched, errors, setFieldValue } = props

  return (
    <>
      <label>
        Please upload a cover image for this content (1200 x 630 recommended){' '}
        <span className="required">*</span>
      </label>
      <Form.Item
        name="coverImageBase64"
        validateStatus={
          touched.coverImageBase64 &&
          errors.coverImageBase64 &&
          !isNil(values.coverImageUrl)
            ? 'error'
            : 'success'
        }
        help={
          touched.coverImageBase64 &&
          errors.coverImageBase64 &&
          !isNil(values.coverImageUrl)
            ? errors.coverImageBase64
            : ''
        }
      >
        <CCUpload
          placeholder="Upload Cover Image"
          imageUrl={values.coverImageBase64 || values.coverImageUrl}
          onChange={(val) => setFieldValue('coverImageBase64', val)}
          style={{ marginBottom: '16px' }}
          rect
        />
      </Form.Item>
    </>
  )
}

ImageStep.propTypes = {}

export default ImageStep
