import { useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Col, Modal, Row, message } from 'antd'
import moment from 'moment'
import http from '../../utils/api'
import TagGroup from '../TagGroup'

const VisibilityModal = ({ visible, contactName, companies, handleCancel }) => {
  const [currentCompanies, setCurrentCompanies] = useState(companies)
  const [refreshMe, setRefreshMe] = useState(moment())

  const changeVisibility = () => {
    http
      .post('https://localhost:5001/companies/invisible', {
        companies: currentCompanies,
      })
      .then(() => {
        message.success('User visibility changed!')
        handleCancel()
      })
      .catch((exception) => {
        message.success('Something wrong happened, try again!')
        console.log(exception)
      })
  }

  const handleChange = (t) => {
    const obj = currentCompanies.find(
      (obj) => obj.companyContactAllocatorId == t.target.value
    )
    obj.isInvisible = !obj.isInvisible
    setCurrentCompanies(currentCompanies)
    setRefreshMe(moment)
  }

  return (
    <Modal
      className="cc-modal-accept"
      title={'Change Visibility'}
      visible={visible}
      okText={'Save Changes'}
      cancelText="Cancel"
      onOk={changeVisibility}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: false,
        style: { backgroundColor: '#D62B34', borderStyle: 'none' },
      }}
      centered={true}
      cancelButtonProps={{ disabled: false }}
    >
      <div>
        Choose companies you want to make <strong>{contactName}</strong>{' '}
        invisible from the Discovery:
      </div>
      <div>
        {currentCompanies &&
          currentCompanies.map(
            (row) =>
              row.category == 'Allocator' && (
                <div style={{ paddingTop: '16px' }}>
                  <Row>
                    <Col span={12} refresh={refreshMe.format()}>
                      <TagGroup
                        color="#2FC5D2"
                        maxLength={200}
                        items={[row.category]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <h4>
                        <strong>{row.name}</strong>
                      </h4>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                      <Checkbox
                        value={row.companyContactAllocatorId}
                        onChange={handleChange}
                        checked={row.isInvisible}
                      >
                        Invisible
                      </Checkbox>
                    </Col>
                  </Row>
                  <hr style={{ border: '1px solid #EFF1F4' }} />
                </div>
              )
          )}
      </div>
    </Modal>
  )
}

VisibilityModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
}

export default VisibilityModal
