import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Radio, Tooltip, message } from 'antd'
import isNil from 'lodash/isNil'
import { getInvestorStatusQuestions } from '../../actions/onboarding'
import LoadingGif from '../LoadingGif'

const accreditedInvestorInfo =
  'I am an “accredited investor” within the meaning of Rule 501 of Regulation D under the Securities Act of 1933 and have selected the item below which is next to the category under which I qualify as an accredited investor'
const qualifiedInvestorInfo =
  'I am a “qualified purchaser” within the meaning of Section 2(a)(51)(A) of the Investment Company Act of 1940 and have selected the item below which is next to the category under which I qualify as a qualified purchaser.'

const InvestorStatusForm = ({
  fromCfnSettings = false,
  onInvestorStatusChange,
  enableNext,
  currentQuestions,
  companyId,
  step,
  onChange,
  customClass = false,
  stepNumber,
  contactId = 0,
}) => {
  const [investorQualifications, setInvestorQualifications] = useState([])
  const [selectedQualification, setSelectedQualification] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [questions, setQuestions] = useState(null)

  useEffect(() => {
    const fillAnswers = (qualificationQuestions) => {
      const selectedAnswer = qualificationQuestions.find(
        (x) => !isNil(x.answer)
      )
      if (isNil(selectedAnswer)) {
        //enableNext(false);
      } else {
        setSelectedQualification(selectedAnswer.shortName)
        setSelectedOptions(
          selectedAnswer.answer.map((x) => `${selectedAnswer.shortName}|${x}`)
        )
        //enableNext(selectedAnswer.answer.length > 0);
      }
      return selectedAnswer
    }

    let stepQuestions
    if (isNil(investorQualifications) || investorQualifications.length === 0) {
      if (
        step.customRender === true &&
        step.customRenderParameter &&
        step.renderAction
      ) {
        step
          .renderAction(step.customRenderParameter)
          .then((response) => {
            const qualificationQuestions = response.data
            setInvestorQualifications(response.data)
            const selectedAnswer = fillAnswers(qualificationQuestions)
            if (isNil(selectedAnswer)) {
              setQuestions(response.data)
            } else {
              const selectedQuestion = response.data.find(
                (x) => x.shortName === selectedAnswer.shortName
              )

              selectedQuestion.answer = selectedAnswer.answer.map(
                (x) => x.split('|')[1]
              )
              selectedQuestion.currentlyValid = selectedAnswer.answer.length > 0
              setQuestions([selectedQuestion])
            }
          })
          .catch(() => {
            message.error(
              'There was an error while attempting to get questions2'
            )
          })
          .finally(setLoading(false))
        stepQuestions = fromCfnSettings
          ? currentQuestions
          : currentQuestions?.find((x) => x.step === 10)
      } else {
      }
      if (
        // currentQuestions.length > 0 &&
        // selectedQualification !== currentQuestions[0].questions[0].shortName
        !isNil(stepQuestions) &&
        selectedQualification !== stepQuestions.questions[0].shortName
      ) {
        setSelectedQualification(stepQuestions.questions[0].shortName)
      }
    }
  }, [
    companyId,
    currentQuestions,
    enableNext,
    fromCfnSettings,
    investorQualifications,
    onChange,
    selectedQualification,
    step,
  ])

  const onSelectionChanged = useCallback(
    (e, newQualification = null) => {
      //enableNext(e.length > 0);
      setSelectedOptions(e)
      if (step.customRender === true) {
        let selectedQuestion
        if (isNil(newQualification)) {
          selectedQuestion = questions[0]
        } else {
          selectedQuestion = investorQualifications.find(
            (x) => x.shortName === newQualification
          )
        }
        selectedQuestion.answer = e.map((x) => x.split('|')[1])
        selectedQuestion.currentlyValid = e.length > 0
        setQuestions([selectedQuestion])
        let isInterestedInDeals = currentQuestions.find(
          (x) => x.shortName === 'IsInterestedInDeals'
        )
        if (isNil(isInterestedInDeals)) {
          const currentStepQuestion = currentQuestions.find(
            (x) => x.step === stepNumber
          )?.questions
          if (!isNil(currentStepQuestion)) {
            isInterestedInDeals = currentStepQuestion.find(
              (x) => x.shortName === 'IsInterestedInDeals'
            )
          }
        }
        const newQuestion = [selectedQuestion].concat(isInterestedInDeals)

        onChange(newQuestion)
      } else {
        onInvestorStatusChange(
          isNil(newQualification) ? selectedQualification : newQualification,
          e
        )
      }
    },
    [
      currentQuestions,
      enableNext,
      investorQualifications,
      onChange,
      onInvestorStatusChange,
      questions,
      selectedQualification,
      step,
      stepNumber,
    ]
  )

  const onRadioChange = useCallback(
    (e) => {
      const selectedValue = e.target.value
      setSelectedQualification(selectedValue)
      onSelectionChanged([], selectedValue)
    },
    [onSelectionChanged]
  )

  const selectedQualificationObject = investorQualifications.find(
    (x) => x.shortName === selectedQualification
  )

  const renderOption = (answer, answerDetails) => (
    <>
      <Checkbox
        value={`${selectedQualificationObject.shortName}|${answer}`}
        className="cc-investor-status-item-checkbox"
        key={`${selectedQualificationObject.shortName}|${answer}`}
      >
        <div className="cc-body-text cc-investor-status-item-label">
          {answer}
        </div>
      </Checkbox>
      <div className="cc-small-font cc-investor-status-item-details">
        {answerDetails}
      </div>
    </>
  )

  const renderTooltip = useCallback(
    (text) => (
      <Tooltip title={text}>
        <span className="cc-info-icon">
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      </Tooltip>
    ),
    []
  )

  return (
    <LoadingGif spinning={loading}>
      <div
        className={
          !fromCfnSettings && !customClass && 'cc-investor-status-form'
        }
      >
        <Radio.Group onChange={onRadioChange} value={selectedQualification}>
          <Radio value="Accredited Investor">
            <div className="cc-horizontal-spawning-radio">
              <div className="cc-body-text cc-company-selection-name">
                Accredited Investor {renderTooltip(accreditedInvestorInfo)}
              </div>
              <div className="cc-small-font cc-company-selection-type">
                This is a lower level than a qualified purchaser
              </div>
            </div>
          </Radio>
          <Radio value="Qualified Purchaser">
            <div className="cc-horizontal-spawning-radio">
              <div className="cc-body-text cc-company-selection-name">
                Qualified Purchaser {renderTooltip(qualifiedInvestorInfo)}
              </div>
              <div className="cc-small-font cc-company-selection-type">
                This is a higher level than accredited investor
              </div>
            </div>
          </Radio>
        </Radio.Group>

        <div className="cc-investor-status-form-separator" />

        <div className="cc-investor-status-form-subselections">
          {isNil(selectedQualificationObject) ? null : (
            <Checkbox.Group
              value={selectedOptions}
              onChange={onSelectionChanged}
            >
              {selectedQualificationObject.questionAnswers.map((x) =>
                renderOption(
                  x.questionAnswer,
                  x.answerDetails,
                  x.answerHelpText
                )
              )}
            </Checkbox.Group>
          )}
        </div>

        <div className="cc-investor-status-error-message">
          Please select an investor qualification and at least one sub category
        </div>
      </div>
    </LoadingGif>
  )
}

InvestorStatusForm.propTypes = {
  onInvestorStatusChange: PropTypes.func.isRequired,
  enableNext: PropTypes.func.isRequired,
  currentQuestions: PropTypes.array.isRequired,
  companyId: PropTypes.number.isRequired,
  fromCfnSettings: PropTypes.bool,
  step: PropTypes.shape({
    stepNumber: PropTypes.number,
    innerStep: PropTypes.number.isRequired,
    steps: PropTypes.array,
    shouldPost: PropTypes.bool,
    actionType: PropTypes.number,
    canGoBack: PropTypes.bool,
    customWidth: PropTypes.bool,
    renderAction: PropTypes.func.isRequired,
    customRender: PropTypes.bool,
    customRenderParameter: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
  customClass: PropTypes.bool,
  stepNumber: PropTypes.number,
}

export default InvestorStatusForm
