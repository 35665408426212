import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isNull } from 'util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Menu, PageHeader, Tabs } from 'antd'
import AttendeeList from '../AttendeeList'
import OnlineAttendeeList from '../OnlineAttendeeList'
import SponsorContentEvent from '../SponsorContentEvent/SponsorContentEvent'
//import OnlineInfo from "./OnlineInfo";
import SummitOverview from '../SummitOverview'
import './OnlineDetails.less'

const { TabPane } = Tabs

const OnlineDetails = ({ push, back }) => {
  return (
    <>
      <div className="SummitDetails" style={{ padding: '10px' }}>
        {/* <div className="SummitDetails-wrapper">
          <OnlineInfo back={back} isonline={true} />
        </div> */}
        <OnlineAttendeeList />
      </div>
    </>
  )
}

OnlineDetails.propTypes = {
  selectedEvent: PropTypes.shape({
    eventId: PropTypes.number.isRequired,
    pastEvent: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    allocators: PropTypes.number.isRequired,
    fundManagers: PropTypes.number.isRequired,
    media: PropTypes.number.isRequired,
    serviceProviders: PropTypes.number.isRequired,
  }).isRequired,
  //push: PropTypes.func.isRequired,
}

export default OnlineDetails
