import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Modal, Row, Select, Tag, message } from 'antd'
import { map } from 'lodash'
import { getInvestorQualificationTypes } from '../../actions/allocator'
import { getFundCompliance, updateFundCompliance } from '../../actions/funds'
import { getAllCountries } from '../../actions/geography'

const FundComplianceModal = ({ visible, fund, onCancel, onOk }) => {
  const [investorTypes, setInvestorTypes] = useState([])
  const [selectedInvestorTypes, setSelectedInvestorTypes] = useState([])
  const [countries, setCountries] = useState([])
  const [selectedCountries, setSelectedCountries] = useState([])

  useEffect(() => {
    getInvestorQualificationTypes().then((response) => {
      const investorOptions = map(response.data, (item) => ({
        value: item.investorQualificationTypeId,
        label: item.name,
      }))
      setInvestorTypes(investorOptions)
    })
  }, [])

  useEffect(() => {
    getAllCountries().then((response) => {
      const countryOptions = map(response.data, (item) => ({
        value: item.countryId,
        label: item.name,
      }))
      setCountries(countryOptions)
    })
  }, [])

  useEffect(() => {
    getFundCompliance(fund.fundId).then((response) => {
      setSelectedInvestorTypes(response.data.hiddenInvestorQualificationTypes)
      setSelectedCountries(response.data.hiddenCountries)
    })
  }, [fund])

  const handleSubmit = () => {
    const complianceData = {
      hiddenCountries: selectedCountries,
      hiddenInvestorQualificationTypes: selectedInvestorTypes,
    }

    updateFundCompliance(fund.fundId, complianceData)
      .then((res) => {
        message.success('Fund Compliance updated successfully.')
        onOk()
      })
      .catch((err) =>
        message.error('An error occurred while updating fund compliance.')
      )
  }

  return (
    <Modal
      visible={visible}
      title={fund.name}
      width={'60%'}
      onCancel={() => onCancel()}
      onOk={() => handleSubmit()}
    >
      <Row>
        <div style={{ fontWeight: 'bold' }}>INVESTOR STATUS</div>
        <label>Hide fund profile from the following investors:</label>
        <Checkbox.Group
          style={{ display: 'grid' }}
          value={selectedInvestorTypes}
          options={investorTypes}
          onChange={(val) => setSelectedInvestorTypes(val)}
        />
      </Row>
      <Row>
        <div style={{ fontWeight: 'bold' }}>GEOGRAPHIC STATUS</div>
        <label>
          Hide fund profile to investors based on the following countries:
        </label>
        <br />
        <Select
          className="searchbox"
          optionFilterProp="children"
          mode="multiple"
          style={{ minWidth: '50%' }}
          onChange={(val) => {
            setSelectedCountries(val)
          }}
          value={selectedCountries}
          showSearch
        >
          {map(countries, (country, key) => (
            <Select.Option key={key} value={country.value}>
              {country.label}
            </Select.Option>
          ))}
        </Select>
      </Row>
    </Modal>
  )
}

FundComplianceModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  fund: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
}

export default FundComplianceModal
