import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, message } from 'antd'
import http from '../../utils/api'

const DeleteUserModal = ({
  visible,
  contactName,
  contactId,
  handleOk,
  handleCancel,
}) => {
  const [loading, setLoading] = useState(false)

  const deleteUser = () => {
    setLoading(true)
    http
      .delete(`/contacts/${contactId}`)
      .then(() => {
        message.open({
          content: 'User deleted successfully',
          icon: (
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={{ marginRight: '10px', color: '#D62B34' }}
            />
          ),
        })
        handleOk()
      })
      .catch((error) => {
        console.log(error)
        handleOk()
      })
      .finally(() => {
        setLoading(false)
        handleOk()
      })
  }

  return (
    <Modal
      className="cc-modal-accept"
      title={`Delete User`}
      visible={visible}
      okText={`Delete User`}
      cancelText="Cancel"
      onOk={deleteUser}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: loading,
        style: { backgroundColor: '#D62B34', borderStyle: 'none' },
      }}
      centered={true}
      cancelButtonProps={{ disabled: false }}
    >
      <div>
        Are you sure you want to delete <strong>{contactName}</strong> from the
        platform?
      </div>
    </Modal>
  )
}

DeleteUserModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  contactName: PropTypes.string.isRequired,
  contactId: PropTypes.number.isRequired,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
}

export default DeleteUserModal
