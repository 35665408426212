import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { Form, Formik } from 'formik'
import { isNil } from 'lodash'
import moment from 'moment'
import * as Yup from 'yup'
import CategoryStep from './Steps/CategoryStep'
import ImageStep from './Steps/ImageStep'
import MediaStep from './Steps/MediaStep'
import OptionsStep from './Steps/OptionsStep'
import OverviewStep from './Steps/OverviewStep'
import './ContentEntryWizard.less'

const STEPS = [
  {
    title: 'Content Overview',
    component: OverviewStep,
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Title is a required field'),
      subtitle: Yup.string(),
      description: Yup.string(),
      publishDateTimeUtc: Yup.date()
        .typeError('Start Date is a required field')
        .required('Start Date is a required field'),
      endDateTimeUtc: Yup.date()
        .nullable()
        .test('after-publish', 'End date must be after start date', (val) =>
          isNil(val)
            ? true
            : moment(val) > moment(Yup.ref('publishDateTimeUtc'))
        ),
    }),
  },
  {
    title: 'Media',
    component: MediaStep,
    validationSchema: Yup.object().shape({
      mediaTypeId: Yup.number()
        .typeError('Media is a required field')
        .required('Media is a required field'),
      mediaContent: Yup.string()
        .typeError('Media is a required field')
        .required('Media is a required field'),
    }),
  },
  {
    title: 'Category',
    component: CategoryStep,
    validationSchema: Yup.object().shape({
      contentCategoryIds: Yup.array().required('Category is a required field'),
      authorContact: Yup.object()
        .shape({
          contactId: Yup.number().nullable(),
          companyContactId: Yup.number()
            .nullable()
            .test(
              'ccid-required-contactid',
              'Please select a company',
              function (val, context) {
                return isNil(val) && !isNil(context.parent.contactId)
                  ? false
                  : true
              }
            ),
          fundId: Yup.number().nullable(),
        })
        .nullable(),
      taggedContacts: Yup.array().of(
        Yup.object().shape({
          contactId: Yup.number()
            .required('Please select a contact or remove')
            .typeError('Please select a contact or remove'),
          companyContactId: Yup.number()
            .nullable()
            .test(
              'ccid-required-contactid',
              'Please select a company',
              function (val, context) {
                return isNil(val) && !isNil(context.parent.contactId)
                  ? false
                  : true
              }
            ),
          fundId: Yup.number().nullable(),
        })
      ),
    }),
  },
  {
    title: 'Cover Image',
    component: ImageStep,
    validationSchema: Yup.object().shape({
      coverImageBase64: Yup.string().test(
        'image',
        'Cover image is required',
        (val) => (!isNil(val) ? true : !isNil(Yup.ref('coverImageUrl')))
      ),
    }),
  },
  {
    title: 'Additional Options',
    component: OptionsStep,
    validationSchema: Yup.object().shape({
      isFeatured: Yup.bool().typeError('Required').required('Required'),
      includeInWeeklyDigest: Yup.bool()
        .typeError('Required')
        .required('Required'),
    }),
  },
]

const ContentEntryWizard = ({ onFinish, initialValues }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [isLastStep, setIsLastStep] = useState(false)

  const handleSubmit = (e) => {
    if (isLastStep) {
      onFinish(e)
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  useEffect(() => {
    if (currentStep === STEPS.length - 1) {
      setIsLastStep(true)
    } else {
      setIsLastStep(false)
    }
  }, [currentStep])

  const getComponent = (props) => {
    const Component = STEPS[currentStep].component

    if (!isNil(Component)) {
      return <Component {...props} />
    } else {
      return null
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={STEPS[currentStep].validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(props) => (
        <Form className="ContentEntryWizard">
          <div className="step-title">{STEPS[currentStep].title}</div>
          {getComponent(props)}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {currentStep !== 0 && (
                <Button onClick={() => setCurrentStep(currentStep - 1)}>
                  Back
                </Button>
              )}
            </span>
            <Button htmlType="submit" type="primary">
              {isLastStep ? 'Finish' : 'Next'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

ContentEntryWizard.propTypes = {
  onFinish: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
}

export default ContentEntryWizard
