import { getDealsByCompanyList } from '../../actions/deals'
import Deals from './Deals'

const DealsByCompanyContainer = ({ companyId, companyName, contacts }) => {
  return (
    <>
      <Deals
        displayTitle={false}
        displayAddButton={true}
        tableTitle={'List of Company Deals'}
        getDealListData={getDealsByCompanyList(companyId)}
        companyIdProp={companyId}
        newDealForCompanyTitle={`New Deal for: ${companyName}`}
        newDealForCompanyContacts={contacts}
      />
    </>
  )
}

export default DealsByCompanyContainer
