import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Divider, Modal, Row, Table } from 'antd'
import moment from 'moment'
import { getEventSms, getEventSmsLogs } from '../../actions/eventSms'
import LoadingGif from '../LoadingGif'

const { Column } = Table

const SummitAttendeeSMSLog = ({ eventId, isVisible, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [eventSms, setEventSms] = useState([])
  const [selectedMessageId, setSelectedMessageId] = useState(0)
  const [loadingLog, setLoadingLog] = useState(false)
  const [eventSmsLogs, setEventSmsLogs] = useState([])

  useEffect(() => {
    if (isVisible) {
      setLoading(true)
      getEventSms(eventId)
        .then((response) => {
          setEventSms(response.data)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [eventId, isVisible])

  useEffect(() => {
    if (isVisible && selectedMessageId > 0) {
      setLoadingLog(true)
      getEventSmsLogs(selectedMessageId)
        .then((response) => {
          setEventSmsLogs(response.data)
          setLoadingLog(false)
        })
        .catch(() => {
          setLoadingLog(false)
        })
    }
  }, [eventId, isVisible, selectedMessageId])

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedMessageId(selectedRowKeys)
    },
  }

  return (
    <Modal
      visible={isVisible}
      title="Summit SMS Logs"
      width="1400px"
      centered
      footer={null}
      onCancel={handleClose}
    >
      <Row>
        <Col span={10}>
          <LoadingGif spinning={loading}>
            <div>
              <Table
                dataSource={eventSms}
                rowKey={(record) => record.id.toString()}
                rowSelection={{
                  type: 'radio',
                  ...rowSelection,
                }}
                pagination={{ position: 'bottom' }}
                size={'small'}
              >
                <Column
                  title="Body"
                  dataIndex="body"
                  key="body"
                  sorter={(a, b) => a.body.localeCompare(b.body)}
                />
                <Column
                  title="Queued for"
                  dataIndex="queuedFor"
                  key="queuedFor"
                  sorter={(a, b) =>
                    a.queuedFor
                      ? a.queuedFor.localeCompare(b.queuedFor)
                      : ''.localeCompare(b.queuedFor)
                  }
                />
                <Column
                  title="Created By"
                  dataIndex="backstageUserEmail"
                  key="backstageUserEmail"
                  sorter={(a, b) =>
                    a.backstageUserEmail
                      ? a.backstageUserEmail.localeCompare(b.backstageUserEmail)
                      : ''.localeCompare(b.backstageUserEmail)
                  }
                />
                <Column
                  title="Created At"
                  dataIndex="createdAt"
                  render={(createdAt) =>
                    moment.utc(createdAt).local().format('YYYY-MM-DD hh:mm a')
                  }
                  sorter={(a, b) =>
                    a.createdAt
                      ? a.createdAt.localeCompare(b.createdAt)
                      : ''.localeCompare(b.createdAt)
                  }
                />
              </Table>
            </div>
          </LoadingGif>
        </Col>
        <Col span={1}>
          <Divider type="vertical" style={{ height: '350px' }} />
        </Col>
        {(selectedMessageId > 0 || loadingLog) && (
          <Col span={13}>
            <LoadingGif spinning={loadingLog}>
              <div>
                <Table
                  dataSource={eventSmsLogs}
                  rowKey={(record) => record.id.toString()}
                  pagination={{ position: 'bottom' }}
                  size={'small'}
                >
                  <Column
                    title="Contact"
                    dataIndex="contactName"
                    key="contactName"
                    sorter={(a, b) =>
                      a.contactName.localeCompare(b.contactName)
                    }
                  />
                  <Column
                    title="To Number"
                    dataIndex="toNumber"
                    key="toNumber"
                    sorter={(a, b) =>
                      a.toNumber
                        ? a.toNumber.localeCompare(b.toNumber)
                        : ''.localeCompare(b.toNumber)
                    }
                  />
                  <Column
                    title="Status"
                    dataIndex="status"
                    key="status"
                    sorter={(a, b) =>
                      a.status
                        ? a.status.localeCompare(b.status)
                        : ''.localeCompare(b.status)
                    }
                  />
                  <Column
                    title="Queued At"
                    dataIndex="dateSent"
                    render={(dateSent) =>
                      moment.utc(dateSent).local().format('YYYY-MM-DD hh:mm a')
                    }
                    sorter={(a, b) =>
                      a.dateSent
                        ? a.dateSent.localeCompare(b.dateSent)
                        : ''.localeCompare(b.dateSent)
                    }
                  />
                  <Column
                    title="Error Message"
                    dataIndex="errorMessage"
                    key="errorMessage"
                    sorter={(a, b) =>
                      a.errorMessage
                        ? a.errorMessage.localeCompare(b.errorMessage)
                        : ''.localeCompare(b.errorMessage)
                    }
                  />
                </Table>
              </div>
            </LoadingGif>
          </Col>
        )}
      </Row>
    </Modal>
  )
}

SummitAttendeeSMSLog.propTypes = {
  eventId: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default SummitAttendeeSMSLog
