import '../../utils/columns'
import { useEffect, useRef, useState } from 'react'
import { Col, DatePicker, Modal, Row, Select } from 'antd'
import { Chart, Interval, Tooltip } from 'bizcharts'
import moment from 'moment'
import { getDealActivityDetailsList } from '../../actions/deals'
import { getMandateActivityDetailsList } from '../../actions/mandates'
import {
  getDealsActivityDetailsMeetingColumns,
  getDealsActivityDetailsViewColumns,
  getMandatesActivityDetailsViewColumns,
} from '../../utils/columns'
import ContextTable from '../ContextTable'
import './Activities.less'

const { Option } = Select

const Activities = ({
  showActivityModal,
  moduleCode,
  entityId,
  entityName,
  handleActivityModalClose,
  getActivityColumns,
  getActivityData,
  getActivityGraphData,
  graphData,
}) => {
  const [refreshActivityData, setRefreshActivityData] = useState(false)
  const [activityColumns, setActivityColumns] = useState([])
  const [activityDetailsColumns, setActivityDetailsColumns] = useState([])
  const [showModuleActivityDetails, setShowModuleActivityDetails] =
    useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [graphicData, setGraphicData] = useState([])
  const [graphPosition, setGraphPosition] = useState(graphData)

  const isPdfView = useRef(false)
  const activityDescription = useRef('')
  const selectedDate = useRef('')
  const isMeetingReceived = useRef(false)

  const activityDetailsDataMethod = () => {
    if (moduleCode == 'Deal') {
      return getDealActivityDetailsList(
        entityId,
        selectedDate.current,
        isPdfView.current,
        isMeetingReceived.current
      )
    } else {
      if (moduleCode == 'Mandate') {
        return getMandateActivityDetailsList(
          entityId,
          selectedDate.current,
          isPdfView.current,
          isMeetingReceived.current
        )
      }
    }
  }

  const refreshActivity = () => {
    setRefreshActivityData(!refreshActivityData)
  }

  useEffect(() => {
    var activityCols = getActivityColumns(refreshActivity, showActivityDetails)
    setActivityColumns(activityCols)
  }, [refreshActivityData])

  useEffect(() => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      refreshActivity()
    }
  }, [startDate, endDate])

  const showActivityDetails = (description, date, pdfView, meetingReceived) => {
    setShowModuleActivityDetails(!showModuleActivityDetails)

    activityDescription.current = description
    selectedDate.current = date
    isPdfView.current = pdfView
    isMeetingReceived.current = meetingReceived

    var activityDetailsCols

    if (moduleCode == 'Deal') {
      if (!meetingReceived)
        activityDetailsCols = getDealsActivityDetailsViewColumns()
      else activityDetailsCols = getDealsActivityDetailsMeetingColumns()
    } else {
      if (moduleCode == 'Mandate') {
        activityDetailsCols = getMandatesActivityDetailsViewColumns()
      }
    }

    setActivityDetailsColumns(activityDetailsCols)
  }

  function handleGraphicDropDownChange(value) {
    setGraphPosition(value)
  }

  const handleFetchModuleActivity = () => {
    const startDateStr = startDate?.format('yyyy-MM-DD') || ''
    const endDateStr = endDate?.format('yyyy-MM-DD') || ''
    var data = getActivityData(startDateStr, endDateStr)
    return data
  }

  useEffect(() => {
    const startDateStr = startDate?.format('yyyy-MM-DD') || ''
    const endDateStr = endDate?.format('yyyy-MM-DD') || ''

    getActivityGraphData(startDateStr, endDateStr).then((response) => {
      var graphicData = response.data
      graphicData.forEach(function (result, index) {
        result.dealDate = moment(result.dealDate).format('MM/DD')
      })
      setGraphicData(graphicData)
    })
  }, [refreshActivityData, graphPosition])

  return (
    <div>
      <Modal
        title={<div>{entityName}</div>}
        destroyOnClose
        visible={showActivityModal}
        onCancel={() => {
          handleActivityModalClose()
        }}
        footer={null}
        width="900px"
        bodyStyle={{ padding: '0px' }}
      >
        <div id="filters">
          <Row type="flex" justify="space-between" align="middle">
            <Col span={16}>
              <Select
                defaultValue={graphData}
                style={{ width: 150 }}
                onChange={handleGraphicDropDownChange}
              >
                {moduleCode == 'Mandate' && (
                  <Option value="mandateDate*mandateViews">
                    Mandate Views
                  </Option>
                )}
                {moduleCode == 'Mandate' && (
                  <Option value="mandateDate*mandatePdfViews">
                    Mandate Pdf Views
                  </Option>
                )}
                {moduleCode == 'Mandate' && (
                  <Option value="mandateDate*applicationsNo">
                    Mandate Applications
                  </Option>
                )}
                {moduleCode == 'Deal' && (
                  <Option value="dealDate*dealViews">Deal Views</Option>
                )}
                {moduleCode == 'Deal' && (
                  <Option value="dealDate*dealPdfViews">Deal Pdf Views</Option>
                )}
                {moduleCode == 'Deal' && (
                  <Option value="dealDate*meetingRequests">
                    Received Meetings
                  </Option>
                )}
              </Select>
            </Col>
            <Col span={8}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={12}>
                  <DatePicker
                    size="default"
                    placeholder="Start Date"
                    onChange={(date) => setStartDate(date)}
                    disabledDate={(date) =>
                      endDate && date.isSameOrAfter(endDate)
                    }
                  />
                </Col>
                <Col span={12}>
                  <DatePicker
                    size="default"
                    placeholder="End Date"
                    onChange={(date) => setEndDate(date)}
                    disabledDate={(date) =>
                      startDate && date.isSameOrBefore(startDate)
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div id="graphic" style={{ padding: '25px' }}>
          <Chart height={200} autoFit data={graphicData}>
            <Interval position={graphPosition} color={'#2FC5D2'} />
            <Tooltip shared />
          </Chart>
        </div>
        <div id="viewTable" className="dealActivities">
          <Row>
            <Col span={24}>
              {activityColumns && (
                <ContextTable
                  tableTitle=""
                  columns={activityColumns}
                  dataMethod={handleFetchModuleActivity()}
                  refresh={refreshActivityData}
                  refreshData={refreshActivityData}
                  hideSearch
                />
              )}
            </Col>
          </Row>
        </div>
      </Modal>

      {showModuleActivityDetails && (
        <Modal
          title={activityDescription.current}
          destroyOnClose
          visible={showModuleActivityDetails}
          onCancel={() => setShowModuleActivityDetails(false)}
          footer={null}
          width="900px"
          bodyStyle={{ padding: '0px' }}
          wrapClassName={'dealActivities'}
        >
          <ContextTable
            tableTitle=""
            columns={activityDetailsColumns}
            hideSearch
            dataMethod={activityDetailsDataMethod()}
          />
        </Modal>
      )}
    </div>
  )
}

export default Activities
