import * as React from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import { Input, Option, Select } from '@context365/forms'
import { DeleteOutline } from '@context365/icons'
import produce from 'immer'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { ADD_NEW_ID } from '../../constants'
import DisabledLabel from '../DisabledLabel'

const getNewStrategyAmount = () => ({
  vettedFundStrategyId: null,
  unvettedFundStrategyId: null,
  name: null,
})

const generateStrategyInputId = (fundStrategy) =>
  fundStrategy?.vettedFundStrategyId && fundStrategy?.unvettedFundStrategyId
    ? 'new'
    : fundStrategy?.vettedFundStrategyId
    ? `vettedFundStrategy-${fundStrategy.vettedFundStrategyId}`
    : fundStrategy?.unvettedFundStrategyId
    ? `unvettedFundStrategy-${fundStrategy.unvettedFundStrategyId}`
    : null

const StrategyField = ({ label, options, strategy, onChange, onRemove }) => (
  <div className="grid grid-cols-4 gap-4 pt-3">
    <Select.AutoComplete
      className="col-span-2"
      key={options.length}
      label={label}
      value={generateStrategyInputId(strategy)}
      onChange={(val) => {
        let newVettedFundStrategyId = val?.startsWith('vettedFundStrategy')
          ? val.split('vettedFundStrategy-')[1]
          : null
        let newUnvettedFundStrategyId = val?.startsWith('unvettedFundStrategy')
          ? val.split('unvettedFundStrategy-')[1]
          : null

        if (!newVettedFundStrategyId && !newUnvettedFundStrategyId) {
          newVettedFundStrategyId = ADD_NEW_ID
          newUnvettedFundStrategyId = ADD_NEW_ID
        }

        onChange([
          {
            field: 'vettedFundStrategyId',
            value: newVettedFundStrategyId,
          },
          {
            field: 'unvettedFundStrategyId',
            value: newUnvettedFundStrategyId,
          },
        ])
      }}
    >
      {({ inputValue }) =>
        map(
          concat(
            filter(options, (o) =>
              o.name.toLowerCase().includes(inputValue.toLowerCase())
            ),
            [
              {
                vettedFundStrategyId: ADD_NEW_ID,
                unvettedFundStrategyId: ADD_NEW_ID,
                name: '+ Add Write In',
              },
            ]
          ),
          (opt) => (
            <Option
              key={generateStrategyInputId(opt)}
              value={generateStrategyInputId(opt)}
            >
              {opt.name}
            </Option>
          )
        )
      }
    </Select.AutoComplete>
    {strategy.vettedFundStrategyId === ADD_NEW_ID && (
      <Input
        required
        label="New strategy name"
        value={strategy.name}
        onChange={(e) => onChange([{ field: 'name', value: e.target.value }])}
      />
    )}
    <IconButton
      className="justify-start mt-2"
      label="Remove"
      icon={<DeleteOutline className="text-red-100" />}
      variant="link"
      onClick={() => onRemove()}
    />
  </div>
)

const StrategiesField = ({ strategyOptions, strategies, onChange }) => {
  const addStrategy = () => {
    const newStrategies = produce(strategies, (newState) => {
      newState.push(getNewStrategyAmount())
    })
    onChange(newStrategies)
  }

  const removeStrategy = (index) => {
    const newStrategies = produce(strategies, (newState) => {
      newState.splice(index, 1)
    })

    onChange(newStrategies)
  }

  const handleStrategyChange = (index, changes) => {
    const newStrategies = produce(strategies, (newState) => {
      forEach(changes, (c) => {
        newState[index][c.field] = c.value
      })
    })

    onChange(newStrategies)
  }

  return (
    <div className="col-start-1 col-span-2">
      {map(strategies, (strategy, index) => (
        <StrategyField
          label={`Strategy ${index + 1}`}
          options={strategyOptions}
          onAdd={addStrategy}
          onRemove={() => removeStrategy(index)}
          onChange={(changes) => handleStrategyChange(index, changes)}
          strategy={strategy}
        />
      ))}
      <Button
        variant="link"
        className="mt-4 justify-start"
        onClick={() => addStrategy()}
      >
        + Add Strategy
      </Button>
    </div>
  )
}

const StrategyDropdown = ({ fund = null, fundStrategies, handleChange }) => {
  return !isNil(fund.vettedFundId) && fund.vettedFundId !== ADD_NEW_ID ? (
    <>
      <DisabledLabel
        label="Fund sub strategy"
        value={fund.subStrategy}
        className="col-start-1"
      />
    </>
  ) : (
    <StrategiesField
      strategyOptions={fundStrategies}
      strategies={fund.strategies}
      onChange={(strategies) =>
        handleChange([{ field: 'strategies', value: strategies }])
      }
    />
  )
}

StrategyDropdown.propTypes = {
  fund: PropTypes.object,
  fundStrategies: PropTypes.array,
  handleChange: PropTypes.func,
}

export default StrategyDropdown
