import http from '../utils/api'

export const getContinents = () => http.get('/on-boarding/continents')

export const getCountries = (continent) =>
  http.get(`/on-boarding/continents/${continent}/countries`)

export const getCities = (stateProvinceId) =>
  http.get(`/on-boarding/statesProvinces/${stateProvinceId}/cities`)

export const getByZip = (zipCode, country) =>
  http.get(`/locations/countries/${country}/zipcode/${zipCode}`)

export const getAllCountries = () => http.get(`/locations/countries`)
