import PropTypes from 'prop-types'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import './TrashButton.less'

const TrashButton = ({ onClick, style }) => (
  <Button className="TrashButton" type="danger" onClick={onClick} style={style}>
    <FontAwesomeIcon className="trashIcon" icon={faTrashAlt} />
  </Button>
)

TrashButton.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
}

export default TrashButton
