import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import { Button, IconButton } from '@context365/button'
import { DatePicker, Input, TimePicker } from '@context365/forms'
import { Add, DeleteOutline, Edit } from '@context365/icons'
import { Divider } from 'antd'
import produce from 'immer'
import map from 'lodash/map'
import moment from 'moment-timezone'
import ArrayItemHeading from './ArrayItemHeading'
import Documents from './Documents'

const generateEmptyBoardMeeting = () => ({
  meetingDateTime: null,
  title: null,
  livestreamLink: null,
  documents: [],
  isEdit: true,
})

const EditBoardMeeting = ({
  heading,
  title,
  meetingDateTime,
  livestreamLink,
  documents,
  documentCategories,
  onFieldChange,
  onSave,
  onRemove,
}) => {
  const meetingDateTimeValue = meetingDateTime
    ? new Date(meetingDateTime)
    : null

  return (
    <>
      <ArrayItemHeading heading={heading} onRemove={onRemove} />
      <div className="grid grid-cols-2 gap-6 py-4">
        <DatePicker
          required
          label="Meeting date (UTC)"
          value={meetingDateTimeValue}
          onChange={(newDate) => {
            onFieldChange('meetingDateTime', newDate.toDateString())
          }}
        />
        <TimePicker
          label="Meeting time (UTC)"
          value={meetingDateTimeValue}
          disabled={!meetingDateTime}
          forDate={meetingDateTimeValue}
          twelveHour={true}
          onChange={(newTime) => {
            onFieldChange('meetingDateTime', newTime.toLocaleString())
          }}
        />
        <Input
          label="Livestream link or video"
          value={livestreamLink}
          onChange={(e) => onFieldChange('livestreamLink', e.target.value)}
        />
        <Input
          label="Meeting title (optional)"
          value={title}
          onChange={(e) => onFieldChange('title', e.target.value)}
        />
        <div className="col-span-2 pl-16">
          <Documents
            documents={documents}
            updateDocuments={(newDocuments) =>
              onFieldChange('documents', newDocuments)
            }
            documentCategories={documentCategories}
            showEmptyEdit={false}
          />
        </div>
        <div className="col-span-2 mt-4">
          <Button variant="filled" onClick={() => onSave()}>
            Close Meeting Editor
          </Button>
        </div>
      </div>
    </>
  )
}

const ViewBoardMeeting = ({ title, meetingDateTime, onRemove, onEdit }) => (
  <div className="space-x-4">
    <IconButton
      icon={<DeleteOutline className="text-red-110" />}
      label="delete"
      variant="link"
      onClick={() => onRemove()}
    />
    <IconButton
      icon={<Edit className="text-primary-110" />}
      label="edit"
      variant="link"
      onClick={() => onEdit()}
    />
    <span>{title ?? 'No title'}</span>
    {meetingDateTime && (
      <span>{moment.utc(meetingDateTime).format('MMMM D, yyyy h:mm A z')}</span>
    )}
    {meetingDateTime && (
      <Badge>
        {moment.utc(meetingDateTime).isBefore(moment()) ? 'Past' : 'Upcoming'}
      </Badge>
    )}
  </div>
)

const BoardMeetings = ({
  boardMeetings,
  updateBoardMeetings,
  documentCategories,
}) => {
  const handleAddBoardMeeting = () => {
    const newBoardMeetings = produce(boardMeetings, (newState) => {
      if (newState.length === 0) {
        newState.push(generateEmptyBoardMeeting())
      }
      newState.push(generateEmptyBoardMeeting())
    })
    updateBoardMeetings(newBoardMeetings)
  }

  const handleRemoveBoardMeeting = (index) => {
    const newBoardMeetings = produce(boardMeetings, (newState) => {
      newState.splice(index, 1)
    })
    updateBoardMeetings(newBoardMeetings)
  }

  const handleEditBoardMeeting = (index, key, value) => {
    const newBoardMeetings = produce(boardMeetings, (newState) => {
      const currentItem = newState[index]
      if (currentItem) {
        currentItem[key] = value
      } else {
        newState.push({ ...generateEmptyBoardMeeting(), [key]: value })
      }
    })
    updateBoardMeetings(newBoardMeetings)
  }

  const hasNoItems = boardMeetings.length === 0
  const boardMeetingIteratees = hasNoItems
    ? [generateEmptyBoardMeeting()]
    : boardMeetings

  return (
    <div>
      {map(boardMeetingIteratees, (boardMeeting, index) => (
        <div className="py-2">
          {boardMeeting.isEdit ? (
            <EditBoardMeeting
              {...boardMeeting}
              heading={`Meeting ${index + 1}`}
              documentCategories={documentCategories}
              onFieldChange={(key, value) =>
                handleEditBoardMeeting(index, key, value)
              }
              onSave={() => handleEditBoardMeeting(index, 'isEdit', false)}
              onRemove={
                hasNoItems ? null : () => handleRemoveBoardMeeting(index)
              }
            />
          ) : (
            <ViewBoardMeeting
              {...boardMeeting}
              onEdit={() => handleEditBoardMeeting(index, 'isEdit', true)}
              onRemove={() => handleRemoveBoardMeeting(index)}
            />
          )}
        </div>
      ))}
      <Divider className="col-span-2" style={{ marginTop: 40 }} />
      <Button
        variant="link"
        size="small"
        onClick={() => handleAddBoardMeeting()}
      >
        <Add size="small" /> Add Meeting
      </Button>
    </div>
  )
}

BoardMeetings.propTypes = {
  boardMeetings: PropTypes.array.isRequired,
  updateBoardMeetings: PropTypes.func.isRequired,
  documentCategories: PropTypes.array.isRequired,
}

export default BoardMeetings
