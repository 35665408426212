import { useEffect, useState } from 'react'
import { Empty, Spin } from 'antd'
import axios from 'axios'
import isNil from 'lodash/isNil'
import http from '../../utils/api'
import CompanyEventDetails from '../CompanyEventDetails/CompanyEventDetails'
import './OnlineDetailsContainer.less'

const OnlineDetailsContainer = ({
  match: {
    params: { type, recordId, tab },
  },
  history: { goBack },
}) => {
  const [attendingCompanyEvent, setAttendingCompanyEvent] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  // const selectedEventParticipantId = parseInt(eventParticipantId, 10);
  // const selectedFundEventId = parseInt(fundEventId, 10);
  const [selectedRecordId, setSelectedRecordId] = useState(
    parseInt(recordId, 10)
  )

  const [refreshTable, setRefreshTable] = useState(false)

  const changeRefresh = (value) => {
    setRefreshTable(!refreshTable)
  }

  useEffect(() => {
    const itemId = parseInt(selectedRecordId, 10)

    http
      .get(`online/${type}/${itemId}`)
      .then((response) => {
        setAttendingCompanyEvent(response.data)
      })
      .finally(function () {
        setIsLoading(false)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [refreshTable])

  if (isNil(attendingCompanyEvent)) {
    return (
      <Spin spinning={isLoading}>
        <Empty />
      </Spin>
    )
  }

  return (
    <CompanyEventDetails
      style={{ padding: '50px' }}
      refresh={changeRefresh}
      refreshMeetings={refreshTable}
      selectedEventCompany={attendingCompanyEvent}
      defaultTab={tab}
      back={goBack}
      type={type}
      recordId={selectedRecordId}
      logType={'onlineCompany'}
      // push={() => push(`/summits/:eventId`)}
    />
  )
}

export default OnlineDetailsContainer
