import { useState } from 'react'
import PropTypes from 'prop-types'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row, message } from 'antd'
import { addEventContent, getEventContentList } from '../../actions/events'
import { getSponsorContentEventColumns } from '../../utils/columns'
import ContextTable from '../ContextTable/ContextTable'
import SponsorContentEventModal from '../SponsorContentEventModal/SponsorContentEventModal'

const SponsorContentEvent = ({ eventId }) => {
  const [refresh, setRefresh] = useState(false)

  const [modalVisible, setModalVisible] = useState(false)

  const refreshData = () => {
    setRefresh(!refresh)
  }

  const columns = getSponsorContentEventColumns(refreshData)

  const onSubmit = (content) => {
    addEventContent(eventId, content)
      .then((res) => {
        if (res.status === 200) {
          message.success('Content created successfully.')
          setModalVisible(false)
          refreshData()
        } else {
          message.error('An error has occurred.')
        }
      })
      .catch(() => {
        message.error('An error has occurred.')
      })
  }

  return (
    <>
      <Row style={{ paddingBottom: '16px' }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            size="large"
            onClick={() => setModalVisible(true)}
          >
            <span>
              <FontAwesomeIcon icon={faPlus} />
              {'  '}Add Content
            </span>
          </Button>
        </Col>
      </Row>
      <SponsorContentEventModal
        visible={modalVisible}
        handleOk={onSubmit}
        handleCancel={() => setModalVisible(false)}
      />
      <ContextTable
        tableTitle="Sponsor Content"
        columns={columns}
        eventId={eventId}
        dataMethod={getEventContentList}
        refresh={refresh}
      />
    </>
  )
}

SponsorContentEvent.propTypes = {
  eventId: PropTypes.number,
}

export default SponsorContentEvent
