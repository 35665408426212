import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SeatsioSeatingChart } from '@seatsio/seatsio-react'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import { getEventKey } from '../../actions/events'
import { REACT_APP_SEATS_PUBLIC_KEY } from '../../config'
import LoadingGif from '../LoadingGif'

const SeatsSelector = ({
  eventId,
  onSeatSelected,
  companiesAndBooths,
  allowSelectingBooked,
  currentSeat,
}) => {
  const [eventKey, setEventKey] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    getEventKey(eventId)
      .then((response) => {
        setEventKey(response.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [eventId])

  const handleSeatSelected = (x) => {
    if (!allowSelectingBooked) {
      if (x.selectable === false) return
    }
    if (isFunction(onSeatSelected)) onSeatSelected(x)
  }
  const handleSeatDeselected = () => {
    if (isFunction(onSeatSelected)) onSeatSelected(null)
  }
  const handleSeatClicked = (x) => {
    if (
      allowSelectingBooked &&
      x.status === 'booked' &&
      isFunction(onSeatSelected)
    )
      onSeatSelected(x)
  }

  return (
    <LoadingGif spinning={loading}>
      {eventKey &&
        !isNil(REACT_APP_SEATS_PUBLIC_KEY) &&
        !isEmpty(REACT_APP_SEATS_PUBLIC_KEY) && (
          <SeatsioSeatingChart
            onObjectSelected={handleSeatSelected}
            onObjectDeselected={handleSeatDeselected}
            onObjectClicked={handleSeatClicked}
            publicKey={REACT_APP_SEATS_PUBLIC_KEY}
            event={eventKey}
            className="seatChart"
            tooltipInfo={(object) => {
              if (currentSeat && object.id === currentSeat) return 'Your booth'
              else {
                const companyName = companiesAndBooths?.find((x) =>
                  x.boothId.split(',').includes(object.id)
                )?.company.name

                return companyName
              }
            }}
            maxSelectedObjects="1"
            objectLabel={(object) => object.labels.own}
            objectColor={(object, defaultColor, extraConfig) => {
              if (object.id === extraConfig.currentSeat) return '#2fc5d2'
              else if (
                object.status === 'free' ||
                extraConfig.companiesAndBooths === undefined ||
                extraConfig.companiesAndBooths === null ||
                extraConfig.companiesAndBooths?.find((x) =>
                  x.boothId.split(',').includes(object.id)
                )
              )
                return defaultColor
              else return '#24C477'
            }}
            extraConfig={{ currentSeat, companiesAndBooths }}
            region="eu"
          />
        )}
    </LoadingGif>
  )
}
SeatsSelector.propTypes = {
  eventId: PropTypes.number.isRequired,
  onSeatSelected: PropTypes.func.isRequired,
  companiesAndBooths: PropTypes.array.isRequired,
  currentSeat: PropTypes.string,
  allowSelectingBooked: PropTypes.bool,
}
export default SeatsSelector
