import { useContext, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import MandateWizardCtx from '../../utils/contexts/MandateWizardCtx'
import StepList from '../Forms/StepList'
import InitialStep from './Steps/InitialStep'
import Step from './Steps/Step'
import './MandateWizard.less'

const STEPS = [
  { title: 'Mandate Overview', stepNumber: 1 },
  {
    title: 'Mandate Details - Asset Management',
    stepNumber: 2,
    alertMessage:
      'Marking a field as required will prevent funds from applying to your mandate that do not meet your specific criteria. ' +
      'By leaving these fields unchecked, you are indicating that the field is a preference and not a hard requirement.',
  },
  { title: 'Plan Overview', stepNumber: 3 },
  { title: 'Documentation', stepNumber: 4 },
  { title: 'Consultants', stepNumber: 5 },
  { title: 'Updates', stepNumber: 6 },
  { title: 'Notifications', stepNumber: 7 },
]

const MandateWizard = () => {
  const {
    otherStepQuestions,
    currentStep,
    setCurrentStep,
    otherStepAnswers,
    invalidAnswers,
    setOtherStepAnswers,
    stepNumbers,
    handleNextStep,
  } = useContext(MandateWizardCtx)
  const [invalidSteps, setInvalidSteps] = useState([])
  const filteredSteps = STEPS.filter((step) =>
    stepNumbers.includes(step.stepNumber)
  )

  useEffect(() => {
    const invalidAnswerSteps = [
      ...invalidAnswers.map(
        (answer) => stepNumbers.indexOf(answer.stepNumber) + 1
      ),
    ]
    setInvalidSteps([...new Set([...invalidAnswerSteps])])
  }, [invalidAnswers, stepNumbers])

  return (
    <div className="MandateWizard">
      {(currentStep !== 0 && (
        <>
          <StepList
            className="MandateWizard-steps"
            steps={filteredSteps.map((step) => step.title)}
            current={currentStep}
            onStepChange={(step) => setCurrentStep(step)}
            stepsInError={invalidSteps}
          />
          <Step
            title={filteredSteps[currentStep].title}
            alertMessage={filteredSteps[currentStep].alertMessage}
            questions={otherStepQuestions.filter(
              (question) =>
                question.stepNumber === filteredSteps[currentStep].stepNumber
            )}
            answers={otherStepAnswers}
            onSetAnswers={setOtherStepAnswers}
            onNextStep={handleNextStep}
            onPrevStep={() =>
              setCurrentStep((prevCurrentStep) => prevCurrentStep - 1)
            }
            isLast={currentStep === filteredSteps.length - 1}
          />
        </>
      )) || <InitialStep title="Mandate Overview" />}
    </div>
  )
}

export default MandateWizard
