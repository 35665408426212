import { Input } from 'antd'
import './SearchBox.less'

const { Search } = Input

const SearchBox = (props) => {
  return (
    <Search
      placeholder="Search"
      onSearch={props.onSearch}
      allowClear={props.allowClear}
      size="large"
      className={`${props.className} search-input`} //props.className ? props.className : 'search-input'}
    />
  )
}

export default SearchBox
