import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Modal,
  Radio,
  Row,
  Select,
  Steps,
  message,
} from 'antd'
import FloatingInput from '../../components/FloatingInput'
import http from '../../utils/api'

const NewContactModal = ({
  visible,
  handleCancel,
  companyId,
  userType,
  refresh,
}) => {
  const [current, setCurrentStep] = useState(0)
  const [allocators, setAllocators] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [selectedManagers, setSelectedManagers] = useState([
    {
      contactId: 0,
      firstName: '',
      lastName: '',
      email: '',
      isAdministrator: false,
    },
  ])
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)

  const next = () => {
    setCurrentStep(current + 1)
  }

  const prev = () => {
    setCurrentStep(current - 1)
  }

  const handlePrevious = () => {
    if (current > 0) {
      prev()
    } else {
      setAllocators([])
      setFilteredUsers([])
      setSelectedManagers([
        {
          contactId: 0,
          firstName: '',
          lastName: '',
          email: '',
          isAdministrator: false,
        },
      ])
      handleCancel()
      setSubmitButtonDisabled(true)
    }
  }

  const handleNext = (event) => {
    event.preventDefault()
    if (current == 1) {
      handleCancel()
    } else {
      next()
    }
  }

  const newEmptyFields = () => () => {
    var newManager = {
      contactId: 0,
      firstName: '',
      lastName: '',
      email: '',
      isAdministrator: false,
    }
    setSelectedManagers([...selectedManagers, newManager])
    setSubmitButtonDisabled(true)
  }

  const checkButtonStatus = (newManager) => {
    setSubmitButtonDisabled(false)
    console.log(newManager)
    if (newManager)
      newManager.map((value) => {
        if (value.email === '') setSubmitButtonDisabled(true)
      })
  }

  const handleDelete = (idx) => () => {
    var manager = selectedManagers.filter((s, sidx) => idx !== sidx)

    if (manager.length === 0) {
      manager = [
        {
          contactId: 0,
          firstName: '',
          lastName: '',
          email: '',
          isAdministrator: false,
        },
      ]
    }
    setSelectedManagers(manager)
    setSubmitButtonDisabled(true)
  }

  const handleSearch = (value) => {
    value = value.toLowerCase()
    if (value.length > 2) {
      var searched = allocators.filter((obj) =>
        (obj.firstName + ' ' + obj.lastName).toLowerCase().includes(value)
      )
      var usersObject = []
      searched.map((item, key) => {
        usersObject.push({
          value: item.contactId,
          label: item.firstName + ' ' + item.lastName,
        })
      })
      setFilteredUsers(usersObject)
    }
  }

  const onChange = (value) => {
    var selectedUser = allocators.filter((obj) => obj.contactId === value)
    var checker = selectedManagers.filter((obj) => obj.contactId === value)
    let newManager = {
      contactId: 0,
      firstName: '',
      lastName: '',
      email: '',
      isAdministrator: false,
    }
    if (checker.length == 0) {
      if (selectedUser.length == 1) {
        if (selectedManagers.length == 1) {
          if (selectedManagers[0].firstName == '') {
            newManager = [
              ...selectedManagers.filter((s, sidx) => 0 !== sidx),
              //...selectedManagers,
              selectedUser[0],
            ]
            setSelectedManagers(newManager)
          } else {
            newManager = [...selectedManagers, selectedUser[0]]
            setSelectedManagers(newManager)
          }
        } else {
          newManager = [...selectedManagers, selectedUser[0]]
          setSelectedManagers(newManager)
        }
      }
    }
    checkButtonStatus(newManager)
  }

  const updateFirstName = (idx) => (evt) => {
    if (selectedManagers.filter((s, sidx) => idx === sidx)[0].contactId === 0) {
      setSelectedManagers(selectedManagers.filter((s, sidx) => idx === sidx)[0])
      const newSelectedManagers = selectedManagers.map((manager, sidx) => {
        if (idx !== sidx) return manager
        return { ...manager, firstName: evt }
      })
      setSelectedManagers(newSelectedManagers)
    }
  }

  const updateLastName = (idx) => (evt) => {
    if (selectedManagers.filter((s, sidx) => idx === sidx)[0].contactId === 0) {
      setSelectedManagers(selectedManagers.filter((s, sidx) => idx === sidx)[0])
      const newSelectedManagers = selectedManagers.map((manager, sidx) => {
        if (idx !== sidx) return manager
        return { ...manager, lastName: evt }
      })
      setSelectedManagers(newSelectedManagers)
    }
  }

  const updateEmail = (idx) => (evt) => {
    if (selectedManagers.filter((s, sidx) => idx === sidx)[0].contactId === 0) {
      if (evt !== '') setSubmitButtonDisabled(false)
      else setSubmitButtonDisabled(true)
      setSelectedManagers(selectedManagers.filter((s, sidx) => idx === sidx)[0])
      const newSelectedManagers = selectedManagers.map((manager, sidx) => {
        if (idx !== sidx) return manager
        return { ...manager, email: evt }
      })
      setSelectedManagers(newSelectedManagers)
    }
  }

  const handleCheck = (idx) => (evt) => {
    setSelectedManagers(selectedManagers.filter((s, sidx) => idx === sidx)[0])
    const newSelectedManagers = selectedManagers.map((manager, sidx) => {
      if (idx !== sidx) return manager
      return { ...manager, isAdministrator: evt.target.checked }
    })
    setSelectedManagers(newSelectedManagers)
  }

  const handleOnClick = () => {
    if (allocators.length === 0) {
      let url = ''

      if (userType == 'fundmanagers') {
        url = 'funds/managers/fundcompanies'
      } else if (userType == 'serviceproviders') {
        url = 'funds/managers/serviceproviders'
      } else if (userType == 'mediaprofiles') {
        url = 'funds/managers/mediaprofile'
      } else {
        url = 'funds/managers/allocators'
      }

      http.get(url).then((response) => {
        var usersObject = []
        setAllocators(response.data)
        response.data.map((item, key) => {
          usersObject.push({
            value: item.contactId,
            label: item.firstName + ' ' + item.lastName,
          })
        })
        setFilteredUsers(usersObject.slice(0, 20))
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    var NewContactSubmit = {
      CompanyId: companyId,
      Contacts: [],
    }
    selectedManagers.map((manager) => {
      NewContactSubmit.Contacts.push({
        ContactId: manager.contactId,
        FirstName: manager.firstName,
        LastName: manager.lastName,
        Email: manager.email,
        IsAdministrator: manager.isAdministrator,
      })
    })

    http
      .post('companies/newcontacts', NewContactSubmit)
      .then((response) => {
        if (response.status == 200) {
          message.success('Successfully added users to the company!')
          setAllocators([])
          setFilteredUsers([])
          setSelectedManagers([])
          handleCancel()
        } else {
          message.error('Something went wrong, please try again!')
          console.log(response)
        }
      })
      .finally(() => refresh())
  }

  return (
    <Modal
      visible={visible}
      width="628px"
      title="Add new user"
      bodyStyle={{ padding: 0 }}
      onCancel={handlePrevious}
      footer={[
        <Button onClick={handlePrevious} className="cancelBtn">
          Close
        </Button>,
        <Button
          type="primary"
          disabled={submitButtonDisabled}
          onClick={handleSubmit}
        >
          Add User
        </Button>,
      ]}
    >
      <div style={{ padding: '13px 16px 32px 32px' }}>
        <div>
          {selectedManagers.map((selectedManager, idx) => (
            <Row>
              <Col span={12}>
                <FloatingInput
                  value={selectedManager.firstName}
                  name="First Name"
                  nameField="FirstName"
                  onChange={updateFirstName(idx)}
                />
              </Col>
              <Col span={12}>
                <FloatingInput
                  value={selectedManager.lastName}
                  name="Last Name"
                  nameField="LastName"
                  onChange={updateLastName(idx)}
                />
              </Col>
              <Col span={24}>
                <FloatingInput
                  value={selectedManager.email}
                  name="Email Address"
                  nameField="Email"
                  onChange={updateEmail(idx)}
                />
              </Col>

              <Col span={10} style={{ fontSize: '14px' }}>
                <Checkbox onChange={handleCheck(idx)}>
                  Administrator Access
                </Checkbox>
              </Col>
              <Col
                span={8}
                offset={6}
                style={{ textAlign: 'right', fontSize: '14px' }}
              >
                <Button
                  style={{
                    border: '0',
                    color: '#D62B34',
                    fontWeight: '600',
                    height: '21px',
                  }}
                  onClick={handleDelete(idx)}
                >
                  Delete
                </Button>
              </Col>

              <Col span={24}>
                <Divider style={{ marginTop: '16px' }} />
              </Col>
            </Row>
          ))}
          <Row>
            <Col span={9}>
              <Button className="buttonRectangle" onClick={newEmptyFields()}>
                <span className="buttonLabel">Add Another User</span>
              </Button>
            </Col>
            <Col span={9} offset={6}>
              <Select
                showSearch
                className="searchbox"
                placeholder="+ Add Existing User"
                optionFilterProp="children"
                filterOption={false}
                onSelect={onChange}
                onSearch={handleSearch}
                value="+ Add Existing User"
                onDropdownVisibleChange={handleOnClick}
                showArrow={false}
              >
                {filteredUsers.map((user) => {
                  return (
                    <Select.Option value={user.value}>
                      {user.label}
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}
NewContactModal.defaultProps = {
  isFundManager: false,
  funds: [],
}
NewContactModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  refresh: PropTypes.func,
}

export default NewContactModal
