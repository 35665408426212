import PropTypes from 'prop-types'
import { Divider } from 'antd'
import { filter, map } from 'lodash'
import FloatingTextArea from '../FloatingTextArea'
import LoadingGif from '../LoadingGif'

const MeetingQuestionsForm = ({
  loadingQuestions,
  questionsForFund,
  answers,
  setAnswers,
}) => (
  <LoadingGif spinning={loadingQuestions}>
    <div className="cc-tabletitle-text cc-darkest-grey-text">
      Answer these questions
    </div>
    <Divider style={{ margin: '16px 0px' }} />
    <div>
      {map(questionsForFund, (q) => (
        <FloatingTextArea
          key={q.meetingQuestionId}
          name={q.question}
          nameField={q.meetingQuestionId}
          onChange={(value) => {
            const unchangedAnswers = filter(
              answers,
              (a) => a.meetingQuestionId !== q.meetingQuestionId
            )
            const changedAnswers = [
              ...unchangedAnswers,
              { meetingQuestionId: q.meetingQuestionId, answer: value },
            ]
            setAnswers(changedAnswers)
          }}
        />
      ))}
    </div>
  </LoadingGif>
)

MeetingQuestionsForm.propTypes = {
  loadingQuestions: PropTypes.bool.isRequired,
  questionsForFund: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  setAnswers: PropTypes.func.isRequired,
}
export default MeetingQuestionsForm
