import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import isNil from 'lodash/isNil'
import './EmptyState.less'

const EmptyState = ({ message, icon = null, action = null }) => {
  const buttonComponent = isNil(action) ? null : (
    <div className="EmptyState-action">
      <Button
        onClick={isNil(action.click) ? null : action.click}
        type={isNil(action.type) ? 'primary' : action.type}
      >
        {isNil(action.icon) ? null : action.icon}
        <span className="EmptyState-action-label">{action.label}</span>
      </Button>
    </div>
  )

  return (
    <div className="EmptyState">
      <h4 className="EmptyState-message">{message}</h4>
      {buttonComponent}
    </div>
  )
}

EmptyState.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.object,
  button: PropTypes.elementType,
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.object,
    click: PropTypes.func,
    type: PropTypes.oneOf(['default', 'primary', 'dashed', 'danger', 'link']),
  }),
}

export default EmptyState
