import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Layout, Row, Spin, Table } from 'antd'
import { isNil } from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import loaderImage from '../../assets/context_loader.gif'
import { transformColumns, transformPagination } from '../../utils/contextTable'
import TableHeader from '../TableHeader'
import AgendaDayDetails from './AgendaDayDetails'
import './ScheduleTable.less'

export const CCTableLinkCell = 'CCTABLE_LINK_CELL'
export const CCTableContactLinkCell = 'CCTABLE_CONTACT_LINK_CELL'
export const CCTableTagGroupCell = 'CCTABLE_TAG_GROUP_CELL'
export const CCTableActionCell = 'CCTABLE_ACTION_CELL'
export const CCTableProfilePicsCell = 'CCTABLE_PROFILE_PICS_CELL'
export const CCTableDateTimeCell = 'CCTABLE_DATETIME_CELL'
export const CCTableCurrencyCell = 'CCTABLE_CURRENCY_CELL'
export const CCTableYesNoCell = 'CCTABLE_YESNO_CELL'
export const CCTablePercentageCell = 'CCTABLE_PERCENTAGE_CELL'
export const CCTableDollarChangeCell = 'CCTABLE_DOLLAR_CHANGE_CELL'
export const CCTableLambdaCell = 'CCTABLE_LAMBDA_CELL'

const ScheduleTable = ({
  scroll,
  pagination,
  tableTitle,
  columns,
  dataSource,
  dataMethod,
  fetchMethod,
  onTableChanged,
  refresh,
  refreshData = false,
  rowSelected,
  clickEvent,
  rowClassName,
  searchVisible,
  displayAddButton = false,
  eventId,
  handleDataRefresh,
  speakerRoles,
  eventTimezone,
}) => {
  const [data, setData] = useState(null)
  const [tablePagination, setTablePagination] = useState({})
  const [tableParameters, setTableParameters] = useState({
    pagination: {},
    filters: {},
    sorter: {},
    searchTerm: {},
  })
  const [loadData, setLoadData] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [internalRefresh, setInternalRefresh] = useState(false)
  const [spinnerLoading, setSpinnerLoading] = useState(false)

  const [showNewAgendaModal, setShowNewAgendaModal] = useState(false)
  const [agendaId, setAgendaId] = useState(0)
  const [agenda, setAgenda] = useState(null)

  const { Header } = Layout
  if (isNil(dataSource) && loadData) {
    dataMethod(
      tableParameters.pagination,
      tableParameters.filters,
      tableParameters.sorter,
      tableParameters.searchTerm
    ).then((response) => {
      if (!isNil(onTableChanged)) onTableChanged(response.data.result)

      setLoadData(false)
      setData(response.data.result.result)
      setTablePagination(response.data.result)
    })
  }

  if (!isNil(dataSource) && !isNil(fetchMethod) && loadData) {
    fetchMethod(
      tableParameters.pagination,
      tableParameters.filters,
      tableParameters.sorter,
      tableParameters.searchTerm
    )

    setLoadData(false)
    setSpinnerLoading(true)
  }

  useEffect(() => {
    setLoadData(true)
  }, [refreshData])

  useEffect(() => {
    if (!isNil(data) || !isNil(dataSource)) {
      getColumnFilters()
      setSpinnerLoading(false)
      setLoadData(false)
    }
  }, [data, dataSource])

  const getColumnFilters = () => {
    const filteredColumns = columns.filter((x) => !isNil(x.filterMethod))
    if (!isNil(filteredColumns)) {
      filteredColumns.forEach((column) => {
        if (isNil(filterData[column.key])) {
          column.filterMethod().then((response) => {
            const filter = filterData
            if (!isNil(column.filterMappingMethod))
              filter[column.key] = response.data.result.map((x) =>
                column.filterMappingMethod(x)
              )
            else filter[column.key] = response.data.result

            setFilterData(filter)
            setInternalRefresh(moment())
          })
        }
      })
    }
  }

  const filteredColumns = columns.filter((x) => !isNil(x.filterMethod))
  if (!isNil(filteredColumns)) {
    filteredColumns.forEach((column) => {
      if (!isNil(filterData[column.key])) {
        column.filters = filterData[column.key]
      }
    })
  }

  columns = transformColumns(columns, rowSelected)
  pagination = false

  const tableChanged = (pagination, filters, sorter) => {
    const filterArray = {
      columns: [],
    }

    for (const propertyName in filters) {
      const column = columns.find(
        (x) => x.dataIndex === propertyName || x.key === propertyName
      )
      const { columnType } = column

      filterArray.columns.push({
        column: propertyName,
        values: filters[propertyName],
        columnType,
      })
    }

    pagination.total = null

    setTableParameters({
      pagination,
      filters: filterArray,
      sorter,
      searchTerm: tableParameters.searchTerm,
    })
    setLoadData(true)
  }

  const searchChanged = (searchTerm) => {
    setTableParameters({
      pagination: { pageSize: tableParameters.pagination.pageSize },
      filters: tableParameters.filters,
      sorter: tableParameters.sorter,
      searchTerm: {
        searchValue: searchTerm,
      },
    })
    setLoadData(true)
  }

  return (
    <>
      <Header
        style={{
          background: '#fff',
          padding: 0,
          boxShadow: '0px 8px 13px rgba(0, 0, 0, 0.04)',
        }}
      >
        <Row type="flex" align="middle">
          <div className="investors-header-col" style={{ padding: '20px' }}>
            <span className="table-header-text">{tableTitle}</span>
          </div>
          <Button
            block
            size="large"
            style={{
              background: '#2FC5D2',
              boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.06);',
              color: 'white',
              width: '190px',
            }}
            onClick={(e) => {
              e.stopPropagation()
              setShowNewAgendaModal(true)
            }}
          >
            <span style={{ background: '#2FC5D2', opacity: '1' }}>
              <FontAwesomeIcon icon={faPlus} /> Add Agenda
            </span>
          </Button>
        </Row>
      </Header>

      <Spin
        size="large"
        spinning={
          (isNil(data) && isNil(dataSource)) ||
          loadData ||
          spinnerLoading === true
        }
        indicator={
          <img
            style={{ marginLeft: '-50px', width: '100px', height: '100px' }}
            src={loaderImage}
          />
        }
      >
        <Table
          scroll={scroll}
          pagination={pagination}
          rowKey="id"
          columns={columns}
          dataSource={isNil(data) ? dataSource : data}
          rowClassName={rowClassName}
          onChange={tableChanged}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onDoubleClick: event => {
          //       record.startDate = moment
          //         .utc(record.startDate)
          //         .tz(eventTimezone);
          //       record.endDate = moment.utc(record.endDate).tz(eventTimezone);

          //       setAgendaId(record.eventAgendaId);
          //       setAgenda(record);
          //       setShowNewAgendaModal(true);
          //     },
          //   };
          // }}
        />
      </Spin>

      {showNewAgendaModal && (
        <AgendaDayDetails
          visible={true}
          agendaId={agendaId}
          eventId={eventId}
          agenda={agenda}
          handleCancel={() => {
            setShowNewAgendaModal(false)
            setAgendaId(0)
            setAgenda(null)
          }}
          handleOk={() => {
            setShowNewAgendaModal(false)
            setAgendaId(0)
            setAgenda(null)
          }}
          handleDataRefresh={handleDataRefresh}
          modalTitle={'Add Agenda'}
          speakerRoles={speakerRoles}
          eventTimezone={eventTimezone}
        />
      )}
    </>
  )
}
ScheduleTable.propTypes = {
  scroll: PropTypes.object,
  pagination: PropTypes.object,
  tableTitle: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataSource: PropTypes.array,
  fetchMethod: PropTypes.func,
  dataMethod: PropTypes.func,
  onTableChanged: PropTypes.func,
  refresh: PropTypes.bool,
  refreshData: PropTypes.bool,
  rowSelected: PropTypes.func,
  clickEvent: PropTypes.func,
}

export default ScheduleTable
