import { useContext } from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from 'antd'
import moment from 'moment'
import FormCtx from '../../../utils/contexts/FormCtx'
import useFormAnswers from '../../../utils/hooks/useFormAnswers'
import '../Fields.less'

const DateField = ({ question }) => {
  const { isEdit } = useContext(FormCtx)
  const { answer, handleTextAnswerChange } = useFormAnswers(question)
  const onChange = (date) => {
    handleTextAnswerChange(date)
  }

  const date =
    answer.responseText.length === 0
      ? {}
      : { value: moment(answer.responseText) }

  const disable =
    question.attributes.canEdit === 'false' && isEdit
      ? {
          disabled: true,
        }
      : {
          disabled: false,
        }

  return (
    <>
      {question.text && <div className="Text">{question.text}</div>}
      <DatePicker
        className="DateField"
        name={question.name}
        {...disable}
        {...date}
        onChange={(_, dateString) => onChange(dateString)}
      />
    </>
  )
}

DateField.propTypes = {
  question: PropTypes.object,
}

export default DateField
