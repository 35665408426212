import http from '../utils/api'

export const getAllocatorTopMatches = (
  companyContactId,
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post(`allocators/topmatches/${companyContactId}`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getAllocatorMutualInterest = (
  companyContactId,
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post(`allocators/mutualinterest/${companyContactId}`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getInvestorQualificationTypes = () =>
  http.get(`allocators/InvestorQualificationTypes`)

export const getAllocatorWizardOptions = () =>
  http.get(`allocators/options`).then((res) => res.data)

export const getAllocatorWizardAlternativeCategories = (companyId) =>
  http
    .get(`allocators/options/${companyId}/alternativeCategories`)
    .then((res) => res.data)

export const getAllocatorWizard = (companyId) =>
  http.get(`allocators/${companyId}/wizard`).then((res) => res.data)

export const saveAllocationWizard = (companyId, company) =>
  http.patch(`allocators/${companyId}/wizard`, company)

export const saveAllocatorNote = (companyId, note) =>
  http.patch(`allocators/${companyId}/note`, note)

export const getAllocatorNote = (companyId, tab) =>
  http.get(`allocators/${companyId}/tab/${tab}/note`).then((res) => res.data)
