import PropTypes from 'prop-types'
import { InputNumber } from 'antd'
import useFormAnswers from '../../../utils/hooks/useFormAnswers'
import RequirableField from './RequirableField'
import '../Fields.less'

const InputNumberField = ({ question }) => {
  const {
    answer,
    handleTextAnswerChange,
    isRequiredForApplication,
    handleRequirementChange,
  } = useFormAnswers(question)

  const formatting =
    question.attributes.formatter === 'percentage'
      ? {
          formatter: (value) => `${value}%`,
          parser: (value) => value.replace('%', ''),
        }
      : question.attributes.formatter === 'currency'
      ? {
          formatter: (value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        }
      : {
          formatter: (value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        }
  return (
    <RequirableField
      allowRequirement={question.allowRequirementSelection}
      checked={isRequiredForApplication}
      onChange={handleRequirementChange}
      containerStyle={{ alignItems: 'center' }}
    >
      <InputNumber
        className="InputField InputField-number"
        name={question.name}
        value={answer.responseText}
        {...formatting}
        onChange={(e) => handleTextAnswerChange(e)}
      />
    </RequirableField>
  )
}

InputNumberField.propTypes = {
  question: PropTypes.shape({
    name: PropTypes.string,
    shortName: PropTypes.string,
    isRequired: PropTypes.bool,
    allowRequirementSelection: PropTypes.bool,
    attributes: PropTypes.shape({ formatter: PropTypes.string }),
  }),
}

export default InputNumberField
