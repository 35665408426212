import PropTypes from 'prop-types'
import FloatingInput from '../../FloatingInput'
import FloatingTextArea from '../../FloatingTextArea'

const TestimonialField = ({ item, onItemChanged, itemIndex }) => {
  return (
    <div className="TestimonialField-container">
      <div className="TestimonialField-fields-column">
        <div className="TestimonialField-fields-upper">
          <div className="TestimonialField-name">
            <FloatingInput
              onChange={(e) => {
                onItemChanged(itemIndex, 'fullName', e)
              }}
              value={item.fullName}
              name="Full Name"
            />
          </div>
          <div className="TestimonialField-position">
            <FloatingInput
              onChange={(e) => {
                onItemChanged(itemIndex, 'jobPosition', e)
              }}
              value={item.jobPosition}
              name="Job Position"
            />
          </div>
        </div>
        <div className="TestimonialField-fields-upper">
          <div className="TestimonialField-title">
            <FloatingInput
              onChange={(e) => {
                onItemChanged(itemIndex, 'title', e)
              }}
              value={item.title}
              name="Testimonial Title"
            />
          </div>
        </div>
        <div className="TestimonialField-description">
          <FloatingTextArea
            onChange={(e) => {
              onItemChanged(itemIndex, 'description', e)
            }}
            value={item.description}
            name="Testimonial"
          />
        </div>
      </div>
    </div>
  )
}

TestimonialField.propTypes = {
  item: PropTypes.object.isRequired,
  onItemChanged: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
}
export default TestimonialField
