import http from '../utils/api'

export const getDealsList = (pagination, filters, sorter, searchTerm) =>
  http.post('/deals/list', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getDealsActivityGraphList = (dealId) => (startDate, endDate) =>
  http.post(
    `/deals/allActivities/${dealId}?startDate=${startDate}&endDate=${endDate}`
  )

export const getDealsActivityList =
  (dealId) =>
  (startDate, endDate) =>
  (pagination, filters, sorter, searchTerm) =>
    http.post(
      `/deals/activities/${dealId}?startDate=${startDate}&endDate=${endDate}`,
      {
        pagination,
        filters,
        sorter,
        searchTerm,
      }
    )

export const getDealActivityDetailsList =
  (dealId, date, isPdfView, isMeetingReceived) =>
  (pagination, filters, sorter, searchTerm) =>
    http.post(
      `/deals/activities/details/${dealId}?date=${date}&isPdfView=${isPdfView}&isMeetingReceived=${isMeetingReceived}`,
      {
        pagination,
        filters,
        sorter,
        searchTerm,
      }
    )

export const getDealsByCompanyList =
  (companyId) => (pagination, filters, sorter, searchTerm) =>
    http.post(`/deals/list/${companyId}`, {
      pagination,
      filters,
      sorter,
      searchTerm,
    })
