import { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faClock, faUserClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Dropdown, Menu, Modal, Radio, Spin, Switch } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment-timezone'
import { getEventSchedule, setAvailability } from '../../actions/events'
import { newCancelMeeting } from '../../actions/meetings'
import http from '../../utils/api'
import CCTable from '../CCTable/CCTable'
import ContactName from '../ContactName'
import MessageModalPast from '../MessageModal/MessageModalPast'
import ProfilePictureGroup from '../ProfilePictureGroup'
import RescheduleEventMeeting from '../RescheduleEventMeeting/RescheduleEventMeeting'
import ScheduleTable from '../ScheduleTable/ScheduleTable'
import TimeDisplay from '../TimeDisplay'
import './ScheduleDay.less'

const ScheduleDay = ({
  eventDate,
  // timeSlots,
  companyEventId,
  eventId,
  refreshSchedule,
  companyId,
  isPast,
}) => {
  const [timeSlot, setTimeSlot] = useState(null)
  const [cancelPopupVisible, setcancelPopupVisible] = useState(false)
  const [showRescheduleModal, setShowRescheduleModal] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [selectedMeetingId, setSelectedMeetingId] = useState(null)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [displayTimezone, setDisplayTimezone] = useState(TimeDisplay.ORIGINAL)
  const [displayTimezoneLabel, setDisplayTimezoneLabel] =
    useState('Event Timezone')
  const [currentCompanyContacts, setCurrentCompanyContacts] = useState({})
  const [checkValue, setCheckValue] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showSendMessageModal, setShowSendMessageModal] = useState(false)
  const [messageLoading, setMessageLoading] = useState(false)
  const [messageBody, setMessageBody] = useState('')
  useEffect(
    () => {
      console.log('Date in use effect' + eventDate)
      setLoading(true)
      getEventSchedule(
        eventId,
        companyEventId,
        eventDate,
        moment.tz.guess()
      ).then((response) => {
        setTimeSlot(response.data[0].items)
        setLoading(false)
      })
    },
    [eventDate],
    [refresh]
  )
  useEffect(
    () => {
      http
        .get(`companies/event/contacts/${companyEventId}`)
        .then((response) => setCurrentCompanyContacts(response.data.contacts))
    },
    [refresh],
    [companyEventId]
  )
  const renderTime = (eventSlotTime, record) => {
    if (record.type === 'grouping') return null
    // return moment(record.eventSlotTime).format('h:mm A');
    return (
      <TimeDisplay
        time={eventSlotTime}
        timezone={record.timeZone}
        displayInTimezone={displayTimezone}
        displayFormat="h:mm A"
      />
    )
  }
  const renderTitleTime = (menu) => (
    <></>
    // <Dropdown overlay={menu}>
    //   <span>TIME ({displayTimezoneLabel})</span>
    // </Dropdown>
  )
  const getColumns = () => {
    const menu = (
      <Menu>
        <Menu.Item
          key={TimeDisplay.ORIGINAL}
          onClick={(e) => {
            setDisplayTimezone(e.key)
            setDisplayTimezoneLabel('Event Timezone')
          }}
        >
          <FontAwesomeIcon icon={faClock} />
          <span>
            Show in <b>Event</b> Timezone
          </span>
        </Menu.Item>
        <Menu.Item
          key={TimeDisplay.LOCAL}
          onClick={(e) => {
            setDisplayTimezone(e.key)
            setDisplayTimezoneLabel('Local Timezone')
          }}
        >
          <FontAwesomeIcon icon={faUserClock} />
          <span>
            Show in your <b>Local</b> Timezone
          </span>
        </Menu.Item>
      </Menu>
    )

    return [
      {
        title: renderTitleTime(menu),
        dataIndex: 'eventSlotTime',
        render: renderTime,
        width: '25%',
      },
      {
        title: 'EVENT',
        render: renderEvent,
        width: '50%',
      },
      {
        title: 'STATUS',
        align: 'right',
        render: renderStatus,
        width: '25%',
      },
    ]
  }

  const renderEvent = (record) => {
    if (record.type === 'grouping')
      return (
        moment(eventDate).format('dddd').toUpperCase() + ' ' + record.groupName
      )
    else {
      if (record.meeting !== null) return getMeeting(record)
      else return record.details
    }
  }

  const getMeeting = (record) => (
    <div className="schedule-day-meeting">
      <span className="schedule-day-meeting-title cc-body-text">
        Meeting with:
      </span>
      <div className="schedule-day-meeting-image">
        <ProfilePictureGroup
          diameter={32}
          profiles={record.meeting.members.map((x) => ({
            name: x.contactName,
            firstName: x.firstName,
            lastName: x.lastName,
            image: x.imageURL,
          }))}
          maxImages="5"
        ></ProfilePictureGroup>
      </div>
      <div className="schedule-day-meeting-card">
        <span className="schedule-day-meeting-name">
          {record.meeting.company.companyName}
        </span>
        <span className="cc-small-font schedule-day-meeting-company">
          {record.meeting.company.companyName}
        </span>
        <span className="cc-small-font schedule-day-meeting-company">
          {record.meeting.meetingLocation &&
            'Location: ' + record.meeting.meetingLocation}
        </span>
      </div>
    </div>
  )

  const reschedule = (record) => {
    setShowRescheduleModal(true)
    setSelectedRecord(record.meeting)
  }
  const sendMessageModal = (record) => {
    setShowSendMessageModal(true)
    setSelectedRecord(record.meeting)
  }
  const cancelModal = (record) => {
    setcancelPopupVisible(true)
    setSelectedMeetingId(record.meeting.meetingId)
  }

  const onRadioChange = (e) => {
    setCheckValue(e.target.value)
  }

  const hideModal = () => {
    setCheckValue(null)
    setcancelPopupVisible(false)
  }

  const closeReschedule = () => {
    setCheckValue(null)
    setShowRescheduleModal(false)
  }

  const onCancelMeeting = () => {
    newCancelMeeting(selectedMeetingId).then(() => {
      hideModal()
      refreshSchedule()
    })
  }

  const handleRescheduleMeeting = () => {
    setCheckValue(null)
    setShowRescheduleModal(false)
    refreshSchedule()
  }

  const onAvailable = (record, e) => {
    setAvailability(eventId, companyId, record.meetingTimeId, e)
      .then(() => {
        const slots = timeSlot
        const changedSlot = slots.find(
          (x) => x.meetingTimeId === record.meetingTimeId
        )
        changedSlot.available = e
        setRefresh(!refresh)
        setTimeSlot(slots)
      })
      .catch(() => alert('unable to save'))
  }

  const renderStatus = (record) => {
    if (record.type === 'grouping') return null
    else {
      if (record.meeting !== null)
        return (
          <>
            {!isPast && (
              <div>
                <Button
                  onClick={reschedule.bind(null, record)}
                  className="schedule-day-reschedule"
                  type="ghost"
                >
                  Reschedule
                </Button>
                <Button
                  onClick={cancelModal.bind(null, record)}
                  className="schedule-day-cancel"
                  type="ghost"
                >
                  Cancel
                </Button>
              </div>
            )}
            {isPast && (
              <Button
                onClick={sendMessageModal.bind(null, record)}
                className="schedule-day-reschedule"
                type="ghost"
              >
                Send Message
              </Button>
            )}
          </>
        )
      else
        return (
          <Switch
            onChange={onAvailable.bind(null, record)}
            className="schedule-day-switch"
            size="large"
            checkedChildren="Available"
            unCheckedChildren="Unavailable"
            checked={record.available}
          ></Switch>
        )
    }
  }

  const rowClassMethod = (record, index) => {
    let result = 'schedule-day-row'
    if (record.type === 'grouping')
      return `schedule-day-row-grouping cc-tabletitle-text ${result}`
    else {
      result = `schedule-day-row-event ${result}`
      if (index % 2 === 0) result += ' schedule-day-row-even'
      else result += ' schedule-day-row-odd'
      return result
    }
  }

  return (
    <>
      <Spin
        spinning={loading}
        size="large"
        style={{ textAlign: 'center', marginTop: '250px' }}
      >
        {timeSlot && (
          <ScheduleTable
            pagination={false}
            rowClassName={rowClassMethod}
            dataSource={timeSlot}
            columns={getColumns()}
            tableTitle={`${moment(eventDate).format('dddd')}'s Schedule`}
            searchVisible={false}
            refresh={refresh}
          />
        )}
      </Spin>

      <Modal
        title="Cancel Meeting Request"
        visible={cancelPopupVisible}
        onOk={onCancelMeeting}
        onCancel={hideModal}
        okText="Cancel Meeting"
        cancelText="Close"
      >
        <div style={{ marginLeft: '20px' }}>
          <span
            className="cc-heading5"
            style={{ fontSize: '14px', fontWeight: 'normal', color: '#4D4F56' }}
          >
            Are you sure you want to Cancel the Summit Meeting Request?
          </span>

          <span
            className="cc-heading5"
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
              color: '#4D4F56',
              marginTop: '15px',
              display: 'block',
            }}
          >
            Choose the user on behalf you want to cancel the meeting:
          </span>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              paddingTop: '16px',
            }}
          >
            <Radio.Group onChange={onRadioChange} value={checkValue}>
              {currentCompanyContacts &&
                currentCompanyContacts.length > 0 &&
                currentCompanyContacts.map((contact) => (
                  <Col
                    span={24}
                    style={{ paddingBottom: '16px', paddingLeft: '0' }}
                  >
                    <div
                      key={contact.contactId.toString()}
                      style={{ width: '100%' }}
                    >
                      <Radio
                        value={contact.contactId}
                        style={{ paddingRight: '12px' }}
                      />
                      <ContactName
                        name={contact.contactName}
                        imageUrl={contact.imageURL}
                      />
                    </div>
                  </Col>
                ))}
            </Radio.Group>
          </div>
        </div>
      </Modal>

      <RescheduleEventMeeting
        visible={showRescheduleModal}
        closeModal={closeReschedule}
        meetingId={isNil(selectedRecord) ? '' : selectedRecord.meetingId}
        eventId={eventId}
        companyId={companyId}
        contactCompanyEventId={companyEventId}
        companyEventId={
          isNil(selectedRecord) ? '' : selectedRecord.companyEventId
        }
        onRescheduleMeeting={handleRescheduleMeeting}
      />
      {selectedRecord && currentCompanyContacts && (
        <MessageModalPast
          visible={showSendMessageModal}
          loading={messageLoading}
          eventId={eventId}
          companyName={isNil(selectedRecord) ? '' : selectedRecord.companyName}
          messageBody={messageBody}
          toCompanyId={selectedRecord.companyId}
          handle={() => {
            console.log('selectedRecord', selectedRecord)
            setShowSendMessageModal(false)
            setMessageLoading(false)
            setMessageBody('')
          }}
          theMessage={messageBody}
          toCompanyEventId={selectedRecord.companyEventId}
          handleCancel={() => setShowSendMessageModal(false)}
          onChange={(e) => setMessageBody(e.target.value)}
          investors={
            isNil(selectedRecord)
              ? []
              : selectedRecord.contacts.map((c) => ({
                  id: c.contactId,
                  contactName: c.contactName,
                  position: c.jobTitle,
                  avatar: c.imageURL,
                }))
          }
          fromCompanyId={companyId}
          fromCompanyEventId={companyEventId}
          fromCompanyContacts={currentCompanyContacts}
        />
      )}
    </>
  )
}

ScheduleDay.propTypes = {
  eventDate: PropTypes.string.isRequired,
  timeSlots: PropTypes.array.isRequired,
  companyEventId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  refreshSchedule: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
}
export default ScheduleDay
