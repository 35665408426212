import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row, message } from 'antd'
import { isEmpty } from 'lodash'
import { addBackstageUser, getBackstageUsers } from '../../actions/admin'
import { getBackstageUserColumns } from '../../utils/columns'
import BackstageUserModal from '../BackstageUserModal/BackstageUserModal'
import ContextTable from '../ContextTable'
import './AdminUserList.less'

const AdminUserList = () => {
  const [refresh, setRefresh] = useState(false)

  const [modalVisible, setModalVisible] = useState(false)

  const refreshData = () => {
    setRefresh(!refresh)
  }

  const columns = getBackstageUserColumns(refreshData)

  const onSubmit = (user) => {
    addBackstageUser(user)
      .then((res) => {
        if (isEmpty(res.data)) {
          message.success('User created successfully.')
          setModalVisible(false)
          refreshData()
        } else {
          message.error(res.data)
        }
      })
      .catch((err) => {
        message.error('An error has occurred.')
      })
  }

  return (
    <>
      <Row style={{ paddingBottom: '16px' }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            size="large"
            onClick={() => setModalVisible(true)}
          >
            <span>
              <FontAwesomeIcon icon={faPlus} />
              {'  '}Add User
            </span>
          </Button>
        </Col>
      </Row>
      <BackstageUserModal
        visible={modalVisible}
        handleOk={onSubmit}
        handleCancel={() => setModalVisible(false)}
      />
      <ContextTable
        tableTitle="Users"
        columns={columns}
        refresh={refresh}
        refreshData={refresh}
        scroll={{ x: 1400 }}
        dataMethod={getBackstageUsers.bind()}
      />
    </>
  )
}

AdminUserList.propTypes = {}

export default AdminUserList
