import { useEffect, useState } from 'react'
import { Avatar, Button, Col, Divider, Row, Typography } from 'antd'

export const contactRows = (contacts, companyId) => {
  const contactRows = groupContacts(contacts)

  return contactRows.map((column, index) => (
    <>
      <Col span={12}>{contactProfile(column[0], companyId)}</Col>
      {column.length > 1 ? (
        <Col span={12}>{contactProfile(column[1], companyId)}</Col>
      ) : null}
    </>
  ))
}

const groupContacts = (contacts) => {
  var grouped = []
  var row = []

  for (let i = 0; i < contacts.length; i++) {
    if (i % 2 === 0) {
      row.push(contacts[i])
    } else {
      row.push(contacts[i])
      grouped.push(row)
      row = []
    }
  }

  //if there is one extra contact
  if (row.length > 0) grouped.push(row)

  return grouped
}

const contactProfile = (contact, companyId) => {
  return (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      style={{ paddingBottom: '10px' }}
    >
      <Col span={3}>
        <Avatar
          src={contact.imageURL}
          style={{
            fontSize: '14px',
            textAlign: 'center',
            verticalAlign: 'bottom',
          }}
          shape="circle"
          size={32}
        >
          {contact.contactName}
        </Avatar>
      </Col>
      <Col span={17}>
        <Row type="flex" justify="space-between" align="middle">
          <div className="contactName">{contact.contactName}</div>
        </Row>
        <Row type="flex" justify="space-between" align="middle">
          <div className="contactDetail">{contact.jobTitle}</div>
        </Row>
      </Col>
      <Col span={4}></Col>
    </Row>
  )
}
