import PropTypes from 'prop-types'
import { List } from 'antd'
import { isEqual } from 'lodash'
import SimpleBar from 'simplebar-react'
import ServiceProviderPeopleCard from './ServiceProviderPeopleCard'

const ServiceProviderPeople = ({ serviceProviders }) => {
  const initialItem = {
    fullName: null,
    jobPosition: null,
    description: null,
    imageUrl: null,
  }

  return (
    <SimpleBar
      style={{ height: 'calc(100vh - 200px)', overflowX: 'hidden' }}
      autoHide={false}
    >
      <List
        dataSource={Object.values(serviceProviders)}
        renderItem={(item) =>
          !isEqual(item, initialItem) && (
            <ServiceProviderPeopleCard serviceProvider={item} />
          )
        }
      />
    </SimpleBar>
  )
}

ServiceProviderPeople.propTypes = {
  serviceProviders: PropTypes.array.isRequired,

  handleInfiniteOnLoad: PropTypes.func.isRequired,

  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
}

export default ServiceProviderPeople
