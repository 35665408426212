import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Divider,
  Input,
  Modal,
  Radio,
  Select,
  Spin,
  message,
} from 'antd'
import { sendMessage } from '../../actions/files'
import loaderImage from '../../assets/context_loader.gif'
import http from '../../utils/api'
import ContactName from '../ContactName'
import './MessageModal.less'

const { Option, OptGroup } = Select
const { TextArea } = Input

const MessageModal = ({
  visible,
  handleCancel,
  messageBody,
  handle,
  eventId,
  toCompanyId,
  toCompanyEventId,
  investors,
  theMessage,
  onChange,
  companyName,
  loading,
  userType,
}) => {
  const [managers, setManagers] = useState([])
  const [fromCompanyEventId, setFromCompanyEventId] = useState(0)
  const [fromCompanyType, setFromCompanyType] = useState(2)
  const [currentCompany, setCurrentCompany] = useState({})
  const [contactId, setContactId] = useState({})
  const [okDisabled, setOkDisabled] = useState(true)

  const handleOk = () => {
    handle()
    sendMessage({
      messageBody,
      messageDetails: {
        companyId: currentCompany.company.companyId,
        toCompanyId,
        companyEventId: currentCompany.companyEventId,
        toCompanyEventId,
        contactIds: currentCompany.contacts.map((x) => x.contactId),
        toContactIds: investors.map((x) => x.id),
        eventId,
        contactId,
        meetingTypeStr: 'Summit',
        meetingType: 1,
      },
    })
      .then((response) => {
        message.success(
          <span>
            Message sent{' '}
            <a href={`/schedule/${currentCompany.companyEventId}/3`}>
              Go to conversation
            </a>
          </span>
        )
      })
      .catch((error) => {
        message.error('There was an error while attempting to send the message')
      })
  }

  const handleFilterOption = (e) => {
    setManagers({})
    if (e.length > 1)
      http.post(`/events/search/${eventId}/3?keyword=${e}`).then((res) => {
        setManagers(res.data.response)
      })
  }
  const radioChange = (e) => {
    setContactId(e.target.value)
  }
  const handleChange = (e) => {
    console.log('e on modal', e.target.value)
    if (e.target.value.trim() !== '') {
      setOkDisabled(false)
    } else {
      setOkDisabled(true)
    }
    onChange(e)
  }
  const handleSelectChange = (e) => {
    console.log(userType)
    let selectedCompany = managers.allocators.filter(
      (obj) => obj.companyEventId === e
    )
    if (selectedCompany.length === 0) {
      selectedCompany = managers.funds.filter((obj) => obj.companyEventId === e)
      setFromCompanyType(1)
    } else {
      setFromCompanyType(2)
    }
    setCurrentCompany(selectedCompany[0])

    setFromCompanyEventId(selectedCompany[0].companyEventId)
  }
  return (
    <Modal
      visible={visible}
      title="New Message"
      onOk={handleOk}
      bodyStyle={{ padding: 0 }}
      onCancel={handleCancel}
      okButtonProps={{ disabled: okDisabled }}
      okText="Send Message"
    >
      <Spin
        size="large"
        spinning={loading}
        indicator={
          <img
            style={{ marginLeft: '-50px', width: '100px', height: '100px' }}
            src={loaderImage}
          />
        }
      >
        <div style={{ padding: '13px 16px 32px 32px' }}>
          <div className="investors-container">
            <h3 className="heading5" style={{ marginTop: '16px' }}>
              To:
            </h3>
            <h3>{companyName}</h3>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                marginBottom: '26px',
              }}
            >
              {' '}
              {investors.map((investor) => (
                <div
                  key={investor.id.toString()}
                  style={{ width: '45%', padding: '2px' }}
                >
                  <ContactName
                    name={investor.contactName}
                    position={investor.position}
                    imageUrl={investor.avatar}
                  />
                </div>
              ))}
            </div>
          </div>

          <h3 className="heading5" style={{ marginTop: '16px' }}>
            From:
          </h3>
          <Select
            showSearch
            className="cc-background-input"
            size="large"
            style={{ width: '95%', height: '48px' }}
            placeholder="From"
            showArrow={false}
            optionFilterProp="children"
            onChange={handleSelectChange}
            onSearch={handleFilterOption}
            filterOption={false}
          >
            <OptGroup label="Allocators">
              {managers.allocators &&
                managers.allocators.map((row) => (
                  <Select.Option value={row.companyEventId}>
                    {row.company.name}
                  </Select.Option>
                ))}
            </OptGroup>
            {userType &&
              (userType == 'serviceprovider' || userType == 'media') && (
                <OptGroup label="Funds">
                  {managers.funds &&
                    managers.funds.map((row) => (
                      <Select.Option value={row.companyEventId}>
                        {row.company.name}
                      </Select.Option>
                    ))}
                </OptGroup>
              )}
          </Select>
          <div>
            <span className="requestSender">SENDER</span>
            <Radio.Group onChange={radioChange} value={contactId}>
              {currentCompany.contacts &&
                currentCompany.contacts.map((row) => (
                  <div style={{ paddingBottom: '6px' }}>
                    <Radio value={row.contactId}></Radio>{' '}
                    <ContactName
                      style={{ display: 'inline' }}
                      name={row.contactName}
                      imageUrl={row.imageURL}
                    />
                  </div>
                ))}
            </Radio.Group>
          </div>
          <div>
            <TextArea
              placeholder="Write a message..."
              rows={8}
              value={theMessage}
              onChange={handleChange}
            />
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

MessageModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  theMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  investors: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default MessageModal
