import PropTypes from 'prop-types'
import { Button, Typography } from 'antd'
import { Formik } from 'formik'
import { includes, isNil, join, map } from 'lodash'
import * as Yup from 'yup'
import * as types from '../../constants/types/serviceprovideronboarding'
import { getInitialValues } from '../DynamicForm/DynamicForm'
import FormField from '../Forms/Fields/FormField.js'

const { Title } = Typography

const InitialStepSchema = Yup.object().shape({})

const NON_EDITABLE_FIELDS = []

const InitialStep = ({
  title,
  companyId = null,
  className = null,
  questions,
  answers,
  questionsWithOptions,
  onNext,
}) => {
  const initialState = getInitialValues(
    questions,
    answers,
    questionsWithOptions
  )

  const classNames = ['ServiceProviderWizard-Step']
  if (!isNil(className)) {
    classNames.push(className)
  }
  const finalClassName = join(classNames, ' ')

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialState}
      validationSchema={InitialStepSchema}
      onSubmit={(values) => {
        onNext(questions, values, types.APPEND_ANSWERS_SP, true, false, 1)
      }}
    >
      {({
        values,
        errors,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <form onSubmit={handleSubmit} className={finalClassName}>
            <Title level={3} className="ServiceProviderWizard-step-title">
              {title}
            </Title>
            {map(questions, (question) => {
              return (
                <FormField
                  key={question.serviceProviderQuestionId}
                  question={question}
                  values={values}
                  disabled={
                    !isNil(companyId) &&
                    includes(NON_EDITABLE_FIELDS, question.shortName)
                  }
                  errors={errors}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              )
            })}
            <div className="ServiceProviderWizard-initial-form-actions">
              <Button
                htmlType="submit"
                type="primary"
                block
                size="large"
                disabled={!isValid}
              >
                Next
              </Button>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

InitialStep.propTypes = {
  title: PropTypes.string,
  companyId: PropTypes.number,
  className: PropTypes.string,
  questions: PropTypes.array,
  answers: PropTypes.array,
  questionsWithOptions: PropTypes.any.isRequired,
  onNext: PropTypes.func,
}

export default InitialStep
