import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isNil from 'lodash/isNil'
import { Link } from 'react-router-dom'
import './SectionHeader.less'

const SectionHeader = ({ title, icon, link }) => {
  let sectionLink = null
  if (!isNil(link)) {
    sectionLink = (
      <Link to={link.to} className="cc-section-header-link">
        {link.label}
      </Link>
    )
  }

  let sectionIcon = null
  if (!isNil(icon)) {
    sectionIcon = (
      <span className="cc-section-header-icon">
        <FontAwesomeIcon icon={icon} />
      </span>
    )
  }

  return (
    <div className="cc-section-header">
      <h1 className="cc-section-header-title">
        {sectionIcon}
        {title}
      </h1>
      {sectionLink}
    </div>
  )
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object,
  link: PropTypes.shape({
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
}

export default SectionHeader
