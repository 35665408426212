import http from '../utils/api'

export const getMandatesList = (pagination, filters, sorter, searchTerm) =>
  http.post('/mandates/list', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getMandatesActivityList =
  (mandateId) =>
  (startDate, endDate) =>
  (pagination, filters, sorter, searchTerm) =>
    http.post(
      `/mandates/activities/${mandateId}?startDate=${startDate}&endDate=${endDate}`,
      {
        pagination,
        filters,
        sorter,
        searchTerm,
      }
    )

export const getMandatesActivityGraphList =
  (mandateId) => (startDate, endDate) =>
    http.post(
      `/mandates/allActivities/${mandateId}?startDate=${startDate}&endDate=${endDate}`
    )

export const getMandatesByCompanyList =
  (companyId) => (pagination, filters, sorter, searchTerm) =>
    http.post(`/mandates/list/${companyId}`, {
      pagination,
      filters,
      sorter,
      searchTerm,
    })

export const getMandateActivityDetailsList =
  (mandateId, date, isPdfView, isMeetingReceived) =>
  (pagination, filters, sorter, searchTerm) =>
    http.post(
      `/mandates/activities/details/${mandateId}?date=${date}&isPdfView=${isPdfView}&isApplicationsActivity=${isMeetingReceived}`,
      {
        pagination,
        filters,
        sorter,
        searchTerm,
      }
    )

export const deleteMandate = (mandateId) =>
  http.delete(`/mandates/${mandateId}`)

export const setExclusive = (mandateId, isExclusive) =>
  http.patch(`/mandates/${mandateId}/exclusive`, isExclusive)
