import React, { useEffect, useState } from 'react'
import { faMinusCircle, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Divider, Dropdown, Menu, Row, message } from 'antd'
import http from '../../utils/api'
import DeleteBoothFund from './DeleteBoothFund'
import DragAndDropContacts2 from './DragAndDropContacts2'
import './DragAndDrop2.less'

function DragNDropFunds2({
  data,
  availableBooths,
  fundList,
  companyId,
  participants,
  setLoading,
}) {
  const [fundsMenu, setFundsMenu] = useState(null)
  const [deleteFundModalVisible, setDeleteFundModalVisible] = useState(false)
  const [fundNameToMove, setFundNameToMove] = useState('')
  const [fundBoothToMove, setFundBoothToMove] = useState('')
  const [fundBoothMeetings, setFundBoothMeetings] = useState('')
  const [fundAvailableBoothsToMoveTo, setFundAvailableBoothsToMoveTo] =
    useState(null)

  const handleAddFundToBooth = (fundId, boothName) => {
    const eventCompanyBoothId = availableBooths.filter(
      (p) => p.BoothId === boothName
    )[0].EventCompanyBoothId

    http
      .post(
        `/booths/fund/${fundId}/booth/${eventCompanyBoothId}/add?api-version=2`
      )
      .then(function (response) {
        message.success('Fund added successfully !', 3)
        setLoading()
      })
      .catch(function (error) {
        message.error('An error has occurred!', 3)
      })
      .finally(() => {})
  }

  const showFundsAvailable = (booth, index) => {
    //check if there are any funds that are not in this booth
    const assignedFunds = booth.items.map((x) => x.fundEvent.fundId)
    const menuFunds = []
    fundList.map((value) => {
      if (assignedFunds.findIndex((p) => p === value.fundId) === -1)
        menuFunds.push(value)
    })
    if (menuFunds.length <= 0) {
      message.warning('All funds are added to this booth', 3)
      return <></>
    } else {
      return (
        <Menu
          style={{ maxWidth: '286px' }}
          onClick={(e) => {
            handleAddFundToBooth(e.key, booth.title)
          }}
        >
          {menuFunds.map((item) => (
            <Menu.Item key={item.fundId}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={21}>
                  <div className="contactName">{item.fund.name}</div>
                </Col>
              </Row>
            </Menu.Item>
          ))}
        </Menu>
      )
    }
  }

  const availableBoothsToMoveTo = (fundEventId, boothId) => {
    const fundAvailableBoothIds =
      data &&
      [...new Set([].concat(...data.map((o) => o.items)))]
        .filter(
          (o) =>
            o.fundEventId == fundEventId && o.eventCompanyBoothId != boothId
        )
        .map((m) => m.eventCompanyBoothId)

    const moveToBooths =
      availableBooths &&
      availableBooths.filter(function (obj) {
        return fundAvailableBoothIds.indexOf(obj.EventCompanyBoothId) >= 0
      })

    return moveToBooths
  }

  const handleDeleteFundClick = (
    boothfundsNo,
    boothFundName,
    fundEventId,
    boothId,
    fundBoothId,
    meetingsNo
  ) => {
    if (boothfundsNo > 1) {
      setDeleteFundModalVisible(true)
      setFundNameToMove(boothFundName)

      setFundBoothToMove(fundBoothId)
      setFundBoothMeetings(meetingsNo)

      const fundAvailableBoothIds = [
        ...new Set([].concat(...data.map((o) => o.items))),
      ]
        .filter(
          (o) =>
            o.fundEventId == fundEventId && o.eventCompanyBoothId != boothId
        )
        .map((m) => m.eventCompanyBoothId)

      const moveToBooths = availableBooths.filter(function (obj) {
        return fundAvailableBoothIds.indexOf(obj.EventCompanyBoothId) >= 0
      })
      setFundAvailableBoothsToMoveTo(moveToBooths)
    } else {
      message.warning('There should always be one fund tied to the booth', 3)
    }
  }

  if (data) {
    return (
      <>
        <Row
          style={{ overflowX: 'auto', display: 'flex', paddingBottom: '10px' }}
        >
          {data.map((booth, boothI) => (
            <Col key={booth.title} className="dnd-booth-group" span={10}>
              <span className="group-title">{booth.title}</span>
              <Divider style={{ marginTop: '20px' }} />
              <div className="group-sub-title">{booth.title} Funds</div>

              <Col className="dnd-group" span={24}>
                {booth.items.map((boothFund, boothFundI) => (
                  <div key={boothFund.boothfundId} className="dnd-item">
                    {boothFund && (
                      <>
                        <Col span={17}>
                          <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                          >
                            <DragAndDropContacts2
                              data={boothFund}
                              participants={participants}
                              companyId={companyId}
                              fundName={boothFund.fundEvent?.name}
                              boothFundId={boothFund.eventCompanyBoothFundId}
                              boothName={booth.title}
                              setLoading={() => setLoading()}
                              availableBoothsToMoveTo={availableBoothsToMoveTo(
                                boothFund.fundEvent.fundEventId,
                                boothFund.eventCompanyBoothId
                              )}
                            />
                          </Row>
                        </Col>
                        <Col
                          span={3}
                          style={{
                            cursor: 'pointer',
                            color: 'red',
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            onClick={() => {
                              handleDeleteFundClick(
                                booth.items.length,
                                boothFund.fundEvent?.name,
                                boothFund.fundEvent.fundEventId,
                                boothFund.eventCompanyBoothId,
                                boothFund.eventCompanyBoothFundId,
                                boothFund.meetingsNo
                              )
                            }}
                          />
                        </Col>
                      </>
                    )}
                  </div>
                ))}
                <Row className="display-center">
                  <Dropdown overlay={fundsMenu} trigger={['click']}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => {
                        setFundsMenu(showFundsAvailable(booth, boothI))
                        e.preventDefault()
                      }}
                      style={{ fontWeight: 600, paddingTop: '6px' }}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add Fund
                    </a>
                  </Dropdown>
                </Row>
              </Col>
            </Col>
          ))}
        </Row>

        {deleteFundModalVisible && (
          <DeleteBoothFund
            visible={deleteFundModalVisible}
            handleCancel={() => setDeleteFundModalVisible(false)}
            closeModal={() => setDeleteFundModalVisible(false)}
            title={fundNameToMove}
            fundBoothId={fundBoothToMove}
            availableBooths={fundAvailableBoothsToMoveTo}
            setLoading={() => setLoading()}
            meetingsNo={fundBoothMeetings}
          />
        )}
      </>
    )
  } else {
    return null
  }
}

export default DragNDropFunds2
