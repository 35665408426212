import PropTypes from 'prop-types'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, message } from 'antd'
import http from '../../utils/api'

const DeleteSponsorContentEventModal = ({
  visible,
  sponsorContentEventId,
  handleOk,
  handleCancel,
}) => {
  const deleteContent = () => {
    http
      .delete(`events/sponsorcontent/delete/${sponsorContentEventId}`)
      .then(() => {
        message.success({
          content: 'Content deleted successfully',
          icon: (
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={{ marginRight: '10px', color: '#D62B34' }}
            />
          ),
        })
        handleOk()
      })
      .catch((error) => {
        message.error('Something went wrong!')
        handleOk()
      })
  }

  return (
    <Modal
      className="cc-modal-accept"
      title={`Delete Content`}
      visible={visible}
      okText={`Delete Content`}
      cancelText="Close"
      onOk={deleteContent}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: false,
        style: { backgroundColor: '#D62B34', borderStyle: 'none' },
      }}
      centered={true}
      cancelButtonProps={{ disabled: false }}
    >
      <div>Are you sure you want to delete this sponsor content?</div>
    </Modal>
  )
}

DeleteSponsorContentEventModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  sponsorContentEventId: PropTypes.instanceOf.isRequired,
}

export default DeleteSponsorContentEventModal
