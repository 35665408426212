import 'moment-timezone'
import PropTypes from 'prop-types'
//import moment from 'moment';
import { SelectTimezone } from '@capaj/react-select-timezone'
import '../../styles/index.less'

const customStyle = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected || state.isFocused ? '#fff' : '#F7F8FA',
    borderStyle: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    color: '#2FC5D2',
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: '0px',
    color: '#2FC5D2',
    font: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px',
  }),
  container: (provided) => ({
    ...provided,
    borderStyle: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    boxShadow: 'inset 0px -1px 0px #EFF1F4',
    padding: '12px 16px',
    backgroundColor: state.isSelected ? '#2FC5D2' : '#fff',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
    marginTop: '1px',
  }),
}
const defaultStyle = {
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
    marginTop: '1px',
  }),
  container: (provided) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #EFF1F4',
    boxShadow: '0px 16px 28px rgba(47, 56, 62, 0.08)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    color: '#2FC5D2',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#2FC5D2',
    font: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
  }),
  option: (provided, state) => ({
    ...provided,
    boxShadow: 'inset 0px -1px 0px #EFF1F4',
    padding: '12px 16px',
    backgroundColor: state.isSelected ? '#2FC5D2' : '#fff',
  }),
}

const Timezone = ({
  setCustomStyle = false,
  value,
  handleTimezoneChange,
  shouldHaveDefaultValue = true,
}) => (
  <SelectTimezone
    styles={setCustomStyle ? customStyle : defaultStyle}
    theme={(theme) => ({
      ...theme,
      borderRadius: 4,
      colors: {
        ...theme.colors,
        primary: '#2FC5D2',
        primary50: '#D3ECEE',
      },
    })}
    classNamePrefix="cc-timezone"
    value={value}
    guess={shouldHaveDefaultValue}
    onChange={handleTimezoneChange}
  />
)
Timezone.propTypes = {
  setCustomStyle: PropTypes.bool,
  value: PropTypes.string.isRequired,
  handleTimezoneChange: PropTypes.func,
}
export default Timezone
