import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Modal, Select, message } from 'antd'
import { ReactComponent as PlusLogo } from '../../assets/plus.svg'
import http from '../../utils/api'
import '../../styles/index.less'

const { OptGroup } = Select

const AddContactToCompany = ({ contactId, onSave }) => {
  const [jobFunctions, setjobFunctions] = useState([])
  const [companies, setCompanies] = useState([])
  const [modalVisible, setmodalVisible] = useState(false)
  const handleAddClick = () => setmodalVisible(true)

  const [companyId, setcompanyId] = useState(undefined)
  const [jobFunction, setjobFunction] = useState(undefined)
  const [isAdministrator, setisAdministrator] = useState(false)

  useEffect(() => {
    http.get('/contacts/jobfunctions').then(function (response) {
      setjobFunctions(response.data)
    })
  }, [])

  const onCancel = () => {
    setmodalVisible(false)
    setjobFunction(undefined)
    setcompanyId(undefined)
    setisAdministrator(false)
  }

  const handleSubmit = (e) => {
    const targ = e.target
    targ.disabled = true
    http
      .post('/contacts/addtocompany', {
        contactId,
        companyId,
        jobFunction,
        isAdministrator,
      })
      .then(() => {
        message.success('Contact added to Company.')
        onCancel()
        onSave()
        targ.disabled = false
      })
      .catch((err) => {
        targ.disabled = false
        if (err.response != undefined) {
          message.error(err.response.data)
        } else {
          message.error('Could not add contact to company!')
        }
      })
  }

  const handleFilterOption = (e) => {
    if (e.length > 2)
      http.post(`/companies/search/online/-1?keyword=${e}`).then((res) => {
        setCompanies(res.data.response)
      })
  }

  const handleSelectChange = (e) => {
    let selectedCompany = companies.funds.filter(
      (obj) => obj.company.companyId === e
    )
    if (selectedCompany.length === 0) {
      selectedCompany = companies.allocators.filter(
        (obj) => obj.company.companyId === e
      )
      if (selectedCompany.length === 0) {
        selectedCompany = companies.serviceProviders.filter(
          (obj) => obj.company.companyId === e
        )
        if (selectedCompany.length === 0) {
          selectedCompany = companies.media.filter(
            (obj) => obj.company.companyId === e
          )
          if (selectedCompany.length === 0) {
            selectedCompany = companies.cfNs.filter(
              (obj) => obj.company.companyId === e
            )
          }
        }
      }
    }
    setcompanyId(selectedCompany[0].company.companyId)
  }

  const handleFunctionChange = (e, name) => {
    setjobFunction(name.props.children)
  }

  const handleAdminChange = (e) => {
    setisAdministrator(e.target.checked)
  }

  return (
    <>
      <div className="companyEmptyContainer" onClick={handleAddClick}>
        <div className="emptyPlus">
          {' '}
          <PlusLogo />{' '}
        </div>
        <span className="addcompanySpan">Add company</span>
      </div>
      <div>
        <Modal
          className="cc-modal-accept"
          title="Choose Company"
          okText="Save Changes"
          onOk={handleSubmit} // needs to be changed in order to actually accept the request
          onCancel={onCancel}
          visible={modalVisible}
          okButtonProps={{
            disabled: false,
            style: { backgroundColor: '#24C477', borderStyle: 'none' },
          }}
          cancelButtonProps={{ disabled: false }}
        >
          <p style={{ fontWeight: '600', fontSize: '16px' }}>Company Details</p>
          <div className="form-container">
            <Select
              showSearch
              className="cc-background-input"
              size="large"
              style={{ width: '100%', height: '48px' }}
              placeholder="Search by Company Name"
              showArrow={false}
              optionFilterProp="children"
              onChange={handleSelectChange}
              onSearch={handleFilterOption}
              filterOption={false}
              value={companyId}
              //onDropdownVisibleChange={handleOnClick}
            >
              <OptGroup label="Allocator Companies">
                {companies.allocators &&
                  companies.allocators.map((row) => {
                    if (row.company.category.toLowerCase() === 'allocator') {
                      return (
                        <Select.Option value={row.company.companyId}>
                          {row.company.name}
                        </Select.Option>
                      )
                    }
                  })}
              </OptGroup>
              <OptGroup label="Fund Manager Companies">
                {companies &&
                  companies.funds &&
                  companies.funds.map((row) => {
                    if (row.company.category.toLowerCase() === 'manager') {
                      return (
                        <Select.Option
                          value={row.company.companyId}
                          key={row.company.companyId}
                        >
                          {row.company.name}
                        </Select.Option>
                      )
                    }
                  })}
              </OptGroup>
              <OptGroup label="Service Providers">
                {companies &&
                  companies.serviceProviders &&
                  companies.serviceProviders.map((row) => {
                    if (
                      row.company.category.toLowerCase() === 'service provider'
                    ) {
                      return (
                        <Select.Option
                          value={row.company.companyId}
                          key={row.company.companyId}
                        >
                          {row.company.name}
                        </Select.Option>
                      )
                    }
                  })}
              </OptGroup>
              <OptGroup label="Media Companies">
                {companies &&
                  companies.media &&
                  companies.media.map((row) => {
                    if (row.company.category.toLowerCase() === 'media') {
                      return (
                        <Select.Option
                          value={row.company.companyId}
                          key={row.company.companyId}
                        >
                          {row.company.name}
                        </Select.Option>
                      )
                    }
                  })}
              </OptGroup>
              <OptGroup label="CFN Companies">
                {companies.cfNs &&
                  companies.cfNs.map((row) => {
                    if (row.company.category.toLowerCase() === 'cfn') {
                      return (
                        <Select.Option value={row.company.companyId}>
                          {row.company.name}
                        </Select.Option>
                      )
                    }
                  })}
              </OptGroup>
            </Select>

            <Select
              className="cc-background-input"
              size="large"
              style={{ width: '100%', height: '48px' }}
              placeholder="Position of this user on the company"
              showArrow={false}
              //optionFilterProp="children"
              onChange={handleFunctionChange}
              filterOption={false}
              //filterOption={true}
              //optionFilterProp="children"
              value={jobFunction}
            >
              {jobFunctions &&
                jobFunctions.map((jobF) => (
                  <Select.Option key={jobF.id} value={jobF.id}>
                    {jobF.name}
                  </Select.Option>
                ))}
            </Select>
            <Checkbox
              className="cc-check-participants"
              onChange={handleAdminChange}
              checked={isAdministrator}
            >
              <span
                style={{
                  color: '#24262B',
                  fontSize: '14px',
                  fontWeight: 'normal',
                }}
              >
                Administrator Access
              </span>
            </Checkbox>
          </div>
        </Modal>
      </div>
    </>
  )
}

AddContactToCompany.propTypes = {
  contactId: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default AddContactToCompany
