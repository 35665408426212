import { useEffect, useState } from 'react'
import { Checkbox } from 'antd'
import { isNil } from 'lodash'
import moment from 'moment'
import { getInvestmentStrategies } from '../../actions/onboarding'

const InvestmentStrategies = ({ onChange, value, displayErrors }) => {
  const [strategies, setStrategies] = useState(null)
  const [selectedLiquidStrategy, setSelectedLiquidStrategy] = useState(
    isNil(value) ? false : !isNil(value.strategies.find((x) => x === 'Liquid'))
  )
  const [selectedIlliquidStrategy, setSelectedIlliquidStrategy] = useState(
    isNil(value)
      ? false
      : !isNil(value.strategies.find((x) => x === 'Illiquid'))
  )
  const [selectedBroadStrategies, setSelectedBroadStrategies] = useState(
    isNil(value) ? null : value.broadStrategies
  )
  const [selectedSubStrategies, setSelectedSubStrategies] = useState(
    isNil(value) ? null : value.subStrategies
  )
  const [refresh, setRefresh] = useState(moment())

  if (isNil(strategies)) {
    getInvestmentStrategies().then((response) => {
      setStrategies(response.data)
    })
  }

  const onBroadChanged = (subStrategies, strategy, e) => {
    if (e.target.checked === true) {
      selectedBroadStrategies.push({ strategy, name: e.target.value })
      setSelectedBroadStrategies(selectedBroadStrategies)
    } else {
      let newStrategies = selectedBroadStrategies.filter(
        (x) => x.name !== e.target.value
      )
      let newSubStrategies = selectedSubStrategies.filter(
        (x) => subStrategies.filter((s) => s === x.name).length === 0
      )
      setSelectedBroadStrategies(newStrategies)
      setSelectedSubStrategies(newSubStrategies)
    }

    setRefresh(moment())
  }

  const onSubChanged = (strategy, e) => {
    if (e.target.checked === true) {
      selectedSubStrategies.push({ strategy, name: e.target.value })
      setSelectedSubStrategies(selectedSubStrategies)
    } else {
      let newStrategies = selectedSubStrategies.filter(
        (x) => x.name !== e.target.value
      )
      setSelectedSubStrategies(newStrategies)
    }
    setRefresh(moment())
  }

  const onAllChanged = () => {
    const selectedStr = []
    if (selectedLiquidStrategy === true) {
      selectedStr.push('Liquid')
    }
    if (selectedIlliquidStrategy === true) {
      selectedStr.push('Illiquid')
    }

    let broadStrategies = selectedBroadStrategies

    if (selectedLiquidStrategy !== true)
      broadStrategies = broadStrategies.filter((x) => x.strategy !== 'Liquid')
    if (selectedIlliquidStrategy !== true)
      broadStrategies = broadStrategies.filter((x) => x.strategy !== 'Illiquid')

    setSelectedBroadStrategies(broadStrategies)

    let subStrategies = selectedSubStrategies
    subStrategies = subStrategies.filter((selected_sub) => {
      if (
        selected_sub.strategy === 'Liquid' &&
        selectedLiquidStrategy === false
      )
        return false
      if (
        selected_sub.strategy === 'Illiquid' &&
        selectedIlliquidStrategy === false
      )
        return false

      if (isNil(strategies)) return true

      let subs_primaries =
        selected_sub.name === 'Liquid'
          ? strategies.liquidBroadStrategies
          : strategies.illiquidBroadStrategies

      subs_primaries = subs_primaries.find(
        (x) => !isNil(selectedBroadStrategies.find((b) => b.name === x.name))
      )

      if (isNil(subs_primaries)) return false

      const lookupSub = subs_primaries.subStrategies.find(
        (x) => x === selected_sub.name
      )
      return !isNil(lookupSub)
    })

    setSelectedSubStrategies(subStrategies)

    onChange({
      strategies: selectedStr,
      broadStrategies: broadStrategies,
      subStrategies: subStrategies,
    })
  }

  useEffect(onAllChanged, [selectedLiquidStrategy, selectedIlliquidStrategy])
  useEffect(onAllChanged, selectedBroadStrategies)
  useEffect(onAllChanged, selectedSubStrategies)

  const getSubStrategiesValue = (strategy, broad) => {
    let broads =
      strategy === 'Liquid'
        ? strategies.liquidBroadStrategies
        : strategies.illiquidBroadStrategies

    broads = broads.find((x) => x.name === broad)

    return broads.subStrategies.filter((x) =>
      selectedSubStrategies.find((s) => s.name === x)
    )
  }

  const renderBroadStrategy = (
    broad,
    subStrategies,
    strategy,
    selectedBroadStrategies
  ) => {
    return (
      <div className="cc-generic-form-checkbox-group">
        <Checkbox
          onChange={onBroadChanged.bind(null, subStrategies, strategy)}
          className="cc-broad-strategy"
          value={broad}
          checked={
            !isNil(selectedBroadStrategies.find((x) => x.name === broad))
          }
        >
          {broad}
        </Checkbox>

        {selectedBroadStrategies.filter((x) => x.name === broad).length > 0 ? (
          <div className="cc-substrategy-group">
            <Checkbox.Group
              //   options={subStrategies.map(x => ({ label: x, value: x }))}
              value={getSubStrategiesValue(strategy, broad)}
            >
              {subStrategies.map((sub) => (
                <Checkbox
                  onChange={onSubChanged.bind(null, strategy)}
                  value={sub}
                >
                  {sub}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </div>
        ) : null}

        <div
          style={{ marginTop: '16px', marginBottom: '16px' }}
          className="cc-investor-details-form-separator"
        ></div>
      </div>
    )
  }

  const strCheckboxValue = []
  if (selectedLiquidStrategy) strCheckboxValue.push('Liquid Strategy')
  if (selectedIlliquidStrategy) strCheckboxValue.push('Illiquid Strategy')

  return (
    <div className="cc-onboard-section" style={{ marginBottom: '50px' }}>
      <div className="cc-heading4 cc-onboard-section-title">
        Qualitative Interest
      </div>

      <div className="cc-generic-form-checkbox-group-grid">
        <span className="cc-heading5 cc-generic-form-checkbox-header cc-strategies-header">
          Strategy *
        </span>

        {displayErrors === true &&
        ((!selectedLiquidStrategy && !selectedIlliquidStrategy) ||
          selectedBroadStrategies.length == 0) ? (
          <div className="cc-onboarding-validation-error cc-onboarding-validation-error-topmargin">
            You need to select at least one strategy and one sub strategy
          </div>
        ) : null}

        <Checkbox.Group
          className="cc-body-text cc-strategies-checkbox"
          value={strCheckboxValue}
        >
          <Checkbox
            onChange={(e) => {
              setSelectedLiquidStrategy(e.target.checked)
              onAllChanged()
            }}
            value="Liquid Strategy"
            checked={selectedLiquidStrategy}
          >
            Liquid Strategy
          </Checkbox>
          <Checkbox
            onChange={(e) => {
              setSelectedIlliquidStrategy(e.target.checked)
            }}
            value="Illiquid Strategy"
            checked={selectedIlliquidStrategy}
          >
            Illiquid Strategy
          </Checkbox>{' '}
        </Checkbox.Group>
      </div>

      <div className="cc-investor-details-form-separator"></div>

      {selectedIlliquidStrategy === true ? (
        <div className="cc-strategy-selections">
          <div className="cc-heading5 cc-onboard-section-title cc-broadstrategy-title">
            Illiquid Broad Strategies
          </div>
          {strategies &&
            strategies.illiquidBroadStrategies.map((x) =>
              renderBroadStrategy(
                x.name,
                x.subStrategies,
                'Illiquid',
                selectedBroadStrategies.filter((x) => x.strategy === 'Illiquid')
              )
            )}
        </div>
      ) : null}

      {selectedLiquidStrategy === true ? (
        <div className="cc-strategy-selections">
          <div className="cc-heading5 cc-onboard-section-title cc-broadstrategy-title">
            Liquid Broad Strategies
          </div>
          {strategies &&
            strategies.liquidBroadStrategies.map((x) =>
              renderBroadStrategy(
                x.name,
                x.subStrategies,
                'Liquid',
                selectedBroadStrategies.filter((x) => x.strategy === 'Liquid')
              )
            )}
        </div>
      ) : null}
    </div>
  )
}
export default InvestmentStrategies
