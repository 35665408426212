import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { isNil } from 'lodash'
import {
  getEventServiceProvidersList,
  getEventServiceProvidersListV2,
  getEventSortFilters,
} from '../../actions/events'
import { CATEGORY_SERVICE_PROVIDER } from '../../constants/types/categories'
import http from '../../utils/api'
import { getServiceProviderAttendeeDefaultColumns } from '../../utils/columns'
import ContextTable from '../ContextTable'

const SummitAttendeeServiceProviders = ({ eventId, isPast, isVirtual }) => {
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [refreshData, setRefreshData] = useState(false)
  const [selectedFund, setSelectedFund] = useState(null)
  const [detailsVisible, setDetailsVisibility] = useState(false)
  const [data, setData] = useState(null)
  const [columns, setColumns] = useState(null)

  const refresh = () => {
    setRefreshData(!refreshData)
  }

  useEffect(() => {
    var cols = getServiceProviderAttendeeDefaultColumns(
      isPast,
      refresh,
      isVirtual
    )

    getEventSortFilters(eventId, CATEGORY_SERVICE_PROVIDER)
      .then((res) => {
        var query = res.data

        if (!isNil(query.sorter) && !isNil(query.sorter.columnKey)) {
          var sortIndex = cols.findIndex(
            (x) =>
              x.dataIndex === query.sorter.columnKey ||
              x.key === query.sorter.columnKey
          )
          if (sortIndex >= 0) {
            cols[sortIndex].defaultSortOrder = query.sorter.order
          }
        }

        if (!isNil(query.filters) && !isNil(query.filters.columns)) {
          var filterIndex

          for (let i = 0; i < query.filters.columns.length; i++) {
            filterIndex = cols.findIndex(
              (x) =>
                x.dataIndex === query.filters.columns[i].column ||
                x.key === query.filters.columns[i].column
            )

            if (filterIndex >= 0) {
              cols[filterIndex].defaultFilteredValue =
                query.filters.columns[i].values
            }
          }
        }

        setColumns(cols)
      })
      .catch((err) => setColumns(cols))
  }, [refreshData])

  const getSavedQuery = () => {
    return getEventSortFilters(eventId, CATEGORY_SERVICE_PROVIDER)
  }

  const viewSchedule = (record) => {
    setSelectedRecord(record)
    setDetailsVisibility(true)
  }

  return (
    <>
      {columns && (
        <ContextTable
          tableTitle="Service Providers"
          columns={columns}
          eventId={eventId}
          dataMethod={getEventServiceProvidersListV2}
          refresh={refreshData}
          refreshData={refreshData}
          rowSelected={viewSchedule}
          //scroll={{ x: 1600 }}
          savedQueryMethod={getSavedQuery}
        />
      )}
    </>
  )
}

SummitAttendeeServiceProviders.propTypes = {
  eventId: PropTypes.number.isRequired,
}
export default SummitAttendeeServiceProviders
