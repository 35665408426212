import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Divider, Input, Modal, Row, Select, message } from 'antd'
import { isEmpty, map } from 'lodash'
import { isNil } from 'lodash/fp'
import { useSelector } from 'react-redux'
import {
  SearchForSPEventCompanies,
  getColleagues,
  sendSummitRequestToSP,
} from '../../actions/contextMeetings.js'
import { getEventFundsForMeetingRequest } from '../../actions/fundList'
import ParticipantsCheckbox from '../ParticipantsCheckbox'
import SelectOnlineMeetingDateTime from '../SelectOnlineMeetingDateTime'
import './SummitSPMeetingRequest.less'

const { TextArea } = Input
const { Option, OptGroup } = Select

const SummitSPMeetingRequest = ({
  visible,
  onClose,
  onRefresh,
  serviceProvider,
  eventId,
  isVirtualSummit = false,
  //creditsAvailable = 0,
  isElevated = false,
  hasCredits = true,
}) => {
  const role = useSelector((state) => state.auth.role)
  const [currentStep, setCurrentStep] = useState(0)
  const [selectedFundId, setSelectedFundId] = useState(null)
  const [otherColleagues, setOtherColleagues] = useState([])
  const [myColleagues, setMyColleagues] = useState([])
  const [funds, setFunds] = useState([])
  const [participants, setParticipants] = useState([])
  const [toParticipants, setToParticipants] = useState([])
  const [loadingContacts, setLoadingContacts] = useState(true)
  const [selectedContactList, setSelectedContactList] = useState({
    participants: [],
    toParticipants: [],
  })
  const [selectedHour, setSelectedHour] = useState({})
  const [textMessage, setMessage] = useState('')
  const [duration, setDuration] = useState(30)
  const [selectedDateTime, setSelectedDateTime] = useState({})
  const [savingInProggress, setSavinginProggress] = useState(false)

  const [companies, setCompanies] = useState([])
  const [selectedFund, setSelectedFund] = useState(null)
  const [fromCompanyType, setFromCompanyType] = useState(1)
  const [isFundManager, setIsFundManager] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState({})
  const [selectValue, setSelectValue] = useState('')
  const [fromEventCompanyId, setFromEventCompanyId] = useState(0)

  const [firstParticipantFullname, setFirstParticipantFullname] = useState([])

  const [senderId, setSenderId] = useState(null)
  const [creditsAvailable, setCreditsAvailable] = useState(0)
  const [contextPointsAvailable, setContextPointsAvailable] = useState(0)

  const handleParticipantsChanged = useCallback(
    (mine, participants) => {
      if (mine)
        setSelectedContactList({
          participants,
          toParticipants: selectedContactList.toParticipants,
        })
      else
        setSelectedContactList({
          participants: selectedContactList.participants,
          toParticipants: participants,
        })
    },
    [
      selectedContactList.participants,
      selectedContactList.toParticipants,
      setSelectedContactList,
    ]
  )

  const handleCancel = useCallback(() => {
    setToParticipants([])
    setSelectedCompany(null)
    setSenderId(null)
    setSelectedFundId(null)
    setFunds([])
    setSelectedHour({})
    setSelectedDateTime({})
    setCompanies([])
    setParticipants([])
    setSelectValue('')
    setCurrentStep(0)
    setMessage('')
    setMyColleagues([])
    setOtherColleagues([])
    onClose()
  }, [serviceProvider.contactId, onClose])

  const handleDateOk = useCallback(() => {
    setSavinginProggress(true)
    sendSummitRequestToSP(
      serviceProvider.companyId,
      senderId,
      selectedFundId,
      selectedContactList.participants,
      selectedContactList.toParticipants,
      textMessage,
      1,
      serviceProvider.companyContactId,
      selectedDateTime.startDateTime,
      duration,
      eventId
    )
      .then((response) => {
        serviceProvider.meeting = {
          meetingType: 'Summit',
          meetingRequestType: 'Sent',
          sent: true,
          received: false,
          meetingId: response.data.result.result.meetingId,
          meetingStatusId: 1,
          meetingStatus: 'Pending',
        }
        message.success('Request sent succesfully.')
        onClose()
      })
      .catch((err) => {
        message.error('Could not send meeting request.')
      })
      .finally(() => {
        setSavinginProggress(false)
        onRefresh()
      })
  }, [
    duration,
    selectedDateTime,
    eventId,
    serviceProvider,
    onClose,
    selectedContactList,
    selectedFundId,
    textMessage,
    onRefresh,
  ])

  const handleOk = useCallback(() => {
    setSavinginProggress(true)
    sendSummitRequestToSP(
      selectedCompany.company.companyId,
      selectedContactList.participants[0],
      serviceProvider.company.companyId,
      selectedFundId,
      selectedContactList.participants,
      selectedContactList.toParticipants,
      textMessage,
      1,
      selectedDateTime.startDateTime,
      duration,
      eventId
    )
      .then((response) => {
        message.success('Request sent succesfully.')
      })
      .catch((err) => {
        var allErr = err.response.data.message
        message.error(
          allErr.substring(allErr.indexOf("'") + 1, allErr.indexOf("'."))
        )
      })
      .finally(() => {
        handleCancel()
        onRefresh()
        setSavinginProggress(false)
      })
  }, [
    selectedCompany,
    selectedContactList.participants,
    selectedContactList.toParticipants,
    serviceProvider.company.companyId,
    serviceProvider.companyContactId,
    selectedFundId,
    textMessage,
    eventId,
    onClose,
    onRefresh,
  ])

  const handleParticipantChecked = useCallback(
    (e) => {
      let tmpparticipants
      if (e.target.checked) {
        tmpparticipants = [...participants, e.target.value]
        setParticipants(tmpparticipants)
        if (tmpparticipants.length === 1)
          setFirstParticipantFullname(e.target.contactName)
      } else {
        tmpparticipants = participants.filter((p) => p !== e.target.value)
        setParticipants(tmpparticipants)
      }

      handleParticipantsChanged(true, tmpparticipants)
    },
    [handleParticipantsChanged, participants]
  )

  const handleToParticipantChecked = useCallback(
    (e) => {
      let tmptoParticipants
      if (e.target.checked) {
        tmptoParticipants = [...toParticipants, e.target.value]
        setToParticipants(tmptoParticipants)
      } else {
        tmptoParticipants = toParticipants.filter((p) => p !== e.target.value)
        setToParticipants(tmptoParticipants)
      }

      handleParticipantsChanged(false, tmptoParticipants)
    },
    [handleParticipantsChanged, toParticipants]
  )

  const onTimeChanged = (changed, newDate) => {
    if (newDate) {
      if (changed === 'date') {
        setSelectedHour({})
      }
      if (changed === 'hour') {
        setSelectedHour(newDate)
      }
      setSelectedDateTime({
        startDateTime: newDate.startTime,
        endDateTime: newDate.endTime,
      })
    }
  }

  const getDisabledSendRequest = useCallback(() => {
    if (
      isEmpty(selectedContactList.toParticipants) ||
      (isFundManager && isNil(selectedFundId)) ||
      isNil(senderId)
    )
      return true
    return false
  }, [selectedContactList, senderId, isFundManager, selectedFundId])

  const handleFilterOption = (e) => {
    setCompanies({})
    if (e.length > 2)
      SearchForSPEventCompanies(eventId, e).then((res) => {
        setCompanies(res.data.response)
      })
  }

  const handleSelectChange = (e) => {
    setSelectedFund(null)
    setFunds([])
    var selectedCompany = companies.funds.filter(
      (obj) => obj.eventCompanyId === e
    )
    if (selectedCompany.length === 0) {
      selectedCompany = companies.allocators.filter(
        (obj) => obj.eventCompanyId === e
      )
      // if (selectedCompany.length === 0) {
      //   selectedCompany = managers.media.filter(
      //     (obj) => obj.companyEventId === e
      //   );
      //   if (selectedCompany.length === 0) {
      //     selectedCompany = managers.serviceProviders.filter(
      //       (obj) => obj.companyEventId === e
      //     );
      //     setFromCompanyType(3);
      //     setIsFundManager(false);
      //   } else {
      //     setFromCompanyType(5);
      //     setIsFundManager(false);
      //   }
      // } else {
      setFromCompanyType(2)
      setIsFundManager(false)
      //}
    } else {
      setIsFundManager(true)
      setFromCompanyType(1)
    }
    setSenderId(null)

    setSelectedCompany(selectedCompany[0])
    setSelectValue(selectedCompany[0].eventCompanyId)
    setFromEventCompanyId(selectedCompany[0].eventCompanyId)
    setMyColleagues(selectedCompany[0].contacts)
  }

  const onMainSenderChange = useCallback((e) => {
    setLoadingContacts(true)

    let tmpparticipants
    ///if (e.target.checked) {
    tmpparticipants = participants.filter((p) => p !== e)
    tmpparticipants = [...myColleagues, e]
    setParticipants(tmpparticipants)
    // } else {
    //   tmpparticipants = participants.filter((p) => p !== e.target.value);
    //   setParticipants(tmpparticipants);
    // }

    handleParticipantsChanged(true, tmpparticipants)
    setSenderId(e)

    setLoadingContacts(false)
  }, [])

  useEffect(() => {
    if (isFundManager && !isNil(selectedCompany) && !isNil(serviceProvider))
      getEventFundsForMeetingRequest(
        eventId,
        selectedCompany.company.companyId,
        serviceProvider.company.companyId
      ).then((response) => {
        setFunds(
          response.data.filter((x) => x.hasActiveMeetingRequest === false)
        )

        if (
          isEmpty(
            response.data.filter((x) => x.hasActiveMeetingRequest === false)
          )
        ) {
          onClose()

          message.error(
            `All of your funds have active meetings with ${serviceProvider.company.name}`
          )
        } else
          setSelectedFundId(
            response.data.filter((x) => x.hasActiveMeetingRequest === false)[0]
              .fundId
          )
      })
  }, [eventId, serviceProvider, selectedCompany])

  useEffect(() => {
    setLoadingContacts(true)
    console.log(senderId)
    console.log(selectedCompany)
    //(isFundManager && selectedFundId) &&
    !isNil(senderId) &&
      !isNil(selectedCompany) &&
      getColleagues(
        selectedCompany.company.companyId,
        senderId,
        serviceProvider.company.companyId,
        selectedFundId,
        eventId
      )
        .then((response) => {
          setMyColleagues(
            response.data.find(
              (x) => x.companyId !== serviceProvider.company.companyId
            ).contacts
          )
          setOtherColleagues(
            response.data.find(
              (x) => x.companyId === serviceProvider.company.companyId
            ).contacts
          )
        })
        .catch((err) => {
          console.log(err)
          message.error('Could not retrieve colleagues')
        })
        .finally(() => setLoadingContacts(false))
  }, [serviceProvider, visible, selectedFundId, selectedCompany, senderId])

  const footer = (
    <div className="footer">
      <Button onClick={handleCancel}>Cancel</Button>
      {isVirtualSummit ? (
        <>
          {currentStep === 0 && (
            <Button
              type="primary"
              onClick={() => {
                setCurrentStep(1)
              }}
              disabled={toParticipants.length === 0}
            >
              Next
            </Button>
          )}
          {currentStep === 1 && (
            <>
              <Button type="primary" onClick={() => setCurrentStep(0)}>
                Previous
              </Button>
              <Button
                type="primary"
                disabled={
                  isEmpty(selectedDateTime) ||
                  isEmpty(selectedHour) ||
                  senderId == 0
                }
                onClick={() => handleOk()}
                loading={savingInProggress}
              >
                Send Meeting Request
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          <Button
            type="primary"
            disabled={isNil(senderId) || senderId == 0}
            onClick={() => handleOk()}
            loading={savingInProggress}
          >
            Send Meeting Request
          </Button>
        </>
      )}
    </div>
  )

  const dateFooter = (
    <div className="footer">
      <Button onClick={handleCancel}>Cancel</Button>
      {currentStep === 0 && (
        <Button
          type="primary"
          onClick={() => {
            setCurrentStep(1)
          }}
          disabled={getDisabledSendRequest()}
        >
          Next
        </Button>
      )}
      {currentStep === 1 && (
        <Button
          type="primary"
          onClick={() => {
            setCurrentStep(0)
          }}
        >
          Previous
        </Button>
      )}
      {currentStep === 1 && (
        <Button
          type="primary"
          disabled={
            isEmpty(selectedDateTime) || isEmpty(selectedHour) || isEmpty(funds)
          }
          onClick={() => handleOk()}
        >
          Send Meeting Request
        </Button>
      )}
    </div>
  )

  return (
    <Modal
      centered
      className="FundOnlineMR"
      visible={visible}
      width="700px"
      height="700px"
      title="Request a Meeting"
      footer={footer}
      onCancel={handleCancel}
      bodyStyle={{ padding: 0 }}
      destroyOnClose
    >
      {/* <LoadingGif spinning={isNil(selectedFundId)}> */}
      {currentStep === 0 && (
        <div style={{ paddingLeft: '24px' }}>
          {/* from details */}
          <Row>
            <Col span="24">
              <h3 className="heading5" style={{ marginTop: '16px' }}>
                From:
              </h3>
              <Select
                showSearch
                className="cc-background-input"
                size="default"
                style={{ width: '90%', height: '35px' }}
                placeholder="From"
                showArrow={false}
                optionFilterProp="children"
                onChange={handleSelectChange}
                onSearch={handleFilterOption}
                filterOption={false}
                value={selectValue}
              >
                <OptGroup label="Allocators">
                  {companies.allocators &&
                    companies.allocators.map((row) => {
                      return (
                        <Select.Option value={row.eventCompanyId}>
                          {row.company.name}
                        </Select.Option>
                      )
                    })}
                </OptGroup>
                <OptGroup label="Funds">
                  {companies.funds &&
                    companies.funds.map((row) => {
                      return (
                        <Select.Option value={row.eventCompanyId}>
                          {row.company.name}
                        </Select.Option>
                      )
                    })}
                </OptGroup>
              </Select>
            </Col>
          </Row>
          {!isEmpty(funds) && (
            <Row>
              <Col span="24">
                <h3 className="heading5" style={{ marginTop: '16px' }}>
                  FUND TO PRESENT
                </h3>
                <Select
                  className="cc-background-input"
                  size="default"
                  defaultActiveFirstOption={true}
                  defaultValue={funds[0].name}
                  style={{ width: '95%', marginTop: '35px' }}
                  placeholder="Select fund"
                  onChange={(e) => {
                    setSelectedFundId(e)
                  }}
                >
                  {funds &&
                    funds.map((fund) => (
                      <Option key={fund.fundId} value={fund.fundId}>
                        {fund.name}
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>
          )}
          {!isNil(selectedCompany) && !isEmpty(selectedCompany.contacts) && (
            <Row>
              <Col span="24">
                <h3 className="heading5" style={{ marginTop: '16px' }}>
                  MAIN SENDER
                </h3>{' '}
                <Select
                  className="cc-background-input"
                  size="default"
                  value={senderId}
                  defaultActiveFirstOption={true}
                  defaultValue={selectedCompany.contacts[0].contactId}
                  style={{ width: '90%', height: '35px' }}
                  placeholder="Select main sender"
                  onChange={(e) => {
                    onMainSenderChange(e)
                  }}
                >
                  {selectedCompany.contacts.map((row) => (
                    <Option key={row.contactId} value={row.contactId}>
                      {row.contactName}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          )}
          {!isNil(myColleagues) && !isEmpty(myColleagues) && (
            <Row>
              <Col span="24">
                <span className="requestSender">OTHER PARTICIPANTS</span>
                {myColleagues &&
                  myColleagues.map((contact) => {
                    return (
                      <ParticipantsCheckbox
                        key={contact.contactId}
                        participants={participants}
                        checked={handleParticipantChecked}
                        contactId={contact.contactId}
                        contactName={`${contact.firstName} ${contact.lastName}`}
                        imageURL={contact.imageUrl}
                        position={contact.jobTitle}
                        disabled={contact.contactId === senderId}
                      />
                    )
                  })}
              </Col>
            </Row>
          )}

          <Divider
            type="horizontal"
            style={{
              backgroundColor: '#F7F8FA',
              margin: '14px 0px 0px 0px',
            }}
          />
          {!isNil(otherColleagues) && !isEmpty(otherColleagues) && (
            <Row>
              <Col span={24}>
                <h3 className="heading5">
                  Sending Request To:
                  {loadingContacts ? ' ' : ' ' + serviceProvider.company.name}
                </h3>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                  }}
                >
                  {otherColleagues &&
                    otherColleagues.map((contact) => (
                      <ParticipantsCheckbox
                        key={contact.contactId}
                        {...contact}
                        position={contact.jobTitle}
                        participants={toParticipants}
                        checked={handleToParticipantChecked}
                        imageURL={contact.imageUrl}
                        //disabled={contact.contactId === allocator.contactId}
                      />
                    ))}
                </div>
              </Col>
            </Row>
          )}
          <Divider
            type="horizontal"
            style={{
              backgroundColor: '#F7F8FA',
              margin: '14px 0px 0px 0px',
            }}
          />
          <Row>
            <Col span="24">
              <div>
                <div className="cc-tabletitle-text cc-darkest-grey-text">
                  Send Message
                </div>
                <div style={{ width: '100%', padding: '20px 20px 20px 0px' }}>
                  <TextArea
                    placeholder="Write a message..."
                    rows={4}
                    value={textMessage}
                    onChange={({ target: { value } }) => {
                      setMessage(value)
                    }}
                  />
                </div>
                {role === 'Manager' && (
                  <div
                    className="cc-credit-wrapper"
                    style={{ marginTop: '15px' }}
                  >
                    <span className="cc-credit-cost">
                      This request will cost you 1 Credits
                    </span>
                    <span className="cc-credit-left">
                      You have {creditsAvailable} Credits left.
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
      {currentStep === 1 && selectedCompany && (
        <div style={{ padding: '20px' }}>
          <SelectOnlineMeetingDateTime
            onTimeChanged={onTimeChanged}
            onDurationChanged={setDuration}
            participants={participants}
            toParticipants={toParticipants}
            eventId={eventId}
            contactFullname={firstParticipantFullname}
          />
        </div>
      )}
      {/* </LoadingGif> */}
    </Modal>
  )
}

SummitSPMeetingRequest.propTypes = {
  visible: PropTypes.bool.isRequired,
  //handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
  serviceProvider: PropTypes.object.isRequired,
  fund: PropTypes.string.isRequired,
  fundId: PropTypes.number.isRequired,
  fundContacts: PropTypes.array,
  eventId: PropTypes.bool,
  isElevated: PropTypes.bool,
  creditsAvailable: PropTypes.number,
  contextPointsAvailable: PropTypes.number,
  hasCredits: PropTypes.bool,
}

export default SummitSPMeetingRequest
