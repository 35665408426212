import { Col, DatePicker, Form, Input, Row } from 'antd'
import isEmpty from 'lodash/isNil'
import moment from 'moment'

const OverviewStep = (props) => {
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    props

  const { TextArea } = Input

  const isEmptyOrBlank = (x) => isEmpty(x) || x === ''

  return (
    <>
      <Row>
        <label>
          Title <span className="required">*</span>
        </label>
        <Form.Item
          name="title"
          validateStatus={touched.title && errors.title ? 'error' : 'success'}
          help={touched.title && errors.title ? errors.title : ''}
        >
          <Input
            name="title"
            classname="floating-input-row"
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Item>
      </Row>
      <Row>
        <label>Subtitle</label>
        <Form.Item
          name="subtitle"
          validateStatus={
            touched.subtitle && errors.subtitle ? 'error' : 'success'
          }
          help={touched.subtitle && errors.subtitle ? errors.subtitle : ''}
        >
          <Input
            name="subtitle"
            classname="floating-input-row"
            value={values.subtitle}
            onChange={handleChange}
          />
        </Form.Item>
      </Row>
      <Row>
        <label>Description</label>
        <Form.Item
          name="description"
          validateStatus={
            touched.description && errors.description ? 'error' : 'success'
          }
          help={
            touched.description && errors.description ? errors.description : ''
          }
        >
          <TextArea
            name="description"
            classname="floating-input-row"
            value={values.description}
            onChange={handleChange}
          />
        </Form.Item>
      </Row>
      <Row>
        <Col span={12}>
          <div>
            <label>
              Start Date <span className="required">*</span>
            </label>
          </div>
          <Form.Item
            name="publishDateTimeUtc"
            validateStatus={
              touched.publishDateTimeUtc && errors.publishDateTimeUtc
                ? 'error'
                : 'success'
            }
            help={
              touched.publishDateTimeUtc && errors.publishDateTimeUtc
                ? errors.publishDateTimeUtc
                : ''
            }
          >
            <DatePicker
              className="cc-background-input"
              name="publishDateTimeUtc"
              allowClear={false}
              value={
                !isEmptyOrBlank(values.publishDateTimeUtc)
                  ? moment(values.publishDateTimeUtc)
                  : null
              }
              onChange={(date, dateString) =>
                setFieldValue(
                  'publishDateTimeUtc',
                  !isEmptyOrBlank(dateString) ? dateString : null
                )
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <div>
            <label>End Date</label>
          </div>
          <Form.Item
            name="endDateTimeUtc"
            validateStatus={
              touched.endDateTimeUtc && errors.endDateTimeUtc
                ? 'error'
                : 'success'
            }
            help={
              touched.endDateTimeUtc && errors.endDateTimeUtc
                ? errors.endDateTimeUtc
                : ''
            }
          >
            <DatePicker
              className="cc-background-input"
              name="endDateTimeUtc"
              value={
                !isEmptyOrBlank(values.endDateTimeUtc)
                  ? moment(values.endDateTimeUtc)
                  : null
              }
              onChange={(date, dateString) =>
                setFieldValue(
                  'endDateTimeUtc',
                  !isEmptyOrBlank(dateString) ? dateString : null
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

OverviewStep.propTypes = {}

export default OverviewStep
