import {
  FETCH_CONTACT_SETTINGS_DATA,
  FETCH_SETTINGS_COUNTRIES,
  FETCH_SETTINGS_JOB_FUNCTIONS,
  FETCH_SETTINGS_JOB_LEVELS,
  FETCH_SETTINGS_PRIMARY_INVESTOR_CATEGORIES,
  FETCH_SETTINGS_SECONDARY_INVESTOR_CATEGORIES,
} from '../constants/types'
import http from '../utils/api'

export const updateContact = (contactId, contactData) =>
  http.put(`/contacts/${contactId}`, contactData)

export const updateCompany = (companyId, companyData) =>
  http.put(`/company/${companyId}`, companyData)

export const fetchContactSettingsData = (contactId) => (dispatch) => {
  http.get(`/contacts/${contactId}`).then((response) =>
    dispatch({
      type: FETCH_CONTACT_SETTINGS_DATA,
      payload: response.data.result,
    })
  )
}

export const fetchJobLevels = () => (dispatch) => {
  http.get('/contacts/joblevels').then((response) =>
    dispatch({
      type: FETCH_SETTINGS_JOB_LEVELS,
      payload: response.data.result,
    })
  )
}

export const fetchJobFunctions = () => (dispatch) => {
  http.get('/contacts/jobfunctions').then((response) =>
    dispatch({
      type: FETCH_SETTINGS_JOB_FUNCTIONS,
      payload: response.data.result,
    })
  )
}

export const fetchCountries = () => (dispatch) => {
  http.get('/countries').then((response) =>
    dispatch({
      type: FETCH_SETTINGS_COUNTRIES,
      payload: response.data.result,
    })
  )
}

export const fetchCountryRegions = (countryCode) =>
  http.get(`/countries/${countryCode}/regions`)

export const fetchRegionCities = (countryCode, regionName) =>
  http.get(`/countries/${countryCode}/region/${regionName}/cities`)

export const fetchPrimaryInvestorCategories = () => (dispatch) => {
  http.get('/company/allocators/primaryinvestorcategories').then((response) =>
    dispatch({
      type: FETCH_SETTINGS_PRIMARY_INVESTOR_CATEGORIES,
      payload: response.data.result,
    })
  )
}

export const getPrimaryInvestorCategories = () =>
  http.get('/company/allocators/primaryinvestorcategories')

export const fetchSecondaryInvestorCategories = () => (dispatch) => {
  http.get('/company/allocators/secondaryinvestorcategories').then((response) =>
    dispatch({
      type: FETCH_SETTINGS_SECONDARY_INVESTOR_CATEGORIES,
      payload: response.data.result,
    })
  )
}

export const getSecondaryInvestorCategories = () =>
  http.get('/company/allocators/secondaryinvestorcategories')
