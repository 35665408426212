import {
  FETCH_SERVICE_PROVIDERS_ATTENDEE_LIST,
  FETCH_SERVICE_PROVIDERS_LIST,
  FETCH_SP_FUNDS_ATTENDEE_LIST,
  FETCH_SP_FUNDS_LIST,
} from '../constants/types'

const initialState = {
  serviceProviderList: [],
  serviceProviderAttendeeList: [],
  fundList: [],
  fundAttendeeList: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SERVICE_PROVIDERS_LIST:
      return { ...state, serviceProviderList: action.payload }

    case FETCH_SERVICE_PROVIDERS_ATTENDEE_LIST:
      return { ...state, serviceProviderAttendeeList: action.payload }

    case FETCH_SP_FUNDS_LIST:
      return { ...state, fundList: action.payload }

    case FETCH_SP_FUNDS_ATTENDEE_LIST:
      return { ...state, fundAttendeeList: action.payload }

    default:
      return state
  }
}
