import { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Empty, PageHeader, Row, Spin, Tabs } from 'antd'
import { filter, isNil } from 'lodash'
import { useSelector } from 'react-redux'
import EmptyState from '../../components/EmptyState'
import SectionHeader from '../../components/SectionHeader'
import http from '../../utils/api'
import { getCompanyEventReceivedMeetingRequests } from '../../utils/columns'
import CenteredPrimaryRadioButtons from '../CenteredPrimaryRadioButtons'
import ConfirmedMeetingsTable from '../ConfirmedMeetingsTable'
import ContextTable from '../ContextTable'
import DeclinedRequestsTable from '../DeclinedRequestsTable/DeclinedRequestsTable'
import ReceivedRequestsTable from '../ReceivedRequestsTable'
import SentRequestsTable from '../SentRequestsTable'

const { TabPane } = Tabs
const CompanyEventMeetings = ({
  eventParticipantId,
  fundEventId,
  eventCompanyId,
  contacts,
  companyId,
  eventId,
  refresh,
  isPast,
  refreshMeetings,
  companyName,
  companyContactId,
  fundId,
  type,
  recordId,
  eventCompanyBoothFundId,
  eventCompanyBoothId,
}) => {
  const [activeTab, setActiveTab] = useState('received') // active tab key should correspond to active radio button on CenteredPrimaryRadioButtons
  const requestsNumber = useSelector((state) => {
    if (eventParticipantId > -1)
      return state.meetings.requestedMeetingsCompanyEventId.total
    else return state.meetings.requestedMeetings.total
  })
  const radioHandler = (e) => {
    setActiveTab(e.target.value)
  }
  const initialState = {
    refreshConfirmed: false,
    refreshSent: false,
    refreshCancelled: false,
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'Accept':
        return { ...state, refreshConfirmed: !state.refreshConfirmed }
      case 'Decline/Cancel':
        return { ...state, refreshCancelled: !state.refreshCancelled }
      case 'Send':
        return { ...state, refreshSent: !state.refreshSent }
      default:
        return state
    }
  }

  const [refreshTable, dispatch] = useReducer(reducer, initialState)

  const BUTTONS = [
    {
      value: 'received',
      badgeCount: requestsNumber,
    },
    {
      value: 'confirmed',
    },
    {
      value: 'sent',
    },
    {
      value: 'declined',
    },
  ]

  return (
    <div id="scheduleMeetings">
      <Row>
        <Col style={{ float: 'right', zIndex: 100, top: '20px' }}>
          <CenteredPrimaryRadioButtons
            selectedKey={activeTab}
            buttons={BUTTONS}
            OnClickFunction={radioHandler}
          />
        </Col>
      </Row>

      <Tabs
        animated={false}
        tabBarStyle={{ display: 'none' }}
        activeKey={activeTab}
        style={{ top: '-60px' }}
      >
        <TabPane tab="Received" key="received">
          <ReceivedRequestsTable
            refresh={refresh}
            contacts={contacts}
            companyId={companyId}
            refreshMeetings={refreshMeetings}
            eventId={eventId}
            eventParticipantId={
              !isNil(eventParticipantId) ? eventParticipantId : 0
            }
            fundEventId={!isNil(fundEventId) ? fundEventId : 0}
            eventCompanyId={!isNil(eventCompanyId) ? eventCompanyId : 0}
            fundId={!isNil(fundId) ? fundId : 0}
            companyContactId={!isNil(companyContactId) ? companyContactId : 0}
            meetingType="online"
            isPast={isPast}
            onAcceptRequest={() => {
              dispatch({ type: 'Accept' })
            }}
            onCancelRequest={() => {
              dispatch({ type: 'Decline/Cancel' })
            }}
            type={type}
            itemId={recordId}
            eventCompanyBoothFundId={eventCompanyBoothFundId}
            eventCompanyBoothId={eventCompanyBoothId}
          />
        </TabPane>
        <TabPane tab="Confirmed" key="confirmed">
          {
            <ConfirmedMeetingsTable
              contacts={contacts}
              refresh={refresh}
              eventParticipantId={
                !isNil(eventParticipantId) ? eventParticipantId : 0
              }
              fundEventId={!isNil(fundEventId) ? fundEventId : 0}
              eventCompanyId={!isNil(eventCompanyId) ? eventCompanyId : 0}
              fundId={!isNil(fundId) ? fundId : 0}
              companyContactId={!isNil(companyContactId) ? companyContactId : 0}
              companyId={companyId}
              refreshMeetings={refreshMeetings}
              eventId={eventId}
              meetingType={eventId && eventId > 0 ? 'summit' : 'online'}
              refreshTable={refreshTable.refreshConfirmed}
              isPast={isPast}
              companyNa={companyName}
              onCancelRequest={() => {
                dispatch({ type: 'Decline/Cancel' })
              }}
              type={type}
              itemId={recordId}
              eventCompanyBoothFundId={eventCompanyBoothFundId}
              eventCompanyBoothId={eventCompanyBoothId}
            />
          }
        </TabPane>
        <TabPane tab="Sent" key="sent">
          {
            <SentRequestsTable
              contacts={contacts}
              companyId={companyId}
              refresh={refresh}
              eventParticipantId={
                !isNil(eventParticipantId) ? eventParticipantId : 0
              }
              fundEventId={!isNil(fundEventId) ? fundEventId : 0}
              eventCompanyId={!isNil(eventCompanyId) ? eventCompanyId : 0}
              fundId={!isNil(fundId) ? fundId : 0}
              companyContactId={!isNil(companyContactId) ? companyContactId : 0}
              refreshMeetings={refreshMeetings}
              meetingType="online"
              refreshTable={refreshTable.refreshSent}
              isPast={isPast}
              onCancelRequest={() => {
                dispatch({ type: 'Decline/Cancel' })
              }}
              type={type}
              itemId={recordId}
              eventCompanyBoothFundId={eventCompanyBoothFundId}
              eventCompanyBoothId={eventCompanyBoothId}
            />
          }
        </TabPane>
        <TabPane tab="Declined" key="declined">
          {
            <DeclinedRequestsTable
              eventParticipantId={
                !isNil(eventParticipantId) ? eventParticipantId : 0
              }
              fundEventId={!isNil(fundEventId) ? fundEventId : 0}
              eventCompanyId={!isNil(eventCompanyId) ? eventCompanyId : 0}
              companyId={companyId}
              fundId={!isNil(fundId) ? fundId : 0}
              companyContactId={!isNil(companyContactId) ? companyContactId : 0}
              refresh={refresh}
              meetingType="Summit"
              refreshTable={refreshTable.refreshCancelled}
              refreshMeetings={refreshMeetings}
              isPast={isPast}
              onSendRequest={() => {
                dispatch({ type: 'Send' })
              }}
              type={type}
              itemId={recordId}
              eventCompanyBoothFundId={eventCompanyBoothFundId}
              eventCompanyBoothId={eventCompanyBoothId}
            />
          }
        </TabPane>
      </Tabs>
    </div>
  )
}

CompanyEventMeetings.propTypes = {
  eventParticipantId: PropTypes.number,
  fundEventId: PropTypes.number,
  eventCompanyId: PropTypes.number,
  companyContactId: PropTypes.number,
  fundId: PropTypes.number,
  type: PropTypes.string,
  recordId: PropTypes.number,
}
export default CompanyEventMeetings
