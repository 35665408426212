import PropTypes from 'prop-types'
import { Avatar, Dropdown, Menu, Tooltip } from 'antd'
import { isEmpty, isNil } from 'lodash'
import { getColor } from '../../utils/helpers'
import './AvatarGroup.less'

const AvatarGroup = ({
  avatars,
  size = 'default',
  missingIcon = 'user',
  pushLeft = 24,
  className = null,
  style = {},
  maxAvatars = 3,
  showInitials = false,
}) => {
  if (isNil(avatars) || isEmpty(avatars)) {
    return null
  }

  const hasMoreThanMax = avatars.length > maxAvatars
  let moreAvatars = null

  if (hasMoreThanMax) {
    const avatarList = (
      <Menu>
        {avatars.slice(maxAvatars).map((avatar) => (
          <Menu.Item key={avatar.id}>
            <Avatar
              icon={
                !showInitials && !isEmpty(avatar.initials) ? missingIcon : null
              }
              src={avatar.img}
              style={{
                marginRight: '16px',
                backgroundColor: getColor(avatar.firstName, avatar.lastName),
              }}
            >
              {showInitials && !isEmpty(avatar.initials) ? avatar.initials : ''}
            </Avatar>
            {avatar.description}
          </Menu.Item>
        ))}
      </Menu>
    )
    moreAvatars = (
      <Dropdown overlay={avatarList}>
        <Avatar
          className="AvatarGroup-avatar AvatarGroup-avatar-plus"
          size={size}
          style={{ marginLeft: '0' }}
        >
          <b>{avatars.length - maxAvatars}+</b>
        </Avatar>
      </Dropdown>
    )
  }

  return (
    <span
      className={`AvatarGroup ${isNil(className) ? '' : className}`}
      style={style}
    >
      {moreAvatars}
      {avatars.slice(0, maxAvatars).map((avatar, i) => (
        <Tooltip key={avatar.id} title={avatar.description}>
          <Avatar
            className="AvatarGroup-avatar"
            icon={
              !showInitials && !isEmpty(avatar.initials) ? missingIcon : null
            }
            src={avatar.img}
            size={size}
            style={{
              marginLeft: `-${pushLeft * (hasMoreThanMax ? i + 1 : i)}px`,
              backgroundColor: getColor(avatar.firstName, avatar.lastName),
            }}
          >
            {showInitials && !isEmpty(avatar.initials) ? avatar.initials : ''}
          </Avatar>
        </Tooltip>
      ))}
    </span>
  )
}

AvatarGroup.propTypes = {
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      img: PropTypes.string,
      description: PropTypes.string,
      initials: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }).isRequired
  ),
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['default', 'small', 'large']),
  ]),
  missingIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  pushLeft: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  maxAvatars: PropTypes.number,
  showInitials: PropTypes.bool,
}

export default AvatarGroup
