import { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import { bookBooth, releaseBooth } from '../../actions/booths'
import BoothSelectorModal from '../BoothSelector/BoothSelectorModal'
import SeatsSelector from '../SeatsSelector/SeatsSelector'

const { confirm } = Modal

const SeatsContainer = ({
  eventId,
  companiesAndBooths,
  getCompaniesForList,
  selectSingleCompany,
  handleSelectSeat,
  currentSeat,
  allowSelectingBooked,
  getEvent,
}) => {
  const [showSeleatorModal, setShowSelectorModal] = useState(false)
  const [selectedSeat, setSelectedSeat] = useState()
  const [confirmBoothEnabled, setConfirmBoothEnabled] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState()
  const [savingBooths, setSavingBooths] = useState(false)
  const [freeBooth, setFreeBooth] = useState(false)
  const [holdSeat, setHoldSeat] = useState(false)

  const availableCompanies = companiesAndBooths?.filter(
    (x) =>
      x.boothCount > x.boothId.split(',').length ||
      x.boothId.split(',')[0] === ''
  )

  const handleSeatSelected = (seat) => {
    setSelectedSeat(seat)
    if (!selectSingleCompany) setShowSelectorModal(true)
    if (isFunction(handleSelectSeat)) handleSelectSeat(seat)
  }

  const handleModalCancel = () => {
    setShowSelectorModal(false)
    setSelectedSeat(null)
    setConfirmBoothEnabled(false)
    setSelectedCompany(null)
    setFreeBooth(false)
    setHoldSeat(false)
  }
  const handleCompanySelected = (company) => {
    setSelectedCompany(company)
    setConfirmBoothEnabled(!isNil(company))
  }
  const handleBookBooth = () => {
    setSavingBooths(true)
    bookBooth(
      eventId,
      selectedSeat.id,
      selectedCompany?.eventCompanyId,
      selectedCompany?.company.companyId,
      freeBooth,
      selectedSeat.labels.own
    )
      .then((response) => {
        if (response.data) {
          handleModalCancel()
          message.success('The booth was booked successfully')
        } else {
          message.error('There was an error while attempting to book booths')
        }
      })
      .catch(() => {
        message.error('Failed to book the booth')
      })
      .finally(() => {
        setSavingBooths(false)
        getCompaniesForList()
        if (isFunction(getEvent)) getEvent()
      })
  }

  const handleReleaseBooth = () => {
    setSavingBooths(true)
    releaseBooth(eventId, selectedSeat.id)
      .then((response) => {
        if (response.data) {
          handleModalCancel()
          message.success('The booth was booked freed')
        } else {
          message.error('There was an error while attempting to free booths')
        }
      })
      .catch(() => {
        message.error('Failed to free the booth')
      })
      .finally(() => {
        setSavingBooths(false)
        getCompaniesForList()
        if (isFunction(getEvent)) getEvent()
      })
  }

  const showConfirmationDialog = () => {
    confirm({
      title: freeBooth ? (
        <span>
          {`You are freeing booth ${selectedSeat.label} ${
            selectedCompany?.company.name
              ? `and assigning it to company ${selectedCompany?.company.name}`
              : `${holdSeat ? `and holding it without a company` : ''}`
          }`}
        </span>
      ) : (
        <span>{`You are choosing ${
          selectedCompany?.company.name ? '' : 'to hold'
        } booth ${selectedSeat.label} ${
          selectedCompany?.company.name
            ? `for  ${selectedCompany?.company.name}`
            : ''
        }`}</span>
      ),
      icon: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      okButtonProps: { loading: savingBooths },
      onOk() {
        if (freeBooth) {
          if (!selectedCompany?.company.name && !holdSeat)
            return handleReleaseBooth()
        }
        return handleBookBooth()
      },
    })
  }

  return (
    <>
      <SeatsSelector
        eventId={eventId}
        onSeatSelected={handleSeatSelected}
        companiesAndBooths={companiesAndBooths}
        currentSeat={currentSeat}
        allowSelectingBooked={allowSelectingBooked}
      ></SeatsSelector>
      <Modal
        visible={showSeleatorModal && selectedSeat}
        onCancel={handleModalCancel}
        cabcelButtonProps={{ loading: savingBooths }}
        okButtonProps={{
          disabled: !confirmBoothEnabled,
          loading: savingBooths,
        }}
        destroyOnClose
        onOk={showConfirmationDialog}
      >
        <BoothSelectorModal
          seat={selectedSeat}
          companiesAndBooths={availableCompanies}
          onCompanySelected={handleCompanySelected}
          setOkEnabled={setConfirmBoothEnabled}
          selectedCompany={selectedCompany}
          setReleaseBooth={setFreeBooth}
          setHoldSeat={setHoldSeat}
        ></BoothSelectorModal>
      </Modal>
    </>
  )
}

SeatsContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
  companiesAndBooths: PropTypes.array,
  getCompaniesForList: PropTypes.func,
  selectSingleCompany: PropTypes.bool,
  handleSelectSeat: PropTypes.func,
  currentSeat: PropTypes.string,
  allowSelectingBooked: PropTypes.bool,
  getEvent: PropTypes.func,
}
export default SeatsContainer
