import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Modal,
  Radio,
  Row,
  Select,
  Steps,
  message,
} from 'antd'
import moment from 'moment'
import http from '../../utils/api'
import ContactName from '../ContactName'
import ParticipantsCheckbox from '../ParticipantsCheckbox'
import ToArrow from './arrow.svg'
import './RequestOnlineMeeting.less'

const { Option, OptGroup } = Select

const steps = [
  {
    title: 'First',
    content: 'First-content',
    prevText: 'Cancel',
    btnText: 'Next',
  },
  {
    title: 'Last',
    content: 'Last-content',
    prevText: 'Previous',
    btnText: 'Send Meeting Request',
  },
]

const RequestOnlineMeetingToAllocator = ({
  visible,
  handleOk,
  handleCancel,
  company,
  companyTo,
  companyToName,
}) => {
  const initRequestDateTime = {
    startDateTime: moment(),
    endDateTime: moment(),
  }
  const [current, setCurrentStep] = useState(0)
  const [participants, setParticipants] = useState([])
  const [toParticipants, setToParticipants] = useState([])
  const [allocators, setAllocators] = useState([])
  const [currentCompany, setCurrentCompany] = useState({})

  const [duration, setDuration] = useState(-1)
  const [date, setDate] = useState('')
  const [availableDates, setAvailableDates] = useState([])
  const [selectedHour, setSelectedHour] = useState(null)
  const [selectedFundId, setSelectedFundId] = useState()
  const [checkValue, setCheckValue] = useState(null)
  const [senderUserId, setSenderUserId] = useState(0)

  const [funds, setFunds] = useState([])
  const [selectedContactList, setSelectedContactList] = useState({})
  const [selectedDateTime, setSelectedDateTime] = useState(initRequestDateTime)
  const [fromCompanyType, setFromCompanyType] = useState(2)
  const [isFundManager, setIsFundManager] = useState(false)

  const next = () => {
    setCurrentStep(current + 1)
  }

  const prev = () => {
    setCurrentStep(current - 1)
  }

  const handlePrevious = () => {
    if (current > 0) {
      prev()
    } else {
      handleCancel()
    }
  }

  const sendOnlineMeetingRequest = (
    toCompanyCategory,
    toCompanyId,
    companyId,
    fundId,
    meetingDateTime,
    meetingEndDateTime,
    contactIds,
    toContactIds
  ) =>
    http
      .post(`online/meetwith/${toCompanyId}/${senderUserId}/${companyId}`, {
        meetingDateTime,
        meetingEndDateTime,
        contactIds,
        toContactIds,
        fundId,
      })
      .then((response) => {
        console.log(response)
        message.success('Meeting request sent successfully')
      })
      .catch((error) => {
        console.log(error)
        message.error('There was an error while sending the meeting request')
      })

  const onFundChanged = (e) => {
    setSelectedFundId(e)
  }

  const handleNext = (event) => {
    event.preventDefault()
    if (current == 1) {
      sendOnlineMeetingRequest(
        'manager',
        companyTo.company.companyId,
        currentCompany.company.companyId,
        selectedFundId,
        selectedDateTime.startDateTime.utc().format(),
        selectedDateTime.endDateTime.utc().format(),
        participants,
        toParticipants
      )
      handleCancel()
    } else {
      next()
    }
  }

  useEffect(() => {
    if ((duration !== 0 && duration !== 1) || date === '' || date === null) {
      return
    }

    let url = `companies/online/availabilities?date=${date.format(
      'YYYY-MM-DD'
    )}&duration=${duration}&`

    participants.forEach((part) => {
      url = `${url}contactIds=${part}&`
    })

    toParticipants.forEach((part) => {
      url = `${url}contactIds=${part}&`
    })

    url = url.substring(0, url.length - 1)

    http
      .get(url)
      .then((response) => {
        setAvailableDates(response.data)
      })
      .catch((err) => {
        console.log(err)
        message.error('Error occurred')
      })
  }, [duration, participants, date, toParticipants])

  const getSelect = () => (
    <Select
      className="cc-background-input"
      style={{ width: '70%' }}
      size="large"
      onChange={(val) => {
        const selectedValue = availableDates.find((x) => x.startTime === val)
        setSelectedHour(selectedValue.startTime)
        onTimeChanged('hour', selectedValue)
      }}
      placeholder="Choose Time"
    >
      {availableDates.map((dt) => (
        <Option value={dt.startTime} key={dt.startTime}>
          {' '}
          {moment(dt.startTime).format('HH:mm')} -{' '}
          {moment(dt.endTime).format('HH:mm')}
        </Option>
      ))}
    </Select>
  )

  const handleParticipantChecked = (e) => {
    let tmpparticipants
    if (e.target.checked) {
      tmpparticipants = [...participants, e.target.value]
      setParticipants(tmpparticipants)
    } else {
      tmpparticipants = participants.filter((p) => p !== e.target.value)
      setParticipants(tmpparticipants)
    }
    onParticipantsChanged(tmpparticipants)
  }

  const handleToParticipantChecked = (e) => {
    let tmptoParticipants
    if (e.target.checked) {
      tmptoParticipants = [...toParticipants, e.target.value]
      setToParticipants(tmptoParticipants)
    } else {
      tmptoParticipants = toParticipants.filter((p) => p !== e.target.value)
      setToParticipants(tmptoParticipants)
    }
    onToParticipantsChanged(tmptoParticipants)
  }

  const handleFilterOption = (e) => {
    setAllocators({})
    if (e.length > 2)
      http.post(`/companies/search/online/2?keyword=${e}`).then((res) => {
        setAllocators(res.data.response)
      })
  }

  const handleSelectChange = (e) => {
    setFunds([])
    var selectedCompany = allocators.funds.filter(
      (obj) => obj.company.companyId === e
    )
    if (selectedCompany.length === 0) {
      selectedCompany = allocators.serviceProviders.filter(
        (obj) => obj.company.companyId === e
      )
      if (selectedCompany.length === 0) {
        selectedCompany = allocators.media.filter(
          (obj) => obj.company.companyId === e
        )
        setFromCompanyType(5)
        setIsFundManager(false)
      } else {
        setFromCompanyType(3)
        setIsFundManager(false)
      }
    } else {
      http.get(`/companies/${e}/funds`).then((response) => {
        setFunds(response.data)
        setIsFundManager(true)
        setFromCompanyType(1)
      })
    }
    setCurrentCompany(selectedCompany[0])
  }

  const onRadioChange = (e) => {
    setCheckValue(e.target.value)
    setSenderUserId(e.target.value)
  }

  const onParticipantsChanged = (participants) => {
    setSelectedContactList({
      participants,
      toParticipants: selectedContactList.toParticipants,
    })
  }

  const onToParticipantsChanged = (participants) => {
    setSelectedContactList({
      participants: selectedContactList.participants,
      toParticipants: participants,
    })
  }

  const onTimeChanged = (changed, newDate) => {
    let date = selectedDateTime.startDateTime
    let endDate = selectedDateTime.endDateTime
    if (changed === 'date') {
      date.set('year', newDate.year())
      date.set('month', newDate.month())
      date.set('date', newDate.date())
      endDate.set('year', newDate.year())
      endDate.set('month', newDate.month())
      endDate.set('date', newDate.date())
      setSelectedDateTime({ startDateTime: date, endDateTime: endDate })
    } else {
      newDate.startTime = moment(newDate.startTime)
      newDate.endTime = moment(newDate.endTime)

      date.set('hour', newDate.startTime.hour())
      date.set('minute', newDate.startTime.minute())
      date.set('second', newDate.startTime.second())
      endDate.set('hour', newDate.endTime.hour())
      endDate.set('minute', newDate.endTime.minute())
      endDate.set('second', newDate.endTime.second())
    }
    setSelectedDateTime({
      startDateTime: date,
      endDateTime: endDate,
    })
  }

  return (
    <Modal
      visible={visible}
      width="628px"
      title="Send Online Meeting Request"
      bodyStyle={{ padding: 0 }}
      onCancel={handlePrevious}
      footer={[
        <Button onClick={handlePrevious} className="cancelBtn">
          {steps[current].prevText}
        </Button>,
        <Button type="primary" onClick={handleNext}>
          {steps[current].btnText}
        </Button>,
      ]}
    >
      <div style={{ padding: '13px 16px 32px 32px' }}>
        <div>
          <div>
            <div className="steps-content">
              {current == 0 && (
                <div>
                  <h3>
                    Please select the company you want to send Online meeting
                    request on behalf of, to {companyTo.company.name} ?
                  </h3>
                  <Row>
                    <Col span={10} style={{ marginTop: '16px' }}>
                      <Select
                        showSearch
                        className="cc-background-input"
                        size="large"
                        style={{ width: '95%', height: '48px' }}
                        placeholder="From"
                        showArrow={false}
                        optionFilterProp="children"
                        onChange={handleSelectChange}
                        onSearch={handleFilterOption}
                        filterOption={false}
                      >
                        <OptGroup label="Fund Companies">
                          {allocators.funds &&
                            allocators.funds.map((row) => {
                              return (
                                <Select.Option value={row.company.companyId}>
                                  {row.company.name}
                                </Select.Option>
                              )
                            })}
                        </OptGroup>
                        <OptGroup label="Service Providers">
                          {allocators.serviceProviders &&
                            allocators.serviceProviders.map((row) => {
                              return (
                                <Select.Option value={row.company.companyId}>
                                  {row.company.name}
                                </Select.Option>
                              )
                            })}
                        </OptGroup>
                        <OptGroup label="Media">
                          {allocators.media &&
                            allocators.media.map((row) => {
                              return (
                                <Select.Option value={row.company.companyId}>
                                  {row.company.name}
                                </Select.Option>
                              )
                            })}
                        </OptGroup>
                      </Select>

                      <div>
                        <Row>
                          <Col span={24}>
                            <span className="requestSender">SENDER</span>
                            <Radio.Group
                              onChange={onRadioChange}
                              value={checkValue}
                              style={{ marginLeft: '15px' }}
                            >
                              {currentCompany.contacts &&
                                currentCompany.contacts.map((row) => {
                                  return (
                                    <div>
                                      <Radio value={row.contactId} />
                                      <Checkbox
                                        style={{ display: 'inline' }}
                                        className="cc-check-participants"
                                        value={row.contactId}
                                        onChange={handleParticipantChecked}
                                      >
                                        <ContactName
                                          style={{ display: 'inline' }}
                                          name={row.contactName}
                                          imageUrl={row.imageURL}
                                        />
                                      </Checkbox>
                                    </div>
                                  )
                                })}
                            </Radio.Group>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col span={2} style={{ marginTop: '16px' }}>
                      <img src={ToArrow} />
                    </Col>

                    <Col span={10} style={{ marginTop: '16px' }}>
                      <span style={{ color: '#7F848A' }}>To</span>
                      <br />
                      <span style={{ color: '#2D3239' }}>
                        {companyTo.company.name}
                      </span>
                      <div>
                        <span style={{ marginTop: '16px' }}>&nbsp;</span>
                        {companyTo.contacts &&
                          companyTo.contacts.map((contact) => (
                            <ParticipantsCheckbox
                              key={contact.contactId}
                              {...contact}
                              participants={toParticipants}
                              checked={handleToParticipantChecked}
                            />
                          ))}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              {current == 1 && (
                <div>
                  <Row>
                    <Col span={24}>
                      <h2>{currentCompany.company.name}</h2>
                    </Col>
                  </Row>
                  <Row>
                    {participants &&
                      participants.map((row) => (
                        <Col span={8} style={{ paddingBottom: '16px' }}>
                          <ContactName
                            style={{ display: 'inline' }}
                            name={
                              currentCompany.contacts.filter(
                                (obj) => obj.contactId == row
                              )[0].firstName +
                              ' ' +
                              currentCompany.contacts.filter(
                                (obj) => obj.contactId == row
                              )[0].lastName
                            }
                            imageUrl={
                              currentCompany.contacts.filter(
                                (obj) => obj.contactId == row
                              )[0].imageURL
                            }
                          />
                        </Col>
                      ))}
                  </Row>

                  <Row>
                    <Col span={24}>
                      <h2>{companyTo.company.name}</h2>
                    </Col>
                  </Row>
                  <Row>
                    {toParticipants &&
                      toParticipants.map((row) => (
                        <Col span={8} style={{ paddingBottom: '16px' }}>
                          <ContactName
                            style={{ display: 'inline' }}
                            name={
                              companyTo.contacts.filter(
                                (obj) => obj.contactId == row
                              )[0].firstName +
                              ' ' +
                              companyTo.contacts.filter(
                                (obj) => obj.contactId == row
                              )[0].lastName
                            }
                            imageUrl={
                              companyTo.contacts.filter(
                                (obj) => obj.contactId == row
                              )[0].imageURL
                            }
                          />
                        </Col>
                      ))}
                  </Row>

                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '280px' }}>
                      <div
                        style={{ marginTop: '16px' }}
                        className="cc-heading4"
                      >
                        Select date and time
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '70%',
                          marginTop: '16px',
                        }}
                      >
                        <DatePicker
                          className="cc-background-input"
                          size="large"
                          width="100%"
                          onChange={(value) => {
                            setDate(value)
                            onTimeChanged('date', moment(value))
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: '40px' }}>
                      <div
                        style={{ marginTop: '16px' }}
                        className="cc-heading4"
                      >
                        Meeting Length
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '70%',
                          marginTop: '16px',
                        }}
                      >
                        <Radio.Group
                          onChange={(e) => setDuration(e.target.value)}
                          value={duration}
                          style={{ display: 'flex' }}
                        >
                          <Radio value={0}>30 minutes</Radio>
                          <Radio value={1}>1 hour</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: '20px', lineHeigth: '1.5' }}>
                    {availableDates.length > 0 && (
                      <>
                        <div style={{ marginTop: '16px' }}>Choose Time</div>
                        {getSelect()}
                      </>
                    )}
                  </div>
                  {isFundManager && (
                    <div style={{ marginTop: '16px' }}>
                      Fund you want to present
                      <div style={{ display: 'flex', width: '70%' }}>
                        <Select
                          className="cc-background-input"
                          size="large"
                          style={{ width: '100%', marginTop: '16px' }}
                          placeholder="Select fund"
                          onChange={onFundChanged}
                        >
                          {funds &&
                            funds.map((fund) => (
                              <Option key={fund.fundId} value={fund.fundId}>
                                {fund.name}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
RequestOnlineMeetingToAllocator.defaultProps = {
  isFundManager: false,
  funds: [],
}
RequestOnlineMeetingToAllocator.propTypes = {
  funds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  companyToName: PropTypes.string.isRequired,
  companyIdTo: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  isFundManager: PropTypes.bool,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  company: PropTypes.object,
  companyTo: PropTypes.object,
  onTimeChanged: PropTypes.func,
  onParticipantsChanged: PropTypes.func,
  onToParticipantsChanged: PropTypes.func,
  onFundChanged: PropTypes.func,
}

export default RequestOnlineMeetingToAllocator
