import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Radio, Row, Select } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import './BoothSelector.less'

const RadioGroup = Radio.Group

const BoothSelectorModal = ({
  seat,
  companiesAndBooths,
  onCompanySelected,
  setOkEnabled,
  selectedCompany,
  setReleaseBooth,
  setHoldSeat,
}) => {
  const [companies, setCompanies] = useState(companiesAndBooths)
  const seatSelectable = seat?.selectable
  const [showCompanySelector, setShowCompanySelector] = useState(seatSelectable)
  const [selectorDisabled, setSelectorDisabled] = useState(false)

  const getHeader = useCallback(() => {
    if (!isNil(seat)) {
      if (seatSelectable) {
        return `Booth ${seat.label} is free`
      } else {
        const companyName = companies.find((x) =>
          x.boothId.split(', ').includes(seat.id)
        )?.company.name
        return companyName
          ? `Booth ${seat.label} has already been booked by ${companyName}`
          : `Booth ${seat.label} has already been reserved`
      }
    }
  }, [companies, seat, seatSelectable])

  const handleSearch = (value) => {
    value = value.toLowerCase()
    if (isNil(value) || isEmpty(value)) {
      setCompanies(companiesAndBooths)
    } else {
      const searchedCompanies = companies.filter((x) =>
        x.company.name.toLowerCase().includes(value)
      )
      setCompanies(searchedCompanies)
    }
  }

  const handleCompanySelected = (company) => {
    const selectedCompany = companiesAndBooths.find(
      (x) => x.eventCompanyId === company
    )
    onCompanySelected(selectedCompany)
    setHoldSeat(false)
  }

  const handleHoldBooth = (value) => {
    if (value.target.checked) onCompanySelected(null)
    setOkEnabled(selectedCompany || value.target.checked)
    setSelectorDisabled(value.target.checked)
    setHoldSeat(true)
  }

  const handleFreeBooth = (checked) => {
    if (checked) {
      onCompanySelected(null)
      setShowCompanySelector(false)
      setOkEnabled(true)
      setReleaseBooth(true)
    }
  }

  const handleSwitchCompany = (checked) => {
    if (checked) {
      setShowCompanySelector(true)
      setOkEnabled(false)
      setReleaseBooth(true)
    }
  }

  const getCompanySelector = () => (
    <div>
      <div className={'cc-body boothSelector-body'}>
        Select a company to tie with this booth or hold without a company
      </div>

      <Select
        showSearch
        className="boothSelector-searchBox"
        placeholder="Search Company"
        optionFilterProp="children"
        filterOption={false}
        onSelect={handleCompanySelected}
        onSearch={handleSearch}
        disabled={selectorDisabled}
        value={selectedCompany?.eventCompanyId}
        //showArrow={false}
      >
        {companies.map((company) => (
          <Select.Option value={company.eventCompanyId}>
            {`${company.company.name} ${
              company.boothId ? `Booth: ${company.boothId}` : ''
            }`}
          </Select.Option>
        ))}
      </Select>
      <div className={'cc-body boothSelector-innerText'}>Or </div>
      <Checkbox className="boothSelector-checkBox" onChange={handleHoldBooth}>
        Hold this booth
      </Checkbox>
    </div>
  )
  return (
    <>
      <div className={'cc-heading4 boothSelector-body'}>{getHeader()}</div>
      {!seatSelectable && (
        <Row className={'boothSelector-bookedOptions'}>
          <RadioGroup>
            <Radio onClick={handleFreeBooth} value={true}>
              Free this Booth
            </Radio>
            <Radio onClick={handleSwitchCompany} value={false}>
              Change Company
            </Radio>
          </RadioGroup>
        </Row>
      )}
      {showCompanySelector && getCompanySelector()}
    </>
  )
}
BoothSelectorModal.propTypes = {
  seat: PropTypes.object,
  companiesAndBooths: PropTypes.array,
  onCompanySelected: PropTypes.func,
  setOkEnabled: PropTypes.func,
  selectedCompany: PropTypes.object,
  setReleaseBooth: PropTypes.func,
  setHoldSeat: PropTypes.func,
}
export default BoothSelectorModal
