import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import { getAllocatorTopMatches } from '../../actions/allocator'
import { getAllocatorKpiBreakdownColumns } from '../../utils/columns'
import ContextTable from '../ContextTable'
import './TopMatches.less'

const { TabPane } = Tabs

const TopMatches = ({ companies }) => {
  const columns = getAllocatorKpiBreakdownColumns()

  const companyTabs = () => {
    return companies.map((x, i) => {
      //only have top matches for allocators currently
      if (x.categoryId === 2)
        return (
          <TabPane tab={<span>{x.name}</span>} key={i}>
            <ContextTable
              tableTitle="Funds"
              columns={columns}
              dataMethod={getAllocatorTopMatches.bind(null, x.companyContactId)}
            />
          </TabPane>
        )
      return null
    })
  }

  return (
    <>
      <Tabs size="large" className="UserActivity-tabs">
        {companyTabs()}
      </Tabs>
    </>
  )
}

TopMatches.propTypes = {}

export default TopMatches
