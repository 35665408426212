import { useEffect, useState } from 'react'
import { getOnlineList, getOnlineSortFilters } from '../../actions/online'
import { CATEGORY_CFN } from '../../constants/types/categories'
import { getOnlineCFNAttendeeDefaultColumns } from '../../utils/columns'
import ContextTable from '../ContextTable'

const OnlineAttendeeCFNs = () => {
  const [refreshData, setRefreshData] = useState(false)
  const [columns, setColumns] = useState(null)
  const [category, setCategory] = useState('cfn')

  const refresh = () => {
    setRefreshData(!refreshData)
  }

  useEffect(() => {
    var cols = getOnlineCFNAttendeeDefaultColumns(refresh)

    setColumns(cols)
  }, [refreshData])

  const getSavedQuery = () => {
    return getOnlineSortFilters(CATEGORY_CFN)
  }

  return (
    <>
      {columns && (
        <ContextTable
          tableTitle="CFN"
          columns={columns}
          dataMethod={(pagination, filters, sorter, searchTerm) =>
            getOnlineList(category, pagination, filters, sorter, searchTerm)
          }
          refresh={refreshData}
          refreshData={refreshData}
        />
      )}
    </>
  )
}

OnlineAttendeeCFNs.propTypes = {
  //eventId: PropTypes.number.isRequired,
}
export default OnlineAttendeeCFNs
