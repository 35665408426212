import { useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Col, DatePicker, Modal, Row, Select } from 'antd'
import { isNil } from 'lodash'
import moment from 'moment'
import http from '../../utils/api'
import BetterFloatingInput from '../BetterFloatingInput/BetterFloatingInput'
import CCUpload from '../CCUpload/CCUpload'
import FloatingTextArea from '../FloatingTextArea/FloatingTextArea'

const SponsorContentModal = ({
  visible,
  handleOk,
  handleCancel,
  editContent,
}) => {
  const [companies, setCompanies] = useState(
    !isNil(editContent)
      ? [
          {
            company: {
              companyId: editContent.companyId,
              name: editContent.companyName,
            },
          },
        ]
      : []
  )
  const [companyId, setcompanyId] = useState(
    !isNil(editContent) ? editContent.companyId : undefined
  )
  const [title, setTitle] = useState(
    !isNil(editContent) ? editContent.title : null
  )
  const [description, setDescription] = useState(
    !isNil(editContent) ? editContent.description : null
  )
  const [date, setDate] = useState(
    !isNil(editContent) ? editContent.date : null
  )
  const [linkUrl, setLinkUrl] = useState(
    !isNil(editContent) ? editContent.linkUrl : null
  )
  const [linkText, setLinkText] = useState(
    !isNil(editContent) ? editContent.linkName : null
  )
  const [imageUrl, setImageUrl] = useState(
    !isNil(editContent) ? editContent.imageUrl : null
  )
  const [active, setActive] = useState(
    !isNil(editContent) ? editContent.isActive : false
  )

  const handleFilterOption = (e) => {
    if (e.length > 2)
      http
        .post('serviceproviders', {
          pagination: {},
          sorter: {},
          searchTerm: {
            searchValue: e,
          },
          filters: {},
        })
        .then((res) => {
          const alc = []
          res.data.result.forEach((element) => {
            alc.push({
              company: element.company,
            })
          })
          console.log(alc)
          setCompanies(alc)
        })
  }

  const handleSelectChange = (e) => {
    const selectedCompany = companies.filter(
      (obj) => obj.company.companyId === e
    )
    setcompanyId(selectedCompany[0].company.companyId)
  }

  const imageChanged = (base64Image) => {
    setImageUrl(base64Image)
  }

  const onOk = () => {
    const content = {
      companyId,
      title,
      description,
      date,
      linkUrl,
      linkName: linkText,
      image: imageUrl,
      isActive: active,
    }

    handleOk(content)
  }

  return (
    <Modal
      className="cc-modal-accept"
      title={
        !isNil(editContent)
          ? 'Edit Sponsored Content'
          : 'Create Sponsored Content'
      }
      visible={visible}
      okText={'Save'}
      cancelText="Cancel"
      onOk={onOk}
      onCancel={handleCancel}
      centered={true}
      cancelButtonProps={{ disabled: false }}
    >
      <h3 className="heading5">Service Provider:</h3>
      <Select
        showSearch
        className="cc-background-input"
        size="large"
        style={{ width: '100%', height: '48px', marginBottom: '6px' }}
        placeholder="Search for Service Provider"
        showArrow={false}
        optionFilterProp="children"
        onChange={handleSelectChange}
        onSearch={handleFilterOption}
        filterOption={false}
        value={companyId}
      >
        {companies.map((row, key) => (
          <Select.Option key={key} value={row.company.companyId}>
            {row.company.name}
          </Select.Option>
        ))}
      </Select>
      <Row style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <div className="heading5">Date (EST):</div>
        </Col>
        <Col span={16}>
          <DatePicker
            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            onChange={(date) => setDate(date._d)}
            placeholder="Date"
            style={{ width: '100%' }}
            format="MMMM Do YYYY, h:mm A"
            defaultValue={
              !isNil(editContent) && moment.utc(editContent.date).local()
            }
          />
        </Col>
      </Row>
      <BetterFloatingInput
        onChange={(e) => setTitle(e.target.value)}
        value={title}
        name="Title"
      />
      <FloatingTextArea
        onChange={(e) => setDescription(e)}
        value={description}
        name="Description (300 char max)"
        maxLength={300}
      />
      <BetterFloatingInput
        onChange={(e) => setLinkText(e.target.value)}
        value={linkText}
        name="Link Text"
      />
      <BetterFloatingInput
        onChange={(e) => setLinkUrl(e.target.value)}
        value={linkUrl}
        name="Link URL"
      />
      <CCUpload
        placeholder="Upload Photo"
        imageUrl={imageUrl}
        onChange={imageChanged}
        style={{ marginBottom: '16px' }}
        rect
      />
      <Checkbox checked={active} onChange={(e) => setActive(e.target.checked)}>
        <strong>Active?</strong>
      </Checkbox>
    </Modal>
  )
}

SponsorContentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  editContent: PropTypes.object,
}

export default SponsorContentModal
