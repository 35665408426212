import { Spin } from 'antd'
import loaderImage from '../../assets/context_loader.gif'

const OnboardingLoading = () => (
  <Spin
    spinning="true"
    style={{ textAlign: 'center' }}
    size="small"
    indicator={
      <img
        style={{
          marginLeft: '-17px',
          textAlign: 'center',
          width: '34px',
          height: '34px',
        }}
        src={loaderImage}
        alt=""
      />
    }
  ></Spin>
)

export default OnboardingLoading
