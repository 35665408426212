import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, DatePicker, Radio, Tooltip, message } from 'antd'
import { isEmpty, isNil } from 'lodash'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'
import http from '../../utils/api'
import LoadingGif from '../LoadingGif'
import Timezone from '../SelectTimezoneComponent/SelectTimezoneComponent'
import './SelectOnlineMeetingDateTime.less'

const RadioGroup = Radio.Group
const RadioButton = Radio.Button

const SelectOnlineMeetingDateTime = ({
  onTimeChanged,
  onDurationChanged,
  participants,
  toParticipants,
  dateTime,
  meetingDuration,
  eventId = null,
  contactFullname,
  selectedOnBehalf,
  defaultTimezone,
  physicalSummit = false,
  meetingId = null,
}) => {
  const [duration, setDuration] = useState(meetingDuration || 30)
  const [date, setDate] = useState(dateTime ? moment.utc(dateTime).local() : '')
  const [loadingSlots, setLoadingSlots] = useState('')
  const [availableDates, setAvailableDates] = useState([])
  const [eventTimeFrame, setEventTimeFrame] = useState(null)
  const [selectedHour, setSelectedHour] = useState(null)
  const [selectedTimezone, setSelectedTimezone] = useState(
    physicalSummit && !isNil(defaultTimezone)
      ? defaultTimezone
      : moment.tz.guess()
  )
  //const userFullname = useSelector(state => state.auth.contact.contactName);

  useEffect(() => {
    const getEventTimeframe = async () => {
      if (!isNil(eventId)) {
        try {
          const eventTimeFrameRaw = await http.get(
            `/events/${eventId}/timeframe`
          )
          const eventTimeFrameResponse = eventTimeFrameRaw.data
          setEventTimeFrame(eventTimeFrameResponse)
          //console.log(eventTimeFrameRaw.data);
        } catch (err) {
          message.error(err)
        }
      }
    }

    getEventTimeframe()
  }, [eventId])

  useEffect(() => {
    if (
      date === '' ||
      date === null ||
      isNil(date) ||
      selectedOnBehalf === false
    ) {
      return
    }
    setLoadingSlots(true)
    const durationForRequest = duration === 30 ? 0 : 1
    let url = `contextmeetings/availabilities?${
      isNil(eventId) ? '' : `eventId=${eventId}`
    }&date=${date.format('YYYY-MM-DD')}&duration=${durationForRequest}&`

    participants.forEach((part) => {
      url = `${url}contactIDs=${part}&`
    })

    toParticipants.forEach((part) => {
      url = `${url}contactIDs=${part}&`
    })

    url = `${url}timeZone=${selectedTimezone}&`
    url = `${url}meetingId=${meetingId}`

    http
      .get(url)
      .then((response) => {
        const convertedDates = response.data.map((dt) => ({
          displayStartTime: moment
            .tz(dt.startTime, selectedTimezone)
            .format('Y-MM-DD HH:mm:ss'),
          displayEndTime: moment
            .tz(dt.endTime, selectedTimezone)
            .format('Y-MM-DD HH:mm:ss'),
          startTime: dt.startTime,
          endTime: dt.endTime,
          busyContactIds: dt.busyContactIds,
          busyContacts: dt.busyContacts,
        }))
        setAvailableDates(convertedDates)

        if (dateTime) {
          const selectedTime = convertedDates.find(
            (x) =>
              x.displayStartTime ===
              moment
                .tz(moment.utc(dateTime), selectedTimezone)
                .format('Y-MM-DD HH:mm:ss')
          )
          setSelectedHour(selectedTime)
        }
      })
      .catch(() => {
        message.error('Error occurred')
      })
      .finally(() => {
        setLoadingSlots(false)
      })
  }, [
    date,
    dateTime,
    duration,
    participants,
    selectedTimezone,
    toParticipants,
    eventId,
    selectedOnBehalf,
  ])

  const handleTimezoneChange = useCallback((e) => {
    setSelectedTimezone(e)
  }, [])

  const getSelect = () => (
    <SimpleBar
      style={{
        maxHeight: '345px',
        padding: '16px 0px 4px 0px',
      }}
    >
      {availableDates && (
        <RadioGroup
          onChange={(val) => {
            setSelectedHour(val.target.value)
            onTimeChanged('hour', val.target.value)
          }}
          className="TimeSlots"
          size="large"
          value={selectedHour}
        >
          {availableDates.map((dt) => (
            <div key={dt.startTime} className="cc-online-meeting-hours-buttons">
              <RadioButton value={dt}>
                {
                  <div>
                    <Badge
                      count={
                        !isEmpty(dt.busyContactIds) ? (
                          <Tooltip
                            title={getTooltipText(dt)}
                            placement="topRight"
                          >
                            <FontAwesomeIcon
                              color={'#F19F00'}
                              icon={faExclamationCircle}
                            />
                          </Tooltip>
                        ) : (
                          0
                        )
                      }
                      className="cc-online-meeting-hours-badge"
                    >
                      {`${moment(dt.displayStartTime).format(
                        'h:mm a'
                      )} - ${moment(dt.displayEndTime).format('h:mm a')}`}
                    </Badge>
                  </div>
                }
              </RadioButton>
            </div>
          ))}
        </RadioGroup>
      )}
    </SimpleBar>
  )

  const getTooltipText = useCallback((info) => {
    if (!isEmpty(info.busyContacts)) {
      const busyNumber = info.busyContacts.length - 1
      const isYou =
        `${info.busyContacts[0].firstName} ${info.busyContacts[0].lastName}` ===
        contactFullname

      return (
        (isYou &&
          `You are already booked on this time slot ${
            busyNumber > 0
              ? `+ ${busyNumber} other${busyNumber > 1 ? 's' : ''}`
              : ''
          }`) ||
        `${info.busyContacts[0].firstName} ${
          info.busyContacts[0].lastName
        } is already booked on this time slot ${
          busyNumber > 0
            ? `+ ${busyNumber} other${busyNumber > 1 ? 's' : ''}`
            : ''
        }`
      )
    }
  }, [])

  return (
    <>
      <div className="online-meeting-date-time-text">Select date</div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%', paddingRight: '10px' }}>
          <div className="online-meeting-date-time-datePicer">
            <DatePicker
              disabledDate={(current) =>
                (current && current < moment().startOf('day')) ||
                (!isNil(eventTimeFrame) &&
                  (current.format('Y-MM-DD') <
                    moment
                      .utc(eventTimeFrame.startTime)
                      .local()
                      .format('Y-MM-DD') ||
                    current.format('Y-MM-DD') >
                      moment
                        .utc(eventTimeFrame.endTime)
                        .local()
                        .format('Y-MM-DD')))
              }
              style={{ width: '100%' }}
              size="large"
              onChange={(value) => {
                setDate(value)
                onTimeChanged('date', moment.utc(value).local())
              }}
              allowClear={false}
              defaultValue={
                dateTime
                  ? moment.utc(dateTime).local()
                  : !isNil(eventTimeFrame)
                  ? moment.utc(eventTimeFrame.startTime).local()
                  : null
              }
            />
          </div>
        </div>
        <div className="online-meeting-date-time-timezone">
          <Timezone
            value={selectedTimezone}
            handleTimezoneChange={handleTimezoneChange}
          />
        </div>
      </div>
      {isNil(eventId) && (
        <>
          {' '}
          <div
            className="online-meeting-date-time-text"
            style={{ marginTop: '30px' }}
          >
            Duration
          </div>
          <div className="online-meeting-date-time-radio-group">
            <Radio.Group
              onChange={(e) => {
                setDuration(e.target.value)
                setSelectedHour(null)
                onTimeChanged('hour', null)
                onDurationChanged(e.target.value)
              }}
              value={duration}
              buttonStyle="solid"
              size="large"
            >
              <Radio.Button
                className="online-meeting-date-time-radio-button-wrapper"
                value={30}
              >
                30 minutes
              </Radio.Button>
              <Radio.Button
                className="online-meeting-date-time-radio-button-wrapper"
                value={60}
              >
                1 hour
              </Radio.Button>
            </Radio.Group>
          </div>
        </>
      )}

      <div style={{ marginTop: '20px', lineHeigth: '1.5' }}>
        {availableDates.length > 0 && (
          <>
            <div
              style={{ marginTop: '30px' }}
              className="online-meeting-date-time-text"
            >
              Time Slot
            </div>
            <LoadingGif spinning={loadingSlots}>{getSelect()}</LoadingGif>
          </>
        )}
      </div>
    </>
  )
}

SelectOnlineMeetingDateTime.propTypes = {
  onTimeChanged: PropTypes.func.isRequired,
  onDurationChanged: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired,
  toParticipants: PropTypes.array.isRequired,
  dateTime: PropTypes.string,
  meetingDuration: PropTypes.number,
  eventId: PropTypes.number,
}

export default SelectOnlineMeetingDateTime
