import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Col,
  Divider,
  Empty,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  message,
} from 'antd'
import { isEmpty, map } from 'lodash'
import { isNil } from 'lodash/fp'
import { useSelector } from 'react-redux'
import {
  SearchForAllocatorEventCompanies,
  getColleagues,
  sendSummitRequestToAllocator,
  sendSummitRequestToSPandContext,
} from '../../actions/contextMeetings.js'
import { getEventFundsForMeetingRequest } from '../../actions/fundList'
import ParticipantsCheckbox from '../ParticipantsCheckbox'
import SelectOnlineMeetingDateTime from '../SelectOnlineMeetingDateTime'
import './SummitAllocatorMeetingRequest.less'

const { TextArea } = Input
const { Option, OptGroup } = Select

const SummitAllocatorMeetingRequest = ({
  visible,
  onClose,
  onRefresh,
  allocator,
  contact,
  eventId,
  // creditsAvailable = 0,
  isElevated = false,
  hasCredits = true,
}) => {
  const role = useSelector((state) => state.auth.role)
  const [currentStep, setCurrentStep] = useState(0)
  const [selectedFundId, setSelectedFundId] = useState(null)
  const [otherColleagues, setOtherColleagues] = useState([])
  const [myColleagues, setMyColleagues] = useState([])
  const [funds, setFunds] = useState([])
  const [participants, setParticipants] = useState([])
  const [toParticipants, setToParticipants] = useState([])
  const [loadingContacts, setLoadingContacts] = useState(true)
  const [selectedContactList, setSelectedContactList] = useState({
    participants: [],
    toParticipants: [],
  })
  const [selectedHour, setSelectedHour] = useState({})
  const [textMessage, setMessage] = useState('')
  const [duration, setDuration] = useState(30)
  const [selectedDateTime, setSelectedDateTime] = useState({})
  const [savingInProggress, setSavinginProggress] = useState(false)

  const [companies, setCompanies] = useState([])
  const [selectedFund, setSelectedFund] = useState(null)
  const [fromCompanyType, setFromCompanyType] = useState(1)
  const [isFundManager, setIsFundManager] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState({})
  const [selectValue, setSelectValue] = useState('')
  const [fromEventCompanyId, setFromEventCompanyId] = useState(0)

  const [senderId, setSenderId] = useState(null)
  const [creditsAvailable, setCreditsAvailable] = useState(0)
  const [contextPointsAvailable, setContextPointsAvailable] = useState(0)

  const [forceReloadMainSender, setForceReloadMainSender] = useState(false)

  const handleParticipantsChanged = useCallback(
    (mine, participants) => {
      if (mine)
        setSelectedContactList({
          participants,
          toParticipants: selectedContactList.toParticipants,
        })
      else
        setSelectedContactList({
          participants: selectedContactList.participants,
          toParticipants: participants,
        })
    },
    [
      selectedContactList.participants,
      selectedContactList.toParticipants,
      setSelectedContactList,
    ]
  )

  const handleDateOk = useCallback(() => {
    setSavinginProggress(true)
    sendSummitRequestToSPandContext(
      allocator.company.companyId,
      selectedFundId,
      selectedContactList.participants,
      selectedContactList.toParticipants,
      textMessage,
      1,
      allocator.companyContactId,
      selectedDateTime.startDateTime,
      duration,
      eventId
    )
      .then((response) => {
        allocator.meeting = {
          meetingType: 'Summit',
          meetingRequestType: 'Sent',
          sent: true,
          received: false,
          meetingId: response.data.result.result.meetingId,
          meetingStatusId: 1,
          meetingStatus: 'Pending',
        }
        message.success('Request sent succesfully.')
        onClose()
      })
      .catch((err) => {
        message.error('Could not send meeting request.')
      })
      .finally(() => {
        setSavinginProggress(false)
        onRefresh()
      })
  }, [
    duration,
    selectedDateTime,
    eventId,
    allocator,
    onClose,
    selectedContactList,
    selectedFundId,
    textMessage,
    onRefresh,
  ])

  const handleCancel = useCallback(() => {
    //setToParticipants([]);
    setSelectedCompany(null)
    setSenderId(null)
    setSelectedFundId(null)
    setFunds([])
    setCompanies([])
    setParticipants([])
    setSelectValue('')
    setCurrentStep(0)
    setMessage('')
    onClose()
    setMyColleagues([])
    setOtherColleagues([])
    setSelectedContactList({ participants: [], toParticipants: [] })
  }, [onClose])

  const handleOk = useCallback(() => {
    setSavinginProggress(true)
    sendSummitRequestToAllocator(
      selectedCompany.company.companyId,
      senderId,
      allocator.company.companyId,
      selectedFundId,
      selectedContactList.participants,
      selectedContactList.toParticipants,
      textMessage,
      1,
      allocator.companyContactId,
      eventId
    )
      .then((response) => {
        message.success('Request sent succesfully.')
      })
      .catch((err) => {
        const allErr = err.response.data.message
        message.error(
          allErr.substring(allErr.indexOf("'") + 1, allErr.indexOf("'."))
        )
      })
      .finally(() => {
        handleCancel()
        onRefresh()
        setSavinginProggress(false)
      })
  }, [
    selectedCompany,
    selectedContactList.participants,
    selectedContactList.toParticipants,
    allocator.company.companyId,
    allocator.companyContactId,
    selectedFundId,
    textMessage,
    eventId,
    onClose,
    onRefresh,
  ])

  const handleParticipantChecked = useCallback(
    (e) => {
      let tmpparticipants
      if (e.target.checked) {
        tmpparticipants = [...participants, e.target.value]
        setParticipants(tmpparticipants)
      } else {
        tmpparticipants = participants.filter((p) => p !== e.target.value)
        setParticipants(tmpparticipants)
      }

      handleParticipantsChanged(true, tmpparticipants)
    },
    [handleParticipantsChanged, participants]
  )

  const handleToParticipantChecked = useCallback(
    (e) => {
      let tmptoParticipants
      if (e.target.checked) {
        tmptoParticipants = [...toParticipants, e.target.value]
        setToParticipants(tmptoParticipants)
      } else {
        tmptoParticipants = toParticipants.filter((p) => p !== e.target.value)
        setToParticipants(tmptoParticipants)
      }

      handleParticipantsChanged(false, tmptoParticipants)
    },
    [handleParticipantsChanged, toParticipants]
  )

  const onTimeChanged = (changed, newDate) => {
    if (newDate) {
      if (changed === 'date') {
        setSelectedHour({})
      }
      if (changed === 'hour') {
        setSelectedHour(newDate)
      }
      setSelectedDateTime({
        startDateTime: newDate.startTime,
        endDateTime: newDate.endTime,
      })
    }
  }

  const getDisabledSendRequest = useCallback(() => {
    if (
      isEmpty(otherColleagues) ||
      isEmpty(selectedContactList.toParticipants) ||
      (isFundManager && isNil(selectedFundId)) ||
      isNil(senderId)
    )
      return true
    else return false
  }, [
    otherColleagues,
    selectedContactList.toParticipants,
    isFundManager,
    selectedFundId,
    senderId,
  ])

  const handleFilterOption = (e) => {
    setCompanies({})
    if (e.length > 2)
      SearchForAllocatorEventCompanies(eventId, e).then((res) => {
        setCompanies(res.data.response)
      })
  }

  const handleFundChange = (e) => {
    setSelectedFundId(e)
    const currentFund = funds.filter((obj) => obj.fundId === e)[0]

    setMyColleagues(currentFund.contacts)

    selectedCompany.contacts = currentFund.contacts
    setSelectedCompany(selectedCompany)
    if (!isEmpty(currentFund.contacts)) setForceReloadMainSender(true)
    else setForceReloadMainSender(false)
  }

  const handleSelectChange = (e) => {
    setForceReloadMainSender(false)
    setSelectedFund(null)
    setFunds([])
    let selectedCompany = companies.funds.filter(
      (obj) => obj.eventCompanyId === e
    )
    if (selectedCompany.length === 0) {
      selectedCompany = companies.serviceProviders.filter(
        (obj) => obj.eventCompanyId === e
      )

      setFromCompanyType(3)
      setIsFundManager(false)
      //}
    } else {
      setIsFundManager(true)
      setFromCompanyType(1)
    }
    setSenderId(null)
    setSelectedCompany(selectedCompany[0])
    setSelectValue(selectedCompany[0].eventCompanyId)
    setFromEventCompanyId(selectedCompany[0].eventCompanyId)
    setMyColleagues(selectedCompany[0].contacts)
    setOtherColleagues([])
  }

  const onRadioChange = useCallback((e) => {
    setLoadingContacts(true)

    let tmpparticipants
    if (e.target.checked) {
      tmpparticipants = participants.filter((p) => p !== e.target.value)
      tmpparticipants = [...myColleagues, e.target.value]
      setParticipants(tmpparticipants)
    } else {
      tmpparticipants = participants.filter((p) => p !== e.target.value)
      setParticipants(tmpparticipants)
    }

    handleParticipantsChanged(true, tmpparticipants)
    setSenderId(e.target.value)
    //setToParticipants([contact.contactId]);
    //handleParticipantsChanged(false, toParticipants);
    setLoadingContacts(false)
  }, [])

  const onMainSenderChange = useCallback((e) => {
    setLoadingContacts(true)

    let tmpparticipants
    ///if (e.target.checked) {
    tmpparticipants = participants.filter((p) => p !== e)
    tmpparticipants = [...myColleagues, e]
    setParticipants(tmpparticipants)
    // } else {
    //   tmpparticipants = participants.filter((p) => p !== e.target.value);
    //   setParticipants(tmpparticipants);
    // }

    handleParticipantsChanged(true, tmpparticipants)
    setSenderId(e)

    setLoadingContacts(false)
  }, [])

  useEffect(() => {
    if (isFundManager && !isNil(selectedCompany))
      getEventFundsForMeetingRequest(
        eventId,
        selectedCompany.company.companyId,
        allocator.company.companyId
      ).then((response) => {
        setFunds(
          response.data.filter((x) => x.hasActiveMeetingRequest === false)
        )

        if (
          isEmpty(
            response.data.filter((x) => x.hasActiveMeetingRequest === false)
          )
        ) {
          onClose()

          message.error(
            `All of your funds have active meetings with ${allocator.company.name}`
          )
        } else {
          setSelectedFundId(
            response.data.filter((x) => x.hasActiveMeetingRequest === false)[0]
              .fundId
          )

          const fundContacts = response.data.filter(
            (x) => x.hasActiveMeetingRequest === false
          )[0].contacts

          setMyColleagues(fundContacts)

          selectedCompany.contacts = fundContacts
          setSelectedCompany(selectedCompany)

          if (!isEmpty(fundContacts)) setForceReloadMainSender(true)
          else setForceReloadMainSender(false)
        }
      })
  }, [eventId, allocator, selectedCompany])

  useEffect(() => {
    setLoadingContacts(true)
    // console.log(senderId);
    // console.log(selectedCompany);
    //(isFundManager && selectedFundId) &&
    !isNil(senderId) &&
      !isNil(selectedCompany) &&
      getColleagues(
        selectedCompany.company.companyId,
        senderId,
        allocator.company.companyId,
        selectedFundId,
        eventId,
        contact?.contactId
      )
        .then((response) => {
          setMyColleagues(
            response.data.find(
              (x) => x.companyId !== allocator.company.companyId
            ).contacts
          )
          setOtherColleagues(
            response.data.find(
              (x) => x.companyId === allocator.company.companyId
            ).contacts
          )

          const toParticipants = response.data
            .find((x) => x.companyId === allocator.company.companyId)
            .contacts.map((contact) => contact.contactId)

          setToParticipants(toParticipants)

          handleParticipantsChanged(false, toParticipants)
        })
        .catch((err) => {
          console.log(err)
          message.error('Could not retrieve colleagues')
        })
        .finally(() => {
          setLoadingContacts(false)
        })
  }, [allocator, visible, selectedFundId, selectedCompany, senderId])

  // useEffect(() => {
  //   if (allocator && contact) {
  //     setToParticipants([contact.contactId]);
  //   }
  // }, [allocator, contact]);

  const footer = (
    <div className="footer">
      <Button onClick={handleCancel}>Cancel</Button>

      <Button
        type="primary"
        disabled={
          isEmpty(otherColleagues) ||
          isEmpty(selectedContactList.toParticipants) ||
          (isFundManager && isNil(selectedFundId)) ||
          isNil(senderId)
        }
        onClick={() => handleOk()}
        loading={savingInProggress}
      >
        Send Meeting Request
      </Button>
    </div>
  )
  const dateFooter = (
    <div className="footer">
      <Button onClick={handleCancel}>Cancel</Button>
      {currentStep === 0 && (
        <Button
          type="primary"
          onClick={() => {
            setCurrentStep(1)
          }}
          disabled={getDisabledSendRequest()}
        >
          Next
        </Button>
      )}
      {currentStep === 1 && (
        <Button
          type="primary"
          onClick={() => {
            setCurrentStep(0)
          }}
        >
          Previous
        </Button>
      )}
      {currentStep === 1 && (
        <Button
          type="primary"
          disabled={
            isEmpty(selectedDateTime) || isEmpty(selectedHour) || isEmpty(funds)
          }
          onClick={() => handleDateOk()}
        >
          Send Meeting Request
        </Button>
      )}
    </div>
  )
  return (
    <Modal
      centered
      className="FundOnlineMR"
      visible={visible}
      width="700px"
      height="700px"
      title="Request a Meeting"
      footer={(isElevated && dateFooter) || footer}
      onCancel={handleCancel}
      bodyStyle={{ padding: 0 }}
      destroyOnClose={true}
    >
      {currentStep === 0 && (
        <div style={{ paddingLeft: '24px' }}>
          {/* from details */}
          <Row>
            <Col span="24">
              <h3 className="heading5" style={{ marginTop: '16px' }}>
                From:
              </h3>
              <Select
                showSearch
                className="cc-background-input"
                size="default"
                style={{ width: '90%', height: '35' }}
                placeholder="From"
                showArrow={false}
                optionFilterProp="children"
                onChange={handleSelectChange}
                onSearch={handleFilterOption}
                filterOption={false}
                value={selectValue}
              >
                <OptGroup label="Funds">
                  {companies &&
                    companies.funds &&
                    companies.funds.map((row) => (
                      <Select.Option value={row.eventCompanyId}>
                        {row.company.name}
                      </Select.Option>
                    ))}
                </OptGroup>
                <OptGroup label="Service Providers">
                  {companies &&
                    companies.serviceProviders &&
                    companies.serviceProviders.map((row) => (
                      <Select.Option value={row.eventCompanyId}>
                        {row.company.name}
                      </Select.Option>
                    ))}
                </OptGroup>
              </Select>
            </Col>
          </Row>
          {!isEmpty(funds) && (
            <Row>
              <Col span="24">
                <h3 className="heading5" style={{ marginTop: '16px' }}>
                  FUND TO PRESENT
                </h3>
                <Select
                  className="cc-background-input"
                  size="default"
                  defaultActiveFirstOption={true}
                  defaultValue={funds[0].name}
                  style={{ width: '90%', height: '35px' }}
                  placeholder="Select fund"
                  onChange={handleFundChange}
                >
                  {funds &&
                    funds.map((fund) => (
                      <Option key={fund.fundId} value={fund.fundId}>
                        {fund.name}
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>
          )}

          {!isNil(selectedCompany) &&
            (!isEmpty(selectedCompany.contacts) || forceReloadMainSender) && (
              <Row>
                <Col span="24">
                  <h3 className="heading5" style={{ marginTop: '16px' }}>
                    MAIN SENDER
                  </h3>
                  <Select
                    className="cc-background-input"
                    size="default"
                    value={senderId}
                    defaultActiveFirstOption={true}
                    defaultValue={selectedCompany.contacts[0].contactId}
                    style={{ width: '90%', height: '35px' }}
                    placeholder="Select main sender"
                    onChange={(e) => {
                      onMainSenderChange(e)
                    }}
                  >
                    {selectedCompany.contacts.map((row) => (
                      <Option key={row.contactId} value={row.contactId}>
                        {row.contactName}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            )}
          {!isNil(myColleagues) && !isEmpty(myColleagues) && (
            <Row>
              <Col span="24">
                <h3 className="heading5" style={{ marginTop: '16px' }}>
                  OTHER PARTICIPANTS
                </h3>
                {myColleagues &&
                  myColleagues.map((contact) => (
                    <ParticipantsCheckbox
                      key={contact.contactId}
                      participants={participants}
                      checked={handleParticipantChecked}
                      contactId={contact.contactId}
                      contactName={`${contact.firstName} ${contact.lastName}`}
                      imageURL={contact.imageUrl}
                      position={contact.jobTitle}
                      disabled={contact.contactId === senderId}
                      //disabled={contact.contactId === userId}
                    />
                  ))}{' '}
              </Col>
            </Row>
          )}

          <Divider
            type="horizontal"
            style={{
              backgroundColor: '#F7F8FA',
              margin: '14px 0px 0px 0px',
            }}
          />
          {/* to details */}

          {!isNil(otherColleagues) && !isEmpty(otherColleagues) && (
            <Row>
              <Col span={24}>
                <h3 className="heading5">
                  Sending Request To:
                  {loadingContacts ? ' ' : ` ${allocator.company.name}`}
                </h3>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                  }}
                >
                  {loadingContacts ? (
                    <Spin spinning={loadingContacts} size="large">
                      <Empty />
                    </Spin>
                  ) : (
                    otherColleagues &&
                    otherColleagues.map((contact) => (
                      <ParticipantsCheckbox
                        key={contact.contactId}
                        {...contact}
                        position={contact.jobTitle}
                        participants={toParticipants}
                        checked={handleToParticipantChecked}
                        imageURL={contact.imageUrl}
                        //disabled={contact.contactId === allocator.contactId}
                      />
                    ))
                  )}
                </div>
              </Col>
            </Row>
          )}
          <Divider
            type="horizontal"
            style={{
              backgroundColor: '#F7F8FA',
              margin: '14px 0px 0px 0px',
            }}
          />
          <Row>
            <Col span="24">
              <div>
                <div className="cc-tabletitle-text cc-darkest-grey-text">
                  Send Message
                </div>
                <div style={{ width: '100%', padding: '20px 20px 20px 0px' }}>
                  <TextArea
                    placeholder="Write a message..."
                    rows={4}
                    value={textMessage}
                    onChange={({ target: { value } }) => {
                      setMessage(value)
                    }}
                  />
                </div>
                {role === 'Manager' && (
                  <div
                    className="cc-credit-wrapper"
                    style={{ marginTop: '15px' }}
                  >
                    <span className="cc-credit-cost">
                      This request will cost you 1 Credits
                    </span>
                    <span className="cc-credit-left">
                      You have {creditsAvailable} Credits left.
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
      {currentStep === 1 && (
        <div style={{ padding: '20px' }}>
          <SelectOnlineMeetingDateTime
            onTimeChanged={onTimeChanged}
            onDurationChanged={setDuration}
            participants={participants}
            toParticipants={toParticipants}
            eventId={eventId}
          />
        </div>
      )}
      {/* </LoadingGif> */}
    </Modal>
  )
}

SummitAllocatorMeetingRequest.propTypes = {
  visible: PropTypes.bool.isRequired,
  //handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
  allocator: PropTypes.object.isRequired,
  fund: PropTypes.string.isRequired,
  fundId: PropTypes.number.isRequired,
  fundContacts: PropTypes.array,
  eventId: PropTypes.bool,
  isElevated: PropTypes.bool,
  creditsAvailable: PropTypes.number,
  contextPointsAvailable: PropTypes.number,
  hasCredits: PropTypes.bool,
}

export default SummitAllocatorMeetingRequest
