import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Divider, Row, Typography } from 'antd'
import { filter, isNil, map } from 'lodash'
import mime from 'mime-types'
import http from '../../utils/api'
import ServiceProviderDynamicFieldsModal from '../CompanyEditModals/ServiceProviderDynamicFieldsModal'
import EditButton from '../EditButton/EditButton'
import '../DiscoverServiceProviderDetailsModal/DiscoverServiceProviderProfile.less'

const { Title } = Typography

const getEndPoint = (category) => {
  switch (category) {
    case 'fund':
      return 'fundmanagerdetails'
    case 'serviceprovider':
      return 'serviceproviderdetails'
    case 'media':
      return 'mediadetails'
    case 'allocator':
      return 'allocatordetails'
    default:
      return ''
  }
}

const CompanyTestimonials = ({ category, companyId }) => {
  const noEditState = {
    generalInfo: false,
    address: false,
    description: false,
    companyDetails: false,
    testimonials: false,
  }

  const [buttonVisible, setButtonVisible] = useState(noEditState)
  const [currentModal, setCurrentModal] = useState(null)
  const [company, setCompany] = useState({})

  const [questions, setQuestions] = useState([])
  const [questionTypes, setQuestionTypes] = useState([])
  const [answers, setAnswers] = useState([])

  const [previewObject, setPreviewObject] = useState({})

  const questionsWithOptions = new Set(
    map(
      filter(questionTypes, (qt) => qt.answers),
      (qt) => qt.questionTypeId
    )
  )

  const toggleEdit = (section, value) => {
    const newState = { ...noEditState }
    newState[section] = value
    setButtonVisible(newState)
  }

  useEffect(() => {
    let type = ''

    type = getEndPoint(category)

    http
      .get(`/companies/${companyId}/${type}`)
      .then(function (response) {
        setCompany(response.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })

    if (type === 'serviceproviderdetails') {
      http
        .get('/serviceproviders/questions')
        .then(function (response) {
          setQuestions(response.data)
        })
        .catch(function (error) {
          console.log(error)
        })
      if (!isNil(companyId)) {
        http
          .get(`/serviceproviders/0/${companyId}/answers`)
          .then(function (response) {
            setAnswers(response.data.answers)
            const { answers } = response.data
            const peopleAnswer = answers.find(
              (answer) => answer.shortName === 'People'
            )

            const testimonialsAnswer = answers.find(
              (answer) => answer.shortName === 'Testimonials'
            )

            const clientsAnswer = answers.find(
              (answer) => answer.shortName === 'Clients'
            )

            const blogLinkAnswer = answers.find(
              (answer) => answer.shortName === 'BlogLink'
            )
            const caseStudiesLinkAnswer = answers.find(
              (answer) => answer.shortName === 'CaseStudiesLink'
            )

            const webCastsLinkAnswer = answers.find(
              (answer) => answer.shortName === 'WebCastsLink'
            )

            const whitePaperGuidesLinkAnswer = answers.find(
              (answer) => answer.shortName === 'WhitePaperGuidesLink'
            )

            const facebookPageLinkAnswer = answers.find(
              (answer) => answer.shortName === 'FacebookPageLink'
            )
            const twitterProfileLink = answers.find(
              (answer) => answer.shortName === 'TwitterProfileLink'
            )

            const linkedInPageLink = answers.find(
              (answer) => answer.shortName === 'LinkedInPageLink'
            )

            const instagramProfileLink = answers.find(
              (answer) => answer.shortName === 'InstagramProfileLink'
            )

            const logoLink = answers.find(
              (answer) => answer.shortName === 'Logo'
            )

            const companyMediaLink = answers.find(
              (answer) => answer.shortName === 'CompanyMedia'
            )

            const parsedAnswers = (answers) =>
              isNil(answers) || isNil(answers.responseText)
                ? null
                : JSON.parse(answers.responseText)

            const imagesList = parsedAnswers(companyMediaLink)?.filter(
              (link) => !mime.lookup(link).includes('video')
            )

            const videoLink = parsedAnswers(companyMediaLink)?.filter((link) =>
              mime.lookup(link).includes('video')
            )
            const object = {
              people: parsedAnswers(peopleAnswer),
              testimonials: parsedAnswers(testimonialsAnswer),
              clients: parsedAnswers(clientsAnswer),
              blogUrl: blogLinkAnswer?.responseText,
              caseStudiesUrl: caseStudiesLinkAnswer?.responseText,
              webCastsUrl: webCastsLinkAnswer?.responseText,
              whitepapersAndGuidesUrl: whitePaperGuidesLinkAnswer?.responseText,
              facebookUrl: facebookPageLinkAnswer?.responseText,
              twitterUrl: twitterProfileLink?.responseText,
              linkedInUrl: linkedInPageLink?.responseText,
              instagramUrl: instagramProfileLink?.responseText,
              imageUrl: logoLink?.responseText,
              images: imagesList,
              videoUrl: videoLink,
              companyName: company[0]?.name,
              address: company[0]?.address,
              city: company[0]?.city,
              description: company[0]?.companyDescription,
              website: company[0]?.companyWebsite,
            }
            setPreviewObject(object)
          })
          .catch(function (error) {
            console.log(error)
          })
      }
      http
        .get('/questions/types')
        .then(function (response) {
          setQuestionTypes(response.data)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [currentModal])

  return (
    <div className="CompanyDetails-Wrapper">
      <Row
        type="flex"
        align="top"
        gutter={16}
        style={{ backgroundColor: 'transparent' }}
        onMouseEnter={() => toggleEdit('testimonials', true)}
        onMouseLeave={() => toggleEdit('testimonials', false)}
      >
        <Col span={24}>
          <Row type="flex" justify="space-between" align="middle">
            <Col span={12}>
              <h1>&nbsp;</h1>
            </Col>
            <Col span={12}>
              <div
                style={{ textAlign: 'right' }}
                hidden={!buttonVisible.testimonials}
              >
                <EditButton onClick={() => setCurrentModal('testimonials')} />
              </div>
            </Col>
          </Row>
        </Col>
        {previewObject.testimonials &&
          previewObject.testimonials.length > 0 &&
          previewObject.testimonials.map((testimonial, key) => (
            <Col
              span={12}
              style={{
                marginBottom: '20px',
              }}
              key={key}
            >
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                style={{ background: 'white', borderRadius: '5px' }}
              >
                <Col span={24}>
                  <Row
                    type="flex"
                    justify="space-between"
                    align="middle"
                    className="paddedRow"
                  >
                    <Title level={4} className="header">
                      {testimonial.title}
                    </Title>
                  </Row>
                  <Divider />
                  <Row
                    type="flex"
                    justify="space-between"
                    align="middle"
                    className="paddedRow"
                  >
                    <div className="boldText">{testimonial.description}</div>
                  </Row>
                  <Divider />
                  <Row
                    type="flex"
                    justify="space-between"
                    align="middle"
                    className="paddedRow"
                  >
                    <div className="boldText">
                      {testimonial.fullName}, {testimonial.jobPosition}
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
          ))}
      </Row>

      {company.createdAt && currentModal === 'testimonials' && (
        <ServiceProviderDynamicFieldsModal
          visible={currentModal === 'testimonials'}
          company={company}
          companyId={companyId}
          handleCancel={() => setCurrentModal(null)}
          handleOk={() => setCurrentModal(null)}
          questions={questions}
          answers={answers}
          questionsWithOptions={questionsWithOptions}
          section={currentModal}
        />
      )}
    </div>
  )
}

CompanyTestimonials.propTypes = {
  companyId: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
}

export default CompanyTestimonials
