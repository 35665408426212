import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getEventCFNsList, getEventSortFilters } from '../../actions/events'
import { CATEGORY_ALLOCATOR } from '../../constants/types/categories'
import { getSummitCFNAttendeeDefaultColumns } from '../../utils/columns'
import ContextTable from '../ContextTable'

const SummitAttendeeCFNs = ({ eventId, isPast, isVirtual }) => {
  const [refreshData, setRefreshData] = useState(false)
  const [columns, setColumns] = useState(null)

  const refresh = useCallback(() => {
    setRefreshData(!refreshData)
  }, [refreshData])

  useEffect(() => {
    const cols = getSummitCFNAttendeeDefaultColumns(refresh, isVirtual)
    setColumns(cols)
  }, [isVirtual, refresh])

  const getSavedQuery = () => getEventSortFilters(eventId, CATEGORY_ALLOCATOR)

  return (
    <>
      {columns && (
        <ContextTable
          tableTitle="Family Members"
          columns={columns}
          eventId={eventId}
          dataMethod={getEventCFNsList}
          savedQueryMethod={getSavedQuery}
          refresh={refreshData}
          refreshData={refreshData}
        />
      )}
    </>
  )
}

SummitAttendeeCFNs.propTypes = {
  eventId: PropTypes.number.isRequired,
}
export default SummitAttendeeCFNs
