import { useState } from 'react'
import { Button, Card } from 'antd'
import moment from 'moment'
import AcceptMeetingComponent from '../../AcceptMeetingComponent'
import CancelMeetingButton from '../../CancelMeetingButton'
import { CCTableDateTimeCell } from '../../CCTable/CCTable'
import ContactName from '../../ContactName'
import RescheduleMeeting from '../../RescheduleMeeting'
import './OnlineMeetingRecivedRequests.less'
import '../../../styles/index.less'

export const CONTACT_NAME = 'CONTANT_NAME'
const ResponsiveCard = ({ requests, columns, meetingType }) => {
  const [showRescheduleModal, setShowRescheduleModal] = useState(false)
  const [selectedMeetingId, setSelectedMeetingId] = useState(false)

  const handleRescheduleClick = (meetingId) => {
    setShowRescheduleModal(true)
    setSelectedMeetingId(meetingId)
  }
  const closeModal = () => {
    setShowRescheduleModal(false)
  }

  return (
    <div>
      <RescheduleMeeting
        visible={showRescheduleModal}
        closeModal={closeModal}
        onlineMeetingId={selectedMeetingId}
      />
      {requests !== 'undefined' &&
        requests.map((req) => (
          <Card
            key={req.meetingId}
            className="cc-shadow2"
            style={{
              margin: 'auto',
              marginBottom: '8px',
              borderRadius: '4px',
              maxWidth: '90%',
            }}
          >
            <div className="cc-responsive-card-block">
              {columns.map(
                (col) =>
                  !col.notRenderInCard && (
                    <div className="cc-card-body-group" key={col.key}>
                      <div>
                        <span className="cc-tabletitle-text">{col.title}</span>
                      </div>
                      <div>
                        {col.cardType === CONTACT_NAME ? (
                          <ContactName
                            name={req[col.contactDataIndex]}
                            fontClass="cc-body-text"
                            marginTop={0}
                          />
                        ) : col.cellType === CCTableDateTimeCell ? (
                          <div>
                            <span>
                              {req[col.dataIndex] === null
                                ? '-'
                                : moment(req[col.dataIndex]).format(
                                    col.dateTimeFormat
                                  )}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <span
                              className={`${col.className} cc-tabletitle-text`}
                            >
                              {col.render
                                ? col.render(req)
                                : req[col.dataIndex]}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  )
              )}
            </div>

            <div className="cc-meeting-actions" style={{ float: 'right' }}>
              <CancelMeetingButton
                company={req.company.companyName}
                meetingType={meetingType}
                cancelText="Cancel"
                meetingId={req.meetingId}
              />
              {meetingType === 'online' ||
                (meetingType === 'Confirmed Meetings' && (
                  <Button
                    type="primary"
                    onClick={handleRescheduleClick.bind(null, req.meetingId)}
                    className="cc-btn cc-btn-notice"
                  >
                    <i className="cc-btn-bg" />
                    {'Reschedule'}
                  </Button>
                ))}
              {meetingType !== 'Confirmed Meetings' &&
                meetingType !== 'Sent Requests' && (
                  <AcceptMeetingComponent
                    buttonType="primary"
                    meetingType={meetingType}
                    company={req.company.companyName}
                    meetingId={req.meetingId}
                  />
                )}
            </div>
          </Card>
        ))}
    </div>
  )
}
export default ResponsiveCard
