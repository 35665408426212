import { useEffect, useState } from 'react'
import { Spin, message } from 'antd'
import { isNil, map } from 'lodash'
import {
  addContentEntry,
  getContentEntry,
  updateContentEntry,
} from '../actions/contentEntries'
import loaderImage from '../assets/context_loader.gif'
import ContentEntryWizard from '../components/ContentEntryWizard/ContentEntryWizard'

const initialValues = {
  title: '',
  subtitle: '',
  description: '',
  publishDateTimeUtc: new Date(),
  endDateTimeUtc: null,
  mediaTypeId: null,
  mediaContent: null,
  contentCategoryIds: [],
  authorContact: null,
  taggedContacts: [],
  coverImageBase64: null,
  isFeatured: null,
  includeInWeeklyDigest: null,
}

const ContentEntryWizardContainer = ({
  match: {
    params: { contentEntryId },
  },
  history: { goBack, push },
}) => {
  const [values, setValues] = useState(initialValues)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!isNil(contentEntryId)) {
      setLoading(true)
      getContentEntry(contentEntryId)
        .then((res) => {
          const vals = res.data
          vals.contentCategoryIds = map(
            vals.contentCategories,
            (x) => x.contentCategoryId
          )
          vals.mediaTypeId = vals.media.contentEntryMediaTypeId
          vals.mediaContent = vals.media.url
          if (vals.mediaTypeId === 3) {
            vals.defaultPdf = vals.mediaContent
          }
          setValues(vals)
          setLoading(false)
        })
        .catch((err) => message.error('An error has occurred'))
    }
  }, [])

  const onFinish = (contentEntry) => {
    if (isNil(contentEntryId)) {
      addContentEntry(contentEntry)
        .then((res) => push('/content'))
        .catch((err) => message.error('An error has occurred'))
    } else {
      updateContentEntry(contentEntryId, contentEntry)
        .then((res) => push('/content'))
        .catch((err) => message.error('An error has occurred'))
    }
  }

  return (
    <Spin
      size="large"
      spinning={loading}
      style={{ width: '100%' }}
      indicator={
        <img
          style={{ marginLeft: '-50px', width: '100px', height: '100px' }}
          src={loaderImage}
        />
      }
    >
      {!loading && (
        <ContentEntryWizard onFinish={onFinish} initialValues={values} />
      )}
    </Spin>
  )
}

ContentEntryWizardContainer.propTypes = {}

export default ContentEntryWizardContainer
