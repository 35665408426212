import {
  EMPTY_CALENDAR,
  FETCH_ALL_REQUESTED_MEETINGS,
  FETCH_CALENDAR_MEETINGS,
  FETCH_CONFIRMED_MEETINGS,
  FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID,
  FETCH_DECLINED_MEETINGS,
  FETCH_DECLINED_MEETINGS_COMPANYEVENTID,
  FETCH_ONLINE_CONFIRMED_MEETINGS,
  FETCH_ONLINE_DECLINED_MEETINGS,
  FETCH_ONLINE_MEETING_PARTICIPANTS,
  FETCH_ONLINE_REQUESTED_MEETINGS,
  FETCH_ONLINE_SENT_REQUESTS,
  FETCH_REQUESTED_MEETINGS,
  FETCH_REQUESTED_MEETINGS_COMPANYEVENTID,
  FETCH_SENT_REQUESTS,
  FETCH_SENT_REQUESTS_COMPANYEVENTID,
  FETCH_UPCOMING_MEETINGS,
} from '../constants/types'

const initialState = {
  upcomingMeetings: [],
  allRequestedMeetings: [],
  calendarMeetings: [],
  requestedMeetings: { result: [] },
  sentRequests: { result: [] },
  confirmedMeetings: { result: [] },
  declinedMeetings: { result: [] },
  onlineRequestedMeetings: { result: [] },
  onlineSentRequests: { result: [] },
  onlineConfirmedMeetings: { result: [] },
  onlineDeclinedMeetings: { result: [] },
  requestedMeetingsCompanyEventId: { result: [] },
  sentRequestsCompanyEventId: { result: [] },
  confirmedMeetingsCompanyEventId: { result: [] },
  declinedMeetingsCompanyEventId: { result: [] },
  onlineMeetingParticipants: { result: [] },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_UPCOMING_MEETINGS:
      return { ...state, upcomingMeetings: action.payload }
    case FETCH_ALL_REQUESTED_MEETINGS:
      return { ...state, allRequestedMeetings: action.payload }
    case FETCH_REQUESTED_MEETINGS:
      return { ...state, requestedMeetings: action.payload }
    case FETCH_CALENDAR_MEETINGS:
      return { ...state, calendarMeetings: action.payload }
    case FETCH_SENT_REQUESTS:
      return { ...state, sentRequests: action.payload }
    case FETCH_CONFIRMED_MEETINGS:
      return { ...state, confirmedMeetings: action.payload }
    case FETCH_DECLINED_MEETINGS:
      return { ...state, declinedMeetings: action.payload }
    case FETCH_ONLINE_SENT_REQUESTS:
      return { ...state, onlineSentRequests: action.payload }
    case FETCH_ONLINE_REQUESTED_MEETINGS:
      return { ...state, onlineRequestedMeetings: action.payload }
    case FETCH_ONLINE_CONFIRMED_MEETINGS:
      return { ...state, onlineConfirmedMeetings: action.payload }
    case FETCH_ONLINE_DECLINED_MEETINGS:
      return { ...state, onlineDeclinedMeetings: action.payload }
    case FETCH_REQUESTED_MEETINGS_COMPANYEVENTID:
      return { ...state, requestedMeetingsCompanyEventId: action.payload }
    case FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID:
      return { ...state, confirmedMeetingsCompanyEventId: action.payload }
    case FETCH_SENT_REQUESTS_COMPANYEVENTID:
      return { ...state, sentRequestsCompanyEventId: action.payload }
    case FETCH_DECLINED_MEETINGS_COMPANYEVENTID:
      return { ...state, declinedMeetingsCompanyEventId: action.payload }
    case EMPTY_CALENDAR:
      return { ...state, calendarMeetings: action.payload }
    case FETCH_ONLINE_MEETING_PARTICIPANTS:
      return { ...state, onlineMeetingParticipants: action.payload }
    default:
      return state
  }
}
