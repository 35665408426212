import { isNil } from 'lodash'
import qs from 'qs'
import {
  FETCH_CONFIRMED_MEETINGS,
  FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID,
  FETCH_DECLINED_MEETINGS,
  FETCH_DECLINED_MEETINGS_COMPANYEVENTID,
  FETCH_ONLINE_CONFIRMED_MEETINGS,
  FETCH_ONLINE_DECLINED_MEETINGS,
  FETCH_ONLINE_REQUESTED_MEETINGS,
  FETCH_ONLINE_SENT_REQUESTS,
  FETCH_REQUESTED_MEETINGS,
  FETCH_REQUESTED_MEETINGS_COMPANYEVENTID,
  FETCH_SENT_REQUESTS,
  FETCH_SENT_REQUESTS_COMPANYEVENTID,
} from '../constants/types'
import http from '../utils/api'

const getRequestParams = (params) => ({
  params,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
})

//actions
export const validateOnlineMeeting = (
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  answers
) =>
  http.post('meetings/validation', {
    toCompanyId,
    fundId,
    message,
    MeetingTypeId: 3,
    contactIds,
    toContactIds,
    answers,
  })

export const sendOnlineRequestToAllocator = (
  companyId,
  contactId,
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  meetingTypeId,
  answers,
  originalCompanyContactId,
  proposedTimes,
  meetingDuration,
  invitedCompanyContactIds
) =>
  http.post(`contextmeetings/${companyId}/${contactId}`, {
    toCompanyId,
    fundId,
    message,
    MeetingTypeId: meetingTypeId,
    contactIds,
    toContactIds,
    answers,
    originalCompanyContactId,
    proposedTimes,
    meetingDuration,
    invitedCompanyContactIds,
  })

export const sendSummitRequestToSPandContext = (
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  meetingTypeId,
  originalCompanyContactId,
  meetingDateTime,
  meetingDuration,
  eventId
) =>
  http.post('meetings', {
    toCompanyId,
    fundId,
    message,
    meetingTypeId,
    contactIds,
    toContactIds,
    originalCompanyContactId,
    meetingDateTime,
    meetingDuration,
    eventId,
  })

export const sendSummitRequestToAllocator = (
  companyId,
  contactId,
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  meetingTypeId,
  originalCompanyContactId,
  eventId
) =>
  http.post(`contextmeetings/${companyId}/${contactId}`, {
    toCompanyId,
    fundId,
    message,
    meetingTypeId,
    contactIds,
    toContactIds,
    originalCompanyContactId,
    eventId,
  })

export const sendOnlineRequestToFund = (
  companyId,
  contactId,
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  meetingTypeId,
  proposedTimes,
  meetingDuration,
  invitedCompanyContactIds
) =>
  http.post(`contextmeetings/${companyId}/${contactId}`, {
    toCompanyId,
    fundId,
    message,
    meetingTypeId,
    contactIds,
    toContactIds,
    proposedTimes,
    meetingDuration,
    invitedCompanyContactIds,
  })

export const sendSummitRequestToSP = (
  companyId,
  contactId,
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  meetingTypeId,
  meetingDateTime,
  meetingDuration,
  eventId
) =>
  http.post(`contextmeetings/${companyId}/${contactId}`, {
    toCompanyId,
    fundId,
    message,
    meetingTypeId,
    contactIds,
    toContactIds,
    meetingDateTime,
    meetingDuration,
    eventId,
  })

export const sendSummitRequestToFund = (
  companyId,
  contactId,
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  meetingTypeId,
  meetingDateTime,
  meetingDuration,
  eventId
) =>
  http.post(`contextmeetings/${companyId}/${contactId}`, {
    toCompanyId,
    fundId,
    message,
    meetingTypeId,
    contactIds,
    toContactIds,
    meetingDateTime,
    meetingDuration,
    eventId,
  })

export const sendSummitRequestFromSP = (
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  meetingTypeId,
  sendContextPoints,
  eventId
) =>
  http.post('meetings', {
    toCompanyId,
    fundId,
    message,
    meetingTypeId,
    contactIds,
    carryContextPoints: sendContextPoints,
    toContactIds,
    eventId,
  })

export const acceptMeetingAllocator = (
  companyId,
  contactId,
  meetingId,
  meetingDateTime,
  meetingDuration,
  //eventId,
  proposedMeetingDateTimeId,
  answers
) =>
  http.patch(`contextmeetings/${companyId}/${contactId}/${meetingId}/accept`, {
    meetingId,
    meetingDateTime,
    meetingDuration,
    //eventId,
    proposedMeetingDateTimeId,
    answers,
  })

// export const acceptOnlineMeetingAllocator = (
//   companyId,
//   contactId,
//   meetingId,
//   meetingDateTime,
//   meetingDuration,
//   proposedMeetingDateTimeId,
//   answers
// ) =>
//   http.patch(`contextmeetings/${meetingId}/accept/${companyId}/${contactId}`, {
//     meetingId,
//     meetingDateTime,
//     meetingDuration,
//     proposedMeetingDateTimeId,
//     answers,
//   });

// export const declineOnlineMeeting = (meetingId, companyId, contactId) =>
//   http.patch(`contextmeetings/${meetingId}/decline/${companyId}/${contactId}`);

export const declineMeeting = (companyId, contactId, meetingId) =>
  http.patch(`contextmeetings/${companyId}/${contactId}/${meetingId}/decline`)

export const cancelMeeting = (
  companyId,
  contactId,
  meetingId,
  cancellationReasonId,
  cancellationReasonText
) =>
  http.patch(`contextmeetings/${companyId}/${contactId}/${meetingId}/cancel`, {
    cancellationReasonId,
    cancellationReasonText,
  })
// export const cancelOnlineMeeting = (
//   meetingId,
//   cancellationReasonId,
//   cancellationReasonText,
//   companyId,
//   contactId
// ) =>
//   http.patch(`contextmeetings/${meetingId}/cancel/${companyId}/${contactId}`, {
//     cancellationReasonId,
//     cancellationReasonText,
//   });
export const cancelMeetingForAll = (
  companyId,
  contactId,
  meetingId,
  cancellationReasonId,
  cancellationReasonText
) =>
  http.patch(
    `contextmeetings/${companyId}/${contactId}/${meetingId}/cancelForAll`,
    {
      cancellationReasonId,
      cancellationReasonText,
    }
  )

// export const cancelOnlineMeetingForAll = (
//   meetingId,
//   cancellationReasonId,
//   cancellationReasonText,
//   companyId,
//   contactId
// ) =>
//   http.patch(
//     `contextmeetings/${meetingId}/cancelForAll/${companyId}/${contactId}`,
//     {
//       cancellationReasonId,
//       cancellationReasonText,
//     }
//   );

export const rescheduleMeeting = (
  companyId,
  contactId,
  meetingId,
  meetingDateTime,
  meetingDuration
) =>
  http.patch(
    `contextmeetings/${companyId}/${contactId}/${meetingId}/reschedule`,
    {
      meetingDateTime,
      meetingDuration,
    }
  )
// export const rescheduleOnlineMeeting = (
//   meetingId,
//   meetingDateTime,
//   meetingDuration,
//   companyId,
//   contactId
// ) =>
//   http.patch(
//     `contextmeetings/${meetingId}/reschedule/${companyId}/${contactId}`,
//     {
//       meetingDateTime,
//       meetingDuration,
//     }
//   );

//lists

export const fetchReceivedRequests = (params) => (dispatch) =>
  http.get('meetings/received', getRequestParams(params)).then((response) => {
    if (params.eventId && params.eventId > 0)
      dispatch({
        type: FETCH_REQUESTED_MEETINGS_COMPANYEVENTID,
        payload: response.data.result,
      })
    else {
      if (params.meetingTypeId === 1) {
        dispatch({
          type: FETCH_REQUESTED_MEETINGS,
          payload: response.data.result,
        })
      } else if (params.meetingTypeId === 3) {
        dispatch({
          type: FETCH_ONLINE_REQUESTED_MEETINGS,
          payload: response.data.result,
        })
      }
    }
  })
export const fetchMeetingsConfirmed = (params) => (dispatch) =>
  http.get('meetings/confirmed', getRequestParams(params)).then((response) => {
    if (params.eventId && params.eventId > 0)
      dispatch({
        type: FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID,
        payload: response.data.result,
      })
    else {
      if (params.meetingTypeId === 1) {
        dispatch({
          type: FETCH_CONFIRMED_MEETINGS,
          payload: response.data.result,
        })
      } else if (params.meetingTypeId === 3) {
        dispatch({
          type: FETCH_ONLINE_CONFIRMED_MEETINGS,
          payload: response.data.result,
        })
      }
    }
  })
export const fetchSentRequests = (params) => (dispatch) =>
  http.get('meetings/sent', getRequestParams(params)).then((response) => {
    if (params.eventId && params.eventId > 0)
      dispatch({
        type: FETCH_SENT_REQUESTS_COMPANYEVENTID,
        payload: response.data.result,
      })
    else {
      if (params.meetingTypeId === 1) {
        dispatch({
          type: FETCH_SENT_REQUESTS,
          payload: response.data.result,
        })
      } else if (params.meetingTypeId === 3) {
        dispatch({
          type: FETCH_ONLINE_SENT_REQUESTS,
          payload: response.data.result,
        })
      }
    }
  })
export const fetchDeclinedRequests = (params) => (dispatch) =>
  http.get('meetings/declined', getRequestParams(params)).then((response) => {
    if (params.eventId && params.eventId > 0)
      dispatch({
        type: FETCH_DECLINED_MEETINGS_COMPANYEVENTID,
        payload: response.data.result,
      })
    else {
      if (params.meetingTypeId === 1) {
        dispatch({
          type: FETCH_DECLINED_MEETINGS,
          payload: response.data.result,
        })
      } else if (params.meetingTypeId === 3) {
        dispatch({
          type: FETCH_ONLINE_DECLINED_MEETINGS,
          payload: response.data.result,
        })
      }
    }
  })

export const SearchForAllocatorEventCompanies = (eventId, searchterm) =>
  http.post(`/events/search/${eventId}/2?keyword=${searchterm}`)

export const SearchForFundEventCompanies = (eventId, searchterm) =>
  http.post(`/events/search/${eventId}/1?keyword=${searchterm}`)

export const SearchForSPEventCompanies = (eventId, searchterm) =>
  http.post(`/events/search/${eventId}/3?keyword=${searchterm}`)

export const GetEventCompanyContacts = (eventCompanyId) =>
  http.get(`/companies/event/contacts/${eventCompanyId}`)

export const getColleagues = (
  companyId,
  contactId,
  toCompanyId,
  fundId,
  eventId,
  requestContactId = 0
) =>
  (isNil(eventId) &&
    http.get(
      `contextmeetings/${companyId}/${contactId}/${toCompanyId}/meeting-participants?fundId=${fundId}`
    )) ||
  (isNil(fundId) &&
    http.get(
      `contextmeetings/${companyId}/${contactId}/${toCompanyId}/meeting-participants?&eventId=${eventId}`
    )) ||
  http.get(
    `contextmeetings/${companyId}/${contactId}/${toCompanyId}/meeting-participants?requestContactId=${requestContactId}&fundId=${fundId}&eventId=${eventId}`
  )

export const createOffPlatformToFund = (
  companyId,
  contactId,
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  invitedCompanyContactIds
) =>
  http.post(`contextmeetings/${companyId}/${contactId}`, {
    toCompanyId,
    fundId,
    message,
    contactIds,
    toContactIds,
    invitedCompanyContactIds,
  })

export const createOffPlatformToAllocator = (
  companyId,
  contactId,
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  originalCompanyContactId,
  invitedCompanyContactIds
) =>
  http.post(`contextmeetings/${companyId}/${contactId}`, {
    toCompanyId,
    fundId,
    message,
    contactIds,
    toContactIds,
    originalCompanyContactId,
    invitedCompanyContactIds,
  })

export const switchMeetingToOffPlatform = (meetingId) =>
  http.put(`contextmeetings/${meetingId}/offplatform`)

export const declineAllSummitReceivedMeetings = (
  eventId,
  companyId,
  contactId
) =>
  http.patch(`events/${eventId}/meetings/${companyId}/${contactId}/declineAll`)
