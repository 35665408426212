import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Divider, Input, Modal, Row, Select, message } from 'antd'
import { isEmpty, isNil, map } from 'lodash'
import {
  SearchForFundEventCompanies,
  getColleagues,
  sendSummitRequestToFund,
} from '../../actions/contextMeetings.js'
import ParticipantsCheckbox from '../ParticipantsCheckbox'
import SelectOnlineMeetingDateTime from '../SelectOnlineMeetingDateTime'
import './SummitFundMeetingRequest.less'

const { TextArea } = Input
const { Option, OptGroup } = Select

const SummitFundMeetingRequest = ({
  visible,
  fund,
  onClose,
  onRefresh,
  eventId,
  isVirtualSummit = false,
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [myColleagues, setMyColleagues] = useState([])
  const [otherColleagues, setOtherColleagues] = useState([])
  const [participants, setParticipants] = useState([])
  const [toParticipants, setToParticipants] = useState([])
  const [selectedHour, setSelectedHour] = useState({})
  const [textMessage, setMessage] = useState('')
  const [duration, setDuration] = useState(30)
  const [selectedDateTime, setSelectedDateTime] = useState({})
  const [selectedContactList, setSelectedContactList] = useState({
    participants: [],
    toParticipants: [],
  })
  const [savingInProggress, setSavinginProggress] = useState(false)

  const [managers, setManagers] = useState([])
  const [fromCompanyType, setFromCompanyType] = useState(2)
  const [selectValue, setSelectValue] = useState('')
  const [fromEventCompanyId, setFromEventCompanyId] = useState(0)
  const [selectedCompany, setSelectedCompany] = useState({})
  const [firstParticipantFullname, setFirstParticipantFullname] = useState([])

  const [senderId, setSenderId] = useState(null)
  const [loadingContacts, setLoadingContacts] = useState(true)
  const [creditsAvailable, setCreditsAvailable] = useState(0)
  const [contextPointsAvailable, setContextPointsAvailable] = useState(0)

  const handleParticipantsChanged = useCallback(
    (mine, participants) => {
      if (mine)
        setSelectedContactList({
          participants,
          toParticipants: selectedContactList.toParticipants,
        })
      else
        setSelectedContactList({
          participants: selectedContactList.participants,
          toParticipants: participants,
        })
    },
    [selectedContactList]
  )

  const handleOk = useCallback(() => {
    setSavinginProggress(true)
    sendSummitRequestToFund(
      selectedCompany.company.companyId,
      senderId,
      fund.company.companyId,
      fund.fund.fundId,
      selectedContactList.participants,
      selectedContactList.toParticipants,
      textMessage,
      1,
      selectedDateTime.startDateTime,
      duration,
      eventId
    )
      .then((response) => {
        const { meetingId } = response.data.result
        if (isNil(fund.meetings)) {
          fund.meetingId = meetingId
          fund.meetingStatus = 'Pending'
        } else {
          fund.meetings.push({
            contextMeetingId: meetingId,
            meetingStatus: 'Pending',
            participantStatus: 'Confirmed',
          })
        }
        message.success('Request sent succesfully.')
        //onRefresh();
      })
      .catch((err) => {
        var allErr = err.response.data.message
        message.error(
          allErr.substring(allErr.indexOf("'") + 1, allErr.indexOf("'."))
        )
      })
      .finally(() => {
        handleCancel()
        onRefresh()
        setSavinginProggress(false)
      })
  }, [
    duration,
    fund,
    selectedContactList,
    selectedDateTime,
    textMessage,
    onClose,
    eventId,
    onRefresh,
  ])

  const handleCancel = useCallback(() => {
    setCurrentStep(0)
    setToParticipants([])
    setParticipants([])
    setSelectedContactList({ participants: [], toParticipants: [] })
    setSelectedHour({})
    setSelectedCompany(null)
    setSenderId(null)
    setManagers([])
    setSelectValue('')
    setMessage('')
    onClose()
    setMyColleagues([])
    setOtherColleagues([])
  }, [onClose])

  const handleParticipantChecked = useCallback(
    (e) => {
      let tmpparticipants
      if (e.target.checked) {
        tmpparticipants = [...participants, e.target.value]
        setParticipants(tmpparticipants)
        if (tmpparticipants.length === 1)
          setFirstParticipantFullname(e.target.contactName)
      } else {
        tmpparticipants = participants.filter((p) => p !== e.target.value)
        setParticipants(tmpparticipants)
      }

      handleParticipantsChanged(true, tmpparticipants)
    },
    [handleParticipantsChanged, participants]
  )

  const handleToParticipantChecked = useCallback(
    (e) => {
      let tmptoParticipants
      if (e.target.checked) {
        tmptoParticipants = [...toParticipants, e.target.value]
        setToParticipants(tmptoParticipants)
      } else {
        tmptoParticipants = toParticipants.filter((p) => p !== e.target.value)
        setToParticipants(tmptoParticipants)
      }

      handleParticipantsChanged(false, tmptoParticipants)
    },
    [handleParticipantsChanged, toParticipants]
  )

  const onTimeChanged = (changed, newDate) => {
    if (newDate) {
      if (changed === 'date') {
        setSelectedHour({})
      }
      if (changed === 'hour') {
        setSelectedHour(newDate)
      }
      setSelectedDateTime({
        startDateTime: newDate.startTime,
        endDateTime: newDate.endTime,
      })
    }
  }

  const handleFilterOption = (e) => {
    setManagers({})
    if (e.length > 1)
      SearchForFundEventCompanies(eventId, e).then((res) => {
        setManagers(res.data.response)
      })
  }

  const handleSelectChange = (e) => {
    var selectedCompany = managers.allocators.filter(
      (obj) => obj.eventCompanyId === e
    )
    if (selectedCompany.length === 0) {
      selectedCompany = managers.serviceProviders.filter(
        (obj) => obj.eventCompanyId === e
      )
      setFromCompanyType(3)
    } else {
      setFromCompanyType(2)
    }
    setSenderId(null)
    setSelectedCompany(selectedCompany[0])
    setSelectValue(selectedCompany[0].eventCompanyId)
    setFromEventCompanyId(selectedCompany[0].eventCompanyId)
    setMyColleagues(selectedCompany[0].contacts)
    setOtherColleagues([])
  }

  const onMainSenderChange = useCallback((e) => {
    setLoadingContacts(true)

    let tmpparticipants
    ///if (e.target.checked) {
    tmpparticipants = participants.filter((p) => p !== e)
    tmpparticipants = [...myColleagues, e]
    setParticipants(tmpparticipants)
    // } else {
    //   tmpparticipants = participants.filter((p) => p !== e.target.value);
    //   setParticipants(tmpparticipants);
    // }

    handleParticipantsChanged(true, tmpparticipants)
    setSenderId(e)

    setLoadingContacts(false)
  }, [])

  const footer = (
    <div className="footer">
      <Button onClick={handleCancel}>Cancel</Button>
      {isVirtualSummit ? (
        <>
          {currentStep === 0 && fromCompanyType === 2 && (
            <Button
              type="primary"
              onClick={() => {
                setCurrentStep(1)
              }}
              disabled={toParticipants.length === 0}
            >
              Next
            </Button>
          )}
          {currentStep === 1 && fromCompanyType === 2 && (
            <>
              <Button type="primary" onClick={() => setCurrentStep(0)}>
                Previous
              </Button>
              <Button
                type="primary"
                disabled={
                  isEmpty(selectedContactList.toParticipants) ||
                  isNil(senderId) ||
                  isEmpty(selectedDateTime) ||
                  isEmpty(selectedHour)
                }
                onClick={() => handleOk()}
                loading={savingInProggress}
              >
                Send Meeting Request
              </Button>
            </>
          )}
          {fromCompanyType === 3 && (
            <>
              <Button
                loading={savingInProggress}
                type="primary"
                disabled={
                  isEmpty(selectedContactList.toParticipants) || isNil(senderId)
                }
                onClick={() => handleOk()}
              >
                Send Meeting Request
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          <Button
            loading={savingInProggress}
            type="primary"
            disabled={
              isEmpty(selectedContactList.toParticipants) || isNil(senderId)
            }
            onClick={() => handleOk()}
          >
            Send Meeting Request
          </Button>
        </>
      )}
    </div>
  )

  useEffect(() => {
    setLoadingContacts(true)
    //(isFundManager && selectedFundId) &&
    !isNil(senderId) &&
      !isNil(selectedCompany) &&
      getColleagues(
        selectedCompany.company.companyId,
        senderId,
        fund.company.companyId,
        fund.fund.fundId,
        eventId
      )
        .then((response) => {
          setMyColleagues(
            response.data.find((x) => x.companyId !== fund.company.companyId)
              .contacts
          )
          setOtherColleagues(
            response.data.find((x) => x.companyId === fund.company.companyId)
              .contacts
          )

          var toParticipants = response.data
            .find((x) => x.companyId === fund.company.companyId)
            .contacts.map((contact) => contact.contactId)

          setToParticipants(toParticipants)

          handleParticipantsChanged(false, toParticipants)
        })
        .catch((err) => {
          console.log(err)
          message.error('Could not retrieve colleagues')
        })
        .finally(() => setLoadingContacts(false))
  }, [fund, senderId, selectedCompany, eventId])

  return (
    <Modal
      destroyOnClose
      centered
      className="AllocatorOnlineMR"
      visible={visible}
      width="700px"
      height="700px"
      title="Request a Meeting"
      footer={footer}
      afterClose={() => {
        setCurrentStep(0)
        setToParticipants([])
        setParticipants([])
        setSelectedHour({})
        onClose()
      }}
      onCancel={handleCancel}
      bodyStyle={{ padding: '16px 32px' }}
    >
      {currentStep === 0 ? (
        <div>
          <Row>
            <Col span="24">
              <h3 className="heading5" style={{ marginTop: '16px' }}>
                From:
              </h3>
              <Select
                showSearch
                className="cc-background-input"
                size="default"
                style={{ width: '90%', height: '35' }}
                placeholder="From"
                showArrow={false}
                optionFilterProp="children"
                onChange={handleSelectChange}
                onSearch={handleFilterOption}
                filterOption={false}
                value={selectValue}
              >
                <OptGroup label="Allocators">
                  {managers.allocators &&
                    managers.allocators.map((row) => {
                      return (
                        <Select.Option value={row.eventCompanyId}>
                          {row.company.name}
                        </Select.Option>
                      )
                    })}
                </OptGroup>
                <OptGroup label="Service Providers">
                  {managers.serviceProviders &&
                    managers.serviceProviders.map((row) => {
                      return (
                        <Select.Option value={row.eventCompanyId}>
                          {row.company.name}
                        </Select.Option>
                      )
                    })}
                </OptGroup>
                {/* <OptGroup label="Media">
                  {managers.media &&
                    managers.media.map((row) => {
                      return (
                        <Select.Option value={row.companyEventId}>
                          {row.company.name}
                        </Select.Option>
                      );
                    })}
                </OptGroup> */}
              </Select>
            </Col>
          </Row>
          {!isNil(selectedCompany) && !isEmpty(selectedCompany.contacts) && (
            <Row>
              <Col span="24">
                <h3 className="heading5" style={{ marginTop: '16px' }}>
                  MAIN SENDER
                </h3>
                <Select
                  className="cc-background-input"
                  size="default"
                  value={senderId}
                  defaultActiveFirstOption={true}
                  defaultValue={selectedCompany.contacts[0].contactId}
                  style={{ width: '90%', height: '35px' }}
                  placeholder="Select main sender"
                  onChange={(e) => {
                    onMainSenderChange(e)
                  }}
                >
                  {selectedCompany.contacts.map((row) => (
                    <Option key={row.contactId} value={row.contactId}>
                      {row.contactName}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          )}
          {!isNil(myColleagues) && !isEmpty(myColleagues) && (
            <Row>
              <Col span="24">
                <span className="requestSender">OTHER PARTICIPANTS</span>
                {myColleagues &&
                  myColleagues.map((contact) => {
                    return (
                      <ParticipantsCheckbox
                        key={contact.contactId}
                        participants={participants}
                        checked={handleParticipantChecked}
                        contactId={contact.contactId}
                        contactName={`${contact.firstName} ${contact.lastName}`}
                        imageURL={contact.imageUrl}
                        position={contact.jobTitle}
                        disabled={contact.contactId === senderId}
                      />
                    )
                  })}
              </Col>
            </Row>
          )}
          <Divider
            type="horizontal"
            style={{
              backgroundColor: '#F7F8FA',
              margin: '14px 0px 0px 0px',
            }}
          />
          {!isNil(otherColleagues) && !isEmpty(otherColleagues) && (
            <Row>
              <Col span={24}>
                <h3 className="heading5">
                  Sending Request To:
                  {loadingContacts ? ' ' : ' ' + fund.company.name}
                </h3>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                  }}
                >
                  {otherColleagues &&
                    otherColleagues.map((contact) => (
                      <ParticipantsCheckbox
                        key={contact.contactId}
                        {...contact}
                        position={contact.jobTitle}
                        participants={toParticipants}
                        checked={handleToParticipantChecked}
                        imageURL={contact.imageUrl}
                        //disabled={contact.contactId === allocator.contactId}
                      />
                    ))}
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col span="24">
              <div>
                <div className="cc-tabletitle-text cc-darkest-grey-text">
                  Send Message
                </div>
                <div style={{ width: '100%', padding: '20px 20px 20px 0px' }}>
                  <TextArea
                    placeholder="Write a message..."
                    rows={4}
                    value={textMessage}
                    onChange={({ target: { value } }) => {
                      setMessage(value)
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <SelectOnlineMeetingDateTime
            onTimeChanged={onTimeChanged}
            onDurationChanged={setDuration}
            participants={participants}
            toParticipants={toParticipants}
            eventId={eventId}
            contactFullname={firstParticipantFullname}
          />
        </div>
      )}
    </Modal>
  )
}

SummitFundMeetingRequest.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
  fund: PropTypes.object.isRequired,
  eventId: PropTypes.number,
}

export default SummitFundMeetingRequest
