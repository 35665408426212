import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import { filter } from 'lodash'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { getHeldBooths } from '../../actions/booths'
import Agenda from '../Agenda'
import AttendeeList from '../AttendeeList'
import BoothSelectorContainer from '../BoothSelector/BoothSelectorContainer'
import SponsorContentEvent from '../SponsorContentEvent/SponsorContentEvent'
import SummitOverview from '../SummitOverview'
import SummitInfo from './SummitInfo'
import './SummitDetails.less'

const { TabPane } = Tabs

const BUTTONS = [
  {
    value: 'seats',
    text: 'Seats',
  },
  {
    value: 'overview',
    text: 'Overview',
  },
  {
    value: 'content',
    text: 'Content',
  },
]

const SummitDetails = ({
  selectedEvent,
  back,
  getEvent,
  defaultFilterValue,
}) => {
  const {
    params: { main },
  } = useRouteMatch()
  const { push } = useHistory()
  const radioHandler = (e) => {
    push(`/summits/${selectedEvent.eventId}/${e}`)
  }
  const buttons = filter(BUTTONS, () => true)
  const [heldBooths, setHeldBooths] = useState()

  const getHeldBoothCount = useCallback(() => {
    if (!selectedEvent.virtualSummit) {
      getHeldBooths(selectedEvent.eventId).then((response) => {
        setHeldBooths(response.data)
      })
    }
  }, [selectedEvent.eventId, selectedEvent.virtualSummit])

  useEffect(() => {
    getHeldBoothCount()
  }, [getHeldBoothCount])

  const refreshEventData = () => {
    getEvent()
    getHeldBoothCount()
  }
  return (
    <>
      <div className="SummitDetails" style={{ padding: '10px' }}>
        <div className="SummitDetails-wrapper">
          <SummitInfo
            event={selectedEvent}
            back={back}
            heldBooths={heldBooths}
          />
        </div>
        <Tabs
          defaultActiveKey="1"
          size="large"
          className="SummitDetails-tabs"
          activeKey={main}
          onTabClick={radioHandler}
        >
          <TabPane
            className="SummitDetails-tabs-tab"
            tab={<span>Attendees</span>}
            key="attendees"
          >
            <AttendeeList
              eventId={selectedEvent.eventId}
              pastEvent={selectedEvent.pastEvent}
              virtualSummit={selectedEvent.virtualSummit}
              cfnEvent={selectedEvent.isCfnEvent}
              defaultFilterValue={defaultFilterValue}
            />
          </TabPane>
          {!selectedEvent.virtualSummit && (
            <TabPane
              className="SummitDetails-tabs-tab"
              tab={<span>Seats</span>}
              key="seats"
            >
              <BoothSelectorContainer
                eventId={selectedEvent.eventId}
                getEvent={refreshEventData}
              />
            </TabPane>
          )}
          <TabPane
            className="SummitDetails-tabs-tab"
            tab={<span>Overview</span>}
            key="overview"
          >
            <SummitOverview eventId={selectedEvent.eventId} />
          </TabPane>
          <TabPane
            className="SummitDetails-tabs-tab"
            tab={<span>Content</span>}
            key="content"
          >
            <SponsorContentEvent eventId={selectedEvent.eventId} />
          </TabPane>
          <TabPane
            className="SummitDetails-tabs-tab"
            tab={<span>Agenda</span>}
            key="agendas"
          >
            <Agenda
              eventId={selectedEvent.eventId}
              eventTimezone={selectedEvent.timeZone}
            />
          </TabPane>
        </Tabs>
      </div>
    </>
  )
}

SummitDetails.propTypes = {
  selectedEvent: PropTypes.shape({
    eventId: PropTypes.number.isRequired,
    pastEvent: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    allocators: PropTypes.number.isRequired,
    fundManagers: PropTypes.number.isRequired,
    media: PropTypes.number.isRequired,
    serviceProviders: PropTypes.number.isRequired,
  }).isRequired,
  getEvent: PropTypes.func,
  //push: PropTypes.func.isRequired,
}

export default SummitDetails
