import { useState } from 'react'
import PropTypes from 'prop-types'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Col, Divider, Row, Switch, Typography } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment'
import http from '../../utils/api'
import EditButton from '../EditButton/EditButton'
import DescriptionEditModal from '../SummitDetails/DescriptionEditModal'
import MainInfoModal from '../SummitDetails/MainInfoModal'
import './SummitOverview.less'

const { Title, Paragraph } = Typography

const DateString = (startDate, endDate, isVirtual, timezone) => {
  const formattedStart =
    moment(startDate).isValid() && !isNil(startDate)
      ? isVirtual
        ? moment.utc(startDate, moment.ISO_8601).local().format('MMMM D')
        : moment.tz(moment.utc(startDate), timezone).format('MMMM D')
      : 'TBD'
  const formattedEnd =
    moment(endDate).isValid() && !isNil(endDate)
      ? isVirtual
        ? moment.utc(endDate, moment.ISO_8601).local().format('MMMM D, YYYY')
        : moment.tz(moment.utc(endDate), timezone).format('MMMM D, YYYY')
      : 'TBD'
  return `${formattedStart} - ${formattedEnd}`
}

const MainInfo = ({ event, handleSubmit }) => {
  const noEditState = {
    mainInformation: false,
    description: false,
    venue: false,
    accomodation: false,
    address: false,
  }

  const [mainInfoVisible, setMainInfoVisible] = useState(false)
  const [descriptionEditVisible, setDescriptionEditVisible] = useState(false)
  const [buttonVisible, setButtonVisible] = useState(noEditState)
  const [selectedField, setSelectedField] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const toggleEdit = (section, value) => {
    const newState = { ...noEditState }
    newState[section] = value
    setButtonVisible(newState)
  }

  const handleDescriptionEdit = (field) => {
    setSelectedField(field)
    setDescriptionEditVisible(true)
  }

  const poster = isNil(event.posterImage) ? (
    <div
      className="AvailableEvent-poster-empty"
      style={{ marginRight: '20px' }}
    >
      No image
    </div>
  ) : (
    <div className="SummitImage">
      <img src={event.posterImage} alt="Image"></img>
    </div>
  )

  const handlePublished = (e) => {
    //check if event can be published
    const errorText = []

    let isValidForPublish = true
    if (e === true) {
      if (isEmpty(event.posterImage)) {
        errorText.push(
          <span>
            Please update a poster image before publishing <br />
          </span>
        )
        isValidForPublish = false
      }
      if (isEmpty(event.thumbnailImage)) {
        errorText.push(
          <span>
            Please update a thumbnali image before publishing <br />
          </span>
        )
        isValidForPublish = false
      }
      if (
        isEmpty(event.startDate) ||
        isEmpty(event.endDate) ||
        !moment(event.startDate).isAfter('0001-01-01') ||
        !moment(event.endDate).isAfter('0001-01-01')
      ) {
        errorText.push(
          <span>
            Please make sure the event start and end dates are correct <br />
          </span>
        )
        isValidForPublish = false
      }
    }
    if (isValidForPublish) {
      http
        .put(`/events/update/published/${event.eventId}/${e}`)
        .then((response) => {
          handleSubmit()
        })
    } else {
      setErrorMessage(errorText)
    }
  }

  const handleFeatured = (e) => {
    http
      .put(`/events/update/featured/${event.eventId}/${e}`)
      .then((response) => {
        handleSubmit()
      })
  }

  return (
    <div>
      {errorMessage && (
        <Alert
          message={errorMessage}
          type="warning"
          icon={<FontAwesomeIcon color="#F19F00" icon={faExclamationCircle} />}
          showIcon
          style={{ marginBottom: '20px' }}
        />
      )}
      <Row
        type="flex"
        align="top"
        className="Section"
        onMouseEnter={(e) => toggleEdit('mainInformation', true)}
        onMouseLeave={(e) => toggleEdit('mainInformation', false)}
      >
        <Col span={24} className="noHorizontalPadding">
          <Row type="flex" justify="space-between" align="middle">
            <Col span={12} className="noHorizontalPadding">
              <div className="SummitHeading">Main Information</div>
            </Col>
            <Col span={12}>
              <div
                style={{ textAlign: 'right' }}
                hidden={!buttonVisible.mainInformation}
              >
                <EditButton onClick={() => setMainInfoVisible(true)} />{' '}
              </div>
            </Col>
          </Row>
          <Divider className="line" />
          <Row type="flex" justify="space-between" align="middle">
            <Col
              span={12}
              className="noHorizontalPadding"
              style={{ paddingLeft: '33px' }}
            >
              <Row
                type="flex"
                justify="space-between"
                style={{ paddingTop: '10px' }}
              >
                <Col span={5} style={{ paddingLeft: '0px' }}>
                  <div className="SummitBoldText">Name: </div>
                </Col>
                <Col span={19} style={{ paddingLeft: '0px' }}>
                  <div className="SummitText bottomPadding">{event.name}</div>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col span={5} style={{ paddingLeft: '0px' }}>
                  <div className="SummitBoldText">Date: </div>
                </Col>
                <Col span={19} style={{ paddingLeft: '0px' }}>
                  <div className="SummitText bottomPadding">
                    {DateString(
                      event.startDate,
                      event.endDate,
                      event.virtualSummit,
                      event.timeZone
                    )}
                  </div>
                </Col>
              </Row>
              {!event.virtualSummit && (
                <>
                  <Row type="flex" justify="space-between">
                    <Col span={5} style={{ paddingLeft: '0px' }}>
                      <div className="SummitBoldText">Timezone: </div>
                    </Col>
                    <Col span={19} style={{ paddingLeft: '0px' }}>
                      <div className="SummitText bottomPadding">
                        {event.timeZone}
                      </div>
                    </Col>
                  </Row>

                  <Row type="flex" justify="space-between">
                    <Col span={5} style={{ paddingLeft: '0px' }}>
                      <div className="SummitBoldText">Booth Dates: </div>
                    </Col>
                    <Col span={19} style={{ paddingLeft: '0px' }}>
                      <div className="SummitText bottomPadding">
                        {DateString(event.boothStartDate, event.boothEndDate)}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              <Row type="flex" justify="space-between">
                <Col span={5} style={{ paddingLeft: '0px' }}>
                  <div className="SummitBoldText">Location: </div>
                </Col>
                <Col span={19} style={{ paddingLeft: '0px' }}>
                  <div className="SummitText bottomPadding">
                    {`${event.address}, ${event.venue}, ${event.city}, ${event.state}`}
                  </div>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                className="SummitText bottomPadding"
              >
                <Col span={5} style={{ paddingLeft: '0px' }}>
                  <div className="SummitBoldText">Website Url: </div>
                </Col>
                <Col span={19} style={{ paddingLeft: '0px' }}>
                  <a
                    href={
                      event &&
                      event.websiteUrl &&
                      !event.websiteUrl.startsWith('http')
                        ? `https://${event.websiteUrl}`
                        : event.websiteUrl
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event.websiteUrl}
                  </a>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                className="SummitText bottomPadding"
              >
                <Col span={5} style={{ paddingLeft: '0px' }}>
                  <div className="SummitBoldText">Registration Url: </div>
                </Col>
                <Col span={19} style={{ paddingLeft: '0px' }}>
                  <a
                    href={
                      event &&
                      event.registrationUrl &&
                      !event.registrationUrl.startsWith('http')
                        ? `https://${event.registrationUrl}`
                        : event.registrationUrl
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event.registrationUrl}
                  </a>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Switch
                  onChange={(e) => handlePublished(e)}
                  className="status-switch"
                  size="large"
                  checkedChildren="Published"
                  unCheckedChildren="Not published"
                  checked={event.isPublished}
                ></Switch>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                style={{ paddingTop: '16px', paddingBottom: '16px' }}
              >
                <Switch
                  onChange={(e) => handleFeatured(e)}
                  className="status-switch"
                  size="large"
                  checkedChildren="Featured"
                  unCheckedChildren="Not featured"
                  checked={event.isFeatured}
                ></Switch>
              </Row>
            </Col>
            <Col
              span={isNil(event.posterImage) ? 4 : 12}
              offset={isNil(event.posterImage) ? 8 : 0}
              className="noHorizontalPadding"
            >
              {poster}
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider className="SectionDivider" />
      <Row
        type="flex"
        align="top"
        className="Section"
        onMouseEnter={(e) => toggleEdit('description', true)}
        onMouseLeave={(e) => toggleEdit('description', false)}
      >
        <Col span={24} className="noHorizontalPadding">
          <Row type="flex" justify="space-between" align="middle">
            <Col span={12} className="noHorizontalPadding">
              <div className="SummitHeading">Description</div>
            </Col>
            <Col span={12}>
              <div
                style={{ textAlign: 'right' }}
                hidden={!buttonVisible.description}
              >
                <EditButton
                  onClick={() => handleDescriptionEdit('eventDescription')}
                />{' '}
              </div>
            </Col>
          </Row>
          <Divider className="line" />
          <Row type="flex" justify="space-between" align="middle">
            <Col span={24} className="noHorizontalPadding">
              <div className="SummitText padding">{event.eventDescription}</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <MainInfoModal
        visible={mainInfoVisible}
        event={event}
        handleCancel={() => setMainInfoVisible(false)}
        handleOk={() => {
          setMainInfoVisible(false)
          handleSubmit()
        }}
      />
      <DescriptionEditModal
        visible={descriptionEditVisible}
        event={event}
        handleCancel={() => setDescriptionEditVisible(false)}
        handleOk={() => {
          setDescriptionEditVisible(false)
          handleSubmit()
        }}
        field={selectedField}
      />
    </div>
  )
}

MainInfo.propTypes = {
  event: PropTypes.shape({
    eventId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    venue: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    eventDescription: PropTypes.string.isRequired,
    venueDescription: PropTypes.string.isRequired,
    accomodationDescription: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    posterImage: PropTypes.string.isRequired,
    headerImage: PropTypes.string.isRequired,
    footerImage: PropTypes.string.isRequired,
  }).isRequired,
}

export default MainInfo
