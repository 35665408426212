import PropTypes from 'prop-types'
import { Tag } from 'antd'
import capitalize from 'lodash/capitalize'

const Holdings = ({ label, value }) => {
  let holdings = value ? JSON.parse(value) : null
  holdings = Array.isArray(holdings) ? holdings : []

  return (
    <div>
      {holdings.length > 0 && (
        <div>
          <div className="cc-grey-text cc-tabletitle-text">{label}</div>

          {holdings.map((h) => (
            <div key={h.Name}>
              <Tag
                className={
                  h.Status === 'realized'
                    ? 'holdings-tag-grey'
                    : 'holdings-tag-green'
                }
              >
                <div>{capitalize(h.Status)}</div>
              </Tag>
              {capitalize(h.Name)}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
Holdings.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}
export default Holdings
