import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Modal, Radio, message } from 'antd'
import { isEmpty, isNil, map } from 'lodash'
import moment from 'moment'
import { acceptMeetingAllocator } from '../../actions/contextMeetings'
import http from '../../utils/api'
import { isElevated } from '../../utils/meetingActions'
import ContactName from '../ContactName'
import LoadingGif from '../LoadingGif'
import MeetingQuestionAnswer from '../MeetingQuestionAnswer'
import MeetingQuestionsForm from '../MeetingQuestionsForm/MeetingQuestionsForm'
import SelectOnlineMeetingDateTime from '../SelectOnlineMeetingDateTime'
import TimeSlotSelection from '../TimeSlotSelection'
import '../../styles/index.less'

const AcceptOnlineMeeting = ({
  visible,
  closeModal,
  company,
  categoryName,
  meetingId,
  meetingStatusId,
  onAccept,
  meetingDateTime,
  role,
  participants,
  toParticipants,
  haveAnswered,
  contacts,
  companyId,
}) => {
  const [acceptingInProgress, setAcceptingInProgress] = useState(false)
  const [selectedDateTime, setSelectedDateTime] = useState({})
  const [duration, setDuration] = useState(30)
  const [selectedHour, setSelectedHour] = useState({})
  const [questionsForFund, setQuestionsForFund] = useState([])
  const [answers, setAnswers] = useState([])
  const [loadingQuestions, setLoadingQuestions] = useState(false)
  const [selectedProposedTimeSlotId, setSelectedProposedTimeSlotId] =
    useState(null)

  const [checkValue, setCheckValue] = useState(null)
  const [contactIDs, setContactIDs] = useState([])
  const [toContactIDs, setToContactIDs] = useState([])
  const [currentRole, setCurrentRole] = useState('')

  useEffect(() => {
    if (currentRole.toLowerCase() === 'manager' && meetingId) {
      setLoadingQuestions(true)
      http
        .get(`/contextmeetings/questions/${meetingId}`)
        .then((response) => {
          setQuestionsForFund(response.data)
          setAnswers(
            map(response.data, (r) => ({
              meetingQuestionId: r.meetingQuestionId,
              answer: '',
            }))
          )
        })
        .finally(() => {
          setLoadingQuestions(false)
        })
    }
  }, [meetingId, currentRole])

  useEffect(() => {
    if (currentRole === '') {
      if (window.location.href.includes('allocator')) {
        setCurrentRole('allocator')
      } else if (window.location.href.includes('fund')) {
        setCurrentRole('manager')
      } else if (window.location.href.includes('sp')) {
        setCurrentRole('service provider')
      } else if (window.location.href.includes('cfn')) {
        setCurrentRole('cfn')
      } else {
        setCurrentRole('unknown')
      }
    }
  }, [currentRole])

  useEffect(() => {
    if (participants.length > 0 && toParticipants.length > 0) {
      const ids = []
      participants.map((contact) => {
        ids.push(contact.contactId)
      })
      setContactIDs(ids)

      const toIds = []
      toParticipants.map((contact) => {
        toIds.push(contact.contactId)
      })
      setToContactIDs(toIds)
    }
  }, [participants, toParticipants])

  const handleModalCancel = () => {
    setSelectedDateTime({})
    setSelectedProposedTimeSlotId(null)
    setCheckValue(null)
    closeModal()
  }

  const handleAcceptMethod = () => {
    setAcceptingInProgress(true)
    acceptMeetingAllocator(
      companyId,
      checkValue,
      meetingId,
      selectedDateTime.startDateTime,
      duration,
      selectedProposedTimeSlotId,
      isEmpty(answers) ? null : answers
    )
      .then(() => {
        message.success('The meeting has been accepted.')
        onAccept(selectedDateTime.startDateTime)
      })
      .catch(() => {
        message.error('Something went wrong')
        /*
        tier === "Free" &&
          onForbidden() &&
          onAccept(selectedDateTime.startDateTime);
          */
      })
      .finally(() => {
        setAcceptingInProgress(false)
        handleModalCancel()
      })
  }

  const onDurationChanged = (value) => {
    setDuration(value)
  }

  const onTimeChanged = (changed, newDate) => {
    if (changed === 'date') {
      setSelectedHour({})
    }
    if (changed === 'hour') {
      setSelectedHour(newDate)
    }
    if (!isNil(newDate)) {
      setSelectedDateTime({
        startDateTime: newDate.startTime,
        endDateTime: newDate.endTime,
      })
    }
  }

  const onTimeSlotChanged = (timeSlotId) => {
    setSelectedProposedTimeSlotId(timeSlotId)
  }

  const onRadioChange = (e) => {
    setCheckValue(e.target.value)
  }

  const handleDisabled = () => {
    if (!isEmpty(meetingDateTime)) {
      return isNil(checkValue)
    } else {
      if (currentRole === 'service provider' || currentRole === 'cfn') {
        return isNil(selectedProposedTimeSlotId) || isNil(checkValue)
      } else {
        if (categoryName === 'CFN')
          return isNil(selectedProposedTimeSlotId) || isNil(checkValue)
        else
          return (
            (isEmpty(meetingDateTime) &&
              isElevated(currentRole, categoryName) &&
              (isEmpty(selectedDateTime) || isEmpty(selectedHour)) &&
              currentRole.toLowerCase() !== 'manager') ||
            (!isElevated(currentRole, categoryName) &&
              isNil(selectedProposedTimeSlotId)) ||
            isNil(checkValue)
          )
      }
    }
  }

  return (
    <Modal
      destroyOnClose
      className="cc-modal-accept"
      title="Accept Meeting Request"
      visible={visible}
      okText="Accept Meeting"
      onOk={handleAcceptMethod}
      onCancel={handleModalCancel}
      okButtonProps={{
        loading: acceptingInProgress,
        disabled: handleDisabled(),
        style: { backgroundColor: '#24C477', borderStyle: 'none' },
      }}
      cancelButtonProps={{ disabled: false }}
      width={'758px'}
      bodyStyle={{ padding: '30px' }}
    >
      {currentRole.toLowerCase() === 'manager' ? (
        <LoadingGif spinning={acceptingInProgress}>
          <div>
            <div>
              <span
                className="cc-heading5"
                style={{
                  fontSize: '14px',
                  fontWeight: 'normal',
                  color: '#4D4F56',
                }}
              >
                Choose the user on behalf you want to accept the meeting:
              </span>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '100%',
                  paddingTop: '16px',
                }}
              >
                <Radio.Group onChange={onRadioChange} value={checkValue}>
                  {contacts &&
                    contacts.length > 0 &&
                    contacts.map((contact, id) => (
                      <Col
                        span={24}
                        key={id}
                        style={{ paddingBottom: '16px', paddingLeft: '0' }}
                      >
                        <div
                          key={contact.contactId.toString()}
                          style={{ width: '100%' }}
                        >
                          <Radio
                            value={contact.contactId}
                            style={{ paddingRight: '12px' }}
                          />
                          <ContactName
                            name={contact.contactName}
                            imageUrl={contact.imageURL}
                          />
                        </div>
                      </Col>
                    ))}
                </Radio.Group>
              </div>
            </div>
          </div>

          {meetingId &&
            isNil(meetingDateTime) &&
            // !isNil(checkValue) &&
            categoryName.replace(/\s/g, '').toLowerCase() !==
              'serviceprovider' && (
              <TimeSlotSelection
                meetingId={meetingId}
                onTimeSlotChanged={onTimeSlotChanged}
              />
            )}

          {!isEmpty(questionsForFund) && !isEmpty(answers) && !haveAnswered && (
            <MeetingQuestionsForm
              questionsForFund={questionsForFund}
              answers={answers}
              setAnswers={setAnswers}
              loadingQuestions={loadingQuestions}
            />
          )}

          {categoryName &&
            categoryName.replace(/\s/g, '').toLowerCase() ===
              'serviceprovider' &&
            isEmpty(meetingDateTime) &&
            isElevated(currentRole, categoryName) && (
              <SelectOnlineMeetingDateTime
                onTimeChanged={onTimeChanged}
                onDurationChanged={onDurationChanged}
                toParticipants={toContactIDs}
                participants={contactIDs}
              />
            )}
        </LoadingGif>
      ) : currentRole.toLowerCase() === 'service provider' ? (
        <LoadingGif spinning={acceptingInProgress}>
          <div>
            <span
              className="cc-heading5"
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                color: '#4D4F56',
              }}
            >
              Choose the user on behalf you want to accept the meeting:
            </span>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                paddingTop: '16px',
              }}
            >
              <Radio.Group onChange={onRadioChange} value={checkValue}>
                {contacts &&
                  contacts.length > 0 &&
                  contacts.map((contact) =>
                    contact.participantStatusId === 1 ? (
                      <Col
                        span={24}
                        style={{
                          paddingBottom: '16px',
                          paddingLeft: '0',
                        }}
                      >
                        <div
                          key={contact.contactId.toString()}
                          style={{ width: '100%' }}
                        >
                          <Radio
                            value={contact.contactId}
                            style={{ paddingRight: '12px' }}
                          />
                          <ContactName
                            name={contact.contactName}
                            imageUrl={contact.imageURL}
                          />
                        </div>
                      </Col>
                    ) : (
                      <span />
                    )
                  )}
              </Radio.Group>
            </div>
          </div>
          {meetingId && isNil(meetingDateTime) && (
            <TimeSlotSelection
              meetingId={meetingId}
              onTimeSlotChanged={onTimeSlotChanged}
            />
          )}
          {isEmpty(meetingDateTime) &&
            isElevated(currentRole, categoryName) && (
              <SelectOnlineMeetingDateTime
                onTimeChanged={onTimeChanged}
                onDurationChanged={onDurationChanged}
                toParticipants={toContactIDs}
                participants={contactIDs}
              />
            )}
        </LoadingGif>
      ) : (
        <LoadingGif spinning={acceptingInProgress}>
          <div>
            <span
              className="cc-heading5"
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                color: '#4D4F56',
              }}
            >
              Choose the user on behalf you want to accept the meeting:
            </span>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                paddingTop: '16px',
              }}
            >
              <Radio.Group onChange={onRadioChange} value={checkValue}>
                {contacts &&
                  contacts.length > 0 &&
                  contacts.map((contact) =>
                    contact.participantStatusId === 1 ? (
                      <Col
                        span={24}
                        style={{
                          paddingBottom: '16px',
                          paddingLeft: '0',
                        }}
                      >
                        <div
                          key={contact.contactId.toString()}
                          style={{ width: '100%' }}
                        >
                          <Radio
                            value={contact.contactId}
                            style={{ paddingRight: '12px' }}
                          />
                          <ContactName
                            name={contact.contactName}
                            imageUrl={contact.imageURL}
                          />
                        </div>
                      </Col>
                    ) : (
                      <span />
                    )
                  )}
              </Radio.Group>
            </div>
          </div>
          <MeetingQuestionAnswer
            meetingId={meetingId}
            acceptingRequest
            companyName={company}
            meetingDateTime={meetingDateTime}
          />
          {meetingId &&
            isNil(meetingDateTime) &&
            !isElevated(currentRole, categoryName) && (
              <TimeSlotSelection
                meetingId={meetingId}
                onTimeSlotChanged={onTimeSlotChanged}
              />
            )}
          {isEmpty(meetingDateTime) &&
            isElevated(currentRole, categoryName) && (
              <SelectOnlineMeetingDateTime
                onTimeChanged={onTimeChanged}
                onDurationChanged={onDurationChanged}
                toParticipants={toContactIDs}
                participants={contactIDs}
              />
            )}
        </LoadingGif>
      )}
    </Modal>
  )
}

AcceptOnlineMeeting.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  company: PropTypes.string.isRequired,
  meetingId: PropTypes.number.isRequired,
  meetingStatusId: PropTypes.number.isRequired,
  onAccept: PropTypes.func.isRequired,
  meetingDateTime: PropTypes.string,
  role: PropTypes.string.isRequired,
  participants: PropTypes.array.isRequired,
  toParticipants: PropTypes.array.isRequired,
  haveAnswered: PropTypes.bool.isRequired,
  categoryName: PropTypes.string,
  onForbidden: PropTypes.func,
}

export default AcceptOnlineMeeting
