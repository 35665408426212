import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Input, Modal, Radio, message } from 'antd'
import { filter, map } from 'lodash'
import {
  cancelMeeting,
  cancelMeetingForAll,
  declineMeeting,
} from '../../actions/contextMeetings'
import {
  //cancelMeeting,
  //cancelOnlineMeeting,
  //declineMeeting,
  //declineOnlineMeeting,
  cancellationReasons,
} from '../../actions/meetings'
import ContactName from '../ContactName'
import '../../styles/index.less'

const { TextArea } = Input

//no action yet
const CancelMeetingButton = ({
  contacts,
  meetingType,
  companyId,
  cancelText = 'Cancel',
  meetingId,
  carryContextPoints,
  meetingStatus = null,
  onCancel,
  initiator,
}) => {
  const reasonRequired =
    meetingStatus && meetingStatus.toLowerCase() === 'confirmed'
  const [cancelMeetingVisible, setcancelMeeting] = useState(false)
  const [selectedReasonId, setSelectedReasonId] = useState(0)
  const [otherReason, setOtherReason] = useState(false)
  const [otherReasonText, setOtherReasonText] = useState('')
  const [cancelReasons, setCancelReasons] = useState([])
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [checkValue, setCheckValue] = useState(null)
  const onRadioChange = (e) => {
    setCheckValue(e.target.value)
    if (!reasonRequired) setSubmitButtonDisabled(false)
  }

  const handleModalCancel = () => {
    setCheckValue(null)
    setcancelMeeting(false)
    setSubmitButtonDisabled(true)
  }
  const handleCancelClick = () => {
    setcancelMeeting(true)
  }

  const cancelMeetingReq = () => {
    setSubmitButtonDisabled(true)
    if (cancelText === 'Cancel') {
      if (meetingType === 'Summit')
        cancelMeeting(
          companyId,
          checkValue.contactId,
          meetingId,
          selectedReasonId,
          otherReasonText
        ).then(() => {
          message.success('The meeting has been cancelled')
          handleModalCancel()
          onCancel()
        })
      else if (meetingType.toLowerCase() === 'online')
        if (initiator.contactId === checkValue.contactId) {
          cancelMeetingForAll(
            companyId,
            checkValue.contactId,
            meetingId,
            selectedReasonId,
            otherReasonText
          ).then(() => {
            message.success('The meeting has been cancelled')
            handleModalCancel()
            onCancel()
          })
        } else {
          cancelMeeting(
            companyId,
            checkValue.contactId,
            meetingId,
            selectedReasonId,
            otherReasonText
          ).then(() => {
            message.success('The meeting has been cancelled')
            handleModalCancel()
            onCancel()
          })
        }
    } else {
      if (meetingType === 'Summit')
        declineMeeting(meetingId, checkValue.contactId).then(() => {
          message.success('The meeting has been declined')
          handleModalCancel()
          onCancel()
        })
      else if (meetingType.toLowerCase() === 'online')
        declineMeeting(companyId, checkValue.contactId, meetingId).then(() => {
          message.success('The meeting has been declined')
          handleModalCancel()
          onCancel()
        })
    }
  }

  useEffect(() => {
    if (cancelMeetingVisible) {
      cancellationReasons().then((res) => {
        setCancelReasons(res.data.filter((r) => r.meetingTypeId === 3))
      })
    }
  }, [cancelMeetingVisible])

  const renderTitle = () => (
    <div>
      {cancelText} Meeting
      {carryContextPoints && (
        <span className="cc-context-points-label">
          <span>+1000 Context Points</span>
        </span>
      )}
    </div>
  )

  return (
    <div className="cc-meeting-actions">
      <Button onClick={handleCancelClick} className="cc-btn cc-btn-danger">
        <i className="cc-btn-bg" />
        {cancelText}
      </Button>
      <Modal
        className="cc-modal-accept"
        title={renderTitle()}
        visible={cancelMeetingVisible}
        okText={`${cancelText} Meeting`}
        cancelText="Close"
        onOk={cancelMeetingReq} //needs to be changed in order to actually accept the request
        onCancel={handleModalCancel}
        okButtonProps={{
          disabled: submitButtonDisabled,
          style: { backgroundColor: '#D62B34', borderStyle: 'none' },
        }}
        cancelButtonProps={{ disabled: false }}
      >
        <span
          className="cc-heading5"
          style={{
            fontSize: '14px',
            fontWeight: 'normal',
            color: '#4D4F56',
            marginTop: '15px',
            display: 'block',
          }}
        >
          Choose the user on behalf you want to {cancelText} the meeting:
        </span>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            paddingTop: '16px',
          }}
        >
          <Radio.Group onChange={onRadioChange} value={checkValue}>
            {contacts &&
              contacts.length > 0 &&
              contacts.map((contact, key) => (
                <Col
                  span={24}
                  key={key}
                  style={{ paddingBottom: '16px', paddingLeft: '0' }}
                >
                  <div
                    key={contact.contactId.toString()}
                    style={{ width: '100%' }}
                  >
                    <Radio value={contact} style={{ paddingRight: '12px' }} />
                    <ContactName
                      name={contact.contactName}
                      imageUrl={contact.imageURL}
                    />
                  </div>
                </Col>
              ))}
          </Radio.Group>
        </div>

        {checkValue != null && cancelReasons.length > 0 && reasonRequired ? (
          <div>
            <p className="cc-body-text cc-lightblack-text">{`Please select the reason you want to ${
              cancelText.indexOf('Meeting') === -1
                ? cancelText.toLowerCase()
                : 'cancel'
            } the meeting${
              cancelText.indexOf('Decline') !== -1 ? ' request' : ''
            }:`}</p>
            <Radio.Group
              style={{ width: '100%' }}
              value={selectedReasonId}
              onChange={(e) => {
                setSelectedReasonId(e.target.value)
                const reason = filter(
                  cancelReasons,
                  (r) => r.cancellationReasonId === e.target.value
                )
                setOtherReason(reason[0].requiresInput)
                if (reason[0].requiresInput) setSubmitButtonDisabled(true)
                else setSubmitButtonDisabled(false)
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '16px',
                }}
              >
                {map(cancelReasons, (c) => (
                  <span
                    style={{ marginBottom: '16px' }}
                    className="cancellingReasons"
                  >
                    <Radio
                      key={c.cancellationReasonId.toString()}
                      value={c.cancellationReasonId}
                    >
                      {c.description}
                    </Radio>
                  </span>
                ))}
                {otherReason && (
                  <TextArea
                    placeholder="Please type another reason"
                    rows={4}
                    value={otherReasonText}
                    onChange={({ target: { value } }) => {
                      setOtherReasonText(value)
                      if (value.trim().length === 0)
                        setSubmitButtonDisabled(true)
                      else setSubmitButtonDisabled(false)
                    }}
                  />
                )}
              </div>
            </Radio.Group>
          </div>
        ) : (
          <p />
        )}
      </Modal>
    </div>
  )
}

CancelMeetingButton.propTypes = {
  cancelText: PropTypes.oneOf(['Decline', 'Cancel']),
  meetingType: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  meetingId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CancelMeetingButton
