import { faArrowDown, faArrowUp } from '@fortawesome/pro-light-svg-icons'
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, DatePicker, InputNumber, Tooltip } from 'antd'
import _, { isNil } from 'lodash'
import get from 'lodash/get'
import moment from 'moment'
import numeral from 'numeral'
import ActionButton from '../components/ActionButton'
import {
  CCTableActionCell,
  CCTableCheckCell,
  CCTableContactLinkCell,
  CCTableCurrencyCell,
  CCTableDateTimeCell,
  CCTableDollarChangeCell,
  CCTableLambdaCell,
  CCTableLinkCell,
  CCTableNumberCell,
  CCTableProfilePicsCell,
  CCTableReverseCheckCell,
  CCTableTagGroupCell,
  CCTableYesNoCell,
} from '../components/ContextTable/ContextTable'
import ProfilePictureGroup from '../components/ProfilePictureGroup'
import TagGroup from '../components/TagGroup'

const { YearPicker, MonthPicker, WeekPicker } = DatePicker

export const transformColumns = (columns, rowSelected) => {
  if (isNil(columns)) return columns

  for (var i = 0; i < columns.length; i++) {
    switch (columns[i].cellType) {
      case CCTableLinkCell:
        transformLinkColumn(columns[i], rowSelected)
        break
      case CCTableContactLinkCell:
        transformContactLinkColumn(columns[i], rowSelected)
        break
      case CCTableProfilePicsCell:
        transformProfilePictureColumn(columns[i])
        break
      case CCTableTagGroupCell:
        transformTagGroupColumn(columns[i])
        break
      case CCTableActionCell:
        transformActionColumn(columns[i])
        break
      case CCTableDateTimeCell:
        transfromDateTimeColumn(columns[i])
        break
      case CCTableCurrencyCell:
        transformCurrencyColumn(columns[i])
        break
      case CCTableNumberCell:
        transformNumberColumn(columns[i])
        break
      case CCTableYesNoCell:
        transformYesNoColumn(columns[i])
        break
      case CCTableCheckCell:
        transformCheckColumn(columns[i])
        break
      case CCTableReverseCheckCell:
        transformReverseCheckColumn(columns[i])
        break
      case CCTableDollarChangeCell:
        transformDollarChangeCell(columns[i])
        break
      case CCTableLambdaCell:
        removeDataIndex(columns[i])
        const itemMethod = columns[i].itemMethod
        columns[i].render = (record) => itemMethod(record)
      default:
        break
    }
  }

  return columns
}

const transformLinkColumn = (column, rowSelected) => {
  const tooltipMethod = column.tooltipMethod
  const showTooltip = !isNil(tooltipMethod)

  removeDataIndex(column)

  column.render = (record) => {
    let icon
    if (record.hasMeeting) {
      let className =
        'meeting-avatar meeting-avatar-' + record.meetingStatus.toLowerCase()
      icon = (
        <Tooltip title={'Meeting Request ' + record.meetingStatus}>
          <Avatar className={className} size={13} shape="circle"></Avatar>
        </Tooltip>
      )
    }
    return (
      <Tooltip title={showTooltip && tooltipMethod(record)}>
        <div style={{ display: 'inline-block', width: 'max-content' }}>
          <Button
            className="SummitAttendeeAllocators-btn"
            type="link"
            onClick={isNil(rowSelected) ? null : rowSelected.bind(null, record)}
            loading={record.recordLoading === true}
          >
            {/* {isNil(column.itemMethod) ? eval('record.' + column.key) : column.itemMethod(record)} */}
            {isNil(column.itemMethod)
              ? _.get(record, column.key)
              : column.itemMethod(record)}
          </Button>
          {icon}
        </div>
      </Tooltip>
    )
  }
}

const transformContactLinkColumn = (column, rowSelected) => {
  const tooltipMethod = column.tooltipMethodd
  const showTooltip = !isNil(tooltipMethod)

  const itemsMethod = column.itemsMethod

  removeDataIndex(column)

  column.render = (record) => {
    const contact = isNil(itemsMethod)
      ? get(record, column.key)
      : itemsMethod(record)
    const contactName = isNil(contact.contactName)
      ? contact.firstName + ' ' + contact.lastName
      : contact.contactName
    return (
      <div style={{ verticalAlign: 'center' }}>
        <ProfilePictureGroup
          diameter={32}
          profiles={[
            {
              name: contactName,
              image: contact.imageURL,
              firstName: contact.firstName,
              lastName: contact.lastName,
            },
          ]}
          maxImages="1"
          style={{ display: 'inline-block', marginTop: '4px' }}
        ></ProfilePictureGroup>
        <Tooltip title={showTooltip && tooltipMethod(record)}>
          <Button
            style={{ padding: '0 6px', verticalAlign: 'bottom' }}
            type="link"
            onClick={rowSelected && rowSelected.bind(record)}
          >
            {contactName}
          </Button>
        </Tooltip>
      </div>
    )
  }
}

const transformProfilePictureColumn = (column) => {
  removeDataIndex(column)

  const contactsMethod = column.contactsMethod
  column.render = (record) => {
    // var contacts = isNil(contactsMethod) ? eval('record.' + column.key) : contactsMethod(record);
    var contacts = isNil(contactsMethod)
      ? _.get(record, column.key)
      : contactsMethod(record)
    return (
      <div style={{ verticalAlign: 'center' }}>
        <ProfilePictureGroup
          diameter={32}
          profiles={contacts.map((x) => ({
            name: x.contactName,
            imageURL: x.imageURL,
            firstName: x.firstName,
            lastName: x.lastName,
          }))}
          maxImages="2"
        ></ProfilePictureGroup>
      </div>
    )
  }
  column.align = 'left'
}

const transformTagGroupColumn = (column) => {
  const itemsMethod = column.itemsMethod
  column.render = (record) => (
    <TagGroup
      color={column.tagGroupColor}
      maxLength={column.tagGroupMaxLength}
      // items={
      //     isNil(itemsMethod) ? eval('record.' + column.key): itemsMethod(record)
      // }
      items={
        isNil(itemsMethod) ? _.get(record, column.key) : itemsMethod(record)
      }
    />
  )
}

const transformActionColumn = (column) => {
  delete column.dataIndex
  column.render = (record) => {
    var menu = isNil(column.menuItemsMethod)
      ? column.menuItems
      : column.menuItemsMethod(record)
    return <ActionButton record={record} menu={menu} />
  }
  column.align = 'center'
}

const transfromDateTimeColumn = (column) => {
  removeDataIndex(column)
  const format = column.dateTimeFormat || 'DD/MM/YYYY'

  if (isNil(column.dataValue)) {
    column.dataValue = column.key
  }

  if (isNil(column.render)) {
    column.render = (record) => {
      // const value = eval('record.' + column.key);
      const value = isNil(column.itemMethod)
        ? _.get(record, column.dataValue)
        : column.itemMethod(record)
      return <span>{moment(value).format(format)}</span>
    }
  }
  column.columnType = 'DateTime'
  if (!isNil(column.isFiltered)) {
    column.filterDropdown = ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) =>
      datePickerFilterDropdown(
        column.key,
        column.datePickerType,
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      )
  }
}

const transformCurrencyColumn = (column) => {
  removeDataIndex(column)

  column.render = (record) => {
    // var value = eval('record.' + column.key);
    var value = isNil(column.itemMethod)
      ? _.get(record, column.key)
      : column.itemMethod(record)
    if (isNil(value)) return '-'
    return numeral(value).format('($0,0[.]00 a)').toUpperCase()
  }

  column.filterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) =>
    numericFilterDropdown(
      column.key,
      column.step,
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    )
  column.columnType = 'Numeric'
}

const transformNumberColumn = (column) => {
  removeDataIndex(column)

  column.render = (record) => {
    // var value = eval('record.' + column.key);
    var value = isNil(column.itemMethod)
      ? _.get(record, column.key)
      : column.itemMethod(record)
    if (isNil(value)) return '-'
    return value
  }

  column.filterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) =>
    numericFilterDropdown(
      column.key,
      column.step,
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    )
  column.columnType = 'Numeric'
}

const transformYesNoColumn = (column) => {
  removeDataIndex(column)
  column.filters = [
    { text: 'YES', value: true },
    { text: 'NO', value: false },
  ]
  column.filterMultiple = false
  column.columnType = 'Boolean'
  column.render = (record) => {
    // const value = eval('record.' + column.key);
    const value = _.get(record, column.key)
    return <span>{value === true ? 'YES' : 'NO'}</span>
  }
}

const transformCheckColumn = (column) => {
  removeDataIndex(column)
  column.filters = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ]
  column.filterMultiple = false
  column.columnType = 'Boolean'
  column.render = (record) => {
    // const value = eval('record.' + column.key);
    const value = _.get(record, column.key)
    return value ? (
      <FontAwesomeIcon icon={faCheck} color={'#24c477'} />
    ) : (
      <FontAwesomeIcon icon={faTimes} color={'#d62b34'} />
    )
  }
}

const transformReverseCheckColumn = (column) => {
  removeDataIndex(column)
  column.filters = [
    { text: 'Yes', value: false },
    { text: 'No', value: true },
  ]
  column.filterMultiple = false
  column.columnType = 'Boolean'
  column.render = (record) => {
    // const value = eval('record.' + column.key);
    const value = _.get(record, column.key)
    return !value ? (
      <FontAwesomeIcon icon={faCheck} color={'#24c477'} />
    ) : (
      <FontAwesomeIcon icon={faTimes} color={'#d62b34'} />
    )
  }
}

const transformDollarChangeCell = (column) => {
  removeDataIndex(column)
  column.render = (record) => {
    // const value = eval('record.' + column.key);
    const value = _.get(record, column.key)
    return value > 0 ? (
      <>
        <span className="cc-positive-number">
          +{numeral(value).format('($0,0[.]00 a)').toUpperCase()}
        </span>
        <span className="cc-positive-number">
          <FontAwesomeIcon icon={faArrowUp} color="green" />
        </span>
      </>
    ) : (
      <>
        <span className="cc-negative-number">
          {' '}
          -{numeral(value).format('($0,0[.]00 a)').toUpperCase()}
        </span>
        <span>
          <FontAwesomeIcon icon={faArrowDown} color="red" />
        </span>
      </>
    )
  }
}

const removeDataIndex = (column) => {
  if (!isNil(column.dataIndex)) {
    if (isNil(column.key)) column.key = column.dataIndex
    delete column.dataIndex
  }
}

const datePickerFilterDropdown = (
  columnKey,
  datePickerType,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters
) => {
  var CustomPicker = getDatePicker(datePickerType)
  return (
    <div style={{ padding: 8 }}>
      From:
      <CustomPicker
        disabledDate={(x) => disabledStartDate(x, selectedKeys[1])}
        value={selectedKeys[0]}
        onChange={(value, dateString) =>
          setSelectedKeys([value, selectedKeys[1]])
        }
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      To:
      <CustomPicker
        disabledDate={(x) => disabledEndDate(selectedKeys[0], x)}
        value={selectedKeys[1]}
        onChange={(value, dateString) =>
          setSelectedKeys([selectedKeys[0], value])
        }
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        //onClick={() => this.handleSearch(selectedKeys, confirm)}
        onClick={() => {
          // var filters = this.state.filters || {};
          // filters[columnKey] = [
          //   selectedKeys[0].valueOf(),
          //   selectedKeys[1].valueOf(),
          // ];
          confirm()
        }}
        icon="search"
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button
        onClick={() => {
          // var filters = this.state.filters || {};
          // delete filters[columnKey];
          clearFilters()
        }}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  )
}

const disabledStartDate = (startValue, endValue) => {
  if (!startValue || !endValue) return false

  return startValue.valueOf() > endValue.valueOf()
}

const disabledEndDate = (startValue, endValue) => {
  if (!startValue || !endValue) return false

  return endValue.valueOf() <= startValue.valueOf()
}

const numericFilterDropdown = (
  columnKey,
  step = 1,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters
) => {
  return (
    <div style={{ padding: 8 }}>
      From:
      <InputNumber
        step={step}
        value={selectedKeys[0]}
        onChange={(value) => setSelectedKeys([value, selectedKeys[1]])}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      To:
      <InputNumber
        step={step}
        value={selectedKeys[1]}
        onChange={(value) => setSelectedKeys([selectedKeys[0], value])}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => {
          confirm()
        }}
        icon="search"
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button
        onClick={() => {
          clearFilters()
        }}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  )
}

const getDatePicker = (pickerType) => {
  switch (pickerType) {
    case 'year':
      return YearPicker
    case 'month':
      return MonthPicker
    case 'week':
      return WeekPicker
    default:
      return DatePicker
  }
}

export const transformPagination = (pagination) => {
  if (typeof pagination === 'object') {
    pagination.size = 'small'
    pagination.showSizeChanger = true
  }
  return pagination
}
