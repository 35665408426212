import { useEffect, useState } from 'react'
import { number } from 'prop-types'
import { Button, Col, Modal, Radio, Row, Select } from 'antd'
import DealsWizardContainer from '../../views/DealsWizardContainer'

const DealContentModal = ({
  showCreateDealsProfile,
  handleModalCancel,
  handleWizardClose,
  usage,
  type,
  contactId,
  compId,
  dealId,
  status,
  refreshDealList,
  openedForView,
  handleViewClose,
  handleEditDeal,
  dealCategoryName,
}) => {
  if (showCreateDealsProfile) {
    return (
      <Modal
        destroyOnClose
        visible={showCreateDealsProfile}
        onCancel={handleModalCancel}
        footer={null}
        width="1100px"
        bodyStyle={{ padding: '0px' }}
      >
        <DealsWizardContainer
          onWizardClosed={handleWizardClose}
          usage={usage}
          type={type}
          contactId={contactId}
          compId={compId}
          dealId={dealId}
          status={status}
          refreshDealList={refreshDealList}
          openedForView={openedForView}
          handleViewClose={handleViewClose}
          dealCategoryName={dealCategoryName}
        />
      </Modal>
    )
  } else {
    if (openedForView) {
      return (
        <DealsWizardContainer
          onWizardClosed={handleWizardClose}
          usage={usage}
          type={type}
          contactId={contactId}
          compId={compId}
          dealId={dealId}
          status={status}
          refreshDealList={refreshDealList}
          openedForView={openedForView}
          handleViewClose={handleViewClose}
          dealCategoryName={dealCategoryName}
          onEditDeal={handleEditDeal}
        />
      )
    } else {
      return null
    }
  }
}

export default DealContentModal
