import PropTypes from 'prop-types'
import { getFundHealthScores } from '../../actions/funds'
import { getFundHealthColumns } from '../../utils/columns'
import ContextTable from '../ContextTable'

const FundHealthScores = ({ companyId }) => {
  const columns = getFundHealthColumns()

  return (
    <ContextTable
      tableTitle="Health Scores"
      columns={columns}
      dataMethod={getFundHealthScores.bind(null, companyId)}
      scroll={{ x: 1600 }}
    />
  )
}

FundHealthScores.propTypes = { companyId: PropTypes.number.isRequired }

export default FundHealthScores
