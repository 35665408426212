import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  message,
} from 'antd'
import { cloneDeep } from 'lodash'
import moment, { utc } from 'moment'
import FloatingInput from '../../components/FloatingInput/FloatingInput'
import http from '../../utils/api'
import './AgendaDay.less'

const AgendaDayDetails = ({
  visible,
  handleOk,
  handleCancel,
  agenda = null,
  agendaId = 0,
  eventId,
  eventTimezone,
  modalTitle = 'Add Agenda',
  handleDataRefresh,
  speakerRoles,
}) => {
  const [data, setData] = useState(cloneDeep(agenda))

  const [loading, setLoading] = useState(false)

  const [showSpeakerModal, setShowSpeakerModal] = useState(false)
  const [speakerName, setSpeakerName] = useState('')
  const [speakerPosition, setSpeakerPosition] = useState('')
  const [speakerRoleId, setSpeakerRoleId] = useState('')
  const [speakerRoleName, setSpeakerRoleName] = useState('')

  useEffect(() => {
    updateData('eventId', eventId)
  }, [])

  const updateData = (field, value) => {
    var newState = { ...data }
    newState[field] = value
    setData(newState)
  }

  const saveChanges = () => {
    var eventTimezoneStarDate = moment.tz(
      data['startDate'].format('yyyy-MM-DD HH:mm'),
      eventTimezone
    )

    var utcStartDate = moment.utc(eventTimezoneStarDate)
    data['startDate'] = utcStartDate

    var eventTimezoneEndDate = moment.tz(
      data['endDate'].format('yyyy-MM-DD HH:mm'),
      eventTimezone
    )

    var utcEndDate = moment.utc(eventTimezoneEndDate)
    data['endDate'] = utcEndDate

    if (agendaId == 0 && agenda == null) {
      http
        .post(`/events/agenda`, data)
        .then(() => {
          message.success({
            content: 'Agenda created successfully',
          })

          handleDataRefresh()
        })
        .catch((error) => {
          message.error('Something went wrong!')
          handleOk()
        })
        .finally(() => {
          setLoading(false)
          handleOk()
        })
    } else {
      http
        .post(`/events/agenda/edit`, data)
        .then(() => {
          message.success({
            content: 'Agenda updated successfully',
          })

          handleDataRefresh()
        })
        .catch((error) => {
          message.error('Something went wrong!')
          handleOk()
        })
        .finally(() => {
          setLoading(false)
          handleOk()
        })
    }
  }

  const onClose = () => {
    handleCancel()
  }

  const renderSpeakers = (record) => {
    return (
      <div className="agenda-day-speakers-container">
        {record === null
          ? '-'
          : record.map((x, index) => renderSpeakerRole(x, index))}
      </div>
    )
  }

  const renderSpeakerRole = (speakerRole, index) => {
    if (!speakerRole.isDeleted) {
      return (
        <>
          <div className="agenda-speaker-container">
            <div className="agenda-day-speaker-role">
              <span className="agenda-day-speaker-role-title">
                {speakerRole.speakerRoleName}
              </span>
              <div className="agenda-day-speakers-people-container">
                {renderSpeakerPerson(speakerRole)}
              </div>
            </div>
            <Button
              style={{
                backgroundColor: '#D62B34',
                color: 'white',
                marginTop: '17px',
                marginRight: '20px',
              }}
              onClick={(e) => {
                e.stopPropagation()
                if (speakerRole.agendaSpeakerId == 0) {
                  data.speakers.splice(index, 1)
                  updateData('speakers', data.speakers)
                } else {
                  data.speakers[index].isDeleted = true
                  updateData('speakers', data.speakers)
                }
              }}
            >
              <span>
                <FontAwesomeIcon icon={faTrash} />
              </span>
            </Button>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }

  const renderSpeakerPerson = (person) => (
    <div className="agenda-day-speakers-person">
      <span className="cc-body-text agenda-day-speakers-person-name">
        {person.speakerName}
      </span>
      <span className="cc-small-font agenda-day-speakers-person-title">
        {person.position}
      </span>
    </div>
  )

  const onRoleDropdownChange = (value, key) => {
    setSpeakerRoleId(key.key)
    setSpeakerRoleName(value)
  }

  const fieldRows = () => (
    <Form>
      <Form.Item className="agenda-label" label="Time" colon={false} required>
        <Row>
          <Col span={12}>
            <DatePicker
              className="agenda-label-field"
              value={data === null ? '' : data['startDate']}
              name="Time"
              nameField="startDate"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              placeholder="From"
              style={{ width: '100%' }}
              format="MMMM Do YYYY, h:mm A"
              onChange={(e) => {
                updateData('startDate', e)
              }}
            />
          </Col>
          <Col span={12}>
            <DatePicker
              className="agenda-label-field"
              value={data === null ? '' : data['endDate']}
              nameField="endDate"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              placeholder="To"
              style={{ width: '100%' }}
              format="MMMM Do YYYY, h:mm A"
              onChange={(e) => {
                updateData('endDate', e)
              }}
            />
          </Col>
        </Row>
      </Form.Item>

      <Form.Item className="agenda-label" label="Event" colon={false} required>
        <Input
          className="agenda-label-field"
          value={data === null ? '' : data['title']}
          name="Event"
          nameField="title"
          placeholder="Enter Title"
          type="string"
          onChange={(e) => {
            updateData('title', e.currentTarget.value)
          }}
        />
      </Form.Item>

      <Form.Item
        className="agenda-label"
        label="Location"
        colon={false}
        required
      >
        <Input
          className="agenda-label-field"
          value={data === null ? '' : data['location']}
          name="Location"
          nameField="location"
          placeholder="Enter Location"
          type="string"
          onChange={(e) => {
            updateData('location', e.currentTarget.value)
          }}
        />
      </Form.Item>

      <>
        <Row className="agenda-label-speakers">SPEAKERS</Row>

        {{ ...data }['speakers'] != null &&
          renderSpeakers({ ...data }['speakers'])}

        <Button
          className="agenda-label-button"
          onClick={() => setShowSpeakerModal(true)}
          disabled={false}
        >
          <FontAwesomeIcon icon={faPlus} />
          Add Speaker
        </Button>
      </>
    </Form>
  )

  const speakerRows = () => (
    <Form>
      <Form.Item className="agenda-label" label="Role" colon={false} required>
        <Select
          showSearch={false}
          placeholder="Select Role"
          onSelect={onRoleDropdownChange}
          filterOption={false}
          showArrow={false}
        >
          {speakerRoles.map((row) => (
            <Select.Option key={row.speakerRoleId} value={row.roleDescription}>
              {row.roleDescription}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        className="agenda-label"
        label="Speaker"
        colon={false}
        required
      >
        <Input
          className="agenda-label-field"
          name="Speaker"
          placeholder="Enter Name"
          type="string"
          onChange={(e) => {
            setSpeakerName(e.currentTarget.value)
          }}
        />
      </Form.Item>

      <Form.Item
        className="agenda-label"
        label="Location"
        colon={false}
        required
      >
        <Input
          className="agenda-label-field"
          // value={data === null ? '' : data['location']}
          name="Position"
          // nameField="position"
          placeholder="Enter Position"
          type="string"
          onChange={(e) => {
            setSpeakerPosition(e.currentTarget.value)
          }}
        />
      </Form.Item>
    </Form>
  )
  return (
    <div>
      <Modal
        className="cc-modal-accept"
        centered={true}
        title={modalTitle}
        visible={visible}
        okText={`Save Changes`}
        cancelText="Close"
        onOk={saveChanges}
        okButtonProps={{
          disabled:
            loading ||
            data == null ||
            data['startDate'] == null ||
            data['startDate'] == '' ||
            data['endDate'] == null ||
            data['endDate'] == '' ||
            data['title'] == null ||
            data['title'] == '' ||
            data['location'] == null ||
            data['location'] == '' ||
            data['startDate'] > data['endDate']
              ? true
              : false,
          style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
        }}
        onCancel={onClose}
        cancelButtonProps={{ disabled: false }}
      >
        <div style={{ padding: '14px 10px 0px' }}>{fieldRows()}</div>
      </Modal>

      {showSpeakerModal && (
        <Modal
          className="cc-modal-accept"
          title={'Add Speaker'}
          visible={showSpeakerModal}
          okText={`Add Speaker`}
          cancelText="Close"
          onOk={() => {
            setShowSpeakerModal(false)
            var newState = { ...data }
            var speakersArray =
              newState['speakers'] != null ? newState['speakers'] : []

            speakersArray.push({
              agendaSpeakerId: 0,
              isDeleted: false,
              speakerName: speakerName,
              position: speakerPosition,
              speakerRoleId: speakerRoleId,
              speakerRoleName: speakerRoleName,
            })

            newState['speakers'] = speakersArray
            setData(newState)

            setSpeakerName('')
            setSpeakerPosition('')
            setSpeakerRoleId('')
            setSpeakerRoleName('')
          }}
          onCancel={() => {
            setShowSpeakerModal(false)
            setSpeakerName('')
            setSpeakerPosition('')
            setSpeakerRoleId('')
            setSpeakerRoleName('')
          }}
          okButtonProps={{
            disabled:
              speakerName == '' ||
              speakerPosition == '' ||
              speakerRoleName == ''
                ? true
                : false,
            style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
          }}
          centered={true}
          cancelButtonProps={{ disabled: false }}
        >
          <div style={{ padding: '14px 10px 0px' }}>{speakerRows()}</div>
        </Modal>
      )}
    </div>
  )
}

AgendaDayDetails.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  agenda: PropTypes.object.isRequired,
  agendaId: PropTypes.number.isRequired,
  modalTitle: PropTypes.string.isRequired,
}

export default AgendaDayDetails
