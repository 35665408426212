import { Component } from 'react'
import { Input, Popover } from 'antd'
import { isNil } from 'lodash'
import './BetterFloatingInput.less'

const { Search } = Input

class BetterFloatingInput extends Component {
  // const valueChanged = e => {
  //   console.log(e);
  //   if (typeof this.props.onChange === 'function')
  //     this.props.onChange(e.target.value, this.props.name);
  // };

  render() {
    let { value } = this.props
    const { isValid } = this.props

    value = isNil(value) ? '' : value

    const anyText = !isNil(value) && value !== ''
    let classes = !anyText
      ? 'cc-floating-input-notext'
      : 'cc-floating-input-text'
    classes += ' cc-floating-input'
    let placeholderClasses = !anyText
      ? 'placeholder-movement-notext'
      : 'placeholder-movement-text'
    placeholderClasses += ' cc-floating-placeholder'

    let validationMessage = null
    if (isValid === false) {
      classes += ' cc-floating-input-invalid'
      validationMessage = this.props.validationMessage
    }

    return (
      <div className="floating-input-row">
        {/* <Tooltip title={this.props.tooltip} defaultVisible="true" mouseLeaveDelay={4} trigger="hover"> */}
        <Popover
          content={this.props.tooltip}
          visible={!isNil(this.props.tooltip)}
        >
          {this.props.isSearch === true ? (
            <Search
              size="large"
              value={value}
              onKeyPress={this.props.onKeyPress}
              placeholder={this.props.name}
              onSearch={this.props.onSearch}
              onChange={
                this.props.handleChange === true
                  ? this.props.onChange.bind(null, this.props.id)
                  : this.props.onChange
              }
              id={this.props.id}
              onBlur={this.props.onBlur}
              enterButton
              loading={this.props.searchLoading}
              className="cc-floating-search"
            />
          ) : (
            <Input
              size="large"
              value={value}
              onKeyPress={this.props.onKeyPress}
              placeholder={
                this.props.placeholder
                  ? this.props.placeholder
                  : this.props.name
              }
              name={this.props.id ? this.props.id : this.props.nameField}
              onChange={
                this.props.handleChange === true
                  ? this.props.onChange.bind(null, this.props.id)
                  : this.props.onChange
              }
              className={classes}
              id={this.props.id}
              onBlur={this.props.onBlur}
              disabled={isNil(this.props.disabled) ? false : true}
            />
          )}
        </Popover>
        {/* </Tooltip> */}
        {this.props.isSearch === true ? null : (
          <span className={placeholderClasses}>{this.props.name}</span>
        )}
        {isNil(validationMessage) ? null : (
          <div className="cc-small-font cc-validation-message">
            {validationMessage}
          </div>
        )}
      </div>
    )
  }
}

export default BetterFloatingInput
