import jwtDecode from 'jwt-decode'
import { get, isNil, join, map, take, trim } from 'lodash'

export const getInitials = (fullname) =>
  map(take(trim(fullname).split(/\s+/gi), 2), (part) =>
    part.substr(0, 1).toUpperCase()
  ).join('')

export const getCompany = (accessToken) => {
  if (isNil(accessToken)) {
    return { companyId: 0, role: null }
  }

  const claims = jwtDecode(accessToken)
  const companyId = get(claims, 'companies[0].id', 0)
  const role = get(claims, 'companies[0].category', 0)
  return { companyId, role }
}
export const generateId = (length) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length

  const generatedChars = []
  for (let i = 0; i < length; i++) {
    generatedChars.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    )
  }

  return join(generatedChars, '')
}
export const getUserId = (accessToken) => {
  if (isNil(accessToken)) {
    return 0
  }

  const claims = jwtDecode(accessToken)
  return get(claims, 'sub', 0)
}
export const getEmail = (accessToken) => {
  if (isNil(accessToken)) {
    return 0
  }

  const claims = jwtDecode(accessToken)
  return get(claims, 'email', 0)
}
export const getAdminStatus = (accessToken) => {
  if (isNil(accessToken)) {
    return 0
  }

  const claims = jwtDecode(accessToken)
  return get(claims, 'is_admin', 0)
}
export const getMarketingStatus = (accessToken) => {
  if (isNil(accessToken)) {
    return 0
  }

  const claims = jwtDecode(accessToken)

  return get(claims, 'is_marketing', 0)
}

export const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}
