import { useEffect, useRef, useState } from 'react'
import { Button, Modal, message } from 'antd'
import axios from 'axios'
import http from '../../utils/api'
import './BoothManagementSP.less'

const DeleteBoothParticipantSP = ({
  visible,
  handleCancel,
  boothName,
  participantName,
  boothParticipantId,
  setLoading,
  closeModal,
}) => {
  const handleParticipantDelete = () => {
    http
      .post(
        '/booths/spParticipant/' + boothParticipantId + '/cancel?api-version=2'
      )
      .then(function (response) {
        setLoading()
        message.success('Participant is removed successfully !', 3)
      })
      .catch(function (error) {
        message.error('An error has occurred!', 3)
      })
      .finally(() => {
        closeModal()
      })
  }

  return (
    <Modal
      visible={visible}
      width="350px"
      bodyStyle={{ padding: 0 }}
      onCancel={() => {
        handleCancel()
      }}
      footer={[
        <Button
          onClick={() => {
            handleCancel()
          }}
          className="cancelBtn"
        >
          Close
        </Button>,

        <Button
          visible={false}
          type="primary"
          onClick={() => handleParticipantDelete()}
        >
          Remove
        </Button>,
      ]}
    >
      <div style={{ padding: '41px 16px 32px 32px' }}>
        <div style={{ display: 'flex' }}>
          <div style={{}}>
            <div style={{ marginTop: '16px' }} className="cc-heading">
              Are you sure you want to remove <b> {participantName} </b>
              on <b> {boothName} </b> ?
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteBoothParticipantSP
