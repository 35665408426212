import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import {
  getAdminStatus,
  getMarketingStatus,
  isAuthenticated,
} from '../selectors/auth'
import AppLayout from './AppLayout'

const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  isAdmin,
  isMarketing,
  admin = false,
  marketing = false,
  width,
  collapsed,
  handleCollapse,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      return isAuthenticated ? (
        (admin && !isAdmin) || (marketing && !isMarketing && !isAdmin) ? (
          <div>Forbidden 403</div>
        ) : (
          <AppLayout
            handleCollapseComponent={handleCollapse}
            collapsed={collapsed}
            width={width}
          >
            <Component width={width} {...props} />
          </AppLayout>
        )
      ) : (
        <Redirect to="/login" />
      )
    }}
  />
)

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isMarketing: PropTypes.bool.isRequired,
  admin: PropTypes.bool.isRequired,
  marketing: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  collapsed: PropTypes.bool.isRequired,
  handleCollapse: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  isAdmin: getAdminStatus(state),
  isMarketing: getMarketingStatus(state),
})

export default connect(mapStateToProps)(ProtectedRoute)
