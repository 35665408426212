import { useEffect, useState } from 'react'
import { Empty, Spin } from 'antd'
import axios from 'axios'
import isNil from 'lodash/isNil'
import http from '../../utils/api'
import CompanyEventDetails from '../CompanyEventDetails/CompanyEventDetails'
import './CompanyEvent.less'

const CompanyEvent = ({
  match: {
    params: { type, recordId, tab },
  },
  history: { goBack },
}) => {
  const [attendingCompanyEvent, setAttendingCompanyEvent] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  // const selectedEventParticipantId = parseInt(eventParticipantId, 10);
  // const selectedFundEventId = parseInt(fundEventId, 10);
  const selectedRecordId = parseInt(recordId, 10)

  const [refreshTable, setRefreshTable] = useState(false)

  const changeRefresh = (value) => {
    setRefreshTable(!refreshTable)
  }

  useEffect(() => {
    switch (type) {
      case 'fund':
        const eventCompanyBoothFundId = parseInt(recordId, 10)
        http
          .get(`funds/fundscheduledetails/${eventCompanyBoothFundId}`)
          .then((response) => {
            setAttendingCompanyEvent(response.data)
          })
          .finally(function () {
            setIsLoading(false)
          })
          .catch(function (error) {
            console.log(error)
          })

        break
      case 'allocator':
        const eventParticipantId = parseInt(recordId, 10)
        http
          .get(`allocators/participantscheduledetails/${eventParticipantId}`)
          .then((response) => {
            setAttendingCompanyEvent(response.data)
          })
          .finally(function () {
            setIsLoading(false)
          })
          .catch(function (error) {
            console.log(error)
          })
        break
      case 'sp':
        const eventCompanyBoothId = parseInt(recordId, 10)
        http
          .get(`serviceproviders/boothscheduledetails/${eventCompanyBoothId}`)
          .then((response) => {
            setAttendingCompanyEvent(response.data)
          })
          .finally(function () {
            setIsLoading(false)
          })
          .catch(function (error) {
            console.log(error)
          })

        break
      default:
        http
          .get(`allocators/scheduledetails/${selectedRecordId}`)
          .then((response) => {
            setAttendingCompanyEvent(response.data)
          })
          .finally(function () {
            setIsLoading(false)
          })
          .catch(function (error) {
            // handle error
            console.log(error)
          })

        break
    }
  }, [refreshTable])

  if (isNil(attendingCompanyEvent)) {
    return (
      <Spin spinning={isLoading}>
        <Empty />
      </Spin>
    )
  }

  return (
    <CompanyEventDetails
      style={{ padding: '50px' }}
      refresh={changeRefresh}
      refreshMeetings={refreshTable}
      selectedEventCompany={attendingCompanyEvent}
      defaultTab={tab}
      back={goBack}
      type={type}
      // push={() => push(`/summits/:eventId`)}
    />
  )
}

export default CompanyEvent
