import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider } from 'antd'
import { isNil, map } from 'lodash'
import { getCompany, getCompanyFunds } from '../../actions/company'
import FundComplianceModal from './FundComplianceModal'
import './FundCompliance.less'

const FundCompliance = ({ companyId }) => {
  const [funds, setFunds] = useState([])
  const [selectedFund, setSelectedFund] = useState(null)

  useEffect(() => {
    getCompanyFunds(companyId).then((response) => setFunds(response.data))
  }, [companyId])

  return (
    <>
      <div className="FundCompliance-title">Funds</div>
      {map(funds, (fund) => (
        <div className="FundCompliance-row">
          <Button
            type="link"
            className="link-button"
            onClick={() => setSelectedFund(fund)}
          >
            {fund.name}
          </Button>
        </div>
      ))}
      {!isNil(selectedFund) && (
        <FundComplianceModal
          visible={!isNil(selectedFund)}
          fund={selectedFund}
          onCancel={() => setSelectedFund(null)}
          onOk={() => setSelectedFund(null)}
        />
      )}
    </>
  )
}

FundCompliance.propTypes = { companyId: PropTypes.number.isRequired }

export default FundCompliance
