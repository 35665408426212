import { Component } from 'react'
import { Input } from 'antd'
import './FloatingTextArea.less'

const { TextArea } = Input

class FloatingTextArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
  }

  valueChanged = (e) => {
    this.setState({
      value: e.target.value,
    })

    if (typeof this.props.onChange === 'function')
      this.props.onChange(e.target.value, this.props.id)
  }

  render() {
    const value =
      typeof this.props.value === 'undefined' || this.props.value === null
        ? this.state.value
        : this.props.value

    const anyText = value === null || value === ''
    let classes = anyText
      ? 'cc-floating-textarea-notext'
      : 'cc-floating-textarea-text'
    classes += ' cc-floating-textarea'

    let placeholderClasses = anyText
      ? 'placeholder-movement-textarea-notext'
      : 'placeholder-movement-textarea-text'
    placeholderClasses += ' cc-floating-placeholder'

    return (
      <div className="floating-textarea-row">
        <TextArea
          onChange={this.valueChanged}
          placeholder={this.props.name}
          className={classes}
          style={{ height: '120px' }}
          id={this.props.id}
          value={value}
          maxLength={this.props.maxLength}
        />
        <span className={placeholderClasses}>{this.props.name}</span>
      </div>
    )
  }
}

export default FloatingTextArea
