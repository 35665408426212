import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Input, Modal, Row, message } from 'antd'
import http from '../../utils/api'
import FloatingInput from '../FloatingInput'

const DescriptionEditModal = ({
  visible,
  handleOk,
  handleCancel,
  event,
  field,
}) => {
  const [data, setData] = useState(event)

  const updateData = (field, value) => {
    const newState = { ...data }
    newState[field] = value
    setData(newState)
  }

  const saveChanges = () => {
    http.put('/events/update', data).then((response) => {
      message.success('Event information updated')
      handleOk()
    })
  }

  const onClose = () => {
    handleCancel()
  }

  useEffect(() => {
    if (event != null) {
      setData(event)
    }
  }, [event])

  return (
    <Modal
      className="cc-modal-accept"
      title={`Edit Event Infomation`}
      visible={visible}
      okText={`Save Changes`}
      cancelText="Close"
      onOk={saveChanges}
      onCancel={onClose}
      okButtonProps={{
        disabled: false,
        style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
      }}
      centered={true}
      cancelButtonProps={{ disabled: false }}
    >
      <div style={{ padding: '13px 16px 32px 32px' }}>
        <div>
          <Row>
            <Col span={24}>
              <Input.TextArea
                autoSize={{ minRows: 10, maxRows: 10 }}
                value={data[field]}
                name={field}
                nameField={field}
                onChange={(e) => {
                  updateData(field, e.target.value)
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}

DescriptionEditModal.DescriptionEditModal = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  event: PropTypes.number.isRequired,
}

export default DescriptionEditModal
