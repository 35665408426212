import { useCallback, useEffect, useState } from 'react'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, Col, Input, Radio, Row, message } from 'antd'
import { useHistory } from 'react-router-dom'
import BetterFloatingInput from '../../components/BetterFloatingInput/BetterFloatingInput'
import http from '../../utils/api'
import './AddAnnouncement.less'

const AddAnnouncement = ({
  match: {
    params: { id },
  },
}) => {
  const history = useHistory()
  const [data, setData] = useState({})
  const [status, setStatus] = useState(true)
  const [loading, setLoading] = useState(false)
  const [checkedList, setCheckedList] = useState([])
  const selectedRecordId = parseInt(id, 10)

  const roles = [
    { title: 'Allocators', id: 2 },
    //{ title: 'CFN', id: 6 },
    { title: 'Fund Managers', id: 1 },
    { title: 'Service Providers', id: 3 },
  ]

  const updateData = (field, value) => {
    const newState = { ...data }
    newState[field] = value
    setData(newState)
  }

  const handleRolesChecked = useCallback(
    (e) => {
      let tmpcheckedlist
      if (e.target.checked) {
        tmpcheckedlist = [...checkedList, e.target.value]
      } else {
        tmpcheckedlist = checkedList.filter((p) => p !== e.target.value)
      }
      setCheckedList(tmpcheckedlist)
    },
    [checkedList]
  )

  useEffect(() => {
    if (!isNaN(selectedRecordId)) {
      http
        .get(`companies/announcement/details/${selectedRecordId}`)
        .then((response) => {
          data['title'] = response.data.title
          data['description'] = response.data.description
          data['link'] = response.data.link
          setStatus(response.data.status)
          setCheckedList(JSON.parse(response.data.roles))
        })
    }
  }, [selectedRecordId])

  const saveChanges = () => {
    Object.keys(data).forEach(function (key) {
      if (data[key] === '') {
        return
      }
    })

    setLoading(true)
    const announcement = {}
    announcement['status'] = status
    announcement['roles'] = JSON.stringify(checkedList)

    Object.keys(data).forEach(function (key) {
      if (key !== 'name') announcement[key] = data[key]
    })

    if (!isNaN(selectedRecordId)) {
      announcement['announcementId'] = selectedRecordId
      http
        .put('companies/announcement/update', announcement)
        .then((response) => {
          if (response.status === 200) {
            message.success('Successfully updated Announcement!')
            history.push('/announcements')
          } else {
            message.error('Something went wrong, please try again!')
          }
        })
        .catch(() => {
          message.error('Something went wrong, please try again!')
        })
        .finally(() => setLoading(false))
    } else {
      http
        .post('companies/announcement/add', announcement)
        .then((response) => {
          if (response.status === 200) {
            message.success('Successfully created new Announcement!')
            history.push('/announcements')
          } else {
            message.error('Something went wrong, please try again!')
          }
        })
        .catch(() => {
          message.error('Something went wrong, please try again!')
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <Row type="flex" justify="space-between">
      <Col span={11} style={{ display: 'flex' }}>
        <Button
          onClick={() => history.back()}
          style={{ marginRight: '10px', padding: '0px 10px' }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <h1 className="cc-section-header-title" style={{ lineHeight: '30px' }}>
          {isNaN(selectedRecordId) ? 'New announcement' : 'Update Announcement'}
        </h1>
      </Col>

      <Col span={24}>
        <Row className="row-styling">
          <Col
            span={12}
            style={{ borderRight: '1px solid #EFF1F4', paddingRight: '70px' }}
          >
            <h3 className="h3-title">Announcement details:</h3>
            <BetterFloatingInput
              value={data['title']}
              name="Title"
              nameField="title"
              onChange={(e) => {
                updateData('title', e.target.value)
              }}
            />
            <Input.TextArea
              name="Description (255 char max)"
              nameField="description"
              placeholder="Description (255 char max)"
              maxLength={255}
              autoSize={{ minRows: 6, maxRows: 6 }}
              value={data['description']}
              onChange={(e) => {
                updateData('description', e.target.value)
              }}
            />
            <span style={{ marginBottom: '16px' }}>&nbsp;</span>
            <BetterFloatingInput
              value={data['link']}
              name="Link"
              nameField="link"
              onChange={(e) => {
                updateData('link', e.target.value)
              }}
            />
          </Col>
          <Col span={12} style={{ paddingLeft: '70px' }}>
            <h3 className="h3-title">Status:</h3>
            <Radio.Group
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <Radio value={true} />
              <span style={{ marginRight: '25px' }}>Enabled</span>
              <Radio value={false} />
              <span>Disabled</span>
            </Radio.Group>
            <h3
              className="h3-title"
              style={{
                paddingTop: '16px',
              }}
            >
              Roles to where it will be displayed:
              <div>
                {roles.map((row) => {
                  return (
                    <Checkbox
                      style={{ display: 'block' }}
                      className="cc-check-participants"
                      value={row.id}
                      onChange={handleRolesChecked}
                      checked={
                        checkedList.some((e) => e == row.id) ? true : false
                      }
                    >
                      {row.title}
                    </Checkbox>
                  )
                })}
              </div>
            </h3>
          </Col>
          <Col span={24} style={{ marginTop: '55px', marginBottom: '35px' }}>
            <hr
              style={{ backgroundColor: '#EFF1F4', height: '1px', border: '0' }}
            />
          </Col>
          <Col span={6} offset={13}>
            <Button
              size="large"
              className="cancel-btn"
              onClick={() => history.back()}
            >
              <span style={{ color: '#2FC5D2', opacity: '1' }}>Cancel</span>
            </Button>
            <Button
              size="large"
              className="submit-btn"
              onClick={() => saveChanges()}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

AddAnnouncement.GeneralInfoModal = {}

export default AddAnnouncement
