import { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { Empty, message } from 'antd'
import axios from 'axios'
import {
  filter,
  first,
  fromPairs,
  get,
  includes,
  isEmpty,
  isNil,
  map,
  uniqBy,
} from 'lodash'
import mime from 'mime-types'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchCompanyIfNeeded } from '../actions/company'
import { setPageTitle } from '../actions/title'
import DiscoverServiceProviderDetailsModal from '../components/DiscoverServiceProviderDetailsModal/DiscoverServiceProviderDetailsModal'
import { getInitialValues } from '../components/DynamicForm/DynamicForm'
import LoadingGif from '../components/LoadingGif'
import ServiceProviderWizard from '../components/ServiceProviderWizard'
// import ServiceProviderDetailsNoActions from '../components/ServiceProviderDetails/ServiveProviderDetailsNoActions';
import { ServiceProviderValidatorShape } from '../components/ServiceProviderWizard/ServiceProviderValidatorShape'
import * as types from '../constants/types/serviceprovideronboarding'
import http from '../utils/api'

const answersInitialState = {
  serviceProviderName: null,
  answers: [],
  finished: false,
}

const converters = {
  'Numeric - INT': (n) => {
    if (isNil(n)) {
      return null
    }
    return parseInt(n, 10)
  },
  'Numeric - FLOAT': (n) => {
    if (isNil(n)) {
      return null
    }
    return parseFloat(n)
  },
  'Boolean': (b) => {
    if (isNil(b)) {
      return null
    }
    return b === 'true'
  },
  'Upload - Excel': (v) => {
    if (isNil(v)) {
      return v
    }
    return encodeURI(v)
  },
  'Upload - File': (v) => {
    if (isNil(v)) {
      return v
    }
    return encodeURI(v)
  },
}

const preSaveConverters = {
  'Upload - Excel': (v) => {
    if (isNil(v)) {
      return v
    }
    return decodeURI(v)
  },
  'Upload - File': (v) => {
    if (isNil(v)) {
      return v
    }
    return decodeURI(v)
  },
}

const getPreSaveData = (data, questions, converters) => {
  const typeLookup = fromPairs(
    map(questions.serviceProviderQuestions, (q) => [
      q.serviceProviderQuestionId,
      get(converters, q.questionType, (elem) => elem),
    ])
  )

  return {
    ...data,
    answers: map(data.answers, (a) => ({
      ...a,
      responseText: get(
        typeLookup,
        a.serviceProviderQuestionId,
        (i) => i
      )(a.responseText),
    })),
  }
}

const getConvertedAnswers = (answers, questions, converters) => {
  const typeLookup = fromPairs(
    map(questions, (q) => [
      q.serviceProviderQuestionId,
      get(converters, q.questionType, (elem) => elem),
    ])
  )

  const convertedAnswers = map(answers, (answer) => ({
    ...answer,
    responseText: get(
      typeLookup,
      answer.serviceProviderQuestionId,
      (i) => i
    )(answer.responseText), // typeLookup[answer.fundQuestionId](answer.responseText),
  }))
  return convertedAnswers
}

const answersReducer = (state, action) => {
  switch (action.type) {
    case types.ADD_SP:
      return { ...state, ...action.payload }
    case types.APPEND_ANSWERS_SP:
      return {
        ...state,
        answers: uniqBy(
          [...action.payload, ...state.answers],
          'serviceProviderQuestionId'
        ),
        finished: get(action, 'meta.finished', false),
      }
    default:
      return state
  }
}

const ServiceProviderWizardContainer = ({
  onWizardClosed = () => {},
  companyId,
  company,
  contacts,
}) => {
  const { push } = useHistory()

  //const companyId = useSelector(getCompanyId);
  //const company = useSelector(getUserCompanies);
  const [questionTypes, setQuestionTypes] = useState([])
  const [currentStep, setCurrentStep] = useState(1)
  const [questions, setQuestions] = useState({})
  const [loading, setLoading] = useState(false)
  const [state, dispatch] = useReducer(answersReducer, answersInitialState)
  const [showServiceProviderProfile, setShowServiceProviderProfile] =
    useState(false)
  const [previewObject, setPreviewObject] = useState({})
  const [finished, setFinished] = useState(false)

  const [stepsInErrorId, setStepsInErrorId] = useState([])
  const reduxDispatch = useDispatch()

  const questionsWithOptions = new Set(
    map(
      filter(questionTypes, (qt) => qt.answers),
      (qt) => qt.questionTypeId
    )
  )

  useEffect(
    () => () => {
      reduxDispatch(setPageTitle(null))
    },
    [reduxDispatch]
  )

  useEffect(() => {
    const peopleAnswer = state.answers.find(
      (answer) => answer.shortName === 'People'
    )

    const testimonialsAnswer = state.answers.find(
      (answer) => answer.shortName === 'Testimonials'
    )

    const clientsAnswer = state.answers.find(
      (answer) => answer.shortName === 'Clients'
    )

    const blogLinkAnswer = state.answers.find(
      (answer) => answer.shortName === 'BlogLink'
    )
    const caseStudiesLinkAnswer = state.answers.find(
      (answer) => answer.shortName === 'CaseStudiesLink'
    )

    const webCastsLinkAnswer = state.answers.find(
      (answer) => answer.shortName === 'WebCastsLink'
    )

    const whitePaperGuidesLinkAnswer = state.answers.find(
      (answer) => answer.shortName === 'WhitePaperGuidesLink'
    )

    const facebookPageLinkAnswer = state.answers.find(
      (answer) => answer.shortName === 'FacebookPageLink'
    )
    const twitterProfileLink = state.answers.find(
      (answer) => answer.shortName === 'TwitterProfileLink'
    )

    const linkedInPageLink = state.answers.find(
      (answer) => answer.shortName === 'LinkedInPageLink'
    )

    const instagramProfileLink = state.answers.find(
      (answer) => answer.shortName === 'InstagramProfileLink'
    )

    const logoLink = state.answers.find((answer) => answer.shortName === 'Logo')

    const companyMediaLink = state.answers.find(
      (answer) => answer.shortName === 'CompanyMedia'
    )

    const parsedAnswers = (answers) =>
      isNil(answers) || isNil(answers.responseText)
        ? null
        : JSON.parse(answers.responseText)

    const imagesList = parsedAnswers(companyMediaLink)?.filter(
      (link) => !mime.lookup(link).includes('video')
    )

    const videoLink = parsedAnswers(companyMediaLink)?.filter((link) =>
      mime.lookup(link).includes('video')
    )
    const object = {
      people: parsedAnswers(peopleAnswer),
      testimonials: parsedAnswers(testimonialsAnswer),
      clients: parsedAnswers(clientsAnswer),
      blogUrl: blogLinkAnswer?.responseText,
      caseStudiesUrl: caseStudiesLinkAnswer?.responseText,
      webCastsUrl: webCastsLinkAnswer?.responseText,
      whitepapersAndGuidesUrl: whitePaperGuidesLinkAnswer?.responseText,
      facebookUrl: facebookPageLinkAnswer?.responseText,
      twitterUrl: twitterProfileLink?.responseText,
      linkedInUrl: linkedInPageLink?.responseText,
      instagramUrl: instagramProfileLink?.responseText,
      imageUrl: logoLink?.responseText,
      images: imagesList,
      videoUrl: videoLink,
      companyName: company[0]?.name,
      address: company[0]?.address,
      city: company[0]?.city,
      description: company[0]?.companyDescription,
      website: company[0]?.companyWebsite,
    }
    setPreviewObject(object)
  }, [state, company])

  useEffect(() => {
    setLoading(true)

    const requests = [
      http.get('/questions/types'),
      http.get('/serviceproviders/questions'),
    ]

    if (!isNil(companyId)) {
      requests.push(
        http.get(
          `/serviceproviders/${contacts[0].contactId}/${companyId}/answers`
        )
      )
    }

    axios
      .all(requests)
      .then(
        axios.spread(
          (questionTypesResponse, questionsResponse, answersResponse) => {
            setQuestionTypes(questionTypesResponse.data)
            setQuestions(questionsResponse.data)

            const serviceProviderQuestions = questionsResponse.data

            if (!isNil(answersResponse)) {
              const { serviceProviderName, answers } = answersResponse.data

              dispatch({
                type: types.ADD_SP,
                payload: { serviceProviderName },
              })

              reduxDispatch(setPageTitle(serviceProviderName))

              dispatch({
                type: types.APPEND_ANSWERS_SP,
                payload: getConvertedAnswers(
                  answers,
                  serviceProviderQuestions,
                  converters
                ),
              })
            }
          }
        )
      )
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('[ServiceProviderWizardContainer]::Error', err)
      })
      .finally(() => setLoading(false))
  }, [companyId, reduxDispatch])

  useEffect(() => {
    const { ...data } = state
    if (finished) {
      setLoading(true)
      setFinished(false)
      const method = isNil(companyId) ? 'post' : 'patch'
      const endpoint = isNil(companyId)
        ? '/serviceproviders/new'
        : `/serviceproviders/${contacts[0].contactId}/${companyId}`

      http[method](endpoint, getPreSaveData(data, questions, preSaveConverters))
        .then(() => {
          message.success('Company profile saved successfully.')
          reduxDispatch(setPageTitle(null))
        })
        .catch((err) => {
          message.error('Could not save service provider.', err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [state, push, companyId, questions, reduxDispatch, finished])

  const getStepQuestions = (stepNumber) =>
    filter(questions, (q) => q.stepNumber === stepNumber)

  const getStepAnswers = () => state.answers

  const changeSteps = (step) => {
    const calculatedStep = step + 1
    setCurrentStep(calculatedStep)
  }

  const handleNavigation = (
    questions,
    values,
    actionType,
    isInitial = false,
    isLast = false,
    nextStep = null,
    saveChanges = false,
    publish = false,
    isValid = true
  ) => {
    if (isInitial) {
      dispatch({
        type: types.ADD_SP,
        payload: {
          serviceProviderName: values.Name,
        },
      })

      reduxDispatch(setPageTitle(values.Name))
    }

    const answers = map(questions, (q) => ({
      serviceProviderQuestionId: q.serviceProviderQuestionId,
      shortName: q.shortName,
      serviceProviderQuestionAnswerIds: questionsWithOptions.has(
        q.questionTypeId
      )
        ? get(values, q.shortName, [])
        : [],
      responseText: !questionsWithOptions.has(q.questionTypeId)
        ? values[q.shortName]
        : null,
    }))

    const action = {
      type: actionType,
      payload: answers,
    }

    const latestStepIds = map(answers, (ans) => ans.serviceProviderQuestionId)

    if (publish) {
      setLoading(true)
      if (isValid) {
        const answersData = [
          ...filter(
            state.answers,
            (ans) => !includes(latestStepIds, ans.serviceProviderQuestionId)
          ),
          ...answers,
        ]
        http
          .post(
            `/serviceproviders/${contacts[0].contactId}/${companyId}/publish`,
            {
              answers: answersData,
            }
          )
          .then(() => {
            message.success('Profile published successfully')
            reduxDispatch(fetchCompanyIfNeeded(companyId))
            setShowServiceProviderProfile(false)
            onWizardClosed()
            setLoading(false)
          })
          .catch(() => {
            message.error('Could not publish profile')
            setLoading(false)
          })
      } else {
        setLoading(false)
        message.error(
          'Could not publish profile. Please check highlighted steps.'
        )
      }
    }

    if (saveChanges) {
      if (isValid) {
        action['meta'] = {
          finished: true,
        }
        dispatch(action)
        setFinished(true)
      } else {
        message.error('Could not save profile. Please check highlighted steps.')
      }
    }

    if (!isEmpty(stepsInErrorId)) {
      validateBeforeSave(answers)
        .then(() => {
          setStepsInErrorId([])
        })
        .catch((err) => {
          getStepInError(err.inner)
        })
    }

    dispatch(action)

    if (!isLast & !saveChanges) {
      if (isNil(nextStep)) {
        // if (isNil(fundId)) {
        //   setCurrentStep(0);
        // }
        setCurrentStep(0)
      } else {
        setCurrentStep(nextStep)
      }
    }
  }

  if (isEmpty(questions)) {
    return (
      <LoadingGif spinning={loading}>
        <Empty />
      </LoadingGif>
    )
  }

  const getResponseText = (questions, answerIds) => {
    if (!isNil(answerIds) && answerIds.length > 0 && !isNil(questions)) {
      const answers = map(
        answerIds,
        (a) =>
          first(
            questions.serviceProviderQuestionAnswers.filter(
              (q) => q.serviceProviderQuestionAnswerId === a
            )
          )?.answer
      )
      return answers.join(', ')
    } else return ''
  }

  const validateBeforeSave = (answers) => {
    answers.forEach((newAnswer) => {
      const match = allAnswers.find(
        (ans) => ans.shortName === newAnswer.shortName
      )
      const index = allAnswers.indexOf(match)
      allAnswers.splice(index, 1, newAnswer)
    })
    const answersLookup = getInitialValues(
      allQuestions,
      allAnswers,
      questionsWithOptions
    )

    const validator = ServiceProviderValidatorShape(allQuestions)

    return validator.validate(answersLookup, { abortEarly: false })
  }

  const allAnswers = [...state.answers]

  const allQuestions = [...questions]

  // eslint-disable-next-line no-unused-vars
  const previewAnswers = isNil(allAnswers)
    ? []
    : map(allAnswers, (ans) => ({
        shortName: ans.shortName,
        responseText:
          ans.responseText !== null
            ? ans.responseText
            : getResponseText(
                first(allQuestions.filter((q) => q.shortName == ans.shortName)),
                ans.serviceProviderQuestionAnswerIds
              ),
      }))

  const getStepInError = (errorList) => {
    const errorStepsList = []
    errorList.forEach((error) => {
      const step = allQuestions.find(
        (a) => a.shortName === error.path
      ).stepNumber
      errorStepsList.push(calculateStep(step))
    })
    setStepsInErrorId(errorStepsList)
  }

  const calculateStep = (step) => {
    if (step >= 8) return step - 1
    else return step
  }

  return (
    <>
      <ServiceProviderWizard
        currentStep={currentStep}
        questions={getStepQuestions(currentStep)}
        companyId={companyId}
        answers={getStepAnswers(currentStep)}
        questionsWithOptions={questionsWithOptions}
        loading={loading}
        onNavigation={handleNavigation}
        onPreview={() => setShowServiceProviderProfile(true)}
        onStepChange={changeSteps}
        stepsInErrorId={stepsInErrorId}
      />
      <DiscoverServiceProviderDetailsModal
        serviceProvider={previewObject}
        hideActions={true}
        isOnPreview={true}
        visible={showServiceProviderProfile}
        companyId={companyId}
        onCancel={() => setShowServiceProviderProfile(false)}
      ></DiscoverServiceProviderDetailsModal>
    </>
  )
}

ServiceProviderWizardContainer.propTypes = {
  onWizardClosed: PropTypes.func,
}

export default ServiceProviderWizardContainer
