export const ACCESS_TOKEN_KEY = 'access_token'
export const REFRESH_TOKEN_KEY = 'refresh_token'
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const REACT_APP_365_URL = process.env.REACT_APP_365_URL
export const REACT_APP_365_BACK_END = process.env.REACT_APP_365_BACK_END
export const REACT_APP_CALL_STATS_URL =
  process.env.REACT_APP_CALL_STATS_URL || ''
export const GEOCODING_API_KEY = 'AIzaSyDCKvrRoroAKNY_hFIPvUG3wYAXb9B3Kj0'
export const GEOCODING_API_URL =
  'https://maps.googleapis.com/maps/api/geocode/json'
export const TIMEOUT = parseInt(process.env.REACT_APP_TIMEOUT || '100000', 10)

export const TrackingAnalytics = {
  enabled: process.env.REACT_APP_INIT_ANALYTICS === '1',
}
export const REACT_APP_SEATS_PUBLIC_KEY =
  process.env.REACT_APP_SEATS_PUBLIC_KEY || ''
export const ALLOW_CONTACT_CREATION =
  process.env.REACT_APP_ALLOW_CONTACT_CREATION === 'true'
export const REACT_APP_STORAGE_URL = process.env.REACT_APP_STORAGE_URL
