import clone from 'lodash/clone'
import filter from 'lodash/filter'
import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
/* eslint-disable no-console */
import * as Yup from 'yup'
import TypeMapper from '../Forms/typemapper'
import './ServiceProviderWizard.less'

const websiteShortnames = [
  'BlogLink',
  'CaseStudiesLink',
  'WebCastsLink',
  'WhitePaperGuidesLink',
  'FacebookPageLink',
  'InstagramProfileLink',
  'LinkedInPageLink',
  'TwitterProfileLink',
  'Link1',
  'Link2',
  'Link3',
]

export const ServiceProviderValidatorShape = (questions) => {
  const validatorShape = fromPairs(
    filter(
      map(questions, (q) => {
        const { isRequired, questionType, shortName } = q
        const validators = clone(get(TypeMapper, questionType, {}))

        validators.nullable = true

        if (isRequired) {
          validators.required = 'This field is required'
        } else {
          validators.notRequired = null
        }

        let validator = null
        if (!isEmpty(validators)) {
          try {
            validator = reduce(
              validators,
              (prev, msg, validatorName) => {
                if (validatorName === 'custom') {
                  return prev.test(
                    msg.message,
                    msg.message,
                    msg.validationFunction
                  )
                } else {
                  return isNil(msg)
                    ? prev[validatorName]()
                    : prev[validatorName](msg)
                }
              },
              Yup
            )
          } catch (err) {
            console.error(
              `VALIDATION ERROR: Error adding validators for ${shortName} (${questionType}):`,
              validators,
              err
            )
          }
        }

        if (!(isNil(q.validators) || isEmpty(q.validators))) {
          validator = reduce(
            q.validators,
            (prev, params, validatorName) => {
              try {
                if (isArray(params)) {
                  return prev[validatorName](...params)
                } else {
                  return isNil(params)
                    ? prev[validatorName]()
                    : prev[validatorName](params)
                }
              } catch (err) {
                console.error(
                  `VALIDATION ERROR: Error adding validators ${validatorName} for ${shortName}:`,
                  params,
                  err
                )
              }
              return prev
            },
            isNil(validator) ? Yup : validator
          )
        }
        if (websiteShortnames.includes(shortName)) {
          validator = Yup.string()
            .matches(
              /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+-~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/,
              'Enter correct url!'
            )
            .nullable()
        }
        if (questionType === 'Checkbox' && isRequired) {
          validator = Yup.array().required()
          validator = validator.test((obj) => {
            if (isEmpty(obj))
              return new Yup.ValidationError(
                'This field is required',
                null,
                shortName
              )
            else return true
          })
        }
        return [shortName, validator]
      }),
      (pair) => !isNil(pair[1])
    )
  )

  return Yup.object().shape(validatorShape)
}
