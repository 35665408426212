import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Layout, Row } from 'antd'
import SearchBox from '../SearchBox/SearchBox'
import './TableHeader.less'

const { Header } = Layout

const TableHeader = ({
  headerTitle,
  searchVisible,
  onSearch,
  onColumnsFilter,
  showColumnsFilter,
}) => {
  return (
    <Header
      className="table-header"
      style={{
        background: '#fff',
        padding: 0,
        boxShadow: '0px 8px 13px rgba(0, 0, 0, 0.04)',
      }}
    >
      <Row type="flex" justify="space-around" align="middle">
        <Col
          className="investors-header-col"
          style={{ paddingTop: '31px' }}
          span={8}
        >
          <span className="table-header-text">{headerTitle}</span>
        </Col>

        <Col
          className="investors-header-col"
          span={16}
          style={{ textAlign: 'right', display: searchVisible }}
        >
          <SearchBox onSearch={onSearch} allowClear={true} />
          {showColumnsFilter && (
            <Button
              type="primary"
              className="investors-columns"
              onClick={onColumnsFilter}
            >
              <FontAwesomeIcon className="investors-columns-icon" />
            </Button>
          )}
        </Col>

        {/* <Col
          className="investors-header-col"
          span={2}
          style={{ textAlign: 'left' }}
        >
          
        </Col> */}
      </Row>
    </Header>
  )
}

export default TableHeader
