import React, { useState } from 'react'
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons'
import { faPlus, faUserFriends } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Col, Divider, Dropdown, Menu, Row, message } from 'antd'
import http from '../../utils/api'
import DeleteBoothParticipantSP from './DeleteBoothParticipantSP'
import MeetingsManagementModalSP from './MeetingsManagementModalSP'
import './DragAndDropSP.less'

function DragNDropContactsSP({
  data,
  participants,
  availableBooths,
  setLoading,
  companyId,
}) {
  const [contactMenu, setContactMenu] = useState(null)
  const [deleteParticipantModalVisible, setDeleteParticipantModalVisible] =
    useState(false)

  const [participantNameToDelete, setParticipantNameToDelete] = useState('')
  const [participantToDelete, setParticipantToDelete] = useState('')
  const [boothNameToRemoveFrom, setBoothNameToRemoveFrom] = useState('')
  const [showBoothMeetings, setShowBoothMeetings] = useState(false)
  const [boothIdToShowMeetings, setBoothIdToShowMeetings] = useState('')
  const [boothNameToShowMeetings, setBoothNameToShowMeetings] = useState('')

  const handleAddParticipant = (contactId, eventCompanyBoothId) => {
    http
      .post(
        '/booths/' +
          eventCompanyBoothId +
          '/spParticipant/' +
          contactId +
          '/add?api-version=2'
      )
      .then(function (response) {
        message.success('Participant added successfully !', 3)
        setLoading()
      })
      .catch(function (error) {
        message.error('An error has occurred!', 3)
      })
      .finally(() => {})
  }

  const showContactsAvailable = (index) => {
    var eventCompanyBoothId = availableBooths.filter(
      (p) => p.BoothId === data[index].title
    )[0].EventCompanyBoothId

    const scheduleContacts = data[index].items
    const menuContacts = []
    participants.map((value) => {
      if (
        typeof scheduleContacts === 'undefined' ||
        scheduleContacts.findIndex(
          (p) => p.eventParticipant.contact.contactId == value.contactId
        ) === -1
      ) {
        menuContacts.push(value)
      }
    })

    if (menuContacts.length <= 0) {
      message.warning('All contacts are added to this booth', 3)
      return <></>
    } else {
      return (
        <Menu
          style={{ maxWidth: '286px' }}
          on
          onClick={(e) => {
            handleAddParticipant(e.key, eventCompanyBoothId)
          }}
        >
          {menuContacts.map((item) => (
            <Menu.Item key={item.contactId}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={3}>
                  <Avatar
                    src={item.imageURL}
                    style={{
                      fontSize: '14px',
                      textAlign: 'center',
                      verticalAlign: 'bottom',
                    }}
                    shape="circle"
                    size={32}
                  >
                    {item.contactName}
                  </Avatar>
                </Col>
                <Col span={17}>
                  <div className="contactName">{item.contactName}</div>
                  <div className="contactDetail">
                    {item.jobTitle != null && item.jobTitle.length > 30
                      ? `${item.jobTitle.substring(0, 30)}...`
                      : item.jobTitle}
                  </div>
                </Col>
              </Row>
            </Menu.Item>
          ))}
        </Menu>
      )
    }
  }

  const showMeetingsFromBoothManagement = (boothIndex, boothName) => {
    var eventCompanyBoothId = availableBooths.filter(
      (p) => p.BoothId === data[boothIndex].title
    )[0].EventCompanyBoothId

    setShowBoothMeetings(!showBoothMeetings)

    setBoothIdToShowMeetings(eventCompanyBoothId)
    setBoothNameToShowMeetings(boothName)
  }

  const handleDeleteParticipantClick = (
    boothName,
    spParticipantsNo,
    participantName,
    boothParticipantId
  ) => {
    if (spParticipantsNo > 1) {
      setBoothNameToRemoveFrom(boothName)
      setDeleteParticipantModalVisible(true)
      setParticipantNameToDelete(participantName)
      setParticipantToDelete(boothParticipantId)
    } else {
      message.warning(
        'There should always be one participant tied to the booth',
        3
      )
    }
  }

  const availableBoothsToMoveTo = (boothId) => {
    const availableBoothIds =
      data &&
      [...new Set([].concat(...data.map((o) => o.items)))]
        .filter((o) => o.eventCompanyBoothId != boothId)
        .map((m) => m.eventCompanyBoothId)

    const moveToBooths =
      availableBooths &&
      availableBooths.filter(function (obj) {
        return availableBoothIds.indexOf(obj.EventCompanyBoothId) >= 0
      })

    return moveToBooths
  }

  if (data) {
    return (
      <>
        <Row
          style={{ overflowX: 'auto', display: 'flex', paddingBottom: '10px' }}
        >
          {data.map((booth, boothI) => (
            <Col key={booth.title} className="dnd-booth-group" span={10}>
              <span className="group-title">{booth.title}</span>
              <Divider style={{ marginTop: '20px' }} />
              <Col className="dnd-group" span={24}>
                {booth.items &&
                  booth.items.map((item, itemI) => (
                    <>
                      <div key={item} className="dnd-item-contact">
                        {item && (
                          <>
                            <Col span={3}>
                              <Avatar
                                src={item.eventParticipant.contact.imageURL}
                                style={{
                                  fontSize: '14px',
                                  textAlign: 'center',
                                  verticalAlign: 'bottom',
                                }}
                                shape="circle"
                                size={32}
                              >
                                {item.eventParticipant.contact.imageURL}
                              </Avatar>
                            </Col>
                            <Col span={17}>
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                              >
                                <div className="contactName">
                                  {`${item.eventParticipant.contact.firstName} ${item.eventParticipant.contact.lastName}`}
                                </div>
                              </Row>
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                              >
                                <div className="contactDetail">
                                  {item.eventParticipant.contact.jobTitle}
                                </div>
                              </Row>
                            </Col>
                            <Col
                              span={3}
                              style={{ cursor: 'pointer', color: 'red' }}
                            >
                              <FontAwesomeIcon
                                icon={faMinusCircle}
                                onClick={() => {
                                  handleDeleteParticipantClick(
                                    booth.title,
                                    booth.items.length,
                                    `${item.eventParticipant.contact.firstName} ${item.eventParticipant.contact.lastName}`,
                                    item.eventCompanyBoothParticipantId
                                  )
                                }}
                              />
                            </Col>
                          </>
                        )}
                      </div>
                      <Divider />
                    </>
                  ))}
                <Row className="display-center">
                  <Col>
                    <Dropdown overlay={contactMenu} trigger={['click']}>
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => {
                          setContactMenu(showContactsAvailable(boothI))
                          e.preventDefault()
                        }}
                        style={{ fontWeight: 600, padding: '6px' }}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Add member
                      </a>
                    </Dropdown>
                  </Col>
                </Row>
                <Row className="meetings-row">
                  <Col>
                    <a
                      className=""
                      onClick={(e) => {
                        showMeetingsFromBoothManagement(boothI, booth)
                        e.preventDefault()
                      }}
                      style={{ fontWeight: 600, padding: '6px' }}
                    >
                      <FontAwesomeIcon icon={faUserFriends} />{' '}
                      {availableBooths &&
                        availableBooths.filter(
                          (p) => p.BoothId === data[boothI].title
                        )[0].MeetingsNo}{' '}
                      Meeting(s)
                    </a>
                  </Col>
                </Row>
              </Col>
            </Col>
          ))}
        </Row>

        {deleteParticipantModalVisible && (
          <DeleteBoothParticipantSP
            visible={deleteParticipantModalVisible}
            boothName={boothNameToRemoveFrom}
            handleCancel={() => setDeleteParticipantModalVisible(false)}
            closeModal={() => setDeleteParticipantModalVisible(false)}
            participantName={participantNameToDelete}
            boothParticipantId={participantToDelete}
            setLoading={() => setLoading()}
          />
        )}
        {showBoothMeetings && (
          <MeetingsManagementModalSP
            visible={showBoothMeetings}
            onCancel={() => setShowBoothMeetings(false)}
            boothId={boothIdToShowMeetings}
            companyId={companyId}
            boothName={boothNameToShowMeetings}
            availableBoothsToMoveTo={availableBoothsToMoveTo(
              boothIdToShowMeetings
            )}
            setPageLoading={() => setLoading()}
          />
        )}
      </>
    )
  } else {
    return null
  }
}

export default DragNDropContactsSP
