import http from '../utils/api'

export const sendEventSms = (eventId, text) =>
  http.post(`sms/${eventId}`, { text }, { timeout: 90000 })

export const getEventSmsCount = (eventId) => http.get(`sms/${eventId}/count`)

export const getEventSms = (eventId) => http.get(`sms/${eventId}`)

export const getEventSmsLogs = (eventSmsId) =>
  http.get(`sms/${eventSmsId}/logs`)
