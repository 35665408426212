import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CompanyDetails from '../components/CompanyDetails/CompanyDetails'
import http from '../utils/api'

const getEndPoint = (category) => {
  switch (category) {
    case 'fund':
      return 'fundmanagerdetails'
    case 'serviceprovider':
      return 'serviceproviderdetails'
    case 'media':
      return 'mediadetails'
    case 'allocator':
      return 'allocatordetails'
    case 'cfn':
      return 'cfndetails'
    default:
      return ''
  }
}

const CompanyDetailsContainer = ({
  match: {
    params: { category, companyId, tab },
  },
}) => {
  const selectedCompanyId = parseInt(companyId, 10)
  const [company, setCompany] = useState({})
  useEffect(() => {
    let type = ''

    type = getEndPoint(category)

    http
      .get(`/companies/${selectedCompanyId}/${type}`)
      .then(function (response) {
        setCompany(response.data)
      })
  }, [category, selectedCompanyId])

  //   if (isNil(company)) {
  //     return (
  //       <Spin spinning="true">
  //         <Empty />
  //       </Spin>
  //     );
  //   }
  return (
    <CompanyDetails
      selectedCompany={company}
      companyId={companyId}
      category={category}
      tab={tab}
    />
  )
}

CompanyDetailsContainer.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string,
      companyId: PropTypes.number,
      tab: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default CompanyDetailsContainer
