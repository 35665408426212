import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  message,
} from 'antd'
import { isEmpty, isNil, map } from 'lodash'
import {
  createOffPlatformToFund,
  getColleagues,
  sendOnlineRequestToFund,
} from '../../actions/contextMeetings.js'
import http from '../../utils/api'
import CompanySelectContainer from '../CompanySelectContainer'
import MeetingRequestProposedTimes from '../MeetingRequestProposedTimes'
import ParticipantsCheckbox from '../ParticipantsCheckbox'
import ToArrow from './arrow.svg'
import './DiscoverFundMeetingRequest.less'

const { TextArea } = Input

const { Option, OptGroup } = Select

const DiscoverFundMeetingRequest = ({
  visible,
  fund,
  company,
  contacts,
  onClose,
  onRefresh,
}) => {
  const [allocators, setAllocators] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [myColleagues, setMyColleagues] = useState([])
  const [otherColleagues, setOtherColleagues] = useState(contacts)
  const [participants, setParticipants] = useState([])
  const [toParticipants, setToParticipants] = useState([])
  const [selectedHour, setSelectedHour] = useState({})
  const [textMessage, setMessage] = useState('')
  const [duration, setDuration] = useState(30)
  const [proposedMeetingDateTimes, setProposedMeetingDateTimes] = useState([])
  const [selectedContactList, setSelectedContactList] = useState({
    participants: [],
    toParticipants: [],
  })
  const [savingInProggress, setSavinginProggress] = useState(false)
  const [fromCompanyType, setFromCompanyType] = useState(1)
  const [isFundManager, setIsFundManager] = useState(false)
  const [currentCompany, setCurrentCompany] = useState({})
  const [destCompany, setDestCompany] = useState({})
  const [funds, setFunds] = useState([])
  const [senderId, setSenderId] = useState(null)
  const [checkedList, setCheckedList] = useState([])
  const [invalidCombinations, setInvalidCombinations] = useState([])
  const [invalidMeetingCombination, setInvalidMeetingCombination] =
    useState(false)
  const [invitedParticipants, setInvitedParticipants] = useState([])
  const [invitedCompany, setInvitedCompany] = useState(null)
  const [canSendToFund, setCanSendToFund] = useState(false)
  const [isOffPlatform, setIsOffPlatform] = useState(false)

  const handleParticipantsChanged = useCallback(
    (mine, participants) => {
      if (mine)
        setSelectedContactList({
          participants,
          toParticipants: selectedContactList.toParticipants,
        })
      else
        setSelectedContactList({
          participants: selectedContactList.participants,
          toParticipants: participants,
        })
    },
    [selectedContactList]
  )

  const handleOk = useCallback(() => {
    setSavinginProggress(true)
    if (isOffPlatform) {
      return createOffPlatformToFund(
        currentCompany.company.companyId,
        senderId,
        company.companyId,
        fund.fundId,
        selectedContactList.participants,
        selectedContactList.toParticipants,
        textMessage,
        invitedParticipants
      )
        .then(() => {
          message.success('Request sent succesfully.')
          onRefresh()
          onClose()
        })
        .catch(() => {
          message.error('Could not send meeting request.')
        })
        .finally(() => {
          setSavinginProggress(false)
        })
    } else {
      return sendOnlineRequestToFund(
        currentCompany.company.companyId,
        senderId,
        company.companyId,
        fund.fundId,
        selectedContactList.participants,
        selectedContactList.toParticipants,
        textMessage,
        3,
        proposedMeetingDateTimes.map((x) => x.selectedTime),
        duration,
        invitedParticipants
      )
        .then(() => {
          message.success('Request sent succesfully.')
          onRefresh()
          onClose()
        })
        .catch(() => {
          message.error('Could not send meeting request.')
        })
        .finally(() => {
          setSavinginProggress(false)
        })
    }
  }, [
    duration,
    fund,
    selectedContactList,
    proposedMeetingDateTimes,
    textMessage,
    onClose,
  ])

  const checkCanSendToFund = (e) => {
    var contact = myColleagues.find((c) => c.contactId === e)
    http
      .get(`/companies/cfn/allocator/check/${contact.companyContactId}`)
      .then((response) => {
        setCanSendToFund(response.data)
        if (response.data === false) {
          message.error('Sender has not onboarded as Allocator!')
        }
      })
  }

  const handleParticipantChecked = useCallback(
    (e) => {
      let tmpparticipants
      let tmpcheckedlist
      if (e.target.checked) {
        tmpparticipants = [...participants, e.target.value]
        tmpcheckedlist = [...checkedList, e.target.value]
      } else {
        tmpparticipants = participants.filter((p) => p !== e.target.value)
        tmpcheckedlist = checkedList.filter((p) => p !== e.target.value)
      }
      setParticipants(tmpparticipants)
      setCheckedList(tmpcheckedlist)
      handleParticipantsChanged(true, tmpparticipants)
    },
    [handleParticipantsChanged, participants]
  )

  const handleToParticipantChecked = useCallback(
    (e) => {
      let tmptoParticipants
      if (e.target.checked) {
        tmptoParticipants = [...toParticipants, e.target.value]
        setToParticipants(tmptoParticipants)
      } else {
        tmptoParticipants = toParticipants.filter((p) => p !== e.target.value)
        setToParticipants(tmptoParticipants)
      }

      handleParticipantsChanged(false, tmptoParticipants)
    },
    [handleParticipantsChanged, toParticipants]
  )

  const handleInvitedParticipants = useCallback(
    (e) => {
      let tmpInvitedParticipants
      if (e.target.checked) {
        tmpInvitedParticipants = [...invitedParticipants, e.target.value]
        setInvitedParticipants(tmpInvitedParticipants)
      } else {
        tmpInvitedParticipants = invitedParticipants.filter(
          (p) => p !== e.target.value
        )
        setInvitedParticipants(tmpInvitedParticipants)
      }
    },
    [invitedParticipants]
  )

  const handleProposedTimesChanged = useCallback((times) => {
    setProposedMeetingDateTimes(times)
  }, [])

  const footer = (
    <div className="footer">
      <Button
        onClick={() => {
          setCurrentStep(0)
          setToParticipants([])
          setParticipants([])
          setSelectedHour({})
          onClose()
        }}
      >
        Cancel
      </Button>
      <Button
        loading={savingInProggress}
        type="primary"
        disabled={
          canSendToFund === false ||
          senderId === null ||
          toParticipants.length === 0 ||
          (!isOffPlatform &&
            fromCompanyType !== 3 &&
            proposedMeetingDateTimes.filter((x) => !isNil(x.selectedTime))
              .length < 2)
        }
        onClick={() => handleOk()}
      >
        Send Meeting Request
      </Button>
    </div>
  )

  const handleSelectChange = (e) => {
    setParticipants([])
    setCheckedList([])
    setSenderId(null)
    setSelectedContactList({
      participants: [],
      toParticipants: selectedContactList.toParticipants,
    })
    var selectedCompany = allocators.allocators.filter(
      (obj) =>
        obj.company.companyId === e &&
        obj.company.category.toLowerCase() === 'allocator'
    )
    if (selectedCompany.length === 0) {
      selectedCompany = allocators.serviceProviders.filter(
        (obj) =>
          obj.company.companyId === e &&
          obj.company.category.toLowerCase() === 'service provider'
      )
      if (selectedCompany.length === 0) {
        selectedCompany = allocators.media.filter(
          (obj) =>
            obj.company.companyId === e &&
            obj.company.category.toLowerCase() === 'media'
        )
        if (selectedCompany.length === 0) {
          selectedCompany = allocators.cfNs.filter(
            (obj) =>
              obj.company.companyId === e &&
              obj.company.category.toLowerCase() === 'cfn'
          )
          setFromCompanyType(6)
          setIsFundManager(false)
        } else {
          setFromCompanyType(5)
          setIsFundManager(false)
        }
      } else {
        setFromCompanyType(3)
        setIsFundManager(false)
      }
    } else {
      setIsFundManager(true)
      setFromCompanyType(2)
    }
    setCurrentCompany(selectedCompany[0])
    setInvitedCompany(null)
    setInvitedParticipants([])
  }

  const handleOnClick = () => {
    setDestCompany(company)
    setFunds(company.funds)
    setInvitedCompany(null)
    setInvitedParticipants([])
  }

  const handleFilterOption = (e) => {
    setAllocators({})
    setParticipants([])
    setSenderId(null)
    //setCheckValue(null);
    if (e.length > 2)
      http.post(`/companies/search/online/1?keyword=${e}`).then((res) => {
        setAllocators(res.data.response)
      })
  }

  const onMainSenderChange = useCallback((e) => {
    let tmpparticipants
    tmpparticipants = participants.filter((p) => p !== e)
    tmpparticipants = [...myColleagues, e]
    setParticipants(tmpparticipants)

    handleParticipantsChanged(true, tmpparticipants)
    setSenderId(e)
  }, [])

  useEffect(() => {
    if (visible && senderId != null) {
      http
        .post(
          `contextmeetings/validation/${currentCompany.company.companyId}/${senderId}`,
          {
            toCompanyId: company.companyId,
            fundId: fund.fundId,
          }
        )
        .then((response) => {
          const validationResponse = response.data
          if (validationResponse.isValid) {
            setInvalidMeetingCombination(false)
          } else {
            setInvalidMeetingCombination(true)
            message.error(validationResponse.reason)
          }
        })
      http
        .get(
          `/contextmeetings/invalid-combinations/${currentCompany.company.companyId}/${senderId}/${company.companyId}`
        )
        .then((response) => {
          setInvalidCombinations(response.data)
        })
      if (currentCompany.company.category === 'CFN')
        checkCanSendToFund(senderId)
      else setCanSendToFund(true)
    }
  }, [visible, senderId])

  useEffect(() => {
    if (visible) {
      setOtherColleagues(contacts)
    }
  }, [visible])

  useEffect(() => {
    if (currentCompany && !isEmpty(currentCompany)) {
      getColleagues(
        currentCompany.company.companyId,
        0,
        company.companyId,
        0,
        null
      )
        .then((response) => {
          setMyColleagues(
            response.data.find((x) => x.companyId !== company.companyId)
              .contacts
          )
        })
        .catch(() => message.error('Could not retrieve colleagues'))
    }
  }, [currentCompany, senderId, visible])

  return (
    <Modal
      destroyOnClose
      centered
      className="AllocatorOnlineMR"
      visible={visible}
      width="900px"
      title="Request a Meeting"
      footer={footer}
      afterClose={() => {
        setCheckedList([])
        setParticipants([])
        setSenderId(null)
        setCurrentStep(0)
        setToParticipants([])
        setParticipants([])
        setSelectedHour({})
        setCanSendToFund(false)
        onClose()
      }}
      onCancel={() => {
        setCheckedList([])
        setParticipants([])
        setSenderId(null)
        setCurrentStep(0)
        setToParticipants([])
        setParticipants([])
        setSelectedHour({})
        setCanSendToFund(false)
        onClose()
      }}
      bodyStyle={{ padding: '16px 32px' }}
    >
      <div>
        <div className="cc-tabletitle-text cc-darkest-grey-text">
          Company on behalf of:
        </div>
        <Divider style={{ margin: '16px 0px' }}></Divider>
        <Checkbox onChange={(e) => setIsOffPlatform(e.target.checked)}>
          Off Platform?
        </Checkbox>
        <Row>
          <Col span={8}>
            <div>
              <div
                style={{ marginTop: '16px' }}
                className="cc-heading4 cc-darkest-grey-text"
              >
                <Select
                  showSearch
                  className="cc-background-input"
                  size="large"
                  style={{ width: '95%', height: '48px' }}
                  placeholder="From"
                  showArrow={false}
                  optionFilterProp="children"
                  onChange={handleSelectChange}
                  onSearch={handleFilterOption}
                  filterOption={false}
                  onDropdownVisibleChange={handleOnClick}
                >
                  <OptGroup label="CFN">
                    {allocators &&
                      allocators.cfNs &&
                      allocators.cfNs.map((row) => {
                        if (row.company.category.toLowerCase() == 'cfn') {
                          return (
                            <Select.Option
                              value={row.company.companyId}
                              key={row.company.companyId}
                            >
                              {row.company.name}
                            </Select.Option>
                          )
                        }
                      })}
                  </OptGroup>
                  <OptGroup label="Allocator Companies">
                    {allocators.allocators &&
                      allocators.allocators.map((row) => {
                        if (row.company.category.toLowerCase() == 'allocator') {
                          return (
                            <Select.Option value={row.company.companyId}>
                              {row.company.name}
                            </Select.Option>
                          )
                        }
                      })}
                  </OptGroup>
                  <OptGroup label="Service Providers">
                    {allocators &&
                      allocators.serviceProviders &&
                      allocators.serviceProviders.map((row) => {
                        if (
                          row.company.category.toLowerCase() ==
                          'service provider'
                        ) {
                          return (
                            <Select.Option
                              value={row.company.companyId}
                              key={row.company.companyId}
                            >
                              {row.company.name}
                            </Select.Option>
                          )
                        }
                      })}
                  </OptGroup>
                </Select>
              </div>
              {!isNil(currentCompany.contacts) &&
                !isEmpty(currentCompany.contacts) && (
                  <>
                    <Row>
                      <Col span={24}>
                        <h3 className="heading5" style={{ marginTop: '16px' }}>
                          MAIN SENDER
                        </h3>
                        <Select
                          className="cc-background-input"
                          size="default"
                          value={senderId}
                          // defaultActiveFirstOption={true}
                          // defaultValue={currentCompany.contacts[0].contactId}
                          style={{ width: '90%', height: '35px' }}
                          placeholder="Select main sender"
                          onChange={(e) => {
                            onMainSenderChange(e)
                          }}
                        >
                          {currentCompany.contacts.map((row) => (
                            <Option key={row.contactId} value={row.contactId}>
                              {row.contactName}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {currentCompany.contacts.map((contact) => {
                          return (
                            <ParticipantsCheckbox
                              key={contact.contactId}
                              participants={participants}
                              checked={handleParticipantChecked}
                              contactId={contact.contactId}
                              contactName={`${contact.firstName} ${contact.lastName}`}
                              imageURL={contact.imageUrl}
                              position={contact.jobTitle}
                              disabled={contact.contactId === senderId}
                              //disabled={contact.contactId === userId}
                            />
                          )
                        })}{' '}
                      </Col>
                    </Row>
                  </>
                )}
              {/* <div>
                <Row>
                  <Col span={24}>
                    <span className="requestSender">SENDER</span>
                    <Radio.Group
                      onChange={onRadioChange}
                      value={checkValue}
                      style={{ marginLeft: "15px" }}
                    >
                      {currentCompany.contacts &&
                        currentCompany.contacts.map((row) => {
                          return (
                            <div>
                              <Radio value={row.contactId} />
                              <Checkbox
                                style={{ display: "inline" }}
                                className="cc-check-participants"
                                value={row.contactId}
                                onChange={handleParticipantChecked}
                                checked={
                                  checkedList.some((e) => e == row.contactId)
                                    ? true
                                    : false
                                }
                              >
                                <ContactName
                                  style={{ display: "inline" }}
                                  name={row.contactName}
                                  imageUrl={row.imageURL}
                                />
                              </Checkbox>
                            </div>
                          );
                        })}
                    </Radio.Group>
                  </Col>
                </Row>
              </div> */}
            </div>
          </Col>

          <Col span={2} style={{ marginTop: '16px' }}>
            <img src={ToArrow} />
          </Col>

          <Col span={14}>
            <div>
              <div className="cc-heading4 cc-darkest-grey-text">
                {fund.name}
              </div>
              <div className="cc-check-participants-wrapper">
                {otherColleagues &&
                  map(otherColleagues, (contact) => (
                    <ParticipantsCheckbox
                      key={contact.contactId}
                      participants={toParticipants}
                      checked={handleToParticipantChecked}
                      contactId={contact.contactId}
                      contactName={`${contact.firstName} ${contact.lastName}`}
                      imageUrl={contact.imageUrl}
                      position={contact.jobTitle}
                    />
                  ))}
              </div>
              {senderId != null && fromCompanyType === 6 && (
                <div>
                  <div
                    style={{ marginTop: '16px' }}
                    className="cc-heading5 cc-darkest-grey-text"
                  >
                    Invite Another Company
                  </div>
                  <div
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <CompanySelectContainer
                      excludedCompanies={[
                        currentCompany.company.companyId,
                        company.companyId,
                      ]}
                      setSelectedCompany={setInvitedCompany}
                      canInviteFunds={false}
                    />
                  </div>
                  {!isNil(invitedCompany) && (
                    <div>
                      <div
                        style={{ marginTop: '16px' }}
                        className="cc-heading4 cc-darkest-grey-text"
                      >
                        {invitedCompany.companyName}
                        <Button
                          className="remove-invited-button cc-text-color-danger"
                          icon={
                            <FontAwesomeIcon
                              style={{ marginRight: '8px' }}
                              icon={faTrashAlt}
                            />
                          }
                          type="link"
                          style={{ fontWeight: '600' }}
                          onClick={() => {
                            setInvitedCompany(null)
                            setInvitedParticipants([])
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                      <div>
                        <div className="cc-check-participants-wrapper">
                          {invitedCompany.contacts &&
                            map(invitedCompany.contacts, (contact) => (
                              <ParticipantsCheckbox
                                key={contact.contactId}
                                participants={invitedParticipants}
                                checked={handleInvitedParticipants}
                                contactId={contact.contactId}
                                companyContactId={contact.companyContactId}
                                contactName={contact.contactName}
                                imageUrl={contact.imageUrl}
                                position={contact.jobTitle}
                                useCompanyContactId
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
        {!isOffPlatform && (
          <div>
            {!isEmpty(toParticipants) &&
              !isEmpty(participants) &&
              fromCompanyType !== 3 && (
                <MeetingRequestProposedTimes
                  proposedTimes={proposedMeetingDateTimes}
                  handleProposedTimesChanged={handleProposedTimesChanged}
                  toCompanyId={company.companyId}
                  companyId={currentCompany.company.companyId}
                  participants={participants}
                  toParticipants={toParticipants}
                  //roadshowId={meetingRoadshowId}
                  //eventId={null}
                ></MeetingRequestProposedTimes>
              )}
          </div>
        )}
        {!isOffPlatform && (
          <div style={{ width: '100%', marginTop: '24px' }}>
            <TextArea
              placeholder="Write a message..."
              rows={4}
              value={textMessage}
              onChange={({ target: { value } }) => {
                setMessage(value)
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

DiscoverFundMeetingRequest.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  fund: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  eventId: PropTypes.number,
  onRefresh: PropTypes.func,
}

export default DiscoverFundMeetingRequest
