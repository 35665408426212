import {
  FETCH_MESSAGE_CHAT_ROOMS,
  FETCH_MESSAGE_TOKEN,
  FETCH_ONLINE_NOTIFICATION_MEETING,
} from '../constants/types'

const initialState = {
  chatAccessToken: null,
  chatRooms: [],
  upcomingOnline: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_MESSAGE_TOKEN:
      return { ...state, chatAccessToken: action.payload }
    case FETCH_MESSAGE_CHAT_ROOMS:
      return { ...state, chatRooms: action.payload }
    case FETCH_ONLINE_NOTIFICATION_MEETING:
      return { ...state, upcomingOnline: action.payload }

    default:
      return state
  }
}
