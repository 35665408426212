import {
  FETCH_SERVICE_PROVIDERS_ATTENDEE_LIST,
  FETCH_SERVICE_PROVIDER_CATEGORIES,
} from '../constants/types'
import http from '../utils/api'

export const getServiceProvidersList = (
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post('/serviceproviders', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const fetchServiceProviderAttendeeList =
  (eventId, pagination, filters, sorter, searchTerm) => (dispatch) => {
    http
      .post(`/events/${eventId}/serviceproviders`, {
        pagination,
        filters,
        sorter,
        searchTerm,
      })
      .then((response) =>
        dispatch({
          type: FETCH_SERVICE_PROVIDERS_ATTENDEE_LIST,
          payload: response.data.result,
        })
      )
  }

export const getServiceProviderAttendeeList = (
  eventId,
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post(`/events/${eventId}/serviceproviders`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getSPFundsList = (pagination, filters, sorter, searchTerm) =>
  http.post('/serviceproviders/funds', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getSPFundAttendeeList = (
  eventId,
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post(`/serviceproviders/events/${eventId}/funds`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getSPAllocatorList = (pagination, filters, sorter, searchTerm) =>
  http.post('/serviceproviders/investors', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getServiceProviderCategories = () =>
  http.get('/serviceproviders/categories')

export const fetchServiceProviderCategories = () => (dispatch) => {
  http.get('/serviceproviders/categories').then((response) => {
    dispatch({
      type: FETCH_SERVICE_PROVIDER_CATEGORIES,
      payload: response.data.result,
    })
  })
}

export const getServiceProviderProfile = (companyId) =>
  http.get(`serviceproviders/${companyId}`)

export const getDiscoverServiceProviderProfile = (companyId) =>
  http.get(`serviceproviders/profile/${companyId}`)

export const getAllServiceProviderOptions = () =>
  http.get('/serviceproviders/options')
