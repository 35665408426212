import { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  PageHeader,
  Popover,
  Radio,
  Row,
  Tooltip,
  message,
} from 'antd'
import { isNil } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import {
  getInvestorDetailsQuestions,
  getQuestions,
  postAllocatorContact, //, getInvestmentStrategies
} from '../../actions/onboarding'
import http from '../../utils/api'
import ContactHeaderBackLink from '../ContactHeaderBackLink/ContactHeaderBackLink'
import DragDropContextForm from '../DragDropContextForm'
import FloatingSelect from '../FloatingSelect'
import LoadingGif from '../LoadingGif'
import OnboardingAddressSection from '../OnboardingComponents/OnboardingAddressSection'
import OnboardingLoading from '../OnboardingComponents/OnboardingLoading'
import InvestmentSizeSection from './InvestmentSizeSection'
import InvestmentStrategies from './InvestmentStrategies'
import InvestorFloatingInput from './InvestorFloatingInput'

const { TextArea } = Input

const InvestorDetailsForm = ({
  match: {
    params: { companyId, contactId },
  },
  history: { goBack },
  //onInvestorStatusChange,
  //enableNext,
}) => {
  const [questions, setQuestions] = useState([])
  const [canPost, setCanPost] = useState(true)

  const [refresh, setRefresh] = useState(moment())
  const [currentQuestions, setcurrentQuestions] = useState([])
  const [investorQuestions, setinvestorQuestions] = useState([])

  const [step, setStep] = useState({
    innerStepTitle: 'InvestorDetails',
    title: 'Investor Preferences Questionnaire',
    component: InvestorDetailsForm,
    //renderAction: getInvestorDetailsQuestions,
    action: null,
    shouldPost: true,
    actionType: 5,
    url: '/onboard/investor-details',
  })
  const [displayErrors, setdisplayErrors] = useState(true)
  const [stepNumber, setstepNumber] = useState(1)

  useEffect(() => {
    const stepQuestions = currentQuestions.find((x) => x.step === step)
    if (!isNil(stepQuestions)) {
      setQuestions(stepQuestions.questions)
      checkAllQuestions()
    } else {
      setQuestions(null)
      getInvestorDetailsQuestions(companyId, contactId).then((response) => {
        setQuestions(response.data)
      })
    }
  }, [stepNumber])

  const renderValidationError = () => {
    return displayErrors === true ? (
      <div className="cc-onboarding-validation-error">
        This field is required
      </div>
    ) : null
  }

  const onQuestionBlur = (questionTypeId, questionId, shortName, e) => {
    if (!isNil(e) && !isNil(e.target)) e = e.target.value
    const value = e
    const currentQuestion = questions.find((x) => x.questionId === questionId)
    currentQuestion.isValid = currentQuestion.validation(value)
    currentQuestion.tooltip = null
    setRefresh(moment())
  }

  const onQuestionChange = (questionTypeId, questionId, shortName, e) => {
    if (questionTypeId === 15) console.log(e)
    const value = isNil(e.target) ? e : e.target.value
    const currentQuestion = questions.find((x) => x.questionId === questionId)
    if (
      questionTypeId === 5 ||
      questionTypeId === 6 ||
      shortName ===
        'MaximumPercentageOfAFundsAUMYouWouldBeComfortableComprising'
    ) {
      if (!numeral.validate(value) && value !== '') {
        currentQuestion.tooltip = 'Numeric values allowed only'
      } else {
        if (numeral(value).value() > 100) {
          currentQuestion.answer = '100'
        } else {
          currentQuestion.answer = numeral(value).format('0')
        }
        currentQuestion.tooltip = null
      }
    } else if (questionTypeId === 3 || questionTypeId === 14) {
      currentQuestion.answer = value.join(',')
    } else {
      currentQuestion.answer = value
      currentQuestion.tooltip = null
    }
    currentQuestion.currentlyValid = currentQuestion.validation(value)
    checkAllQuestions()
    setRefresh(moment())
  }

  const onChange = (formQuestions) => {
    setinvestorQuestions(formQuestions)
  }

  const checkAllQuestions = () => {
    if (isNil(questions)) return

    const requiredQuestions = questions.filter((x) => x.isRequired)
    const invalidQuestions = requiredQuestions.filter(
      (x) => isNil(x.currentlyValid) || x.currentlyValid === false
    )
    setCanPost(invalidQuestions.length === 0)

    //enableNext(invalidQuestions.length === 0);
    //if (!isNil(onChange))
    onChange(questions)
  }

  const getOptions = (dropdownOrCheckbox, companyContactQuestionAnswers) => {
    if (dropdownOrCheckbox === 'checkbox')
      return companyContactQuestionAnswers.map(
        (companyContactQuestionAnswer) => ({
          label: companyContactQuestionAnswer.questionAnswer,
          value: companyContactQuestionAnswer.questionAnswer,
        })
      )
    else
      return companyContactQuestionAnswers.map(
        (companyContactQuestionAnswer) => ({
          value: companyContactQuestionAnswer.questionAnswer,
          text: companyContactQuestionAnswer.questionAnswer,
        })
      )
  }

  const renderManuallyManagedControl = (shortName, question) => {
    question.validation = (x) => true
    switch (shortName) {
      case 'InvestmentSizePreferences':
        question.validation = (x) => x.isValid === true
        return (
          <InvestmentSizeSection
            onChange={onQuestionChange.bind(
              null,
              question.questionTypeId,
              question.questionId,
              question.shortName
            )}
            onBlur={onQuestionBlur.bind(
              null,
              question.questionTypeId,
              question.questionId,
              question.shortName
            )}
            value={isNil(question.answer) ? {} : question.answer}
            displayErrors={displayErrors}
          ></InvestmentSizeSection>
        )
      case 'StrategyPreferences':
        question.validation = (x) =>
          !isNil(x) && !isNil(x.strategies) && x.strategies.length > 0
        return (
          <InvestmentStrategies
            onChange={onQuestionChange.bind(
              null,
              question.questionTypeId,
              question.questionId,
              question.shortName
            )}
            value={question.answer}
            displayErrors={displayErrors}
          ></InvestmentStrategies>
        )
      case 'MaximumPercentageOfAFundsAUMYouWouldBeComfortableComprising':
        console.log(question)
        question.validation = (x) => !isNil(x) && x !== ''
        const validationMessage = 'Field is required'
        question.currentlyValid = question.validation(question.answer)
        return (
          <div style={{ textAlign: 'left', marginBottom: '24px' }}>
            <div className="cc-heading5 cc-generic-form-checkbox-header">
              {question.isRequired === true
                ? question.label + ' *'
                : question.label}
            </div>
            <Popover
              content={question.tooltip}
              visible={!isNil(question.tooltip)}
            >
              <Input
                addonAfter="%"
                value={question.answer}
                className={
                  (question.isValid !== false ? '' : 'cc-onboarding-invalid ') +
                  'cc-onboarding-percentage'
                }
                onChange={onQuestionChange.bind(
                  null,
                  question.questionTypeId,
                  question.questionId,
                  question.shortName
                )}
                onBlur={onQuestionBlur.bind(
                  null,
                  question.questionTypeId,
                  question.questionId,
                  question.shortName
                )}
              ></Input>
            </Popover>

            {question.currentlyValid === false ? renderValidationError() : null}
          </div>
        )
      case 'MandateOrder':
        question.validation = (x) => {
          const result =
            question.isRequired === false || (!isNil(x) && x.length > 0)
          return result
        }
        question.currentlyValid = question.validation(question.answer)
        return (
          <div style={{ textAlign: 'left' }}>
            <div className="cc-heading5 cc-generic-form-checkbox-header">
              Mandate Order {question.isRequired === true ? '*' : ''}
            </div>
            <div>
              Please rank in order of importance. To rank, drag the items from
              the left column to the right.
            </div>
            <DragDropContextForm
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeId,
                question.questionId,
                question.shortName
              )}
              value={question.answer}
              options={getOptions('ranking', question.questionAnswers)}
            />

            {question.currentlyValid === false ? renderValidationError() : null}
          </div>
        )
      case 'AdditionalInvestmentPreferences':
        question.validation = (x) =>
          question.isRequired === false || (!isNil(x) && x !== '')
        question.currentlyValid = question.validation(question.answer)
        return (
          <div style={{ textAlign: 'left' }}>
            <div className="cc-heading5 cc-generic-form-checkbox-header">
              Is there anything else you would like to tell us about your
              investment preferences? (This will help us better match you with
              funds) {question.isRequired === true ? '*' : ''}
            </div>
            <TextArea
              placeholder="Type here..."
              className="cc-onboarding-anything-else-textarea"
              value={question.answer}
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeId,
                question.questionId,
                question.shortName
              )}
            ></TextArea>
          </div>
        )

      default:
        question.currentlyValid = true
        question.validation = (x) => true
        return null
    }
  }

  const renderControl = (question) => {
    let input
    const commonFloatInput = (
      <InvestorFloatingInput
        tooltip={question.tooltip}
        onChange={onQuestionChange.bind(
          null,
          question.questionTypeId,
          question.questionId,
          question.shortName
        )}
        onBlur={onQuestionBlur.bind(
          null,
          question.questionTypeId,
          question.questionId,
          question.shortName
        )}
        value={question.answer}
        validationMessage={
          isNil(question.validationMessage)
            ? 'This field is required'
            : question.validationMessage
        }
        isValid={question.isValid === true || isNil(question.isValid)}
        name={
          question.isRequired === true ? question.label + ' *' : question.label
        }
      ></InvestorFloatingInput>
    )
    switch (question.questionTypeId) {
      case 1:
      case 2:
        input = commonFloatInput
        question.validation = (x) =>
          question.isRequired === false || (!isNil(x) && x !== '')
        break
      case 3:
        input = (
          <div className="cc-generic-form-checkbox-group">
            <span className="cc-heading5 cc-generic-form-checkbox-header">
              {question.isRequired === true
                ? question.label + ' *'
                : question.label}
            </span>
            <Checkbox.Group
              value={
                isNil(question.answer)
                  ? question.answer
                  : question.answer.split(',')
              }
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeId,
                question.questionId,
                question.shortName
              )}
              options={question.questionAnswers.map((x) => ({
                label: x.questionAnswer,
                value: x.questionAnswer,
              }))}
            ></Checkbox.Group>
            {question.currentlyValid === false ? renderValidationError() : null}
          </div>
        )
        question.validation = (x) => {
          const result =
            question.isRequired === false || (!isNil(x) && x.length > 0)
          return result
        }
        break
      case 4:
        input = (
          <FloatingSelect
            name={
              question.isRequired === true
                ? question.label + ' *'
                : question.label
            }
            value={question.answer}
            validationMessage={
              isNil(question.validationMessage)
                ? 'This field is required'
                : question.validationMessage
            }
            isValid={question.isValid === true || isNil(question.isValid)}
            onChange={onQuestionChange.bind(
              null,
              question.questionTypeId,
              question.questionId,
              question.shortName
            )}
            onBlur={onQuestionBlur.bind(
              null,
              question.questionTypeId,
              question.questionId,
              question.shortName
            )}
            options={question.questionAnswers.map((x) => ({
              value: x.questionAnswer,
              text: x.questionAnswer,
            }))}
          ></FloatingSelect>
        )
        question.validation = (x) =>
          question.isRequired === false ||
          (!isNil(x) &&
            question.questionAnswers.filter((a) => x === a.questionAnswer)
              .length > 0)
        break
      case 5:
      case 6:
        input = (
          <InvestorFloatingInput
            tooltip={question.tooltip}
            onChange={onQuestionChange.bind(
              null,
              question.questionTypeId,
              question.questionId,
              question.shortName
            )}
            onBlur={onQuestionBlur.bind(
              null,
              question.questionTypeId,
              question.questionId,
              question.shortName
            )}
            value={question.answer}
            validationMessage={
              isNil(question.validationMessage)
                ? 'This field is required'
                : question.validationMessage
            }
            isValid={question.isValid === true || isNil(question.isValid)}
            name={
              question.isRequired === true
                ? question.label + ' *'
                : question.label
            }
          ></InvestorFloatingInput>
        )
        question.validationMessage =
          'This field is required and should have a numeric value'
        question.validation = (x) => numeral.validate(x)
        break
      case 7:
        input = (
          <div className="cc-generic-form-radio-group">
            <span className="cc-heading5 cc-generic-form-radio-header">
              {question.isRequired === true
                ? question.label + ' *'
                : question.label}
            </span>
            {question.shortName === 'FirstLossCapital' ? (
              <div>Do you only provide first loss capital?</div>
            ) : null}
            <Radio.Group
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeId,
                question.questionId,
                question.shortName
              )}
              value={question.answer}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>

            {question.currentlyValid === false ? renderValidationError() : null}
          </div>
        )
        question.validation = (x) => {
          const result =
            question.isRequired === false || (!isNil(x) && x.length > 0)
          return result
        }
        break
      case 12:
        input = (
          <div className="cc-generic-form-radio-group">
            <span className="cc-heading6 cc-generic-form-radio-header">
              {question.isRequired === true
                ? question.label + ' *'
                : question.label}
            </span>

            <Radio.Group
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeId,
                question.questionId,
                question.shortName
              )}
              value={question.answer}
            >
              {question.questionAnswers.map((x) => (
                <Radio value={x.questionAnswer}>{x.questionAnswer}</Radio>
              ))}
            </Radio.Group>
          </div>
        )
        question.validation = (x) => {
          const result =
            question.isRequired === false || (!isNil(x) && x.length > 0)
          return result
        }
        break
      case 13:
        input = (
          <OnboardingAddressSection
            validationMessage="This field is required"
            onChange={onQuestionChange.bind(
              null,
              question.questionTypeId,
              question.questionId,
              question.shortName
            )}
            value={isNil(question.answer) ? {} : question.answer}
          ></OnboardingAddressSection>
        )
        question.validationMessage = 'This field is required'
        question.currentlyValid = true
        question.validation = (x) => x.answer && x.answer.isValid === true
        break
      case 14:
        input = (
          <div className="cc-generic-form-checkbox-group-grid">
            <span className="cc-heading5 cc-generic-form-checkbox-header">
              {question.isRequired === true
                ? question.label + ' *'
                : question.label}
            </span>
            <Checkbox.Group
              value={
                isNil(question.answer)
                  ? question.answer
                  : question.answer.split(',')
              }
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeId,
                question.questionId,
                question.shortName
              )}
              className="cc-body-text"
              options={question.questionAnswers.map((x) => ({
                label: x.questionAnswer,
                value: x.questionAnswer,
              }))}
            ></Checkbox.Group>

            {question.currentlyValid === false ? renderValidationError() : null}
          </div>
        )
        question.validation = (x) => {
          const result =
            question.isRequired === false || (!isNil(x) && x.length > 0)
          return result
        }
        break
      case 15:
        return renderManuallyManagedControl(question.shortName, question)
      default:
        input = commonFloatInput
        question.validation = (x) => true
        break
    }

    question.currentlyValid = question.validation(question.answer)

    return (
      <>
        <div
          className="cc-generic-form-control-container"
          style={{ width: '100%' }}
        >
          {input}
        </div>
        <div className="cc-investor-details-form-separator"></div>
      </>
    )
  }

  const clearQuestions = () => {
    setQuestions([])
  }

  const postContactAllocator = () => {
    checkAllQuestions()
    postAllocatorContact(companyId, contactId, investorQuestions).then(
      (response) => {
        //clearQuestions();
        message.success('Contact allocator information saved successfully')
        //setLoading(false);
      }
    )
  }

  useEffect(() => {
    if (displayErrors === true) {
      checkAllQuestions(true)

      questions.forEach((q) => {
        q.isValid = !isNil(q.validation) && q.validation(q.answer)
      })
      setRefresh(moment())
    }
  }, [displayErrors])

  return isNil(questions) ? (
    <div
      style={{ textAlign: 'center', height: '100vh', verticalAlign: 'center' }}
    >
      <LoadingGif style={{ position: 'absolute', top: '50%' }}></LoadingGif>
    </div>
  ) : (
    <>
      <ContactHeaderBackLink contactId={contactId}></ContactHeaderBackLink>

      <Row style={{ marginTop: '20px', backgroundColor: 'white' }}>
        <Row>
          <Col span={24}>
            <h2
              style={{
                marginBottom: '24px',
                marginTop: '24px',
                fontWeight: '600',
                marginLeft: '24px',
                fontSize: '18px',
                lineHeight: '22px',
              }}
            >
              Investor Details
            </h2>
          </Col>
          <Divider />
        </Row>

        <div className="cc-layout-default">
          <div
            className="cc-layout-default-form-space"
            style={{
              width: '556px;',
            }}
          >
            <div className="cc-generic-form-container">
              {questions.map((x) => renderControl(x))}
            </div>
          </div>
          <div
            className="form-section-submit"
            style={{
              paddingBottom: '40px',
            }}
          >
            <div className="empty-column-left" />
            <div className="form-section-submit-buttons">
              <Button
                type="default"
                className="form-section-btn-cancel"
                onClick={goBack}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="form-section-btn-save"
                onClick={postContactAllocator}
                disabled={!canPost}
              >
                Save
              </Button>
            </div>
            <div className="empty-column-right" />
          </div>
        </div>
      </Row>
    </>
  )
}

export default InvestorDetailsForm
