import { useState } from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import { Button, IconButton } from '@context365/button'
import { Input, Select } from '@context365/forms'
import {
  Add,
  CloudUploadOutlined,
  DeleteOutline,
  Edit,
} from '@context365/icons'
import { Divider, Upload } from 'antd'
import produce from 'immer'
import { join, last, split } from 'lodash'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useSelector } from 'react-redux'
import { BASE_URL } from '../../../config'
import { getFileExtension } from '../../../utils/helpers'
import ArrayItemHeading from './ArrayItemHeading'

const generateEmptyDocument = () => ({
  documentCategoryId: null,
  title: null,
  url: null,
  isEdit: true,
})

const { Dragger } = Upload

const AcceptedExtensions = [
  '.pdf',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
]

const isCorrectFileExtension = (file) => {
  const extension = getFileExtension(file.name)
  return AcceptedExtensions.includes(`.${extension.toLowerCase()}`)
}

const EditDocument = ({
  heading,
  title,
  url,
  documentCategoryId,
  documentCategories,
  onFieldChange,
  onSave,
  onRemove,
}) => {
  const accessToken = useSelector((state) => state.auth.accessToken)
  const [file, setFile] = useState(
    url
      ? {
          url,
          name: last(split(decodeURI(url), '/')),
          status: 'done',
          uid: 1,
        }
      : null
  )

  const handleUpload = (info) => {
    const { status, response } = info.file

    if (status === 'removed') {
      setFile(null)
      onFieldChange('url', null)
      return
    }

    const isIncludedInAccept = isCorrectFileExtension(info.file)

    if (!isIncludedInAccept) {
      info.file.status = 'error'
      info.file.response = 'Incorrect file type'
      return
    }

    setFile(info.file)
    if (status === 'done' && !isNil(response)) {
      const { url } = response
      onFieldChange('url', encodeURI(url))
    }
  }

  return (
    <>
      <ArrayItemHeading heading={heading} onRemove={onRemove} />
      <div className="grid grid-cols-2 gap-6 py-4">
        <Input
          label="Document title"
          value={title}
          onChange={(e) => onFieldChange('title', e.target.value)}
        />
        <Select
          label="Document category"
          clearable
          value={documentCategoryId}
          onChange={(value) => onFieldChange('documentCategoryId', value)}
        >
          {map(documentCategories, (dc) => (
            <Select.Option key={dc.id} value={dc.id}>
              {dc.name}
            </Select.Option>
          ))}
        </Select>
        <Dragger
          className="col-span-2"
          accept={join(AcceptedExtensions, ',')}
          action={`${BASE_URL}uploads`}
          headers={{ Authorization: `Bearer ${accessToken}` }}
          onChange={handleUpload}
          fileList={file ? [{ ...file }] : []}
        >
          <CloudUploadOutlined size="28px" className="text-grey-400" />
          <div className="type-header-normal-extra-small text-primary-5">
            Drag or click here to upload a document
          </div>
          <div className="type-header-normal-extra-small text-primary-5">
            (PDF, Word, PPT, XLS / Max file size 5mb)
          </div>
        </Dragger>
        <div className="col-span-2 mt-4">
          <Button variant="filled" onClick={() => onSave()}>
            Close Document Editor
          </Button>
        </div>
      </div>
    </>
  )
}

const ViewDocument = ({ title, category, onRemove, onEdit }) => (
  <div className="space-x-4">
    <IconButton
      icon={<DeleteOutline className="text-red-110" />}
      label="delete"
      variant="link"
      onClick={() => onRemove()}
    />
    <IconButton
      icon={<Edit className="text-primary-110" />}
      label="edit"
      variant="link"
      onClick={() => onEdit()}
    />
    <span>{title ?? 'No title'}</span>
    {category && <Badge>{category}</Badge>}
  </div>
)

const Documents = ({
  documents,
  updateDocuments,
  documentCategories,
  showEmptyEdit = true,
}) => {
  const handleAddDocument = () => {
    const newDocuments = produce(documents, (newState) => {
      if (newState.length === 0 && showEmptyEdit) {
        newState.push(generateEmptyDocument())
      }
      newState.push(generateEmptyDocument())
    })
    updateDocuments(newDocuments)
  }

  const handleRemoveDocument = (index) => {
    const newDocuments = produce(documents, (newState) => {
      newState.splice(index, 1)
    })
    updateDocuments(newDocuments)
  }

  const handleEditDocument = (index, key, value) => {
    const newDocuments = produce(documents, (newState) => {
      const currentItem = newState[index]
      if (currentItem) {
        currentItem[key] = value
      } else {
        newState.push({ ...generateEmptyDocument(), [key]: value })
      }
    })
    updateDocuments(newDocuments)
  }

  const hasNoItems = documents.length === 0

  const documentIteratees =
    hasNoItems && showEmptyEdit ? [generateEmptyDocument()] : documents

  return (
    <div>
      {map(documentIteratees, (document, index) => {
        const category = find(
          documentCategories,
          (dc) => dc.id === document.documentCategoryId
        )

        return (
          <div className="py-2">
            {document.isEdit ? (
              <EditDocument
                {...document}
                heading={`Document ${index + 1}`}
                documentCategories={documentCategories}
                onFieldChange={(key, value) =>
                  handleEditDocument(index, key, value)
                }
                onSave={() => handleEditDocument(index, 'isEdit', false)}
                onRemove={hasNoItems ? null : () => handleRemoveDocument(index)}
              />
            ) : (
              <ViewDocument
                title={document.title}
                category={category?.name}
                onEdit={() => handleEditDocument(index, 'isEdit', true)}
                onRemove={() => handleRemoveDocument(index)}
              />
            )}
          </div>
        )
      })}
      <Divider className="col-span-2" style={{ marginTop: 40 }} />
      <Button variant="link" size="small" onClick={() => handleAddDocument()}>
        <Add size="small" /> Add Document
      </Button>
    </div>
  )
}

Documents.propTypes = {
  documents: PropTypes.array.isRequired,
  updateDocuments: PropTypes.func.isRequired,
  documentCategories: PropTypes.array.isRequired,
}

export default Documents
