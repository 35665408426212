import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row, Tooltip, Typography } from 'antd'
import { Formik } from 'formik'
import { findIndex, fromPairs, isNil, join, map, pick, values } from 'lodash'
import { getInitialValues } from '../DynamicForm/DynamicForm'
import FormField from '../Forms/Fields/FormField'
import StepList from './StepList'
import './ServiceProviderWizard.less'

const { Title } = Typography

const fieldDependencies = {}

const getSteps = (titles, indexes) =>
  values(pick(fromPairs(map(titles, (title, ix) => [ix, title])), indexes))

const STEPS = {
  TITLES: [
    'Edit Profile',
    'Media',
    'Logo',
    'People',
    'Testimonials',
    'Clients',
    'Content',
    'Contact',
  ],
  SERVICEPROVIDER: [1, 2, 3, 4, 5, 6, 7],
}

const ICONS = {
  facebook: faFacebookF,
  instagram: faInstagram,
  linkedin: faLinkedinIn,
  twitter: faTwitter,
}

const ServiceProviderStep = ({
  title,
  className = null,
  questions,
  answers,
  validationSchema,
  questionsWithOptions,
  isLast = false,
  loading = false,
  onNavigation,
  onPreview,
  onStepChange,
  currentStep,
  stepsInError,
}) => {
  const initialValues = getInitialValues(
    questions,
    answers,
    questionsWithOptions
  )
  const classNames = ['ServiceProviderWizard-Step']
  if (!isNil(className)) {
    classNames.push(className)
  }
  const finalClassName = join(classNames, ' ')

  const [saveChanges, setSaveChanges] = useState(false)
  const [publish, setPublish] = useState(false)
  const [isValid, setIsValid] = useState(true)

  const steps = getSteps(STEPS.TITLES, STEPS.SERVICEPROVIDER)

  const current = findIndex(STEPS.SERVICEPROVIDER, (i) => i === currentStep)

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onNavigation(questions, values, true, saveChanges, publish, isValid)
        }}
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          setErrors,
        }) => (
          <>
            <Row className="ServiceProviderWizard-row">
              <Col className="ServiceProviderWizard-steps" span={8}>
                {' '}
                <StepList
                  className="FundWizard-steps"
                  steps={steps}
                  current={current}
                  onStepChange={(step) => {
                    if (dirty && !isValid) {
                      return
                    } else {
                      onNavigation(questions, values, false, false)
                      onStepChange(step)
                    }
                  }}
                  values={values}
                  stepsInError={stepsInError}
                />
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className="ServiceProviderStepPreviewButton"
                    ghost
                    htmlType="submit"
                    type="primary"
                    size="large"
                    onClick={() => {
                      onNavigation(questions, values, false, false)
                      setSaveChanges(false)
                      setPublish(false)
                      onPreview()
                    }}
                    style={{
                      display: isLast || 'none',
                    }}
                    name="preview"
                    value="preview"
                    loading={loading}
                  >
                    Preview Changes
                  </Button>
                </div>
              </Col>
              <Col span={16} className="ServiceProviderWizard-form">
                {' '}
                <form
                  onSubmit={handleSubmit}
                  className={finalClassName}
                  autoComplete="off"
                >
                  <div>
                    {!isNil(title) && (
                      <Title
                        level={3}
                        className="ServiceProviderWizard-step-title"
                      >
                        {title}
                      </Title>
                    )}
                    {map(questions, (question) => (
                      <div className="ServiceProviderStep-container">
                        {question.attributes?.preIcon && (
                          <div className="ServiceProviderStep-pre-icon">
                            <FontAwesomeIcon
                              icon={ICONS[question.attributes?.preIcon]}
                            ></FontAwesomeIcon>
                          </div>
                        )}

                        <div
                          className={`${
                            question.attributes?.infoIcon &&
                            'ServiceProviderStep-reduced'
                          } ${
                            question.attributes?.preIcon &&
                            'ServiceProviderStep-pre-reduced'
                          }`}
                        >
                          <FormField
                            key={question.serviceProviderQuestionId}
                            question={question}
                            values={values}
                            errors={errors}
                            dependencies={fieldDependencies}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                          />
                        </div>
                        {question.attributes?.infoIcon && (
                          <div className="ServiceProviderStep-info">
                            <Tooltip title={question.attributes?.infoIcon}>
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                              ></FontAwesomeIcon>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="ServiceProviderWizard-actions">
                    {current >= 1 && (
                      <div
                        className="ServiceProviderStepButton-back"
                        onClick={() => {
                          setErrors({})
                          onNavigation(questions, values, false, false, false)
                        }}
                      >
                        <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>{' '}
                        Back
                      </div>
                    )}
                    <div>
                      <Button
                        className="ServiceProviderStepButton ServiceProviderStepButton-save"
                        htmlType="submit"
                        type="primary"
                        size="large"
                        ghost
                        name="save"
                        value="save"
                        disabled={!isValid}
                        loading={loading}
                        onClick={() => {
                          setSaveChanges(true)
                          setPublish(false)
                        }}
                      >
                        {'Save Draft'}
                      </Button>
                      <Button
                        className="ServiceProviderStepPublishButton"
                        ghost
                        htmlType="submit"
                        type="primary"
                        size="large"
                        onClick={() => {
                          setSaveChanges(false)
                          setPublish(true)
                          setIsValid(isValid)
                        }}
                        style={{
                          display: isLast || 'none',
                        }}
                        name="preview"
                        value="preview"
                        loading={loading}
                      >
                        Publish
                      </Button>
                    </div>{' '}
                    {!isLast && (
                      <Button
                        className="ServiceProviderStepButton"
                        htmlType="submit"
                        type="primary"
                        size="large"
                        name="nav"
                        value="next"
                        disabled={!isValid}
                        loading={loading}
                        onClick={() => {
                          setSaveChanges(false)
                          setPublish(false)
                        }}
                      >
                        {'Next'}
                      </Button>
                    )}
                  </div>
                </form>
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </>
  )
}

ServiceProviderStep.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  questions: PropTypes.array,
  answers: PropTypes.array,
  validationSchema: PropTypes.object,
  questionsWithOptions: PropTypes.any.isRequired,
  isLast: PropTypes.bool,
  loading: PropTypes.bool,
  onNavigation: PropTypes.func,
  onPreview: PropTypes.func,
  onStepChange: PropTypes.func,
  currentStep: PropTypes.number.isRequired,
  stepsInError: PropTypes.array,
}

export default ServiceProviderStep
