import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Radio, Select, message } from 'antd'
import filter from 'lodash/filter'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import throttle from 'lodash/throttle'
import trim from 'lodash/trim'
import qs from 'qs'
import http from '../../utils/api'
import LoadingGif from '../LoadingGif'
import ContactsText from './ContactsText'
import './CompanySelectContainer.less'

const { Option } = Select
const RadioGroup = Radio.Group
const RadioButton = Radio.Button

const CompanySelectContainer = ({
  setSelectedCompany,
  excludedCompanies = [],
  isPopover = false,
  canInviteFunds = true,
}) => {
  const [data, setData] = useState([])
  const [value, setValue] = useState([])
  const [fetching, setFetching] = useState(false)
  const [category, setCategory] = useState('CFN')

  const fetchCompanies = useCallback(
    (searchTerm) => {
      const params = {
        page: 1,
        pageSize: 10,
      }

      let url
      switch (category) {
        case 'CFN':
          url = 'companies/family-network'
          break
        case 'allocators':
          url = '/companies'
          params['categoryId'] = 2
          break
        case 'serviceProviders':
          url = '/companies'
          params['categoryId'] = 3
          break
        case 'funds':
          url = '/funds'
          break
        default:
          url = 'companies/family-network'
          break
      }

      if (!isNil(searchTerm) && !isEmpty(searchTerm)) {
        params['q'] = trim(searchTerm)
      }

      setFetching(true)
      http
        .get(url, {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: 'repeat' }),
        })
        .then((response) => {
          setData(
            filter(
              response.data.results,
              (f) => !includes(excludedCompanies, f.companyId)
            )
          )
        })
        .catch((error) => {
          const msg = isNil(error.response)
            ? get(error, 'message', 'Could not retrieve list.')
            : get(error, 'response.data.message', 'Could not retrieve list.')
          message.error(msg)
        })
        .finally(() => setFetching(false))
    },
    [category, excludedCompanies]
  )

  useEffect(() => {
    fetchCompanies()
  }, [fetchCompanies, category])

  const handleChange = useCallback(
    (value) => {
      setValue(value)
      setFetching(false)
      if (category === 'funds') {
        const company = data.find((d) => d.fundId === value?.key)
        setSelectedCompany(company)
      } else {
        const company = data.find((d) => d.companyId === value?.key)
        setSelectedCompany(company)
      }
    },
    [category, data, setSelectedCompany]
  )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div
        style={{
          marginBottom: '8px',
        }}
      >
        <RadioGroup
          value={category}
          onChange={(e) => {
            setCategory(e.target.value)
          }}
        >
          <RadioButton value="CFN">Family Offices</RadioButton>
          <RadioButton value="allocators">Allocator</RadioButton>
          {canInviteFunds && <RadioButton value="funds">Funds</RadioButton>}
          <RadioButton value="serviceProviders">Service Providers</RadioButton>
        </RadioGroup>
      </div>
      <Select
        size="large"
        dropdownClassName={isPopover && 'company-dropdown'}
        className="select-container"
        allowClear
        autoFocus
        labelInValue
        value={value}
        placeholder="Search company name or person name"
        notFoundContent={
          fetching ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <LoadingGif spinning={fetching} />
            </div>
          ) : null
        }
        filterOption={false}
        onSearch={throttle(fetchCompanies, 1000)}
        showSearch
        onChange={handleChange}
        style={{ width: '100%' }}
        getPopupContainer={
          isPopover && (() => document.getElementById('popover'))
        }
      >
        {map(data, (d) => (
          <Option
            value={
              d.category === 'Manager' && !isNil(d.fundId)
                ? d.fundId
                : d.companyId
            }
          >
            <div className="select-company">
              <div className="select-company-text">
                <div>{d.name || d.companyName}</div>
                <div className="select-company-text-contacts cc-small-font">
                  <ContactsText
                    contacts={d.contacts}
                    length={(isPopover && 42) || 80}
                    contactsNumber={(isPopover && 3) || 5}
                  />
                </div>
              </div>
            </div>
          </Option>
        ))}
      </Select>
    </div>
  )
}

CompanySelectContainer.propTypes = {
  setSelectedCompany: PropTypes.func,
  excludedCompanies: PropTypes.array,
  isPopover: PropTypes.bool,
  canInviteFunds: PropTypes.bool,
}
export default CompanySelectContainer
