import {
  LOAD_FAVORITE_FUNDS_FAILURE,
  LOAD_FAVORITE_FUNDS_REQUEST,
  LOAD_FAVORITE_FUNDS_SUCCESS,
} from '../constants/types/favorites'

const initialState = {
  fundsById: {},
  fundsIds: [],
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FAVORITE_FUNDS_REQUEST:
      return { ...state, loading: true }
    case LOAD_FAVORITE_FUNDS_SUCCESS:
      return { ...state, loading: false, ...action.payload }
    case LOAD_FAVORITE_FUNDS_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}
