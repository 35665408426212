import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faArrowLeft, faFileExport } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row, Spin, Tabs } from 'antd'
import { CSVDownload, CSVLink } from 'react-csv'
import { Link } from 'react-router-dom'
import http from '../../utils/api'
import ExportLogs from '../ExportLogs/ExportLogs'
import MutualInterest from '../MutualInterest/MutualInterest'
import SignInButton from '../SignInButton/SignInButton'
import TopMatches from '../TopMatches/TopMatches'
import UserActivity from '../UserActivity/UserActivity'
import UserInfo from './UserInfo'
import './UserDetails.less'

const { TabPane } = Tabs

const UserDetails = ({ contactId, selectedUser, tab, back }) => {
  const [loading, setLoading] = useState(false)
  const [log, setLog] = useState([])
  const [exportModalVisible, setExportModalVisible] = useState(false)
  const handleExportClick = () => {
    setExportModalVisible(true)
  }

  return (
    <>
      <div className="UserDetails" style={{ padding: '10px' }}>
        <div className="UserDetails-wrapper">
          <Row type="flex" justify="space-between">
            <Col span={12} style={{ display: 'flex' }}>
              <Button
                href={'/users'}
                style={{ marginRight: '10px', padding: '0px 10px' }}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
              <h1
                className="cc-section-header-title"
                style={{ lineHeight: '30px' }}
              >
                {selectedUser && (
                  <span style={{ lineHeight: '30px' }}>
                    {`${selectedUser.firstName} ${selectedUser.lastName}`}
                  </span>
                )}
                <SignInButton
                  contactId={contactId}
                  style={{ marginLeft: '10px', padding: '0px 10px' }}
                />
              </h1>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button type="primary" onClick={handleExportClick}>
                <FontAwesomeIcon icon={faFileExport} /> Export Activity Log
              </Button>
            </Col>
          </Row>
        </div>
        <Tabs
          defaultActiveKey={tab || '1'}
          size="large"
          className="UserDetails-tabs"
        >
          <TabPane
            className="UserDetails-tabs-tab"
            tab={<span>Details</span>}
            key="1"
          >
            <UserInfo contactId={contactId} selectedUser={selectedUser} />
          </TabPane>
          <TabPane
            className="UserDetails-tabs-tab"
            tab={<span>Activity</span>}
            key="2"
          >
            {selectedUser && (
              <UserActivity
                contactId={contactId}
                companies={selectedUser.companies}
              />
            )}
          </TabPane>
          <TabPane
            className="UserDetails-tabs-tab"
            tab={<span>Top Matches</span>}
            key="3"
          >
            {selectedUser && <TopMatches companies={selectedUser.companies} />}
          </TabPane>
          <TabPane
            className="UserDetails-tabs-tab"
            tab={<span>Mutual Interest</span>}
            key="4"
          >
            {selectedUser && (
              <MutualInterest companies={selectedUser.companies} />
            )}
          </TabPane>
        </Tabs>
        <ExportLogs
          visible={exportModalVisible}
          handleCancel={() => setExportModalVisible(false)}
          logType="user"
          recordId={contactId}
        />
      </div>
    </>
  )
}

UserDetails.propTypes = {}

export default UserDetails
