import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Divider, Row } from 'antd'
import { isEmpty, isNil } from 'lodash'
import './DiscoverServiceProviderProfile.less'

const ServiceProviderProfile = ({ company }) => {
  const [serviceProviderInfo, setServiceProviderInfo] = useState({})

  useEffect(() => {
    setServiceProviderInfo(company)
  }, [company])

  return (
    <div>
      {!isEmpty(serviceProviderInfo) && (
        <div>
          <Row style={{ marginTop: '20px' }}>
            <Col span={15}>
              <div>
                <span className="cc-grey-text cc-tabletitle-text">
                  Company Description
                </span>
                <div className="profile-info">
                  {serviceProviderInfo.description}
                </div>
              </div>
              {((!isNil(serviceProviderInfo.videoUrl) &&
                !isEmpty(serviceProviderInfo.videoUrl)) ||
                (!isEmpty(serviceProviderInfo.images) &&
                  serviceProviderInfo.images)) && (
                <span
                  className="cc-grey-text cc-tabletitle-text"
                  style={{ paddingTop: '15px', display: 'block' }}
                >
                  Media
                </span>
              )}
              <Row className="media-row">
                {!isNil(serviceProviderInfo.videoUrl) &&
                  !isEmpty(serviceProviderInfo.videoUrl) && (
                    <Col
                      span={
                        (!isEmpty(serviceProviderInfo.images) &&
                          serviceProviderInfo.images &&
                          12) ||
                        24
                      }
                    >
                      {' '}
                      <>
                        <div className="video-column">
                          <video
                            autoPlay={false}
                            width={'100%'}
                            muted={false}
                            style={{
                              borderRadius: '8px',
                              boxShadow:
                                '3px 3px 10px rgba(186, 186, 201, 0.15)',
                            }}
                            controls
                          >
                            <source src={serviceProviderInfo.videoUrl} />
                          </video>
                        </div>
                      </>
                    </Col>
                  )}
                {!isEmpty(serviceProviderInfo.images) &&
                  serviceProviderInfo.images && (
                    <Col
                      span={
                        (!isNil(serviceProviderInfo.videoUrl) &&
                          !isEmpty(serviceProviderInfo.videoUrl) &&
                          12) ||
                        24
                      }
                    >
                      <>
                        <Row
                          style={{
                            height: '100%',
                          }}
                        >
                          {serviceProviderInfo.images.map((image, id) => (
                            <Col
                              key={id}
                              className="service-image-col"
                              span={
                                ((serviceProviderInfo.images.length === 1 ||
                                  (id === 2 &&
                                    serviceProviderInfo.images.length === 3)) &&
                                  24) ||
                                12
                              }
                            >
                              <img src={image} className="service-image" />
                            </Col>
                          ))}
                        </Row>
                      </>
                    </Col>
                  )}
              </Row>
            </Col>
            <Col span={2}>
              <Divider type="vertical" style={{ height: '100%' }} />
            </Col>
            <Col span={7}>
              {serviceProviderInfo.serviceProviderCategory && (
                <div>
                  <span className="cc-grey-text cc-tabletitle-text">
                    Category
                  </span>
                  <div className="ServiceProviderContent-link">
                    {' '}
                    {serviceProviderInfo.serviceProviderCategory}
                  </div>
                </div>
              )}
              {serviceProviderInfo.website && (
                <div>
                  <span className="cc-grey-text cc-tabletitle-text">
                    Website
                  </span>
                  <div className="ServiceProviderContent-link">
                    {' '}
                    <a
                      href={serviceProviderInfo.website}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {serviceProviderInfo.website}
                    </a>
                  </div>
                </div>
              )}

              {serviceProviderInfo.imageUrl && (
                <div>
                  <span className="cc-grey-text cc-tabletitle-text">Logo</span>
                  <div className="profile-info">
                    <img width={200} src={serviceProviderInfo.imageUrl} />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}
ServiceProviderProfile.propTypes = {
  company: PropTypes.shape({
    companyDescription: PropTypes.string.isRequired,
    companyServiceProvider: PropTypes.shape({
      serviceProviderCategory: PropTypes.string.isRequired,
    }).isRequired,
    companyWebsite: PropTypes.string.isRequired,
  }),
  companyId: PropTypes.number,
}
export default ServiceProviderProfile
