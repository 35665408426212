import {
  ADD_SIGN_IN_AS,
  REMOVE_SIGN_IN_AS,
  SIGNIN_USER,
  SIGNOUT_USER,
} from '../constants/types/auth'

const initialState = {
  accessToken: null,
  refreshToken: null,
  userId: 0,
  openSignInAs: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGNIN_USER:
      return action.payload
    case SIGNOUT_USER:
      return action.payload
    case ADD_SIGN_IN_AS:
      return { ...state, openSignInAs: [...state.openSignInAs, action.payload] }
    case REMOVE_SIGN_IN_AS:
      return { ...state, openSignInAs: [] }
    default:
      return state
  }
}
