import { Button, Popover, Tag } from 'antd'
import './TagGroup.css'

const TagGroup = (props) => {
  let clipButton = null

  let maxLen = props.items.length
  if (typeof props.maxLength !== 'undefined') maxLen = props.maxLength

  if (props.items.length > maxLen) {
    const content = props.items.map((x) => (
      <Tag key={x} className="category-tag" color={props.color}>
        {x}
      </Tag>
    ))

    clipButton = (
      <Popover content={content} title="Categories">
        <Button className="tag-clip-button" type="primary">
          <span>•••</span>
        </Button>
      </Popover>
    )
  }

  return (
    <span>
      {props.items.slice(0, maxLen).map((item) => (
        <Tag key={item} className="category-tag" color={props.color}>
          {item}
        </Tag>
      ))}
      {clipButton}
    </span>
  )
}

export default TagGroup
