import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Dropdown, Icon, Menu, Tooltip } from 'antd'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { connect } from 'react-redux'
import { logout } from '../../actions/auth'
import {
  getCurrentUser,
  getUserFullname,
  getUserInitials,
  getUserRole,
} from '../../selectors/auth'
import { nameToColor } from '../../utils/columns'
import './CompanySwitchList.less'

const CompanySwitchList = (props) => {
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <div onClick={props.logout} role="button">
          Logout
        </div>
      </Menu.Item>
    </Menu>
  )
  let indexCtr = 0
  let diameter = '24px'
  return (
    <div className="CompanySwitchList">
      <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
        <span className="CompanySwitchList-trigger">
          <span className="CompanySwitchList-trigger-label">
            <Tooltip title={props.userFullname} key={props.userFullname}>
              <Avatar
                src={props.imageURL}
                style={{
                  position: 'relative',
                  zIndex: 150 + indexCtr++,
                  display: 'inline-block',
                  backgroundColor: nameToColor(props.userInitials),
                  fontSize: '14px',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                }}
                shape="circle"
                size={diameter}
                //icon="user"
                className="profile-thumbnail"
              >
                {props.userInitials}
              </Avatar>
            </Tooltip>{' '}
            {props.userFullname} <Icon type="down" />
          </span>
        </span>
      </Dropdown>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  userInitials: getUserInitials(state),
  userFullname: getUserFullname(state),
  role: getUserRole(state),
  pathname: state.router.location.pathname,
  pathkey: state.router.location.key,
})

const mapDispatchToProps = {
  logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySwitchList)
