import { Component } from 'react'
import { Select } from 'antd'
import './FloatingMultiSelect.less'

const Option = Select

class SelectInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: -1,
      focused: false,
    }
  }

  valueChanged = (e) => {
    this.setState({
      value: e,
      focused: false,
    })

    if (typeof this.props.onChange === 'function')
      this.props.onChange(e, this.props.id)
  }

  focused = () => {
    this.setState({
      focused: true,
    })
  }

  blurred = () => {
    this.setState({
      focused: false,
    })
  }

  render() {
    const value =
      typeof this.props.value === 'undefined' || this.props.value === null
        ? this.state.value
        : this.props.value

    const anySelection = value !== -1

    let classes = !anySelection
      ? 'cc-floating-multiselect-notext'
      : 'cc-floating-multiselect-text'
    classes +=
      ' ' +
      (this.state.focused === true
        ? 'cc-floating-multiselect-focused'
        : 'cc-floating-multiselect-nonfocused')
    classes += ' cc-floating-multiselect'

    let placeholderClasses = !anySelection
      ? 'placeholder-multiselect-movement-notext'
      : 'placeholder-multiselect-movement-text'
    placeholderClasses +=
      ' ' +
      (this.state.focused === true
        ? 'placeholder-multiselect-focused'
        : 'placeholder-multiselect-nonfocused')
    placeholderClasses += ' cc-floating-placeholder-multiselect'

    const options = this.props.options.map((x) => (
      <Option key={x.value} value={x.value.toString()}>
        {x.text || x.name}
      </Option>
    ))

    return (
      <div className="floating-multiselect-row">
        <Select
          onFocus={this.focused}
          onBlur={this.blurred}
          mode="multiple"
          size="large"
          placeholder={this.props.name}
          name={this.props.id}
          onChange={this.valueChanged}
          onSelect={this.onSelect}
          className={classes}
          value={anySelection ? value : undefined}
          showArrow
        >
          {options}
        </Select>
        <input
          type="hidden"
          value={
            this.props.useText ? this.state.selectedText : this.state.value
          }
          name={this.props.id}
        />
        <span className={placeholderClasses}>{this.props.name}</span>
      </div>
    )
  }
}

export default SelectInput
