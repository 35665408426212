import { Component, useEffect, useHistory, useState } from 'react'
import { Button, Checkbox, Col, Divider, Empty, Row, Spin, message } from 'antd'
//import PropTypes from "prop-types";
import isNil from 'lodash/isNil'
import { updateContact } from '../../actions/settings'
import http from '../../utils/api'
import CCUpload from '../CCUpload'
import ContactHeaderBackLink from '../ContactHeaderBackLink/ContactHeaderBackLink'
import FloatingInput from '../FloatingInput'
import FloatingSelect from '../FloatingSelect'
import OnboardingAddressSection from '../OnboardingComponents/OnboardingAddressSection'
import './ContactForm.less'
import '../../styles/forms.less'

const ContactForm = ({
  match: {
    params: { contactId },
  },
  history: { goBack },
}) => {
  const [contactData, setcontactData] = useState(null)
  //const [addressValue, setaddressValue] = useState(null);

  const [jobLevels, setjobLevels] = useState([])
  const [jobFunctions, setjobFunctions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [valuesChanged, setvaluesChanged] = useState(false)
  const selectedContactId = parseInt(contactId, 10)
  const [countries, setcountries] = useState([])
  const [regions, setregions] = useState([])
  const [cities, setcities] = useState([])
  // const address = {
  //   ...contactData,
  //   zipCode: contactData.postalCode
  // };
  const [addressValue, setAddressValue] = useState(null)
  //test

  useEffect(() => {
    http.get(`contacts/details/${selectedContactId}`).then((response) => {
      setcontactData({
        ...response.data[0],
        zipCode: response.data[0].postalCode,
      })
      const address = {
        ...response.data[0],
        zipCode: response.data[0].postalCode,
      }
      console.log('response', address)
      setAddressValue(address)
    })
    http
      .get(`contacts/joblevels`)
      .then((response) => setjobLevels(response.data))
    http
      .get(`contacts/jobfunctions`)
      .then((response) => setjobFunctions(response.data))

    // let cnt = await http.get(`countries`);
    // setcountries(cnt.data);
    // if(!isNil(userdetails.data[0].country) && !isEmpty(userdetails.data[0].country))
    // {
    //   let rgs = await http.get('/countries/' + cnt.data.find(obj=>obj.name===userdetails.data[0].country).value + '/regions');
    //   setregions(rgs.data);
    //   if(!isNil(userdetails.data[0].state) && !isEmpty(userdetails.data[0].state))
    //   {
    //     if(!isNil(rgs.data.find(obj=>obj.region===userdetails.data[0].state)))
    //     {
    //       let res3 = await http.get('countries/' + cnt.data.find(obj=>obj.name===userdetails.data[0].country).value + '/region/' + rgs.data.find(obj=>obj.region===userdetails.data[0].state).region + '/cities');
    //       setcities(res3.data);
    //     }
    //   }
    // }

    setIsLoading(false)
    //response();
  }, [])

  if (isNil(contactData)) {
    return <Spin spinning={isLoading}></Spin>
  }

  const onCountryChange = (value, name) => {
    if (!isNil(value)) {
      http.get('/countries/' + value + '/regions').then((res) => {
        setregions(res.data)
      })
    }
    setcontactData({ ...contactData, [name]: value })
    setvaluesChanged(true)
  }

  const onStateChange = (value, name) => {
    if (!isNil(value)) {
      var countryCode = contactData['country']
      if (!isNil(countryCode)) {
        http
          .get('countries/' + countryCode + '/region/' + value + '/cities')
          .then((res) => {
            setcities(res.data)
          })
      }
    }
    setcontactData({ ...contactData, [name]: value })
    setvaluesChanged(true)
  }

  const onValueChanged = (value, name) => {
    //const { contactData } = this.state;
    setcontactData({ ...contactData, [name]: value })

    //setcontactData({name:value});
    setvaluesChanged(true)

    // callbackChanged(contactData);
  }

  const imageChanged = (base64Image) => {
    setcontactData({
      ...contactData,
      imageUrl: base64Image,
      image: base64Image,
    })
    setvaluesChanged(true)
  }

  // const addressChanged = contact => {
  //     setcontactData({...contact})
  //     setvaluesChanged(true);
  // };

  const addressChanged = (contactValue) => {
    //company.state = company.stateProvince;
    //console.log("contact", contactValue);
    contactValue.postalCode = contactValue.zipCode
    //setAddressValue(contactValue);
    setAddressValue(contactValue)
    setcontactData(contactValue)
    //console.log("contact", contactData);
    // setData(company);
    setvaluesChanged(true)
  }

  const postData = () => {
    if (valuesChanged) {
      updateContact(selectedContactId, contactData).then((response) => {
        message.success('Contact information saved successfully')
        setvaluesChanged(false)
        setIsLoading(false)
      })
      setIsLoading(true)
    }
  }

  return (
    <>
      <Spin size="large" spinning={isLoading || contactData === null}>
        <ContactHeaderBackLink contactId={contactId}></ContactHeaderBackLink>

        <Row style={{ marginTop: '20px', backgroundColor: 'white' }}>
          <Row>
            <Col span={24}>
              <h2
                style={{
                  marginBottom: '24px',
                  marginTop: '24px',
                  fontWeight: '600',
                  marginLeft: '24px',
                  fontSize: '18px',
                  lineHeight: '22px',
                }}
              >
                User Details
              </h2>
            </Col>
            <Divider />
          </Row>
          <div className="form-container">
            <div className="form-section">
              <div className="form-column-grouping cc-heading5">
                Personal Information
              </div>
              <div className="form-column-inputs">
                <FloatingInput
                  onChange={onValueChanged}
                  value={contactData.firstName}
                  name="First Name"
                  id="firstName"
                />
                <FloatingInput
                  onChange={onValueChanged}
                  value={contactData.lastName}
                  name="Last Name"
                  id="lastName"
                />
                <FloatingInput
                  onChange={onValueChanged}
                  value={contactData.email}
                  name="Email"
                  id="email"
                />
                <FloatingInput
                  onChange={onValueChanged}
                  value={contactData.jobTitle}
                  name="Job Title"
                  id="jobTitle"
                />
                <FloatingSelect
                  name="Job Level"
                  value={contactData.jobLevel}
                  onChange={onValueChanged}
                  id="jobLevel"
                  options={jobLevels.map((x) => ({
                    value: x.name,
                    text: x.name,
                  }))}
                />
                <FloatingSelect
                  name="Job Function"
                  id="jobFunction"
                  value={contactData.jobFunction}
                  onChange={onValueChanged}
                  options={jobFunctions.map((x) => ({
                    value: x.name,
                    text: x.name,
                  }))}
                />
                <FloatingInput
                  onChange={onValueChanged}
                  value={contactData.workPhone}
                  name="Work Phone"
                  id="workPhone"
                />
                <FloatingInput
                  onChange={onValueChanged}
                  value={contactData.workExtension}
                  name="Work Extension"
                  id="workExtension"
                />
                <FloatingInput
                  onChange={onValueChanged}
                  value={contactData.mobilePhone}
                  name="Mobile Phone"
                  id="mobilePhone"
                />
              </div>
              <div className="form-column-upload">
                <CCUpload
                  onChange={imageChanged}
                  imageUrl={contactData.imageUrl}
                  placeholder="Upload Photo"
                />
              </div>
            </div>

            <div className="form-container">
              <div className="form-section">
                <div className="form-column-grouping cc-heading5">Address</div>
                <div className="form-column-inputs">
                  {isNil(contactData) ? null : (
                    <OnboardingAddressSection
                      value={contactData}
                      inputNamePrefix="Contact."
                      onChange={addressChanged}
                    />
                  )}
                </div>
                <div className="form-column-upload"></div>
              </div>
            </div>

            <div className="form-section-submit">
              <div className="empty-column-left" />
              <div className="form-section-submit-buttons">
                <Button
                  type="default"
                  className="form-section-btn-cancel"
                  onClick={goBack}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="form-section-btn-save"
                  onClick={postData}
                >
                  Save
                </Button>
              </div>
              <div className="empty-column-right" />
            </div>
          </div>
        </Row>
      </Spin>
    </>
  )
}

export default ContactForm
