import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'
import filter from 'lodash/filter'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import LoadingGif from '../components/LoadingGif'
import MandateWizard from '../components/MandateWizard/MandateWizard'
import http from '../utils/api'
import MandateWizardCtx from '../utils/contexts/MandateWizardCtx'

const MandateWizardForm = () => {
  const { state: locationState } = useLocation()
  const [initialStepQuestions, setInitialStepQuestions] = useState([])
  const [initialStepAnswers, setInitialStepAnswers] = useState([])
  const [otherStepQuestions, setOtherStepQuestions] = useState([])
  const [otherStepAnswers, setOtherStepAnswers] = useState([])
  const [invalidAnswers, setInvalidAnswers] = useState([])
  const [requiredQuestionsForApplication, setRequiredQuestionsForApplication] =
    useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [companyId, setCompanyId] = useState(
    get(locationState, 'companyId', '')
  )
  const [contactId, setContactId] = useState(
    get(locationState, 'contactId', '')
  )
  const {
    params: { mandateId },
  } = useRouteMatch()

  const [submitPath, setSubmitPath] = useState(
    get(locationState, 'submitPath', '/mandates')
  )

  const isEdit = !isNil(mandateId)

  const stepNumbers = [
    1,
    ...new Set(otherStepQuestions.map((step) => step.stepNumber)),
  ]

  const fetchInitialStepQuestions = useCallback(() => {
    setLoading(true)
    http
      .post(`/mandates/questions/overview`, {
        companyId: companyId,
        mandateId: mandateId,
      })
      .then((response) => setInitialStepQuestions(response.data))
      .catch(() =>
        message.error('Could not fetch mandate questions for the first step')
      )
      .finally(() => {
        setLoading(false)
        isEdit &&
          http
            .get(`/mandates/answers/${mandateId}/overview`)
            .then((response) => setInitialStepAnswers(response.data.answers))
            .catch(() =>
              message.error(
                'Could not fetch mandate answers for the first step'
              )
            )
      })
  }, [isEdit, mandateId])

  const handleNextInitialStep = () => {
    setLoading(true)
    http
      .post('/mandates/questions', initialStepAnswers)
      .then((response) => {
        const questions = response.data
        setOtherStepQuestions(questions)

        const questionsShortNames = questions.map((r) => r.shortName)
        setOtherStepAnswers((prevAnswers) =>
          prevAnswers.filter((a) => questionsShortNames.includes(a.shortName))
        )
        setCurrentStep(1)
      })
      .catch(() =>
        message.error('Could not fetch mandate questions for the other steps')
      )
      .finally(() => {
        setLoading(false)
        if (isEdit) {
          setLoading(true)
          http
            .post(`/mandates/answers/${mandateId}`, initialStepAnswers)
            .then((response) => {
              setOtherStepAnswers(response.data.answers)
              setInvalidAnswers((prev) =>
                prev.filter((p) => p.stepNumber === currentStep + 1)
              )
              setRequiredQuestionsForApplication(
                map(
                  filter(response.data.answers, 'isRequiredForApplication'),
                  (a) => a.questionId
                )
              )
            })
            .catch(() =>
              message.error(
                'Could not fetch mandate answers for the other steps'
              )
            )
            .finally(() => setLoading(false))
        }
      })
  }

  const handleSubmit = () => {
    const type = isEdit ? 'patch' : 'post'
    const url = isEdit ? `/mandates/${mandateId}` : '/mandates'
    const action = isEdit ? 'edited' : 'created'
    setLoading(true)
    const answers = [...initialStepAnswers, ...otherStepAnswers]
    const allAnswers = map(answers, (a) => ({
      ...a,
      isRequiredForApplication: includes(
        requiredQuestionsForApplication,
        a.questionId
      ),
    }))
    http[type](url, {
      companyId: companyId,
      contactId: contactId,
      mandateId: mandateId,
      answers: allAnswers,
    })
      .then(() => {
        message.success(`Mandate ${action} successfully`)
        history.push(submitPath)
      })
      .catch(() => message.error('Could not create mandate'))
      .finally(() => setLoading(false))
  }

  const handleNextStep = () => {
    setCurrentStep((prevCurrentStep) => prevCurrentStep + 1)
  }

  const mandateWizardCtxValue = {
    initialStepQuestions,
    setInitialStepQuestions,
    initialStepAnswers,
    setInitialStepAnswers,
    otherStepQuestions,
    handleNextInitialStep,
    otherStepAnswers,
    setOtherStepAnswers,
    invalidAnswers,
    setInvalidAnswers,
    currentStep,
    setCurrentStep,
    stepNumbers,
    handleNextStep,
    handleSubmit,
    isEdit,
    requiredQuestionsForApplication,
    setRequiredQuestionsForApplication,
  }

  useEffect(() => {
    fetchInitialStepQuestions()
  }, [fetchInitialStepQuestions])

  return (
    <MandateWizardCtx.Provider value={mandateWizardCtxValue}>
      <LoadingGif spinning={loading}>
        <MandateWizard />
      </LoadingGif>
    </MandateWizardCtx.Provider>
  )
}

export default MandateWizardForm
