import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, DatePicker, Input, InputNumber, Radio, Select } from 'antd'
import classNames from 'classnames'
import { ErrorMessage } from 'formik'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import has from 'lodash/has'
import includes from 'lodash/includes'
import isBoolean from 'lodash/isBoolean'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import map from 'lodash/map'
import replace from 'lodash/replace'
import split from 'lodash/split'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import trim from 'lodash/trim'
import values from 'lodash/values'
import moment from 'moment'
import { useSelector } from 'react-redux'
import ArrayField from '../../ServiceProviderWizard/Fields/ArrayField'
import ClientsField from '../../ServiceProviderWizard/Fields/ClientsField'
import PersonField from '../../ServiceProviderWizard/Fields/PersonField'
import TestimonialField from '../../ServiceProviderWizard/Fields/TestimonialField'
import AutocompleteField from './AutocompleteField'
import Field from './Field'
import LinkField from './LinkField'
import SearchField from './SearchField'
import StaticText from './StaticText'
import UploadFileField from './UploadFileField'
import UploadMultipleFileField from './UploadMultipleFileField'
import './FormField.less'

const { TextArea } = Input

const FORMATTERS = {
  percentage: (value) => (value ? `${value}%` : ''),
  multiple: (value) => (value ? `${value}x` : ''),
  amount: (value) =>
    value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '',
}

const PARSERS = {
  percentage: (value) => value.replace('%', ''),
  multiple: (value) => value.replace('x', ''),
  amount: (value) => value.replace(/\$\s?|(,*)/g, ''),
}

const CONSTRAINTS = {
  disabledDate: {
    future: (value) => !isNil(value) && value > moment().startOf('day'),
    past: (value) => !isNil(value) && value < moment().startOf('day'),
  },
}

const TRANSFORMERS = {
  valueSameAsKey: (opts) => map(opts, (opt) => ({ value: opt, text: opt })),
  countryToOptions: (opts) =>
    map(opts, (opt) => ({ value: opt.name, text: opt.name })),
  geocodingResultsToUpdates: (results) => {
    let city
    let state
    let postalTown
    let administrative_3
    let sublocality
    forEach(get(results, '0.address_components', []), (element) => {
      const { types } = element
      types.forEach((type) => {
        if (type === 'locality') {
          city = element.long_name
        }
        if (type === 'postal_town') {
          postalTown = element.long_name
        }
        if (type === 'administrative_area_level_1') {
          state = element.short_name
        }
        if (type === 'administrative_area_level_3') {
          administrative_3 = element.short_name
        }
        if (type === 'sublocality_level_1') {
          sublocality = element.short_name
        }
      })
    })

    if (isNil(state)) {
      state = sublocality
    }

    if (isNil(state)) {
      state = city
    }

    if (!isNil(postalTown)) {
      city = postalTown
    }

    if (isNil(city)) {
      city = administrative_3
    }

    if (isNil(city)) {
      city = state
    }

    return {
      ManagerStateProvince: state,
      ManagerCity: city,
    }
  },
}

const toFieldName = (val) =>
  join(
    map(
      split(
        replace(replace(trim(val), /[^a-zA-Z\s]/gi, ''), /\s+/gi, ' '),
        ' '
      ),
      (p) => startCase(toLower(p))
    ),
    ''
  )

const showField = (fieldName, dependencies, values) => {
  const dependency = get(dependencies, fieldName)

  if (isNil(dependency)) {
    return true
  }

  const depValue = get(values, dependency.field)

  if (has(dependency, 'showOn')) {
    return isEqual(depValue, dependency.showOn)
  }

  if (has(dependency, 'showWhenIncludes')) {
    return includes(depValue, dependency.showWhenIncludes)
  }

  return true
}

const getFilteredOptions = (fieldName, options, dependencies, values) => {
  const dependency = get(dependencies, fieldName)

  if (isNil(dependency)) {
    return options
  }

  const { field: otherFieldName, filterOn } = dependency
  if (filterOn) {
    const depValue = get(values, `${otherFieldName}.0`)

    return filter(
      options,
      (opt) => opt.conditionalFundQuestionAnswerId === depValue
    )
  }

  return options
}

const hasDependents = (fieldName, dependencies) =>
  includes(
    map(values(dependencies), (d) => d.field),
    fieldName
  )

const getDependents = (fieldName, dependencies) =>
  map(
    filter(
      map(dependencies, (v, k) => [v.field, k]),
      (fields) => fields[0] === fieldName
    ),
    (names) => names[1]
  )

const FormField = ({
  question,
  values,
  errors,
  dependencies,
  disabled = false,
  onBlur,
  onChange,
  setFieldValue,
  setFieldTouched,
  hideLabel = false,
  isDeal = false,
}) => {
  const {
    questionType,
    name: labelText,
    shortName,
    text,
    attributes,
    isRequired,
    fundQuestionAnswers,
    dealQuestionAnswers,
  } = question

  const show = showField(shortName, dependencies, values)

  const options = getFilteredOptions(
    shortName,
    (isDeal && dealQuestionAnswers) || fundQuestionAnswers,
    dependencies,
    values
  )
  const role = useSelector((state) => state.auth.role)

  const handleBlur = useCallback(() => {
    setFieldTouched(shortName)
  }, [setFieldTouched, shortName])

  const handleChange = useCallback(
    (value) => {
      setFieldValue(shortName, value)
    },
    [setFieldValue, shortName]
  )

  const handleSelectChange = useCallback(
    (value) => {
      if (hasDependents(shortName, dependencies)) {
        forEach(getDependents(shortName, dependencies), (name) => {
          setFieldValue(name, undefined)
        })
      }
      setFieldValue(shortName, value ? [parseInt(value, 10)] : null)
    },
    [setFieldValue, shortName, dependencies]
  )

  const handleBooleanChange = useCallback(
    (e) => {
      if (hasDependents(shortName, dependencies)) {
        forEach(getDependents(shortName, dependencies), (name) => {
          setFieldValue(name, undefined)
        })
      }
      onChange(e)
    },
    [onChange, setFieldValue, dependencies, shortName]
  )

  const handleRadioChange = useCallback(
    (e) => {
      setFieldValue(shortName, [e.target.value])
    },
    [setFieldValue, shortName]
  )

  const handleDateChange = useCallback(
    (value) => {
      setFieldValue(
        shortName,
        isNil(value) ? null : value.format(moment.HTML5_FMT.DATE)
      )
    },
    [setFieldValue, shortName]
  )

  if (!show) {
    return null
  }

  const QUESTION_TYPE_FIELDS = {
    TextSingle: { component: Field, tag: Input },
    TextMultiple: {
      component: Field,
      tag: TextArea,
    },
    Checkbox: {
      component: Field,
      tag: Checkbox.Group,
      labelType: 'label',
      blur: handleBlur,
      change: handleChange,
      floating: false,
      renderOptions: (opts) =>
        map(opts, (opt) => (
          <Checkbox
            key={isDeal ? opt.questionAnswerId : opt.fundQuestionAnswerId}
            value={isDeal ? opt.questionAnswerId : opt.fundQuestionAnswerId}
          >
            {opt.answer}
          </Checkbox>
        )),
    },
    Dropdown: {
      component: Field,
      tag: Select,
      valueGetter: (values, name) => get(values, `${name}.0`),
      renderOptions: (opts) =>
        map(opts, (opt) => (
          <Select.Option
            key={hideLabel ? opt.questionAnswerId : opt.fundQuestionAnswerId}
            value={hideLabel ? opt.questionAnswerId : opt.fundQuestionAnswerId}
          >
            {opt.answer}
          </Select.Option>
        )),
      blur: handleBlur,
      change: handleSelectChange,
    },
    NumericInt: { component: Field, tag: InputNumber, change: handleChange },
    NumericFloat: { component: Field, tag: InputNumber, change: handleChange },
    Boolean: {
      component: Field,
      tag: Radio.Group,
      floating: false,
      renderOptions: (options, extraProps) => {
        return (
          <>
            <Radio value={true}>{extraProps.yesText || 'Yes'}</Radio>
            <Radio value={false}>{extraProps.noText || 'No'}</Radio>
          </>
        )
      },
      valueGetter: (values, name) => {
        if (isBoolean(get(values, name))) {
          return get(values, name)
        }
        return isNil(get(values, name)) ? null : get(values, name) === 'true'
      },
      change: handleBooleanChange,
    },
    Ranking: {},
    UploadExcel: {
      component: UploadFileField,
      change: handleChange,
      extras: { accept: '.xlsx' },
    },
    UploadFile: {
      component: UploadMultipleFileField,
      change: handleChange,
      extras: {
        accept: '.pdf',
        help: 'Drag or click here to upload documents',
        hint: '(PDF / Max file size 100mb)',
      },
    },
    UploadImage: {
      component: UploadMultipleFileField,
      change: handleChange,
      extras: {
        accept: '.jpg, .png, .bmp, .mov, .avi, .mp4, .mpeg4',
        listType: 'picture-card',
        listLength: (role === 'Service Provider' || role === 'Manager') && 5,
      },
    },
    UploadImageSingle: {
      component: UploadFileField,
      change: handleChange,
      extras: {
        accept: '.jpg, .png, .bmp',
        listType: 'picture-card',
        help: 'Drag or click here to upload a logo',
        hint: '(PNG, JPG / Max file size 5mb)',
      },
    },
    Date: {
      component: Field,
      tag: DatePicker,
      valueGetter: (values, name) =>
        isNil(get(values, name))
          ? null
          : moment.utc(values[name], moment.HTML5_FMT.DATE),
      change: handleDateChange,
    },
    RadioButton: {
      component: Field,
      tag: Radio.Group,
      floating: false,
      valueGetter: (values, name) => get(values, `${name}.0`),
      change: handleRadioChange,
      renderOptions: (opts) =>
        map(opts, (opt) => (
          <Radio
            key={opt.fundQuestionAnswerId}
            value={opt.fundQuestionAnswerId}
          >
            {opt.answer}
          </Radio>
        )),
    },
    Address: {},
    CheckboxGrid: {},
    ManuallyHandled: {},
    Strategy: {},
    StaticText: { component: StaticText },
    PeopleList: {
      component: ArrayField,
      valueGetter: (values, name) =>
        get(values, name) === null ? undefined : get(values, name),
      change: handleChange,
      extras: {
        component: PersonField,
        componentProps: {},
        initialItem: {
          fullName: null,
          jobPosition: null,
          description: null,
          imageUrl: null,
        },
      },
    },
    TestimonalList: {
      component: ArrayField,
      valueGetter: (values, name) =>
        get(values, name) === null ? undefined : get(values, name),
      change: handleChange,
      extras: {
        component: TestimonialField,
        componentProps: {},
        initialItem: {
          fullName: null,
          jobPosition: null,
          description: null,
          title: null,
        },
      },
    },
    ClientList: {
      component: ArrayField,
      valueGetter: (values, name) =>
        get(values, name) === null ? undefined : get(values, name),
      change: handleChange,
      extras: {
        component: ClientsField,
        componentProps: {},
        initialItem: {
          name: null,
          imageUrl: null,
        },
      },
    },
    LinkList: {
      component: ArrayField,
      valueGetter: (values, name) =>
        get(values, name) === null ? undefined : get(values, name),
      change: handleChange,
      extras: {
        component: LinkField,
        componentProps: {},
        initialItem: {
          title: null,
          url: null,
        },
        text: 'External Links',
        useModalHeight: false,
        maxItems: 5,
      },
    },
  }

  const fieldName = toFieldName(questionType)
  const {
    component: Component,
    tag,
    extras = {},
    renderOptions,
    blur = onBlur,
    change = onChange,
    valueGetter = (values, name) => get(values, name),
    floating,
  } = get(QUESTION_TYPE_FIELDS, fieldName, {})

  let FieldTag = tag
  let changeHandler = change

  if (isNil(Component)) {
    console.error(`ERROR: Cannot get field for ${fieldName}`)
    return null
  }

  let isFloating = !has(attributes, 'label')
  if (!isNil(floating)) {
    isFloating = floating
  }
  const label = get(attributes, 'label', text)
  const value = valueGetter(values, shortName)
  const hasError = has(errors, shortName)

  const attrs = {}
  if (has(question, 'attributes.allowClear')) {
    attrs.allowClear = get(attributes, 'allowClear') === 'true'
  }
  if (has(question, 'attributes.sortAnswers')) {
    attrs.sortAnswers = get(attributes, 'sortAnswers') === 'true'
  }

  if (has(question, 'attributes.formatter')) {
    attrs.formatter = get(
      FORMATTERS,
      question.attributes.formatter,
      (value) => value
    )
  }

  if (has(question, 'attributes.parser')) {
    attrs.parser = get(PARSERS, question.attributes.parser, (value) => value)
  }

  if (has(question, 'attributes.constraints')) {
    forEach(question.attributes.constraints, (val, key) => {
      attrs[key] = get(CONSTRAINTS, `${key}.${val}`)
    })
  }

  forEach(
    ['help', 'hint', 'info', 'alertType', 'yesText', 'noText'],
    (attr) => {
      if (has(attributes, attr)) {
        attrs[attr] = get(attributes, attr)
      }
    }
  )

  if (has(attributes, 'autocomplete')) {
    FieldTag = AutocompleteField
    changeHandler = handleChange

    attrs.url = get(attributes, 'autocomplete.url')
    if (has(attributes, 'autocomplete.filterBy')) {
      const [key, otherFieldName] = get(attributes, 'autocomplete.filterBy')
      attrs.filterBy = { [key]: get(values, otherFieldName) }
    }

    if (has(attributes, 'autocomplete.transform')) {
      attrs.transform = get(
        TRANSFORMERS,
        get(attributes, 'autocomplete.transform')
      )
    }
  }

  if (has(attributes, 'search')) {
    FieldTag = SearchField
    isFloating = false

    if (has(attributes, 'search.filterBy')) {
      const otherFieldName = get(attributes, 'search.filterBy')
      attrs.filterBy = get(values, otherFieldName)
    }

    if (has(attributes, 'search.transform')) {
      attrs.transform = get(TRANSFORMERS, get(attributes, 'search.transform'))
    }

    if (get(attributes, 'search.updateFromSearchResults', false)) {
      attrs.onSearchResults = (fields) => {
        forEach(fields, (val, fieldName) => {
          setFieldValue(fieldName, val)
        })
      }
    }
  }

  const props = { ...attrs, ...extras }

  return (
    <div
      className={classNames('FormField', {
        'FormField-is-required': isRequired,
        'FormField-has-error': hasError,
      })}
    >
      <Component
        name={shortName}
        label={hideLabel ? null : label}
        labelText={labelText}
        value={value}
        options={options}
        renderOptions={renderOptions}
        isFloating={isFloating}
        isRequired={isRequired}
        disabled={disabled}
        onBlur={blur}
        onChange={changeHandler}
        hideLabel={hideLabel}
        tag={FieldTag}
        {...props}
      />
      <ErrorMessage name={shortName}>
        {(msg) => (
          <div className="Field-error">
            <FontAwesomeIcon icon={faExclamationCircle} />
            <span className="Field-error-message">{msg}</span>
          </div>
        )}
      </ErrorMessage>
    </div>
  )
}

FormField.propTypes = {
  question: PropTypes.object,
  values: PropTypes.object,
  errors: PropTypes.object,
  dependencies: PropTypes.object,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  hideLabel: PropTypes.bool,
  isDeal: PropTypes.bool,
}

export default FormField
