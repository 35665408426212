import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faExclamationCircle,
  faHandshake,
} from '@fortawesome/pro-light-svg-icons'
import {
  faMapMarkerCheck,
  faPencil,
  faUserFriends,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Button, Col, Row, Switch } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'
import { getHeldBooths } from '../../actions/booths'
import http from '../../utils/api'
import CountDetails from '../CountDetails'
import './AvailableEvent.less'

const AvailableEvent = ({ event }) => {
  const [heldBooths, setHeldBooths] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  useEffect(() => {
    if (!event.virtualSummit) {
      getHeldBooths(event.eventId).then((response) => {
        setHeldBooths(response.data)
      })
    }
  }, [event.eventId, event.virtualSummit])

  const boothStartDate =
    moment(event.boothStartDate).isValid() && !isNil(event.boothStartDate)
      ? moment
          .utc(event.boothStartDate, moment.ISO_8601)
          .local()
          .format('MMM D, YYYY')
      : ''
  const boothEndDate =
    moment(event.boothEndDate).isValid() && !isNil(event.boothEndDate)
      ? moment
          .utc(event.boothEndDate, moment.ISO_8601)
          .local()
          .format('MMM D, YYYY')
      : ''

  const [published, setPublished] = useState(event.isPublished)
  const [featured, setFeatured] = useState(event.isFeatured)
  const { push } = useHistory()

  const poster = isNil(event.thumbnailImage) ? (
    <div className="AvailableEvent-poster-empty">No image</div>
  ) : (
    <div
      className="AvailableEvent-poster"
      style={{
        backgroundImage: `url(${event.thumbnailImage})`,
        height: '96px',
      }}
    />
  )

  const handlePublished = (e) => {
    //check if event can be published
    const errorText = []

    let isValidForPublish = true
    if (e === true) {
      if (isEmpty(event.posterImage)) {
        errorText.push(
          <span>
            Please update a poster image before publishing <br />
          </span>
        )
        isValidForPublish = false
      }
      if (isEmpty(event.thumbnailImage)) {
        errorText.push(
          <span>
            Please update a thumbnali image before publishing <br />
          </span>
        )
        isValidForPublish = false
      }
      if (
        isEmpty(event.startDate) ||
        isEmpty(event.endDate) ||
        !moment(event.startDate).isAfter('0001-01-01') ||
        !moment(event.endDate).isAfter('0001-01-01')
      ) {
        errorText.push(
          <span>
            Please make sure the event start and end dates are correct <br />
          </span>
        )
        isValidForPublish = false
      }
    }
    if (isValidForPublish) {
      http
        .put(`/events/update/published/${event.eventId}/${e}`)
        .then((response) => {
          if (e === false) {
            handleFeatured(false)
          }
          setPublished(e)
        })
    } else {
      setErrorMessage(errorText)
    }
  }

  const handleFeatured = (e) => {
    http
      .put(`/events/update/featured/${event.eventId}/${e}`)
      .then((response) => {
        setFeatured(e)
      })
  }

  return (
    <Row>
      <Col span={24}>
        {errorMessage && (
          <Alert
            message={errorMessage}
            type="warning"
            icon={
              <FontAwesomeIcon color="#F19F00" icon={faExclamationCircle} />
            }
            showIcon
            style={{ marginBottom: '20px' }}
          />
        )}
        <div className="AvailableEvent">
          <Col
            span={3}
            style={{
              textAlign: 'center',
              alignContent: 'center',
              marginTop: '15px',
            }}
          >
            {poster}
            <Row style={{ marginTop: '15px' }}>
              <Switch
                onChange={(e) => handlePublished(e)}
                className="status-switch"
                size="large"
                checkedChildren="Published"
                unCheckedChildren="Not published"
                checked={published}
              />
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Switch
                onChange={(e) => handleFeatured(e)}
                className="status-switch"
                size="large"
                checkedChildren="Featured"
                unCheckedChildren="Not featured"
                checked={featured}
              ></Switch>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Button
                className="btn-edit-action"
                onClick={() => push(`/summits/${event.eventId}/overview`)}
              >
                <FontAwesomeIcon
                  icon={faPencil}
                  style={{ marginRight: '5px' }}
                />
                Edit event
              </Button>
            </Row>
          </Col>
          <Col span={9}>
            <Link
              to={`/summits/${event.eventId}/attendees/${
                event.isCfnEvent ? 'family-members' : 'allocators'
              }`}
            >
              <div className="AvailableEvent-info">
                <Row>
                  <Col lg={10} md={6} sm={24}>
                    <h5 className="AvailableEvent-event-name">{event.name}</h5>
                  </Col>
                  <Col lg={7} md={9} sm={24}>
                    {published && published === true && (
                      <span className="AvailableEvent-tag published">
                        Published
                      </span>
                    )}
                  </Col>
                  <Col lg={7} md={9} sm={24}>
                    {featured && featured === true && (
                      <span className="AvailableEvent-tag featured">
                        Featured
                      </span>
                    )}
                  </Col>
                </Row>
                <p
                  className={
                    event.eventDescription &&
                    event.eventDescription.length > 200
                      ? 'AvailableEvent-description-hidden'
                      : 'AvailableEvent-description'
                  }
                >
                  {event.eventDescription}
                </p>
              </div>
            </Link>
          </Col>
          <Col span={12}>
            <Row type="flex" justify="space-between" className="info-row">
              <Col span={3}>
                <FontAwesomeIcon
                  icon={faUserFriends}
                  size="2x"
                  style={{ color: '#C2C6CC', marginTop: '15px' }}
                />
              </Col>
              <Col span={21}>
                <Row>
                  <Col span={24}>
                    <h3 className="registrations-header">
                      Current Registrations
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col span={event.cfns > 0 ? 4 : 6}>
                    <CountDetails count={event.allocators} label="Allocators" />
                  </Col>
                  <Col span={event.cfns > 0 ? 4 : 6}>
                    <CountDetails count={event.fundManagers} label="Funds" />
                  </Col>
                  <Col span={6}>
                    <CountDetails
                      count={event.serviceProviders}
                      label="Service Providers"
                    />
                  </Col>
                  <Col span={event.cfns > 0 ? 4 : 6}>
                    <CountDetails count={event.media} label="Media" />
                  </Col>
                  {event.cfns > 0 && (
                    <Col span={4}>
                      <CountDetails count={event.cfns} label="CFN" />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" className="info-row">
              <Col span={3}>
                <FontAwesomeIcon
                  icon={faHandshake}
                  size="2x"
                  style={{ color: '#C2C6CC', marginTop: '15px' }}
                />
              </Col>
              <Col span={21}>
                <Row>
                  <Col span={24}>
                    <h3 className="registrations-header">Meetings Stats</h3>
                  </Col>
                </Row>
                <Row>
                  <Col span={4}>
                    <CountDetails
                      count={event.pendingMeetings}
                      label="Pending"
                    />
                  </Col>
                  <Col span={4}>
                    <CountDetails
                      count={event.confirmedMeetings}
                      label="Confirmed"
                    />
                  </Col>
                  <Col span={4}>
                    <CountDetails
                      count={event.declinedMeetings}
                      label="Declined"
                    />
                  </Col>
                  <Col span={4}>
                    <CountDetails
                      count={event.cancelledMeetings}
                      label="Cancelled"
                    />
                  </Col>
                  <Col span={4}>
                    <CountDetails
                      count={event.expiredMeetings}
                      label="Expired"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {!event.virtualSummit && (
              <Row type="flex" justify="space-between" className="info-row">
                <Col span={3}>
                  <FontAwesomeIcon
                    icon={faMapMarkerCheck}
                    size="2x"
                    style={{ color: '#C2C6CC', marginTop: '15px' }}
                  />
                </Col>
                <Col span={21}>
                  <Row>
                    <Col span={24}>
                      <h3 className="registrations-header">Booth</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={4}>
                      <CountDetails count={event.bookedCount} label="Booked" />
                    </Col>
                    <Col span={4}>
                      <CountDetails count={heldBooths} label="Held" />
                    </Col>
                    <Col span={8}>
                      <CountDetails
                        count={boothStartDate}
                        label="Booth open date"
                      />
                    </Col>
                    <Col span={8}>
                      <CountDetails
                        count={boothEndDate}
                        label="Booth closed date"
                      />
                    </Col>
                  </Row>
                  {!event.virtualSummit && (
                    <Row style={{ marginTop: '6px' }}>
                      <Button
                        className="btn-edit-action"
                        onClick={() => push(`/summits/${event.eventId}/seats`)}
                      >
                        <FontAwesomeIcon
                          icon={faPencil}
                          style={{ marginRight: '5px' }}
                        />
                        Edit booths
                      </Button>
                    </Row>
                  )}
                </Col>
              </Row>
            )}
          </Col>
        </div>
      </Col>
    </Row>
  )
}

export const availableEventShape = PropTypes.shape({
  eventId: PropTypes.number.isRequired,
  eventName: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  thumbnailImage: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
})

AvailableEvent.propTypes = {
  event: availableEventShape.isRequired,
}

export default AvailableEvent
