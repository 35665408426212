import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Modal, Radio, Row, message } from 'antd'
import { isEmpty, isNil } from 'lodash'
import { declineMeeting } from '../../actions/contextMeetings'
import ContactName from '../ContactName'
import LoadingGif from '../LoadingGif'
import '../../styles/index.less'

const DeclineMeetingComponent = ({
  contacts,
  companyId,
  visible,
  closeModal,
  company,
  meetingId,
  //meetingType,
  onCancel,
  onForbidden = () => {},
  //initiatorRole,
}) => {
  const [cancellingInProgress, setCancellingInProgress] = useState(false)
  //const tier = useSelector(getTier);
  const [senderId, setSenderId] = useState(null)

  const handleModalCancel = useCallback(() => {
    setSenderId(null)
    closeModal()
  }, [closeModal])

  const onRadioChange = (e) => {
    setSenderId(e.target.value)
  }

  const cancelMeetingReq = useCallback(() => {
    setCancellingInProgress(true)
    declineMeeting(companyId, senderId, meetingId)
      .then(() => {
        message.success('The meeting has been declined')
        handleModalCancel()
        onCancel()
      })
      .catch((err) => {
        var allErr = err.response.data.message
        message.error(
          allErr.substring(allErr.indexOf("'") + 1, allErr.indexOf("'."))
        )
        //tier === "Free" && onForbidden() && onCancel();
      })
      .finally(() => {
        setCancellingInProgress(false)
      })
  }, [companyId, handleModalCancel, meetingId, onCancel, senderId])

  const title = 'Decline Meeting'
  useEffect(() => {
    if (contacts && contacts.length === 1) {
      setSenderId(contacts[0].contactId)
    }
  }, [contacts])
  return (
    <Modal
      destroyOnClose
      mask={false}
      className="cc-modal-accept"
      title={title}
      visible={visible}
      okText={title}
      cancelText="Close"
      onOk={cancelMeetingReq}
      onCancel={handleModalCancel}
      okButtonProps={{
        loading: cancellingInProgress,
        style: { backgroundColor: '#D62B34', borderStyle: 'none' },
        disabled: isNil(senderId),
      }}
      cancelButtonProps={{ disabled: false }}
    >
      <LoadingGif spinning={cancellingInProgress}>
        <Row>
          <p>Decline meeting request with {company} on behalf of:</p>
          <Col span="24">
            <Radio.Group
              onChange={onRadioChange}
              value={senderId}
              style={{ marginLeft: '15px' }}
            >
              {contacts &&
                contacts.map((row) => {
                  return (
                    <div style={{ paddingBottom: '6px' }}>
                      <Radio value={row.contactId} />
                      <ContactName
                        style={{ display: 'inline' }}
                        name={row.contactName}
                        imageUrl={row.imageURL}
                      />
                    </div>
                  )
                })}
            </Radio.Group>
          </Col>
        </Row>
      </LoadingGif>
    </Modal>
  )
}

DeclineMeetingComponent.propTypes = {
  contacts: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  company: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
  //meetingType: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onForbidden: PropTypes.func,
  //initiatorRole: PropTypes.string,
}

export default DeclineMeetingComponent
