import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'antd'
import axios from 'axios'
import { isNil } from 'lodash'
import {
  getEventAllocatorsList,
  getEventSortFilters,
} from '../../actions/events'
import { CATEGORY_ALLOCATOR } from '../../constants/types/categories'
import http from '../../utils/api'
import { getSummitAllocatorAttendeeDefaultColumns } from '../../utils/columns'
import ContextTable from '../ContextTable'

const SummitAttendeeAllocators = ({
  eventId,
  isPast,
  isVirtual,
  defaultFilterValue,
}) => {
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [refreshData, setRefreshData] = useState(false)
  const [selectedFund, setSelectedFund] = useState(null)
  const [columns, setColumns] = useState(null)

  const refresh = () => {
    setRefreshData(!refreshData)
  }

  useEffect(() => {
    var cols = getSummitAllocatorAttendeeDefaultColumns(
      isPast,
      refresh,
      isVirtual
    )

    getEventSortFilters(eventId, CATEGORY_ALLOCATOR)
      .then((res) => {
        var query = res.data

        if (!isNil(query.sorter) && !isNil(query.sorter.columnKey)) {
          var sortIndex = cols.findIndex(
            (x) =>
              x.dataIndex === query.sorter.columnKey ||
              x.key === query.sorter.columnKey
          )
          if (sortIndex >= 0) {
            cols[sortIndex].defaultSortOrder = query.sorter.order
          }
        }

        if (!isNil(query.filters) && !isNil(query.filters.columns)) {
          var filterIndex

          for (let i = 0; i < query.filters.columns.length; i++) {
            filterIndex = cols.findIndex(
              (x) =>
                x.dataIndex === query.filters.columns[i].column ||
                x.key === query.filters.columns[i].column
            )

            if (filterIndex >= 0) {
              cols[filterIndex].defaultFilteredValue =
                query.filters.columns[i].values
            }
          }
        }
        setColumns(cols)
      })
      .catch((err) => setColumns(cols))
  }, [refreshData])

  const getSavedQuery = () => {
    return getEventSortFilters(eventId, CATEGORY_ALLOCATOR)
  }

  return (
    <>
      {columns && (
        <ContextTable
          tableTitle="Allocators"
          columns={columns}
          eventId={eventId}
          dataMethod={getEventAllocatorsList}
          savedQueryMethod={getSavedQuery}
          refresh={refreshData}
          refreshData={refreshData}
          defaultSearchTerm={defaultFilterValue}
        />
      )}
    </>
  )
}

SummitAttendeeAllocators.propTypes = {
  eventId: PropTypes.number.isRequired,
}
export default SummitAttendeeAllocators
