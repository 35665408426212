import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faAngleDown,
  faArrowRight,
  faPlus,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Menu, Modal, Spin, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment'
import http from '../../utils/api'
import ProfilePictureGroup from '../ProfilePictureGroup'

const MeetingsManagementModalSP = ({
  visible,
  onCancel,
  boothId,
  companyId,
  boothName,
  availableBoothsToMoveTo = [],
  setPageLoading,
}) => {
  const [meetings, setMeetings] = useState([])
  const [submitResponses, setSubmitResponses] = useState([])
  const [selectedMeetings, setSelectedMeetings] = useState([])
  const [loading, setLoading] = useState(false)
  const boothMenu = (meetingId) => (
    <Menu
      style={{ maxWidth: '286px' }}
      onClick={(e) => {
        const meetingIds = selectedMeetings.map((s) => s.meetingId)
        if (meetingIds.includes(meetingId)) {
          setSelectedMeetings((prev) => {
            const prevMeetings = [...prev]
            prevMeetings.find((m) => m.meetingId === meetingId).newBoothId =
              e.key
            return prevMeetings
          })
        } else
          setSelectedMeetings((prev) => [
            ...prev,
            { meetingId, newBoothId: e.key },
          ])
      }}
    >
      {availableBoothsToMoveTo.map((item) => (
        <Menu.Item key={item.EventCompanyBoothId}>
          <div className="contactName">{item.BoothId}</div>
        </Menu.Item>
      ))}
    </Menu>
  )

  const handleGetBoothMeetings = (boothId) => {
    setLoading(true)
    http
      .get(`booths/${boothId}/meetings?api-version=2`)
      .then((response) => setMeetings(response.data))
      .catch(() => message.success('Could not fetch meetings'))
      .finally(() => setLoading(false))
  }

  const handleStatusDisplay = (status) => {
    switch (status) {
      case 1:
        return <span className="tag tag-pending">Pending</span>
      case 2:
        return <span className="tag tag-confirmed">Confirmed</span>

      default:
        break
    }
  }

  const handleDropdownTextDisplay = (meetingId) => {
    const meeting = selectedMeetings.find((m) => m.meetingId === meetingId)

    if (meeting)
      return availableBoothsToMoveTo.find(
        (b) => b.EventCompanyBoothId.toString() === meeting.newBoothId
      )?.BoothId
    return 'Select Booth'
  }

  useEffect(() => {
    handleGetBoothMeetings(boothId)
  }, [boothId])

  const onOk = () => {
    setLoading(true)
    http
      .post(
        `booths/meetings/${companyId}/manual/move?api-version=2`,
        selectedMeetings
      )
      .then(function (response) {
        setSubmitResponses(response.data)
        message.success('Changes processed successfully')
      })
      .catch(() => message.error('Could not process changes'))
      .finally(() => {
        setLoading(false)
        setPageLoading()
      })
  }

  const getMeetingElement = (meetingId) =>
    submitResponses.find((res) => res.meetingId === meetingId)

  return (
    <Modal
      className="cc-modal-accept"
      title={`${boothName} (${meetings.length} Meetings)`}
      visible={visible}
      okText={'Save'}
      cancelText="Cancel"
      onOk={() => onOk()}
      onCancel={() => {
        onCancel()
        setSelectedMeetings([])
      }}
      centered={true}
      cancelButtonProps={{ disabled: false }}
      width={1000}
    >
      <Spin spinning={loading}>
        <div>
          {meetings.map((meeting) => (
            <div className="mb-20">
              <div className="meeting-row flex" key={meeting.meetingId}>
                <div className="flex">
                  <div className="meeting-time">
                    {isNil(meeting.meetingDateTime)
                      ? 'TBD'
                      : moment
                          .utc(meeting.meetingDateTime)
                          .tz(meeting.timeZone)
                          .format('YYYY-MM-DD hh:mm a')}
                  </div>
                  <div>
                    <ProfilePictureGroup
                      diameter={32}
                      profiles={meeting.meetingParticipants.map((x) => ({
                        name: `${x.firstName} ${x.lastName}`,
                        imageURL: x.imageUrl,
                        firstName: x.firstName,
                        lastName: x.lastName,
                      }))}
                      maxImages="2"
                      style={{ marginRight: '10px' }}
                    ></ProfilePictureGroup>
                  </div>
                  <div className="company-info">
                    <div className="company-name">{meeting.fromCompany}</div>
                    <div className="contactDetail">
                      {meeting.fromCompanyCategory}
                    </div>
                  </div>
                  <div className="company-info">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                  <div className="company-info">
                    <div className="company-name">{meeting.toCompany}</div>
                    <div className="contactDetail">
                      {meeting.toCompanyCategory}
                    </div>
                  </div>
                  {handleStatusDisplay(meeting.meetingStatusId)}
                </div>
                {!isEmpty(availableBoothsToMoveTo) && (
                  <Dropdown
                    overlay={() => boothMenu(meeting.meetingId)}
                    trigger={['click']}
                  >
                    <a
                      className="ant-dropdown-link flex"
                      onClick={(e) => {
                        e.preventDefault()
                      }}
                      style={{
                        fontWeight: 600,
                        paddingTop: '6px',
                        color: 'black',
                        width: 'auto',
                      }}
                    >
                      {handleDropdownTextDisplay(meeting.meetingId)}{' '}
                      <FontAwesomeIcon icon={faAngleDown} size="2x" />
                    </a>
                  </Dropdown>
                )}
              </div>
              {getMeetingElement(meeting.meetingId) &&
                !getMeetingElement(meeting.meetingId).isSuccessful && (
                  <div className="meeting-error">
                    {getMeetingElement(meeting.meetingId).message}
                  </div>
                )}
              {getMeetingElement(meeting.meetingId) &&
                getMeetingElement(meeting.meetingId).isSuccessful && (
                  <div className="meeting-success">
                    {'Meeting moved successfully'}
                  </div>
                )}
            </div>
          ))}
        </div>
      </Spin>
    </Modal>
  )
}

MeetingsManagementModalSP.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
}

export default MeetingsManagementModalSP
