import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faArrowLeft, faFileExport } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Modal, Row, Tabs, Tooltip, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import replace from 'lodash/replace'
import { bookBooth, releaseBooth, updateBooth } from '../../actions/booths'
import EventFunds from '../EventFunds/EventFunds'
import ExportLogs from '../ExportLogs/ExportLogs'
import SeatsContainer from '../SeatsContainer/SeatsContainer'
import CompanyEventMeetings from './CompanyEventMeetings'
import CompanyScheduleDetails from './CompanyScheduleDetails'

const { TabPane } = Tabs
const { confirm } = Modal

const CompanyEventDetails = ({
  selectedEventCompany,
  defaultTab,
  refreshMeetings,
  push,
  back,
  refresh,
  type,
  recordId,
  logType = 'companyEvent',
}) => {
  const [exportModalVisible, setExportModalVisible] = useState(false)
  const [showEditSeatModal, setShowEditSeatModal] = useState(false)
  const handleExportClick = () => {
    setExportModalVisible(true)
  }
  const [selectedSeat, setSelectedSeat] = useState(null)
  const [savingBooths, setSavingBooths] = useState(false)
  const [editingBooths, setEditingBooth] = useState(false)
  const [selectedBooth, setSelectedBooth] = useState(false)

  const handleSelectedSeat = (seat) => {
    setSelectedSeat(seat)
  }
  const canBookMoreBooths = () => isEmpty(selectedEventCompany.boothId)

  const handleBookBooth = () => {
    bookBooth(
      selectedEventCompany.eventId,
      selectedSeat.id,
      selectedEventCompany?.eventCompanyId,
      selectedEventCompany?.company.companyId,
      false,
      selectedSeat.labels.own,
      selectedEventCompany?.eventCompanyBoothFundId
    )
      .then((response) => {
        if (response.data) {
          selectedEventCompany.boothId = selectedEventCompany.boothId
            ? `${selectedEventCompany.boothId},${selectedSeat.id}`
            : selectedSeat.id
          setShowEditSeatModal(false)
          message.success('The booth was booked successfully')
        } else {
          message.error('There was an error while attempting to book booths')
        }
      })
      .catch(() => {
        message.error('Failed to book the booth')
      })
      .finally(() => {
        setSavingBooths(false)
      })
  }

  const handleUpdateBooth = useCallback(() => {
    updateBooth(
      selectedEventCompany?.eventId,
      selectedBooth,
      selectedSeat?.id,
      selectedEventCompany.eventCompanyBoothId,
      selectedEventCompany.eventCompanyBoothFundId
    )
      .then((response) => {
        if (response.data) {
          selectedEventCompany.boothId = selectedEventCompany.boothId.replace(
            selectedBooth,
            selectedSeat.id
          )
          message.success('The booth was booked successfully')
          setShowEditSeatModal(false)
        } else {
          message.error('There was an error while attempting to book booths')
        }
      })
      .catch(() => {
        message.error('Failed to book the booth')
      })
      .finally(() => {
        setSavingBooths(false)
      })
  }, [selectedBooth, selectedEventCompany, selectedSeat?.id])

  const showConfirmationDialog = () => {
    confirm({
      title: (
        <span>{`You are choosing  booth ${selectedSeat.label}
           for  ${selectedEventCompany.company.name}`}</span>
      ),
      icon: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      okButtonProps: { loading: savingBooths },
      onOk() {
        if (editingBooths && !isNil(selectedBooth) && !isEmpty(selectedBooth)) {
          return handleUpdateBooth()
        } else {
          return handleBookBooth()
        }
      },
    })
  }
  const handleBookSeat = () => {
    showConfirmationDialog()
  }

  return (
    <>
      <div className="ScheduleDetails" style={{ padding: '10px' }}>
        <div className="ScheduleDetails-wrapper">
          <Row type="flex" justify="space-between">
            <Col span={11} style={{ display: 'flex' }}>
              <Button
                onClick={back}
                style={{ marginRight: '10px', padding: '0px 10px' }}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>

              <h1
                className="cc-section-header-title"
                style={{ lineHeight: '30px' }}
              >
                {isEmpty(selectedEventCompany.eventName)
                  ? selectedEventCompany.company.name
                  : `${selectedEventCompany.eventName} / ${selectedEventCompany.company.name}`}
              </h1>
            </Col>

            {type !== 'family-member' && (
              <Col span={11} style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={handleExportClick}>
                  <FontAwesomeIcon icon={faFileExport} /> Export Activity Log
                </Button>
              </Col>
            )}
          </Row>
          <Row style={{ paddingTop: '50px' }}>
            <Col span={11}></Col>
            {(type === 'fund' || type == 'sp') &&
              selectedEventCompany.canBookBooths && (
                <Col span={9} style={{ float: 'right' }}>
                  <div
                    className="cc-suite-content-info-wrapper"
                    style={{
                      display: 'flex',
                      width: '100%',
                      marginRight: '24px',
                      zIndex: '100',
                      marginBottom: '0px',
                    }}
                  >
                    {!selectedEventCompany.virtualSummit && (
                      <div style={{ marginLeft: '8px', marginTop: '1%' }}>
                        <span className="cc-heading5">Booth: </span>
                        {!isEmpty(selectedEventCompany.boothId) &&
                          selectedEventCompany.boothId.split(',').map((x) => (
                            <span className="cc-heading4">
                              <Button
                                type="link"
                                size="large"
                                onClick={() => {
                                  setSelectedBooth(x)
                                  setShowEditSeatModal(true)
                                  setEditingBooth(true)
                                }}
                              >
                                {x}
                              </Button>
                            </span>
                          ))}
                      </div>
                    )}
                    {canBookMoreBooths() &&
                      !selectedEventCompany.virtualSummit && (
                        <div className="cc-suite-content-info-icon">
                          <Button
                            onClick={() => {
                              setSelectedBooth('')
                              setEditingBooth(false)
                              setShowEditSeatModal(true)
                            }}
                            type="primary"
                            style={{ marginTop: '1%' }}
                          >
                            Add A Seat
                          </Button>
                        </div>
                      )}
                  </div>
                </Col>
              )}
          </Row>
        </div>
        <Tabs
          defaultActiveKey={defaultTab || '1'}
          size="large"
          className="ScheduleDetails-tabs"
          style={{ top: '-55px' }}
        >
          <TabPane
            className="ScheduleDetails-tabs-tab"
            tab={<span>Overview</span>}
            key="1"
          >
            {type !== 'family-member' && (
              <CompanyEventMeetings
                refresh={refresh}
                refreshMeetings={refreshMeetings}
                contacts={selectedEventCompany.contacts}
                eventParticipantId={selectedEventCompany.eventParticipantId}
                fundEventId={selectedEventCompany.fundEventId}
                eventCompanyId={selectedEventCompany.eventCompanyId}
                companyId={selectedEventCompany.company.companyId}
                fundId={
                  !isNil(selectedEventCompany.fund)
                    ? selectedEventCompany.fund.fundId
                    : 0
                }
                companyContactId={selectedEventCompany.companyContactId}
                eventId={selectedEventCompany.eventId}
                isPast={selectedEventCompany.pastEvent}
                companyName={selectedEventCompany.company.name}
                type={type}
                recordId={recordId}
                eventCompanyBoothFundId={
                  selectedEventCompany?.eventCompanyBoothFundId
                }
                eventCompanyBoothId={selectedEventCompany?.eventCompanyBoothId}
              />
            )}
            <CompanyScheduleDetails
              scheduleDetails={selectedEventCompany}
              refresh={refresh}
            />
          </TabPane>

          {selectedEventCompany.company.categoryId === 1 ? (
            <TabPane
              className="ScheduleDetails-tabs-tab"
              tab={<span>Funds</span>}
              key="4"
            >
              <EventFunds
                fundId={selectedEventCompany.fund.fundId}
              ></EventFunds>
            </TabPane>
          ) : null}
        </Tabs>
        <ExportLogs
          visible={exportModalVisible}
          handleCancel={() => setExportModalVisible(false)}
          logType={logType}
          recordId={selectedEventCompany.eventCompanyId}
          companyId={selectedEventCompany.company.companyId}
          eventId={selectedEventCompany.eventId}
        />
      </div>
      <Modal
        visible={showEditSeatModal}
        onCancel={() => {
          setShowEditSeatModal(false)
          setSelectedSeat(null)
        }}
        width="1100px"
        destroyOnClose
        onOk={handleBookSeat}
        okButtonProps={{
          disabled: isNil(selectedSeat) || !selectedSeat?.selectable,
        }}
      >
        {!selectedEventCompany.virtualSummit && (
          <SeatsContainer
            eventId={selectedEventCompany.eventId}
            selectedEventCompany={selectedEventCompany}
            selectSingleCompany={true}
            handleSelectSeat={handleSelectedSeat}
            currentSeat={selectedBooth}
            allowSelectingBooked={false}
          />
        )}
      </Modal>
    </>
  )
}

CompanyEventDetails.propTypes = {
  selectedEventCompany: PropTypes.shape({
    eventId: PropTypes.number,
    eventName: PropTypes.string,
    eventParticipantId: PropTypes.number.isRequired,
    fundEventId: PropTypes.number.isRequired,
    eventCompanyId: PropTypes.number.isRequired,
    company: PropTypes.object.isRequired,
    suite: PropTypes.string,
    type: PropTypes.string.isRequired,
    recordId: PropTypes.number.isRequired,
  }).isRequired,
  //push: PropTypes.func.isRequired,
}

export default CompanyEventDetails
