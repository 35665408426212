import { useCallback, useEffect, useState } from 'react'
import { Button, Col, Row, Tabs } from 'antd'
import http from '../../utils/api'
import SalesforceLogsItem from './SalesforceLogsItem'
import './SalesforceLogs.less'

const { TabPane } = Tabs
const SalesforceLogs = () => {
  const tabs = [
    { title: 'All', key: '1' },

    { title: 'Company Create', key: '15' },
    { title: 'Company Update', key: '16' },
    { title: 'Company Delete', key: '17' },

    { title: 'Contact Create', key: '18' },
    { title: 'Contact Update', key: '19' },
    { title: 'Contact Delete', key: '20' },

    { title: 'Event Create', key: '21' },
    { title: 'Event Update', key: '22' },

    { title: 'Online Registration Create', key: '24' },
    { title: 'Online Registration Update', key: '25' },
    { title: 'Online Registration Delete', key: '26' },

    { title: 'Event Registration Company', key: '27' },

    { title: 'Event Registration Contact', key: '36' },
  ]
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentKey, setCurrentKey] = useState(1)
  const [currentSearchText, setCurrentSearchText] = useState('')
  const [currentLogs, setCurrentLogs] = useState(null)
  const [currentPlatform, setCurrentPlatform] = useState(0)
  const [currentLogTypes, setCurrentLogTypes] = useState([])
  const [hasNextPage, setHasNextPage] = useState(false)

  const requestLogs = useCallback(
    (
      currentIndex,
      currentKey,
      currentSearchText,
      currentPlatform,
      currentLogTypes
    ) => {
      http
        .post('/log/salesforce', {
          index: currentIndex,
          category: currentKey,
          searchTerm: currentSearchText,
          platform: currentPlatform,
          filters: currentLogTypes,
        })
        .then((res) => {
          setCurrentLogs(res.data.items)
          setHasNextPage(res.data.nextPage)
        })
    },
    []
  )

  const loadLogs = (key) => {
    setCurrentIndex(0)
    setCurrentSearchText('')
    setCurrentKey(key)
    requestLogs(0, key, '', 0, [])
  }

  const handlePrevious = () => {
    setCurrentIndex(currentIndex - 1)
    requestLogs(
      currentIndex - 1,
      currentKey,
      currentSearchText,
      currentPlatform,
      currentLogTypes
    )
  }

  const handleNext = () => {
    setCurrentIndex(currentIndex + 1)
    requestLogs(
      currentIndex + 1,
      currentKey,
      currentSearchText,
      currentPlatform,
      currentLogTypes
    )
  }

  const handleSerach = (value) => {
    setCurrentSearchText(value)
    requestLogs(
      currentIndex,
      currentKey,
      value,
      currentPlatform,
      currentLogTypes
    )
  }

  const onPlatformChange = (platform) => {
    setCurrentPlatform(platform)
    requestLogs(
      currentIndex,
      currentKey,
      currentSearchText,
      platform,
      currentLogTypes
    )
  }

  const onLogTypeChange = (logTypes) => {
    setCurrentLogTypes(logTypes)
    requestLogs(
      currentIndex,
      currentKey,
      currentSearchText,
      currentPlatform,
      logTypes
    )
  }

  useEffect(() => {
    if (currentLogs === null) {
      requestLogs(0, 1, '', 0, [])
    }
  }, [])

  return (
    <>
      <Tabs onChange={(activeKey) => loadLogs(activeKey)}>
        {tabs.map((row) => (
          <TabPane
            tab={<span className="title">{row.title}</span>}
            key={row.key}
          >
            <SalesforceLogsItem
              title={row.title}
              currentCategory={row.key}
              logs={currentLogs}
              handleSearch={handleSerach}
              handlePlatformChange={onPlatformChange}
              handleLogTypeChange={onLogTypeChange}
            />
          </TabPane>
        ))}
      </Tabs>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            disabled={currentIndex <= 0 ? true : false}
            onClick={handlePrevious}
          >
            Previous
          </Button>
          <Button disabled={!hasNextPage} onClick={handleNext}>
            Next
          </Button>
        </Col>
      </Row>
    </>
  )
}

SalesforceLogs.propTypes = {}

export default SalesforceLogs
