import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Modal, message } from 'antd'
import { getEventSmsCount, sendEventSms } from '../../actions/eventSms'

const { TextArea } = Input
const { confirm } = Modal

const gsm7bitChars =
  '@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà'
const gsm7bitExChar = '\\^{}\\\\\\[~\\]|€'
const gsm7bitRegExp = RegExp(`^[${gsm7bitChars}]*$`)
const gsm7bitExRegExp = RegExp(`^[${gsm7bitChars}${gsm7bitExChar}]*$`)
const gsm7bitExOnlyRegExp = RegExp(`^[\\${gsm7bitExChar}]*$`)
const GSM_7BIT = 'GSM_7BIT'
const GSM_7BIT_EX = 'GSM_7BIT_EX'
const UTF16 = 'UTF16'
const messageLength = {
  GSM_7BIT: 160,
  GSM_7BIT_EX: 160,
  UTF16: 70,
}
// const multiMessageLength = {
//   GSM_7BIT: 153,
//   GSM_7BIT_EX: 153,
//   UTF16: 67,
// };

const SummitAttendeeSMS = ({ eventId, eventName, isVisible, handleClose }) => {
  const [textMessage, setMessage] = useState('')
  const [totalCharacters, setTotalCharacters] = useState(160)
  // const [remainingCharacters, setRemainingCharacteres] = useState(160);
  // const [noOfMessages, setNoOfMessages] = useState(1);
  const [characteresUsed, setCharactersUsed] = useState(0)
  const [numberOfContacts, setNumberOfContacts] = useState(0)

  const characterCount = (text) => {
    const encoding = detectEncoding(text)
    let { length } = text
    if (encoding === GSM_7BIT_EX) {
      length += countGsm7bitEx(text)
    }
    const per_message = messageLength[encoding]
    const messages = Math.ceil(length / per_message)
    let remaining = per_message * messages - length
    if (remaining == 0 && messages == 0) {
      remaining = per_message
    }

    if (length > per_message) {
      return
    }
    setTotalCharacters(per_message)
    //setRemainingCharacteres(remaining);
    //setNoOfMessages(messages);
    setCharactersUsed(length)
    setMessage(text)
  }

  const detectEncoding = (text) => {
    switch (false) {
      case text.match(gsm7bitRegExp) == null:
        return GSM_7BIT
      case text.match(gsm7bitExRegExp) == null:
        return GSM_7BIT_EX
      default:
        return UTF16
    }
  }

  const countGsm7bitEx = (text) => {
    let char2
    const chars = (function () {
      let _i, _len
      const _results = []
      for (_i = 0, _len = text.length; _i < _len; _i++) {
        char2 = text[_i]
        if (char2.match(gsm7bitExOnlyRegExp) != null) {
          _results.push(char2)
        }
      }
      return _results
    })()
    return chars.length
  }

  const handleSendMessages = () => {
    sendEventSms(eventId, textMessage).catch(() => {
      message.error('There was an error while generating event SMS')
    })
    message.success(
      'Event SMS are being generated. You can check the progress by Viewing SMS Logs'
    )
  }

  const handleCancel = () => {
    setMessage('')
    setTotalCharacters(160)
    // setRemainingCharacteres(160);
    // setNoOfMessages(1);
    setCharactersUsed(0)
    handleClose()
  }

  useEffect(() => {
    if (isVisible) {
      getEventSmsCount(eventId).then((response) => {
        setNumberOfContacts(response.data)
      })
    }
  }, [eventId, isVisible])

  const showConfirmationDialog = () => {
    confirm({
      title: (
        <span>
          {`You are sending the message ${textMessage} to ${numberOfContacts} contacts`}
        </span>
      ),
      icon: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return handleSendMessages()
      },
    })
  }

  return (
    <Modal
      visible={isVisible}
      onOk={showConfirmationDialog}
      onCancel={handleCancel}
    >
      <div style={{ padding: '10px' }}>
        <div className={'cc-heading4'}>
          Send SMS to All Attendees of event "{eventName}"
        </div>
        <div style={{ paddingTop: '10px', color: 'red' }}>
          Sending message to {numberOfContacts} contacts
        </div>
        <div style={{ width: '100%', paddingTop: '10px' }}>
          <TextArea
            placeholder="Write a message..."
            rows={4}
            value={textMessage}
            onChange={({ target: { value } }) => {
              characterCount(value)
            }}
          />
        </div>
        <div style={{ paddingTop: '10px' }}>
          {characteresUsed} of {totalCharacters} characters used
        </div>
      </div>
    </Modal>
  )
}

SummitAttendeeSMS.propTypes = {
  eventId: PropTypes.number.isRequired,
  eventName: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default SummitAttendeeSMS
