import http from '../utils/api'

export const getCompaniesToOnboard = () => http.get('/on-boarding/companies')

export const getCompanyQuestions = () =>
  http.get('/on-boarding/questions/company')

export const postCompany = () => http.post('/on-boarding/company')

export const getContactQuestions = () =>
  http.get('/on-boarding/questions/contacts/identification')

export const getQuestions = (table, stepNumber, withCompany = false) =>
  http.get(
    `/on-boarding/questions/${table}/${stepNumber}?includeCompany=${withCompany}`
  )

export const getCompanyAllocatorQuestions = (companyId) =>
  http.get(`/on-boarding/questions/allocator-company/${companyId}`)

export const postCompanyAllocatorQuestions = (companyId, questions) =>
  http.post(`/on-boarding/allocator-company/${companyId}`, questions)

export const getCompanyManagerQuestions = (companyId) =>
  http.get(`/on-boarding/questions/manager-company/${companyId}`)

export const postCompanyManagerQuestions = (companyId, questions) =>
  http.post(`/on-boarding/manager-company/${companyId}`, questions)

export const getCompanyServiceProviderQuestions = (companyId) =>
  http.get(`/on-boarding/questions/service-provider-company/${companyId}`)

export const postCompanyServiceProviderQuestions = (companyId, questions) =>
  http.post(`/on-boarding/service-provider-company/${companyId}`, questions)

export const getContactIdentityQuestions = () =>
  http.get('/on-boarding/questions/contact-identity')

export const getContactLocationQuestions = () =>
  http.get('/on-boarding/questions/contact-location')

export const getInvestorStatusQuestions = (companyId, contactId) =>
  http.get(`/on-boarding/questions/investor-status/${companyId}/${contactId}`)

export const getInvestorDetailsQuestions = (companyId, contactId) =>
  http.get(`/on-boarding/questions/investor-details/${companyId}/${contactId}`)

export const getInvestmentStrategies = () =>
  http.get('/on-boarding/questions/investor-details/strategies')

export const postContactBasic = (questions) =>
  http.post('/on-boarding/contact-basic', questions)

export const postContact = (questions) =>
  http.post('/on-boarding/contact', questions)

export const postAllocatorStatus = (companyId, contactId, questions) =>
  http.post(
    `/on-boarding/allocator-status/${companyId}/${contactId}`,
    questions
  )

export const postAllocatorContact = (companyId, contactId, questions) =>
  http.post(
    `/on-boarding/allocator-contact/${companyId}/${contactId}`,
    questions
  )

export const getDomicileCountries = () =>
  http.get('/on-boarding/questions/domicile-countries')
