import {
  LOAD_REPORTS_FAILURE,
  LOAD_REPORTS_REQUEST,
  LOAD_REPORTS_SUCCESS,
  SET_ACTIVE_REPORT,
} from '../constants/types/reports'

const initialState = {
  reportsById: {},
  reportIds: [],
  activeReportId: 0,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_REPORTS_REQUEST:
      return { ...state, loading: true }
    case LOAD_REPORTS_SUCCESS:
      return { ...state, loading: false, ...action.payload }
    case LOAD_REPORTS_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case SET_ACTIVE_REPORT:
      return { ...state, activeReportId: action.payload }
    default:
      return state
  }
}
