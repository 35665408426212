import { useEffect, useState } from 'react'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Divider, Row, Spin, message } from 'antd'
import isNil from 'lodash/isNil'
import { contactRows } from '../../actions/split'
import loaderImage from '../../assets/context_loader.gif'
import http from '../../utils/api'
import LoadingGif from '../LoadingGif'
import DragAndDropContactsSP from './DragAndDropContactsSP'
import './BoothManagementSP.less'

const BoothManagementSP = ({
  match: {
    params: { eventCompanyId },
  },
  history: { goBack },
}) => {
  const [attendingCompanyEvent, setAttendingCompanyEvent] = useState(null)
  const [loading, setLoading] = useState(true)
  const [participantData, setParticipantData] = useState()
  const [availableBooths, setAvailableBooths] = useState()

  useEffect(() => {
    http
      .get(`serviceproviders/scheduledetails/${eventCompanyId}`)
      .then((response) => {
        setAttendingCompanyEvent(response.data)
      })
      .finally(function () {})
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }, [eventCompanyId])

  useEffect(() => {
    if (loading && attendingCompanyEvent) {
      http
        .get(
          `booths/event/sp/${attendingCompanyEvent.eventCompanyId}?api-version=2`
        )
        .then((response) => {
          const b = [
            ...new Set(response.data.map((item) => item.eventCompanyBoothId)),
          ]
          const allAvailableBooths = [
            ...new Set(
              response.data.map(function (item) {
                return {
                  EventCompanyBoothId: item.eventCompanyBoothId,
                  BoothId: item.boothId,
                  MeetingsNo: item.meetingsNo,
                }
              })
            ),
          ]
          const arr = []

          b.map((boothId) => {
            arr.push({
              title: response.data.filter(
                (item) => item.eventCompanyBoothId === boothId
              )[0].boothId,
              items: response.data.filter(
                (item) => item.eventCompanyBoothId === boothId
              )[0].eventCompanyBoothParticipants,
            })
          })

          setParticipantData(arr)
          setAvailableBooths(allAvailableBooths)
        })
        .finally(function () {
          setLoading(false)
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    }
  }, [attendingCompanyEvent, loading])

  useEffect(() => {
    if (!isNil(attendingCompanyEvent)) {
      setParticipantData(participantData)
    }
  }, [attendingCompanyEvent, participantData])

  if (isNil(attendingCompanyEvent)) {
    return (
      <Spin
        spinning={loading}
        style={{ width: '100%', height: '100%', paddingTop: '200px' }}
        indicator={
          <img style={{ width: '100px', height: '100px' }} src={loaderImage} />
        }
      ></Spin>
    )
  }

  return (
    <>
      {attendingCompanyEvent && (
        <LoadingGif spinning={loading}>
          <div className="ScheduleDetails">
            <div>
              <Row>
                <Col span={12} style={{ display: 'flex' }}>
                  <Button
                    onClick={() => goBack()}
                    style={{ marginRight: '10px', padding: '0px 10px' }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>

                  <h1
                    className="cc-section-header-title"
                    style={{ lineHeight: '30px' }}
                  >
                    Booth Management SP
                  </h1>
                </Col>

                <Col span={12} style={{ textAlign: 'right' }}></Col>
              </Row>
            </div>
          </div>
          <Row style={{ marginTop: '20px', backgroundColor: 'white' }}>
            <Col span={24} style={{ padding: '20px' }}>
              <h5 className="heading5">
                {' '}
                {attendingCompanyEvent.company.name}
              </h5>

              <span className="requestSender" style={{ marginTop: '20px' }}>
                EVENT PARTICIPANTS
              </span>

              {contactRows(attendingCompanyEvent.contacts, eventCompanyId)}

              <Divider style={{ marginTop: '20px' }} />

              <DragAndDropContactsSP
                data={participantData}
                availableBooths={availableBooths}
                participants={attendingCompanyEvent.contacts}
                setLoading={() => setLoading(true)}
                companyId={attendingCompanyEvent.company.companyId}
              />

              <Divider style={{ marginTop: '20px' }} />
            </Col>
          </Row>
        </LoadingGif>
      )}
    </>
  )
}

export default BoothManagementSP
