import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Input, Modal, Row } from 'antd'
import { isEmpty } from 'lodash'
import http from '../../utils/api'

const DescriptionModal = ({
  visible,
  handleOk,
  handleCancel,
  company,
  companyId,
  fieldName = '',
}) => {
  const [data, setData] = useState(company)

  const updateData = (field, value) => {
    if (isEmpty(fieldName)) {
      const newState = { ...data }
      newState[field] = value
      setData(newState)
    } else {
      const newState = { ...data }
      const splitField = field.split('.')
      newState[splitField[0]][splitField[1]] = value
      setData(newState)
    }
  }

  const saveChanges = () => {
    http
      .put(`/companies/edit/${companyId}`, data)
      .then((response) => {
        handleOk()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onClose = () => {
    setData(company)
    handleCancel()
  }

  return (
    <Modal
      className="cc-modal-accept"
      title={
        isEmpty(fieldName)
          ? `Edit Description`
          : fieldName === `familyBio`
          ? `Edit Family Bio`
          : ``
      }
      visible={visible}
      okText={`Save Changes`}
      cancelText="Close"
      onOk={saveChanges}
      onCancel={onClose}
      okButtonProps={{
        disabled: false,
        style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
      }}
      centered={true}
      cancelButtonProps={{ disabled: false }}
    >
      <div>
        <div>
          <Row>
            <Col span={24}>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: '18px',
                  paddingBottom: '8px',
                }}
              >
                {isEmpty(fieldName)
                  ? `Edit Description`
                  : fieldName === `familyBio`
                  ? `Edit Family Bio`
                  : ``}
              </div>
            </Col>
            <Col span={24}>
              <Input.TextArea
                autoSize={{ minRows: 10, maxRows: 10 }}
                value={
                  isEmpty(fieldName)
                    ? data.companyDescription
                    : data.companyCFN.familyBio
                }
                onChange={(e) => {
                  updateData(
                    isEmpty(fieldName)
                      ? 'companyDescription'
                      : 'companyCFN.familyBio',
                    e.target.value
                  )
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}

DescriptionModal.GeneralInfoModal = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
  fieldName: PropTypes.string,
}

export default DescriptionModal
