import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Modal, Radio, Row, message } from 'antd'
import { isEmpty, isNil } from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { acceptMeetingAllocator } from '../../actions/contextMeetings'
import { getUserRole } from '../../selectors/auth'
import { isElevated } from '../../utils/meetingActions'
import ContactName from '../ContactName'
import LoadingGif from '../LoadingGif'
import SelectOnlineMeetingDateTime from '../SelectOnlineMeetingDateTime'
import '../../styles/index.less'

const AcceptMeetingComponent = ({
  contacts,
  // buttonType,
  // carryContextPoints,
  companyId,
  visible,
  closeModal,
  company,
  categoryName,
  meetingId,
  eventId,
  onAccept,
  meetingDateTime,
  participants,
  toParticipants,
  initiatorRole,
  isOptimized = false,
  isVirtualSummit = false,
  timezone,
}) => {
  const [acceptingInProgress, setAcceptingInProgress] = useState(false)
  const [selectedDateTime, setSelectedDateTime] = useState({})
  const [duration, setDuration] = useState(30)
  const [selectedHour, setSelectedHour] = useState({})

  const [senderId, setSenderId] = useState(null)

  const userRole = useSelector(getUserRole)

  const handleModalCancel = () => {
    setSelectedDateTime({})
    setSenderId(null)
    closeModal()
  }

  const onRadioChange = (e) => {
    setSenderId(e.target.value)
  }

  const handleAcceptMethod = () => {
    setAcceptingInProgress(true)
    acceptMeetingAllocator(
      companyId,
      senderId,
      meetingId,
      selectedDateTime.startDateTime,
      duration
      //eventId
    )
      .then(() => {
        message.success('The meeting has been accepted.')
        onAccept(selectedDateTime.startDateTime)
      })
      .catch((err) => {
        const allErr = err.response.data.message
        message.error(
          allErr.substring(allErr.indexOf("'") + 1, allErr.indexOf("'."))
        )
      })
      .finally(() => {
        setAcceptingInProgress(false)
        handleModalCancel()
      })
  }
  const onDurationChanged = (value) => {
    setDuration(value)
  }

  const onTimeChanged = (changed, newDate) => {
    if (changed === 'date') {
      setSelectedHour({})
    }
    if (changed === 'hour') {
      setSelectedHour(newDate)
    }
    if (!isNil(newDate)) {
      setSelectedDateTime({
        startDateTime: newDate.startTime,
        endDateTime: newDate.endTime,
      })
    }
  }

  useEffect(() => {
    if (contacts && contacts.length === 1) {
      setSenderId(contacts[0].contactId)
    }
  }, [contacts])

  const haveToChooseMeetingTime =
    (!isVirtualSummit && isOptimized) ||
    (isVirtualSummit &&
      isNil(meetingDateTime) &&
      isElevated(userRole, categoryName))

  return (
    <Modal
      mask={false}
      className="cc-modal-accept"
      destroyOnClose
      title="Accept Meeting Request"
      visible={visible}
      okText="Accept Meeting"
      onOk={handleAcceptMethod} // needs to be changed in order to actually accept the request
      onCancel={handleModalCancel}
      okButtonProps={{
        loading: acceptingInProgress,
        disabled: isNil(senderId)
          ? true
          : haveToChooseMeetingTime
          ? isEmpty(selectedDateTime) || isEmpty(selectedHour)
            ? true
            : false
          : false,
        style: { backgroundColor: '#24C477', borderStyle: 'none' },
      }}
      cancelButtonProps={{ disabled: false }}
      width="758px"
      bodyStyle={{ padding: '30px' }}
    >
      <Row>
        <p>Accept meeting request with {company} on behalf of:</p>
        <Col span="24">
          <Radio.Group
            onChange={onRadioChange}
            value={senderId}
            style={{ marginLeft: '15px' }}
          >
            {contacts &&
              contacts.map((row) =>
                row.participantStatusId === 1 ? (
                  <div style={{ paddingBottom: '6px' }}>
                    <Radio value={row.contactId} />
                    <ContactName
                      style={{ display: 'inline' }}
                      name={row.contactName}
                      imageUrl={row.imageURL}
                    />
                  </div>
                ) : (
                  <span />
                )
              )}
          </Radio.Group>
        </Col>
      </Row>

      {!haveToChooseMeetingTime ? (
        <p>
          {moment(meetingDateTime).isValid() && !isNil(meetingDateTime)
            ? ` on
           ${moment
             .utc(meetingDateTime)
             .local()
             .format('MMMM D, YYYY [at] h:mm A')}?`
            : '?'}
        </p>
      ) : (
        <LoadingGif spinning={acceptingInProgress}>
          <Row>
            <SelectOnlineMeetingDateTime
              onTimeChanged={onTimeChanged}
              onDurationChanged={onDurationChanged}
              toParticipants={toParticipants}
              participants={participants}
              eventId={eventId}
              physicalSummit={!isVirtualSummit && !isNil(eventId)}
              defaultTimezone={timezone}
              meetingId={meetingId}
            />
          </Row>
        </LoadingGif>
      )}
    </Modal>
  )
}

AcceptMeetingComponent.propTypes = {
  contacts: PropTypes.array.isRequired,
  contactId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  meetingType: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  buttonType: PropTypes.string,
  meetingId: PropTypes.number.isRequired,
  onAccept: PropTypes.func.isRequired,
  eventId: PropTypes.number,
  meetingDateTime: PropTypes.string,
  participants: PropTypes.array.isRequired,
  toParticipants: PropTypes.array.isRequired,
  categoryName: PropTypes.string,
  initiatorRole: PropTypes.string,
}

export default AcceptMeetingComponent
