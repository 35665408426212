import {
  EMPTY_CALENDAR,
  FETCH_ALL_REQUESTED_MEETINGS,
  FETCH_CALENDAR_MEETINGS,
  FETCH_CONFIRMED_MEETINGS,
  FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID,
  FETCH_DECLINED_MEETINGS,
  FETCH_DECLINED_MEETINGS_COMPANYEVENTID,
  FETCH_ONLINE_CONFIRMED_MEETINGS,
  FETCH_ONLINE_DECLINED_MEETINGS,
  FETCH_ONLINE_MEETING_PARTICIPANTS,
  FETCH_ONLINE_REQUESTED_MEETINGS,
  FETCH_ONLINE_SENT_REQUESTS,
  FETCH_REQUESTED_MEETINGS,
  FETCH_REQUESTED_MEETINGS_COMPANYEVENTID,
  FETCH_SENT_REQUESTS,
  FETCH_SENT_REQUESTS_COMPANYEVENTID,
  FETCH_UPCOMING_MEETINGS,
} from '../constants/types'
import http from '../utils/api'

export const sendOnlineRequestToFund = (
  companyId,
  toCompanyId,
  meetingDateTime,
  meetingEndDateTime,
  contactIds,
  toContactIds,
  fundId
) =>
  http.post(
    `meetings/online/fromallocator/${companyId}/tofund/${toCompanyId}`,
    {
      meetingDateTime,
      meetingEndDateTime,
      contactIds,
      toContactIds,
      fundId,
    }
  )

export const sendOnlineRequestToAllocator = (
  companyId,
  toCompanyId,
  meetingStartTime,
  meetingEndDateTime,
  contactIds,
  toContactIds,
  fundId
) =>
  http.post(
    `meetings/online/fromfund/${companyId}/toallocator/${toCompanyId}`,
    {
      meetingDateTime: meetingStartTime,
      meetingEndDateTime,
      contactIds,
      toContactIds,
      fundId,
    }
  )

export const fetchUpcomingMeetings =
  (pagination, filters, sorter, searchTerm) => (dispatch) => {
    http
      .post('/meetings/upcoming', {
        pagination,
        filters,
        sorter,
        searchTerm,
      })
      .then((response) =>
        dispatch({
          type: FETCH_UPCOMING_MEETINGS,
          payload: response.data.result,
        })
      )
  }

export const fetchAllMeetingRequestsByCompany =
  (pagination, filters, sorter, searchTerm) => (dispatch) => {
    http
      .post('/meetings/requested', {
        pagination,
        filters,
        sorter,
        searchTerm,
      })
      .then((response) =>
        dispatch({
          type: FETCH_ALL_REQUESTED_MEETINGS,
          payload: response.data.result,
        })
      )
  }

export const fetchMeetingRequestsByCompany =
  (meetingType, pagination, filters, sorter, searchTerm) => (dispatch) => {
    http
      .post(`/meetings/${meetingType}/requested`, {
        pagination,
        filters,
        sorter,
        searchTerm,
      })
      .then((response) => {
        meetingType === 'online'
          ? dispatch({
              type: FETCH_ONLINE_REQUESTED_MEETINGS,
              payload: response.data.result,
            })
          : dispatch({
              type: FETCH_REQUESTED_MEETINGS,
              payload: response.data.result,
            })
      })
  }

export const fetchSentRequestsByCompany =
  (meetingType, pagination, filters, sorter, searchTerm) => (dispatch) => {
    http
      .post(`/meetings/${meetingType}/sent`, {
        pagination,
        filters,
        sorter,
        searchTerm,
      })
      .then((response) => {
        meetingType === 'online'
          ? dispatch({
              type: FETCH_ONLINE_SENT_REQUESTS,
              payload: response.data.result,
            })
          : dispatch({
              type: FETCH_SENT_REQUESTS,
              payload: response.data.result,
            })
      })
  }

export const fetchConfirmedMeetingsByCompany =
  (meetingType, pagination, filters, sorter, searchTerm) => (dispatch) => {
    http
      .post(`/meetings/${meetingType}/confirmed`, {
        pagination,
        filters,
        sorter,
        searchTerm,
      })
      .then((response) => {
        meetingType === 'online'
          ? dispatch({
              type: FETCH_ONLINE_CONFIRMED_MEETINGS,
              payload: response.data.result,
            })
          : dispatch({
              type: FETCH_CONFIRMED_MEETINGS,
              payload: response.data.result,
            })
      })
  }

export const fetchDeclinedMeetingRequestsByCompany =
  (meetingType, pagination, filters, sorter, searchTerm) => (dispatch) => {
    http
      .post(`/meetings/${meetingType}/declined`, {
        pagination,
        filters,
        sorter,
        searchTerm,
      })
      .then((response) => {
        meetingType === 'online'
          ? dispatch({
              type: FETCH_ONLINE_DECLINED_MEETINGS,
              payload: response.data.result,
            })
          : dispatch({
              type: FETCH_DECLINED_MEETINGS,
              payload: response.data.result,
            })
      })
  }

export const fetchMeetingRequestsByCompanyEventId =
  (companyEventId, pagination, filters, sorter, searchTerm) => (dispatch) => {
    http
      .post(`/meetings/summit/requested/${companyEventId}`, {
        pagination,
        filters,
        sorter,
        searchTerm,
      })
      .then((response) =>
        dispatch({
          type: FETCH_REQUESTED_MEETINGS_COMPANYEVENTID,
          payload: response.data.result,
        })
      )
  }

export const fetchSentRequestsByCompanyEventId =
  (companyEventId, pagination, filters, sorter, searchTerm) => (dispatch) => {
    http
      .post(`/meetings/summit/sent/${companyEventId}`, {
        pagination,
        filters,
        sorter,
        searchTerm,
      })
      .then((response) =>
        dispatch({
          type: FETCH_SENT_REQUESTS_COMPANYEVENTID,
          payload: response.data.result,
        })
      )
  }

export const fetchConfirmedMeetingsByCompanyEventId =
  (companyEventId, pagination, filters, sorter, searchTerm) => (dispatch) => {
    http
      .post(`/meetings/summit/confirmed/${companyEventId}`, {
        pagination,
        filters,
        sorter,
        searchTerm,
      })
      .then((response) =>
        dispatch({
          type: FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID,
          payload: response.data.result,
        })
      )
  }

export const fetchDeclinedMeetingRequestsByCompanyEventId =
  (companyEventId, pagination, filters, sorter, searchTerm) => (dispatch) => {
    http
      .post(`/meetings/summit/declined/${companyEventId}`, {
        pagination,
        filters,
        sorter,
        searchTerm,
      })
      .then((response) =>
        dispatch({
          type: FETCH_DECLINED_MEETINGS_COMPANYEVENTID,
          payload: response.data.result,
        })
      )
  }

export const fetchCalendarMeetings = (meetingTypes) => (dispatch) =>
  http.get(`/meetings/calendar/${meetingTypes}`).then((response) =>
    dispatch({
      type: FETCH_CALENDAR_MEETINGS,
      payload: response.data.result,
    })
  )

export const emptyCalendar = () => (dispatch) =>
  dispatch({
    type: EMPTY_CALENDAR,
    payload: [],
  })

export const fetchOnlineMeetingParticipants = (onlineMeetingId) => (dispatch) =>
  http
    .get(`/meetings/online/participants/${onlineMeetingId}`)
    .then((response) =>
      dispatch({
        type: FETCH_ONLINE_MEETING_PARTICIPANTS,
        payload: response.data.result,
      })
    )

export const sendSummitMeetingRequest = (
  eventId,
  toCompanyCategory,
  toCompanyEventId,
  message,
  fundId,
  meetingTimeId,
  userId,
  companyId,
  fromCompanyEventId,
  fromCompanyType,
  carryContextPoints
) =>
  http.post(
    `/events/${eventId}/meetwith/${toCompanyCategory}/${toCompanyEventId}/${userId}/${companyId}/${fromCompanyEventId}`,
    {
      message,
      fundId,
      meetingTimeId,
      fromCompanyType,
      carryContextPoints,
    }
  )

export const sendOnlineMeetingRequest = (
  toCompanyCategory,
  toCompanyId,
  message,
  fundId,
  meetingDateTime,
  meetingEndDateTime,
  contactIds,
  toContactIds
) =>
  http.post(`/online/meetwith/${toCompanyCategory}/${toCompanyId}`, {
    message,
    fundId,
    meetingDateTime,
    meetingEndDateTime,
    contactIds,
    toContactIds,
  })

export const cancelMeeting = (meetingId) =>
  http.delete(`/meetings/${meetingId}`)

export const cancellationReasons = () =>
  http.get('/meeting/cancellation/reasons')

export const newCancelMeeting = (meetingId) =>
  http.delete(`/events/${meetingId}/meeting`)

export const cancelOnlineMeeting = (
  meetingId,
  contactId,
  companyId,
  cancellationReasonId,
  cancellationReasonText
) =>
  http.delete(`/meeting/${meetingId}/cancel/${contactId}/${companyId}`, {
    data: { cancellationReasonId, cancellationReasonText },
  })

export const declineMeeting = (meetingId, contactId) =>
  http.put(`/meeting/${meetingId}/decline/${contactId}`)

export const declineOnlineMeeting = (meetingId) =>
  http.put(`/meetings/online/${meetingId}/decline`)

export const acceptMeeting = (meetingId, contactId, meetingTimeId, companyId) =>
  http.put(
    `/meeting/${meetingId}/accept/${contactId}/${meetingTimeId}/${companyId}`,
    {
      meetingTimeId,
    }
  )

export const acceptOnlineMeeting = (meetingId) =>
  http.put(`/meetings/online/${meetingId}/accept`)

export const rescheduleMeeting = (contactId, meetingId, meetingTimeId) =>
  http.put(`/meeting/${meetingId}/reschedule/${contactId}`, { meetingTimeId })

export const newRescheduleMeeting = (
  companyId,
  senderId,
  meetingId,
  meetingTimeId
) =>
  http.put(`/companies/${companyId}/${senderId}/${meetingId}/reschedule`, {
    meetingTimeId,
  })

export const rescheduleOnlineMeeting = (
  onlineMeetingId,
  meetingStartTime,
  meetingEndDateTime
) =>
  http.put(`/meetings/online/${onlineMeetingId}/reschedule`, {
    meetingDateTime: meetingStartTime,
    meetingEndDateTime,
  })
