import './Profiles.less'

const Profiles = (props) => {
  let profiles = props.profiles
  let maxImages = props.maxImages
  let slicedImages = false

  if (typeof maxImages === 'undefined') maxImages = 5
  if (props.profiles.length > maxImages) {
    profiles = props.profiles.slice(0, maxImages)
    slicedImages = true
  }

  let indexCtr = 0
  return (
    <span className="round-profile-imagegroup">
      {profiles.map((x) => (
        <img
          alt=""
          style={{ position: 'relative', zIndex: 999 - indexCtr++ }}
          className="round-profile-thumbnail"
          src={x}
        />
      ))}
      <span>{slicedImages === true ? '•••' : ''}</span>
    </span>
  )
}

export default Profiles
