import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import { isEmpty, isNil } from 'lodash'
import { getByZip } from '../../../actions/geography'

const { Search } = Input

const SearchField = ({
  id = null,
  name,
  value,
  className,
  filterBy,
  transform,
  onFocus,
  onBlur,
  onChange,
  onSearchResults,
}) => {
  const [searching, setSearching] = useState(false)

  const handleSearch = useCallback(
    (val) => {
      setSearching(true)
      getByZip(val, filterBy)
        .then((response) => {
          const { results } = response.data
          const extractedResults = transform(results)
          if (!isNil(extractedResults) && !isEmpty(extractedResults)) {
            onSearchResults(extractedResults)
          }
        })
        .finally(() => setSearching(false))
    },
    [filterBy, transform, onSearchResults]
  )

  return (
    <Search
      id={isNil(id) ? name : id}
      name={name}
      className={className}
      value={value}
      loading={searching}
      onFocus={onFocus}
      onBlur={(e) => console.log('BLUR:', e)}
      onChange={onChange}
      onSearch={handleSearch}
    />
  )
}

SearchField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  filterBy: PropTypes.object,
  transform: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onSearchResults: PropTypes.func,
}

export default SearchField
