import { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { Formik } from 'formik'
import { get, map } from 'lodash'
import http from '../../utils/api'
import { getInitialValues } from '../DynamicForm/DynamicForm'
import FormField from '../Forms/Fields/FormField'
import OnboardingAddressSection from '../OnboardingComponents/OnboardingAddressSection'
import { ServiceProviderValidatorShape } from '../ServiceProviderWizard/ServiceProviderValidatorShape'

const AddressModal = ({
  visible,
  handleOk,
  handleCancel,
  company,
  companyId,
  questions,
  answers,
  questionsWithOptions,
  category,
}) => {
  const [, setData] = useState(company)
  const [, setValueChanged] = useState(false)

  const validationSchema = ServiceProviderValidatorShape(questions)

  const address = {
    ...company,
    zipCode: company.postalCode,
  }
  const [addressValue, setAddressValue] = useState(address)

  const initialValues = getInitialValues(
    questions,
    answers,
    questionsWithOptions
  )

  const saveChanges = (values) => {
    http
      .put(`/companies/edit/${companyId}`, addressValue)
      .then(() => {
        handleOk()
      })
      .catch((error) => {
        console.log(error)
      })

    if (category === 'serviceprovider') {
      const answersData = map(questions, (q) => ({
        serviceProviderQuestionId: q.serviceProviderQuestionId,
        shortName: q.shortName,
        serviceProviderQuestionAnswerIds: questionsWithOptions.has(
          q.questionTypeId
        )
          ? get(values, q.shortName, [])
          : [],
        responseText: !questionsWithOptions.has(q.questionTypeId)
          ? values[q.shortName]
          : null,
      }))

      http
        .patch(`/serviceproviders/0/${companyId}`, {
          answers: answersData,
        })
        .then(() => {
          handleOk()
        })
    }
  }

  const onClose = () => {
    setData(company)
    handleCancel()
  }

  const addressChanged = (companyValue) => {
    //company.state = company.stateProvince;
    console.log('company', companyValue)
    companyValue.postalCode = companyValue.zipCode
    setAddressValue(companyValue)
    console.log('addressValue', addressValue)
    // setData(company);
    setValueChanged(true)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        saveChanges(values)
      }}
    >
      {({
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Modal
          className="cc-modal-accept"
          title={'Edit Address'}
          visible={visible}
          okText={'Save Changes'}
          cancelText="Close"
          onOk={handleSubmit}
          onCancel={onClose}
          okButtonProps={{
            disabled: false,
            style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
          }}
          centered={true}
          cancelButtonProps={{ disabled: false }}
        >
          <div style={{ padding: '14px 32px 0px 32px' }}>
            <div>
              <OnboardingAddressSection
                value={addressValue}
                inputNamePrefix="Company."
                onChange={addressChanged}
              />
            </div>
            <div style={{ paddingBottom: '55px' }}>
              {questions
                .filter((q) => q.stepNumber == 7)
                .map((question, key) => (
                  <div key={key}>
                    <FormField
                      key={question.serviceProviderQuestionId}
                      question={question}
                      values={values}
                      errors={errors}
                      //dependencies={fieldDependencies}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                  </div>
                ))}
            </div>
          </div>
        </Modal>
      )}
    </Formik>
  )
}

AddressModal.GeneralInfoModal = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
}

export default AddressModal
