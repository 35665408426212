import { useEffect, useState } from 'react'
import { Checkbox } from 'antd'
import CheckboxGroup from 'antd/lib/checkbox/Group'
import { isNil } from 'lodash'
import { getDomicileCountries } from '../../actions/onboarding'

const onshore = 'Onshore (US)'
const offshore = 'Offshore'

const DomicileSection = ({ onChange, value, isRequired }) => {
  const transformValueToDomicile = () => {
    if (value === null) return []
    const result = []
    const valueSplit = value.split(',')
    if (!isNil(valueSplit.find((x) => x === onshore))) result.push(onshore)
    if (!isNil(valueSplit.find((x) => x === offshore))) result.push(offshore)
    return result
  }

  const transformValueToCountries = () => {
    const domicile = transformValueToDomicile()
    if (!isNil(domicile.find((x) => x === offshore))) {
      const valueSplit = value.split(',')
      return valueSplit.filter((x) => x !== onshore && x !== offshore)
    }
    return []
  }

  const [countries, setCountries] = useState()
  const [countriesLoading, setCountriesLoading] = useState(false)
  const [selectedDomicile, setSelectedDomicile] = useState(
    transformValueToDomicile()
  )
  const [selectedCountries, setSelectedCountries] = useState(
    transformValueToCountries()
  )
  const [allCountriesSelected, setAllCountriesSelected] = useState(false)

  if (isNil(countries) && countriesLoading === false) {
    setCountriesLoading(true)
    getDomicileCountries().then((response) => {
      setCountries(response.data)
      setCountriesLoading(false)
    })
  }

  const onAllChanged = () => {
    let result = ''
    if (!isNil(selectedDomicile.find((x) => x === onshore))) result = onshore

    if (!isNil(selectedDomicile.find((x) => x === offshore))) {
      result = result === '' ? offshore : result + ',' + offshore
      if (selectedCountries.length > 0)
        result = result + ',' + selectedCountries.join(',')
    }

    onChange(result)
  }

  useEffect(onAllChanged, [selectedDomicile])
  useEffect(onAllChanged, [selectedCountries])

  const onDomicileChanged = (value) => {
    const hasOffshore = isNil(value.find((x) => x === offshore))
    if (hasOffshore) {
      setSelectedCountries([])
    }
    setSelectedDomicile(value)
  }

  const onCountryChanged = (value) => {
    setSelectedCountries(value)
    setAllCountriesSelected(false)
  }

  const allCountriesChanged = (e) => {
    const checked = e.target.checked
    setAllCountriesSelected(checked)
    if (checked) {
      setSelectedCountries(countries)
    } else {
      setSelectedCountries([])
    }
  }

  const options = [onshore, offshore]

  return (
    <div>
      <div className="cc-generic-form-checkbox-group">
        <span className="cc-heading6 cc-generic-form-checkbox-header">
          Fund Domicile Interest {isRequired === true ? ' *' : ''}
        </span>
        <Checkbox.Group
          onChange={onDomicileChanged}
          value={selectedDomicile}
          options={options.map((x) => ({
            label: x,
            value: x,
          }))}
        ></Checkbox.Group>

        {isNil(selectedDomicile.find((x) => x === offshore)) ? null : (
          <div className="cc-domicile-countries">
            <Checkbox
              className="cc-domicile-all-countries-checkbox"
              onChange={allCountriesChanged}
              checked={allCountriesSelected}
            >
              All Countries
            </Checkbox>
            <Checkbox.Group
              onChange={onCountryChanged}
              value={selectedCountries}
              options={
                countries &&
                countries.map((x) => ({
                  label: x,
                  value: x,
                }))
              }
            ></Checkbox.Group>
          </div>
        )}
      </div>
    </div>
  )
}

export default DomicileSection
