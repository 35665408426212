import { useEffect, useState } from 'react'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Modal, Radio, Row, Select } from 'antd'
import isNil from 'lodash/isNil'
import { useHistory } from 'react-router-dom'
import { isNumericLiteral } from 'typescript'
import {
  getMandatesActivityGraphList,
  getMandatesActivityList,
} from '../../actions/mandates'
import Activities from '../../components/ActivityDetails/Activities'
import ContextTable from '../../components/ContextTable'
import http from '../../utils/api'
import {
  getMandatesActivityColumns,
  getMandatesColumns,
} from '../../utils/columns'
import MandateProfileModal from '../mandates/MandateProfileModal'
import './Mandates.less'

const { OptGroup } = Select
const RadioGroup = Radio.Group

const NO_CONTACT_ID = -1

const ContactChoice = ({ id, label }) => (
  <>
    <Radio value={id}>{label}</Radio>
    <br />
  </>
)

const Mandates = ({
  displayTitle,
  displayAddButton,
  tableTitle,
  getMandateListData,
  companyIdProp = undefined,
  newMandateForCompanyTitle = 'Choose a Company',
  newMandateForCompanyContacts = [],
  companyCategory = '',
}) => {
  const [refreshData, setRefreshData] = useState(false)
  const [columns, setColumns] = useState(null)
  const [showMandateActivity, setShowMandateActivity] = useState(false)
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false)
  const [mandateId, setMandateId] = useState(null)
  const [mandateName, setMandateName] = useState(null)
  const [showMandateCompanyModal, setshowMandateCompanyModal] = useState(false)
  const [companyId, setcompanyId] = useState(companyIdProp)
  const [companies, setCompanies] = useState([])
  const [contactId, setcontactId] = useState(null)
  const [contacts, setContacts] = useState(newMandateForCompanyContacts)
  const [showContacts, setShowContacts] = useState(false)

  const { push } = useHistory()

  const showMandateActivityFromGrid = (mandateId, name) => {
    setMandateId(mandateId)
    setMandateName(name)
    setShowMandateActivity(!showMandateActivity)
  }

  const showMandateViewDetails = (mandateId, name) => {
    setMandateId(mandateId)
    setMandateName(name)
    setIsProfileModalVisible(!isProfileModalVisible)
  }

  const refresh = () => {
    setRefreshData(!refreshData)
  }

  useEffect(() => {
    if (contacts && companyId) {
      setShowContacts(true)
    }
  }, [contacts, companyId])

  useEffect(() => {
    const cols = getMandatesColumns(
      refresh,
      showMandateActivityFromGrid,
      showMandateViewDetails
    )
    setColumns(cols)
  }, [refreshData])

  const handleFilterOption = (e) => {
    if (e.length > 2)
      http.post(`/companies/search/online/-1?keyword=${e}`).then((res) => {
        setCompanies(res.data.response)
      })
  }

  const handleSelectChange = (e) => {
    let selectedCompany = companies.allocators.filter(
      (obj) => obj.company.companyId === e
    )
    if (selectedCompany.length === 0) {
      selectedCompany = companies.serviceProviders.filter(
        (obj) => obj.company.companyId === e
      )
    }

    setcompanyId(selectedCompany[0].company.companyId)

    http
      .get(`/companies/` + selectedCompany[0].company.companyId + `/contacts`)
      .then((res) => {
        setContacts(res.data)
      })
  }

  return (
    <div>
      <Row
        className={
          displayTitle || displayAddButton
            ? 'deal-row-with-padding'
            : 'deal-row-with-no-padding'
        }
      >
        <Col span={12}>
          {displayTitle && (
            <h1
              className="cc-section-header-title"
              style={{ lineHeight: '30px' }}
            >
              Mandates
            </h1>
          )}
        </Col>
        {displayAddButton && (
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button
              block
              size="large"
              style={{
                background: '#2FC5D2',
                boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.06);',
                color: 'white',
                width: '208px',
              }}
              onClick={(e) => {
                setshowMandateCompanyModal(true)
              }}
            >
              <span style={{ background: '#2FC5D2', opacity: '1' }}>
                <FontAwesomeIcon icon={faPlus} /> New Mandate
              </span>
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col id="mandatesList" span={24}>
          {columns && (
            <ContextTable
              tableTitle={tableTitle}
              columns={columns}
              dataMethod={getMandateListData}
              refresh={refreshData}
              refreshData={refreshData}
              companyId={companyIdProp}
              // scroll={{ x: 1900 }}
            />
          )}
        </Col>
      </Row>
      {showMandateCompanyModal && (
        <Modal
          className="cc-modal-accept"
          title={newMandateForCompanyTitle}
          visible={showMandateCompanyModal}
          centered={true}
          destroyOnClose
          okText="Continue"
          cancelText="Close"
          okButtonProps={{
            disabled: isNil(companyId) || isNil(contactId),
            style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
          }}
          cancelButtonProps={{ disabled: false }}
          onCancel={() => {
            setshowMandateCompanyModal(false)
          }}
          onOk={() => {
            setshowMandateCompanyModal(false)
            const mandateContactId =
              contactId === NO_CONTACT_ID ? null : contactId

            if (companyCategory === '') {
              push('/create-mandate/', {
                companyId: companyId,
                contactId: mandateContactId,
              })
            } else {
              push('/create-mandate/', {
                companyId: companyId,
                contactId: mandateContactId,
                submitPath:
                  '/companies' +
                  '/' +
                  companyCategory +
                  '/' +
                  companyId +
                  '/11',
              })
            }
          }}
        >
          {isNil(companyIdProp) && (
            <div className="form-container">
              <Select
                showSearch
                size="large"
                style={{ width: '100%', height: '48px' }}
                placeholder="Search here"
                showArrow={true}
                optionFilterProp="children"
                onChange={handleSelectChange}
                onSearch={handleFilterOption}
                filterOption={false}
                value={companyId}
                disabled={false}
              >
                <OptGroup label="Allocator Companies">
                  {companies.allocators &&
                    companies.allocators.map((row) => {
                      if (row.company.category.toLowerCase() === 'allocator') {
                        return (
                          <Select.Option value={row.company.companyId}>
                            {row.company.name}
                          </Select.Option>
                        )
                      }
                    })}
                </OptGroup>
                <OptGroup label="Service Providers">
                  {companies &&
                    companies.serviceProviders &&
                    companies.serviceProviders.map((row) => {
                      if (
                        row.company.category.toLowerCase() ===
                        'service provider'
                      ) {
                        return (
                          <Select.Option
                            value={row.company.companyId}
                            key={row.company.companyId}
                          >
                            {row.company.name}
                          </Select.Option>
                        )
                      }
                    })}
                </OptGroup>
              </Select>
            </div>
          )}
          <div
            id="userDiv"
            style={{ display: showContacts ? 'block' : 'none' }}
          >
            <p>Select user</p>
            <div>
              <RadioGroup
                value={contactId}
                onChange={(e) => {
                  setcontactId(e.target.value)
                }}
              >
                <ContactChoice id={NO_CONTACT_ID} label="No User" />
                {contacts.map((row) => (
                  <ContactChoice
                    id={row.contactId}
                    label={`${row.firstName} ${row.lastName}`}
                  />
                ))}
              </RadioGroup>
            </div>
          </div>
        </Modal>
      )}
      {showMandateActivity && (
        <Activities
          showActivityModal={showMandateActivity}
          moduleCode="Mandate"
          entityId={mandateId}
          entityName={mandateName}
          handleActivityModalClose={() => setShowMandateActivity(false)}
          getActivityColumns={getMandatesActivityColumns}
          getActivityData={getMandatesActivityList(mandateId)}
          getActivityGraphData={getMandatesActivityGraphList(mandateId)}
          graphData={'mandateDate*mandateViews'}
        />
      )}
      {isProfileModalVisible && (
        <MandateProfileModal
          mandateId={mandateId}
          visible={isProfileModalVisible}
          onCancel={() => setIsProfileModalVisible(false)}
          hideActions={true}
          canApplyToMandate={false}
          companyId={companyId}
          companyCategory={companyCategory}
        />
      )}{' '}
    </div>
  )
}

Mandates.propTypes = {}

export default Mandates
