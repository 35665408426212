export const RESET_APP = 'RESET_APP'

export const FETCH_MEETINGS_START = 'FETCH_MEETINGS_START'
export const FETCH_MEETINGS_SUCCESS = 'FETCH_MEETINGS_SUCCESS'
export const FETCH_MEETINGS_ERROR = 'FETCH_MEETINGS_ERROR'
export const FETCH_MEETINGS_DONE = 'FETCH_MEETINGS_DONE'

export const FETCH_COLLEAGUES_START = 'FETCH_COLLEAGUES_START'
export const FETCH_COLLEAGUES_SUCCESS = 'FETCH_COLLEAGUES_SUCCESS'
export const FETCH_COLLEAGUES_ERROR = 'FETCH_COLLEAGUES_ERROR'
export const FETCH_COLLEAGUES_DONE = 'FETCH_COLLEAGUES_DONE'

export const FETCH_FUNDS_START = 'FETCH_FUNDS_START'
export const FETCH_FUNDS_SUCCESS = 'FETCH_FUNDS_SUCCESS'
export const FETCH_FUNDS_ERROR = 'FETCH_FUNDS_ERROR'
export const FETCH_FUNDS_DONE = 'FETCH_FUNDS_DONE'

export const FETCH_UPCOMING_MEETINGS = 'FETCH_UPCOMING_MEETINGS'
export const FETCH_ALL_REQUESTED_MEETINGS = 'FETCH_ALL_REQUESTED_MEETINGS'
export const FETCH_SENT_REQUESTS = 'FETCH_SENT_REQUESTS'
export const FETCH_REQUESTED_MEETINGS = 'FETCH_REQUESTED_MEETINGS'
export const FETCH_CONFIRMED_MEETINGS = 'FETCH_CONFIRMED_MEETINGS'
export const FETCH_DECLINED_MEETINGS = 'FETCH_DECLINED_MEETINGS'
export const FETCH_ONLINE_SENT_REQUESTS = 'FETCH_ONLINE_SENT_REQUESTS'
export const FETCH_ONLINE_REQUESTED_MEETINGS = 'FETCH_ONLINE_REQUESTED_MEETINGS'
export const FETCH_ONLINE_CONFIRMED_MEETINGS = 'FETCH_ONLINE_CONFIRMED_MEETINGS'
export const FETCH_ONLINE_DECLINED_MEETINGS = 'FETCH_ONLINE_DECLINED_MEETINGS'
export const FETCH_CALENDAR_MEETINGS = 'FETCH_CALENDAR_MEETINGS'
export const FETCH_REQUESTED_MEETINGS_COMPANYEVENTID =
  'FETCH_REQUESTED_MEETINGS_COMPANYEVENTID'
export const FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID =
  'FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID'
export const FETCH_SENT_REQUESTS_COMPANYEVENTID =
  'FETCH_SENT_REQUESTS_COMPANYEVENTID'
export const FETCH_DECLINED_MEETINGS_COMPANYEVENTID =
  'FETCH_DECLINED_MEETINGS_COMPANYEVENTID'

export const FETCH_ONLINE_MEETING_PARTICIPANTS =
  'FETCH_ONLINE_MEETING_PARTICIPANTS'

export const EMPTY_CALENDAR = 'EMPTY_CALENDAR'

export const FETCH_INVESTORS_LIST_START = 'FETCH_INVESTORS_LIST_START'
export const FETCH_INVESTORS_LIST_SUCCESS = 'FETCH_INVESTORS_LIST_SUCCESS'
export const FETCH_INVESTORS_LIST_ERROR = 'FETCH_INVESTORS_LIST_ERROR'
export const FETCH_INVESTORS_LIST_DONE = 'FETCH_INVESTORS_LIST_DONE'

export const FETCH_INVESTORS_CATEGORIES_START =
  'FETCH_INVESTORS_CATEGORIES_START'
export const FETCH_INVESTORS_CATEGORIES_SUCCESS =
  'FETCH_INVESTORS_CATEGORIES_SUCCESS'
export const FETCH_INVESTORS_CATEGORIES_ERROR =
  'FETCH_INVESTORS_CATEGORIES_ERROR'
export const FETCH_INVESTORS_CATEGORIES_DONE = 'FETCH_INVESTORS_CATEGORIES_DONE'

export const FETCH_INVESTORS_INVESTMENT_SIZES_START =
  'FETCH_INVESTORS_MINIMUM_SIZES_START'
export const FETCH_INVESTORS_INVESTMENT_SIZES_SUCCESS =
  'FETCH_INVESTORS_MINIMUM_SIZES_SUCCESS'
export const FETCH_INVESTORS_INVESTMENT_SIZES_ERROR =
  'FETCH_INVESTORS_MINIMUM_SIZES_ERROR'
export const FETCH_INVESTORS_INVESTMENT_SIZES_DONE =
  'FETCH_INVESTORS_MINIMUM_SIZES_DONE'

export const FETCH_INVESTORS_COUNTRIES_START = 'FETCH_INVESTORS_COUNTRIES_START'
export const FETCH_INVESTORS_COUNTRIES_SUCCESS =
  'FETCH_INVESTORS_COUNTRIES_SUCCESS'
export const FETCH_INVESTORS_COUNTRIES_ERROR = 'FETCH_INVESTORS_COUNTRIES_ERROR'
export const FETCH_INVESTORS_COUNTRIES_DONE = 'FETCH_INVESTORS_COUNTRIES_DONE'

export const FETCH_FUNDS_LIST = 'FETCH_FUNDS_LIST'
export const FETCH_FUNDS_LIST_ATTENDEES = 'FETCH_FUNDS_LIST_ATTENDEES'
export const FETCH_FUNDS_BROAD_STRATEGY_GROUPS =
  'FETCH_FUNDS_BROAD_STRATEGY_GROUPS'
export const FETCH_FUNDS_SUBSTRATEGY_GROUPS = 'FETCH_FUNDS_SUBSTRATEGY_GROUPS'
export const FETCH_FUNDS_INVESTMENT_VEHICLES = 'FETCH_FUNDS_INVESTMENT_VEHICLES'

export const FETCH_MESSAGE_TOKEN = 'FETCH_MESSAGE_TOKEN'
export const FETCH_MESSAGE_CHAT_ROOMS = 'FETCH_MESSAGE_CHAT_ROOMS'

export const FETCH_CONTACT_SETTINGS_DATA = 'FETCH_CONTACT_SETTINGS_DATA'
export const FETCH_COMPANY_SETTINGS_DATA = 'FETCH_COMPANY_SETTINGS_DATA'
export const FETCH_SETTINGS_JOB_LEVELS = 'FETCH_SETTINGS_JOB_LEVELS'
export const FETCH_SETTINGS_JOB_FUNCTIONS = 'FETCH_SETTINGS_JOB_FUNCTIONS'
export const FETCH_SETTINGS_COUNTRIES = 'FETCH_SETTINGS_COUNTRIES'
export const FETCH_SETTINGS_PRIMARY_INVESTOR_CATEGORIES =
  'FETCH_SETTINGS_PRIMARY_INVESTOR_CATEGORIES'
export const FETCH_SETTINGS_SECONDARY_INVESTOR_CATEGORIES =
  'FETCH_SETTINGS_SECONDARY_INVESTOR_CATEGORIES'
export const FETCH_ONLINE_NOTIFICATION_MEETING =
  'FETCH_ONLINE_NOTIFICATION_MEETING'

export const FETCH_EVENT_SCHEDULE = 'FETCH_EVENT_SCHEDULE'

export const FETCH_SERVICE_PROVIDERS_LIST = 'FETCH_SERVICE_PROVIDERS_LIST'
export const FETCH_SERVICE_PROVIDERS_ATTENDEE_LIST =
  'FETCH_SERVICE_PROVIDERS_ATTENDEE_LIST'
export const FETCH_SP_FUNDS_LIST = 'FETCH_SP_FUNDS_LIST'
export const FETCH_SP_FUNDS_ATTENDEE_LIST = 'FETCH_SP_FUNDS_ATTENDEE_LIST'

export const FETCH_MEDIA_LIST = 'FETCH_MEDIA_LIST'
export const FETCH_MEDIA_ATTENDEE_LIST = 'FETCH_MEDIA_ATTENDEE_LIST'
export const FETCH_MEDIA_FUNDS_ATTENDEE_LIST = 'FETCH_MEDIA_FUNDS_ATTENDEE_LIST'

export const FETCH_CONTEXT_LIST = 'FETCH_CONTEXT_LIST'

export const FETCH_PORTFOLIO_LIST = 'FETCH_PORTFOLIO_LIST'
export const FETCH_PORTFOLIO_FUNDS = 'FETCH_PORTFOLIO_FUNDS'

export const FETCH_SETTINGS_INVESTMENT_VEHICLE_INTERESTS =
  'FETCH_SETTINGS_INVESTMENT_VEHICLE_INTERESTS'
export const FETCH_SETTINGS_FUND_ATTRIBUTES = 'FETCH_SETTINGS_FUND_ATTRIBUTES'

export const FETCH_SERVICE_PROVIDER_CATEGORIES =
  'FETCH_SERVICE_PROVIDER_CATEGORIES'

export const FETCH_CONVERSATIONS = 'FETCH_CHANNELS'
export const FETCHING_CONVERSATIONS = 'FETCHING_CHANNELS'
export const FETCH_CONVERSATIONS_COMPLETE = 'FETCH_CHANNELS_COMPLETE'
export const INCREMENT_MESSAGE_COUNT = 'INCREMENT_MESSAGE_COUNT'
export const SET_MESSAGE_COUNT = 'SET_MESSAGE_COUNT'
export const DECREMENT_MESSAGE_COUNT = 'DECREMENT_MESSAGE_COUNT'

export const CONTEXT_FUNDS_ATTENDEES_COLUMNS_REARRANGED =
  'CONTEXT_FUNDS_ATTENDEES_COLUMNS_REARRANGED'

export const FETCH_CANCELLATION_REASONS = 'FETCH_CANCELLATION_REASONS'
export const FETCHING_CANCELLATION_REASONS = 'FETCHING_CANCELLATION_REASONS'
