import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isArray } from 'util'
import { faRepeat } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Divider, Modal, Row, message } from 'antd'
import moment from 'moment'
import numeral from 'numeral'
import { REACT_APP_365_URL } from '../../config'
import http from '../../utils/api'
import FundAttachments from '../FundAttachments'
import TrashButton from '../TrashButton/TrashButton'
import DeleteFundModal from './DeleteFundModal'

const CompanyFunds = ({ companyId }) => {
  const [funds, setFunds] = useState([])
  const [buttonVisible, setButtonVisible] = useState({})
  const [fundToDelete, setFundToDelete] = useState(null)

  const { confirm } = Modal

  //use section fundToDelete to set details of fund for use in modal
  const toggleState = (section, value) => {
    if (section === 'fundToDelete') {
      setFundToDelete(value)
    } else {
      var newState = {}
      newState[section] = value
      setButtonVisible(newState)
    }
  }

  //updates whenever fundToDelete is changed
  useEffect(() => {
    http
      .get('/companies/' + companyId + '/funds')
      .then(function (response) {
        setFunds(response.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }, [fundToDelete])

  const fundAttributes = (values, className) => {
    var valArray = []

    if (!isArray(values) && values !== null) {
      valArray = values.split(',')
    } else if (values !== null) {
      valArray = values
    }

    var attrRows = groupAttrs(valArray)

    return attrRows.map((column, index) => (
      <Row type="flex" justify="start" align="middle">
        <Col style={{ paddingLeft: '0px', marginRight: '2px' }}>
          <div className={className}>{column[0]}</div>
        </Col>
        {column.length > 1 ? (
          <Col style={{ paddingRight: '0px' }}>
            <div className={className}>{column[1]}</div>
          </Col>
        ) : null}
      </Row>
    ))
  }

  //create new array of funds grouped in rows of two
  const groupAttrs = (attrs) => {
    var grouped = []
    var row = []

    for (let i = 0; i < attrs.length; i++) {
      if (i % 2 === 0) {
        row.push(attrs[i])
      } else {
        row.push(attrs[i])
        grouped.push(row)
        row = []
      }
    }

    //if there is one extra fund
    if (row.length > 0) {
      grouped.push(row)
    }

    return grouped
  }

  const fundProfile = (fund, buttonState, toggleState) => {
    return (
      <Row
        type="flex"
        justify="space-between"
        align="top"
        style={{ marginBottom: '24px', background: 'white' }}
        onMouseEnter={(e) => toggleState(fund.fundId, true)}
        onMouseLeave={(e) => toggleState(fund.fundId, false)}
      >
        <Col span={24}>
          <Row type="flex" justify="space-between" align="middle">
            <Col span={12} style={{ paddingLeft: '24px' }}>
              <div className="fundName">{fund.name}</div>
              <a
                href={`${REACT_APP_365_URL}/discover/funds/search/0/${fund.fundId}`}
                target="_blank"
              >{`${REACT_APP_365_URL}/discover/funds/search/0/${fund.fundId}`}</a>
            </Col>
            <Col span={12}>
              <div
                style={{ textAlign: 'right' }}
                hidden={!buttonState[fund.fundId]}
              >
                <TrashButton
                  style={{ marginRight: '5px' }}
                  onClick={(e) => {
                    toggleState('fundToDelete', {
                      name: fund.name,
                      fundId: fund.fundId,
                    })
                  }}
                />
                <Button
                  className="RefreshButton"
                  onClick={() => showRefreshConfirm(fund.fundId, fund.name)}
                >
                  <FontAwesomeIcon
                    icon={faRepeat}
                    style={{ marginRight: '10px' }}
                  />
                  Refresh Stats
                </Button>
              </div>
            </Col>
          </Row>
          <Divider />
          <Row style={{ paddingBottom: '32px' }}>
            <Col span={8} style={{ paddingLeft: '24px' }}>
              <Row>
                <Col span={24}>
                  <div className="fundHeading">FUND AUM</div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="fundValue">
                    {numeral(fund.fundAUM).format('($0,0[.]0 a)').toUpperCase()}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <Col span={24}>
                  <div className="fundHeading">INCEPTION DATE</div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="fundValue">
                    {checkEmpty(moment(fund.inception).format('MMM YYYY'))}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <Col span={24}>
                  <div className="fundHeading">SHARPE RATIO</div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="fundValue">{fund.sharpe}</div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ paddingBottom: '28px' }}>
            <Col span={12} style={{ paddingLeft: '24px' }}>
              <Row>
                <Col span={24}>
                  <div className="fundHeading">BROAD STRATEGY GROUP</div>
                </Col>
              </Row>
              {fundAttributes(fund.broadStrategy, 'roundBlue')}
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <div className="fundHeading">SUB STRATEGY</div>
                </Col>
              </Row>
              {fundAttributes(fund.subStrategy, 'roundPurple')}
            </Col>
          </Row>
          <Row style={{ paddingBottom: '42px' }}>
            <Col span={16} style={{ paddingLeft: '24px' }}>
              <Row>
                <Col span={24}>
                  <div className="fundHeading">INVESTMENT VEHICLES</div>
                </Col>
              </Row>
              {fundAttributes(fund.investmentVehicle, 'roundRed')}
            </Col>
            <Col span={8}></Col>
          </Row>
          <Row>
            <Col span={24} style={{ paddingLeft: '24px' }}>
              <Row>
                <Col span={24}>
                  <div className="fundHeading">MARKETING MATERIALS</div>
                </Col>
              </Row>
              <FundAttachments fundId={fund.fundId} />
            </Col>
            <Col span={8}></Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const fundRows = (funds, buttonState, toggleState) => {
    const fundRows = groupFunds(funds)
    return (
      <Row type="flex" justify="space-between" align="top">
        <Col span={12} style={{ paddingLeft: '0px' }}>
          {fundRows.map((column, index) => (
            <>{fundProfile(column[0], buttonState, toggleState)}</>
          ))}
        </Col>
        <Col span={12} style={{ paddingLeft: '0px' }}>
          {fundRows.map((column, index) => (
            <>
              {column.length > 1 ? (
                <>{fundProfile(column[1], buttonState, toggleState)}</>
              ) : null}
            </>
          ))}
        </Col>
      </Row>
    )
  }

  //create new array of funds grouped in rows of two
  const groupFunds = (funds) => {
    var grouped = []
    var row = []

    for (let i = 0; i < funds.length; i++) {
      row.push(funds[i])

      if (i % 2 === 1 || i === funds.length - 1) {
        grouped.push(row)
        row = []
      }
    }

    return grouped
  }

  const checkEmpty = (value) => {
    if (!value) return 'N/A'
    else return value
  }

  const showRefreshConfirm = (fundId, fundName) => {
    confirm({
      title: (
        <span>
          {`Are you sure you want to refresh stats for ${fundName} ?`}
        </span>
      ),
      icon: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return http
          .patch(`funds/updateStats/${fundId}`)
          .then((response) => {
            if (response.data) {
              message.success('Fund stats updated')
            } else {
              message.error('Fund stats were not updated')
            }
          })
          .catch(() => {
            message.error('Failed to update fund stats')
          })
      },
    })
  }

  return (
    <div className="CompanyDetails-Wrapper">
      {fundRows(funds, buttonVisible, toggleState)}
      {fundToDelete && (
        <DeleteFundModal
          fundName={fundToDelete.name}
          fundId={fundToDelete.fundId}
          visible={fundToDelete}
          handleCancel={() => setFundToDelete(null)}
          handleOk={() => setFundToDelete(null)}
        />
      )}
    </div>
  )
}

CompanyFunds.propTypes = {
  companyId: PropTypes.number.isRequired,
}

export default CompanyFunds
