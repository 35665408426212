import React, { useState } from 'react'
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons'
import { faPlus, faUserFriends } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Col, Divider, Dropdown, Menu, Row, message } from 'antd'
import http from '../../utils/api'
import DeleteBoothParticipant from './DeleteBoothParticipant'
import MeetingsManagementModal from './MeetingsManagementModal'
import './DragAndDrop2.less'

function DragNDropContacts2({
  data,
  participants,
  fundName,
  companyId,
  boothName,
  boothFundId,
  setLoading,
  availableBoothsToMoveTo,
}) {
  const [showFundMeetings, setShowFundMeetings] = useState(false)
  const [contactMenu, setContactMenu] = useState(null)
  const [deleteParticipantModalVisible, setDeleteParticipantModalVisible] =
    useState(false)

  const [participantNameToDelete, setParticipantNameToDelete] = useState('')
  const [participantToDelete, setParticipantToDelete] = useState('')

  const handleAddParticipant = (contactId) => {
    http
      .post(
        `/booths/fund/${boothFundId}/fundParticipant/${contactId}/add?api-version=2`
      )
      .then(function (response) {
        message.success('Participant added successfully !', 3)
        setLoading()
      })
      .catch(function (error) {
        message.error('An error has occurred!', 3)
      })
      .finally(() => {})
  }

  const showContactsAvailable = (index) => {
    const scheduleContacts = data.eventCompanyBoothParticipants
    const menuContacts = []
    participants.map((value) => {
      if (
        typeof scheduleContacts === 'undefined' ||
        scheduleContacts.findIndex(
          (p) => p.eventParticipant.contact.contactId == value.contactId
        ) === -1
      ) {
        menuContacts.push(value)
      }
    })

    if (menuContacts.length <= 0) {
      message.warning('All contacts are added to this fund', 3)
      return <></>
    } else {
      return (
        <Menu
          style={{ maxWidth: '286px' }}
          onClick={(e) => {
            handleAddParticipant(e.key)
          }}
        >
          {menuContacts.map((item) => (
            <Menu.Item key={item.contactId}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={3}>
                  <Avatar
                    src={item.imageURL}
                    style={{
                      fontSize: '14px',
                      textAlign: 'center',
                      verticalAlign: 'bottom',
                    }}
                    shape="circle"
                    size={32}
                  >
                    {item.contactName}
                  </Avatar>
                </Col>
                <Col span={17}>
                  <div className="contactName">{item.contactName}</div>
                  <div className="contactDetail">
                    {item.jobTitle != null && item.jobTitle.length > 30
                      ? `${item.jobTitle.substring(0, 30)}...`
                      : item.jobTitle}
                  </div>
                </Col>
              </Row>
            </Menu.Item>
          ))}
        </Menu>
      )
    }
  }

  const showFundMeetingsFromBoothManagement = (a, b) => {
    setShowFundMeetings(!showFundMeetings)
  }

  const handleDeleteParticipantClick = (
    fundParticipantsNo,
    participantName,
    boothParticipantId
  ) => {
    if (fundParticipantsNo > 1) {
      setDeleteParticipantModalVisible(true)
      setParticipantNameToDelete(participantName)
      setParticipantToDelete(boothParticipantId)
    } else {
      message.warning(
        'There should always be one participant tied to the fund',
        3
      )
    }
  }

  if (data) {
    return (
      <>
        <Col className="dnd-contact-group" span={24}>
          <span className="fund-name">{fundName}</span>
          {data.eventCompanyBoothParticipants &&
            data.eventCompanyBoothParticipants.map((item, itemI) => (
              <>
                <div key={item} className="dnd-item-contact">
                  {item && (
                    <>
                      <Col span={3}>
                        <Avatar
                          src={item.eventParticipant.contact.imageURL}
                          style={{
                            fontSize: '14px',
                            textAlign: 'center',
                            verticalAlign: 'bottom',
                          }}
                          shape="circle"
                          size={32}
                        >
                          {item.eventParticipant.contact.imageURL}
                        </Avatar>
                      </Col>
                      <Col span={17}>
                        <Row type="flex" justify="space-between" align="middle">
                          <div className="contactName">
                            {`${item.eventParticipant.contact.firstName} ${item.eventParticipant.contact.lastName}`}
                          </div>
                        </Row>
                        <Row type="flex" justify="space-between" align="middle">
                          <div className="contactDetail">
                            {item.eventParticipant.contact.jobTitle}
                          </div>
                        </Row>
                      </Col>
                      <Col span={3} style={{ cursor: 'pointer', color: 'red' }}>
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          onClick={() => {
                            handleDeleteParticipantClick(
                              data.eventCompanyBoothParticipants.length,
                              `${item.eventParticipant.contact.firstName} ${item.eventParticipant.contact.lastName}`,
                              item.eventCompanyBoothParticipantId
                            )
                          }}
                        />
                      </Col>
                    </>
                  )}
                </div>
                <Divider />
              </>
            ))}
          <Row type="flex" justify="space-between" flex-direction="row">
            <Col>
              <Dropdown overlay={contactMenu} trigger={['click']}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => {
                    setContactMenu(showContactsAvailable())
                    e.preventDefault()
                  }}
                  style={{ fontWeight: 600, padding: '6px' }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add member
                </a>
              </Dropdown>
            </Col>
            <Col>
              <a
                className="ant-dropdown-link"
                onClick={(e) => {
                  showFundMeetingsFromBoothManagement('a', 'b')
                  e.preventDefault()
                }}
                style={{ fontWeight: 600, padding: '6px' }}
              >
                <FontAwesomeIcon icon={faUserFriends} /> {data.meetingsNo}{' '}
                Meeting(s)
              </a>
            </Col>
          </Row>
        </Col>

        {deleteParticipantModalVisible && (
          <DeleteBoothParticipant
            visible={deleteParticipantModalVisible}
            handleCancel={() => setDeleteParticipantModalVisible(false)}
            closeModal={() => setDeleteParticipantModalVisible(false)}
            boothName={boothName}
            fundName={fundName}
            participantName={participantNameToDelete}
            boothParticipantId={participantToDelete}
            setLoading={() => setLoading()}
          />
        )}
        {showFundMeetings && (
          <MeetingsManagementModal
            visible={showFundMeetings}
            onCancel={() => setShowFundMeetings(false)}
            boothFundId={boothFundId}
            companyId={companyId}
            boothName={boothName}
            fundName={fundName}
            availableBoothsToMoveTo={availableBoothsToMoveTo}
            setPageLoading={() => setLoading()}
          />
        )}
      </>
    )
  }
}

export default DragNDropContacts2
