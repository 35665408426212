import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  message,
} from 'antd'
import { filter, isEmpty, isNil, map, size, trim, xor } from 'lodash'
import qs from 'qs'
import {
  createOffPlatformToAllocator,
  getColleagues,
  sendOnlineRequestToAllocator,
} from '../../actions/contextMeetings.js'
import http from '../../utils/api'
import CompanySelectContainer from '../CompanySelectContainer'
import MeetingQuestionsForm from '../MeetingQuestionsForm'
import MeetingRequestProposedTimes from '../MeetingRequestProposedTimes'
import ParticipantsCheckbox from '../ParticipantsCheckbox'
import ToArrow from './arrow.svg'
import './DiscoverAllocatorMeetingRequest.less'

const { TextArea } = Input

const { Option, OptGroup } = Select

const DiscoverAllocatorMeetingRequest = ({
  visible,
  company,
  contacts,
  onClose,
  onRefresh,
  companyContactId,
  isServiceProvider,
  isCfn,
  includeAllocators,
}) => {
  const [allocators, setAllocators] = useState([])
  const [myColleagues, setMyColleagues] = useState([])
  const [otherColleagues, setOtherColleagues] = useState(contacts)
  const [disabledContactIds, setDisabledContactIds] = useState([])
  const [participants, setParticipants] = useState([])
  const [toParticipants, setToParticipants] = useState([])
  const [selectedHour, setSelectedHour] = useState({})
  const [textMessage, setMessage] = useState('')
  const [duration, setDuration] = useState(30)
  const [selectedContactList, setSelectedContactList] = useState({
    participants: [],
    toParticipants: (contacts[0].contactId && [contacts[0].contactId]) || [],
  })
  const [savingInProggress, setSavinginProggress] = useState(false)
  const [questionsForFund, setQuestionsForFund] = useState([])
  const [loadingQuestions, setLoadingQuestions] = useState(false)
  const [fromCompanyType, setFromCompanyType] = useState(1)
  const [isFundManager, setIsFundManager] = useState(false)
  const [currentCompany, setCurrentCompany] = useState({})
  const [destCompany, setDestCompany] = useState({})
  const [funds, setFunds] = useState([])
  const [senderId, setSenderId] = useState(null)
  const [canInviteFunds, setCanInviteFunds] = useState(false)
  //  const [senderUserId, setSenderUserId] = useState(0);
  const [selectedFundId, setSelectedFundId] = useState(null)
  const [didSelectFund, setDidSelectFund] = useState(false)
  const [didLoadQuestions, setDidLoadQuestions] = useState(false)
  const [checkedList, setCheckedList] = useState([])
  const [invalidCombinations, setInvalidCombinations] = useState([])
  const [invalidMeetingCombination, setInvalidMeetingCombination] =
    useState(false)
  const [selectedFundPlaceholder, setSelectedFundPlaceholder] =
    useState('Select fund')
  const [answers, setAnswers] = useState([])
  const [noQuestions, setNoQuestions] = useState(false)
  const [userAnswers, setUserAnswers] = useState([])
  const [proposedMeetingDateTimes, setProposedMeetingDateTimes] = useState([])
  const [invitedParticipants, setInvitedParticipants] = useState([])
  const [invitedCompany, setInvitedCompany] = useState(null)
  const [isOffPlatform, setIsOffPlatform] = useState(false)

  const handleParticipantsChanged = useCallback(
    (mine, participants) => {
      if (mine)
        setSelectedContactList({
          participants,
          toParticipants: selectedContactList.toParticipants,
        })
      else
        setSelectedContactList({
          participants: selectedContactList.participants,
          toParticipants: participants,
        })
    },
    [
      selectedContactList.participants,
      selectedContactList.toParticipants,
      setSelectedContactList,
    ]
  )

  const handleOk = () => {
    setSavinginProggress(true)
    if (isOffPlatform) {
      createOffPlatformToAllocator(
        currentCompany.company.companyId,
        senderId,
        company.companyId,
        selectedFundId,
        selectedContactList.participants,
        selectedContactList.toParticipants,
        textMessage,
        null,
        invitedParticipants
      )
        .then(() => {
          message.success('Request sent succesfully.')
          onRefresh()
          onClose()
        })
        .catch(() => {
          message.error('Could not send meeting request.')
        })
        .finally(() => {
          setSavinginProggress(false)
        })
    } else {
      sendOnlineRequestToAllocator(
        currentCompany.company.companyId,
        senderId,
        company.companyId,
        selectedFundId,
        selectedContactList.participants,
        selectedContactList.toParticipants,
        textMessage,
        3,
        answers,
        null,
        proposedMeetingDateTimes.map((x) => x.selectedTime),
        duration,
        invitedParticipants
      )
        .then(() => {
          message.success('Request sent succesfully.')
          onRefresh()
          onClose()
        })
        .catch(() => {
          message.error('Could not send meeting request.')
        })
        .finally(() => {
          setSavinginProggress(false)
        })
    }
  }

  const handleParticipantChecked = useCallback(
    (e) => {
      let tmpparticipants
      //let tmpcheckedlist;
      if (e.target.checked) {
        tmpparticipants = [...participants, e.target.value]
        //tmpcheckedlist = [...checkedList, e.target.value];
      } else {
        tmpparticipants = participants.filter((p) => p !== e.target.value)
        //tmpcheckedlist = checkedList.filter((p) => p !== e.target.value);
      }
      setParticipants(tmpparticipants)
      // setCheckedList(tmpcheckedlist);
      handleParticipantsChanged(true, tmpparticipants)
    },
    [handleParticipantsChanged, participants]
  )

  const handleToParticipantChecked = useCallback(
    (e) => {
      let tmptoParticipants
      if (e.target.checked) {
        tmptoParticipants = [...toParticipants, e.target.value]
        setToParticipants(tmptoParticipants)
      } else {
        tmptoParticipants = toParticipants.filter((p) => p !== e.target.value)
        setToParticipants(tmptoParticipants)
      }

      handleParticipantsChanged(false, tmptoParticipants)
    },
    [handleParticipantsChanged, toParticipants]
  )

  const handleInvitedParticipants = useCallback(
    (e) => {
      let tmpInvitedParticipants
      if (e.target.checked) {
        tmpInvitedParticipants = [...invitedParticipants, e.target.value]
        setInvitedParticipants(tmpInvitedParticipants)
      } else {
        tmpInvitedParticipants = invitedParticipants.filter(
          (p) => p !== e.target.value
        )
        setInvitedParticipants(tmpInvitedParticipants)
      }
    },
    [invitedParticipants]
  )

  const handleSelectChange = (e) => {
    setSelectedContactList({
      participants: [],
      toParticipants: selectedContactList.toParticipants,
    })
    var selectedCompany = allocators.funds.filter(
      (obj) => obj.company.companyId === e
    )
    if (selectedCompany.length === 0) {
      selectedCompany = allocators.serviceProviders.filter(
        (obj) => obj.company.companyId === e
      )
      if (selectedCompany.length === 0) {
        selectedCompany = allocators.media.filter(
          (obj) => obj.company.companyId === e
        )
        if (selectedCompany.length === 0) {
          selectedCompany = allocators.allocators.filter(
            (obj) => obj.company.companyId === e
          )
          if (selectedCompany.length === 0) {
            selectedCompany = allocators.cfNs.filter(
              (obj) => obj.company.companyId === e
            )
            setFromCompanyType(6)
            setIsFundManager(false)
          } else {
            setFromCompanyType(2)
            setIsFundManager(false)
          }
        } else {
          setFromCompanyType(5)
          setIsFundManager(false)
        }
      } else {
        setFromCompanyType(3)
        setIsFundManager(false)
      }
    } else {
      http.get(`/companies/${e}/funds`).then((response) => {
        setFunds(response.data)
        setIsFundManager(true)
        setFromCompanyType(1)
      })
    }
    setCurrentCompany(selectedCompany[0])
    setSelectedFundId(null)
    setInvitedCompany(null)
    setInvitedParticipants([])
  }

  const handleOnClick = () => {
    setDestCompany(company)
    setFunds(company.funds)
  }

  const handleFilterOption = (e) => {
    if (e.length > 2)
      http.post(`/companies/search/online/5?keyword=${e}`).then((res) => {
        setAllocators(res.data.response)
      })
  }

  const onMainSenderChange = useCallback((e) => {
    let tmpparticipants
    tmpparticipants = participants.filter((p) => p !== e)
    tmpparticipants = [...myColleagues, e]
    setParticipants(tmpparticipants)
    handleParticipantsChanged(true, tmpparticipants)
    setSenderId(e)
  }, [])

  const onFundChanged = (e) => {
    //clearValues();
    setSelectedFundPlaceholder(funds.find((x) => x.fundId == e).name)
    setSelectedFundId(e)
  }

  const clearValues = () => {
    setCheckedList([])
    setParticipants([])
    //setSenderUserId(0);
    setSenderId(null)
    setInvalidMeetingCombination(false)
    setSelectedFundId(null)
    setSelectedFundPlaceholder('Select Fund')
    setDidSelectFund(false)
    setQuestionsForFund([])
    //setAnswers([]);
    setDidLoadQuestions(false)
    setNoQuestions(false)
  }

  useEffect(() => {
    if (visible) {
      setOtherColleagues(contacts)
      setToParticipants([contacts[0].contactId])
      http
        .get(`companies/${company.companyId}/contacts`)
        .then((response) => {
          if (size(response.data) > 0) {
            setOtherColleagues(response.data)
            setToParticipants([contacts[0].contactId])
          } else {
            setOtherColleagues(contacts)
            setToParticipants([contacts[0].contactId])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [visible])

  useEffect(() => {
    if (visible && !isEmpty(toParticipants)) {
      const totalParticipants = participants.concat(toParticipants)
      const almostHitCombinations = invalidCombinations.filter(
        (x) => xor(x, totalParticipants).length == 1
      )
      const invalidContactIds = almostHitCombinations
        .flat(1)
        .filter((x) => !totalParticipants.includes(x))
      setDisabledContactIds(invalidContactIds)
    }
  }, [toParticipants, visible, participants, invalidCombinations])

  useEffect(() => {
    if (visible && senderId != null) {
      http
        .post(
          `contextmeetings/validation/${currentCompany.company.companyId}/${senderId}`,
          {
            toCompanyId: company.companyId,
            fundId: selectedFundId,
          }
        )
        .then((response) => {
          const validationResponse = response.data
          if (validationResponse.isValid) {
            setInvalidMeetingCombination(false)
          } else {
            setInvalidMeetingCombination(true)
            message.error(validationResponse.reason)
          }
        })
      http
        .get(
          `/contextmeetings/invalid-combinations/${currentCompany.company.companyId}/${senderId}/${company.companyId}`
        )
        .then((response) => {
          setInvalidCombinations(response.data)
        })
    }
  }, [visible, senderId])

  useEffect(() => {
    if (visible && senderId != null) {
      if (!isEmpty(toParticipants) && fromCompanyType === 1) {
        setLoadingQuestions(true)
        const params = { companyContactIds: [companyContactId] }
        http
          .get('/contextmeetings/questions', {
            params,
            paramsSerializer: (params) =>
              qs.stringify(params, { arrayFormat: 'repeat' }),
          })
          .then((response) => {
            setDidLoadQuestions(true)
            setQuestionsForFund(response.data)
            setAnswers(
              map(response.data, (r) => ({
                meetingQuestionId: r.meetingQuestionId,
                answer: '',
              }))
            )
            if (size(response.data) === 0) {
              setNoQuestions(true)
            } else {
              setNoQuestions(false)
            }
          })
          .finally(() => {
            setLoadingQuestions(false)
          })
      }
    }
  }, [setAnswers, toParticipants, visible, senderId, noQuestions])

  useEffect(() => {
    if (currentCompany && !isEmpty(currentCompany)) {
      getColleagues(
        currentCompany.company.companyId,
        0,
        company.companyId,
        selectedFundId == null ? 0 : selectedFundId,
        null
      )
        .then((response) => {
          setMyColleagues(
            response.data.find((x) => x.companyId !== company.companyId)
              .contacts
          )
          setDidSelectFund(true)
        })
        .catch(() => message.error('Could not retrieve colleagues'))
    }
  }, [selectedFundId, currentCompany, senderId, visible])

  useEffect(() => {
    if (visible && currentCompany.companyId != undefined) {
      setFunds([])
      clearValues()
    }
  }, [visible, currentCompany])

  const handleUserType = () => {
    if (includeAllocators) {
      return false
    } else {
      if (fromCompanyType === 3 && isServiceProvider === false) {
        return false
      } else {
        if (isServiceProvider === true) return false
        else if (currentCompany.company.category.toLowerCase() === 'cfn')
          return isOffPlatform
            ? false
            : proposedMeetingDateTimes.filter((x) => !isNil(x.selectedTime))
                .length < 2
        else if (noQuestions) return false
        else
          return isOffPlatform
            ? false
            : size(questionsForFund) === 0 ||
                size(filter(answers, (a) => trim(a.answer) == '')) > 0
      }
    }
  }

  const checkCanInviteFund = (e) => {
    var contact = myColleagues.find((c) => c.contactId === e)
    http
      .get(`/companies/cfn/allocator/check/${contact.companyContactId}`)
      .then((response) => {
        setCanInviteFunds(response.data)
      })
  }

  const footer = (
    <div className="footer">
      <Button
        onClick={() => {
          setToParticipants([])
          setParticipants([])
          setSelectedHour({})
          onClose()
        }}
      >
        Cancel
      </Button>
      <Button
        loading={savingInProggress}
        type="primary"
        disabled={
          (!isOffPlatform &&
            isServiceProvider &&
            proposedMeetingDateTimes.filter((x) => !isNil(x.selectedTime))
              .length < 2) ||
          participants.length === 0 ||
          toParticipants.length === 0 ||
          senderId === null ||
          invalidMeetingCombination === true ||
          handleUserType()
        }
        onClick={() => handleOk()}
      >
        Send Meeting Request
      </Button>
    </div>
  )

  const handleProposedTimesChanged = useCallback((times) => {
    setProposedMeetingDateTimes(times)
  }, [])

  return (
    <Modal
      destroyOnClose
      centered
      className="AllocatorOnlineMR"
      visible={visible}
      width="900px"
      title="Request a Meeting"
      footer={footer}
      afterClose={() => {
        setToParticipants([])
        setSelectedHour({})
        setIsFundManager(false)
        setAllocators([])
        onClose()
        clearValues()
      }}
      onCancel={() => {
        setToParticipants([])
        setSelectedHour({})
        setIsFundManager(false)
        setAllocators()
        onClose()
        clearValues()
      }}
      bodyStyle={{ padding: '16px 32px' }}
    >
      <div>
        <div className="cc-tabletitle-text cc-darkest-grey-text">
          Company on behalf of:
        </div>
        <Divider style={{ margin: '16px 0px' }}></Divider>
        <Checkbox onChange={(e) => setIsOffPlatform(e.target.checked)}>
          Off Platform?
        </Checkbox>
        <Row>
          <Col span={8}>
            <div>
              <div
                style={{ marginTop: '16px' }}
                className="cc-heading4 cc-darkest-grey-text"
              >
                <Select
                  showSearch
                  className="cc-background-input"
                  size="default"
                  style={{ width: '95%', height: '35px' }}
                  placeholder="From"
                  showArrow={false}
                  optionFilterProp="children"
                  onChange={handleSelectChange}
                  onSearch={handleFilterOption}
                  filterOption={false}
                  onDropdownVisibleChange={handleOnClick}
                >
                  <OptGroup label="CFN">
                    {allocators &&
                      allocators.cfNs &&
                      allocators.cfNs.map((row) => {
                        if (row.company.category.toLowerCase() == 'cfn') {
                          return (
                            <Select.Option
                              value={row.company.companyId}
                              key={row.company.companyId}
                            >
                              {row.company.name}
                            </Select.Option>
                          )
                        }
                      })}
                  </OptGroup>
                  {includeAllocators && (
                    <OptGroup label="Allocators">
                      {allocators &&
                        allocators.allocators &&
                        allocators.allocators.map((row) => {
                          if (
                            row.company.category.toLowerCase() == 'allocator'
                          ) {
                            return (
                              <Select.Option
                                value={row.company.companyId}
                                key={row.company.companyId}
                              >
                                {row.company.name}
                              </Select.Option>
                            )
                          }
                        })}
                    </OptGroup>
                  )}
                  {!isCfn && (
                    <OptGroup label="Fund Manager Companies">
                      {allocators &&
                        allocators.funds &&
                        allocators.funds.map((row) => {
                          if (row.company.category.toLowerCase() == 'manager') {
                            return (
                              <Select.Option
                                value={row.company.companyId}
                                key={row.company.companyId}
                              >
                                {row.company.name}
                              </Select.Option>
                            )
                          }
                        })}
                    </OptGroup>
                  )}
                  {!isCfn && (
                    <OptGroup label="Service Providers">
                      {allocators &&
                        allocators.serviceProviders &&
                        allocators.serviceProviders.map((row) => {
                          if (
                            row.company.category.toLowerCase() ==
                            'service provider'
                          ) {
                            if (isServiceProvider) {
                              return (
                                <Select.Option
                                  value={row.company.companyId}
                                  key={row.company.companyId}
                                >
                                  {row.company.name}
                                </Select.Option>
                              )
                            } else {
                              if (row.company.canSendMeetingRequest) {
                                return (
                                  <Select.Option
                                    value={row.company.companyId}
                                    key={row.company.companyId}
                                  >
                                    {row.company.name}
                                  </Select.Option>
                                )
                              }
                            }
                          }
                        })}
                    </OptGroup>
                  )}
                </Select>
              </div>
              {!isEmpty(funds) && (
                <div style={{ marginTop: '12px' }}>
                  Fund:
                  <div style={{ display: 'flex', width: '95%' }}>
                    <Select
                      className="cc-background-input"
                      size="default"
                      style={{ width: '100%', marginTop: '8px' }}
                      placeholder="Select fund"
                      onChange={onFundChanged}
                    >
                      {funds &&
                        funds.map((fund) => (
                          <Option key={fund.fundId} value={fund.fundId}>
                            {fund.name}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
              )}
              {!isNil(myColleagues) && !isEmpty(myColleagues) && (
                <>
                  <Row>
                    <Col span={24}>
                      <h3 className="heading5" style={{ marginTop: '16px' }}>
                        MAIN SENDER
                      </h3>
                      <Select
                        className="cc-background-input"
                        size="default"
                        value={senderId}
                        defaultActiveFirstOption={true}
                        defaultValue={currentCompany.contacts[0].contactId}
                        style={{ width: '90%', height: '35px' }}
                        placeholder="Select main sender"
                        onChange={(e) => {
                          onMainSenderChange(e)
                          checkCanInviteFund(e)
                        }}
                      >
                        {myColleagues.map((row) => (
                          <Option key={row.contactId} value={row.contactId}>
                            {row.contactName}
                          </Option>
                        ))}
                      </Select>

                      {/* <Radio.Group
                        onChange={onRadioChange}
                        value={senderId}
                        style={{ marginLeft: "15px" }}
                      >
                        {myColleagues &&
                          myColleagues.map((row) => {
                            return (
                              <div>
                                <Checkbox
                                  disabled={invalidMeetingCombination}
                                  style={{ display: "inline" }}
                                  className="cc-check-participants"
                                  value={row.contactId}
                                  onChange={handleParticipantChecked}
                                  checked={
                                    checkedList.some((e) => e == row.contactId)
                                      ? true
                                      : false
                                  }
                                >
                                  <ContactName
                                    style={{ display: "inline" }}
                                    name={`${row.firstName} ${row.lastName}`}
                                    imageUrl={row.imageUrl}
                                  />
                                </Checkbox>
                              </div>
                            );
                          })}
                      </Radio.Group> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {myColleagues.map((contact) => {
                        return (
                          <ParticipantsCheckbox
                            key={contact.contactId}
                            participants={participants}
                            checked={handleParticipantChecked}
                            contactId={contact.contactId}
                            contactName={`${contact.firstName} ${contact.lastName}`}
                            imageURL={contact.imageUrl}
                            position={contact.jobTitle}
                            disabled={contact.contactId === senderId}
                            //disabled={contact.contactId === userId}
                          />
                        )
                      })}{' '}
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Col>

          <Col span={2} style={{ marginTop: '16px' }}>
            <img src={ToArrow} />
          </Col>

          <Col span={14}>
            <div style={{ marginTop: '16px' }}>
              <div className="cc-heading4 cc-darkest-grey-text">
                <Select
                  disabled={true}
                  className="cc-background-input"
                  size="default"
                  style={{ width: '95%', height: '35px' }}
                  placeholder={company.name}
                  showArrow={false}
                  filterOption={false}
                ></Select>
              </div>
              <div className="cc-check-participants-wrapper">
                {otherColleagues &&
                  map(otherColleagues, (contact) => (
                    <ParticipantsCheckbox
                      key={contact.contactId}
                      participants={toParticipants}
                      checked={handleToParticipantChecked}
                      contactId={contact.contactId}
                      contactName={`${contact.firstName} ${contact.lastName}`}
                      imageUrl={contact.imageUrl}
                      position={contact.jobTitle}
                    />
                  ))}
              </div>
              {senderId != null && fromCompanyType === 6 && (
                <div>
                  <div
                    style={{ marginTop: '16px' }}
                    className="cc-heading5 cc-darkest-grey-text"
                  >
                    Invite Another Company
                  </div>
                  <div
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <CompanySelectContainer
                      excludedCompanies={[
                        currentCompany.company.companyId,
                        company.companyId,
                      ]}
                      setSelectedCompany={setInvitedCompany}
                      canInviteFunds={canInviteFunds}
                    />
                  </div>
                  {!isNil(invitedCompany) && (
                    <div>
                      <div
                        style={{ marginTop: '16px' }}
                        className="cc-heading4 cc-darkest-grey-text"
                      >
                        {invitedCompany.companyName}
                        <Button
                          className="remove-invited-button cc-text-color-danger"
                          icon={
                            <FontAwesomeIcon
                              style={{ marginRight: '8px' }}
                              icon={faTrashAlt}
                            />
                          }
                          type="link"
                          style={{ fontWeight: '600' }}
                          onClick={() => {
                            setInvitedCompany(null)
                            setInvitedParticipants([])
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                      <div>
                        <div className="cc-check-participants-wrapper">
                          {invitedCompany.contacts &&
                            map(invitedCompany.contacts, (contact) => (
                              <ParticipantsCheckbox
                                key={contact.contactId}
                                participants={invitedParticipants}
                                checked={handleInvitedParticipants}
                                contactId={contact.contactId}
                                companyContactId={contact.companyContactId}
                                contactName={contact.contactName}
                                imageUrl={contact.imageUrl}
                                position={contact.jobTitle}
                                useCompanyContactId
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
          {!isOffPlatform && (
            <Col span={24}>
              {didLoadQuestions && size(questionsForFund) > 0 && (
                <div style={{ paddingTop: '16px' }}>
                  <MeetingQuestionsForm
                    questionsForFund={questionsForFund}
                    answers={answers}
                    setAnswers={setAnswers}
                    loadingQuestions={loadingQuestions}
                  />
                </div>
              )}
            </Col>
          )}
        </Row>
        {isServiceProvider &&
          currentCompany &&
          currentCompany.company &&
          currentCompany.company.category.toLowerCase() !== 'cfn' && (
            <div>
              {!isEmpty(toParticipants) &&
                !isEmpty(participants) &&
                !isOffPlatform && (
                  <MeetingRequestProposedTimes
                    proposedTimes={proposedMeetingDateTimes}
                    handleProposedTimesChanged={handleProposedTimesChanged}
                    toCompanyId={company.companyId}
                    companyId={currentCompany.company.companyId}
                    participants={participants}
                    toParticipants={toParticipants}
                  ></MeetingRequestProposedTimes>
                )}
            </div>
          )}
        {currentCompany &&
          currentCompany.company &&
          currentCompany.company.category.toLowerCase() === 'cfn' && (
            <div>
              {!isEmpty(toParticipants) &&
                !isEmpty(participants) &&
                !isOffPlatform && (
                  <MeetingRequestProposedTimes
                    proposedTimes={proposedMeetingDateTimes}
                    handleProposedTimesChanged={handleProposedTimesChanged}
                    toCompanyId={company.companyId}
                    companyId={currentCompany.company.companyId}
                    participants={participants}
                    toParticipants={toParticipants}
                  ></MeetingRequestProposedTimes>
                )}
            </div>
          )}
        {!isOffPlatform && (
          <div style={{ width: '100%', marginTop: '24px' }}>
            <TextArea
              placeholder="Write a message..."
              rows={4}
              value={textMessage}
              onChange={({ target: { value } }) => {
                setMessage(value)
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

DiscoverAllocatorMeetingRequest.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  company: PropTypes.object.isRequired,
  eventId: PropTypes.number,
  onRefresh: PropTypes.func,
  isServiceProvider: PropTypes.bool,
  isCfn: PropTypes.bool,
}

export default DiscoverAllocatorMeetingRequest
