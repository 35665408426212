import { getDealsList } from '../../actions/deals'
import Deals from './Deals'

const DealsContainer = ({ history: { goBack } }) => {
  return (
    <>
      <Deals
        displayTitle={true}
        displayAddButton={true}
        tableTitle={'List of Deals'}
        getDealListData={getDealsList}
      />
    </>
  )
}

export default DealsContainer
