import PropTypes from 'prop-types'
import { Checkbox } from 'antd'

const RequirableField = ({
  children,
  containerStyle,
  checkboxStyle,
  allowRequirement,
  checked,
  onChange,
}) => {
  const baseContainerStyle = { display: 'flex' }
  const baseCheckboxStyle = { paddingLeft: '16px' }

  return (
    <div style={{ ...baseContainerStyle, ...containerStyle }}>
      {children}
      {allowRequirement && (
        <Checkbox
          style={{ ...baseCheckboxStyle, ...checkboxStyle }}
          onChange={(e) => onChange(e.target.checked)}
          checked={checked}
        >
          {'Required'}
        </Checkbox>
      )}
    </div>
  )
}

RequirableField.propTypes = {
  children: PropTypes.node,
  containerStyle: PropTypes.object,
  checkboxStyle: PropTypes.object,
  allowRequirement: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default RequirableField
