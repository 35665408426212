const initialState = {
  notificationsbyId: {
    '433d55fc-dd3f-41fa-b8cd-b44f3d7c1e32': {
      id: 'd646072e-3ba0-4488-91c4-a48ad9c24438',
      type: 'New Event',
      subject: 'Event Name',
      description: 'will take place in New York, June 12-15',
    },
    'd646072e-3ba0-4488-91c4-a48ad9c24438': {
      id: 'd646072e-3ba0-4488-91c4-a48ad9c24438',
      type: 'Meeting Request',
      subject: 'John Doe',
      description: 'is asking you for Online Meeting',
    },
    'bf06812c-fadd-4bca-9c26-3e813a56350d': {
      id: 'd646072e-3ba0-4488-91c4-a48ad9c24438',
      type: 'Accepted Meeting',
      subject: 'Jane Doe',
      description: 'accepted your meeting request',
    },
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}
