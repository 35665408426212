import {
  FETCH_CONTACT_SETTINGS_DATA,
  FETCH_SETTINGS_COUNTRIES,
  FETCH_SETTINGS_JOB_FUNCTIONS,
  FETCH_SETTINGS_JOB_LEVELS,
  FETCH_SETTINGS_PRIMARY_INVESTOR_CATEGORIES,
  FETCH_SETTINGS_SECONDARY_INVESTOR_CATEGORIES,
} from '../constants/types'

const initialState = {
  contactData: null,
  companyData: null,
  jobLevels: [],
  jobFunctions: [],
  countries: [],
  primaryInvestorCategories: [],
  secondaryInvestorCategories: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTACT_SETTINGS_DATA:
      return { ...state, contactData: action.payload }
    case FETCH_SETTINGS_JOB_LEVELS:
      return { ...state, jobLevels: action.payload }
    case FETCH_SETTINGS_JOB_FUNCTIONS:
      return { ...state, jobFunctions: action.payload }
    case FETCH_SETTINGS_COUNTRIES:
      return { ...state, countries: action.payload }
    case FETCH_SETTINGS_PRIMARY_INVESTOR_CATEGORIES:
      return { ...state, primaryInvestorCategories: action.payload }
    case FETCH_SETTINGS_SECONDARY_INVESTOR_CATEGORIES:
      return { ...state, secondaryInvestorCategories: action.payload }
    default:
      return state
  }
}
