import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Modal, message } from 'antd'
import emailValidator from 'email-validator'
import { isEmpty, isNil } from 'lodash'
import BetterFloatingInput from '../BetterFloatingInput/BetterFloatingInput'

const BackstageUserModal = ({ visible, handleOk, handleCancel, editUser }) => {
  const [firstName, setFirstName] = useState(
    !isNil(editUser) ? editUser.firstName : ''
  )
  const [lastName, setLastName] = useState(
    !isNil(editUser) ? editUser.lastName : ''
  )
  const [email, setEmail] = useState(!isNil(editUser) ? editUser.email : null)
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [mobile, setMobile] = useState(
    !isNil(editUser) ? editUser.mobilePhone : null
  )
  const [admin, setAdmin] = useState(
    !isNil(editUser) ? editUser.isAdmin : false
  )
  const [ir, setIr] = useState(!isNil(editUser) ? editUser.isIR : false)
  const [sales, setSales] = useState(
    !isNil(editUser) ? editUser.isSales : false
  )
  const [marketing, setMarketing] = useState(
    !isNil(editUser) ? editUser.isMarketing : false
  )

  const onOk = () => {
    if (isNil(email)) message.error('Email is required')
    else {
      var user = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobilePhone: mobile,
        isAdmin: admin,
        isIR: ir,
        isSales: sales,
        isMarketing: marketing,
      }

      handleOk(user)
    }
  }

  const handleEmailChanged = (e) => {
    setEmail(e.target.value)
    setIsValidEmail(emailValidator.validate(email))
  }

  return (
    <Modal
      className="cc-modal-accept"
      title={
        !isNil(editUser) ? `Edit Sponsored Content` : `Create Sponsored Content`
      }
      visible={visible}
      okText={`Save`}
      cancelText="Cancel"
      onOk={onOk}
      okButtonProps={{
        disabled: !isValidEmail,
      }}
      onCancel={handleCancel}
      centered={true}
      cancelButtonProps={{ disabled: false }}
    >
      <BetterFloatingInput
        onChange={(e) => setFirstName(e.target.value)}
        value={firstName}
        name="First Name"
      />
      <BetterFloatingInput
        onChange={(e) => setLastName(e.target.value)}
        value={lastName}
        name="Last Name"
      />
      <BetterFloatingInput
        onChange={handleEmailChanged}
        value={email}
        name="Email"
        isValid={isValidEmail}
        validationMessage="Invalid Email"
      />

      <BetterFloatingInput
        onChange={(e) => setMobile(e.target.value)}
        value={mobile}
        name="Mobile Phone"
      />
      <Checkbox checked={admin} onChange={(e) => setAdmin(e.target.checked)}>
        <strong>Admin?</strong>
      </Checkbox>
      <Checkbox checked={ir} onChange={(e) => setIr(e.target.checked)}>
        <strong>IR?</strong>
      </Checkbox>
      <Checkbox checked={sales} onChange={(e) => setSales(e.target.checked)}>
        <strong>Sales?</strong>
      </Checkbox>
      <Checkbox
        checked={marketing}
        onChange={(e) => setMarketing(e.target.checked)}
      >
        <strong>Marketing?</strong>
      </Checkbox>
    </Modal>
  )
}

BackstageUserModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  editUser: PropTypes.object,
}

export default BackstageUserModal
