import React, { useEffect, useState } from 'react'
import { faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Dropdown, Menu, Row, message } from 'antd'
import './DragAndDrop.less'

function DragNDropFunds({ data, dataChanged, fundList }) {
  const [list, setList] = useState(data)
  const [fundsMenu, setFundsMenu] = useState(null)

  useEffect(() => {
    setList(data)
  }, [setList, data])

  const removeFundFromSchedule = (index, eventBoothId) => {
    const modifiedArray = [...list]
    const indexToRemove = data[index].items.findIndex(
      (x) => x.eventBoothId === eventBoothId
    )
    modifiedArray[index].items.splice(indexToRemove, 1)
    setList(modifiedArray)
  }

  const showFundsAvailable = (booth, index) => {
    //check if there are any funds that are not in this booth
    const assignedFunds = booth.items.map((x) => x.fundEvent.fund.fundId)
    const menuFunds = []
    fundList.map((value) => {
      if (assignedFunds.findIndex((p) => p === value.fundId) === -1)
        menuFunds.push(value)
    })
    if (menuFunds.length <= 0) {
      message.warning('All funds are added to this booth', 3)
      return <></>
    } else {
      return (
        <Menu
          style={{ maxWidth: '286px' }}
          onClick={(e) => addNewFundFromList(booth, parseInt(e.key), index)}
        >
          {menuFunds.map((item) => (
            <Menu.Item key={item.fundId}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={21}>
                  <div className="contactName">{item.fund.name}</div>
                </Col>
              </Row>
            </Menu.Item>
          ))}
        </Menu>
      )
    }
  }

  const addNewFundFromList = (booth, fundId, index) => {
    const modifiedArray = [...list]
    const fundToAdd = fundList.find((x) => x.fundId == fundId)
    const fundBoothData = { ...booth.items[index] }

    fundBoothData['eventBoothId'] = null
    fundBoothData['fundEvent'] = fundToAdd
    fundBoothData['fundEventId'] = fundToAdd.fundEventId
    fundBoothData['boothId'] = booth.title
    fundBoothData['eventCompanyId'] = fundToAdd.eventCompanyId

    modifiedArray[index].items.push(fundBoothData)
    setList(modifiedArray)
  }

  if (list) {
    return (
      <Row
        style={{ overflowX: 'auto', display: 'flex', paddingBottom: '10px' }}
      >
        {list.map((grp, grpI) => (
          <Col key={grp.title} className="dnd-group" span={7}>
            <span className="group-title">{grp.title}</span>
            {grp.items.map((item, itemI) => (
              <div key={item} className="dnd-item">
                {item && (
                  <>
                    <Col span={17}>
                      <Row type="flex" justify="space-between" align="middle">
                        <p className="contactName">
                          {item.fundEvent?.fund.name}
                        </p>
                      </Row>
                    </Col>
                    {grp.title && (
                      <Col span={3} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          onClick={() => {
                            removeFundFromSchedule(grpI, item.eventBoothId)
                          }}
                        />
                      </Col>
                    )}
                    {/* <Col span={4} /> */}
                  </>
                )}
              </div>
            ))}
            {grp.title && (
              <Row>
                <Dropdown overlay={fundsMenu} trigger={['click']}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => {
                      setFundsMenu(showFundsAvailable(grp, grpI))
                      e.preventDefault()
                    }}
                    style={{ fontWeight: 600, paddingTop: '6px' }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add new
                  </a>
                </Dropdown>
              </Row>
            )}
          </Col>
        ))}
      </Row>
    )
  } else {
    return null
  }
}

export default DragNDropFunds
