import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import { DatePicker, Input, Option, Select } from '@context365/forms'
import { DeleteOutline } from '@context365/icons'
import produce from 'immer'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { getAllServiceProviderOptions } from '../../../actions/serviceProviders'
import { ADD_NEW_ID } from '../constants'

const generateServiceProviderInputId = (serviceProvider) =>
  serviceProvider?.vettedServiceProviderId &&
  serviceProvider?.unvettedServiceProviderId
    ? 'new'
    : serviceProvider?.vettedServiceProviderId
    ? `vettedServiceProvider-${serviceProvider.vettedServiceProviderId}`
    : serviceProvider?.unvettedServiceProviderId
    ? `unvettedServiceProvider-${serviceProvider.unvettedServiceProviderId}`
    : null

const ServiceProviderField = ({
  values,
  options,
  label,
  handleChange,
  handleAdd,
  handleRemove,
}) => (
  <div className="grid grid-cols-5 gap-4">
    {map(values, (x, i) => (
      <>
        <Select.AutoComplete
          key={options.length}
          className="col-start-1 col-span-2"
          value={generateServiceProviderInputId(x)}
          label={`${label} ${i + 1}`}
          onChange={(val) => {
            let newVettedServiceProviderId = val?.startsWith(
              'vettedServiceProvider'
            )
              ? val.split('vettedServiceProvider-')[1]
              : null
            let newUnvettedServiceProviderId = val?.startsWith(
              'unvettedServiceProvider'
            )
              ? val.split('unvettedServiceProvider-')[1]
              : null

            if (!newVettedServiceProviderId && !newUnvettedServiceProviderId) {
              newVettedServiceProviderId = ADD_NEW_ID
              newUnvettedServiceProviderId = ADD_NEW_ID
            }

            handleChange(x.totalIndex, [
              {
                field: 'vettedServiceProviderId',
                value: newVettedServiceProviderId,
              },
              {
                field: 'unvettedServiceProviderId',
                value: newUnvettedServiceProviderId,
              },
            ])
          }}
        >
          {({ inputValue }) =>
            map(
              concat(
                filter(options, (o) =>
                  o.name.toLowerCase().includes(inputValue.toLowerCase())
                ),
                [
                  {
                    vettedServiceProviderId: ADD_NEW_ID,
                    unvettedServiceProviderId: ADD_NEW_ID,
                    name: '+ Add Write In',
                  },
                ]
              ),
              (opt) => (
                <Option
                  key={generateServiceProviderInputId(opt)}
                  value={generateServiceProviderInputId(opt)}
                >
                  {opt.name}
                </Option>
              )
            )
          }
        </Select.AutoComplete>
        {x.vettedServiceProviderId === ADD_NEW_ID && (
          <Input
            required
            label={`New ${label} name`}
            value={x.name}
            onChange={(e) =>
              handleChange(x.totalIndex, [
                { field: 'name', value: e.target.value },
              ])
            }
          />
        )}
        <div className="flex space-between gap-4">
          <DatePicker
            label="Hire date"
            value={isNil(x.hireDate) ? null : new Date(x.hireDate)}
            maxDate={new Date()}
            onChange={(val) =>
              handleChange(x.totalIndex, [{ field: 'hireDate', value: val }])
            }
          />
          <IconButton
            label={`Remove ${label}`}
            icon={<DeleteOutline className="text-red-100" />}
            variant="link"
            onClick={() => handleRemove(x.totalIndex)}
            className="mt-4"
          />
        </div>
      </>
    ))}
    <Button
      variant="link"
      className="mt-4 justify-start"
      onClick={() => handleAdd()}
    >
      + Add {label}
    </Button>
  </div>
)

const getNewServiceProvider = (serviceProviderTypeId) => {
  return {
    vettedServiceProviderId: null,
    unvettedServiceProviderId: null,
    serviceProviderTypeId,
    name: null,
    hireDate: null,
  }
}

const ServiceProviders = ({
  serviceProviders,
  visible,
  refreshServiceProviders,
  updateServiceProviders,
}) => {
  const [serviceProviderOptions, setServiceProviderOptions] = useState([])

  useEffect(() => {
    if (visible) {
      getAllServiceProviderOptions().then((res) =>
        setServiceProviderOptions(res.data)
      )
    }
  }, [visible, refreshServiceProviders])

  const addServiceProvider = (serviceProviderTypeId) => {
    const newSPs = produce(serviceProviders, (newState) => {
      newState.push(getNewServiceProvider(serviceProviderTypeId))
    })
    updateServiceProviders(newSPs)
  }

  const removeServiceProvider = (index) => {
    const newSPs = produce(serviceProviders, (newState) => {
      newState.splice(index, 1)
    })

    updateServiceProviders(newSPs)
  }

  const handleChange = (index, changes) => {
    const newSPs = produce(serviceProviders, (newState) => {
      forEach(changes, (c) => {
        newState[index][c.field] = c.value
      })
    })

    updateServiceProviders(newSPs)
  }

  const serviceProvidersWithIndex = map(serviceProviders, (sp, i) => ({
    ...sp,
    totalIndex: i,
  }))

  return map(serviceProviderOptions, (spt) => (
    <ServiceProviderField
      key={spt.serviceProviderTypeId}
      values={filter(
        serviceProvidersWithIndex,
        (selectedSp) =>
          selectedSp.serviceProviderTypeId === spt.serviceProviderTypeId
      )}
      options={spt.options}
      label={spt.serviceProviderTypeName}
      handleAdd={() => addServiceProvider(spt.serviceProviderTypeId)}
      handleRemove={removeServiceProvider}
      handleChange={handleChange}
    />
  ))
}

ServiceProviders.propTypes = {
  serviceProviders: PropTypes.array.isRequired,
  visible: PropTypes.bool,
  refreshServiceProviders: PropTypes.bool,
  updateServiceProviders: PropTypes.func.isRequired,
}

export default ServiceProviders
