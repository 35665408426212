import { Form, Radio } from 'antd'

const OptionsStep = (props) => {
  const { values, handleChange, touched, errors } = props

  return (
    <>
      <label>
        Set as Featured <span className="required">*</span>
      </label>
      <div>
        <Form.Item
          name="isFeatured"
          validateStatus={
            touched.isFeatured && errors.isFeatured ? 'error' : 'success'
          }
          help={
            touched.isFeatured && errors.isFeatured ? errors.isFeatured : ''
          }
        >
          <Radio.Group
            onChange={(e) => handleChange(e)}
            value={values.isFeatured}
            name="isFeatured"
          >
            <div className="radio-option">
              <Radio value={true} style={{ paddingRight: '12px' }} />
              <span>Yes</span>
            </div>
            <div className="radio-option">
              <Radio value={false} style={{ paddingRight: '12px' }} />
              <span>No</span>
            </div>
          </Radio.Group>
        </Form.Item>
      </div>
      <label>
        Include In Weekly Digest <span className="required">*</span>
      </label>
      <div>
        <Form.Item
          name="includeInWeeklyDigest"
          validateStatus={
            touched.includeInWeeklyDigest && errors.includeInWeeklyDigest
              ? 'error'
              : 'success'
          }
          help={
            touched.includeInWeeklyDigest && errors.includeInWeeklyDigest
              ? errors.includeInWeeklyDigest
              : ''
          }
        >
          <Radio.Group
            onChange={(e) => handleChange(e)}
            value={values.includeInWeeklyDigest}
            name="includeInWeeklyDigest"
          >
            <div className="radio-option">
              <Radio value={true} style={{ paddingRight: '12px' }} />
              <span>Yes</span>
            </div>
            <div className="radio-option">
              <Radio value={false} style={{ paddingRight: '12px' }} />
              <span>No</span>
            </div>
          </Radio.Group>
        </Form.Item>
      </div>
    </>
  )
}

OptionsStep.propTypes = {}

export default OptionsStep
