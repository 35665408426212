import { Component } from 'react'
import { InputNumber } from 'antd'
import './FloatingInputNumber.less'

class FloatingInputNumber extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
    }
  }

  valueChanged = (e) => {
    this.setState({
      value: e,
    })

    if (typeof this.props.onChange === 'function')
      this.props.onChange(e, this.props.id)
  }

  formatter = (value) => {
    if (this.props.type === 'currency') {
      return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else if (this.props.type === 'percentage') {
      return `${value}%`
    }
  }

  parser = (value) => {
    if (this.props.type === 'currency') {
      return value.replace(/\$\s?|(,*)/g, '')
    } else if (this.props.type === 'percentage') {
      return value.replace('%', '')
    }
  }

  render() {
    const value =
      typeof this.props.value === 'undefined' || this.props.value === null
        ? this.state.value
        : this.props.value

    const anyText = value !== null && value !== ''
    let classes = !anyText
      ? 'cc-floating-inputnumber-notext'
      : 'cc-floating-inputnumber-text'
    classes += ' cc-floating-inputnumber'
    let placeholderClasses = !anyText
      ? 'placeholder-movement-notext'
      : 'placeholder-movement-text'
    placeholderClasses += ' cc-floating-placeholder'

    return (
      <div className="floating-inputnumber-row">
        <InputNumber
          size="large"
          value={value}
          disabled={this.props.disabled}
          onKeyPress={this.props.onKeyPress}
          placeholder={this.props.name}
          name={this.props.id ? this.props.id : this.props.nameField}
          formatter={this.formatter}
          parser={this.parser}
          precision={this.props.precision}
          min={this.props.min}
          max={this.props.max}
          onChange={this.valueChanged}
          className={classes}
          id={this.props.id}
        />
        <span className={placeholderClasses}>{this.props.name}</span>
      </div>
    )
  }
}

export default FloatingInputNumber
