import { useContext } from 'react'
import PropTypes from 'prop-types'
import MandateWizardCtx from '../../../utils/contexts/MandateWizardCtx'
import Step from './Step/Step'

const InitialStep = ({ title }) => {
  const {
    initialStepQuestions,
    initialStepAnswers,
    setInitialStepAnswers,
    handleNextInitialStep,
  } = useContext(MandateWizardCtx)

  return (
    <Step
      title={title}
      questions={initialStepQuestions}
      answers={initialStepAnswers}
      onSetAnswers={setInitialStepAnswers}
      onNextStep={handleNextInitialStep}
      isFirst={true}
    />
  )
}

InitialStep.propTypes = {
  title: PropTypes.string,
}

export default InitialStep
