import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { DatePicker, Input, Option, Select } from '@context365/forms'
import { Tooltip } from '@context365/popovers'
import { Divider } from 'antd'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import {
  formatNumber,
  fromMillions,
  toMillions,
} from '../../../../utils/helpers'
import { ADD_NEW_ID } from '../../constants'
import DisabledLabel from '../DisabledLabel'
import FundDropdown from './FundDropdown'
import StrategyDropdown from './StrategyDropdown'

const FundNameInput = ({
  fund,
  vettedCompanyManagerId,
  unvettedCompanyManagerId,
  fundStrategies,
  onChange,
}) => {
  if (fund.companyAllocatorManagerFundId) {
    return <DisabledLabel label="Name of fund" value={fund.name} />
  }

  return (
    <>
      <FundDropdown
        vettedCompanyManagerId={vettedCompanyManagerId}
        unvettedCompanyManagerId={unvettedCompanyManagerId}
        fund={fund}
        fundStrategies={fundStrategies}
        handleChange={(vals) => onChange(vals)}
      />
      {fund.vettedFundId === ADD_NEW_ID && (
        <Input
          required
          label="New fund name"
          value={fund.name}
          onChange={(e) => onChange([{ field: 'name', value: e.target.value }])}
        />
      )}
    </>
  )
}

const FundSection = ({
  funds = [],
  vettedCompanyManagerId,
  unvettedCompanyManagerId,
  companyAllocatorManagerId,
  removeFund,
  handleFundChanges,
  addFund,
  fundStrategies,
  alternativeCategories,
}) => {
  return (
    <>
      {map(funds, (fund, fIndex) => (
        <>
          <div className="flex space-between">
            <div className="type-subtitle-sm text-primary-90">
              Fund {fIndex + 1}
            </div>
            <Button
              variant="link"
              status="error"
              onClick={() => removeFund(fIndex)}
            >
              Remove
            </Button>
          </div>
          <div className="grid grid-cols-3 gap-6 mb-6">
            <FundNameInput
              fundStrategies={fundStrategies}
              vettedCompanyManagerId={vettedCompanyManagerId}
              unvettedCompanyManagerId={unvettedCompanyManagerId}
              fund={fund}
              onChange={(changes) => handleFundChanges(fIndex, changes)}
            />
            <Select
              className="col-start-1"
              label="Allocation Category"
              value={fund.alternativeCategoryId}
              onChange={(val) =>
                handleFundChanges(fIndex, [
                  { field: 'alternativeCategoryId', value: val },
                ])
              }
            >
              {map(alternativeCategories, (opt) => (
                <Option value={opt.id}>{opt.name}</Option>
              ))}
            </Select>
            <StrategyDropdown
              fund={fund}
              fundStrategies={fundStrategies}
              handleChange={(changes) => handleFundChanges(fIndex, changes)}
            />
            <Input
              className="col-start-1"
              label="Money invested (millions)"
              type="number"
              prefix="US$"
              value={toMillions(fund.totalInvestments)}
              onChange={(e) =>
                handleFundChanges(fIndex, [
                  {
                    field: 'totalInvestments',
                    value: fromMillions(e.target.value),
                  },
                ])
              }
              helpMessage={formatNumber(fund.totalInvestments)}
            />
            <DatePicker
              label="Date invested in fund"
              mode="day"
              placeholder="Select date"
              clearable
              value={
                isNil(fund.investmentDate)
                  ? null
                  : new Date(fund.investmentDate)
              }
              onChange={(date) =>
                handleFundChanges(fIndex, [
                  { field: 'investmentDate', value: date },
                ])
              }
            />
            <DatePicker
              label="Fund vintage"
              mode="day"
              placeholder="Select date"
              clearable
              value={
                isNil(fund.vintageDate) ? null : new Date(fund.vintageDate)
              }
              onChange={(date) =>
                handleFundChanges(fIndex, [
                  { field: 'vintageDate', value: date },
                ])
              }
            />
          </div>
          <Divider />
        </>
      ))}
      <Tooltip
        text={
          isNil(vettedCompanyManagerId) && isNil(unvettedCompanyManagerId)
            ? 'You must select a manager company before adding a fund'
            : null
        }
      >
        <Button
          variant="link"
          className="justify-start"
          onClick={addFund}
          disabled={
            isNil(vettedCompanyManagerId) &&
            isNil(unvettedCompanyManagerId) &&
            isNil(companyAllocatorManagerId)
          }
        >
          + Add Fund
        </Button>
      </Tooltip>
    </>
  )
}

FundSection.propTypes = {
  funds: PropTypes.array,
  vettedCompanyManagerId: PropTypes.number,
  unvettedCompanyManagerId: PropTypes.number,
  companyAllocatorManagerId: PropTypes.number,
  removeFund: PropTypes.func,
  handleFundChanges: PropTypes.func,
  addFund: PropTypes.func,
  fundStrategies: PropTypes.array,
  alternativeCategories: PropTypes.array,
}

export default FundSection
