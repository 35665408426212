import { getLogsList } from '../../actions/logs'
import SalesforceLogs from './SalesforceLogs'

const LogsContainer = ({ history: { goBack } }) => (
  <div style={{ backgroundColor: 'white', padding: '50px' }}>
    <SalesforceLogs
      displayTitle={true}
      displayAddButton={true}
      tableTitle={'List of Salesforce Logs'}
      getDealListData={getLogsList}
    />
  </div>
)

export default LogsContainer
