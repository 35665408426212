import PropTypes from 'prop-types'
import { Avatar } from 'antd'

const ContactName = ({
  name,
  position,
  imageUrl = '',
  fontClass = 'cc-small-font',
  marginTop = '15px',
}) => (
  <div style={{ marginTop, display: 'inline' }}>
    <Avatar icon="user" src={imageUrl} size="small" />
    <div style={{ display: 'inline', flexDirection: 'column' }}>
      <span style={{ marginLeft: '8px' }} className={fontClass}>
        {name}
      </span>
      <span
        style={{ marginLeft: '8px' }}
        className="cc-xsmall-font cc-grey-text "
      >
        {position}
      </span>
    </div>
  </div>
)

ContactName.propTypes = {
  name: PropTypes.string.isRequired,
  fontClass: PropTypes.string,
  position: PropTypes.string,
  imageUrl: PropTypes.string,
  marginTop: PropTypes.string,
}

export default ContactName
