import http from '../utils/api'

export const getContentEntryCategories = () =>
  http.get(`ContentEntries/Categories`)

export const getContentEntry = (contentEntryId) =>
  http.get(`ContentEntries/${contentEntryId}`)

export const addContentEntry = (contentEntry) =>
  http.post(`ContentEntries`, contentEntry)

export const updateContentEntry = (contentEntryId, contentEntry) =>
  http.patch(`ContentEntries/${contentEntryId}`, contentEntry)

export const publishContentEntry = (contentEntryId, setPublished) =>
  http.patch(`ContentEntries/${contentEntryId}/Publish/${setPublished}`)

export const markContentEntryFeatured = (contentEntryId) =>
  http.patch(`ContentEntries/${contentEntryId}/featured`)

export const getContentEntryList = (pagination, filters, sorter, searchTerm) =>
  http.post(`ContentEntries/paginated`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })
