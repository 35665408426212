import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import { getLeadsList } from '../../actions/leads'
import { getLeadColumns } from '../../utils/columns'
import ContextTable from '../ContextTable'

const LeadList = () => {
  const [data, setData] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [columns, setColumns] = useState(null)

  useEffect(() => {
    var cols = getLeadColumns()
    setColumns(cols)
  }, [])

  return (
    <>
      {columns && (
        <ContextTable
          tableTitle="Leads"
          columns={columns}
          dataMethod={getLeadsList}
          refresh={refresh}
          scroll={{ x: 1900 }}
        />
      )}
    </>
  )
}

LeadList.propTypes = {}
export default LeadList
