import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Switch, Tooltip } from 'antd'
import groupBy from 'lodash/groupBy'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import LoadingGif from '../LoadingGif'
import './CFNVisibilityEditor.less'

const CFNVisibilityEditor = ({ onChange, values }) => {
  const [options, setOptions] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [preferences, setPreferences] = useState([])

  useEffect(() => {
    if (isNil(options) && values) {
      setIsLoading(true)
      const groupedResult = groupBy(values, (x) => x.section)
      setOptions(Object.values(groupedResult))
      setPreferences(values)
      onChange(values)
      setIsLoading(false)
    }
  }, [onChange, options, values])

  const onTogglePrederence = useCallback(
    (checked, preferenceId, index) => {
      options[index].find((x) => x.preferenceId === preferenceId).value =
        checked
      setOptions(options)
      preferences.find((x) => x.preferenceId === preferenceId).value = checked
      setPreferences(preferences)
      onChange(preferences)
    },
    [onChange, options, preferences]
  )

  return (
    <>
      <LoadingGif spinning={isLoading}>
        {options &&
          map(options, (optionSection, index) => (
            <div>
              <div className="cfnVisibilityEditor-header">
                <div className="cc-heading5 cc-black-text">
                  {optionSection[0].section}
                </div>
              </div>

              <Divider className="cfnVisibilityEditor-divider" />
              {map(optionSection, (option) => (
                <div>
                  <div className="cfnVisibilityEditor-body">
                    <span className="cc-body-text cc-lightblack-text">
                      {option.label}
                      {option.info && (
                        <Tooltip title={option.info}>
                          <span className="cfnVisibilityEditor-info-icon">
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                            ></FontAwesomeIcon>
                          </span>
                        </Tooltip>
                      )}
                    </span>
                    <Tooltip title={option.cannotChangeReason}>
                      <Switch
                        defaultChecked={option.value}
                        disabled={!option.canChange}
                        onChange={(checked) => {
                          onTogglePrederence(
                            checked,
                            option.preferenceId,
                            index
                          )
                        }}
                      ></Switch>
                    </Tooltip>
                  </div>
                  <Divider className="cfnVisibilityEditor-divider" />
                </div>
              ))}
            </div>
          ))}
      </LoadingGif>
    </>
  )
}

CFNVisibilityEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default CFNVisibilityEditor
