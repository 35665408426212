import PropTypes from 'prop-types'
import OnlineDetails from '../../components/OnlineDetails/OnlineDetails'

const OnlineContainer = ({ history: { goBack } }) => (
  <OnlineDetails
    //selectedEvent={attendingEvent}
    // push={() => push(`/summits/:eventId`)}
    back={goBack}
  />
)
OnlineContainer.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string,
      companyId: PropTypes.number,
      tab: PropTypes.string,
    }).isRequired,
  }).isRequired,
}
export default OnlineContainer
