import http from '../utils/api'

export const getSponsorContentList = (
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post('/sponsorcontent', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const addSponsorContent = (sponsorContent) =>
  http.post('/sponsorcontent/add', { ...sponsorContent })

export const updateSponsorContent = (sponsorContentId, sponsorContent) =>
  http.put(`/sponsorcontent/edit/${sponsorContentId}`, {
    ...sponsorContent,
  })
