import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faClock, faInfoCircle, faPlus } from '@fortawesome/pro-light-svg-icons'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons'
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  DatePicker,
  Divider,
  Radio,
  Select,
  Tooltip,
  message,
} from 'antd'
import { concat, isEmpty, isNil, map } from 'lodash'
import momentTz from 'moment-timezone'
import qs from 'qs'
import { useSelector } from 'react-redux'
import http from '../../utils/api'
import LoadingGif from '../LoadingGif'
import Timezone from '../SelectTimezoneComponent/SelectTimezoneComponent'
import './MeetingRequestProposedTimes.less'

const MeetingRequestProposedTimes = ({
  companyId,
  toCompanyId,
  eventId = null,
  roadshowId = null,
  proposedTimes,
  handleProposedTimesChanged,
  participants,
  toParticipants,
}) => {
  const [loading, setLoading] = useState(false)
  const [selectedTimeZone, setSelectedTimeZone] = useState(momentTz.tz.guess())
  const [selectedDuration, setSelectedDuration] = useState(30)
  const [eventTimeFrame, setEventTimeFrame] = useState(null)
  const [minimumProposedDate, setMinimumProposedDate] = useState(null)
  const [minimumProposedDateSlots, setMinimumProposedDateSlots] = useState(null)
  const userFullname = ''

  useEffect(() => {
    // if (!isNil(eventId)) {
    //   http.get(`/events/${eventId}/timeframe`).then((eventTimeFrameRaw) => {
    //     setEventTimeFrame(eventTimeFrameRaw.data.result);
    //   });
    // } else if (!isNil(roadshowId) && roadshowId > 0) {
    //   http
    //     .get(`/roadshows/${roadshowId}/timeframe`)
    //     .then((eventTimeFrameRaw) => {
    //       setEventTimeFrame(eventTimeFrameRaw.data.result);
    //     });
    // }
    if (!isNil(eventId)) {
      try {
        const eventTimeFrameRaw = http.get(`/events/${eventId}/timeframe`)
        const eventTimeFrameResponse = eventTimeFrameRaw.data
        setEventTimeFrame(eventTimeFrameResponse)
      } catch (err) {
        message.error(err)
      }
    }
  }, [eventId, roadshowId])

  const getAvailableSlotsForDate = useCallback(
    (date, selectedDuration, selectedTimeZone) => {
      if (isNil(date)) return

      const params = {
        contactIDs: concat(participants, toParticipants),
        date: momentTz.utc(date).format('YYYY-MM-DD'),
        duration: selectedDuration === 30 ? 0 : 1,
        timeZone: selectedTimeZone,
      }
      if (!isNil(eventId)) {
        params['eventId'] = eventId
      }
      return http
        .get('contextmeetings/availabilities', {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: 'repeat' }),
        })
        .then((response) =>
          response.data.map((dt) => ({
            displayStartTime: momentTz
              .tz(dt.startTime, selectedTimeZone)
              .format('Y-MM-DD HH:mm:ss'),
            displayEndTime: momentTz
              .tz(dt.endTime, selectedTimeZone)
              .format('Y-MM-DD HH:mm:ss'),
            startTime: dt.startTime,
            endTime: dt.endTime,
            busyContactIds: dt.busyContactIds,
            busyContacts: dt.busyContacts,
          }))
        )
    },
    [eventId, participants, toParticipants]
  )

  useEffect(() => {
    setLoading(true)
    http
      .get(`contextmeetings/${companyId}/${toCompanyId}/first-available-date`)
      .then((minimumProposedDateRaw) => {
        const minimumProposedDateResponse = minimumProposedDateRaw.data
        setMinimumProposedDate(minimumProposedDateResponse)
        getAvailableSlotsForDate(
          minimumProposedDateResponse,
          selectedDuration,
          selectedTimeZone
        )
          .then((timeslots) => {
            handleProposedTimesChanged([
              {
                selectedDate: momentTz.utc(minimumProposedDateResponse),
                selectedTime: null,
                availableSlots: timeslots,
              },
              {
                selectedDate: momentTz.utc(minimumProposedDateResponse),
                selectedTime: null,
                availableSlots: timeslots,
              },
            ])
            setMinimumProposedDateSlots(timeslots)
          })
          .finally(() => {
            setLoading(false)
          })
      })
  }, [
    getAvailableSlotsForDate,
    handleProposedTimesChanged,
    selectedDuration,
    selectedTimeZone,
    toCompanyId,
  ])

  const resetSelectedTime = useCallback(
    (duration, timezone) => {
      let newDates = [...proposedTimes]
      newDates = newDates.map((date) => {
        const newDate = Object.assign({}, date)
        getAvailableSlotsForDate(newDate.selectedDate, duration, timezone).then(
          (timeslots) => {
            newDate.selectedTime = null
            newDate.availableSlots = timeslots
          }
        )
        return newDate
      })
      handleProposedTimesChanged(newDates)
    },
    [getAvailableSlotsForDate, handleProposedTimesChanged, proposedTimes]
  )

  const onTimeZoneChanged = useCallback(
    (e) => {
      setSelectedTimeZone(e)
      resetSelectedTime(selectedDuration, e)
    },
    [resetSelectedTime, selectedDuration]
  )

  const onDurationChanged = useCallback(
    (e) => {
      setSelectedDuration(e.target.value)
      resetSelectedTime(e.target.value, selectedTimeZone)
    },
    [resetSelectedTime, selectedTimeZone]
  )

  const onOptionAdded = useCallback(() => {
    const newProposedTimes = [
      ...proposedTimes,
      {
        selectedDate: momentTz.utc(minimumProposedDate),
        availableSlots: minimumProposedDateSlots,
      },
    ]
    handleProposedTimesChanged(newProposedTimes)
  }, [
    handleProposedTimesChanged,
    minimumProposedDate,
    minimumProposedDateSlots,
    proposedTimes,
  ])

  const onOptionRemoved = useCallback(() => {
    handleProposedTimesChanged([proposedTimes[0], proposedTimes[1]])
  }, [handleProposedTimesChanged, proposedTimes])

  const onDateChanged = useCallback(
    (dateTime, optionIndex) => {
      setLoading(true)
      const newDate = [...proposedTimes]
      getAvailableSlotsForDate(dateTime, selectedDuration, selectedTimeZone)
        .then((timeslots) => {
          const changedDate = {
            selectedDate: dateTime,
            selectedTime: null,
            availableSlots: timeslots,
          }
          newDate[optionIndex] = changedDate
          handleProposedTimesChanged(newDate)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [
      getAvailableSlotsForDate,
      handleProposedTimesChanged,
      proposedTimes,
      selectedDuration,
      selectedTimeZone,
    ]
  )

  const onTimeSlotChanged = useCallback(
    (timeSlot, optionIndex) => {
      const newDate = [...proposedTimes]
      const changedDate = { ...newDate[optionIndex], selectedTime: timeSlot }
      newDate[optionIndex] = changedDate
      handleProposedTimesChanged(newDate)
    },
    [proposedTimes, handleProposedTimesChanged]
  )

  const getTooltipText = useCallback(
    (info) => {
      if (!isEmpty(info.busyContacts)) {
        const busyNumber = info.busyContacts.length - 1
        const isYou =
          `${info.busyContacts[0].firstName} ${info.busyContacts[0].lastName}` ===
          userFullname

        return (
          (isYou &&
            `You are already booked on this time slot ${
              busyNumber > 0
                ? `+ ${busyNumber} other${busyNumber > 1 ? 's' : ''}`
                : ''
            }`) ||
          `${info.busyContacts[0].firstName} ${
            info.busyContacts[0].lastName
          } is already booked on this time slot ${
            busyNumber > 0
              ? `+ ${busyNumber} other${busyNumber > 1 ? 's' : ''}`
              : ''
          }`
        )
      }
    },
    [userFullname]
  )

  return (
    <LoadingGif spinning={loading}>
      <div className="meeting-request-proposed-times">
        <div className="meeting-request-proposed-times-title">
          PROPOSE TIMES
          <span className="meeting-request-proposed-times-info">
            <Tooltip title="Select up to 3 timeslots you want to propose for the meeting">
              <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
            </Tooltip>
          </span>
        </div>
        <Divider></Divider>
        <div className="online-meeting-date-time-timezone">
          <Timezone
            value={selectedTimeZone}
            handleTimezoneChange={onTimeZoneChanged}
          />

          <Radio.Group onChange={onDurationChanged} value={selectedDuration}>
            <Radio value={30}>30 minutes</Radio>
            <Radio value={60}>1 hour</Radio>
          </Radio.Group>
        </div>
        <Divider></Divider>

        <div className="meeting-request-proposed-times-options">
          <div className="meeting-request-proposed-times-options-header">
            <span className="meeting-request-proposed-times-options-date cc-body-text">
              Date
            </span>
            <span className="meeting-request-proposed-times-options-time cc-body-text">
              Time
            </span>
          </div>
          {map(proposedTimes, (x, index) => (
            <div key={index}>
              <span className="meeting-request-proposed-time-title cc-body-text">
                Option {index + 1}
                {index == 2 && (
                  <FontAwesomeIcon
                    color={'#F19F00'}
                    icon={faMinusCircle}
                    onClick={onOptionRemoved}
                  />
                )}
              </span>
              <span>
                <span className="meeting-request-proposed-time-date cc-body-text">
                  <DatePicker
                    size="large"
                    allowClear={false}
                    disabledDate={(current) =>
                      (current &&
                        current <
                          momentTz.utc(minimumProposedDate).startOf('day')) ||
                      (!isNil(eventTimeFrame) &&
                        (current.format('Y-MM-DD') <
                          momentTz
                            .utc(eventTimeFrame.startTime)
                            .local()
                            .format('Y-MM-DD') ||
                          current.format('Y-MM-DD') >
                            momentTz
                              .utc(eventTimeFrame.endTime)
                              .local()
                              .format('Y-MM-DD')))
                    }
                    onChange={(value) => {
                      onDateChanged(value, index)
                    }}
                    value={
                      x.selectedDate
                        ? momentTz.utc(x.selectedDate).local()
                        : !isNil(eventTimeFrame)
                        ? momentTz.utc(eventTimeFrame.startTime).local()
                        : null
                    }
                  />
                </span>
                <span className="meeting-request-proposed-time-slot cc-body-text">
                  <Select
                    placeholder="Select a timeslot"
                    value={x.selectedTime}
                    onChange={(value) => {
                      onTimeSlotChanged(value, index)
                    }}
                    size="large"
                    bordered={false}
                    icon={<FontAwesomeIcon icon={faClock} />}
                  >
                    {x.availableSlots &&
                      x.availableSlots.map((s) => (
                        <Select.Option key={s.startTime} value={s.startTime}>
                          <span>
                            {`${momentTz(s.displayStartTime).format(
                              'h:mm a'
                            )} - ${momentTz(s.displayEndTime).format(
                              'h:mm a'
                            )}`}
                          </span>
                          <span className="meeting-request-proposed-time-slot-busy-icon">
                            {!isEmpty(s.busyContactIds) && (
                              <Tooltip
                                title={getTooltipText(s)}
                                placement="topRight"
                              >
                                <FontAwesomeIcon
                                  color={'#F19F00'}
                                  icon={faExclamationCircle}
                                />
                              </Tooltip>
                            )}
                          </span>
                        </Select.Option>
                      ))}
                  </Select>
                </span>
              </span>

              <Divider></Divider>
            </div>
          ))}

          <div className="meeting-request-proposed-times-options-add">
            {proposedTimes.length < 3 && (
              <Button type="link" onClick={onOptionAdded}>
                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                Add another option
              </Button>
            )}
          </div>
        </div>
      </div>
    </LoadingGif>
  )
}

MeetingRequestProposedTimes.propTypes = {
  participants: PropTypes.array.isRequired,
  toParticipants: PropTypes.array.isRequired,
  toCompanyId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,

  eventId: PropTypes.number,
  roadshowId: PropTypes.number,
  proposedTimes: PropTypes.array,
  handleProposedTimesChanged: PropTypes.func.isRequired,
}

export default MeetingRequestProposedTimes
