import { useCallback, useEffect, useState } from 'react'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, Col, Input, Radio, Row, message } from 'antd'
import { useHistory } from 'react-router-dom'
import BetterFloatingInput from '../../components/BetterFloatingInput/BetterFloatingInput'
import http from '../../utils/api'

const AddDeal = ({
  match: {
    params: { dealId },
  },
}) => {
  const selectedRecordId = parseInt(dealId, 10)

  useEffect(() => {
    if (!isNaN(selectedRecordId)) {
      http.get(`deals/${selectedRecordId}`).then((response) => {
        console.log('Done')
      })
    }
  }, [selectedRecordId])

  return null
}

export default AddDeal
