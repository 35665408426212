import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faDownload, faFilePdf } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNil, last, map, split } from 'lodash'
import { downloadDocument } from '../../actions/files'
import http from '../../utils/api'
import LoadingGif from '../LoadingGif'
import './FundAttachments.less'

const FundAttachments = ({ fundId }) => {
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    http
      .get(`funds/marketingmaterials/${fundId}`)
      .then((response) => {
        if (!isNil(response.data) && response.data != '') {
          const listFiles = []
          try {
            response.data.forEach((item) => {
              const urlString = decodeURI(item)
                .replace('[', '')
                .replace(']', '')
              try {
                let url = ''
                if (urlString.startsWith('"')) {
                  url = new URL(urlString.slice(1, -1))
                } else {
                  url = new URL(urlString)
                }
                listFiles.push({
                  name: last(split(decodeURI(url.pathname), '/')),
                  url: decodeURI(url.href),
                })
              } catch (e) {
                console.log(e)
              }
            })
          } catch (e) {
            try {
              const url = new URL(decodeURI(response.data))
              listFiles.push({
                name: last(split(decodeURI(url.pathname), '/')),
                url: decodeURI(url.href),
              })
            } catch (ex) {
              console.log(ex)
            }
          }
          setFileList(listFiles)
        }
      })
      .finally(() => setLoading(false))
  }, [fundId])

  return (
    <LoadingGif spinning={loading}>
      {fileList &&
        fileList.length > 0 &&
        map(fileList, (x) => (
          <div className="FundAttachments">
            <div className="FundAttachments-image">
              <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>
            </div>
            <div className="FundAttachments-body">
              {isNil(x.name) || x.name === '' ? 'UNTITLED' : x.name}
            </div>
            <div className="FundAttachments-download-body">
              <a
                onClick={() => {
                  downloadDocument(x.url).then((response) => {
                    window.open(response.data, '_blank')
                  })
                }}
              >
                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                Download
              </a>
            </div>
          </div>
        ))}
      {fileList.length === 0 && (
        <div className="FundAttachments-no-documents">
          No documents attached
        </div>
      )}
    </LoadingGif>
  )
}
FundAttachments.propTypes = {
  fundId: PropTypes.number.isRequired,
}

export default FundAttachments
