import React from 'react'
import AdminUserList from '../../components/AdminUserList/AdminUserList'

const AdminContainer = ({ history: { goBack } }) => {
  return (
    <>
      <AdminUserList />
    </>
  )
}

export default AdminContainer
