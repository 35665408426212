import { FETCH_CONTEXT_LIST } from '../constants/types'

const initialState = {
  contextList: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTEXT_LIST:
      return { ...state, contextList: action.payload }

    default:
      return state
  }
}
