import PropTypes from 'prop-types'
import { Form } from 'antd'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import FormCtx from '../../utils/contexts/FormCtx'
import FormField from './FormField'
import { schema } from './Validations'
import './Fields.less'

const ContextForm = ({
  questions,
  answers,
  invalidAnswers,
  onSetAnswers,
  onSetInvalidAnswers,
  requiredQuestionsForApplication,
  onSetRequiredQuestionsForApplication,
  buttonComponent,
  isEdit = false,
}) => {
  const formCtxValue = {
    questions,
    answers,
    onSetAnswers,
    onSetInvalidAnswers,
    requiredQuestionsForApplication,
    onSetRequiredQuestionsForApplication,
    isEdit,
  }
  const invalidAnswersShortNames = invalidAnswers.map(
    (answer) => answer.shortName
  )
  const initialAnswers = () => {
    let initAnswers = {}
    answers.map((answer) => {
      const question =
        questions.find((q) => q.shortName === answer.shortName) || null

      if (
        isEmpty(question?.questionAnswers) ||
        question?.questionType === 'Boolean'
      ) {
        initAnswers = {
          ...initAnswers,
          [answer.shortName]:
            isNaN(answer.responseText) ||
            isNil(answer.responseText) ||
            answer.responseText === ''
              ? answer.responseText
              : Number(answer.responseText),
        }
      } else {
        initAnswers = {
          ...initAnswers,
          [answer.shortName]: answer.questionAnswerIds,
        }
      }
    })
    return initAnswers
  }

  return (
    <FormCtx.Provider value={formCtxValue}>
      <Formik
        initialValues={initialAnswers()}
        validationSchema={schema}
        validateOnChange
        validateOnBlur={false}
        enableReinitialize
        validateOnMount
      >
        <Form className="ContextForm" layout="vertical">
          {questions.map(
            (question, key) =>
              question.publicVisible && (
                <>
                  <FormField key={key} question={question} />{' '}
                </>
              )
          )}
          {buttonComponent(!isEmpty(invalidAnswersShortNames))}
        </Form>
      </Formik>
    </FormCtx.Provider>
  )
}

ContextForm.propTypes = {
  questions: PropTypes.array,
  answers: PropTypes.array,
  invalidAnswers: PropTypes.array,
  onSetAnswers: PropTypes.func,
  onSetInvalidAnswers: PropTypes.func,
  requiredQuestionsForApplication: PropTypes.array,
  onSetRequiredQuestionsForApplication: PropTypes.func,
  buttonComponent: PropTypes.node,
  isEdit: PropTypes.bool,
}

export default ContextForm
