import { useEffect, useState } from 'react'
import { List } from 'antd'
import { isNil } from 'lodash'
import { getByZip, getContinents, getCountries } from '../../actions/geography'
import FloatingSelect from '../FloatingSelect'
import AddressInput from './AddressInput'

const OnboardingAddressSection = ({
  value = {},
  onChange,
  validationMessage,
  displayErrors,
}) => {
  const [continents, setContinents] = useState([])
  const [selectedContinent, setSelectedContinent] = useState(null)
  const [continentValid, setContinentValid] = useState(true)

  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(value.country)
  const [countryValid, setCountryValid] = useState(true)

  const [selectedState, setSelectedState] = useState(value.state)
  const [stateValid, setStateValid] = useState(true)

  const [selectedCity, setSelectedCity] = useState(value.city)
  const [cityValid, setCityValid] = useState(true)

  const [zipCode, setZipCode] = useState(value.zipCode)
  const [zipCodeValid, setZipCodeValid] = useState(true)
  const [zipCodeLoading, setZipCodeLoading] = useState(false)
  const [zipCodeStatus, setZipCodeStatus] = useState(null)
  const [zipCodeSuggestions, setZipCodeSuggestions] = useState(null)
  const [zipSearchResult, setZipSearchResult] = useState(null)
  const [firstTimeLoaded, setFirstTimeLoaded] = useState(false)

  const [address1, setAddress1] = useState(value.address1)
  const [addressValid, setAddressValid] = useState(true)
  const [address2, setAddress2] = useState(value.address2)
  const [address2Valid, setAddress2Valid] = useState(true)
  const [address3, setAddress3] = useState(value.address3)
  const [address3Valid, setAddress3Valid] = useState(true)

  const [continentsLoading, setContinentsLoading] = useState(false)
  const [countriesLoading, setCountriesLoading] = useState(false)

  if (continents.length === 0 && !continentsLoading) {
    setContinentsLoading(true)
    getContinents().then((response) => {
      setContinentsLoading(false)
      setContinents(response.data)
      if (
        !isNil(value.continent) &&
        value.continent !== -1 &&
        !isNil(response.data.find((x) => x === value.continent))
      ) {
        setSelectedContinent(value.continent)
        setContinentValid(true)
        const result = {
          ...value,
          continent: value.continent,
          isValid: checkValueAddress(),
        }
        onChange(result)
      } else setSelectedContinent(-1)
    })
  }

  const checkAddress = () => {
    return (
      !isNil(selectedContinent) &&
      selectedContinent !== -1 &&
      !isNil(selectedCountry) &&
      selectedCountry !== -1 &&
      !isNil(selectedCity) &&
      selectedCity !== -1
    )
  }

  const checkAddressByResult = (value) => {
    return (
      !isNil(value.continent) &&
      value.continent !== -1 &&
      !isNil(value.country) &&
      value.country !== -1 &&
      !isNil(value.city) &&
      value.city !== -1 &&
      value.city !== ''
    )
  }

  const checkValueAddress = () => {
    return (
      !isNil(value.continent) &&
      value.continent !== -1 &&
      !isNil(value.country) &&
      value.country !== -1 &&
      !isNil(value.city) &&
      value.city !== -1
    )
  }

  const onValueChanged = (newValue, id, zipCodeValue) => {
    let result
    if (id === 'continent') {
      setSelectedContinent(newValue)
      setContinentValid(!isNil(newValue))
      result = { ...value, continent: newValue, isValid: checkAddress() }
      delete value.country
    } else if (id === 'country') {
      const countryName = countries.find((x) => x.value === newValue).value
      setSelectedCountry(newValue)
      setCountryValid(!isNil(newValue))
      result = { ...value, country: countryName, isValid: checkAddress() }
    } else if (id === 'autocomplete_city_state') {
      setSelectedCity(newValue.city)
      setCityValid(true)
      setSelectedState(newValue.state)
      result = {
        ...value,
        postalCode: zipCodeValue,
        zipCode: zipCodeValue,
        city: newValue.city,
        state: newValue.state,
        isValid: checkAddress(),
      }
    }
    result.isValid = checkAddressByResult(value) //checkAddressByResult(result);
    onChange(result)
  }

  const onZipCodeSearch = (value, paramZipCode = null, paramCountry = null) => {
    setZipCodeLoading(true)
    getByZip(
      isNil(paramZipCode) ? zipCode : paramZipCode,
      isNil(paramCountry) ? selectedCountry : paramCountry
    )
      .then(async (response) => {
        if (response.data.status === 'ZERO_RESULTS') {
          onValueChanged(
            { city: '', state: '' },
            'autocomplete_city_state',
            zipCode
          )
          setZipCodeStatus('No results found')
        } else {
          setZipCodeStatus(null)
          if (response.data.results.length > 1) {
            setZipCodeSuggestions(response.data.results)
            setZipSearchResult(response.data.results)
          } else {
            const address_components =
              response.data.results[0].address_components
            let city, state, postalTown, administrative_3, sublocality
            await address_components.forEach((element) => {
              const types = element.types
              types.forEach((type) => {
                if (type === 'locality') {
                  city = element.long_name
                }
                if (type === 'postal_town') {
                  postalTown = element.long_name
                }
                if (type === 'administrative_area_level_1') {
                  state = element.short_name
                }
                if (type === 'administrative_area_level_3') {
                  administrative_3 = element.short_name
                }
                if (type === 'sublocality_level_1') {
                  sublocality = element.short_name
                }
              })
            })

            if (isNil(state)) state = sublocality
            if (isNil(state)) state = city

            if (!isNil(postalTown)) city = postalTown
            if (isNil(city)) city = administrative_3
            if (isNil(city)) city = state

            onValueChanged({ city, state }, 'autocomplete_city_state', zipCode)
          }
        }
      })
      .catch((err) => {
        console.log(err.response)
      })
      .finally(() => {
        setZipCodeLoading(false)
      })
  }

  const onSuggestion = (value, index) => {
    const address_components = value.address_components
    let city, state
    address_components.forEach((element) => {
      const types = element.types
      types.forEach((type) => {
        if (type === 'locality') {
          city = element.long_name
        }
        if (type === 'administrative_area_level_1') {
          state = element.short_name
        }
      })
    })

    onValueChanged({ city, state }, 'autocomplete_city_state')
    setZipCodeSuggestions(null)
    setZipSearchResult(null)
  }

  const renderSuggestion = (item) => {
    const address_components = item.address_components
    let city, state
    address_components.forEach((element) => {
      const types = element.types
      types.forEach((type) => {
        if (type === 'locality') {
          city = element.long_name
        }
        if (type === 'administrative_area_level_1') {
          state = element.short_name
        }
      })
    })

    return <span>{city + ', ' + state}</span>
  }

  const onTextChanged = (id, e) => {
    let result
    if (id === 'zipCode') {
      setZipCode(e.target.value)
      setZipCodeValid(!isNil(e.target.value) && e.target.value !== '')
      result = {
        ...value,
        zipCode: e.target.value,
        postalCode: e.target.value,
        isValid: checkAddress(),
      }
    } else if (id === 'address1') {
      setAddress1(e.target.value)
      setAddressValid(!isNil(e.target.value) && e.target.value !== '')
      result = { ...value, address1: e.target.value, isValid: checkAddress() }
    } else if (id === 'address2') {
      setAddress2(e.target.value)
      setAddress2Valid(!isNil(e.target.value) && e.target.value !== '')
      result = { ...value, address2: e.target.value, isValid: checkAddress() }
    } else if (id === 'address3') {
      setAddress3(e.target.value)
      setAddress3Valid(!isNil(e.target.value) && e.target.value !== '')
      result = { ...value, address3: e.target.value, isValid: checkAddress() }
    } else if (id === 'state') {
      //const stateName = states.find(x => x.value === newValue).value;
      setSelectedState(e.target.value)
      //setStateValid(!isNil(e.target.value) && e.target.value !== '');
      result = {
        ...value,
        state: e.target.value,
        isValid: checkAddress(),
      }
    } else if (id === 'city') {
      //const cityName = cities.find(x => x.value === newValue).value;
      setSelectedCity(e.target.value)
      setCityValid(!isNil(e.target.value) && e.target.value !== '')
      result = { ...value, city: e.target.value, isValid: checkAddress() }
    }
    result.isValid = checkAddressByResult(result)

    onChange(result)
  }

  useEffect(() => {
    if (selectedContinent === -1 || isNil(selectedContinent)) return

    setCountryValid(!isNil(continentValid) || false)
    setCountriesLoading(true)
    getCountries(selectedContinent).then((response) => {
      setCountries(response.data)
      if (
        !isNil(value.country) &&
        value.country !== -1 &&
        !isNil(response.data.find((x) => x.value === value.country))
      ) {
        setSelectedCountry(value.country)
        setCountryValid(true)
        const result = {
          ...value,
          country: value.country,
          isValid: checkValueAddress(),
        }

        onChange(result)
      } else {
        setSelectedCountry(-1)
        const result = {
          ...value,
          country: null,
          isValid: checkValueAddress(),
        }
        onChange(result)
      }
      setCountriesLoading(false)
    })
  }, [selectedContinent])

  useEffect(() => {
    if (displayErrors === true) {
      setContinentValid(!isNil(selectedContinent) && selectedContinent !== -1)
      setCountryValid(!isNil(selectedCountry) && selectedCountry !== -1)
      setCityValid(!isNil(selectedCity) && selectedCity !== '')
    }
  }, [displayErrors])

  const onBlur = (id) => {
    switch (id) {
      case 'continent':
        setContinentValid(!isNil(selectedContinent) && selectedContinent !== -1)
        break
      case 'country':
        setCountryValid(!isNil(selectedCountry) && selectedCountry !== -1)
        break
      case 'state':
        setStateValid(
          !isNil(selectedState) && selectedState !== -1 && selectedState !== ''
        )
        break
      case 'city':
        setCityValid(
          !isNil(selectedCity) && selectedCity !== -1 && selectedCity !== ''
        )
        break
      case 'zipCode':
        setZipCodeValid(!isNil(zipCode) && zipCode !== '')
        break
      case 'address1':
        setAddressValid(!isNil(address1) && address1 !== '')
        break
      case 'address2':
        setAddress2Valid(!isNil(address2) && address2 !== '')
        break
      case 'address3':
        setAddress3Valid(!isNil(address3) && address3 !== '')
        break
    }
  }

  if (!firstTimeLoaded) {
    setFirstTimeLoaded(true)
    if (
      isNil(value.zipCode) ||
      value.zipCode === '' ||
      isNil(value.country) ||
      value.country === ''
    )
      return

    onZipCodeSearch(value.zipCode, value.zipCode, value.country)
  }

  return (
    <>
      <FloatingSelect
        name="Continent"
        handleChange={false}
        value={selectedContinent}
        onChange={onValueChanged}
        id={'continent'}
        options={continents.map((x) => ({ value: x, text: x }))}
        isValid={continentValid}
        onBlur={onBlur.bind(null, 'continent')}
        validationMessage={validationMessage}
        loading={continentsLoading}
      />
      <FloatingSelect
        name="Country"
        handleChange={false}
        value={selectedCountry}
        onChange={onValueChanged}
        id={'country'}
        options={countries.map((x) => ({ value: x.value, text: x.value }))}
        isValid={countryValid}
        onBlur={onBlur.bind(null, 'country')}
        validationMessage={validationMessage}
        loading={countriesLoading}
      />

      <AddressInput
        onChange={onTextChanged}
        value={zipCode}
        name="Zip Code"
        id={'zipCode'}
        handleChange={true}
        //isValid={zipCodeValid}
        onBlur={onBlur.bind(null, 'zipCode')}
        validationMessage={validationMessage}
        isSearch={true}
        onSearch={(e) => {
          onZipCodeSearch()
        }}
        searchLoading={zipCodeLoading}
      />
      {isNil(zipCodeSuggestions) ? null : (
        <div className="cc-address-zip-code-suggestions">
          <List
            dataSource={zipCodeSuggestions}
            renderItem={(item, index) => (
              <List.Item
                className="cc-address-zip-code-suggestion-item"
                onClick={onSuggestion.bind(null, item, index)}
              >
                {renderSuggestion(item)}
              </List.Item>
            )}
            bordered
          />
        </div>
      )}
      {isNil(zipCodeStatus) ? null : (
        <div className="cc-address-zip-code-message">
          <span>{zipCodeStatus}</span>
        </div>
      )}

      <AddressInput
        value={selectedState === -1 ? '' : selectedState}
        onChange={onTextChanged}
        id={'state'}
        //isValid={stateValid}
        handleChange={true}
        onBlur={onBlur.bind(null, 'state')}
        validationMessage={validationMessage}
        name="State"
      />
      <AddressInput
        value={selectedCity === -1 ? '' : selectedCity}
        onChange={onTextChanged}
        name="City"
        id={'city'}
        handleChange={true}
        isValid={cityValid}
        onBlur={onBlur.bind(null, 'city')}
        validationMessage={validationMessage}
      />

      <AddressInput
        onChange={onTextChanged}
        value={address1}
        name="Address 1"
        id={'address1'}
        handleChange={true}
        //isValid={addressValid}
        onBlur={onBlur.bind(null, 'address1')}
        validationMessage={validationMessage}
      />
      <AddressInput
        onChange={onTextChanged}
        value={address2}
        name="Address 2"
        id={'address2'}
        handleChange={true}
        //isValid={address2Valid}
        onBlur={onBlur.bind(null, 'address2')}
        validationMessage={validationMessage}
      />
      <AddressInput
        onChange={onTextChanged}
        value={address3}
        name="Address 3"
        id={'address3'}
        handleChange={true}
        //isValid={address3Valid}
        onBlur={onBlur.bind(null, 'address3')}
        validationMessage={validationMessage}
      />
    </>
  )
}

export default OnboardingAddressSection
