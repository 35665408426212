import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Empty, Tabs } from 'antd'
import moment from 'moment'
import { getAgendaSpeakerRoles, getEventAgenda } from '../../actions/events'
import AgendaDay from '../AgendaDay'
import AgendaDayDetails from '../AgendaDay/AgendaDayDetails'
import CenteredPrimaryRadioButtons from '../CenteredPrimaryRadioButtons'
import LoadingGif from '../LoadingGif'
import './Agenda.less'

const { TabPane } = Tabs

const Agenda = ({ eventId, eventTimezone }) => {
  const [loading, setLoading] = useState(false)
  const [agendaDays, setAgendaDays] = useState(null)
  const [activeTab, setActiveTab] = useState('0')
  const [speakerRoles, setSpeakerRoles] = useState(null)
  const [showNewAgendaModal, setShowNewAgendaModal] = useState(false)

  const getDayButtons = () => {
    if (agendaDays === null) return null
    else
      return agendaDays.map((x, index) => ({
        value: index.toString(),
        text: moment.utc(x.eventDate).format('dddd'),
      }))
  }

  const handleEventAgendaDataSource = () => {
    setLoading(true)
    getEventAgenda(eventId)
      .then((response) => {
        setAgendaDays(response.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSpeakerRoles = () => {
    getAgendaSpeakerRoles()
      .then((response) => {
        setSpeakerRoles(response.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    handleEventAgendaDataSource()
    handleSpeakerRoles()
  }, [eventId])

  return (
    <div style={{ minHeight: '500px' }}>
      <LoadingGif spinning={loading}>
        {agendaDays && agendaDays.length > 0 && (
          <CenteredPrimaryRadioButtons
            selectedKey={activeTab}
            buttons={getDayButtons()}
            OnClickFunction={(e) => {
              setActiveTab(e.target.value)
            }}
          />
        )}
        {agendaDays && agendaDays.length > 0 ? (
          <Tabs
            animated={false}
            tabBarStyle={{ display: 'none' }}
            activeKey={activeTab.toString()}
          >
            {agendaDays.map((x, index) => (
              <TabPane tab={x.eventDate} key={index.toString()}>
                <AgendaDay
                  dayText={moment.utc(x.eventDate).format('dddd')}
                  items={x.eventAgendas}
                  eventId={eventId}
                  handleAgendaData={handleEventAgendaDataSource}
                  speakerRoles={speakerRoles}
                  eventTimezone={x.eventTimezone}
                />
              </TabPane>
            ))}
          </Tabs>
        ) : !loading ? (
          <div className="empty-div">
            <Empty description="There is no agenda for this summit." />
            <Button
              block
              size="large"
              style={{
                background: '#2FC5D2',
                boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.06);',
                color: 'white',
                width: '190px',
                marginLeft: '133px',
                marginTop: '16px',
              }}
              onClick={(e) => {
                e.stopPropagation()
                setShowNewAgendaModal(true)
              }}
            >
              <span style={{ background: '#2FC5D2', opacity: '1' }}>
                <FontAwesomeIcon icon={faPlus} /> Add Agenda
              </span>
            </Button>
          </div>
        ) : (
          <></>
        )}
      </LoadingGif>

      {showNewAgendaModal && (
        <AgendaDayDetails
          visible={true}
          eventId={eventId}
          handleCancel={() => {
            setShowNewAgendaModal(false)
          }}
          handleOk={() => {
            setShowNewAgendaModal(false)
          }}
          handleDataRefresh={handleEventAgendaDataSource}
          modalTitle={'Add Agenda'}
          speakerRoles={speakerRoles}
          eventTimezone={eventTimezone}
        />
      )}
    </div>
  )
}

Agenda.propTypes = {
  eventId: PropTypes.number.isRequired,
}

export default Agenda
