import { Component } from 'react'
import { Input } from 'antd'
import { isNil } from 'lodash'
import './InvestorFloatingInput.less'

class InvestorFloatingInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      isValid: true,
    }
  }

  valueChanged = (e) => {
    this.setState({
      value: e.target.value,
    })

    if (typeof this.props.onChange === 'function')
      this.props.onChange(this.props.id, e)
  }

  render() {
    let { value, isValid } = this.props
    value = isNil(value) ? '' : value

    // const value =
    //   typeof this.props.value === "undefined" || this.props.value === null
    //     ? this.state.value
    //     : this.props.value;

    const anyText = value !== null && value !== ''
    let classes = !anyText
      ? 'cc-floating-input-notext'
      : 'cc-floating-input-text'
    classes += ' cc-floating-input'
    let placeholderClasses = !anyText
      ? 'placeholder-movement-notext'
      : 'placeholder-movement-text'
    placeholderClasses += ' cc-floating-placeholder'

    let validationMessage = null
    if (isValid === false) {
      classes += ' cc-floating-input-invalid'
      validationMessage = this.props.validationMessage
    }

    return (
      <div className="floating-input-row">
        <Input
          size="large"
          value={value}
          disabled={this.props.disabled}
          onKeyPress={this.props.onKeyPress}
          placeholder={this.props.name}
          name={this.props.id ? this.props.id : this.props.nameField}
          onChange={this.valueChanged}
          onBlur={this.props.onBlur}
          className={classes}
          id={this.props.id}
        />
        <span className={placeholderClasses}>{this.props.name}</span>
        {isNil(validationMessage) ? null : (
          <div className="cc-small-font cc-validation-message">
            {validationMessage}
          </div>
        )}
      </div>
    )
  }
}

export default InvestorFloatingInput
