import { Component } from 'react'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, DatePicker, InputNumber, Spin, Table } from 'antd'
import moment from 'moment'
import numeral from 'numeral'
import ActionButton from '../ActionButton'
import Profiles from '../Profiles'
import TableHeader from '../TableHeader'
import TagGroup from '../TagGroup'
import './CCTable.less'

export const CCTableTagGroupCell = 'CCTABLE_TAG_GROUP_CELL'
export const CCTableActionCell = 'CCTABLE_ACTION_CELL'
export const CCTableProfilePicsCell = 'CCTABLE_PROFILE_PICS_CELL'
export const CCTableDateTimeCell = 'CCTABLE_DATETIME_CELL'
export const CCTableCurrencyCell = 'CCTABLE_CURRENCY_CELL'
export const CCTableYesNoCell = 'CCTABLE_YESNO_CELL'
export const CCTablePercentageCell = 'CCTABLE_PERCENTAGE_CELL'
export const CCTableDollarChangeCell = 'CCTABLE_DOLLAR_CHANGE_CELL'

const { YearPicker, MonthPicker, WeekPicker } = DatePicker

class CCTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: this.initColumns(),
      pagination: null,
      filters: null,
      filterArray: null,
      sorter: null,
      searchTerm: null,
      tableChanged: false,
      loading: true,
    }
  }

  initColumns() {
    const rawColumns =
      typeof this.props.getColumnsMethod === 'function'
        ? this.props.getColumnsMethod()
        : this.props.columns

    if (typeof rawColumns === 'undefined') return
    const newColumns = rawColumns.map((x) => {
      if (
        typeof x.filtersFetchMethod !== 'undefined' &&
        typeof x.filtersFetchMethod === 'function'
      ) {
        console.log('filters fetch method defined')
        if (x.filters !== x.filtersState) {
          x.filtersFetchMethod()
        }
      }
      if (typeof x.cellType === 'undefined') {
        x.cellType = 'DEFAULT'
      }
      return x
    })
    return newColumns
  }

  fillFilterElementsForColumns(rawColumns, onInit) {
    if (typeof rawColumns === 'undefined') return
    if (typeof this.state.columns === 'undefined') return

    let changed = false
    const newColumns = rawColumns.map((x) => {
      let currEl = this.state.columns.find(
        (c) => c.filtersState === x.filtersState && c.title === x.title
      )
      if (typeof currEl === 'undefined') {
        if (
          typeof x.filterMappingMethod !== 'undefined' &&
          typeof x.filterMappingMethod === 'function'
        ) {
          x.filters = x.filtersState.map((el) => x.filterMappingMethod(el))
        } else {
          x.filters = x.filtersState
        }
        changed = true
      } else {
        x.filters = currEl.filters
        x.filterDropdown = currEl.filterDropdown
        x.columnType = currEl.columnType
        x.filterMultiple = currEl.filterMultiple
        x.dateTimeFormat = currEl.dateTimeFormat
      }

      currEl = this.state.columns.find(
        (c) => c.cellType === x.cellType && c.title === x.title
      )
      if (typeof currEl === 'undefined' || changed || onInit) {
        switch (x.cellType) {
          case CCTableProfilePicsCell:
            // eslint-disable-next-line no-case-declarations
            const profilesMethodDefined =
              typeof x.profilesProperty === 'function'
            // eslint-disable-next-line no-constant-condition
            if (typeof (x.dataIndex !== 'undefined')) delete x.dataIndex
            x.render = (record) => (
              <Profiles
                profiles={
                  profilesMethodDefined
                    ? x.profilesProperty(record)
                    : record.profiles
                }
                maxImages={x.maxImages}
              />
            )
            break
          case CCTableTagGroupCell:
            // eslint-disable-next-line no-var
            var itemsMethodDefined = typeof x.itemsMethod === 'function'
            //delete x.dataIndex;
            x.render = (record) => (
              <TagGroup
                color={x.tagGroupColor}
                maxLength={x.tagGroupMaxLength}
                items={
                  itemsMethodDefined ? x.itemsMethod(record) : record.tagItems
                }
              />
            )
            break
          case CCTableActionCell:
            // eslint-disable-next-line no-constant-condition
            if (typeof (x.dataIndex !== 'undefined')) delete x.dataIndex
            x.render = (record) => (
              <ActionButton record={record} menu={x.menuItems} />
            )
            break
          case CCTableDateTimeCell:
            if (typeof x.key !== 'undefined') delete x.key

            if (!x.render) {
              x.render = (value) => {
                const format = x.dateTimeFormat || 'DD/MM/YYYY'
                return (
                  <span>
                    {value === null ? '' : moment(value).format(format)}
                  </span>
                )
              }
            }

            x.columnType = 'DateTime'
            if (typeof x.isFiltered !== 'undefined') {
              x.filterDropdown = ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
              }) =>
                this.datePickerFilterDropdown(
                  x.dataIndex,
                  x.datePickerType,
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters
                )
            }
            break
          case CCTableCurrencyCell:
            if (typeof x.key !== 'undefined') {
              if (typeof x.dataIndex === 'undefined') x.dataIndex = x.key
              delete x.key
            }
            x.render = (value) => {
              if (value === null || value === undefined) {
                return '-'
              }
              return numeral(value).format('($0,0[.]00 a)').toUpperCase()
            }
            x.filterDropdown = ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) =>
              this.numericFilterDropdown(
                x.dataIndex,
                x.step,
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters
              )
            x.columnType = 'Numeric'
            break
          case CCTableYesNoCell:
            if (typeof x.key !== 'undefined') {
              if (typeof x.dataIndex === 'undefined') x.dataIndex = x.key
              delete x.key
            }
            x.filters = [
              { text: 'YES', value: true },
              { text: 'NO', value: false },
            ]
            x.columnType = 'Boolean'
            x.filterMultiple = false
            x.render = (value) => <span>{value === true ? 'YES' : 'NO'}</span>
            break
          case CCTablePercentageCell:
            x.render = (value) =>
              value > 0 ? (
                <>
                  <span className="cc-positive-number">+{value}% </span>
                  <span className="cc-positive-number">
                    <FontAwesomeIcon icon={faArrowUp} color="green" />
                  </span>
                </>
              ) : (
                <>
                  <span className="cc-negative-number">+{value}% </span>
                  <span>
                    <FontAwesomeIcon icon={faArrowDown} color="red" />
                  </span>
                </>
              )
            break

          case CCTableDollarChangeCell:
            x.render = (value) =>
              value > 0 ? (
                <>
                  <span className="cc-positive-number">
                    +{numeral(value).format('($0,0[.]00 a)').toUpperCase()}
                  </span>
                  <span className="cc-positive-number">
                    <FontAwesomeIcon icon={faArrowUp} color="green" />
                  </span>
                </>
              ) : (
                <>
                  <span className="cc-negative-number">
                    {' '}
                    -{numeral(value).format('($0,0[.]00 a)').toUpperCase()}
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faArrowDown} color="red" />
                  </span>
                </>
              )
            break
          default:
            break
        }
        changed = true
      } else {
        x.render = currEl.render
      }

      return x
    })

    if (changed) {
      this.setState({
        columns: newColumns,
        loading: false,
      })
    }

    return newColumns
  }

  getDatePicker(pickerType) {
    switch (pickerType) {
      case 'year':
        return YearPicker
      case 'month':
        return MonthPicker
      case 'week':
        return WeekPicker
      default:
        return DatePicker
    }
  }

  datePickerFilterDropdown(
    columnKey,
    datePickerType,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  ) {
    const CustomPicker = this.getDatePicker(datePickerType)
    return (
      <div style={{ padding: 8 }}>
        From:
        <CustomPicker
          disabledDate={(x) => this.disabledStartDate(x, selectedKeys[1])}
          value={selectedKeys[0]}
          onChange={(value) => setSelectedKeys([value, selectedKeys[1]])}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        To:
        <CustomPicker
          disabledDate={(x) => this.disabledEndDate(selectedKeys[0], x)}
          value={selectedKeys[1]}
          onChange={(value) => setSelectedKeys([selectedKeys[0], value])}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          //onClick={() => this.handleSearch(selectedKeys, confirm)}
          onClick={() => {
            const filters = this.state.filters || {}
            filters[columnKey] = [
              selectedKeys[0].valueOf(),
              selectedKeys[1].valueOf(),
            ]
            confirm()
          }}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            const filters = this.state.filters || {}
            delete filters[columnKey]
            clearFilters()
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    )
  }

  numericFilterDropdown(
    columnKey,
    step = 1,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  ) {
    return (
      <div style={{ padding: 8 }}>
        From:
        <InputNumber
          step={step}
          value={selectedKeys[0]}
          onChange={(value) => setSelectedKeys([value, selectedKeys[1]])}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        To:
        <InputNumber
          step={step}
          value={selectedKeys[1]}
          onChange={(value) => setSelectedKeys([selectedKeys[0], value])}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => {
            const filters = this.state.filters || {}
            filters[columnKey] = [selectedKeys[0], selectedKeys[1]]
            confirm()
          }}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            const filters = this.state.filters || {}
            delete filters[columnKey]
            clearFilters()
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    )
  }

  disabledStartDate = (startValue, endValue) => {
    if (!startValue || !endValue) return false

    return startValue.valueOf() > endValue.valueOf()
  }

  disabledEndDate = (startValue, endValue) => {
    if (!startValue || !endValue) return false

    return endValue.valueOf() <= startValue.valueOf()
  }

  componentDidMount() {
    if (typeof this.props.fetchMethod === 'function') {
      this.props.fetchMethod(
        this.state.pagination,
        this.state.filterArray,
        this.state.sorter,
        this.state.searchTerm
      )
    } else {
      this.fillFilterElementsForColumns(this.props.columns, true)
    }
  }

  componentDidUpdate() {
    if (this.state.tableChanged) {
      if (typeof this.props.fetchMethod !== 'undefined') {
        this.props.fetchMethod(
          this.state.pagination,
          this.state.filterArray,
          this.state.sorter,
          this.state.searchTerm
        )
      }

      this.setState({
        tableChanged: false,
        loading: true,
      })
    }

    // if (typeof this.props.getColumnsMethod === 'function') {
    //   this.fillFilterElementsForColumns(this.props.getColumnsMethod());
    // } else {
    //   this.fillFilterElementsForColumns(this.props.columns);
    // }
  }

  onColumnsFilter() {
    console.log('filtered')
  }

  onSearch(value) {
    console.log(`searched ${value}`)

    let { pagination } = this.state
    if (pagination === null) pagination = {}
    pagination.total = null

    this.setState({
      searchTerm: {
        searchValue: value,
      },
      pagination,

      tableChanged: true,
    })
  }

  onChange(pagination, filters, sorter) {
    // pagination = this.state.pagination : pagination;
    // filters = filters || this.state.filters;
    // sorter = sorter || this.state.sorter;
    console.log('params', pagination, filters, sorter)
    const filterArray = {
      columns: [],
    }

    for (const propertyName in filters) {
      const column = this.state.columns.find(
        this.findColumnFromProperty(propertyName)
      )
      const columnType =
        typeof column === 'undefined' ? null : column.columnType

      filterArray.columns.push({
        column: propertyName,
        values: filters[propertyName],
        columnType,
      })
    }

    if (
      this.state.filterArray !== filterArray &&
      this.state.pagination !== null
    ) {
      pagination.total = null
    }

    this.setState({
      pagination,
      filterArray,
      filters,
      sorter,
      tableChanged: true,
    })
  }

  findColumnFromProperty(propertyName) {
    return (x) => x.dataIndex === propertyName || x.key === propertyName
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (this.props.dataSource !== newProps.dataSource)
      this.setState({
        loading: false,
      })
  }

  render() {
    const { pagination } = this.props
    if (typeof pagination === 'object') {
      pagination.size = 'small'
      pagination.showSizeChanger = true
    }

    return (
      <>
        <TableHeader
          headerTitle={this.props.tableTitle}
          showColumnsFilter={this.props.showColumnsFilter}
          onColumnsFilter={this.onColumnsFilter}
          onSearch={this.onSearch.bind(this)}
        />
        <Spin size="large" spinning={this.state.loading}>
          <Table
            scroll={this.props.scroll}
            pagination={pagination}
            columns={this.state.columns}
            dataSource={this.props.dataSource}
            onChange={this.onChange.bind(this)}
            rowClassName={this.props.rowClassName}
            className="investors-list-table"
            rowKey="id"
            onRow={(record) => {
              if (this.props.clickEvent) {
                return {
                  onClick: () => {
                    this.props.clickEvent(record)
                  },
                }
              }
            }}
          />
        </Spin>
      </>
    )
  }
}

export default CCTable
