import { SWITCH_COMPANY } from '../constants/types/auth'

const initialState = {
  companyId: 0,
  role: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SWITCH_COMPANY:
      return action.payload
    default:
      return state
  }
}
