import { useEffect, useState } from 'react'
import { faArrowLeft, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Collapse, Divider, Row, Spin, message } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment'
import {
  getEventDates,
  getNOEventSchedule,
  splitSchedule,
} from '../../actions/events'
import { contactRows } from '../../actions/split'
import loaderImage from '../../assets/context_loader.gif'
import http from '../../utils/api'
import ArrayDragAndDrop from '../ArrayDragAndDrop/ArrayDragAndDrop'
import LoadingGif from '../LoadingGif'
import MeetingDragAndDrop from '../MeetingDragAndDrop/MeetingDragAndDrop'
import './noTimeSplit.less'

const { Panel } = Collapse

const NoTimeSplit = ({
  match: {
    params: { companyEventId },
  },
  history: { goBack },
}) => {
  const [attendingCompanyEvent, setAttendingCompanyEvent] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [load, setLoad] = useState(true)
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [scheduleDays, setScheduleDays] = useState(null)
  const [eventDates, setEventDates] = useState(null)

  const [meeting2, setMeeting2] = useState([])

  const [participants, setParticipants] = useState([])
  const [selected, setSelected] = useState([])
  const [arrayContacts, setArrayContacts] = useState([])
  const [currentRequestCount, setCurrentRequestCount] = useState(0)
  const [createScheduleDisabled, setCreateScheduleDisabled] = useState(false)

  const getSelected = (val, source, destination, selected) => {
    const sourceIndex = parseInt(source.split('-')[1], 10)
    const destinationIndex = parseInt(destination.split('-')[1], 10)
    const orgMeetings = [...meeting2]
    if (val[sourceIndex].rows.length < 1) {
      //Move every meeting to the destination
      meeting2.forEach((day) => {
        if (day.items[sourceIndex].rows.length > 0) {
          const toMoveMeetings = day.items[sourceIndex].rows
          const toUpdate = day
          toUpdate.items[sourceIndex].rows = []
          toMoveMeetings.forEach((element) => {
            toUpdate.items[destinationIndex].rows.push(element)
          })
          const toRemoveIndex = orgMeetings.findIndex(
            (x) => x.dayIndex == day.dayIndex
          )
          orgMeetings.splice(toRemoveIndex, 1)
          orgMeetings.push(toUpdate)
        }
      })
      setMeeting2(orgMeetings)
    }
    setSelected(val)
  }

  const getMeeting2 = (val, day) => {
    let count = currentRequestCount
    if (count < eventDates.length) {
      count += 1
      setCurrentRequestCount(count)
    }
    const currentIndex = meeting2.findIndex((m) => m.dayIndex == day)
    if (currentIndex != -1) {
      meeting2.splice(currentIndex, 1)
    }
    setMeeting2((prevArray) => [...prevArray, { dayIndex: day, items: val }])
  }

  const getDayButtons = () => {
    if (eventDates === null) return null
    else {
      return eventDates.map((x, index) => ({
        value: index.toString(),
        text: moment(x).format('dddd'),
      }))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let canContinue = true

    arrayContacts.forEach((value, index) => {
      meeting2.forEach((element) => {
        if (element.items[index].rows.length > 0 && value.rows.length <= 0) {
          message.error('A schedule with meetings, does not have Contacts!', 3)
          canContinue = false
          return
        }
      })
    })
    if (canContinue) {
      setLoading(true)
      splitSchedule({
        eventCompanyId: attendingCompanyEvent.eventCompanyId,
        contacts: arrayContacts,
        meetings: meeting2,
        eventId: attendingCompanyEvent.eventId,
      })
        .then((response) => {
          message.success('The schedule has been modified successfully!', 2)
        })
        .catch((error) => {
          message.error('An error has occurred!', 2)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    http
      .get(`allocators/scheduledetails/${companyEventId}`)
      .then((response) => {
        setAttendingCompanyEvent(response.data)
        const toUpdate = []
        const contacts = []
        if (response.data.eventSchedules.length > 0) {
          response.data.eventSchedules.forEach((schedule) => {
            toUpdate.push({ rows: schedule.eventScheduleContacts })
            schedule.eventScheduleContacts.forEach((cont) => {
              if (!contacts.some((v) => v.contactId == cont.contactId)) {
                contacts.push(cont)
              }
            })
          })
        }

        //Check rows for participants without schedule
        const withoutSchedule = response.data.contacts.filter(
          (c) => !contacts.some((contact) => c.contactId == contact.contactId)
        )
        withoutSchedule.forEach((c) => {
          toUpdate.push({ rows: [c] })
          contacts.push(c)
        })
        setArrayContacts(toUpdate)
        setParticipants(contacts)
      })
      .finally(function () {
        setLoad(false)
      })
      .catch(function (error) {})
  }, [])

  useEffect(() => {
    if (load && attendingCompanyEvent) {
      getEventDates(attendingCompanyEvent.eventId)
        .then((dateResponse) => {
          setEventDates(dateResponse.data)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [attendingCompanyEvent, isLoading])

  useEffect(() => {
    if (isLoading === false && !isNil(attendingCompanyEvent)) {
      getNOEventSchedule(
        attendingCompanyEvent.eventId,
        attendingCompanyEvent.eventCompanyId
      )
        .then((response) => {
          setScheduleDays(response.data)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [attendingCompanyEvent, refresh, isLoading])

  useEffect(() => {
    if (arrayContacts.length <= 3) {
      setCreateScheduleDisabled(false)
    } else {
      setCreateScheduleDisabled(true)
    }
  }, [arrayContacts])

  if (isNil(attendingCompanyEvent) || isNil(scheduleDays)) {
    return (
      <Spin
        spinning={loading}
        style={{ width: '100%', height: '100%', paddingTop: '200px' }}
        indicator={
          <img style={{ width: '100px', height: '100px' }} src={loaderImage} />
        }
      ></Spin>
    )
  }

  const createSchedule = () => {
    setArrayContacts([...arrayContacts, { rows: [] }])
  }

  return (
    <>
      <div className="ScheduleDetails">
        <div>
          <Row>
            <Col span={12} style={{ display: 'flex' }}>
              <Button
                onClick={() => goBack()}
                style={{ marginRight: '10px', padding: '0px 10px' }}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>

              <h1
                className="cc-section-header-title"
                style={{ lineHeight: '30px' }}
              >
                Manage Schedules
              </h1>
            </Col>

            <Col span={12} style={{ textAlign: 'right' }}></Col>
          </Row>
        </div>
      </div>
      <Row style={{ marginTop: '20px', backgroundColor: 'white' }}>
        <LoadingGif spinning={false}>
          <Col span={18} offset={3} style={{ padding: '20px' }}>
            <h5 className="heading5"> {attendingCompanyEvent.company.name}</h5>
            <span className="requestSender" style={{ marginTop: '20px' }}>
              PARTICIPANTS
            </span>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ marginLeft: '-20px' }}
            >
              {participants &&
                contactRows(participants, attendingCompanyEvent.eventCompanyId)}
            </Row>
            <Divider style={{ marginTop: '20px' }} />
          </Col>

          <Col span={18} offset={3}>
            <div
              style={{
                display: 'flex',
                overflowX: 'scroll',
                overflowY: 'hidden',
              }}
            >
              <ArrayDragAndDrop
                contacts={arrayContacts}
                sendSelected={getSelected}
                participants={participants}
              />
            </div>
          </Col>
          <Col
            span={1}
            style={{
              backgroundColor: '#2FC5D2',
              borderRadius: '5px',
              minHeight: '150px',
              marginTop: '15px',
              position: 'relative',
              paddingLeft: '0',
            }}
          >
            <Button
              onClick={createSchedule}
              disabled={createScheduleDisabled}
              style={{
                top: '50%',
                backgroundColor: 'transparent',
                border: '0',
                boxShadow: '0 0 0 0',
                color: '#FFF',
                position: 'absolute',
                transform: 'translateY(-50%)',
              }}
            >
              <FontAwesomeIcon size="2x" icon={faPlus} />
            </Button>
          </Col>
          <Col span={18} offset={3} style={{ padding: '20px' }}>
            <Divider style={{ marginTop: '20px' }} />
            <h2
              style={{
                color: '#2FC5D2',
                fontWeight: '600',
              }}
            >
              Confirmed Meetings
            </h2>
            <Collapse
              bordered={false}
              style={{ backgroundColor: 'white' }}
              defaultActiveKey={getDayButtons().map((day, index) => index)}
            >
              {attendingCompanyEvent.isOptimized &&
                eventDates &&
                getDayButtons().map((day, index) => (
                  <Panel header={day.text}>
                    <h2
                      className="heading2"
                      style={{
                        fontSize: '30px',
                        color: '#2FC5D2',
                        marginTop: '23px',
                        fontWeight: '600',
                      }}
                    ></h2>
                    <div
                      style={{
                        display: 'flex',
                        overflowX: 'scroll',
                        overflowY: 'hidden',
                      }}
                    >
                      <MeetingDragAndDrop
                        contacts={arrayContacts}
                        eventDate={eventDates[index]}
                        attendingCompanyEvent={attendingCompanyEvent}
                        companyEventId={attendingCompanyEvent.eventCompanyId}
                        eventId={attendingCompanyEvent.eventId}
                        sendSelected={getMeeting2}
                        dayIndex={index}
                        isEventOptimized={true}
                      />
                    </div>
                  </Panel>
                ))}
            </Collapse>
            {!attendingCompanyEvent.isOptimized && eventDates && (
              <>
                <div
                  style={{
                    display: 'flex',
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                  }}
                >
                  <MeetingDragAndDrop
                    contacts={arrayContacts}
                    eventDate={eventDates}
                    attendingCompanyEvent={attendingCompanyEvent}
                    companyEventId={attendingCompanyEvent.eventCompanyId}
                    eventId={attendingCompanyEvent.eventId}
                    sendSelected={getMeeting2}
                    dayIndex={0}
                    isEventOptimized={false}
                  />
                </div>
              </>
            )}
          </Col>
          <Col span={18} offset={3} style={{ padding: '20px' }}>
            <Divider style={{ marginTop: '20px' }} />
            <h2
              style={{
                color: '#2FC5D2',
                fontWeight: '600',
              }}
            >
              Pending Meetings
            </h2>

            {eventDates && (
              <>
                <div
                  style={{
                    display: 'flex',
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                  }}
                >
                  <MeetingDragAndDrop
                    pendingMeetings={true}
                    contacts={arrayContacts}
                    eventDate={eventDates}
                    attendingCompanyEvent={attendingCompanyEvent}
                    companyEventId={attendingCompanyEvent.eventCompanyId}
                    eventId={attendingCompanyEvent.eventId}
                    sendSelected={getMeeting2}
                    dayIndex={-1}
                    isEventOptimized={false}
                  />
                </div>
              </>
            )}
          </Col>

          <Col span={8} offset={10}>
            <Button
              type="submit"
              className="ant-btn-primary"
              style={{ margin: '34px', width: '30%' }}
              onClick={handleSubmit}
            >
              {' '}
              Save{' '}
            </Button>
          </Col>
        </LoadingGif>
      </Row>
    </>
  )
}

export default NoTimeSplit
