import { useContext } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'antd'
import isEmpty from 'lodash/isEmpty'
import FormCtx from '../../../utils/contexts/FormCtx'
import useFormAnswers from '../../../utils/hooks/useFormAnswers'
import ConditionalCheckboxField from './ConditionalCheckboxField'
import RequirableField from './RequirableField'
import '../Fields.less'

const CheckboxField = ({ question }) => {
  const { questions, answers, onSetAnswers } = useContext(FormCtx)
  const {
    answer,
    handleOptionsAnswerChange,
    isRequiredForApplication,
    handleRequirementChange,
  } = useFormAnswers(question)
  const chosenAnswerIds = answer.questionAnswerIds

  const selectedQuestion = questions.find(
    (filteredquestion) =>
      filteredquestion.shortName.includes('Sub') &&
      filteredquestion.shortName.replace('Sub', '') === question.shortName
  ) || { questionId: 0, questionAnswers: [] }

  const unFilteredAnswers = answers.filter(
    (a) =>
      a.questionId !== selectedQuestion.questionId &&
      a.questionId !== question.questionId
  )
  const filteredAnswer = answers.find(
    (a) => a.questionId === selectedQuestion.questionId
  ) || { questionAnswerIds: [] }

  const onChange = (e) => {
    const checkboxQuestions = selectedQuestion.questionAnswers?.filter(
      (questionAnswer) => e.includes(questionAnswer.conditionalQuestionAnswerId)
    )
    const filteredCheckboxQuestionIds = checkboxQuestions.map(
      (c) => c.questionAnswerId
    )

    selectedQuestion.questionId === 0
      ? handleOptionsAnswerChange(e)
      : onSetAnswers([
          ...unFilteredAnswers,
          {
            questionId: selectedQuestion.questionId,
            shortName: selectedQuestion.shortName,
            dependentOnQuestion: isEmpty(selectedQuestion.attributes)
              ? null
              : selectedQuestion.attributes.dependentOnQuestion,
            questionAnswerIds: filteredAnswer.questionAnswerIds.filter((q) =>
              filteredCheckboxQuestionIds.includes(q)
            ),
          },
          {
            questionId: question.questionId,
            shortName: question.shortName,
            dependentOnQuestion: isEmpty(question.attributes)
              ? null
              : question.attributes.dependentOnQuestion,
            questionAnswerIds: e,
          },
        ])
  }

  return (
    <>
      {question.text && <div className="Text">{question.text}</div>}
      <RequirableField
        allowRequirement={question.allowRequirementSelection}
        checked={isRequiredForApplication}
        onChange={handleRequirementChange}
        checkboxStyle={{ marginTop: '10px' }}
      >
        <Checkbox.Group
          className="CheckboxField-box"
          value={answer.questionAnswerIds}
          onChange={(e) => onChange(e)}
        >
          {question.questionAnswers.map((q, key) => (
            <div key={key}>
              <Checkbox key={key} value={q.questionAnswerId}>
                {q.answer}
              </Checkbox>

              {chosenAnswerIds.includes(q.questionAnswerId) && (
                <ConditionalCheckboxField
                  questionAnswerId={q.questionAnswerId}
                  selectedQuestion={selectedQuestion}
                />
              )}
            </div>
          ))}
        </Checkbox.Group>
      </RequirableField>
    </>
  )
}

CheckboxField.propTypes = {
  question: PropTypes.object,
}

export default CheckboxField
