import { Component } from 'react'
import PropTypes from 'prop-types'
import {
  faClock,
  faComment,
  faUserClock,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Dropdown, Menu, Tooltip } from 'antd'
import { isEmpty, isNil, map, trim } from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import { getDeclinedContextList } from '../../actions/events'
import {
  fetchDeclinedMeetingRequestsByCompany,
  fetchDeclinedMeetingRequestsByCompanyEventId,
} from '../../actions/meetings'
import http from '../../utils/api'
import { getInitials } from '../../utils/helpers'
import AcceptMeetingComponent from '../AcceptMeetingComponent'
import AvatarGroup from '../AvatarGroup'
import ContactName from '../ContactName'
import ContextTable from '../ContextTable'
import ProfilePictureGroup from '../ProfilePictureGroup'
import ResponsiveCard from '../Responsive/ResponsiveCards/OnlineMeetingRecivedRequestResponsiveCard'
import TimeDisplay from '../TimeDisplay'
import './DeclinedRequestsTable.less'

class DeclinedRequestsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      refreshTable: this.props.refreshTable,
      declinedRequestsList: null,
    }
  }
  static propTypes = {
    // fetchDeclinedMeetingRequestsByCompany: PropTypes.func.isRequired,
    // fetchDeclinedMeetingRequestsByCompanyEventId: PropTypes.func.isRequired,
    //meetingType: PropTypes.string.isRequired,
    eventParticipantId: PropTypes.number.isRequired,
    //fromSummit: PropTypes.bool,
    //width: PropTypes.number.isRequired,
    refreshTable: PropTypes.bool,
  }

  // componentDidMount() {
  //   http
  //     .post(
  //       `/meeting/summit/declined?companyEventId=` + this.props.companyEventId,
  //       {
  //         pagination: { pageSize: 20 },
  //         sorter: {},
  //         searchTerm: {},
  //         filters: {},
  //       }
  //     )
  //     .then((res) => {
  //       //let count = this.state.cnt;
  //       this.setState({ declinedRequestsList: res.data.result });
  //     });
  // }

  renderParticipantsAvatars = (record) => (
    <div style={{ display: 'flex', justifyContent: 'left' }}>
      <AvatarGroup
        showInitials
        avatars={record?.colleagues?.map((c) => ({
          id: c.contactId,
          img: c.imageUrl,
          description: c.contactName,
          initials: getInitials(c.contactName),
          firstName: c.firstName,
          lastName: c.lastName,
        }))}
        style={{ height: '24px' }}
      />
      <Divider type="vertical" style={{ color: '#C2C6CC', height: '34px' }} />
      <AvatarGroup
        showInitials
        avatars={map(record?.participants, (c) => ({
          id: c.contactId,
          img: c.imageUrl,
          description: c.contactName,
          initials: getInitials(c.contactName),
          firstName: c.firstName,
          lastName: c.lastName,
        }))}
        style={{ height: '24px' }}
      />{' '}
    </div>
  )

  renderParticipantsAvatars = (record) => (
    <div style={{ display: 'flex', justifyContent: 'left' }}>
      <ProfilePictureGroup
        diameter={32}
        profiles={record?.allMembers?.map((c) => ({
          image: c.imageUrl,
          name: c.contactName,
          firstName: c.firstName,
          lastName: c.lastName,
        }))}
        maxImages="3"
        style={{ display: 'inline-block', marginTop: '4px' }}
      />
    </div>
  )

  renderContactThatCancelled = (cancellationReasons) => {
    if (isNil(cancellationReasons)) return
    const {
      userThatCancelledOrDeclined,
      cancellationReasonId,
      cancellationReasonText,
    } = cancellationReasons
    return (
      userThatCancelledOrDeclined && (
        <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
          <ContactName
            marginTop="0px"
            name={userThatCancelledOrDeclined.contactName}
            imageUrl={userThatCancelledOrDeclined.imageUrl}
            fontClass="cc-body-text"
          />
          {cancellationReasonId > 0 && (
            <span style={{ marginLeft: '8px' }}>
              <Tooltip title={cancellationReasonText}>
                <FontAwesomeIcon color="#92949C" icon={faComment} />
              </Tooltip>
            </span>
          )}
        </div>
      )
    )
  }

  renderDeclinedStatus = (record) => (
    <span
      className={`cc-body-text cc-status-${record.meetingStatus.toLowerCase()}`}
    >
      {record.meetingStatus}
    </span>
  )

  renderMember = (record) => (
    <ContactName
      name={record.member.contactName}
      imageUrl={record.member.imageURL}
      fontClass="cc-body-text"
    />
  )

  renderCompany = (record) => {
    if (!isNil(record.companies)) {
      return record.companies.map((company) => (
        <>
          {company.companyName}
          <span>{' (' + company.category + ')'}</span>
          {record.carryContextPoints && (
            <span className="cc-context-points-label">
              <span>+1000 Context Points</span>
            </span>
          )}
          <br></br>
        </>
      ))
    } else {
      return (
        <div>
          {record.company.companyName}
          {record.carryContextPoints && (
            <span className="cc-context-points-label">
              <span>+1000 Context Points</span>
            </span>
          )}
        </div>
      )
    }
  }

  renderActions = (record) => {
    const { meetingType } = this.props
    if (meetingType === 'online') {
      return (
        <div className="cc-meeting-actions">
          <AcceptMeetingComponent
            buttonType="primary"
            company={record.company.companyName}
            meetingId={record.meetingId}
            meetingType={record.meetingType}
            onAccept={() => {
              this.setState((state) => ({
                refreshTable: !state.refreshTable,
              }))
            }}
          />
        </div>
      )
    } else {
      return (
        //to do
        <div className="cc-meeting-actions">
          {!this.props.isPast && (
            <Button type="primary" className="cc-btn cc-btn-success">
              <i className="cc-btn-bg" />
              {'Send Meeting Request'}
            </Button>
          )}
        </div>
      )
    }
  }

  getColumns = () => {
    const menu = (
      <Menu>
        <Menu.Item
          key={TimeDisplay.ORIGINAL}
          onClick={(e) =>
            this.setState({
              displayTimezone: e.key,
              displayTimezoneLabel: 'Event Timezone',
            })
          }
        >
          <FontAwesomeIcon icon={faClock} />
          <span>
            Show in <b>Event</b> Timezone
          </span>
        </Menu.Item>
        <Menu.Item
          key={TimeDisplay.LOCAL}
          onClick={(e) =>
            this.setState({
              displayTimezone: e.key,
              displayTimezoneLabel: 'Local Timezone',
            })
          }
        >
          <FontAwesomeIcon icon={faUserClock} />
          <span>
            Show in your <b>Local</b> Timezone
          </span>
        </Menu.Item>
      </Menu>
    )

    const columns = [
      {
        key: '1',
        title: 'COMPANIES',
        //dataIndex: 'company.companyName',
        className: 'cc-body-text',
        render: this.renderCompany,
      },
      {
        key: '2',
        title: 'MEMBERS',
        render: this.renderParticipantsAvatars,
        //   notRenderInCard: true,
      },

      {
        key: '4',
        title: ' Meeting Date & Time',
        dataIndex: 'meetingDateTime',
        render: (meetingDateTime) =>
          moment(meetingDateTime).isValid()
            ? moment.utc(meetingDateTime).local().format('YYYY-MM-DD hh:mm a')
            : 'TBD',
      },
      {
        key: '5',
        title: ' Declined/Cancelled By',
        render: this.renderContactThatCancelled,
        dataIndex: 'cancellationReasons',
        align: 'left',
        notRenderInCard: true, //fix for later
      },
      {
        key: '6',
        title: 'STATUS',
        render: this.renderDeclinedStatus,
        align: 'center',
      },
      /* {
          key: '6',
         title: 'ACTIONS',
        render: this.renderActions,
        align: 'center',
        notRenderInCard: true,
      }, */
    ]

    // if (meetingType === 'online')
    //   return columns.filter(x => x.title !== 'STATUS');
    return columns
  }

  render() {
    // const {
    //    fetchDeclinedMeetingRequestsByCompany,
    //   fetchDeclinedMeetingRequestsByCompanyEventId,
    //   companyEventId,
    //   fromSummit,
    //   declinedRequestsList,
    //   width,
    //   meetingType,
    // } = this.props;

    // if (width < 1025) {
    //   return (
    //     <ResponsiveCard
    //       columns={this.getColumns()}
    //       requests={declinedRequestsList.result || []}
    //       meetingType={meetingType}
    //     />
    //   );
    // } else {

    return (
      <div>
        <ContextTable
          tableTitle="Meetings"
          key="id"
          columns={this.getColumns()}
          eventParticipantId={this.props.eventParticipantId}
          fundEventId={this.props.fundEventId}
          eventCompanyId={this.props.eventCompanyId}
          fundId={this.props.fundId}
          companyContactId={this.props.companyContactId}
          dataMethod={(pagination, filters, sorter, searchTerm) =>
            getDeclinedContextList(
              pagination,
              filters,
              sorter,
              searchTerm,
              null,
              this.props.eventParticipantId,
              this.props.fundEventId,
              this.props.eventCompanyId,
              this.props.itemId ?? 0,
              this.props.type,
              this.props.eventCompanyBoothFundId,
              this.props.eventCompanyBoothId
            )
          }
          refresh={this.props.refreshMeetings}
          refreshData={this.props.refreshMeetings}
          companyId={this.props.companyId}
        />
      </div>
    )
    //}
  }
}

export default DeclinedRequestsTable
