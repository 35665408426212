import http from '../utils/api'

export const getFundsList = (pagination, filters, sorter, searchTerm) =>
  http.post('/funds', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getFundsListAttendees = (
  eventId,
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post(`/events/${eventId}/funds`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const fetchFundProfile = (fundId) => http.get(`funds/${fundId}/profile`)

export const fetchFundDetails = (fundId) => http.get(`funds/${fundId}/details`)

export const fetchFundManagers = (fundId) =>
  http.get(`funds/${fundId}/managers`)

export const getFundsByCompany = () => http.get('companies/funds')

export const getCountries = () => http.get('/countries')

export const getTiers = () => http.get('/tiers')

export const getType = () => ({
  data: [{ id: 1, value: 'Manager', name: 'Manager' }],
})

export const getCategories = () => http.get('/serviceproviders/categories')

export const getSubStrategies = () => http.get('funds/substrategies')

export const getBroadStrategies = () => http.get('funds/broadstrategies')

export const getEventFundsForMeetingRequest = (
  eventId,
  fromCompanyId,
  toCompanyId
) =>
  http.get(
    `events/${eventId}/fromCompanyId/${fromCompanyId}/toCompanyId/${toCompanyId}/funds`
  )
