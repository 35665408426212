import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faComment } from '@fortawesome/pro-regular-svg-icons'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import isNil from 'lodash/isNil'
import './RatingDisplay.less'

const RatingDisplay = ({
  WouldMeetAgain,
  Rating,
  Comment,
  isFundsScreen,
  //MainScreen
  //RatingIndications
}) => {
  return (
    //rankng with numbers
    <div>
      {isNil(isFundsScreen) ? (
        isNil(WouldMeetAgain) ? (
          <>
            {' '}
            <FontAwesomeIcon color="#F19F00" icon={faStar} />{' '}
            <span>{Rating}</span>{' '}
            {isNil(Comment) || Comment == '' ? (
              ' '
            ) : (
              <Tooltip placement="top" title={Comment}>
                <FontAwesomeIcon icon={faComment} />
              </Tooltip>
            )}
          </>
        ) : (
          <>
            {' '}
            {!WouldMeetAgain ? (
              <span style={{ color: '#D62B34' }}>No</span>
            ) : (
              <span style={{ color: '#24C477' }}>Yes</span>
            )}
          </>
        )
      ) : (
        <>
          <span style={{ color: '#24C477' }}>
            Yes: {Rating.yesRatingReceived}
          </span>{' '}
          <span style={{ color: '#D62B34' }}>
            No: {Rating.noRatingReceived}
          </span>
        </>
      )}
    </div>
  )
}
export default RatingDisplay
