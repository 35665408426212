import { getMandatesList } from '../../actions/mandates'
import Mandates from './Mandates'

const MandatesContainer = ({ history: { goBack } }) => {
  return (
    <>
      <Mandates
        displayTitle={true}
        displayAddButton={true}
        tableTitle={'List of Mandates'}
        //below should be the server call to get data
        getMandateListData={getMandatesList}
      />
    </>
  )
}

export default MandatesContainer
