import { useEffect, useRef, useState } from 'react'
import { Button, DatePicker, Modal } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { CSVDownload, CSVLink } from 'react-csv'
import { BASE_URL } from '../../config'
import http from '../../utils/api'

const ExportLogs = ({
  visible,
  handleCancel,
  logType,
  recordId,
  companyId,
  eventId,
}) => {
  const [loading, setLoading] = useState(true)
  const [log, setLog] = useState([])
  const [messageLog, setMessageLog] = useState([])
  const [enableDownload, setEnableDownload] = useState(false)

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const { RangePicker } = DatePicker
  const [requestDate, setRequetDate] = useState({
    startDate: '',
    endDate: '',
  })
  const [fileName, setFileName] = useState('')
  const [messagesFileName, setMessagesFileName] = useState('')
  const inputRef = useRef(null)
  const inputMessageRef = useRef(null)

  const logHeaders = [
    { label: 'Action', key: 'action' },
    { label: 'Status', key: 'status' },
    { label: 'Platform', key: 'platform' },
    { label: 'Contact Name', key: 'contact' },
    { label: 'Date', key: 'actionDate' },
    { label: 'Context User', key: 'user' },
    { label: 'Company Name', key: 'company' },
    { label: 'Event Name', key: 'event' },
    { label: 'Additional Info', key: 'additionalInfro' },
    { label: 'ToCompany', key: 'toCompany' },
    { label: 'ToCompanyParticipants', key: 'toCompanyParticipants' },
  ]
  const messageLogHeaders = [
    { label: 'Action', key: 'action' },
    { label: 'Platform', key: 'platform' },
    { label: 'Sender Contact Name', key: 'contactFrom' },
    { label: 'Sender Company Name', key: 'companyFrom' },
    { label: 'Reciever Company Name', key: 'companyTo' },
    { label: 'Message Text', key: 'message' },
    { label: 'Date', key: 'messageDate' },
    { label: 'Context User', key: 'user' },
    { label: 'Event Name', key: 'event' },
  ]

  // const http = axios.create({
  //   baseURL: BASE_URL,
  //   timeout: "30000",
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //   },
  // });

  const handleProccessLogs = () => {
    console.log(log)
    console.log(logType)
    setLoading(true)
    setEnableDownload(false)

    if (logType == 'user') {
      http
        .post('/user/' + recordId + '/export', requestDate)

        .then(function (response) {
          setLog(response.data)
          console.log(response.data)
          http
            .post('/user/' + recordId + '/exportMessages', requestDate)
            .then(function (responseMessage) {
              setMessageLog(responseMessage.data)
              console.log(responseMessage.data)
              setLoading(false)

              setEnableDownload(true)
              setFileName('UserActivity_ContactId_' + recordId)
              setMessagesFileName('UserMessages_ContactId_' + recordId)
              inputRef.current.link.click()
              inputMessageRef.current.link.click()
            })
            .catch(function (messageError) {
              // handle error
              console.log(messageError)
              setLoading(false)
            })
        })
        .catch(function (error) {
          // handle error
          console.log(error)
          setLoading(false)
        })
    }
    if (logType == 'company') {
      http
        .post('/company/' + recordId + '/export', requestDate)

        .then(function (response) {
          setLog(response.data)
          console.log(response.data)
          http
            .post('/company/' + recordId + '/exportMessages', requestDate)
            .then(function (responseMessage) {
              setMessageLog(responseMessage.data)
              console.log(responseMessage.data)

              setLoading(false)

              setEnableDownload(true)
              setFileName('CompanyActivity_CompanyId_' + recordId)
              setMessagesFileName('CompanyMessages_CompanyId_' + recordId)
              inputRef.current.link.click()
              inputMessageRef.current.link.click()
            })
            .catch(function (messageError) {
              // handle error
              console.log(messageError)
              setLoading(false)
            })
        })
        .catch(function (error) {
          // handle error
          console.log(error)
          setLoading(false)
        })
    }
    if (logType == 'companyEvent') {
      http
        .post(
          '/companyEvent/' +
            recordId +
            '/' +
            companyId +
            '/' +
            eventId +
            '/export',
          requestDate
        )

        .then(function (response) {
          setLog(response.data)
          console.log(response.data)
          setLoading(false)

          setEnableDownload(true)
          setFileName('CompanyEventActivity_CompanyId_' + recordId)
          inputRef.current.link.click()
        })
        .catch(function (error) {
          // handle error
          console.log(error)
          setLoading(false)
        })
    }
    if (logType == 'onlineCompany') {
      http
        .post('/onlineCompany/' + companyId + '/export', requestDate)

        .then(function (response) {
          setLog(response.data)
          console.log(response.data)
          setLoading(false)

          setEnableDownload(true)
          setFileName('OnlineCompanyActivity_CompanyId_' + recordId)
          inputRef.current.link.click()
        })
        .catch(function (error) {
          // handle error
          console.log(error)
          setLoading(false)
        })
    }
  }

  return (
    <Modal
      visible={visible}
      width="628px"
      title="Export Activity Log"
      bodyStyle={{ padding: 0 }}
      onCancel={() => {
        setEnableDownload(true)
        handleCancel()
      }}
      footer={[
        <Button
          onClick={() => {
            setEnableDownload(true)
            handleCancel()
          }}
          className="cancelBtn"
        >
          Close
        </Button>,
        <Button
          type="primary"
          onClick={handleProccessLogs}
          disabled={!enableDownload}
        >
          Download
        </Button>,
      ]}
    >
      <div style={{ padding: '13px 16px 32px 32px' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '280px' }}>
            <div style={{ marginTop: '16px' }} className="cc-heading4">
              Select dates
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginTop: '16px',
              }}
            >
              <RangePicker
                className="cc-background-input"
                size="large"
                width="100%"
                onChange={(value) => {
                  setRequetDate({
                    startDate: moment(value[0]),
                    endDate: moment(value[1]),
                  })

                  setEnableDownload(true)
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Button
        type="primary"
        style={{
          display: 'none',
        }}
      >
        <CSVLink
          ref={inputRef}
          data={log}
          headers={logHeaders}
          filename={fileName + '.csv'}
          target="_blank"
        >
          Download
        </CSVLink>
      </Button>
      <Button
        type="primary"
        style={{
          display: 'none',
        }}
      >
        <CSVLink
          ref={inputMessageRef}
          data={messageLog}
          headers={messageLogHeaders}
          filename={messagesFileName + '.csv'}
          target="_blank"
        >
          Download Messages
        </CSVLink>
      </Button>
    </Modal>
  )
}

export default ExportLogs
