import { Tag } from 'antd'
import { isEmpty, isNil, join, map, replace, sum, take, trim } from 'lodash'
import validator from 'validator'

export const getInitials = (fullname) =>
  map(take(trim(fullname).split(/\s+/gi), 2), (part) =>
    part.substr(0, 1).toUpperCase()
  ).join('')

export const getClickableLink = (link) =>
  link?.startsWith('http://') || link?.startsWith('https://')
    ? link
    : `http://${link}`

export const getColor = (firstName, lastName) => {
  const fullname = trim(
    `${isEmpty(firstName) ? '' : firstName}${isEmpty(lastName) ? '' : lastName}`
  )

  const minCodeValue = 50
  const maxCodeValue = 255 - minCodeValue

  const red = isEmpty(firstName)
    ? 0
    : sum(map(replace(firstName, /\s+/gi, ''), (c) => c.charCodeAt(0)))
  const green = isEmpty(lastName)
    ? 0
    : sum(map(replace(lastName, /\s+/gi, ''), (c) => c.charCodeAt(0)))
  const blue = isEmpty(fullname)
    ? 0
    : sum(map(replace(fullname, /\s+/gi, ''), (c) => c.charCodeAt(0)))

  const hexCode = join(
    map([red, green, blue], (c) =>
      ((c % (maxCodeValue - minCodeValue)) + minCodeValue).toString(16)
    ),
    ''
  ).toUpperCase()

  return `#${hexCode}`
}

export const validURL = (str = '') =>
  str === '' || isNil(str) ? true : validator.isURL(str)

export const getStatusTag = (status) => {
  let color
  let backgroundColor
  switch (status) {
    case 'Draft':
      color = '#4D4F56'
      backgroundColor = 'rgba(77, 79, 86, 0.1)'
      break
    case 'Live':
      color = '#24C477'
      backgroundColor = 'rgba(36, 196, 119, 0.1)'
      break
    case 'Open':
      color = '#24C477'
      backgroundColor = 'rgba(36, 196, 119, 0.1)'
      break
    case 'Closed':
      color = '#D62B34'
      backgroundColor = 'rgba(214, 43, 52, 0.1)'
      break
    case 'Closed - Unfilled':
      color = '#D62B34'
      backgroundColor = 'rgba(214, 43, 52, 0.1)'
      break
    case 'Closed - Filled':
      color = '#D62B34'
      backgroundColor = 'rgba(214, 43, 52, 0.1)'
      break
    default:
      break
  }
  return (
    <Tag
      style={{
        borderRadius: '48px',
        border: 'none',
        backgroundColor,
        color,
        width: 'fit-content',
        textAlign: 'center',
      }}
    >
      {status}
    </Tag>
  )
}

export const getFileExtension = (fileName) => fileName.split('.').pop()

export const toMillions = (value) =>
  isNil(value) ? null : parseFloat(value) / 1_000_000
export const fromMillions = (value) =>
  isNil(value) ? null : parseFloat(value) * 1_000_000

export const formatCurrency = (value) => {
  if (value) return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  else return 'N/A'
}

export const formatNumber = (value) => {
  if (!value) {
    return null
  }
  try {
    const numberFormatter = Intl.NumberFormat('en-US')
    return numberFormatter.format(value)
  } catch {
    return value
  }
}
