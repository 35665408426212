import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getFundAllocatorEngagement } from '../../actions/funds'
import CenteredPrimaryRadioButtons from '../../components/CenteredPrimaryRadioButtons'
import { getFundAllocatorEngagementColumns } from '../../utils/columns'
import ContextTable from '../ContextTable'
import './FundAllocatorEngagement.less'

const FundAllocatorEngagement = ({ companyId }) => {
  const columns = getFundAllocatorEngagementColumns()

  const [days, setDays] = useState(90)
  const [refresh, setRefresh] = useState(false)

  const radioHandler = (e) => {
    setDays(e.target.value)
    setRefresh(!refresh)
  }

  const buttons = [
    {
      value: 7,
      text: '7 days',
    },
    {
      value: 30,
      text: '30 days',
    },
    {
      value: 90,
      text: '90 days',
    },
  ]

  return (
    <>
      <CenteredPrimaryRadioButtons
        selectedKey={days}
        buttons={buttons}
        OnClickFunction={radioHandler}
      />
      <ContextTable
        tableTitle="Allocator Engagement"
        columns={columns}
        dataMethod={getFundAllocatorEngagement.bind(null, companyId, days)}
        scroll={{ x: 1600 }}
        refreshData={refresh}
      />
    </>
  )
}

FundAllocatorEngagement.propTypes = { companyId: PropTypes.number.isRequired }

export default FundAllocatorEngagement
