import { useEffect, useState } from 'react'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, PageHeader, Row } from 'antd'
import http from '../../utils/api'

//import EditButton from '../EditButton/EditButton';
const ContactHeaderBackLink = ({ contactId }) => {
  const [contactData, setcontactData] = useState({})
  //const { Link } = Anchor;

  useEffect(() => {
    http.get(`contacts/details/${contactId}`).then((response) => {
      setcontactData(response.data[0])
    })
  }, [])

  return (
    <Row type="flex" justify="space-between">
      <Col span={11} style={{ display: 'flex' }}>
        <Button
          style={{ marginRight: '10px', padding: '0px 10px' }}
          href={`/users/${contactId}`}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <PageHeader
          style={{ paddingTop: '0px' }}
          title={
            'Edit' + ' ' + contactData.firstName + ' ' + contactData.lastName
          }
        />
      </Col>
    </Row>
  )
}

export default ContactHeaderBackLink
