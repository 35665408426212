import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Button } from 'antd'
import isEmpty from 'lodash/isEmpty'
import MandateWizardCtx from '../../../../utils/contexts/MandateWizardCtx'
import ContextForm from '../../../ContextForm/ContextForm'
import './Step.less'

const Step = ({
  title,
  alertMessage,
  questions,
  answers,
  isLast = false,
  isFirst = false,
  onSetAnswers,
  onNextStep,
  onPrevStep,
}) => {
  const {
    handleSubmit,
    invalidAnswers,
    setInvalidAnswers,
    isEdit,
    stepNumbers,
    requiredQuestionsForApplication,
    setRequiredQuestionsForApplication,
  } = useContext(MandateWizardCtx)
  const buttonComponent = () => {
    const disabled = !isEmpty(
      invalidAnswers.filter((a) => stepNumbers.includes(a.stepNumber))
    )
    return (
      <div className="Step-button">
        {!isFirst && (
          <Button className="Step-button-prev" type="text" onClick={onPrevStep}>
            <FontAwesomeIcon icon={faChevronLeft} /> Back
          </Button>
        )}
        {(isLast && (
          <Button
            className="Step-button-next"
            type="primary"
            htmlType="submit"
            disabled={disabled}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )) || (
          <Button
            className="Step-button-next"
            type="primary"
            htmlType="submit"
            onClick={onNextStep}
          >
            Next
          </Button>
        )}
      </div>
    )
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div
      className="Step"
      style={{
        alignItems: title === 'Mandate Overview' ? 'center' : 'flex-start',
        padding: title === 'Mandate Overview' ? '0px' : '0px 100px',
      }}
    >
      <div className="Step-title cc-heading3">{title}</div>
      {alertMessage && (
        <Alert
          type="info"
          style={{ marginBottom: '8px' }}
          showIcon
          description={alertMessage}
          message="Required Fields"
        />
      )}
      <div
        className="Step-form"
        style={{
          maxWidth: title === 'Mandate Overview' ? '1000px' : '100%',
          width: title === 'Mandate Overview' ? 'inherit' : '100%',
        }}
      >
        <ContextForm
          questions={questions}
          answers={answers}
          invalidAnswers={invalidAnswers}
          onSetAnswers={onSetAnswers}
          onSetInvalidAnswers={setInvalidAnswers}
          requiredQuestionsForApplication={requiredQuestionsForApplication}
          onSetRequiredQuestionsForApplication={
            setRequiredQuestionsForApplication
          }
          buttonComponent={buttonComponent}
          isEdit={isEdit}
        />
      </div>
    </div>
  )
}

Step.propTypes = {
  title: PropTypes.string,
  questions: PropTypes.array,
  answers: PropTypes.array,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  onSetAnswers: PropTypes.func,
  onNextStep: PropTypes.func,
  onPrevStep: PropTypes.func,
}

export default Step
