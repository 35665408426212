import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { Formik } from 'formik'
import { filter, get, map } from 'lodash'
import http from '../../utils/api'
import { getInitialValues } from '../DynamicForm/DynamicForm'
import FormField from '../Forms/Fields/FormField'
import { ServiceProviderValidatorShape } from '../ServiceProviderWizard/ServiceProviderValidatorShape'

const ServiceProviderDynamicFieldsModal = ({
  visible,
  handleOk,
  handleCancel,
  company,
  companyId,
  questions,
  answers,
  questionsWithOptions,
  section,
}) => {
  const [data, setData] = useState(company)
  const [stepNumber, setStepNumber] = useState(0)
  const validationSchema = ServiceProviderValidatorShape(questions)

  useEffect(() => {
    if (section == 'media') {
      setStepNumber(1)
    }
    if (section == 'testimonials') {
      setStepNumber(4)
    }
    if (section == 'clients') {
      setStepNumber(5)
    }
    if (section == 'content') {
      setStepNumber(6)
    }
  }, [section])

  const initialValues = getInitialValues(
    questions,
    answers,
    questionsWithOptions
  )

  const getStepQuestions = (stepNumber) =>
    filter(questions, (q) => q.stepNumber === stepNumber)

  const saveChanges = (values) => {
    const answersData = map(questions, (q) => ({
      serviceProviderQuestionId: q.serviceProviderQuestionId,
      shortName: q.shortName,
      serviceProviderQuestionAnswerIds: questionsWithOptions.has(
        q.questionTypeId
      )
        ? get(values, q.shortName, [])
        : [],
      responseText: !questionsWithOptions.has(q.questionTypeId)
        ? values[q.shortName]
        : null,
    }))

    http
      .patch(`/serviceproviders/0/${companyId}`, {
        answers: answersData,
      })
      .then((response) => {
        handleOk()
      })
  }

  const onClose = () => {
    setData(company)
    handleCancel()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        saveChanges(values)
      }}
    >
      {({
        values,
        errors,
        isValid,
        dirty,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        setErrors,
      }) => (
        <Modal
          className="cc-modal-accept"
          title={`Edit ${section} details`}
          visible={visible}
          okText={`Save Changes`}
          cancelText="Close"
          onOk={handleSubmit}
          onCancel={onClose}
          okButtonProps={{
            disabled: false,
            style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
          }}
          centered={true}
          cancelButtonProps={{ disabled: false }}
        >
          <div>
            {questions
              .filter((q) => q.stepNumber == stepNumber)
              .map((question) => (
                <div
                  style={section == 'media' ? { padding: '0px 0px 180px' } : {}}
                >
                  <FormField
                    key={question.serviceProviderQuestionId}
                    question={question}
                    values={values}
                    errors={errors}
                    //dependencies={fieldDependencies}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              ))}
          </div>
        </Modal>
      )}
    </Formik>
  )
}

ServiceProviderDynamicFieldsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
}

export default ServiceProviderDynamicFieldsModal
