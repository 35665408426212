import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Modal, Radio, message } from 'antd'
import { isEmpty, isNil } from 'lodash'
import { rescheduleMeeting } from '../../actions/contextMeetings'
import ContactName from '../ContactName'
import LoadingGif from '../LoadingGif'
import SelectOnlineMeetingDateTime from '../SelectOnlineMeetingDateTime'
import './RescheduleOnlineMeeting.less'
import '../../styles/index.less'

const RescheduleOnlineMeeting = ({
  onlineMeetingId,
  visible,
  closeModal,
  onRescheduleMeeting,
  participants,
  toParticipants,
  meetingDateTime,
  meetingDuration,
  colleagues,
  companyId,
}) => {
  const [reschedulingInProgress, setReschedulingInProgress] = useState(false)
  const [selectedDateTime, setSelectedDateTime] = useState({})
  const [duration, setDuration] = useState()
  const [selectedHour, setSelectedHour] = useState({})
  const tier = 'Pro'
  const [checkValue, setCheckValue] = useState(null)

  const onDurationChanged = (value) => {
    setDuration(value)
  }

  useEffect(() => {
    setDuration(meetingDuration || 30)
  }, [meetingDuration])

  const onModalClosed = useCallback(() => {
    closeModal()
    setSelectedDateTime({})
    setDuration(30)
    setSelectedHour({})
    setCheckValue(null)
  }, [closeModal])

  const onTimeChanged = (changed, newDate) => {
    if (changed === 'date') {
      setSelectedHour({})
    }
    if (changed === 'hour') {
      setSelectedHour(newDate)
    }
    if (!isNil(newDate)) {
      setSelectedDateTime({
        startDateTime: newDate.startTime,
        endDateTime: newDate.endTime,
      })
    }
  }

  const handleModalOk = useCallback(() => {
    setReschedulingInProgress(true)
    rescheduleMeeting(
      companyId,
      checkValue,
      onlineMeetingId,
      selectedDateTime.startDateTime,
      duration
    )
      .then(() => {
        message.success('The meeting has been rescheduled.')
        onModalClosed()
        onRescheduleMeeting(selectedDateTime.startDateTime)
      })
      .catch(() => {
        tier === 'Free' && onRescheduleMeeting(selectedDateTime.startDateTime)
      })
      .finally(() => {
        setReschedulingInProgress(false)
      })
  }, [
    onModalClosed,
    duration,
    onRescheduleMeeting,
    onlineMeetingId,
    selectedDateTime.startDateTime,
    tier,
  ])

  const onRadioChange = (e) => {
    setCheckValue(e.target.value)
  }

  return (
    <div className="cc-meeting-actions">
      <Modal
        destroyOnClose
        className="cc-modal-reschedule"
        title="Reschedule the Meeting"
        visible={visible}
        okText="Reschedule the Meeting"
        onOk={handleModalOk}
        onCancel={onModalClosed}
        okButtonProps={{
          loading: reschedulingInProgress,
          disabled:
            isEmpty(selectedDateTime) ||
            isEmpty(selectedHour) ||
            isNil(checkValue)
              ? true
              : false,
          size: 'large',
          style: {
            marginLeft: '24px',
            backgroundColor: '#F19F00',
            borderStyle: 'none',
          },
        }}
        cancelButtonProps={{ disabled: false, size: 'large' }}
        width={'758px'}
        bodyStyle={{ padding: '30px' }}
      >
        <LoadingGif spinning={reschedulingInProgress}>
          <div>
            <span
              className="cc-heading5"
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                color: '#4D4F56',
              }}
            >
              Choose the user on behalf you want to reschedule the meeting:
            </span>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                paddingTop: '16px',
              }}
            >
              <Radio.Group onChange={onRadioChange} value={checkValue}>
                {colleagues &&
                  colleagues.length > 0 &&
                  colleagues.map((contact) => (
                    <Col
                      span={24}
                      style={{ paddingBottom: '16px', paddingLeft: '0' }}
                    >
                      <div
                        key={contact.contactId.toString()}
                        style={{ width: '100%' }}
                      >
                        <Radio
                          value={contact.contactId}
                          style={{ paddingRight: '12px' }}
                        />
                        <ContactName
                          name={contact.contactName}
                          imageUrl={contact.imageURL}
                        />
                      </div>
                    </Col>
                  ))}
              </Radio.Group>
            </div>
          </div>
          <SelectOnlineMeetingDateTime
            onTimeChanged={onTimeChanged}
            onDurationChanged={onDurationChanged}
            toParticipants={toParticipants}
            participants={participants}
            dateTime={meetingDateTime}
            meetingDuration={meetingDuration}
            selectedOnBehalf={isNil(checkValue) ? false : true}
          />
        </LoadingGif>
      </Modal>
    </div>
  )
}

RescheduleOnlineMeeting.propTypes = {
  visible: PropTypes.bool.isRequired,
  onlineMeetingId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  onRescheduleMeeting: PropTypes.func.isRequired,
  participants: PropTypes.array,
  toParticipants: PropTypes.array,
  meetingDateTime: PropTypes.string,
  meetingDuration: PropTypes.number,
}

export default RescheduleOnlineMeeting
