import axios from 'axios'
import { BASE_URL, TIMEOUT } from '../config'

const http = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export default http
