import {
  FETCH_MEDIA_ATTENDEE_LIST,
  FETCH_MEDIA_FUNDS_ATTENDEE_LIST,
  FETCH_MEDIA_LIST,
} from '../constants/types'

const initialState = {
  mediaList: [],
  mediaAttendeeList: [],
  fundAttendeeList: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_MEDIA_LIST:
      return { ...state, mediaList: action.payload }

    case FETCH_MEDIA_ATTENDEE_LIST:
      return { ...state, mediaAttendeeList: action.payload }

    case FETCH_MEDIA_FUNDS_ATTENDEE_LIST:
      return { ...state, fundAttendeeList: action.payload }

    default:
      return state
  }
}
