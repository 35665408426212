import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { RESET_APP } from '../constants/types'
import api from './api'
import auth from './auth'
import company from './company'
import context from './context'
import events from './events'
import favorites from './favorites'
import fundList from './fundList'
import investorList from './investorList'
import media from './media'
import meetings from './meetings'
import messaging from './messaging'
import notifications from './notifications'
import portfolio from './portfolio'
import reports from './reports'
import serviceProviders from './serviceProviders'
import settings from './settings'
import user from './user'

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    api,
    auth,
    user,
    company,
    events,
    reports,
    meetings,
    investorList,
    fundList,
    serviceProviders,
    media,
    context,
    notifications,
    messaging,
    settings,
    portfolio,
    favorites,
  })

const rootReducer = (history) => (state, action) => {
  if (action.type === RESET_APP) {
    state = undefined
  }

  return appReducer(history)(state, action)
}

export default rootReducer
