import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faPaperPlane,
  faStar as lightStar,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Modal, Row, Tabs } from 'antd'
import { isEmpty, isNil } from 'lodash'
import { getDiscoverServiceProviderProfile } from '../../actions/serviceProviders'
import LoadingGif from '../LoadingGif'
import ServiceProviderClients from '../ServiceProviderPeople/ServiceProviderClients'
import ServiceProviderContact from '../ServiceProviderPeople/ServiceProviderContact'
import ServiceProviderContent from '../ServiceProviderPeople/ServiceProviderContent'
import ServiceProviderPeople from '../ServiceProviderPeople/ServiceProviderPeople'
import ServiceProviderTestimonials from '../ServiceProviderPeople/ServiceProviderTestimonials'
import DiscoverServiceProviderProfile from './DiscoverServiceProviderProfile'
import './DiscoverServiceProviderDetailsModal.less'
import '../../styles/index.less'

const { TabPane } = Tabs

const DiscoverServiceProviderDetailsModal = ({
  serviceProvider,
  companyId,
  visible,
  onCancel,
  canSendMeetingRequest,
  onSendRequest,
  onSendMessage,
  hideActions = false,
  isOnPreview = false,
  selectedFundId,
}) => {
  const [activeTabKey, setActiveTabKey] = useState('1')
  const [loading, setLoading] = useState(false)
  const [companyProfile, setCompanyProfile] = useState({})
  const [refresh, setRefresh] = useState(false)
  const [loadingMeetings, setLoadingMeetings] = useState(false)
  const [meetings, setMeetings] = useState(null)

  useEffect(() => {
    if (visible) {
      setLoading(true)
      setCompanyProfile({})
      setActiveTabKey('1')
      if (isOnPreview) {
        setCompanyProfile(serviceProvider)
        setLoading(false)
      } else {
        getDiscoverServiceProviderProfile(companyId)
          .then((response) => {
            setLoading(false)
            const profile = response.data
            setCompanyProfile(profile)
          })
          .catch(() => {
            setLoading(false)
            setCompanyProfile({})
          })
      }
    }
  }, [companyId, visible, isOnPreview, serviceProvider])

  const onTabChange = (tabKey) => {
    setActiveTabKey(tabKey)
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => onCancel()}
      width="1000px"
    >
      <div className="discover-service-provider-details-container">
        <LoadingGif spinning={loading}>
          <Row className="discover-service-provider-details-header">
            <Row className="discover-service-provider-details-title">
              <Col span={8}>
                <span className="cc-heading5">
                  {serviceProvider.companyName}
                </span>
                <br />
                <span className="discover-service-provider-details-location cc-small-font">
                  {serviceProvider.address} , {serviceProvider.city}
                </span>
              </Col>
              <Col
                className="discover-service-provider-details-buttons"
                span={16}
              >
                {!hideActions && (
                  <div className="">
                    <Button
                      type="primary"
                      style={{ borderStyle: 'none' }}
                      ghost={true}
                      className="discover-details-send-message"
                      onClick={onSendMessage}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} /> Send Message
                    </Button>
                    {canSendMeetingRequest && (
                      <Button type="primary" onClick={onSendRequest}>
                        Send meeting request
                      </Button>
                    )}
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Tabs
                className="discover-service-provider-details-tabSection"
                activeKey={activeTabKey}
                onChange={onTabChange}
              >
                <TabPane
                  tab={<span className="tab-pane">Overview</span>}
                  key="1"
                >
                  <DiscoverServiceProviderProfile
                    company={companyProfile}
                    companyId={companyId}
                  />
                </TabPane>
                {!isEmpty(companyProfile.people) && (
                  <TabPane
                    tab={<span className="tab-pane">People</span>}
                    key="3"
                  >
                    <ServiceProviderPeople
                      serviceProviders={companyProfile.people}
                    />
                  </TabPane>
                )}
                {!isEmpty(companyProfile.testimonials) && (
                  <TabPane
                    tab={<span className="tab-pane">Testimonials</span>}
                    key="4"
                  >
                    <ServiceProviderTestimonials
                      serviceProviders={companyProfile.testimonials}
                    />
                  </TabPane>
                )}
                {!isEmpty(companyProfile.clients) && (
                  <TabPane
                    tab={<span className="tab-pane">Clients</span>}
                    key="5"
                  >
                    <ServiceProviderClients
                      serviceProviders={companyProfile.clients}
                    />
                  </TabPane>
                )}
                {(!isNil(companyProfile.blogUrl) ||
                  !isNil(companyProfile.caseStudiesUrl) ||
                  !isNil(companyProfile.webCastsUrl) ||
                  !isNil(companyProfile.whitepapersAndGuidesUrl)) && (
                  <TabPane
                    tab={<span className="tab-pane">Content</span>}
                    key="6"
                  >
                    <ServiceProviderContent serviceProviders={companyProfile} />
                  </TabPane>
                )}
                <TabPane
                  tab={<span className="tab-pane">Contact Us</span>}
                  key="7"
                >
                  <ServiceProviderContact
                    serviceProviders={companyProfile}
                    onSendRequest={onSendRequest}
                    onSendMessage={onSendMessage}
                    canSendMeetingRequest={canSendMeetingRequest}
                    hideActions={hideActions}
                  />
                </TabPane>
              </Tabs>
            </Row>
          </Row>
        </LoadingGif>
      </div>
    </Modal>
  )
}

DiscoverServiceProviderDetailsModal.propTypes = {
  onMarkAsFavorite: PropTypes.func,
  onSendRequest: PropTypes.func,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  canSendMeetingRequest: PropTypes.bool,
  canSendMessage: PropTypes.bool,
  serviceProvider: PropTypes.object,
  onSendMessage: PropTypes.func,
  hideActions: PropTypes.bool,
  isOnPreview: PropTypes.bool,
  selectedFundId: PropTypes.number,
}

export default DiscoverServiceProviderDetailsModal
