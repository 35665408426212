import { useState } from 'react'
import { faFilter, faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Col, Dropdown, Menu, Row, Timeline } from 'antd'
import moment from 'moment'
import SearchBox from '../../components/SearchBox/SearchBox'
import './SalesforceLogs.less'
import './SalesforceLogsItem.less'

const SalesforceLogsItem = ({
  title,
  currentCategory,
  logs,
  handleSearch,
  handlePlatformChange,
  handleLogTypeChange,
}) => {
  const [visible, setVisible] = useState(false)
  const [platform, setPlatform] = useState(0)
  const [logTypes, setLogTypes] = useState([])

  const logEventTypes = [
    { title: 'Company Create', key: '15' },
    { title: 'Company Update', key: '16' },
    { title: 'Company Delete', key: '17' },

    { title: 'Contact Create', key: '18' },
    { title: 'Contact Update', key: '19' },
    { title: 'Contact Delete', key: '20' },

    { title: 'Event Create', key: '21' },
    { title: 'Event Udpate', key: '22' },

    { title: 'Online Registration Create', key: '24' },
    { title: 'Online Registration Update', key: '25' },
    { title: 'Online Registration Delete', key: '26' },

    { title: 'Event Registration Company', key: '27' },

    { title: 'Event Registration Contact', key: '36' },
  ]

  const onChangePlatform = (e) => {
    if (e.target.checked) setPlatform(e.target.value)
    else setPlatform(0)
    handlePlatformChange(e.target.checked === true ? e.target.value : 0)
  }

  const onChangeLogTypes = (e) => {
    if (e.target.checked) {
      const arr = logTypes
      setLogTypes([...arr, e.target.value])
      handleLogTypeChange([...arr, e.target.value])
    } else {
      const filtered = logTypes.filter((row) => row !== e.target.value)
      setLogTypes(filtered)
      handleLogTypeChange(filtered)
    }
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag)
  }

  const menu = (
    <Menu style={{ width: 300 }} mode="inline">
      <Menu.ItemGroup key="g1" title="Platform">
        <Menu.Item key="0">
          <Checkbox
            onChange={onChangePlatform}
            value={1}
            checked={platform === 1 ? true : false}
          >
            Backstage
          </Checkbox>
        </Menu.Item>
        <Menu.Item key="1">
          <Checkbox
            onChange={onChangePlatform}
            value={2}
            checked={platform === 2 ? true : false}
          >
            Salesforce
          </Checkbox>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )

  return (
    <>
      <Row style={{ paddingBottom: '20px' }}>
        <Col span={14} style={{ paddingLeft: '35px' }}>
          <h2 style={{ fontWeight: 'bolder' }}>{title} Logs</h2>
          {platform != 0 && (
            <p>
              Filters:{' '}
              <span className="filter-item">
                {platform === 1 ? 'Backstage' : 'Salesforce'}
                <span
                  style={{ paddingLeft: '14px' }}
                  onClick={() => {
                    setPlatform(0)
                    handlePlatformChange(0)
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </span>
            </p>
          )}
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <SearchBox
            onSearch={(value) => handleSearch(value)}
            allowClear={true}
          />
        </Col>
        <Col span={1} style={{ textAlign: 'right' }}>
          <Dropdown
            overlay={menu}
            onVisibleChange={handleVisibleChange}
            visible={visible}
            className="filter-button"
            placement="bottomRight"
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <FontAwesomeIcon icon={faFilter} />
            </a>
          </Dropdown>
        </Col>
      </Row>
      {logs &&
        logs.length > 0 &&
        logs.map((row) => (
          <Row>
            <Col span={5} style={{ textAlign: 'right' }}>
              <p className="logs-left-date">
                {moment(row.dateCreated).isValid() &&
                  moment
                    .utc(row.dateCreated)
                    .local()
                    .format('MMMM D, YYYY [at] h:mm A')}
              </p>
              <p className="logs-left-type">{row.logType}</p>
              <p className="logs-left-integration">
                Integration log id: {row.integrationLogId}
              </p>
            </Col>
            <Col span={19}>
              <Timeline.Item>
                <p style={{ fontWeight: 'bolder', paddingTop: '5px' }}>
                  {row.response && row.response.startsWith('[')
                    ? JSON.parse(
                        row.response.substring(1, row.response.length - 1)
                      ).message
                    : row.response}
                </p>
                <p>{row.request.substring(1, row.request.length - 1)}</p>
              </Timeline.Item>
            </Col>
          </Row>
        ))}
    </>
  )
}
SalesforceLogsItem.propTypes = {}

export default SalesforceLogsItem
