import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import toPairs from 'lodash/toPairs'
import http from '../../utils/api'
import FileComponent from '../FileComponent'
import ProfileBodyWithTabs from '../ProfileBodyWithTabs'
import ProfileDisclaimerTab from '../ProfileDisclaimerTab'
import ProfileHeaderWithButtons from '../ProfileHeaderWithButtons'
import ProfileOverviewTab from '../ProfileOverviewTab'

const ABOUT_TAB_KEYS = ['opportunity', 'opportunityDetails']
const ABOUT_TAB_KEYS_SIDE = ['imageUrl']

const DealProfileContainer = ({
  deal,
  hideActions = false,
  showHeader = false,
  openDataroom = false,
  dealCategoryName = '',
  openedForView = false,
  onEditDeal,
}) => {
  const [isRequestMeetingModalVisible, setIsRequestMeetingModalVisible] =
    useState(false)
  const [composeModalVisible, setComposeModalVisible] = useState(false)
  const [activeTab, setActiveTab] = useState(
    openDataroom ? 'documentation' : 'overview'
  )
  const [meetings, setMeetings] = useState([])
  const [loadingMeetings, setLoadingMeetings] = useState(false)

  const getMeetings = useCallback(() => {
    setLoadingMeetings(true)
    http
      .get(`deals/${deal.dealId}/meetings`)
      .then((response) => {
        setMeetings(response.data.result)
      })
      .finally(() => {
        setLoadingMeetings(false)
      })
  }, [deal])

  useEffect(() => {
    if (!hideActions) {
      getMeetings()
    }
  }, [deal, getMeetings, hideActions])

  const getDealsData = () =>
    map(toPairs(deal), (f) => ({
      label: startCase(f[0]),
      value: f[1],
      name: f[0],
    }))

  const getTabs = () => {
    const dealsData = getDealsData()
    const tabs = [
      {
        key: 'overview',
        title: 'Overview',
        component: (
          <ProfileOverviewTab
            dealDisclaimer
            onDealDisclaimerClick={() => setActiveTab('disclaimer')}
            postedBy={dealCategoryName}
            sections={dealsData.filter(
              (f) =>
                ABOUT_TAB_KEYS.indexOf(f.name) > -1 &&
                !isNil(f.value) &&
                !isEmpty(f.value)
            )}
            sideSections={dealsData.filter(
              (f) =>
                ABOUT_TAB_KEYS_SIDE.indexOf(f.name) > -1 &&
                !isNil(f.value) &&
                !isEmpty(f.value)
            )}
          />
        ),
      },
      {
        key: 'details',
        title: 'Details',
        component: (
          <ProfileOverviewTab
            sections={deal.profile
              .filter((f) => f.groupName === 'Opportunity Details')[0]
              ?.sections.filter((s) => s.value)}
            sideSections={deal.profile
              .filter((f) => f.groupName === 'Content')[0]
              ?.sections.filter(
                (s) =>
                  s.key !== 'ImageUrl' && !isNil(s.value) && !isEmpty(s.value)
              )}
          />
        ),
      },
      {
        key: 'documentation',
        title: 'Documentation',
        component: <FileComponent files={deal.documents} />,
      },
      {
        key: 'disclaimer',
        title: 'Disclaimer',
        component: <ProfileDisclaimerTab />,
      },
    ]
    return filter(tabs, (t) => !isNil(t))
  }

  return (
    <>
      {showHeader && (
        <ProfileHeaderWithButtons
          name={deal.title}
          address={deal.manager}
          status={deal.status === 'Closed' ? deal.status : null}
          hideActions={hideActions}
          canSendMeetingRequest={true}
          sendRequestText="Meet with Deal Partner"
          editDealText="Edit Deal"
          showEditButton={openedForView}
          onEditDeal={() => {
            onEditDeal()
            //should open edit deal modal.
          }}
          onSendRequest={() => {
            setIsRequestMeetingModalVisible(true)
          }}
          onSendMessage={() => {
            setComposeModalVisible(true)
          }}
        />
      )}
      <ProfileBodyWithTabs
        onTabChange={(tabKey) => {
          setActiveTab(tabKey)
        }}
        activeTabKey={activeTab}
        tabs={getTabs()}
      />
    </>
  )
}

DealProfileContainer.propTypes = {
  deal: PropTypes.shape({
    dealId: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
    title: PropTypes.string,
    companyName: PropTypes.string,
    manager: PropTypes.string,
    profile: PropTypes.object,
    imageUrl: PropTypes.string,
    categoryName: PropTypes.string,
    documents: PropTypes.object,
  }),
  hideActions: PropTypes.bool,
  showHeader: PropTypes.bool,
  openDataroom: PropTypes.bool,
}

export default DealProfileContainer
