import { Component } from 'react'
import PropTypes from 'prop-types'
import {
  faCheck,
  faClock,
  faTimes,
  faUserClock,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Dropdown, Menu } from 'antd'
import { isEmpty, isNil, map, trim } from 'lodash'
import moment from 'moment'
import { getSentContextList } from '../../actions/events'
import http from '../../utils/api'
import { getInitials } from '../../utils/helpers'
import AvatarBadgeGroup from '../AvatarBadgeGroup'
import AvatarGroup from '../AvatarGroup'
import CancelMeetingButton from '../CancelMeetingButton'
import CancelMeetingComponent from '../CancelMeetingComponent/CancelMeetingComponent'
import ContextTable from '../ContextTable'
import ProfilePictureGroup from '../ProfilePictureGroup'
import ResponsiveCard from '../Responsive/ResponsiveCards/OnlineMeetingRecivedRequestResponsiveCard'
import TimeDisplay from '../TimeDisplay'
import './SentRequestsTable.less'

class SentRequestsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // displayTimezone: TimeDisplay.ORIGINAL,
      // displayTimezoneLabel: 'Event Timezone',
      showFollowUpMessages: false,
      ShowCancelMeetingComponent: false,
      refreshTable: false,
      cancelForAll: false,
      //sentRequestsList: null,
    }
  }
  static propTypes = {
    // fetchSentRequestsByCompany: PropTypes.func.isRequired,
    // fetchSentRequestsByCompanyEventId: PropTypes.func.isRequired,
    //sentRequestsList: PropTypes.array.isRequired,
    //meetingType: PropTypes.string.isRequired,
    //companyEventId: PropTypes.number.isRequired,
    eventParticipantId: PropTypes.number.isRequired,

    //fromSummit: PropTypes.bool,
    //width: PropTypes.number.isRequired,
    refreshTable: PropTypes.bool,
    onCancelRequest: PropTypes.func,
  }

  // componentDidUpdate(prevProps) {
  //   const {
  //     fetchSentRequestsByCompany,
  //     fetchSentRequestsByCompanyEventId,
  //     meetingType,
  //     fromSummit,
  //     companyEventId,
  //   } = this.props;
  //   if (this.props.refreshTable !== prevProps.refreshTable) {
  //     fromSummit
  //       ? fetchSentRequestsByCompanyEventId(companyEventId)
  //       : fetchSentRequestsByCompany(meetingType.toLowerCase());
  //   }
  // }

  // componentDidMount() {
  //   http
  //     .post(
  //       `/meeting/summit/sent?companyEventId=` + this.props.companyEventId,
  //       {
  //         pagination: { pageSize: 20 },
  //         sorter: {},
  //         searchTerm: {},
  //         filters: {},
  //       }
  //     )
  //     .then((res) => {
  //       //let count = this.state.cnt;
  //       this.setState({ sentRequestsList: res.data.result });
  //     });
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.refreshTable !== prevState.refreshTable) {
  //     http
  //       .post(
  //         `/meeting/summit/sent?companyEventId=` + this.props.companyEventId,
  //         {
  //           pagination: { pageSize: 20 },
  //           sorter: {},
  //           searchTerm: {},
  //           filters: {},
  //         }
  //       )
  //       .then((res) => {
  //         //let count = this.state.cnt;
  //         this.setState({ sentRequestsList: res.data.result });
  //       });
  //   }
  // }

  handleFollowUpClick = (record) => {
    this.setState({
      showFollowUpMessages: true,
      selectedRecord: record,
    })
  }

  canLeaveMeeting = (record) => {
    let leave = false

    if (!isEmpty(record.colleagues)) {
      const pendingParticipants = record.colleagues.filter(
        (x) => x.participantStatusId === 2 || x.participantStatusId === 1
      )
      if (!isEmpty(pendingParticipants) && pendingParticipants.length > 1) {
        leave = true
      }
    }

    return leave
  }

  canCancelForAll = (record) => {
    if (record.isInitiator) return true
    else return false
  }

  cancelButtonDisabled = (record) =>
    this.canLeaveMeeting(record) && !this.canCancelForAll(record)

  closeFollowUp = (shouldRefresh) => {
    this.setState((state) => ({
      showFollowUpMessages: false,
    }))
    if (shouldRefresh) this.props.refresh(!this.state.refreshTable)
  }

  renderActions = (record) =>
    //const { meetingType } = this.props;
    !this.props.isPast &&
    record.meetingStatus.toLowerCase() !== 'declined' &&
    ((record.meetingType.toLowerCase() === 'summit' && (
      <div className="cc-meeting-actions">
        <span className={'cc-btn cc-btn-danger'}>
          {this.canLeaveMeeting(record) && (
            <Button
              onClick={this.handleLeaveClick.bind(this, record)}
              className={`cc-btn ${
                !this.canLeaveMeeting(record)
                  ? 'cc-btn-disabled'
                  : 'cc-btn-danger'
              }`}
            >
              <i className="cc-btn-bg" />
              {'Leave Meeting'}
            </Button>
          )}
        </span>

        <span className={'cc-btn cc-btn-danger'}>
          {!this.cancelButtonDisabled(record) && (
            <Button
              onClick={
                this.canCancelForAll(record)
                  ? this.handleCancelForAllClick.bind(this, record)
                  : this.handleCancelClick.bind(this, record)
              }
              className={`cc-btn ${
                this.cancelButtonDisabled(record)
                  ? 'cc-btn-disabled'
                  : 'cc-btn-danger'
              }`}
            >
              <i className="cc-btn-bg" />
              Cancel
            </Button>
          )}
        </span>
      </div>
    )) ||
      (record.meetingType.toLowerCase() === 'online' && (
        <CancelMeetingButton
          contacts={record.colleagues}
          meetingType={record.meetingType}
          company={record.company.companyName}
          companyId={record.senderCompanyId}
          meetingId={record.meetingId}
          onCancel={() => {
            this.props.onCancelRequest()
            this.props.refresh(!this.state.refreshTable)
            this.setState((state) => ({
              refreshTable: !state.refreshTable,
            }))
          }}
          initiator={record.member}
        />
      )))

  handleCancelClick = (record) => {
    this.setState({
      cancelForAll: false,
      selectedRecord: record,
      ShowCancelMeetingComponent: true,
    })
  }

  handleLeaveClick = (record) => {
    this.setState((state) => ({
      CancelForAll: false,
      selectedRecord: record,
      ShowCancelMeetingComponent: true,
    }))
  }

  handleCancelForAllClick = (record) => {
    this.setState({
      cancelForAll: true,
      selectedRecord: record,
      ShowCancelMeetingComponent: true,
    })
  }

  renderCompany = (record) => {
    if (!isNil(record.companies)) {
      return record.companies.map((company) => (
        <>
          {company.companyName}
          <span>{` (${company.category})`}</span>
          {record.carryContextPoints && (
            <span className="cc-context-points-label">
              <span>+1000 Context Points</span>
            </span>
          )}
          <br></br>
        </>
      ))
    } else {
      return (
        <div>
          {record.company.companyName}
          {record.carryContextPoints && (
            <span className="cc-context-points-label">
              <span>+1000 Context Points</span>
            </span>
          )}
        </div>
      )
    }
  }

  renderSentRequestStatus = (record) => (
    <span
      className={`cc-body-text cc-status-${record.meetingStatus.toLowerCase()}`}
    >
      <FontAwesomeIcon
        size="lg"
        style={{ marginRight: '8px' }}
        icon={
          record.meetingStatus.toLowerCase() === 'confirmed'
            ? faCheck
            : record.meetingStatus.toLowerCase() === 'pending'
            ? faClock
            : faTimes
        }
      />
      {record.meetingStatus}
    </span>
  )

  renderParticipantsAvatars = (record) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ProfilePictureGroup
        diameter={32}
        profiles={record?.allMembers?.map((c) => ({
          image: c.imageUrl,
          name: c.contactName,
          firstName: c.firstName,
          lastName: c.lastName,
        }))}
        maxImages="3"
        style={{ display: 'inline-block', marginTop: '4px' }}
      />
    </div>
  )

  getColumns = () => {
    const menu = (
      <Menu>
        <Menu.Item
          key={TimeDisplay.ORIGINAL}
          onClick={(e) =>
            this.setState({
              displayTimezone: e.key,
              displayTimezoneLabel: 'Event Timezone',
            })
          }
        >
          <FontAwesomeIcon icon={faClock} />
          <span>
            Show in <b>Event</b> Timezone
          </span>
        </Menu.Item>
        <Menu.Item
          key={TimeDisplay.LOCAL}
          onClick={(e) =>
            this.setState({
              displayTimezone: e.key,
              displayTimezoneLabel: 'Local Timezone',
            })
          }
        >
          <FontAwesomeIcon icon={faUserClock} />
          <span>
            Show in your <b>Local</b> Timezone
          </span>
        </Menu.Item>
      </Menu>
    )

    return [
      {
        key: '1',
        title: 'COMPANIES',
        //dataIndex: 'company.companyName',
        className: 'cc-body-text',
        render: this.renderCompany,
      },
      {
        key: '2',
        title: 'MEMBERS',
        render: this.renderParticipantsAvatars,
        align: 'center',
      },

      {
        key: '5',
        title: 'ACTIONS',
        render: this.renderActions,
        align: 'left',
        width: 270,
        notRenderInCard: true,
      },
    ]
  }
  render() {
    return (
      <div>
        <CancelMeetingComponent
          visible={this.state.ShowCancelMeetingComponent}
          closeModal={() => {
            this.setState({
              ShowCancelMeetingComponent: false,
            })
          }}
          companyId={this.props.companyId}
          contacts={
            !isEmpty(this.state.selectedRecord)
              ? this.state.selectedRecord.colleagues
              : []
          }
          company={
            isNil(this.state.selectedRecord) ||
            isNil(this.state.selectedRecord.company)
              ? ''
              : this.state.selectedRecord.company.companyName
          }
          meetingId={
            !isEmpty(this.state.selectedRecord)
              ? this.state.selectedRecord.meetingId
              : 0
          }
          meetingType={this.props.meetingType}
          onCancel={() => {
            this.props.onCancelRequest()
            this.props.refresh(!this.state.refreshTable)
            this.setState((state) => ({
              refreshTable: !state.refreshTable,
            }))
          }}
          canDeclineMeeting={false}
          cancelForAll={this.state.cancelForAll}
          meetingStatus={
            !isEmpty(this.state.selectedRecord)
              ? this.state.selectedRecord.meetingStatus
              : null
          }
        />
        <ContextTable
          tableTitle="Meetings"
          key="id"
          columns={this.getColumns()}
          eventParticipantId={this.props.eventParticipantId}
          fundEventId={this.props.fundEventId}
          eventCompanyId={this.props.eventCompanyId}
          fundId={this.props.fundId}
          companyContactId={this.props.companyContactId}
          dataMethod={(pagination, filters, sorter, searchTerm) =>
            getSentContextList(
              pagination,
              filters,
              sorter,
              searchTerm,
              null,
              this.props.eventParticipantId,
              this.props.fundEventId,
              this.props.eventCompanyId,
              this.props.itemId ?? 0,
              this.props.type,
              this.props.eventCompanyBoothFundId,
              this.props.eventCompanyBoothId
            )
          }
          refresh={this.props.refreshMeetings}
          refreshData={this.props.refreshMeetings}
          companyId={this.props.companyId}
        />
      </div>
    )
  }
}

export default SentRequestsTable
