import { getMandatesByCompanyList } from '../../actions/mandates'
import Mandates from './Mandates'

const MandatesByCompanyContainer = ({
  companyId,
  companyName,
  contacts,
  companyCategory,
}) => {
  return (
    <>
      <Mandates
        displayTitle={false}
        displayAddButton={true}
        tableTitle={'List of Company Mandates'}
        getMandateListData={getMandatesByCompanyList(companyId)}
        companyIdProp={companyId}
        newMandateForCompanyTitle={`New Mandate for: ${companyName}`}
        newMandateForCompanyContacts={contacts}
        companyCategory={companyCategory}
      />
    </>
  )
}

export default MandatesByCompanyContainer
