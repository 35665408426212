import PropTypes from 'prop-types'
import { Avatar, Col, Row } from 'antd'
import { nameToColor } from '../../utils/columns'
import './ServiceProviderPeopleCard.less'

const ServiceProviderPeopleCard = ({ serviceProvider }) => {
  let indexCtr = 0

  return (
    <>
      {serviceProvider && (
        <div className="discover-allocator" id="fixed">
          <div className="ServiceProviderPeopleCard">
            <Row className="ServiceProviderPeopleCard-row">
              <div className="ServiceProviderPeopleCard-avatar">
                <Avatar
                  src={serviceProvider.imageUrl}
                  style={{
                    marginLeft: '0',
                    position: 'relative',
                    zIndex: 150 + indexCtr++,
                    display: 'inline-block',
                    backgroundColor: nameToColor(
                      serviceProvider.fullName,
                      serviceProvider.fullName
                    ),
                    fontSize: '13px',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                  }}
                  shape="circle"
                  size={72}
                  className="round-profile-thumbnail-group"
                >
                  {serviceProvider.fullName}
                </Avatar>
              </div>
              <div className="ServiceProviderPeopleCard-text">
                <Row className="ServiceProviderPeopleCard-title">
                  {serviceProvider.fullName}
                </Row>
                <Row className="ServiceProviderPeopleCard-subtitle">
                  {serviceProvider.jobPosition}
                </Row>
                <Row className="ServiceProviderPeopleCard-description">
                  {serviceProvider.description}
                </Row>
              </div>
            </Row>
          </div>
        </div>
      )}
    </>
  )
}

ServiceProviderPeopleCard.propTypes = {
  serviceProvider: PropTypes.object.isRequired,
}

export default ServiceProviderPeopleCard
