import { useEffect, useState } from 'react'
import { Col, Menu, Row, Tabs } from 'antd'
import { OmitProps } from 'antd/lib/transfer/renderListBody'
import { isEmpty, isNil } from 'lodash'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Logo from '../../assets/header-logo.svg'
import { REACT_APP_CALL_STATS_URL } from '../../config'
import CompanySwitchList from '../CompanySwitchList'
import '../../styles/index.less'
import './TopBar.less'

const TopBar = ({
  userFullname,
  logout,
  changeCompany,
  isAdmin,
  isMarketing,
}) => {
  const { TabPane } = Tabs

  const initialState = {
    current: null,
  }
  console.log(window.location.pathname)
  const [state, setState] = useState(initialState)
  useEffect(() => {
    if (
      window.location.pathname.includes('summits') ||
      window.location.pathname.includes('schedule') ||
      window.location.pathname.includes('split') ||
      window.location.pathname.includes('booth')
    ) {
      setState({
        current: '4',
      })
    } else if (
      window.location.pathname.includes('users') ||
      window.location.pathname.includes('contactdetails') ||
      window.location.pathname.includes('editManagerDetails') ||
      window.location.pathname.includes('investorstatus') ||
      window.location.pathname.includes('investordetails') ||
      window.location.pathname.includes('contactcompanies')
    ) {
      setState({
        current: '3',
      })
    } else if (window.location.pathname.includes('online')) {
      setState({
        current: '8',
      })
    } else if (window.location.pathname.includes('announcements')) {
      setState({
        current: '11',
      })
    } else if (window.location.pathname.includes('leads')) {
      setState({
        current: '5',
      })
    } else if (window.location.pathname.includes('content')) {
      setState({
        current: '6',
      })
    } else if (window.location.pathname.includes('roadshow')) {
      setState({
        current: '7',
      })
    } else if (window.location.pathname.includes('admin')) {
      setState({
        current: '10',
      })
    } else if (window.location.pathname.includes('deals')) {
      setState({
        current: '12',
      })
    } else if (window.location.pathname.includes('logs')) {
      setState({
        current: '13',
      })
    } else if (
      window.location.pathname.includes('mandates') ||
      window.location.pathname.includes('create-mandate') ||
      window.location.pathname.includes('edit-mandate')
    ) {
      setState({
        current: '14',
      })
    } else if (
      window.location.pathname.includes('companies') ||
      window.location.pathname.includes('')
    ) {
      setState({
        current: '2',
      })
    }
  }, [])

  const handleClick = (e) => {
    setState({
      current: e.key,
    })
  }

  const handleChange = (newValue) => {
    console.log('new', newValue)
    setState({
      current: newValue,
    })
  }

  return (
    <Row className="cb-navbar">
      <Col span={20} offset={2}>
        <Row>
          <Col span={3}>
            <div className="logo-container">
              <img src={Logo} alt="Logo" className="Logo" />
            </div>
          </Col>
          <Col span={17}>
            <Tabs
              //defaultActiveKey="1"
              tabPosition={'top'}
              //style={{ height: 220 }}
              mode="horizontal"
              activeKey={state.current}
              //onClick={handleClick}
              animated={false}
              tabBarStyle={{ marginBottom: '1px' }}
              //activeKey={activeTab}
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              variant="scrollable"
              onChange={handleChange}
              className="TopBar-tabs"
            >
              <TabPane
                key="1"
                tab={
                  <NavLink
                    to={'/analytics'}
                    exact
                    activeClassName={'cc-nav-active'}
                  >
                    Analytics
                  </NavLink>
                }
              ></TabPane>

              <TabPane
                key="2"
                tab={
                  <NavLink to={'/'} exact activeClassName={'cc-nav-active'}>
                    Companies
                  </NavLink>
                }
              ></TabPane>
              <TabPane
                key="3"
                tab={
                  <NavLink
                    to={'/users'}
                    exact
                    activeClassName={'cc-nav-active'}
                  >
                    Users
                  </NavLink>
                }
              ></TabPane>
              <TabPane
                key="4"
                tab={
                  <NavLink
                    to={'/summits'}
                    exact
                    activeClassName={'cc-nav-active'}
                  >
                    Summits
                  </NavLink>
                }
              ></TabPane>
              <TabPane
                key="5"
                tab={
                  <NavLink
                    to={'/leads'}
                    exact
                    activeClassName={'cc-nav-active'}
                  >
                    Leads
                  </NavLink>
                }
              ></TabPane>
              {(isMarketing || isAdmin) && (
                <TabPane
                  key="6"
                  tab={
                    <NavLink
                      to={'/content'}
                      exact
                      activeClassName={'cc-nav-active'}
                    >
                      Content
                    </NavLink>
                  }
                ></TabPane>
              )}
              <TabPane
                key="7"
                tab={
                  <NavLink
                    to={'/roadshow'}
                    exact
                    activeClassName={'cc-nav-active'}
                  >
                    Roadshow
                  </NavLink>
                }
              ></TabPane>
              <TabPane
                key="8"
                tab={
                  <NavLink
                    to={'/online/allocators'}
                    exact
                    activeClassName={'cc-nav-active'}
                  >
                    Online
                  </NavLink>
                }
              ></TabPane>
              {!isNil(REACT_APP_CALL_STATS_URL) &&
                !isEmpty(REACT_APP_CALL_STATS_URL) && (
                  <TabPane
                    key="9"
                    tab={
                      <a href={REACT_APP_CALL_STATS_URL} target="_blank">
                        Call Stats
                      </a>
                    }
                  ></TabPane>
                )}
              {isAdmin && (
                <TabPane
                  key="10"
                  tab={
                    <NavLink
                      to={'/admin'}
                      exact
                      activeClassName={'cc-nav-active'}
                    >
                      Admin
                    </NavLink>
                  }
                ></TabPane>
              )}
              {(isMarketing || isAdmin) && (
                <TabPane
                  key="11"
                  tab={
                    <NavLink
                      to={'/announcements'}
                      exact
                      activeClassName={'cc-nav-active'}
                    >
                      Announcements
                    </NavLink>
                  }
                ></TabPane>
              )}
              <TabPane
                key="12"
                tab={
                  <NavLink
                    to={'/deals'}
                    exact
                    activeClassName={'cc-nav-active'}
                  >
                    Deals
                  </NavLink>
                }
              ></TabPane>
              <TabPane
                key="13"
                tab={
                  <NavLink to={'/logs'} exact activeClassName={'cc-nav-active'}>
                    Salesforce Logs
                  </NavLink>
                }
              ></TabPane>
              <TabPane
                key="14"
                tab={
                  <NavLink
                    to={'/mandates'}
                    exact
                    activeClassName={'cc-nav-active'}
                  >
                    Mandates
                  </NavLink>
                }
              ></TabPane>
            </Tabs>
          </Col>
          <Col span={4}>
            <CompanySwitchList userFullname={userFullname} logout={logout} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default TopBar
