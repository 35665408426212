import PropTypes from 'prop-types'
import { Button, Col, Row, Spin, Tabs } from 'antd'
import map from 'lodash'
import { getAllocatorKpis } from '../../actions/activity'
import KpiList from './KpiList'
import './UserActivity.less'

const { TabPane } = Tabs

const UserActivity = ({ contactId, companies }) => {
  const companyTabs = () => {
    return companies.map((x, i) => {
      //only have kpis for allocators currently
      if (x.categoryId === 2)
        return (
          <TabPane tab={<span>{x.name}</span>} key={i}>
            <Tabs>
              <TabPane tab={<span>Meetings</span>} key={1}>
                <KpiList
                  companyContactId={x.companyContactId}
                  type="meetings"
                />
              </TabPane>
              <TabPane tab={<span>Engagement</span>} key={2}>
                <KpiList
                  companyContactId={x.companyContactId}
                  type="engagement"
                />
              </TabPane>
            </Tabs>
          </TabPane>
        )
      return null
    })
  }

  return (
    <>
      <Tabs size="large" className="UserActivity-tabs">
        {companyTabs()}
      </Tabs>
    </>
  )
}

UserActivity.propTypes = {}

export default UserActivity
