import { useEffect, useState } from 'react'
import { faArrowLeft, faFileExport } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Modal, Row, Spin, Tabs, message } from 'antd'
import { isNil } from 'lodash'
import { useHistory } from 'react-router-dom'
import loaderImage from '../../assets/context_loader.gif'
import AllocatorProfileWizard from '../../components/AllocatorProfileWizard'
import http from '../../utils/api'
import DealsByCompanyContainer from '../../views/deals/DealsByCompanyContainer'
import MandatesByCompanyContainer from '../../views/mandates/MandatesByCompanyContainer'
import ServiceProviderWizardContainer from '../../views/ServiceProviderWizardContainer'
import CompanyFunds from '../CompanyFunds/CompanyFunds'
import CompanySummits from '../CompanySummits/CompanySummits'
import ExportLogs from '../ExportLogs/ExportLogs'
import FundAllocatorEngagement from '../FundAllocatorEngagement/FundAllocatorEngagement'
import FundCompliance from '../FundCompliance/FundCompliance'
import FundHealthScores from '../FundHealthScores/FundHealthScores'
import CompanyOverview from './CompanyOverview'
import CompanyPeople from './CompanyPeople'
import CompanyTestimonials from './CompanyTestimonials'
import './CompanyDetails.less'

const { TabPane } = Tabs

const CompanyDetails = ({ selectedCompany, companyId, category, tab }) => {
  const [contacts, setContacts] = useState([])
  const [exportModalVisible, setExportModalVisible] = useState(false)

  const [refreshContacts, setRefreshContacts] = useState(false)
  const [newName, setNewName] = useState(null)
  const changeRefresh = () => {
    setRefreshContacts(!refreshContacts)
  }
  const changeCompanyName = (value) => {
    setNewName(value)
  }

  const [showServiceProviderProfile, setShowServiceProviderProfile] =
    useState(false)
  const [spinnerLoading, setSpinnerLoading] = useState(false)

  useEffect(() => {
    http
      .get(`/companies/${companyId}/contacts`)
      .then(function (response) {
        setContacts(response.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }, [refreshContacts, newName])

  const handleExportClick = () => {
    setExportModalVisible(true)
  }

  const handlePublishClick = () => {
    setSpinnerLoading(true)
    http
      .post(`/serviceproviders/0/${companyId}/publish`, {
        answers: [],
      })
      .then(() => {
        message.success('Profile published successfully')
        setSpinnerLoading(false)
      })
      .catch(() => {
        message.error('Could not publish profile')
        setSpinnerLoading(false)
      })
  }
  const history = useHistory()

  return (
    <>
      <div className="CompanyDetails" style={{ padding: '10px' }}>
        <div className="CompanyDetails-wrapper">
          <Row type="flex" justify="space-between">
            <Col span={11} style={{ display: 'flex' }}>
              <Button
                onClick={() => history.push('/')}
                style={{ marginRight: '10px', padding: '0px 10px' }}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
              <h1
                className="cc-section-header-title"
                style={{ lineHeight: '30px' }}
              >
                {isNil(newName) && selectedCompany.name}
                {!isNil(newName) && newName}
              </h1>
            </Col>
            <Col
              span={category === 'serviceprovider' ? 8 : 11}
              style={{ textAlign: 'right' }}
            >
              <Button type="primary" onClick={handleExportClick}>
                <FontAwesomeIcon icon={faFileExport} /> Export Activity Log
              </Button>
            </Col>
            {category === 'serviceprovider' && (
              <Col span={3} style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={handlePublishClick}>
                  Publish Profile{' '}
                  <Spin
                    size="small"
                    spinning={spinnerLoading === true}
                    indicator={
                      <img
                        style={{
                          marginLeft: '10px',
                          width: '20px',
                          height: '20px',
                        }}
                        src={loaderImage}
                      />
                    }
                  ></Spin>
                </Button>
              </Col>
            )}
          </Row>
        </div>
        <Tabs
          defaultActiveKey={tab || '1'}
          size="large"
          className="CompanyDetails-tabs"
        >
          <TabPane
            className="CompanyDetails-tabs-tab"
            tab={<span>Overview</span>}
            key="1"
          >
            {category === 'allocator' ? (
              <AllocatorProfileWizard companyId={companyId} />
            ) : (
              <CompanyOverview
                companyId={companyId}
                category={category}
                changeCompanyName={changeCompanyName}
              />
            )}
          </TabPane>
          <TabPane
            className="CompanyDetails-tabs-tab"
            tab={<span>Users</span>}
            key="2"
          >
            <CompanyPeople
              companyId={companyId}
              contacts={contacts}
              refresh={changeRefresh}
              category={category}
            />
          </TabPane>
          {category === 'serviceprovider' ? (
            <TabPane
              className="CompanyDetails-tabs-tab"
              tab={<span>Testimonials</span>}
              key="6"
            >
              <CompanyTestimonials
                companyId={companyId}
                category={category}
                changeCompanyName={changeCompanyName}
              />
            </TabPane>
          ) : null}
          {category === 'fund' ? (
            <TabPane
              className="CompanyDetails-tabs-tab"
              tab={<span>Funds</span>}
              key="3"
            >
              <CompanyFunds companyId={companyId} />
            </TabPane>
          ) : null}
          {category === 'fund' ? (
            <TabPane
              className="CompanyDetails-tabs-tab"
              tab={<span>Engagement</span>}
              key="8"
            >
              <FundAllocatorEngagement companyId={companyId} />
            </TabPane>
          ) : null}
          {category === 'fund' ? (
            <TabPane
              className="CompanyDetails-tabs-tab"
              tab={<span>Health Scores</span>}
              key="9"
            >
              <FundHealthScores companyId={companyId} />
            </TabPane>
          ) : null}
          {category === 'fund' ? (
            <TabPane
              className="CompanyDetails-tabs-tab"
              tab={<span>Compliance</span>}
              key="10"
            >
              <FundCompliance companyId={companyId} />
            </TabPane>
          ) : null}
          {category === 'cfn' ||
          category === 'serviceprovider' ||
          category === 'allocator' ? (
            <TabPane
              className="CompanyDetails-tabs-tab"
              tab={<span>Deals</span>}
              key="7"
            >
              <DealsByCompanyContainer
                companyId={companyId}
                companyName={selectedCompany.name}
                contacts={contacts}
              />
            </TabPane>
          ) : null}

          {category !== 'cfn' ? (
            <TabPane
              className="CompanyDetails-tabs-tab"
              tab={<span>Summits</span>}
              key="5"
            >
              <CompanySummits category={category} companyId={companyId} />
            </TabPane>
          ) : null}
          {category === 'serviceprovider' || category === 'allocator' ? (
            <TabPane
              className="CompanyDetails-tabs-tab"
              tab={<span>Mandates</span>}
              key="11"
            >
              <MandatesByCompanyContainer
                companyId={companyId}
                companyName={selectedCompany.name}
                contacts={contacts}
                companyCategory={category}
              />
            </TabPane>
          ) : null}
        </Tabs>
      </div>
      <ExportLogs
        visible={exportModalVisible}
        handleCancel={() => setExportModalVisible(false)}
        logType="company"
        recordId={companyId}
      />
      <Modal
        destroyOnClose
        visible={showServiceProviderProfile}
        onCancel={() => {
          setShowServiceProviderProfile(false)
        }}
        footer={null}
        width="1000px"
        bodyStyle={{ padding: '0px' }}
      >
        <ServiceProviderWizardContainer
          company={selectedCompany}
          companyId={companyId}
          contacts={contacts}
        />
      </Modal>
    </>
  )
}

CompanyDetails.propTypes = {}

export default CompanyDetails
