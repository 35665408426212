import { useEffect, useState } from 'react'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import { getAnnouncementList } from '../../actions/announcements'
import ContextTable from '../../components/ContextTable'
import { getAnnouncementColumns } from '../../utils/columns'

const Announcements = () => {
  const [refreshData, setRefreshData] = useState(false)
  const [columns, setColumns] = useState(null)

  const history = useHistory()

  const refresh = () => {
    setRefreshData(!refreshData)
  }

  useEffect(() => {
    var cols = getAnnouncementColumns(refresh)

    setColumns(cols)
  }, [refreshData])

  return (
    <div style={{ marginTop: '20px' }}>
      <Row style={{ paddingBottom: '24px' }}>
        <Col span={12}>
          <h1
            className="cc-section-header-title"
            style={{ lineHeight: '30px' }}
          >
            Announcements
          </h1>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button
            block
            size="large"
            style={{
              background: '#2FC5D2',
              boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.06);',
              color: 'white',
              width: '208px',
            }}
            onClick={() => history.push('/announcements/new')}
          >
            <span style={{ background: '#2FC5D2', opacity: '1' }}>
              <FontAwesomeIcon icon={faPlus} /> New Announcement
            </span>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {columns && (
            <ContextTable
              tableTitle="List of Announcements"
              columns={columns}
              dataMethod={getAnnouncementList}
              refresh={refreshData}
              refreshData={refreshData}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

Announcements.propTypes = {}

export default Announcements
