import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import {
  DatePicker,
  Input,
  MultiSelect,
  Option,
  Select,
} from '@context365/forms'
import { Add } from '@context365/icons'
import { Divider } from 'antd'
import produce from 'immer'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { formatNumber } from '../../../utils/helpers'

const getNewCategoryAmount = (categoryId, categoryName) => ({
  alternativeCategoryId: categoryId,
  alternativeCategoryName: categoryName,
  actualPercentage: null,
  actualDollars: null,
  strategyIds: [],
})

const ReturnCategories = ({
  amounts,
  strategies,
  onAmountChange,
  onRemoveOtherCategory,
  onAddOtherCategory,
}) => (
  <>
    {map(amounts, (amount, amIndex) => (
      <div
        key={amIndex}
        className="grid grid-cols-2 gap-2 px-8 text-primary-90 pb-4"
      >
        <div className="col-span-2 type-body-semibold-md flex items-center">
          {isNil(amount.alternativeCategoryId)
            ? amount.alternativeCategoryName
              ? `Other (${amount.alternativeCategoryName})`
              : 'Other'
            : amount.alternativeCategoryName}
          {isNil(amount.alternativeCategoryId) && (
            <Button
              onClick={() => onRemoveOtherCategory(amIndex)}
              variant="link"
              status="error"
            >
              Remove
            </Button>
          )}
        </div>
        {isNil(amount.alternativeCategoryId) && (
          <Input
            label="Name"
            required
            value={amount.alternativeCategoryName}
            onChange={(e) =>
              onAmountChange(amIndex, 'alternativeCategoryName', e.target.value)
            }
          />
        )}
        <Input
          className="col-start-1"
          label={`${amount.alternativeCategoryName} actual (percent)`}
          type="number"
          suffix="%"
          value={amount.actualPercentage}
          helpMessage={formatNumber(amount.actualPercentage)}
          onChange={(e) =>
            onAmountChange(amIndex, 'actualPercentage', e.target.value)
          }
        />
        <Input
          label={`${amount.alternativeCategoryName} target (percent)`}
          type="number"
          suffix="%"
          value={amount.targetPercentage}
          helpMessage={formatNumber(amount.targetPercentage)}
          onChange={(e) =>
            onAmountChange(amIndex, 'targetPercentage', e.target.value)
          }
        />
        <MultiSelect
          label="Strategies"
          helpMessage="Select multiple as needed"
          value={amount.strategyIds}
          clearable
          onChange={(value) => onAmountChange(amIndex, 'strategyIds', value)}
        >
          {map(
            orderBy(
              flatMap(strategies, (s) => s.children),
              'name'
            ),
            (s) => (
              <MultiSelect.Option value={s.id}>{s.name}</MultiSelect.Option>
            )
          )}
        </MultiSelect>
      </div>
    ))}
    <Button
      variant="link"
      className="mx-6"
      onClick={() => onAddOtherCategory()}
    >
      <Add size="small" /> Write-in Other Category
    </Button>
  </>
)

const Returns = ({
  returns = [],
  alternativeCategories,
  strategies,
  returnCadences,
  updateCompany,
}) => {
  const getNewReturn = () => {
    return {
      valuationDate: null,
      cadenceId: 1,
      expectedRateOfReturnPercentage: null,
      actualRateOfReturnPercentage: null,
      amounts: map(alternativeCategories, (c) => {
        return getNewCategoryAmount(c.id, c.name)
      }),
    }
  }

  const addReturnYear = () => {
    const newReturns = returns.slice()
    newReturns.push(getNewReturn())
    updateCompany('returns', newReturns)
  }

  const removeReturnYear = (index) => {
    const newReturns = returns.slice()
    newReturns.splice(index, 1)
    updateCompany('returns', newReturns)
  }

  const handleChange = (index, field, val) => {
    const newReturns = produce(returns, (newState) => {
      newState[index][field] = val
    })

    updateCompany('returns', newReturns)
  }

  const handleAmountChange = (returnIndex, amountIndex, field, val) => {
    const newReturns = produce(returns, (newState) => {
      newState[returnIndex].amounts[amountIndex][field] = val
    })

    updateCompany('returns', newReturns)
  }

  const handleAddOtherCategory = (returnIndex) => {
    const newReturns = produce(returns, (newState) => {
      newState[returnIndex].amounts.push(getNewCategoryAmount(null, ''))
    })

    updateCompany('returns', newReturns)
  }

  const handleRemoveOtherCategory = (returnIndex, amountIndex) => {
    const newReturns = produce(returns, (newState) => {
      newState[returnIndex].amounts.splice(amountIndex, 1)
    })

    updateCompany('returns', newReturns)
  }

  return (
    <>
      {map(returns, (ret, returnIndex) => (
        <>
          <div className="grid grid-cols-2 gap-6">
            <div className="flex space-between">
              <div className="type-subtitle-sm text-primary-90">
                Return {returnIndex + 1}
              </div>
              <Button
                variant="link"
                status="error"
                onClick={() => removeReturnYear(returnIndex)}
              >
                Remove
              </Button>
            </div>
            <Select
              className="col-start-1"
              value={ret.cadenceId}
              label="Breakdown by"
              helpMessage="Breakdown returns by month, year, or quarter"
              onChange={(val) => handleChange(returnIndex, 'cadenceId', val)}
            >
              {map(returnCadences, (rc, i) => (
                <Option key={i} value={rc.id}>
                  {rc.name}
                </Option>
              ))}
            </Select>
            <DatePicker
              required
              label="Valuation date"
              mode="month"
              placeholder="Select date (MM/YY)"
              dateFormat="MM/yy"
              maxDate={new Date()}
              value={
                isNil(ret.valuationDate) ? null : new Date(ret.valuationDate)
              }
              onChange={(date) => {
                handleChange(returnIndex, 'valuationDate', date.toDateString())
              }}
            />
            <Input
              label={`${
                find(returnCadences, (rc) => rc.id === ret.cadenceId)?.name
              } actual rate of return (percent)`}
              type="number"
              suffix="%"
              value={ret.actualRateOfReturnPercentage}
              onChange={(e) =>
                handleChange(
                  returnIndex,
                  'actualRateOfReturnPercentage',
                  e.target.value
                )
              }
              helpMessage={formatNumber(ret.actualRateOfReturnPercentage)}
            />
            <Input
              label={`${
                find(returnCadences, (rc) => rc.id === ret.cadenceId)?.name
              } expected rate of return (percent)`}
              type="number"
              suffix="%"
              value={ret.expectedRateOfReturnPercentage}
              onChange={(e) =>
                handleChange(
                  returnIndex,
                  'expectedRateOfReturnPercentage',
                  e.target.value
                )
              }
              helpMessage={formatNumber(ret.expectedRateOfReturnPercentage)}
            />
          </div>
          <ReturnCategories
            amounts={ret.amounts}
            strategies={strategies}
            onAmountChange={(amountIndex, field, value) =>
              handleAmountChange(returnIndex, amountIndex, field, value)
            }
            onAddOtherCategory={() => handleAddOtherCategory(returnIndex)}
            onRemoveOtherCategory={(amountIndex) =>
              handleRemoveOtherCategory(returnIndex, amountIndex)
            }
          />
          <Divider />
        </>
      ))}

      <Button variant="link" className="justify-start" onClick={addReturnYear}>
        + Add Return
      </Button>
    </>
  )
}

Returns.propTypes = {
  returns: PropTypes.array,
  alternativeCategories: PropTypes.array.isRequired,
  strategies: PropTypes.array.isRequired,
  returnCadences: PropTypes.array.isRequired,
  updateCompany: PropTypes.func.isRequired,
}

export default Returns
