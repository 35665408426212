import PropTypes from 'prop-types'
import { findIndex, fromPairs, get, isNil, map, pick, values } from 'lodash'
import * as types from '../../constants/types/serviceprovideronboarding'
import InitialStep from './InitialStep'
import ServiceProviderStep from './ServiceProviderStep'
import { ServiceProviderValidatorShape } from './ServiceProviderValidatorShape'
import './ServiceProviderWizard.less'

const getSteps = (titles, indexes) =>
  values(pick(fromPairs(map(titles, (title, ix) => [ix, title])), indexes))

const actionTypesLookup = {
  1: types.APPEND_ANSWERS_SP,
}

const STEPS = {
  TITLES: [
    'Edit Profile',
    'Media',
    'Logo',
    'People',
    'Testimonials',
    'Clients',
    'Content',
    'Contact',
  ],
  SERVICEPROVIDER: [1, 2, 3, 4, 5, 6, 7],
}

const ServiceProviderWizard = ({
  currentStep,
  questions,
  onPreview,
  answers,
  questionsWithOptions,
  loading = false,
  onNavigation,
  onStepChange,
  stepsInErrorId,
}) => {
  let title = get(STEPS.TITLES, currentStep)

  const steps = getSteps(STEPS.TITLES, STEPS.SERVICEPROVIDER)
  const stepsInError = []
  stepsInErrorId.forEach((errorId) => stepsInError.push(steps[errorId - 1]))

  const current = findIndex(STEPS.SERVICEPROVIDER, (i) => i === currentStep)

  const isFirst = current === 0
  const isLast = current === steps.length - 1
  const nextStep = STEPS.SERVICEPROVIDER[current + 1]
  const prevStep = STEPS.SERVICEPROVIDER[current - 1]

  const handleNavigation = (
    questions,
    values,
    isNext,
    saveChanges,
    publish
  ) => {
    const actionType = get(
      actionTypesLookup,
      currentStep,
      types.APPEND_ANSWERS_SP
    )

    onNavigation(
      questions,
      values,
      actionType,
      false,
      isNext ? isLast : false,
      isNext ? nextStep : prevStep,
      saveChanges,
      publish
    )
  }

  const validationSchema = ServiceProviderValidatorShape(questions)

  const className = `ServiceProviderWizard-form ServiceProviderWizard-form-${currentStep}`

  return (
    <div className="ant-col ant-col-24 ServiceProviderWizard">
      <ServiceProviderStep
        className={className}
        title={title}
        questions={questions}
        answers={answers}
        validationSchema={validationSchema}
        questionsWithOptions={questionsWithOptions}
        isFirst={isFirst}
        isLast={isLast}
        loading={loading}
        onNavigation={handleNavigation}
        onPreview={onPreview}
        onStepChange={onStepChange}
        currentStep={currentStep}
        stepsInError={stepsInError}
      />
    </div>
  )
}

ServiceProviderWizard.propTypes = {
  currentStep: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  questionsWithOptions: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  onNavigation: PropTypes.func.isRequired,
  onStepChange: PropTypes.func,
  onPreview: PropTypes.func,
  stepsInErrorId: PropTypes.array,
}

ServiceProviderWizard.STEPS = STEPS

export default ServiceProviderWizard
