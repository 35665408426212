import PropTypes from 'prop-types'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, message } from 'antd'
import http from '../../utils/api'

const DeleteSummitUserModal = ({
  visible,
  contactName,
  contactId,
  eventId,
  companyEventId,
  handleOk,
  handleCancel,
}) => {
  const deleteUser = () => {
    http
      .delete(
        `/events/delete/${contactId}/${companyEventId}/${eventId}/schedule`
      )
      .then(() => {
        message.success({
          content: 'User removed successfully',
          icon: (
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={{ marginRight: '10px', color: '#D62B34' }}
            />
          ),
        })
        handleOk()
      })
      .catch((error) => {
        message.error('Something went wrong!')
        handleOk()
      })
  }

  return (
    <Modal
      className="cc-modal-accept"
      title={`Delete User`}
      visible={visible}
      okText={`Delete User`}
      cancelText="Close"
      onOk={deleteUser}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: false,
        style: { backgroundColor: '#D62B34', borderStyle: 'none' },
      }}
      centered={true}
      cancelButtonProps={{ disabled: false }}
    >
      <div>
        Are you sure you want to remove <strong>{contactName}</strong> from the
        summit?
      </div>
    </Modal>
  )
}

DeleteSummitUserModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  contactName: PropTypes.string.isRequired,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  contactId: PropTypes.instanceOf.isRequired,
  companyeEventId: PropTypes.instanceOf.isRequired,
  eventId: PropTypes.instanceOf.isRequired,
}

export default DeleteSummitUserModal
