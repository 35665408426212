import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import LoadingGif from '../LoadingGif'
import SearchBox from '../SearchBox/SearchBox'
import './BoothCompaniesList.less'

const { Column } = Table

const BoothCompaniesList = ({
  setTableParameters,
  companiesAndBooths,
  loading,
}) => {
  const handleSearch = useCallback(
    (value) => {
      const newParameters = {
        pagination: {},
        filters: {},
        sorter: {},
        searchTerm: { SearchValue: value },
      }
      setTableParameters(newParameters)
    },
    [setTableParameters]
  )

  return (
    <LoadingGif spinning={loading}>
      <div>
        <SearchBox
          className="boothCompaniesList-searchBar"
          onSearch={handleSearch}
          allowClear={true}
        />
        <Table
          dataSource={companiesAndBooths}
          className="cc-company-colleagues-table"
          rowKey={(record) => record.eventCompanyId.toString()}
        >
          <Column
            title="Company Name"
            dataIndex="company.name"
            key="company.name"
            sorter={(a, b) => a.company.name.localeCompare(b.company.name)}
          />
          <Column
            title="Booth Name"
            dataIndex="boothName"
            key="boothName"
            sorter={(a, b) =>
              a.boothName
                ? a.boothName.localeCompare(b.boothName)
                : ''.localeCompare(b.boothName)
            }
          />
        </Table>
      </div>
    </LoadingGif>
  )
}
BoothCompaniesList.propTypes = {
  eventId: PropTypes.number,
  companiesAndBooths: PropTypes.array.isRequired,
  setTableParameters: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}
export default BoothCompaniesList
