import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, DatePicker, Modal, Row, message } from 'antd'
import moment from 'moment'
import FloatingInput from '../../components/FloatingInput'
import { BASE_URL } from '../../config'
import http from '../../utils/api'
import { validURL } from '../../utils/helpers'
import BetterFloatingInput from '../BetterFloatingInput'
import UploadFileField from '../Forms/Fields/UploadFileField'
import Timezone from '../SelectTimezoneComponent/SelectTimezoneComponent'

const MainInfoModal = ({ visible, handleOk, handleCancel, event }) => {
  const [data, setData] = useState(event)

  const updateData = (field, value) => {
    const newState = { ...data }
    newState[field] = value
    setData(newState)
  }

  const saveChanges = () => {
    if (validURL(data.websiteUrl) && validURL(data.registrationUrl))
      http.put('/events/update', data).then((response) => {
        message.success('Summit information updated')
        handleOk()
      })
  }

  const onClose = () => {
    handleCancel()
  }

  useEffect(() => {
    if (event != null) {
      setData(event)
    }
  }, [event])

  return (
    <Modal
      className="cc-modal-accept"
      title={`Edit Summit Main Infomation`}
      visible={visible}
      okText={`Save Changes`}
      cancelText="Close"
      onOk={saveChanges}
      onCancel={onClose}
      okButtonProps={{
        disabled: false,
        style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
      }}
      centered={true}
      cancelButtonProps={{ disabled: false }}
    >
      <div style={{ padding: '13px 16px 32px 32px' }}>
        <div>
          <Row>
            <Col span={24}>
              <FloatingInput
                value={data.address}
                name="Address"
                nameField="Address"
                onChange={(e) => updateData('address', e)}
              />
            </Col>
            <Col span={24}>
              <FloatingInput
                value={data.state}
                name="State"
                nameField="State"
                onChange={(e) => updateData('state', e)}
              />
            </Col>
            <Col span={24}>
              <FloatingInput
                value={data.city}
                name="City"
                nameField="City"
                onChange={(e) => updateData('city', e)}
              />
            </Col>
            <Col span={24}>
              <FloatingInput
                value={data.venue}
                name="Venue"
                nameField="Venue"
                onChange={(e) => updateData('venue', e)}
              />
            </Col>

            <Col span={24}>
              <BetterFloatingInput
                onChange={(e) => {
                  updateData('websiteUrl', e.target.value)
                }}
                isValid={validURL(data.websiteUrl)}
                validationMessage="Please enter a valid url"
                value={data.websiteUrl}
                name="Website Url"
              />
            </Col>
            <Col span={24}>
              <BetterFloatingInput
                onChange={(e) => {
                  updateData('registrationUrl', e.target.value)
                }}
                isValid={validURL(data.registrationUrl)}
                validationMessage="Please enter a valid url"
                value={data.registrationUrl}
                name="Registration Url"
              />
            </Col>
            {!event.virtualSummit && (
              <Col span={24}>
                <h3>Summit Timezone:</h3>
                <Timezone
                  value={data.timeZone}
                  handleTimezoneChange={(e) => updateData('timeZone', e)}
                  shouldHaveDefaultValue={false}
                />
              </Col>
            )}
            {!event.virtualSummit && (
              <Col span={24}>
                <h3>Booth Dates:</h3>
                <Col span={12}>
                  <DatePicker
                    showTime
                    name="boothStartDate"
                    nameField="boothStartDate"
                    className="cc-background-input"
                    size="large"
                    width="100%"
                    defaultValue={moment.utc(
                      data.boothStartDate,
                      moment.ISO_8601
                    )}
                    onChange={(value) => {
                      updateData(
                        'boothStartDate',
                        value != null
                          ? value.utc().format('YYYY-MM-DDTHH:mm:ss')
                          : null
                      )
                    }}
                  />
                </Col>
                <Col span={12}>
                  <DatePicker
                    showTime
                    name="boothEndDate"
                    nameField="boothEndDate"
                    className="cc-background-input"
                    size="large"
                    width="100%"
                    defaultValue={moment.utc(
                      data.boothEndDate,
                      moment.ISO_8601
                    )}
                    onChange={(value) => {
                      updateData(
                        'boothEndDate',
                        value != null
                          ? value.utc().format('YYYY-MM-DDTHH:mm:ss')
                          : null
                      )
                    }}
                  />
                </Col>
              </Col>
            )}

            <Col span={24}>
              <h3>Poster Image</h3>
              <UploadFileField
                accept=".jpg, .png, .bmp"
                listType="picture"
                hint="File Types: .PNG, .JPG Max File Size: 4MB"
                action={`${BASE_URL}uploads`}
                value={data.posterImage}
                name="Logo"
                onChange={(e) => {
                  updateData('posterImage', e)
                }}
              />
            </Col>
            <Col span={24}>
              <h3>Thumbnail Image</h3>
              <UploadFileField
                accept=".jpg, .png, .bmp"
                listType="picture"
                hint="File Types: .PNG, .JPG Max File Size: 4MB"
                action={`${BASE_URL}uploads`}
                value={data.thumbnailImage}
                name="Logo"
                onChange={(e) => {
                  updateData('thumbnailImage', e)
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}

MainInfoModal.MainInfoModal = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  event: PropTypes.number.isRequired,
}

export default MainInfoModal
