import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, message } from 'antd'
import http from '../../utils/api'

const DeleteModal = ({
  visible,
  deleteEndPoint,
  deleteSuccessMessage,
  modalTitle,
  modalOkText,
  modalBody,
  handleOk,
  handleCancel,
}) => {
  const [loading, setLoading] = useState(false)

  const deleteEntity = () => {
    setLoading(true)

    http
      .delete(`${deleteEndPoint}`)
      .then(() => {
        message.success({
          content: `${deleteSuccessMessage}`,
          icon: (
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={{ marginRight: '10px', color: '#D62B34' }}
            />
          ),
        })
      })
      .catch((error) => {
        message.error('Something went wrong!')
        handleOk()
      })
      .finally(() => {
        setLoading(false)
        handleOk()
      })
  }

  return (
    <Modal
      className="cc-modal-accept"
      title={modalTitle}
      visible={visible}
      okText={modalOkText}
      cancelText="Close"
      onOk={deleteEntity}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: loading,
        style: { backgroundColor: '#D62B34', borderStyle: 'none' },
      }}
      centered={true}
      cancelButtonProps={{ disabled: false }}
    >
      <div>
        {' '}
        Are you sure you want to remove <strong>{modalBody}</strong>?{' '}
      </div>
    </Modal>
  )
}

DeleteModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  deleteEndPoint: PropTypes.string,
  deleteSuccessMessage: PropTypes.string,
  modalTitle: PropTypes.string,
  modalOkText: PropTypes.string,
  modalBody: PropTypes.string,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
}

export default DeleteModal
