import CheckboxField from '../../components/ContextForm/Fields/CheckboxField'
import ConditionalRadioField from '../../components/ContextForm/Fields/ConditionalRadioField'
import DateField from '../../components/ContextForm/Fields/DateField'
import InputField from '../../components/ContextForm/Fields/InputField'
import TextField from '../../components/ContextForm/Fields/TextField'
import UploadField from '../../components/ContextForm/Fields/UploadField'

const getRadioFieldComponent = (question) => {
  let component
  switch (question.questionType) {
    case 'Text - Single':
      component = InputField
      break
    case 'Text - Multiple':
      component = TextField
      break
    case 'Boolean':
      component = ConditionalRadioField
      break
    case 'Checkbox':
      component = CheckboxField
      break
    case 'Radio Button':
      component = ConditionalRadioField
      break
    case 'Date':
      component = DateField
      break
    case 'Upload - File':
      component = UploadField
      break
    default:
      component = 'div'
  }
  return component
}

export default getRadioFieldComponent
