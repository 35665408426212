import PropTypes from 'prop-types'
import { Select } from 'antd'
import useFormAnswers from '../../../utils/hooks/useFormAnswers'
import RequirableField from './RequirableField'
import '../Fields.less'

const { Option } = Select

const DropdownField = ({ question }) => {
  const {
    answer,
    handleOptionsAnswerChange,
    isRequiredForApplication,
    handleRequirementChange,
  } = useFormAnswers(question)
  const chosenAnswerId = answer.questionAnswerIds[0]
  return (
    <>
      {question.text && <div className="Text">{question.text}</div>}
      <RequirableField
        allowRequirement={question.allowRequirementSelection}
        checked={isRequiredForApplication}
        onChange={handleRequirementChange}
        containerStyle={{ alignItems: 'center' }}
      >
        <Select
          className="DropdownField"
          placeholder="Select one"
          size="large"
          allowClear
          value={chosenAnswerId}
          onChange={(e) => handleOptionsAnswerChange([e])}
        >
          {question.questionAnswers.map((q, key) => (
            <Option key={key} value={q.questionAnswerId}>
              {q.answer}
            </Option>
          ))}
        </Select>
      </RequirableField>
    </>
  )
}

DropdownField.propTypes = {
  question: PropTypes.object,
}

export default DropdownField
