import { useCallback, useEffect, useState } from 'react'
import { Button } from '@context365/button'
import { TextArea } from '@context365/forms'
import { ChevronLeft, ChevronRight, Save } from '@context365/icons'
import { Divider } from 'antd'
import { getAllocatorNote, saveAllocatorNote } from '../../actions/allocator'
import useDebounced from '../../hooks/useDebounced'
import LoadingGif from '../LoadingGif'

const CALLBACK_WAIT_MS = 200

const Step = ({ children, heading, onSave, onBack, onForward, companyId }) => {
  const [note, setNote] = useState(null)
  const [noteLoading, setNoteLoading] = useState(true)

  const debouncedOnChange = useDebounced(saveAllocatorNote, CALLBACK_WAIT_MS)

  const onNoteChange = useCallback(
    (val) => {
      setNote(val)
      debouncedOnChange(companyId, { note: val, tab: heading })
    },
    [debouncedOnChange, companyId, heading]
  )

  useEffect(() => {
    getAllocatorNote(companyId, heading)
      .then((data) => setNote(data.note))
      .finally(setNoteLoading(false))
  }, [companyId, heading])

  return (
    <div>
      <div className="w-full space-y-4">
        <div className="flex flex-row justify-between">
          <span className="type-subtitle-md">{heading}</span>
          <Button iconLeft={<Save />} onClick={() => onSave()} variant="filled">
            Save
          </Button>
        </div>
        <Divider />
        <LoadingGif spinning={noteLoading}>
          <TextArea
            helpMessage="Notes will automatically save as you type"
            label="Notes"
            rows={8}
            value={note}
            onChange={(e) => onNoteChange(e.target.value)}
          />
        </LoadingGif>
        <Divider />
        {children}
      </div>
      <div className="mt-8 flex justify-between">
        <div className="flex gap-6">
          <Button
            iconLeft={<ChevronLeft />}
            disabled={!onBack}
            onClick={onBack}
          >
            Go Back
          </Button>
          {onForward && (
            <Button iconRight={<ChevronRight />} onClick={onForward}>
              {'Continue'}
            </Button>
          )}
        </div>
        <Button iconLeft={<Save />} onClick={() => onSave()} variant="filled">
          Save
        </Button>
      </div>
    </div>
  )
}

export default Step
