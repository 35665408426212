import { useEffect, useState } from 'react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, Form, Row } from 'antd'
import { map } from 'lodash'
import { getContentEntryCategories } from '../../../actions/contentEntries'
import ContactField from '../Fields/ContactField'

const CategoryStep = (props) => {
  const { values, touched, errors, setFieldValue } = props

  const [categories, setCategories] = useState([])

  useEffect(() => {
    getContentEntryCategories().then((response) => {
      setCategories(
        response.data.map((x) => ({
          label: x.name,
          value: x.contentCategoryId,
        }))
      )
    })
  }, [])

  const onAddContact = () => {
    const val = values.taggedContacts
    val.push({ contactId: null, companyContactId: null, fundId: null })
    setFieldValue('taggedContacts', val)
  }

  const onDeleteContact = (i) => {
    const val = values.taggedContacts
    val.splice(i, 1)
    setFieldValue('taggedContacts', val)
  }

  const onDeleteAuthor = () => {
    setFieldValue('authorContact', null)
  }

  return (
    <>
      <Row>
        <label>
          What category does this content fall under?{' '}
          <span className="required">*</span>
        </label>
        <Form.Item
          name="contentCategoryIds"
          validateStatus={
            touched.contentCategoryIds && errors.contentCategoryIds
              ? 'error'
              : 'success'
          }
          help={
            touched.contentCategoryIds && errors.contentCategoryIds
              ? errors.contentCategoryIds
              : ''
          }
        >
          <Checkbox.Group
            name="contentCategoryIds"
            style={{ display: 'grid' }}
            value={values.contentCategoryIds}
            options={categories}
            onChange={(val) => setFieldValue('contentCategoryIds', val)}
          />
        </Form.Item>
      </Row>
      <ContactField name="authorContact" onDelete={onDeleteAuthor} {...props} />
      <Row>
        <label>Add additonal profiles to this post (optional)</label>
      </Row>
      {map(values.taggedContacts, (contact, i) => (
        <ContactField
          name="taggedContacts"
          key={i}
          itemKey={i}
          isArray
          onDelete={onDeleteContact}
          {...props}
        />
      ))}
      <Button
        type="link"
        onClick={onAddContact}
        style={{ marginBottom: '12px' }}
      >
        <FontAwesomeIcon className="button-icon" icon={faPlus} />
        Add New
      </Button>
    </>
  )
}

CategoryStep.propTypes = {}

export default CategoryStep
