import { useEffect, useState } from 'react'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  PageHeader,
  Radio,
  Row,
  Spin,
  Tooltip,
  message,
} from 'antd'
import { isFunction, isNil } from 'lodash'
import {
  getInvestorStatusQuestions,
  postAllocatorStatus,
} from '../../actions/onboarding'
import http from '../../utils/api'
import ContactHeaderBackLink from '../ContactHeaderBackLink/ContactHeaderBackLink'
import LoadingGif from '../LoadingGif'
import '../OnboardingComponents/Onboarding.less'

const accreditedInvestorInfo =
  'I am an “accredited investor” within the meaning of Rule 501 of Regulation D under the Securities Act of 1933 and have selected the item below which is next to the category under which I qualify as an accredited investor'
const qualifiedInvestorInfo =
  'I am a  “qualified purchaser” within the meaning of Section 2(a)(51)(A) of the Investment Company Act of 1940 and have selected the item below which is next to the category under which I qualify as a qualified purchaser.'

const InvestorStatusForm = ({
  match: {
    params: { companyId, contactId },
  },
  history: { goBack },
}) => {
  const [investorQualifications, setInvestorQualifications] = useState([])
  const [selectedQualification, setSelectedQualification] = useState(null)
  const [investorStatusQuestions, setinvestorStatusQuestions] = useState([])

  const [selectedOptions, setSelectedOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [requestInitiated, setRequestInitiated] = useState(false)
  const [questions, setQuestions] = useState([])
  const [step, setStep] = useState(1)

  if (
    (isNil(investorQualifications) || investorQualifications.length === 0) &&
    !requestInitiated
  ) {
    setRequestInitiated(true)

    getInvestorStatusQuestions(companyId, contactId).then((response) => {
      setQuestions([])
      const qualificationQuestions = response.data
      setInvestorQualifications(response.data)
      const selectedAnswer = qualificationQuestions.find(
        (x) => !isNil(x.answer)
      )
      if (!isNil(selectedAnswer)) {
        setSelectedQualification(selectedAnswer.shortName)
        setSelectedOptions(
          selectedAnswer.answer.map((x) => selectedAnswer.shortName + '|' + x)
        )
      } else {
      }
      setLoading(false)
      setRequestInitiated(false)
    })
  }

  const clearQuestions = () => {
    setQuestions([])
  }

  const onInvestorStatusChange = (questionShortName, selections) => {
    setinvestorStatusQuestions([
      {
        entity: 'CompanyContactAllocator',
        shortName: questionShortName,
        answer: selections.map((x) => x.split('|')[1]),
        questionTypeId: -1,
      },
    ])
    setQuestions(questions)
    console.log(questions)
  }

  const stepQuestions = questions.find((x) => x.step === 6)
  if (
    !isNil(stepQuestions) &&
    selectedQualification !== stepQuestions.questions[0].shortName
  ) {
    setSelectedQualification(stepQuestions.questions[0].shortName)
  }

  useEffect(() => {}, [selectedQualification])

  const onRadioChange = (e) => {
    const selectedValue = e.target.value
    setSelectedQualification(selectedValue)
    onSelectionChanged([], selectedValue)
  }

  const onSelectionChanged = (e, newQualification = null) => {
    setSelectedOptions(e)
    if (isFunction(onInvestorStatusChange)) {
      onInvestorStatusChange(
        isNil(newQualification) ? selectedQualification : newQualification,
        e
      )
    }
  }

  const selectedQualificationObject = investorQualifications.find(
    (x) => x.shortName === selectedQualification
  )

  const renderOption = (answer, answerDetails, answerHelpText) => {
    return (
      <>
        <Checkbox
          value={selectedQualificationObject.shortName + '|' + answer}
          className="cc-investor-status-item-checkbox"
        >
          <div className="cc-body-text cc-investor-status-item-label">
            {answer}
          </div>
        </Checkbox>
        <div className="cc-small-font cc-investor-status-item-details">
          {answerDetails}
        </div>
      </>
    )
  }

  const renderTooltip = (text) => {
    return (
      <Tooltip title={text}>
        <span className="cc-info-icon">
          <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
        </span>
      </Tooltip>
    )
  }

  const postContactAllocator = () => {
    setLoading(true)
    postAllocatorStatus(companyId, contactId, investorStatusQuestions).then(
      (response) => {
        message.success('Contact allocator information saved successfully')
        setLoading(false)
        clearQuestions()
      }
    )
  }

  return !loading ? (
    <Spin style={{ textAlign: 'center' }} size="large" spinning={loading}>
      <ContactHeaderBackLink contactId={contactId}></ContactHeaderBackLink>
      <Row style={{ marginTop: '20px', backgroundColor: 'white' }}>
        <Row>
          <Col span={24}>
            <h2
              style={{
                marginBottom: '24px',
                marginTop: '24px',
                fontWeight: '600',
                marginLeft: '24px',
                fontSize: '18px',
                lineHeight: '22px',
              }}
            >
              Investor Status
            </h2>
          </Col>
          <Divider />
        </Row>
        <div className="cc-layout-default">
          <div
            className="cc-layout-default-form-space"
            style={{
              width: '364px',
            }}
          >
            <div className="cc-investor-status-form">
              <Radio.Group
                onChange={onRadioChange}
                value={selectedQualification}
              >
                <Radio value="Accredited Investor">
                  {' '}
                  <div className="cc-horizontal-spawning-radio">
                    <div className="cc-body-text cc-company-selection-name">
                      Accredited Investor{' '}
                      {renderTooltip(accreditedInvestorInfo)}
                    </div>
                    <div className="cc-small-font cc-company-selection-type">
                      This is a lower level than a qualified purchaser
                    </div>
                  </div>
                </Radio>
                <Radio value="Qualified Purchaser">
                  {' '}
                  <div className="cc-horizontal-spawning-radio">
                    <div className="cc-body-text cc-company-selection-name">
                      Qualified Purchaser {renderTooltip(qualifiedInvestorInfo)}
                    </div>
                    <div className="cc-small-font cc-company-selection-type">
                      This is a higher level than accredited investor
                    </div>
                  </div>
                </Radio>
              </Radio.Group>

              <div className="cc-investor-status-form-separator"></div>

              <div className="cc-investor-status-form-subselections">
                {isNil(selectedQualificationObject) ? null : (
                  <Checkbox.Group
                    value={selectedOptions}
                    onChange={onSelectionChanged}
                  >
                    {selectedQualificationObject.questionAnswers.map((x) =>
                      renderOption(
                        x.questionAnswer,
                        x.answerDetails,
                        x.answerHelpText
                      )
                    )}
                  </Checkbox.Group>
                )}
              </div>
            </div>
          </div>
          <div
            className="form-section-submit"
            style={{
              paddingBottom: '40px',
            }}
          >
            <div className="empty-column-left" />
            <div className="form-section-submit-buttons">
              <Button
                type="default"
                className="form-section-btn-cancel"
                onClick={goBack}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="form-section-btn-save"
                onClick={postContactAllocator}
              >
                Save
              </Button>
            </div>
            <div className="empty-column-right" />
          </div>
        </div>
      </Row>{' '}
    </Spin>
  ) : (
    <div
      style={{ textAlign: 'center', height: '100vh', verticalAlign: 'center' }}
    >
      <LoadingGif style={{ position: 'absolute', top: '50%' }}></LoadingGif>
    </div>
  )
}

export default InvestorStatusForm
