import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import { DatePicker, Input, Option, Select } from '@context365/forms'
import { Add, DeleteOutline } from '@context365/icons'
import { Divider } from 'antd'
import produce from 'immer'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { getAllStrategyOptions } from '../../../actions/funds'
import { formatNumber, fromMillions, toMillions } from '../../../utils/helpers'
import { ADD_NEW_ID } from '../constants'

const getNewCategoryAmount = (categoryId, categoryName) => ({
  alternativeCategoryId: categoryId,
  alternativeCategoryName: categoryName,
  targetPercentage: null,
  actualDollars: null,
  strategies: [],
})

const getNewStrategyAmount = () => ({
  vettedFundStrategyId: null,
  unvettedFundStrategyId: null,
  name: null,
  targetPercentage: null,
  actualDollars: null,
})

const generateStrategyInputId = (fundStrategy) =>
  fundStrategy?.vettedFundStrategyId && fundStrategy?.unvettedFundStrategyId
    ? 'new'
    : fundStrategy?.vettedFundStrategyId
    ? `vettedFundStrategy-${fundStrategy.vettedFundStrategyId}`
    : fundStrategy?.unvettedFundStrategyId
    ? `unvettedFundStrategy-${fundStrategy.unvettedFundStrategyId}`
    : null

const StrategyField = ({ label, options, strategy, onChange, onRemove }) => (
  <div className="grid grid-cols-6 gap-4 pt-3">
    <Select.AutoComplete
      className="col-span-2"
      key={options.length}
      label={label}
      value={generateStrategyInputId(strategy)}
      onChange={(val) => {
        let newVettedFundStrategyId = val?.startsWith('vettedFundStrategy')
          ? val.split('vettedFundStrategy-')[1]
          : null
        let newUnvettedFundStrategyId = val?.startsWith('unvettedFundStrategy')
          ? val.split('unvettedFundStrategy-')[1]
          : null

        if (!newVettedFundStrategyId && !newUnvettedFundStrategyId) {
          newVettedFundStrategyId = ADD_NEW_ID
          newUnvettedFundStrategyId = ADD_NEW_ID
        }

        onChange([
          {
            field: 'vettedFundStrategyId',
            value: newVettedFundStrategyId,
          },
          {
            field: 'unvettedFundStrategyId',
            value: newUnvettedFundStrategyId,
          },
        ])
      }}
    >
      {({ inputValue }) =>
        map(
          concat(
            filter(options, (o) =>
              o.name.toLowerCase().includes(inputValue.toLowerCase())
            ),
            [
              {
                vettedFundStrategyId: ADD_NEW_ID,
                unvettedFundStrategyId: ADD_NEW_ID,
                name: '+ Add Write In',
              },
            ]
          ),
          (opt) => (
            <Option
              key={generateStrategyInputId(opt)}
              value={generateStrategyInputId(opt)}
            >
              {opt.name}
            </Option>
          )
        )
      }
    </Select.AutoComplete>
    {strategy.vettedFundStrategyId === ADD_NEW_ID && (
      <Input
        required
        label="New strategy name"
        value={strategy.name}
        onChange={(e) => onChange([{ field: 'name', value: e.target.value }])}
      />
    )}
    <Input
      label={`${strategy.name ?? ''} actual (millions)`}
      type="number"
      prefix="US$"
      value={toMillions(strategy.actualDollars)}
      helpMessage={formatNumber(strategy.actualDollars)}
      onChange={(e) =>
        onChange([
          { field: 'actualDollars', value: fromMillions(e.target.value) },
        ])
      }
    />
    <Input
      label={`${strategy.name ?? ''} target (percent)`}
      type="number"
      suffix="%"
      value={strategy.targetPercentage}
      helpMessage={formatNumber(strategy.targetPercentage)}
      onChange={(e) =>
        onChange([{ field: 'targetPercentage', value: e.target.value }])
      }
    />
    <IconButton
      className="justify-start -mt-2"
      label="Remove"
      icon={<DeleteOutline className="text-red-100" />}
      variant="link"
      onClick={() => onRemove()}
    />
  </div>
)

const StrategiesField = ({ strategyOptions, strategies, onChange }) => {
  const addStrategy = () => {
    const newStrategies = produce(strategies, (newState) => {
      newState.push(getNewStrategyAmount())
    })
    onChange(newStrategies)
  }

  const removeStrategy = (index) => {
    const newStrategies = produce(strategies, (newState) => {
      newState.splice(index, 1)
    })

    onChange(newStrategies)
  }

  const handleStrategyChange = (index, changes) => {
    const newStrategies = produce(strategies, (newState) => {
      forEach(changes, (c) => {
        newState[index][c.field] = c.value
      })
    })

    onChange(newStrategies)
  }

  return (
    <div className="col-span-2">
      {map(strategies, (strategy, index) => (
        <StrategyField
          label={`Strategy ${index + 1}`}
          options={strategyOptions}
          onAdd={addStrategy}
          onRemove={() => removeStrategy(index)}
          onChange={(changes) => handleStrategyChange(index, changes)}
          strategy={strategy}
        />
      ))}
      <Button
        variant="link"
        className="mt-4 justify-start"
        onClick={() => addStrategy()}
      >
        + Add Strategy
      </Button>
    </div>
  )
}

const AllocationCategories = ({
  amounts,
  strategyOptions,
  onAmountChange,
  onRemoveOtherCategory,
  onAddOtherCategory,
}) => {
  return (
    <>
      {map(amounts, (amount, amIndex) => (
        <div
          key={amIndex}
          className="grid grid-cols-2 gap-2 px-8 text-primary-90 pb-4"
        >
          <div className="col-span-2 type-body-semibold-md flex items-center">
            {isNil(amount.alternativeCategoryId)
              ? amount.alternativeCategoryName
                ? `Other (${amount.alternativeCategoryName})`
                : 'Other'
              : amount.alternativeCategoryName}
            {isNil(amount.alternativeCategoryId) && (
              <Button
                onClick={() => onRemoveOtherCategory(amIndex)}
                variant="link"
                status="error"
              >
                Remove
              </Button>
            )}
          </div>
          {isNil(amount.alternativeCategoryId) && (
            <Input
              label="Name"
              required
              value={amount.alternativeCategoryName}
              onChange={(e) =>
                onAmountChange(
                  amIndex,
                  'alternativeCategoryName',
                  e.target.value
                )
              }
            />
          )}
          <Input
            className="col-start-1"
            label={`${amount.alternativeCategoryName} actual (millions)`}
            type="number"
            prefix="US$"
            value={toMillions(amount.actualDollars)}
            helpMessage={formatNumber(amount.actualDollars)}
            onChange={(e) =>
              onAmountChange(
                amIndex,
                'actualDollars',
                fromMillions(e.target.value)
              )
            }
          />
          <Input
            label={`${amount.alternativeCategoryName} target (percent)`}
            type="number"
            suffix="%"
            value={amount.targetPercentage}
            helpMessage={formatNumber(amount.targetPercentage)}
            onChange={(e) =>
              onAmountChange(amIndex, 'targetPercentage', e.target.value)
            }
          />
          <StrategiesField
            strategyOptions={strategyOptions}
            strategies={amount.strategies}
            onChange={(newStrategies) =>
              onAmountChange(amIndex, 'strategies', newStrategies)
            }
          />
        </div>
      ))}
      <Button
        variant="link"
        className="mx-6"
        onClick={() => onAddOtherCategory()}
      >
        <Add size="small" /> Write-in Other Category
      </Button>
    </>
  )
}

const Allocations = ({
  allocations = [],
  alternativeCategories,
  updateCompany,
  visible,
  refreshStrategies,
}) => {
  const [strategies, setStrategies] = useState([])

  useEffect(() => {
    if (visible) {
      getAllStrategyOptions().then((res) => setStrategies(res.data))
    }
  }, [visible, refreshStrategies])

  const getNewAllocation = () => {
    return {
      valuationDate: null,
      alternativeAssetsTargetPercentage: null,
      alternativeAssetsActualDollars: null,
      amounts: map(alternativeCategories, (c) => {
        return getNewCategoryAmount(c.id, c.name)
      }),
    }
  }

  const addAllocation = () => {
    const newAllocs = allocations.slice()
    newAllocs.push(getNewAllocation())
    updateCompany('allocations', newAllocs)
  }

  const removeAllocation = (index) => {
    const newAllocs = allocations.slice()
    newAllocs.splice(index, 1)
    updateCompany('allocations', newAllocs)
  }

  const handleChange = (index, field, val) => {
    const newAllocs = produce(allocations, (newState) => {
      newState[index][field] = val
    })

    updateCompany('allocations', newAllocs)
  }

  const handleAmountChange = (allocIndex, amountIndex, field, val) => {
    const newAllocs = produce(allocations, (newState) => {
      newState[allocIndex].amounts[amountIndex][field] = val
    })

    updateCompany('allocations', newAllocs)
  }

  const handleAddOtherCategory = (allocIndex) => {
    const newAllocs = produce(allocations, (newState) => {
      newState[allocIndex].amounts.push(getNewCategoryAmount(null, ''))
    })

    updateCompany('allocations', newAllocs)
  }

  const handleRemoveOtherCategory = (allocIndex, amountIndex) => {
    const newAllocs = produce(allocations, (newState) => {
      newState[allocIndex].amounts.splice(amountIndex, 1)
    })

    updateCompany('allocations', newAllocs)
  }

  return (
    <>
      {map(allocations, (allocation, allocIndex) => (
        <>
          <div className="grid grid-cols-2 gap-6">
            <div className="flex space-between">
              <div className="type-subtitle-sm text-primary-90">
                Allocation {allocIndex + 1}
              </div>
              <Button
                variant="link"
                status="error"
                onClick={() => removeAllocation(allocIndex)}
              >
                Remove
              </Button>
            </div>
            <DatePicker
              required
              className="col-start-1"
              label="Allocation date"
              mode="month"
              placeholder="Select date (MM/YY)"
              dateFormat="MM/yy"
              maxDate={new Date()}
              value={
                isNil(allocation.valuationDate)
                  ? null
                  : new Date(allocation.valuationDate)
              }
              onChange={(date) => {
                handleChange(allocIndex, 'valuationDate', date.toDateString())
              }}
            />
            <Input
              className="col-start-1"
              label="Alternative assets actual (millions)"
              type="number"
              prefix="US$"
              value={toMillions(allocation.alternativeAssetsActualDollars)}
              onChange={(e) =>
                handleChange(
                  allocIndex,
                  'alternativeAssetsActualDollars',
                  fromMillions(e.target.value)
                )
              }
              helpMessage={formatNumber(
                allocation.alternativeAssetsActualDollars
              )}
            />
            <Input
              label="Alternative assets target (percent)"
              type="number"
              suffix="%"
              value={allocation.alternativeAssetsTargetPercentage}
              onChange={(e) =>
                handleChange(
                  allocIndex,
                  'alternativeAssetsTargetPercentage',
                  e.target.value
                )
              }
            />
          </div>
          <AllocationCategories
            amounts={allocation.amounts}
            onAmountChange={(amountIndex, field, value) =>
              handleAmountChange(allocIndex, amountIndex, field, value)
            }
            onAddOtherCategory={() => handleAddOtherCategory(allocIndex)}
            onRemoveOtherCategory={(amountIndex) =>
              handleRemoveOtherCategory(allocIndex, amountIndex)
            }
            strategyOptions={strategies}
          />
          <Divider />
        </>
      ))}

      <Button variant="link" className="justify-start" onClick={addAllocation}>
        + Add Allocation
      </Button>
    </>
  )
}

Allocations.propTypes = {
  allocations: PropTypes.array,
  alternativeCategories: PropTypes.array.isRequired,
  updateCompany: PropTypes.func.isRequired,
}

export default Allocations
