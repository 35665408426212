import { Col, Layout, Row } from 'antd'
import { connect } from 'react-redux'
import { logout } from '../actions/auth'
import TopBar from '../components/TopBar/TopBar'
import {
  getAdminStatus,
  getCurrentUser,
  getMarketingStatus,
  getUserFullname,
  getUserInitials,
  getUserRole,
} from '../selectors/auth'

const { Content } = Layout

const AppLayout = (props) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Row style={{ width: '100%' }}>
        <TopBar
          userFullname={props.userFullname}
          logout={props.logout}
          isAdmin={props.isAdmin}
          isMarketing={props.isMarketing}
        />
        <Col span={20} offset={2}>
          <Content style={{ marginTop: '24px' }}>{props.children}</Content>
        </Col>
      </Row>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  userInitials: getUserInitials(state),
  userFullname: getUserFullname(state),
  isAdmin: getAdminStatus(state),
  isMarketing: getMarketingStatus(state),
  role: getUserRole(state),
  pathname: state.router.location.pathname,
  pathkey: state.router.location.key,
})

const mapDispatchToProps = {
  logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout)
