import { useEffect, useState } from 'react'
import {
  getAllocatorCompanyEventsList,
  getFundCompanyEventsList,
  getMediaCompanyEventsList,
  getServiceProviderCompanyEventsList,
} from '../../actions/company'
import ContextTable from '../../components/ContextTable'
import {
  getAllocatorCompanyEventColumns,
  getFundCompanyEventColumns,
  getMediaCompanyEventColumns,
  getServiceProviderCompanyEventColumns,
} from '../../utils/columns'

const CompanySummits = ({ companyId, category }) => {
  var columns = []
  var dataMethod = null

  if (category === 'allocator') {
    columns = getAllocatorCompanyEventColumns
    dataMethod = getAllocatorCompanyEventsList
  } else if (category === 'fund') {
    columns = getFundCompanyEventColumns
    dataMethod = getFundCompanyEventsList
  } else if (category === 'serviceprovider') {
    columns = getServiceProviderCompanyEventColumns
    dataMethod = getServiceProviderCompanyEventsList
  } else if (category === 'media') {
    columns = getMediaCompanyEventColumns
    dataMethod = getMediaCompanyEventsList
  }

  return (
    <ContextTable
      tableTitle="Summits"
      columns={columns}
      dataMethod={dataMethod}
      companyId={companyId}
      scroll={{ x: 1900 }}
    />
  )
}

export default CompanySummits
