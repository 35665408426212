import { faCalendar, faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import { isNil } from 'lodash'

export const enabledDependsOnCredits = (role, otherRole) =>
  (role.toLowerCase() === 'serviceprovider' &&
    otherRole.toLowerCase() !== 'context') ||
  (role.toLowerCase() === 'manager' && otherRole.toLowerCase() === 'allocator')

export const isElevated = (senderRole, receiverRole) => {
  return (
    receiverRole.toLowerCase() === 'context' ||
    (senderRole.toLowerCase() === 'allocator' &&
      receiverRole.toLowerCase() !== 'cfn') ||
    (senderRole.toLowerCase() === 'cfn' &&
      receiverRole.toLowerCase() !== 'allocator' &&
      receiverRole.toLowerCase() !== 'cfn') ||
    (senderRole.toLowerCase() === 'manager' &&
      receiverRole.toLowerCase() === 'service provider') ||
    (senderRole.toLowerCase() === 'manager' &&
      receiverRole.toLowerCase() === 'serviceprovider') ||
    (senderRole.toLowerCase() === 'fund' && receiverRole.toLowerCase() === 'sp')
  )
}

export const getNextAction = (
  meeting,
  role,
  otherRole,
  onSendNewRequest,
  onCancelMeeting,
  onDeclineMeeting,
  creditsAvailable
) => {
  if (isNil(meeting))
    return {
      buttonText: 'Send Meeting Request',
      action: onSendNewRequest,
      enabled: enabledDependsOnCredits(role, otherRole)
        ? creditsAvailable > 0
        : true,
    }
  if (meeting.hasMeeting !== true)
    return {
      buttonText: 'Send Meeting Request',
      action: onSendNewRequest,
      enabled: enabledDependsOnCredits(role, otherRole)
        ? creditsAvailable > 0
        : true,
    }

  if (meeting.meetingStatus.toLowerCase() === 'confirmed')
    return {
      buttonText: 'Cancel Meeting',
      action: onCancelMeeting,
      buttonType: 'danger',
      icon: faTimesCircle,
    }
  else if (
    meeting.meetingStatus.toLowerCase() === 'pending' &&
    meeting.isMeetingSender
  )
    return {
      buttonText: 'Cancel Meeting',
      action: onCancelMeeting,
      buttonType: 'danger',
      icon: faTimesCircle,
    }
  else if (
    meeting.meetingStatus.toLowerCase() === 'pending' &&
    !meeting.isMeetingSender
  )
    return {
      buttonText: 'Decline Meeting',
      action: onDeclineMeeting,
      buttonType: 'danger',
      icon: faTimesCircle,
    }
  else if (
    meeting.meetingStatus.toLowerCase() === 'declined' &&
    !meeting.isMeetingSender
  )
    return {
      buttonText: 'Send Meeting Request',
      action: onSendNewRequest,
      enabled: true,
    }
  else if (
    meeting.meetingStatus.toLowerCase() === 'declined' &&
    meeting.isMeetingSender
  ) {
    if (
      role.toLowerCase() === 'allocator' ||
      role.toLowerCase() === 'context' ||
      (role.toLowerCase() === 'manager' &&
        otherRole.toLowerCase() === 'serviceprovider')
    ) {
      return {
        buttonText: 'Send Meeting Request',
        action: onSendNewRequest,
        enabled: true,
      }
    } else {
      return null
    }
  } else if (meeting.meetingStatus.toLowerCase() === 'cancelled')
    return {
      buttonText: 'Send Meeting Request',
      action: onSendNewRequest,
      enabled: true,
    }
}

export const getNextActionButton = (
  meeting,
  role,
  otherRole,
  onSendNewRequest,
  onCancelMeeting,
  onDeclineMeeting,
  creditsAvailable
) => {
  const action = getNextAction(
    meeting,
    role,
    otherRole,
    onSendNewRequest,
    onCancelMeeting,
    onDeclineMeeting,
    creditsAvailable
  )

  if (isNil(action)) return null

  if (isNil(action.icon)) action.icon = faCalendar

  if (isNil(action.buttonType)) action.buttonType = 'primary'

  return (
    <Tooltip
      title={
        action.enabled === false && enabledDependsOnCredits(role, otherRole)
          ? 'Not enough available credits to send a meeting request'
          : ''
      }
    >
      <Button
        type={action.buttonType}
        onClick={action.action}
        disabled={action.enabled === false}
      >
        <FontAwesomeIcon icon={action.icon} />
        {action.buttonText}
      </Button>
    </Tooltip>
  )
}

export const getRescheduleButton = (meeting, onRescheduleMeeting) => {
  if (isNil(meeting)) return null

  if (meeting.hasMeeting !== true) return null

  if (meeting.meetingStatus.toLowerCase() === 'confirmed')
    return (
      <Button type="primary" onClick={onRescheduleMeeting}>
        <FontAwesomeIcon icon={faCalendar} />
        Reschedule Meeting
      </Button>
    )

  return null
}

export const getAcceptMeetingButton = (meeting, onAcceptMeeting) => {
  if (isNil(meeting)) return null

  if (meeting.hasMeeting !== true) return null

  if (
    meeting.meetingStatus.toLowerCase() === 'pending' &&
    !meeting.isMeetingSender
  ) {
    return (
      <Button
        style={{ background: '#24c477', color: 'white' }}
        onClick={onAcceptMeeting}
      >
        Accept Meeting
      </Button>
    )
  }

  return null
}
