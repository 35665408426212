import PropTypes from 'prop-types'
import { faSignInAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { get } from 'lodash'
import { REACT_APP_365_URL } from '../../config'
import http from '../../utils/api'

const SignInButton = ({ contactId, style }) => (
  <Button
    className="SignInButton"
    type="primary"
    onClick={(e) => signinas(contactId)}
    style={style}
  >
    <span>Login 365</span>
    <FontAwesomeIcon style={{ marginLeft: '6px' }} icon={faSignInAlt} />
  </Button>
)

const signinas = (contactId) => {
  const method = 'Email'
  http
    .post('/auth/signinas', { contactId, method })
    .then((response) => {
      const accessToken = get(response, 'data', '')
      window.open(REACT_APP_365_URL + '/sso?token=' + accessToken, '_blank')
    })
    .catch((error) => {
      console.log(error)
    })
}

SignInButton.propTypes = {
  contactId: PropTypes.number,
}

export default SignInButton
