import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Divider, Modal, Radio, message } from 'antd'
import moment from 'moment'
import { rescheduleOnlineMeeting } from '../../actions/meetings'
import http from '../../utils/api'
import ContactName from '../ContactName'
import Timezone from '../SelectTimezoneComponent/SelectTimezoneComponent'
import './RescheduleMeeting.less'
import '../../styles/index.less'

const RadioGroup = Radio.Group
const RadioButton = Radio.Button

const RescheduleMeeting = ({
  onlineMeetingId,
  visible,
  closeModal,
  onRescheduleMeeting,
}) => {
  const [morningOrAfternoon, setMorningOrAfternoon] = useState('am')
  const [participants, setParticipants] = useState([])
  const [duration, setDuration] = useState(-1)
  const [date, setDate] = useState(moment())
  const [availableDates, setAvailableDates] = useState([])
  const [selectedTime, setSelectedTime] = useState('')
  const [timezone, setTimezone] = useState(moment.tz.guess())

  useEffect(() => {
    if (onlineMeetingId && visible)
      http
        .get(`/meetings/online/participants/${onlineMeetingId}`)
        .then((response) => setParticipants(response.data.result))
  }, [onlineMeetingId, visible])

  const myCompanyContacts = participants.item1
    ? participants.item1.contacts
    : []
  const otherCompanyContacts = participants.item2
    ? participants.item2.contacts
    : []

  useEffect(() => {
    if (
      (duration !== 0 && duration !== 1) ||
      date === '' ||
      myCompanyContacts.length < 0 ||
      otherCompanyContacts.length < 0
    ) {
      return
    }
    let url = `meetings/online/availabilities?date=${date.format(
      'YYYY-MM-DD'
    )}&duration=${duration}&`
    myCompanyContacts.forEach(({ contactId }) => {
      url = `${url}contactIds=${contactId}&`
    })
    otherCompanyContacts.forEach(({ contactId }) => {
      url = `${url}contactIds=${contactId}&`
    })
    url = url.substring(0, url.length - 1)
    http
      .get(url)
      .then((response) => {
        setAvailableDates(response.data.result)
      })
      .catch(() => {
        message.error('Error occurred')
      })
  }, [duration, myCompanyContacts, otherCompanyContacts, date])

  const handleModalOk = () => {
    const endTime =
      duration === 0
        ? moment.utc(selectedTime).add(30, 'minutes').format()
        : moment.utc(selectedTime).add(1, 'h').format()
    rescheduleOnlineMeeting(onlineMeetingId, selectedTime, endTime).then(() => {
      message.success('The meeting has been rescheduled.')
      closeModal()
      onRescheduleMeeting()
    })
  }

  const handleTimezoneChange = (val) => {
    setTimezone(val)
  }

  const renderMeetingHours = () => (
    <RadioGroup
      onChange={(e) => {
        setSelectedTime(e.target.value)
      }}
      value={selectedTime}
      className="cc-meeting-hours-radiogroup"
      size="large"
    >
      {availableDates &&
        availableDates.length > 0 &&
        availableDates.map(
          ({ startTime, endTime }) =>
            (morningOrAfternoon === 'pm'
              ? moment.utc(startTime).hours() >= 12
              : moment.utc(startTime).hours() < 12) && (
              <div className="cc-meeting-hours-buttons">
                <RadioButton key={startTime.toString()} value={startTime}>
                  {`${moment(startTime).format('HH:mm')} - ${moment(
                    endTime
                  ).format('HH:mm')}`}
                </RadioButton>
              </div>
            )
        )}
    </RadioGroup>
  )

  return (
    <div className="cc-meeting-actions">
      <Modal
        className="cc-modal-reschedule"
        title="Reschedule the Meeting"
        visible={visible}
        okText="Reschedule the Meeting"
        onOk={handleModalOk} // timezone should be used here
        onCancel={closeModal}
        okButtonProps={{
          disabled: false,
          size: 'large',
          style: {
            marginLeft: '24px',
            backgroundColor: '#F19F00',
            borderStyle: 'none',
          },
        }}
        cancelButtonProps={{ disabled: false, size: 'large' }}
        width="714px"
        bodyStyle={{ padding: '0' }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ width: '400px', flex: 1 }}>
            <div style={{ marginLeft: '30px', marginTop: '22px' }}>
              <span className="cc-heading5">
                {participants.item2 && participants.item2.name}
              </span>
              <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                {participants.item2 &&
                  participants.item2.contacts.map((contact) => (
                    <div key={contact.contactId} style={{ width: '50%' }}>
                      <ContactName
                        name={contact.contactName}
                        imageUrl={contact.imageURL}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <Divider
              type="horizontal"
              style={{ backgroundColor: '#F7F8FA', margin: '14px 0px 0px 0px' }}
            />
            <div className="cc-reschedule-datepicker-container">
              <DatePicker
                onChange={(value) => setDate(value)}
                disabledDate={(current) => current < moment().startOf('day')}
                popupStyle={{ position: 'relative', verticalAlign: 'middle' }}
                className="cc-datepicker"
                getCalendarContainer={(trigger) => trigger.parentNode}
                open
              />
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#F7F8FA',
              flex: 1,
              width: '100%',
              padding: '16px 24px 3px',
            }}
          >
            <div>
              <div style={{ marginTop: '16px' }} className="cc-heading6">
                Meeting Length
              </div>
              <div style={{ display: 'flex', width: '70%', marginTop: '16px' }}>
                <Radio.Group
                  onChange={(e) => setDuration(e.target.value)}
                  value={duration}
                  style={{ display: 'flex' }}
                >
                  <Radio value={0}>30 minutes</Radio>
                  <Radio value={1}>1 hour</Radio>
                </Radio.Group>
              </div>
            </div>
            <div style={{ marginTop: '16px' }}>
              <span className="cc-heading6">What time works best?</span>
              <Timezone
                value={timezone}
                handleTimezoneChange={handleTimezoneChange}
              />
            </div>
            {availableDates && availableDates.length > 0 && (
              <RadioGroup
                size="large"
                style={{
                  width: '100%',
                  marginTop: '10px',
                  marginBottom: '16px',
                  textAlign: 'center',
                }}
                value={morningOrAfternoon}
                onChange={(e) => setMorningOrAfternoon(e.target.value)}
              >
                <RadioButton className="cc-AMPM-radio-buttons" value="am">
                  MORNING
                </RadioButton>
                <RadioButton className="cc-AMPM-radio-buttons" value="pm">
                  AFTERNOON
                </RadioButton>
              </RadioGroup>
            )}
            <div>{renderMeetingHours()}</div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

RescheduleMeeting.propTypes = {
  visible: PropTypes.bool.isRequired,
  onlineMeetingId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  onRescheduleMeeting: PropTypes.func.isRequired,
}

export default RescheduleMeeting
