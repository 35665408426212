import PropTypes from 'prop-types'
import { Checkbox } from 'antd'
import includes from 'lodash/includes'
import ContactName from '../ContactName'
import './ParticipantsCheckbox.less'

const ParticipantsCheckbox = ({
  contactId,
  companyContactId,
  contactName,
  imageURL,
  position,
  participants,
  checked,
  disabled = false,
  useCompanyContactId = false,
}) => {
  const isChecked = useCompanyContactId
    ? includes(participants, companyContactId)
    : includes(participants, contactId)
  return (
    <div
      className={`cc-check-participants-box ${
        isChecked ? ' cc-check-participants-box-checked' : ''
      }`}
    >
      <Checkbox
        className="cc-check-participants"
        value={useCompanyContactId ? companyContactId : contactId}
        checked={isChecked}
        onChange={checked}
        contactName={contactName}
        disabled={disabled}
      >
        <ContactName
          name={contactName}
          imageUrl={imageURL}
          position={position}
        />
      </Checkbox>
    </div>
  )
}

ParticipantsCheckbox.defaultProps = {
  position: '',
}

ParticipantsCheckbox.propTypes = {
  contactId: PropTypes.number.isRequired,
  companyContactId: PropTypes.number,
  contactName: PropTypes.string.isRequired,
  imageURL: PropTypes.string.isRequired,
  position: PropTypes.string,
  checked: PropTypes.func.isRequired,
  participants: PropTypes.arrayOf(PropTypes.number).isRequired,
  useCompanyContactId: PropTypes.bool,
}

export default ParticipantsCheckbox
