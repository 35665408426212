import { useContext, useEffect } from 'react'
import { useFormikContext } from 'formik'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import FormCtx from '../contexts/FormCtx'
import getQuestionAnswer from '../form/getQuestionAnswer'
import setInvalidAnswersList from '../form/setInvalidAnswersList'

const useFormAnswers = (question) => {
  const {
    answers,
    onSetAnswers,
    onSetInvalidAnswers,
    questions,
    requiredQuestionsForApplication,
    onSetRequiredQuestionsForApplication,
  } = useContext(FormCtx)
  const { setFieldValue, errors } = useFormikContext()
  const answer = getQuestionAnswer(question, answers)
  const firstAnswer =
    (answer.questionAnswerIds && answer.questionAnswerIds[0]) || null
  const isRequiredForApplication = includes(
    requiredQuestionsForApplication,
    question.questionId
  )

  const unFilteredAnswers = answers.filter((a) => {
    const dependentOnQuestion = questions.find(
      (q) => q.questionId === a.questionId
    ) || {
      attributes: { dependentOnQuestion: null },
    }
    return (
      a.questionId !== question.questionId &&
      dependentOnQuestion.attributes.dependentOnQuestion !==
        question.questionId.toString()
    )
  })

  const handleRemoveValidations = () =>
    onSetInvalidAnswers((prevInvalidAnswers) =>
      prevInvalidAnswers.filter((answer) => {
        const dependentOnQuestion = questions.find(
          (q) => q.shortName === answer.shortName
        ) || {
          attributes: { dependentOnQuestion: null },
        }
        return (
          answer.shortName !== question.shortName &&
          dependentOnQuestion.attributes.dependentOnQuestion !==
            question.questionId.toString()
        )
      })
    )

  const handleTextAnswerChange = (value) => {
    onSetAnswers([
      ...unFilteredAnswers,
      {
        questionId: question.questionId,
        shortName: question.shortName,
        dependentOnQuestion: isEmpty(question.attributes)
          ? null
          : question.attributes.dependentOnQuestion,
        responseText: value,
      },
    ])
    setFieldValue(question.shortName, value)
    handleRemoveValidations()
  }

  const handleOptionsAnswerChange = (values) => {
    onSetAnswers([
      ...unFilteredAnswers,
      {
        questionId: question.questionId,
        shortName: question.shortName,
        dependentOnQuestion: isEmpty(question.attributes)
          ? null
          : question.attributes.dependentOnQuestion,
        questionAnswerIds: values,
      },
    ])
    setFieldValue(question.shortName, values)
    handleRemoveValidations()
  }

  const handleRequirementChange = (isRequiredForApplication) => {
    if (isRequiredForApplication) {
      onSetRequiredQuestionsForApplication([
        ...requiredQuestionsForApplication,
        question.questionId,
      ])
    } else {
      onSetRequiredQuestionsForApplication(
        filter(
          requiredQuestionsForApplication,
          (rq) => rq !== question.questionId
        )
      )
    }
  }

  useEffect(() => {
    setInvalidAnswersList(
      question.shortName,
      question.isRequired,
      answer.responseText,
      onSetInvalidAnswers,
      firstAnswer,
      question.stepNumber,
      errors
    )
  }, [
    answer.responseText,
    firstAnswer,
    question.isRequired,
    question.shortName,
    onSetInvalidAnswers,
    question.stepNumber,
    errors,
  ])

  return {
    answer,
    isRequiredForApplication,
    handleTextAnswerChange,
    handleOptionsAnswerChange,
    handleRequirementChange,
  }
}

export default useFormAnswers
