import http from '../utils/api'

export const getAllocatorKpis = (type, companyContactId) =>
  http.get(`allocators/kpis/${type}/${companyContactId}`)

export const getAllocatorKpiFunds = (
  type,
  companyContactId,
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post(`allocators/kpis/${type}/details/${companyContactId}`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getContactSendGridStats = (contactId) =>
  http.get(`contacts/sendgriddetails/${contactId}`)
