import Announcements from './Announcements'

const AnnouncementsContainer = ({ history: { goBack } }) => {
  return (
    <>
      <Announcements />
    </>
  )
}

export default AnnouncementsContainer
