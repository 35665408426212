import PropTypes from 'prop-types'
import {
  faBadgeCheck,
  faExternalLink,
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Divider, Row } from 'antd'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import map from 'lodash/map'
import moment from 'moment'
import numeral from 'numeral'
import defaultImage from '../../assets/no_image_available.png'
import { getClickableLink } from '../../utils/helpers'
import './ProfileOverviewTab.less'

const percentageValues = [
  'Target IRR',
  'Interest Rate',
  'Dividend Return',
  'Target Return',
]
const currency = [
  'Minimum Investment Size',
  'Average Investment Size',
  'Portfolio Size',
  'Mandate Size',
  'Offer Size',
  'Current Raised Investment',
  'Fund AUM Minimum',
  'Fund AUM Maximum',
  'Firm AUM Minimum',
  'Firm AUM Maximum',
]

const titleAndValue = (label, value, className) => {
  if (currency.includes(label)) {
    value = numeral(value).format('($0.0a)').toUpperCase()
  } else if (isArray(value) && value.length > 1) {
    value = join(value, ', ').replace(/,([^,]*)$/, ' and $1')
  } else if (percentageValues.includes(label)) value = `${value} %`
  else if (label === 'MOIC') value = `${value}x`
  else if (label === 'Image Url') {
    return <img src={value} width={200} fallback={defaultImage} />
  } else if (label.indexOf('Date') > -1) {
    value = moment.utc(value).format('MM/DD/YYYY')
  }

  return (
    <div style={{ marginBottom: '24px', paddingLeft: '20px' }}>
      {label.indexOf('Link') === -1 && (
        <span className="cc-grey-text cc-tabletitle-text">{label}</span>
      )}
      {label === 'Company Website' ||
        (label.indexOf('Link') > -1 && (
          <a
            style={{ display: 'block' }}
            href={getClickableLink(value)}
            rel="noreferrer"
            target="_blank"
            className={className}
          >
            <FontAwesomeIcon icon={faExternalLink} />
            {value}
          </a>
        )) || <div className={className}>{value}</div>}
    </div>
  )
}

const ProfileOverviewTab = ({
  sections,
  sideSections,
  postedBy = '',
  dealDisclaimer = false,
  onDealDisclaimerClick = () => {},
}) => {
  return (
    <>
      <Row className="antd-row-custom">
        <Col
          span={(!isEmpty(sideSections) && !isNil(sideSections) && 15) || 24}
        >
          {(isEmpty(sections) && (
            <div className="profile-empty">
              <FontAwesomeIcon icon={faInfoCircle} size="2x" color="#2fc5d2" />
              No data
            </div>
          )) ||
            map(sections, (x) =>
              titleAndValue(x.label, x.value, 'cc-profile-info', x.type)
            )}
        </Col>
        {!isEmpty(sideSections) && !isNil(sideSections) && (
          <>
            <Col span={2}>
              <Divider type="vertical" style={{ height: '100%' }} />
            </Col>
            <Col span={7}>
              {map(sideSections, (x) =>
                titleAndValue(x.label, x.value, 'cc-heading3 profile-link')
              )}
            </Col>
          </>
        )}
      </Row>
      {postedBy && !isEmpty(postedBy) && (
        <Row>
          <Col span={24}>
            <div className="cc-body-text deal-info-text">
              <FontAwesomeIcon
                icon={faBadgeCheck}
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '8px',
                  verticalAlign: 'middle',
                }}
              />
              Posted by {postedBy.toLowerCase() === 'allocator' ? 'an' : 'a'}{' '}
              {postedBy}{' '}
              {postedBy.toLowerCase() === 'context family network'
                ? 'member'
                : ''}
            </div>
            {dealDisclaimer && (
              <div className="cc-body-text deal-info-text">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '8px',
                    verticalAlign: 'middle',
                  }}
                />
                <Button
                  type="link"
                  style={{ margin: 0, padding: 0 }}
                  onClick={onDealDisclaimerClick}
                >
                  Please read this important disclaimer.
                </Button>
              </div>
            )}
          </Col>
        </Row>
      )}
    </>
  )
}

ProfileOverviewTab.propTypes = {
  sections: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  sideSections: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  postedBy: PropTypes.string,
  dealDisclaimer: PropTypes.bool,
  onDealDisclaimerClick: PropTypes.func,
}

export default ProfileOverviewTab
