import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isArray } from 'util'
import {
  Avatar,
  Button,
  Col,
  Divider,
  Icon,
  Radio,
  Row,
  Tabs,
  Typography,
  message,
} from 'antd'
import { isNil } from 'lodash'
import numeral from 'numeral'
import http from '../../utils/api'
import EditButton from '../EditButton/EditButton'
import './UserDetails.less'

const { Title, Paragraph } = Typography
const { TabPane } = Tabs

//qualitative interest fields
const attributes = [
  { text: 'Strategy', field: 'strategy' },
  { text: 'Liquid Strategies', field: 'liquidStrategies' },
  { text: 'Liquid Instruments Interest', field: 'instrumentsInterest' },
  { text: 'Illiquid Strategies', field: 'illiquidStrategies' },
  { text: 'Net Dollar Exposure', field: 'netDollarExposure' },
  { text: 'Liquidity Terms', field: 'liquidityTerms' },
  { text: 'Capital Lock Up Preference', field: 'capitalLockUpPreference' },
  {
    text: "Max % of a fund's AUM would investing",
    field: 'maxPercentageOfAFundsAUMTheyWouldBeComfortableComprising',
  },
  { text: 'Investment Objectives', field: 'investmentObjectives' },
  { text: 'Sectors', field: 'sectors' },
  { text: 'Geographic Concentration', field: 'geographicConcentration' },
  { text: 'Investment Decision Process', field: 'investmentDecisionProcess' },
  { text: 'First Loss Capital', field: 'firstLossCapital' },
  { text: 'Fund Attributes', field: 'fundAttributes' },
  { text: 'Mandate Order', field: 'mandateOrder' },
  { text: 'Additional Information', field: 'additionalInformation' },
]

const AllocatorDetails = ({ contactId, details, hideInvestorStatus }) => {
  const noEditState = {
    investorStatus: false,
    investorDetails: false,
  }

  const [buttonVisible, setButtonVisible] = useState(noEditState)
  const [companiesVisibility, setCompaniesVisibility] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isInvisible, setIsInvisible] = useState(false)
  const fetchVisibility = useCallback(() => {
    http
      .get(`/allocators/invisible/${contactId}`)
      .then(function (response) {
        setCompaniesVisibility(response.data)
        //getManagerDetails(contactId);
      })
      .catch(function (error) {
        //handle error
        console.log(error)
        //getManagerDetails(contactId);
      })
  }, [])

  useEffect(() => {
    fetchVisibility()
  }, [fetchVisibility])

  const handleVisibilityChange = useCallback((companyId, contactId, e) => {
    //setIsInvisible(invisible);
    const invisible = e.target.value === 'true'
    setIsSaving(true)
    http
      .patch('/allocators/invisible', {
        companyId,
        contactId,
        isInvisible: invisible,
      })
      .then((response) => {
        message.success('Visibility has been successfully saved')
        fetchVisibility()
        setIsSaving(false)
      })
      .catch((error) => {
        console.log('error on post invisibility', error)
      })
  }, [])

  const toggleEdit = (section, value) => {
    const newState = { ...noEditState }
    newState[section] = value
    setButtonVisible(newState)
  }

  let investorStatusColumns = []
  if (
    details.investorQualificationSubcategory != null &&
    details.investorQualificationSubcategory.length > 0
  ) {
    investorStatusColumns = columnPairs(
      details.investorQualificationSubcategory
    )
  }

  return (
    <div>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ background: 'white' }}
        onMouseEnter={(e) => toggleEdit('investorStatus', true)}
        onMouseLeave={(e) => toggleEdit('investorStatus', false)}
      >
        <Col span={24}>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="editRow"
          >
            <Col span={12}>
              <Title level={4} className="header">
                Investor Status
              </Title>
            </Col>
            <Col span={12}>
              <div
                style={{ textAlign: 'right' }}
                hidden={
                  hideInvestorStatus === false
                    ? !buttonVisible.investorStatus
                    : true
                }
              >
                <EditButton
                  onClick={`/investorstatus/${details.companyId}/${contactId}`}
                />
              </div>
            </Col>
          </Row>
          <Divider />
          <Row
            type="flex"
            justify="space-between"
            align="top"
            className="paddedRow"
          >
            <Col span={24}>
              <div className="greenHeader">{details.investorStatus}</div>
            </Col>
          </Row>
          {investorStatusColumns.map((column, index) => (
            <Row
              type="flex"
              justify="space-between"
              align="top"
              className="paddedRow"
            >
              <Col span={12}>
                <Row
                  type="flex"
                  justify="space-between"
                  align="top"
                  style={{ paddingBottom: '8px' }}
                >
                  <div className="boldText">
                    <Icon type="check" className="check" />
                    {column[0]}
                  </div>
                </Row>
              </Col>
              {column.length > 1 ? (
                <Col span={12}>
                  <Row
                    type="flex"
                    justify="space-between"
                    align="top"
                    style={{ paddingBottom: '8px' }}
                  >
                    <div className="boldText">
                      <Icon type="check" className="check" />
                      {column[1]}
                    </div>
                  </Row>
                </Col>
              ) : null}
            </Row>
          ))}
        </Col>
      </Row>
      <Divider />
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ background: 'white' }}
        onMouseEnter={(e) => toggleEdit('investorDetails', true)}
        onMouseLeave={(e) => toggleEdit('investorDetails', false)}
      >
        <Col span={24}>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="editRow"
          >
            <Col span={12}>
              <Title level={4} className="header">
                Investor Details
              </Title>
            </Col>
            <Col span={12}>
              <div
                style={{ textAlign: 'right' }}
                hidden={!buttonVisible.investorDetails}
              >
                <EditButton
                  onClick={`/investordetails/${details.companyId}/${contactId}`}
                />
              </div>
            </Col>
          </Row>
          <Divider />
          <Row
            type="flex"
            justify="space-between"
            align="top"
            className="paddedRow"
          >
            <Col span={24}>
              <div className="blueHeader">Quantitive Interest</div>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-between"
            align="top"
            className="paddedRow"
          >
            <Col span={6}>
              <Row
                type="flex"
                justify="space-between"
                align="top"
                style={{ paddingBottom: '8px' }}
              >
                <div className="blue">Investment Size</div>
              </Row>
              <Row type="flex" justify="space-between" align="top">
                <div className="boldText">
                  {numeral(details.minimumInvestmentSize)
                    .format('($0,0[.]0 a)')
                    .toUpperCase()}
                  -{' '}
                  {numeral(details.averageInvestmentSize)
                    .format('($0,0[.]0 a)')
                    .toUpperCase()}
                </div>
              </Row>
            </Col>
            <Col span={6}>
              <Row
                type="flex"
                justify="space-between"
                align="top"
                style={{ paddingBottom: '8px' }}
              >
                <div className="blue">Fund Size Interest</div>
              </Row>
              <Row type="flex" justify="space-between" align="top">
                <div className="boldText">
                  {numeral(details.minimumFundSizeInterest)
                    .format('($0,0[.]0 a)')
                    .toUpperCase()}
                  -{' '}
                  {numeral(details.maximumFundSizeInterest)
                    .format('($0,0[.]0 a)')
                    .toUpperCase()}
                </div>
              </Row>
            </Col>
            <Col span={6}>
              <Row
                type="flex"
                justify="space-between"
                align="top"
                style={{ paddingBottom: '8px' }}
              >
                <div className="blue">Firm Size AUM Interest</div>
              </Row>
              <Row type="flex" justify="space-between" align="top">
                <div className="boldText">
                  {numeral(details.minimumFirmSizeInterest)
                    .format('($0,0[.]0 a)')
                    .toUpperCase()}
                  -{' '}
                  {numeral(details.maximumFirmSizeInterest)
                    .format('($0,0[.]0 a)')
                    .toUpperCase()}
                </div>
              </Row>
            </Col>
            <Col span={6}>
              <Row
                type="flex"
                justify="space-between"
                align="top"
                style={{ paddingBottom: '8px' }}
              >
                <div className="blue">Track Record Length</div>
              </Row>
              {checkedRows(details.trackRecordLength)}
            </Col>
          </Row>
          <Divider />
          <Row
            type="flex"
            justify="space-between"
            align="top"
            className="paddedRow"
          >
            <Col span={24}>
              <div className="blueHeader">Qualitative Interest</div>
            </Col>
          </Row>
          {attributeRows(attributes, details)}
        </Col>
      </Row>
      <Divider />
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ background: 'white' }}
        onMouseEnter={(e) => toggleEdit('investorDetails', true)}
        onMouseLeave={(e) => toggleEdit('investorDetails', false)}
      >
        <Col span={24}>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="editRow"
          >
            <Col span={12}>
              <Title level={4} className="header">
                Investor Visibility
              </Title>
            </Col>
            <Col span={12} />
          </Row>
          <Divider />
          <Row
            type="flex"
            justify="space-between"
            align="top"
            className="paddedRow"
          >
            {isArray(companiesVisibility) &&
              companiesVisibility.map((item, index) => (
                <div className="visibility-row">
                  <div className="cc-matchmaking-settings">
                    <div
                      type="flex"
                      style={{
                        alignItems: 'stretch',
                      }}
                    >
                      <span className="cc-body-text cc-lightblack-text cc-matchmaking-settings-radio-text">
                        {item.companyName}
                      </span>
                      <br />
                      <Radio.Group
                        onChange={(e) =>
                          handleVisibilityChange(
                            item.companyId,
                            item.contactId,
                            e
                          )
                        }
                        value={`${item.isInvisible}`}
                        disabled={isSaving}
                        className="cc-matchmaking-settings-radio-visibility"
                      >
                        <Radio
                          className="cc-body-text cc-lightblack-text"
                          value="false"
                          style={{ paddingTop: '20px', marginLeft: '130px' }}
                        >
                          Visible
                        </Radio>
                        <Radio
                          className="cc-body-text cc-lightblack-text "
                          value="true"
                        >
                          Hidden
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
              ))}
          </Row>
          <Divider />
        </Col>
      </Row>
    </div>
  )
}

const attributeList = (attributes, details) => {
  const attributeList = []
  let val

  for (let i = 0; i < attributes.length; i++) {
    val = details[attributes[i].field]

    if ((isArray(val) && val.length > 0) || (!isArray(val) && !isNil(val))) {
      attributes[i].value = val
      attributeList.push(attributes[i])
    }
  }

  return attributeList
}

const columnPairs = (attributeList) => {
  const grouped = []
  let row = []

  for (let i = 0; i < attributeList.length; i++) {
    if (i % 2 === 0) {
      row.push(attributeList[i])
    } else {
      row.push(attributeList[i])
      grouped.push(row)
      row = []
    }
  }

  //if there is one extra column
  if (row.length > 0) grouped.push(row)

  return grouped
}

const attributeRows = (attributes, details) => {
  const attrList = attributeList(attributes, details)
  const groupedRows = columnPairs(attrList)

  return groupedRows.map((column, index) => (
    <Row
      type="flex"
      justify="space-between"
      align="top"
      style={{ paddingBottom: '48px' }}
    >
      <Col span={12}>
        <Row
          type="flex"
          justify="space-between"
          align="top"
          style={{ paddingBottom: '8px' }}
        >
          <div className="blue">{column[0].text}</div>
        </Row>
        {isArray(column[0].value) ? (
          checkedRows(column[0].value)
        ) : (
          <Row
            type="flex"
            justify="space-between"
            align="top"
            style={{ paddingBottom: '8px' }}
          >
            <div className="boldText">{column[0].value}</div>
          </Row>
        )}
      </Col>
      {column.length > 1 ? (
        <Col span={12}>
          <Row
            type="flex"
            justify="space-between"
            align="top"
            style={{ paddingBottom: '8px' }}
          >
            <div className="blue">{column[1].text}</div>
          </Row>
          {isArray(column[1].value) ? (
            checkedRows(column[1].value)
          ) : (
            <Row
              type="flex"
              justify="space-between"
              align="top"
              style={{ paddingBottom: '8px' }}
            >
              <div className="boldText">{column[1].value}</div>
            </Row>
          )}
        </Col>
      ) : null}
    </Row>
  ))
}

const checkedRows = (items) => {
  if (isArray(items)) {
    return items.map((item, index) => (
      <Row
        type="flex"
        justify="space-between"
        align="top"
        style={{ paddingBottom: '8px' }}
      >
        <div className="boldText">
          <Icon type="check" className="check" />
          {item}
        </div>
      </Row>
    ))
  }

  return null
}

const renderVisibilities = (companiesData) => null

AllocatorDetails.propTypes = {
  details: PropTypes.object,
}

export default AllocatorDetails
