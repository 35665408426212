import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Empty, Spin } from 'antd'
import axios from 'axios'
import isNil from 'lodash/isNil'
import UserDetails from '../components/UserDetails/UserDetails'
import http from '../utils/api'

const UserDetailsContainer = ({
  match: {
    params: { contactId, tab },
  },
  history: { goBack },
}) => {
  const selectedcontactId = parseInt(contactId, 10)

  const [user, setUser] = useState(null)
  useEffect(() => {
    http
      .get('/contacts/details/' + selectedcontactId)
      .then(function (response) {
        var userData = response.data

        if (Array.isArray(userData) && userData.length > 0)
          userData = userData[0]

        setUser(userData)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }, [])

  return (
    <UserDetails
      contactId={selectedcontactId}
      selectedUser={user}
      tab={tab}
      back={goBack}
    />
  )
}

UserDetailsContainer.propTypes = {}

export default UserDetailsContainer
