import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Spin, Tabs } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { isNumericLiteral } from 'typescript'
import { getEventDates, getEventSchedule } from '../../actions/events'
import CenteredPrimaryRadioButtons from '../CenteredPrimaryRadioButtons'
import ScheduleDay from '../ScheduleDay/ScheduleDay'

const { TabPane } = Tabs

const Schedule = ({ eventId, companyEventId, companyId, isPast }) => {
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [scheduleDays, setScheduleDays] = useState(null)
  const [activeTab, setActiveTab] = useState('0')
  const [eventDates, setEventDates] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)

  const getDayButtons = () => {
    if (eventDates === null) return null
    else
      return eventDates.map((x, index) => ({
        value: index.toString(),
        text: moment(x).format('dddd'),
      }))
  }

  useEffect(() => {
    setLoading(true)
    getEventDates(eventId).then((dateResponse) => {
      setEventDates(dateResponse.data)
      if (isNil(selectedDate)) setSelectedDate(dateResponse.data[0])
      setLoading(false)
    })
  }, [eventId, companyEventId, refresh])

  // useEffect(() => {
  //   setLoading(true);
  //   console.log("changed selected date");
  //   console.log(selectedDate);
  //   getEventSchedule(eventId, companyEventId, selectedDate).then(response => {
  //     setScheduleDays(response.data);
  //     setLoading(false);
  //     console.log(scheduleDays);
  //   });
  // }, [eventId, companyEventId, refresh, selectedDate]);

  const radioHandler = (e) => {
    setActiveTab(e.target.value)
    setSelectedDate(eventDates[e.target.value])
  }

  return (
    <div style={{ minHeight: '500px' }}>
      <Spin
        spinning={loading}
        size="large"
        // style={{ marginTop: "250px", marginLeft: "50%" }}
      >
        {eventDates && !loading && (
          <CenteredPrimaryRadioButtons
            selectedKey={activeTab}
            buttons={getDayButtons()}
            OnClickFunction={radioHandler}
          />
        )}

        {selectedDate && !loading && (
          <ScheduleDay
            eventDate={selectedDate}
            companyId={companyId}
            eventId={eventId}
            companyEventId={companyEventId}
            isPast={isPast}
            refreshSchedule={() => {
              setRefresh(!refresh)
            }}
          />
        )}

        {/* {scheduleDays && (
          <Tabs
            animated={false}
            tabBarStyle={{ display: "none" }}
            activeKey={activeTab}
          >
            {scheduleDays &&
              scheduleDays.map((x, index) => (
                <TabPane tab={x.eventDate} key={index.toString()}>
                  <ScheduleDay
                    eventDate={x.eventDate}
                    companyId={companyId}
                    eventId={eventId}
                    timeSlots={x.items}
                    companyEventId={companyEventId}
                    refreshSchedule={() => {
                      setRefresh(!refresh);
                    }}
                  />
                </TabPane>
              ))}
          </Tabs>
        )} */}
      </Spin>
    </div>
  )
}

Schedule.propTypes = {
  eventId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  companyEventId: PropTypes.number.isRequired,
}
export default Schedule
