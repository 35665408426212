import { useState } from 'react'
import PropTypes from 'prop-types'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getAdminStatus } from '../../selectors/auth'
import CountDetails from '../CountDetails'
import SummitAttendeeSMS from '../SummitAttendeeSMS/SummitAttendeeSMS'
import SummitAttendeeSMSLog from '../SummitAttendeeSMS/SummitAttendeeSMSLog'
import './SummitInfo.less'

const SummitInfo = ({ event, back, heldBooths }) => {
  const isAdmin = useSelector(getAdminStatus)
  const [sendSMSVisible, setSendSMSVisible] = useState(false)
  const [viewSMSVisible, setViewSMSVisible] = useState(false)

  const boothStartDate =
    moment(event.boothStartDate).isValid() && !isNil(event.boothStartDate)
      ? moment
          .utc(event.boothStartDate, moment.ISO_8601)
          .local()
          .format('MMM D, YYYY')
      : ''
  const boothEndDate =
    moment(event.boothEndDate).isValid() && !isNil(event.boothEndDate)
      ? moment
          .utc(event.boothEndDate, moment.ISO_8601)
          .local()
          .format('MMM D, YYYY')
      : ''

  return (
    <>
      <Row type="flex" justify="space-between">
        <Col>
          <Row type="flex" justify="space-between">
            <Button
              onClick={back}
              style={{ marginRight: '10px', padding: '0px 10px' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <h1
              className="cc-section-header-title"
              style={{ lineHeight: '30px' }}
            >
              {event.name}
            </h1>
          </Row>
          {isAdmin && (
            <div>
              <Button
                className="btn-edit-action"
                onClick={() => setSendSMSVisible(true)}
                style={{ marginLeft: '10px' }}
              >
                Send SMS to all attendees
              </Button>
              <Button
                className="btn-edit-action"
                style={{ marginLeft: '10px' }}
                onClick={() => setViewSMSVisible(true)}
              >
                View SMS Logs
              </Button>
            </div>
          )}
        </Col>
        <Col lg={14} md={18} sm={24}>
          <Row type="flex" justify="space-between" className="summit-stat-row">
            <Col
              span={5}
              style={{ borderRight: '2px solid rgba(239, 241, 244, 1)' }}
            >
              <h3
                style={{
                  paddingTop: '12px',
                  textAlign: 'right',
                }}
                className="registrations-header"
              >
                Registrations
              </h3>
            </Col>
            <Col span={3}>
              <CountDetails count={event.allocators} label="Allocators" />
            </Col>
            <Col span={event.cfns > 0 ? 3 : 5}>
              <CountDetails count={event.fundManagers} label="Funds" />
            </Col>
            <Col span={event.cfns > 0 ? 5 : 6}>
              <CountDetails
                count={event.serviceProviders}
                label="Service Providers"
              />
            </Col>
            <Col span={event.cfns > 0 ? 2 : 3}>
              <CountDetails count={event.media} label="Media" />
            </Col>
            {event.cfns > 0 && (
              <Col span={3}>
                <CountDetails count={event.cfns} label="CFN" />
              </Col>
            )}
          </Row>
          <Row type="flex" justify="space-between" className="summit-stat-row">
            <Col
              span={5}
              style={{ borderRight: '2px solid rgba(239, 241, 244, 1)' }}
            >
              <h3
                style={{
                  paddingTop: '12px',
                  textAlign: 'right',
                }}
                className="registrations-header"
              >
                Meetings
              </h3>
            </Col>
            <Col span={3}>
              <CountDetails count={event.pendingMeetings} label="Pending" />
            </Col>
            <Col span={3}>
              <CountDetails count={event.confirmedMeetings} label="Confirmed" />
            </Col>
            <Col span={3}>
              <CountDetails count={event.declinedMeetings} label="Declined" />
            </Col>
            <Col span={3}>
              <CountDetails count={event.cancelledMeetings} label="Cancelled" />
            </Col>
            <Col span={3}>
              <CountDetails count={event.expiredMeetings} label="Expired" />
            </Col>
          </Row>
          {!event.virtualSummit && (
            <Row
              type="flex"
              justify="space-between"
              className="summit-stat-row"
              style={{ marginBottom: 0 }}
            >
              <Col
                span={5}
                style={{ borderRight: '2px solid rgba(239, 241, 244, 1)' }}
              >
                <h3
                  style={{
                    paddingTop: '12px',
                    textAlign: 'right',
                  }}
                  className="registrations-header"
                >
                  Booth
                </h3>
              </Col>
              <Col span={3}>
                <CountDetails count={event.bookedCount} label="Booked" />
              </Col>
              <Col span={3}>
                <CountDetails count={heldBooths} label="Held" />
              </Col>
              <Col span={6}>
                <CountDetails count={boothStartDate} label="Booth open date" />
              </Col>
              <Col span={6}>
                <CountDetails count={boothEndDate} label="Booth closed date" />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <SummitAttendeeSMS
        eventId={event.eventId}
        eventName={event.name}
        isVisible={sendSMSVisible}
        handleClose={() => setSendSMSVisible(false)}
      />
      <SummitAttendeeSMSLog
        eventId={event.eventId}
        isVisible={viewSMSVisible}
        handleClose={() => setViewSMSVisible(false)}
      />
    </>
  )
}

SummitInfo.propTypes = {
  event: PropTypes.shape({
    eventId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    allocators: PropTypes.number.isRequired,
    //allocatorContacts: PropTypes.number.isRequired,
    fundManagers: PropTypes.number.isRequired,
    media: PropTypes.number.isRequired,
    serviceProviders: PropTypes.number.isRequired,
  }).isRequired,
  heldBooths: PropTypes.number,
}

export default SummitInfo
