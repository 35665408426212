import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import { filter } from 'lodash'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { getEventSortFilters } from '../../actions/events'
import CenteredPrimaryRadioButtons from '../CenteredPrimaryRadioButtons'
import OnlineAttendeeAllocators from '../OnlineAttendeeAllocators'
import OnlineAttendeeCFNs from '../OnlineAttendeeCFNs/OnlineAttendeeCFNs'
import OnlineAttendeeFunds from '../OnlineAttendeeFunds'
import OnlineAttendeeSPs from '../OnlineAttendeeSPs'
import SummitAttendeeMedia from '../SummitAttendeeMedia'
import SummitAttendeeServiceProviders from '../SummitAttendeeServiceProviders'
import './OnlineAttendeeList.less'

const { TabPane } = Tabs

const BUTTONS = [
  {
    value: 'allocators',
    text: 'allocators',
  },
  {
    value: 'funds',
    text: 'funds',
  },
  {
    value: 'service-providers',
    text: 'service providers',
  },
  {
    value: 'cfn',
    text: 'cfn',
  },
]
const OnlineAttendeeList = () => {
  const {
    params: { type },
  } = useRouteMatch()
  const { push } = useHistory()
  const radioHandler = (e) => {
    push(`/online/${e.target.value}`)
  }

  const buttons = filter(BUTTONS, (button) => true)

  return (
    <>
      <CenteredPrimaryRadioButtons
        selectedKey={type}
        buttons={buttons}
        OnClickFunction={radioHandler}
      />
      <Tabs animated={false} tabBarStyle={{ display: 'none' }} activeKey={type}>
        <TabPane tab="Allocators" key="allocators">
          <OnlineAttendeeAllocators title="Allocators" />
        </TabPane>
        <TabPane tab="Funds" key="funds">
          <OnlineAttendeeFunds title="Funds" />
        </TabPane>
        <TabPane tab="Service Providers" key="service-providers">
          <OnlineAttendeeSPs title="Service Providers" />
        </TabPane>
        <TabPane tab="CFNs" key="cfn">
          <OnlineAttendeeCFNs title="CFNs" />
        </TabPane>
      </Tabs>
    </>
  )
}

OnlineAttendeeList.propTypes = {
  eventId: PropTypes.number.isRequired,
}

export default OnlineAttendeeList
