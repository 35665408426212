import PropTypes from 'prop-types'
import { Button } from '@context365/button'

const ArrayItemHeading = ({ heading, onRemove }) => (
  <div className="flex space-between items-center pb-2">
    <div className="type-subtitle-sm">{heading}</div>
    {onRemove && (
      <Button variant="link" status="error" onClick={() => onRemove()}>
        Remove
      </Button>
    )}
  </div>
)

ArrayItemHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
}

export default ArrayItemHeading
