import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faPencilAlt,
  faSignInAlt,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons'
import { Avatar, Button, Col, Divider, Row, Typography } from 'antd'
import NewContactModal from '../../components/NewContactModal/NewContactModal'
import { ALLOW_CONTACT_CREATION } from '../../config'
import { VerticalActionButton } from '../ActionButton/ActionButton'

const { Title } = Typography

const CompanyPeople = ({ companyId, contacts, refresh, category }) => {
  useEffect(() => {
    if (category == 'allocator') {
      category = 'allocators'
    }
    if (category == 'fund') {
      category = 'fundmanagers'
    }
    if (category == 'serviceprovider') {
      category = 'serviceproviders'
    }
    if (category == 'media') {
      category = 'mediaprofiles'
    }

    console.log(category)
  }, [])

  const handleAddPeople = ALLOW_CONTACT_CREATION
    ? () => setNewContactModalVisible(true)
    : () => alert('Please use Salesforce to create a new user in 365')

  const [newContactModalVisible, setNewContactModalVisible] = useState(false)
  return (
    <>
      <div className="CompanyDetails-Wrapper">
        <Row type="flex" align="top" style={{ background: 'white' }}>
          <Col span={24}>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={12}>
                <Title level={3}>People</Title>
              </Col>
              <Col span={12}>
                <div style={{ textAlign: 'right' }}>
                  <Button
                    icon="plus"
                    className="AddButton"
                    type="primary"
                    onClick={handleAddPeople}
                  >
                    Add People
                  </Button>
                </div>
              </Col>
            </Row>
            <Divider />
            {contactRows(contacts, companyId, refresh)}
          </Col>
        </Row>
        <NewContactModal
          companyId={companyId}
          userType={category}
          visible={newContactModalVisible}
          handleCancel={() => setNewContactModalVisible(false)}
          refresh={refresh}
        />
      </div>
    </>
  )
}

//create new array of contacts grouped in rows of two
const groupContacts = (contacts) => {
  const grouped = []
  let row = []

  for (let i = 0; i < contacts.length; i++) {
    if (i % 2 === 0) {
      row.push(contacts[i])
    } else {
      row.push(contacts[i])
      grouped.push(row)
      row = []
    }
  }

  //if there is one extra contact
  if (row.length > 0) grouped.push(row)

  return grouped
}

const contactRows = (contacts, companyId, refresh) => {
  const contactRows = groupContacts(contacts)

  return contactRows.map((column, key) => (
    <Row key={key} type="flex" justify="space-between" align="middle">
      <Col span={12}>{contactProfile(column[0], companyId, refresh)}</Col>
      {column.length > 1 ? (
        <Col span={12}>{contactProfile(column[1], companyId, refresh)}</Col>
      ) : (
        <Col span={12} />
      )}
    </Row>
  ))
}

const contactMenus = (contact) => [
  {
    text: 'Edit User Profile',
    icon: faPencilAlt,
    link: `/contactdetails/${contact.contactId}`,
  },
  {
    text: `Sign in as ${contact.firstName} ${contact.lastName}`,
    icon: faSignInAlt,
    func: `signisas_${contact.contactId}`,
  },
  {
    text: 'Remove from the Company',
    icon: faTrashAlt,
    func: 5,
  },
]

const contactProfile = (contact, companyId, refresh) => (
  <Row type="flex" justify="space-around" style={{ paddingBottom: '16px' }}>
    <Col span={3} style={{ minWidth: '64px' }}>
      <Avatar
        src={contact.imageURL}
        style={{
          fontSize: '14px',
          textAlign: 'center',
          verticalAlign: 'bottom',
        }}
        shape="circle"
        size={64}
      >
        {contact.contactName}
      </Avatar>
    </Col>
    <Col span={14}>
      <Row type="flex" justify="space-between" align="middle">
        <div className="contactName">{contact.contactName}</div>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <div className="contactDetail">{contact.jobTitle}</div>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <div className="contactDetail">
          <a href={`mailto:${contact.email}`}>{contact.email}</a>
        </div>
      </Row>
    </Col>
    <Col span={3} style={{ width: '40px', paddingLeft: '0px' }}>
      <div>
        <VerticalActionButton
          tag={contact}
          companyId={companyId}
          menus={contactMenus(contact)}
          refresh={refresh}
        />
      </div>
    </Col>
    <Col span={4} />
  </Row>
)

CompanyPeople.propTypes = {
  companyId: PropTypes.number.isRequired,
}

export default CompanyPeople
