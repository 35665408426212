import { useEffect, useState } from 'react'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Divider, Row, Spin, message } from 'antd'
import isNil from 'lodash/isNil'
import { contactRows } from '../../actions/split'
import loaderImage from '../../assets/context_loader.gif'
import http from '../../utils/api'
import LoadingGif from '../LoadingGif'
import DragAndDropContacts from './DragAndDropContacts'
import DragAndDropFunds from './DragAndDropFunds'
import './BoothManagement.less'

const BoothManagement = ({
  match: {
    params: { fundEventId },
  },
  history: { goBack },
}) => {
  const [attendingCompanyEvent, setAttendingCompanyEvent] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [load, setLoad] = useState(true)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [fundData, setFundData] = useState()
  const [fundList, setFundList] = useState(null)

  const getData = (val) => {
    setFundData(val)
  }

  const getContactData = (val) => {
    setData(val)
  }

  const reloadData = () => {
    setAttendingCompanyEvent(null)
    setIsLoading(true)
    setLoading(true)
    http
      .get(`funds/scheduledetails/${fundEventId}`)
      .then((response) => {
        setAttendingCompanyEvent(response.data)
        setLoad(true)
      })
      .finally(function () {
        setLoad(true)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }

  const handleSubmit = () => {
    setLoading(true)
    const eventFundParticipant = []
    data.map((obj) => {
      eventFundParticipant.push({
        fundEventId: obj.title,
        participants: obj.items.fundEvent.eventFundParticipants,
      })
    })

    http
      .post(`booths/event/${fundEventId}`, {
        boothData: fundData,
        participants: eventFundParticipant,
      })
      .then((response) => {
        message.success('Data Saved Successfully!', 3)
        reloadData()
      })
      .catch((ex) => {
        message.error('An error has occurred!', 3)
      })
      .finally(() => {
        //setLoading(false);
      })
  }

  useEffect(() => {
    http
      .get(`funds/scheduledetails/${fundEventId}`)
      .then((response) => {
        setAttendingCompanyEvent(response.data)
        setLoad(true)
      })
      .finally(function () {
        setLoad(true)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }, [fundEventId])

  useEffect(() => {
    if (attendingCompanyEvent) {
      http
        .get(`booths/fundEvents/${attendingCompanyEvent.eventCompanyId}`)
        .then((response) => {
          setFundList(response.data)
        })
    }
  }, [attendingCompanyEvent])

  useEffect(() => {
    if (isLoading && attendingCompanyEvent) {
      http
        .get(`booths/event/${fundEventId}`)
        .then((response) => {
          const b = [...new Set(response.data.map((item) => item.boothId))]
          const c = [...new Set(response.data.map((item) => item.fundEventId))]
          const arr = []
          const cArr = []
          b.map((boothId) => {
            arr.push({
              title: boothId,
              items: response.data.filter(
                (item) => item.boothId === boothId && item.fundEvent !== null
              ),
            })
          })

          c.map((fundEventId) => {
            if (fundEventId != null)
              cArr.push({
                title: fundEventId,
                items: response.data.filter(
                  (item) =>
                    item.fundEventId === fundEventId && item.fundEvent !== null
                )[0],
              })
          })

          setData(cArr)
          setFundData(arr)
        })
        .finally(function () {
          setLoad(false)
          setLoading(false)
          setIsLoading(false)
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    }
  }, [attendingCompanyEvent, fundEventId, isLoading])

  useEffect(() => {
    if (!isNil(attendingCompanyEvent)) {
      setFundData(fundData)
    }
  }, [attendingCompanyEvent, fundData])

  if (isNil(attendingCompanyEvent)) {
    return (
      <Spin
        spinning={loading}
        style={{ width: '100%', height: '100%', paddingTop: '200px' }}
        indicator={
          <img style={{ width: '100px', height: '100px' }} src={loaderImage} />
        }
      ></Spin>
    )
  }

  return (
    <>
      {attendingCompanyEvent && (
        <LoadingGif spinning={loading}>
          <div className="ScheduleDetails">
            <div>
              <Row>
                <Col span={12} style={{ display: 'flex' }}>
                  <Button
                    onClick={() => goBack()}
                    style={{ marginRight: '10px', padding: '0px 10px' }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>

                  <h1
                    className="cc-section-header-title"
                    style={{ lineHeight: '30px' }}
                  >
                    Booth Management
                  </h1>
                </Col>

                <Col span={12} style={{ textAlign: 'right' }}></Col>
              </Row>
            </div>
          </div>
          <Row style={{ marginTop: '20px', backgroundColor: 'white' }}>
            <Col span={20} offset={2} style={{ padding: '20px' }}>
              <h5 className="heading5">
                {' '}
                {attendingCompanyEvent.company.name}
              </h5>
              <span className="requestSender" style={{ marginTop: '20px' }}>
                EVENT PARTICIPANTS
              </span>
              {contactRows(attendingCompanyEvent.contacts, fundEventId)}
              <Divider style={{ marginTop: '20px' }} />

              <h5 className="heading5">Funds</h5>
              <DragAndDropFunds
                data={fundData}
                dataChanged={getData}
                fundList={fundList}
              />

              <Divider style={{ marginTop: '20px' }} />

              <h5 className="heading5">Contacts</h5>
              <DragAndDropContacts
                data={data}
                dataChanged={getContactData}
                participants={attendingCompanyEvent.contacts}
                companyId={attendingCompanyEvent.company.companyId}
              />
              <Row>
                <Col span={6} offset={8}>
                  <Button
                    type="submit"
                    className="ant-btn-primary"
                    style={{ margin: '34px', width: '84%' }}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </LoadingGif>
      )}
    </>
  )
}

export default BoothManagement
