import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Row, Spin, Tabs } from 'antd'
import { isEmpty, isNil } from 'lodash'
import { getAllocatorKpiFunds, getAllocatorKpis } from '../../actions/activity'
import {
  getAllocatorKpiBreakdownColumns,
  getAllocatorKpiEngagementColumns,
} from '../../utils/columns'
import ContextTable from '../ContextTable'
import './UserActivity.less'

const KpiList = ({ companyContactId, type }) => {
  const [kpis, setKpis] = useState([])
  const [refreshTable, setRefreshTable] = useState(false)
  const [selectedKpi, setSelectedKpi] = useState(0)

  const columns =
    type === 'engagement'
      ? getAllocatorKpiEngagementColumns()
      : getAllocatorKpiBreakdownColumns()

  useEffect(() => {
    getAllocatorKpis(type, companyContactId)
      .then((response) => {
        setKpis(response.data)
      })
      .catch((err) => console.log(err))
  }, [companyContactId, type])

  useEffect(() => {
    setRefreshTable(!refreshTable)
  }, [selectedKpi])

  const getClassName = (kpi, i) => {
    var className = 'Kpi-column-card'

    if (selectedKpi === i) {
      className += ' Kpi-selected'
    }

    if (kpi.hasDetails) {
      className += ' Kpi-hasDetails'
    }

    return className
  }

  return (
    <div>
      <Row type="flex" justify="space-between" align="top">
        <Col span={6} className="Kpi-column" style={{ paddingLeft: '0px' }}>
          {kpis.map((x, i) => (
            <Row className="Kpi-row">
              <Col span={2} />
              <Col span={20}>
                <div
                  className={getClassName(x, i)}
                  onClick={() => {
                    if (kpis[i].hasDetails) setSelectedKpi(i)
                  }}
                >
                  <div className="Kpi-value">{x.value}</div>
                  <div>{x.name}</div>
                </div>
              </Col>
              <Col span={2} />
            </Row>
          ))}
        </Col>
        <Col span={18} style={{ paddingRight: '0px' }}>
          {!isEmpty(kpis) && (
            <ContextTable
              tableTitle="Funds"
              columns={columns}
              dataMethod={getAllocatorKpiFunds.bind(
                null,
                isNil(kpis[selectedKpi]) ? '' : kpis[selectedKpi].key,
                companyContactId
              )}
              refreshData={refreshTable}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

KpiList.propTypes = { companyContactId: PropTypes.number.isRequired }

export default KpiList
