import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Avatar,
  Col,
  Divider,
  Icon,
  Modal,
  Row,
  Tabs,
  Typography,
  message,
} from 'antd'
import filter from 'lodash/filter'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import toPairs from 'lodash/toPairs'
import moment from 'moment'
import numeral from 'numeral'
import { getContactSendGridStats } from '../../actions/activity'
import { getContactQuestions } from '../../actions/onboarding'
import http from '../../utils/api'
import CFNVisibilityEditor from '../CompanyDetails/CFNVisibilityEditor'
import EditButton from '../EditButton/EditButton'
import FamilyNetworkDealsForm from '../OnboardingComponents/FamilyNetworkDealsForm'
import GenericForm from '../OnboardingComponents/GenericForm'
import ProfileNestedSectionsTab from '../ProfileNestedSectionsTab'
import ProfileOverviewTab from '../ProfileOverviewTab'
import ServiceProviderContact from '../ServiceProviderPeople/ServiceProviderContact'
import AllocatorDetails from './AllocatorDetails'
import './UserDetails.less'

const { Title } = Typography
const { TabPane } = Tabs

const UserInfo = ({ contactId, selectedUser }) => {
  const noEditState = {
    userDetails: false,
    managerDetails: false,
    companies: false,
    cfnInvestmentDetails: false,
    cfnAboutDetails: false,
    cfnSocialDetails: false,
    cfnVisibilityDetails: false,
    cfnInvestmentStatusDetails: false,
  }

  const [allocatorInfo, setAllocatorInfo] = useState(null)
  const [managerInfo, setManagerInfo] = useState(null)
  const [cfnInfo, setCfnInfo] = useState(null)
  const [buttonVisible, setButtonVisible] = useState(noEditState)
  const [sendGridStats, setSendGridStats] = useState(null)
  const [familyMemberData, setFamilyMemberData] = useState(null)
  const [cfnContactQuestions, setCfnContactQuestions] = useState(null)
  const [cfnContactQuestionsVisible, setCfnContactQuestionsVisible] =
    useState(false)
  const [answers, setAnswers] = useState([])
  const [innerStep, setInnerStep] = useState(2)
  const [visibilityQuestions, setVisibilityQuestions] = useState(null)
  const [cfnVisibilityQuestionsVisible, setCfnVisibilityQuestionsVisible] =
    useState(false)
  const [cfnInvestorStatusVisible, setCfnInvestorStatusVisible] =
    useState(false)
  const [cfnInvestorStatus, setCfnInvestorStatus] = useState(null)
  const [currentInvestorCategories, setCurrentInvestorCategories] =
    useState(null)
  const [cfnCompanies, setCfnCompanies] = useState(null)

  const ABOUT_TAB_KEYS = ['bio', 'education', 'membershipGain']
  const ABOUT_TAB_KEYS_SIDE = ['role', 'familyName']
  const INVESTMENTS_TAB_KEYS_SIDE = [
    'MinimumInvestmentSize',
    'AverageInvestmentSize',
  ]
  const SECTIONS_NAME_AND_TITLE = {
    membershipGain:
      'What do you hope to gain as a Context Family Network Member?',
  }

  const getFamilyMemberData = (familyMember) =>
    map(toPairs(familyMember), (f) => ({
      label: has(SECTIONS_NAME_AND_TITLE, f[0])
        ? SECTIONS_NAME_AND_TITLE[f[0]]
        : startCase(f[0]),
      value: f[1],
      name: f[0],
    }))

  const toggleEdit = (section, value) => {
    if (section === 'cfnAboutDetails') setInnerStep(2)
    if (section === 'cfnInvestmentDetails') setInnerStep(5)
    if (section === 'cfnSocialDetails') setInnerStep(6)
    if (section === 'cfnInvestmentStatusDetails') setInnerStep(3)
    var newState = { ...noEditState }
    newState[section] = value
    setButtonVisible(newState)
  }

  // needed to ensure allocator details are set first if contact is allocator
  // and manager tab is not default selected
  const getManagerDetails = (contactId) => {
    http
      .get('/funds/managers/' + contactId + '/details')
      .then(function (response) {
        setManagerInfo(response.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }

  const getCFNDetails = (companyId, contactId) => {
    http
      .get(`/cfn/company/${companyId}/member/${contactId}`)
      .then(function (response) {
        setCfnInfo(response.data)
        setFamilyMemberData(getFamilyMemberData(response.data))
        getCFNVisibilityQuestions(
          response.data.companyId,
          response.data.companyContactId
        )
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getCFNContactQuestions = () => {
    http
      .get(
        `companies/cfn/questions/contact/${innerStep}/${contactId}/${cfnInfo.companyContactId}`
      )
      .then(function (response) {
        setCfnContactQuestionsVisible(true)
        setCfnContactQuestions(response.data)
      })
  }

  const getCFNVisibilityQuestions = (companyId, companyContactId) => {
    http
      .get(
        `companies/cfn/questions/visibility/contact/${companyId}/${companyContactId}`
      )
      .then(function (response) {
        setVisibilityQuestions(response.data)
      })
  }

  const getCFNInvestmentStatus = (contactId, companyContactId) => {
    http
      .get(`companies/cfn/questions/contact/3/${contactId}/${companyContactId}`)
      .then(function (response) {
        setCfnInvestorStatus(response.data)
        http
          .get(
            `companies/cfn/questions/contact/7/${contactId}/${companyContactId}`
          )
          .then(function (response) {
            if (response.data.length > 0) {
              if (response.data[0].answer === null)
                setCurrentInvestorCategories(response.data[1])
              else setCurrentInvestorCategories(response.data[0])
              setCfnContactQuestions(response.data)
            }
          })
      })
  }

  const onChange = useCallback((answer) => {
    setAnswers(answer)
  }, [])

  const onSubmit = () => {
    http
      .post(
        `companies/cfn/questions/contact/${contactId}/${cfnInfo.companyContactId}/${innerStep}`,
        answers
      )
      .then(function (response) {
        message.success(response.data)
        setCfnContactQuestionsVisible(false)
        setCfnInvestorStatusVisible(false)
        getCFNDetails(cfnInfo.companyId, contactId)
        getCFNInvestmentStatus(contactId, cfnInfo.companyContactId)
      })
  }

  const onSubmitVisibility = () => {
    http
      .post(
        `companies/cfn/visibility/contact/0/${cfnInfo.companyContactId}/${contactId}`,
        answers
      )
      .then(function (response) {
        message.success(response.data)
        setCfnVisibilityQuestionsVisible(false)
        getCFNVisibilityQuestions(cfnInfo.companyId, cfnInfo.companyContactId)
      })
  }

  const loadCFNDetails = (companyContactId) => {
    const selectedCfn = selectedUser.companies.filter(
      (c) => c.companyContactId === parseInt(companyContactId)
    )
    getCFNDetails(selectedCfn[0].companyId, contactId)
    getCFNInvestmentStatus(contactId, selectedCfn[0].companyContactId)
  }

  const checkIsCFN = (row) => {
    const selectedRow = selectedUser.companies.filter(
      (c) => c.companyId === row.companyId
    )
    if (selectedRow.length > 0) {
      return selectedRow[0].category === 'CFN'
    }
  }

  useEffect(() => {
    if (selectedUser) {
      const cfn = selectedUser.companies.filter((c) => c.category === 'CFN')
      if (cfn.length > 0) {
        setCfnCompanies(cfn)
        getCFNDetails(cfn[0].companyId, contactId)
        getCFNInvestmentStatus(contactId, cfn[0].companyContactId)
      }
    }
  }, [selectedUser])

  useEffect(() => {
    http
      .get('/allocator/' + contactId + '/details')
      .then(function (response) {
        setAllocatorInfo(response.data)
        getManagerDetails(contactId)
      })
      .catch(function (error) {
        //handle error
        console.log(error)
        getManagerDetails(contactId)
      })
  }, [])

  useEffect(() => {
    getContactSendGridStats(contactId)
      .then((response) => {
        setSendGridStats(response.data)
      })
      .catch((err) => {
        setSendGridStats(null)
      })
  }, [contactId])

  return (
    <>
      <div className="UserDetails-Wrapper">
        <Row type="flex" justify="space-between" align="top">
          <Col span={6} style={{ paddingLeft: '0px' }}>
            {contactAddress(selectedUser, buttonVisible, toggleEdit)}
            <Divider />
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ background: 'white' }}
            >
              <Col span={24}>
                <Title level={4} className="header">
                  Email Stats (Past 90 days)
                </Title>
                <Divider />
                <Row
                  type="flex"
                  justify="space-between"
                  align="top"
                  className="paddedRow"
                >
                  <Col span={14} style={{ padding: '0px' }}>
                    <Row type="flex" justify="space-between" align="middle">
                      <div className="boldText">Bounces:</div>
                    </Row>
                    <Row type="flex" justify="space-between" align="middle">
                      <div className="boldText">Delivered:</div>
                    </Row>
                    <Row type="flex" justify="space-between" align="middle">
                      <div className="boldText">Open Rate:</div>
                    </Row>
                    <Row type="flex" justify="space-between" align="middle">
                      <div className="boldText">Click Rate:</div>
                    </Row>
                  </Col>
                  <Col span={10}>
                    <Row type="flex" justify="space-between" align="middle">
                      <div>
                        {!isNil(sendGridStats) ? sendGridStats.bounces : 'N/A'}
                      </div>
                    </Row>
                    <Row type="flex" justify="space-between" align="middle">
                      <div>
                        {!isNil(sendGridStats)
                          ? sendGridStats.delivered
                          : 'N/A'}
                      </div>
                    </Row>
                    <Row type="flex" justify="space-between" align="middle">
                      <div>
                        {!isNil(sendGridStats) && !isNil(sendGridStats.openRate)
                          ? `${numeral(sendGridStats.openRate).format(
                              '0.[00]'
                            )}%`
                          : 'N/A'}
                      </div>
                    </Row>
                    <Row type="flex" justify="space-between" align="middle">
                      <div>
                        {!isNil(sendGridStats) &&
                        !isNil(sendGridStats.clickRate)
                          ? `${numeral(sendGridStats.clickRate).format(
                              '0.[00]'
                            )}%`
                          : 'N/A'}
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            {companySection(selectedUser, buttonVisible, toggleEdit)}
            <Divider />
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ background: 'white' }}
            >
              <Col span={24}>
                <Title level={4} className="header">
                  Joined Summits
                </Title>
                <Divider />
                {summitSection(selectedUser)}
              </Col>
            </Row>
          </Col>
          <Col span={18} style={{ paddingRight: '0px' }}>
            <Tabs
              defaultActiveKey="1"
              size="large"
              className="UserDetails-tabs"
            >
              {allocatorInfo && selectedUser && allocatorInfo.length > 0 && (
                <TabPane
                  className="UserDetails-tabs-tab"
                  tab={<span>Allocator Detail</span>}
                  key="1"
                >
                  <Tabs
                    defaultActiveKey={allocatorInfo[0].companyId}
                    size="large"
                    className="UserDetails-tabs"
                  >
                    {allocatorInfo.map((row) => (
                      <TabPane
                        className="UserDetails-tabs-tab"
                        tab={<span>{row.companyName}</span>}
                        key={row.companyId}
                      >
                        <AllocatorDetails
                          contactId={contactId}
                          details={row}
                          hideInvestorStatus={checkIsCFN(row)}
                        />
                      </TabPane>
                    ))}
                  </Tabs>
                </TabPane>
              )}
              {managerInfo && managerInfo.length > 0 && (
                <TabPane
                  className="UserDetails-tabs-tab"
                  tab={<span>Fund Manager Detail</span>}
                  key="2"
                >
                  <Tabs
                    defaultActiveKey={managerInfo[0].companyContactId}
                    size="large"
                    className="UserDetails-tabs"
                  >
                    {managerInfo.map((row) => (
                      <TabPane
                        className="UserDetails-tabs-tab"
                        tab={<span>{row.name}</span>}
                        key={row.companyContactId}
                      >
                        {fundManagerDetails(
                          row,
                          buttonVisible,
                          toggleEdit,
                          contactId
                        )}
                      </TabPane>
                    ))}
                  </Tabs>
                </TabPane>
              )}
              {cfnInfo &&
                cfnCompanies &&
                familyMemberData &&
                visibilityQuestions &&
                cfnInfo.profile && (
                  <TabPane
                    className="UserDetails-tabs-tab"
                    tab={<span>CFN Detail</span>}
                    key="3"
                  >
                    <Tabs
                      defaultActiveKey={cfnCompanies[0].companyContactId}
                      size="large"
                      className="UserDetails-tabs"
                      onChange={(activeKey) => loadCFNDetails(activeKey)}
                    >
                      {cfnCompanies.map((row) => (
                        <TabPane
                          className="UserDetails-tabs-tab"
                          tab={<span>{row.name}</span>}
                          key={row.companyContactId}
                        >
                          <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={{ background: 'white' }}
                            onMouseEnter={(e) =>
                              toggleEdit('cfnInvestmentStatusDetails', true)
                            }
                            onMouseLeave={(e) =>
                              toggleEdit('cfnInvestmentStatusDetails', false)
                            }
                          >
                            <Col span={24}>
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                                className="editRow"
                              >
                                <Col span={12}>
                                  <Title level={4} className="header">
                                    Investment Status
                                  </Title>
                                </Col>
                                <Col span={12}>
                                  <div
                                    style={{ textAlign: 'right' }}
                                    hidden={
                                      !buttonVisible.cfnInvestmentStatusDetails
                                    }
                                  >
                                    <EditButton
                                      onClick={() =>
                                        setCfnInvestorStatusVisible(true)
                                      }
                                    ></EditButton>{' '}
                                  </div>
                                </Col>
                              </Row>
                              <Divider />
                              <Row
                                type="flex"
                                justify="space-between"
                                align="left"
                                className="paddedRow"
                              >
                                <Col span={24}>
                                  <div className="greenHeader">
                                    {currentInvestorCategories &&
                                    cfnInvestorStatus &&
                                    cfnInvestorStatus.length > 0 &&
                                    cfnInvestorStatus[0].answer == false
                                      ? 'Not interested in private deals'
                                      : currentInvestorCategories?.label}
                                  </div>
                                </Col>
                              </Row>
                              <Row
                                type="flex"
                                justify="space-between"
                                align="left"
                                className="paddedRow"
                              >
                                {cfnInvestorStatus &&
                                  cfnInvestorStatus.length > 0 &&
                                  cfnInvestorStatus[0].answer == true &&
                                  currentInvestorCategories &&
                                  currentInvestorCategories.answer &&
                                  currentInvestorCategories.answer[0] != '' &&
                                  currentInvestorCategories.answer.map(
                                    (val) => (
                                      <Col span={12}>
                                        <Row
                                          type="flex"
                                          justify="space-between"
                                          align="top"
                                          style={{ paddingBottom: '8px' }}
                                        >
                                          <div className="boldText">
                                            <Icon
                                              type="check"
                                              className="check"
                                            />
                                            {val}
                                          </div>
                                        </Row>
                                      </Col>
                                    )
                                  )}
                              </Row>
                            </Col>
                          </Row>
                          <Divider />

                          <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={{ background: 'white' }}
                            onMouseEnter={(e) =>
                              toggleEdit('cfnAboutDetails', true)
                            }
                            onMouseLeave={(e) =>
                              toggleEdit('cfnAboutDetails', false)
                            }
                          >
                            <Col span={24}>
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                                className="editRow"
                              >
                                <Col span={12}>
                                  <Title level={4} className="header">
                                    About
                                  </Title>
                                </Col>
                                <Col span={12}>
                                  <div
                                    style={{ textAlign: 'right' }}
                                    hidden={!buttonVisible.cfnAboutDetails}
                                  >
                                    <EditButton
                                      onClick={getCFNContactQuestions}
                                    ></EditButton>{' '}
                                  </div>
                                </Col>
                              </Row>
                              <Divider />
                              <Row
                                type="flex"
                                justify="space-between"
                                align="left"
                                className="paddedRow"
                              >
                                <ProfileOverviewTab
                                  sections={familyMemberData.filter(
                                    (f) =>
                                      ABOUT_TAB_KEYS.indexOf(f.name) > -1 &&
                                      !isNil(f.value) &&
                                      !isEmpty(f.value)
                                  )}
                                  sideSections={familyMemberData.filter(
                                    (f) =>
                                      ABOUT_TAB_KEYS_SIDE.indexOf(f.name) >
                                        -1 &&
                                      !isNil(f.value) &&
                                      !isEmpty(f.value)
                                  )}
                                />
                              </Row>
                            </Col>
                          </Row>
                          <Divider />
                          <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={{ background: 'white' }}
                            onMouseEnter={(e) =>
                              toggleEdit('cfnInvestmentDetails', true)
                            }
                            onMouseLeave={(e) =>
                              toggleEdit('cfnInvestmentDetails', false)
                            }
                          >
                            <Col span={24}>
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                                className="editRow"
                              >
                                <Col span={12}>
                                  <Title level={4} className="header">
                                    Investments
                                  </Title>
                                </Col>
                                <Col span={12}>
                                  <div
                                    style={{ textAlign: 'right' }}
                                    hidden={!buttonVisible.cfnInvestmentDetails}
                                  >
                                    <EditButton
                                      onClick={getCFNContactQuestions}
                                    ></EditButton>{' '}
                                  </div>
                                </Col>
                              </Row>
                              <Divider />
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                                className="paddedRow"
                              >
                                <Col span={24}>
                                  <ProfileNestedSectionsTab
                                    sections={filter(
                                      cfnInfo.profile[0]?.sections,
                                      (s) =>
                                        INVESTMENTS_TAB_KEYS_SIDE.indexOf(
                                          s.key
                                        ) === -1
                                    )}
                                    sideSections={filter(
                                      cfnInfo.profile[0]?.sections,
                                      (s) =>
                                        INVESTMENTS_TAB_KEYS_SIDE.indexOf(
                                          s.key
                                        ) > -1
                                    )}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Divider />
                          <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={{ background: 'white' }}
                            onMouseEnter={(e) =>
                              toggleEdit('cfnSocialDetails', true)
                            }
                            onMouseLeave={(e) =>
                              toggleEdit('cfnSocialDetails', false)
                            }
                          >
                            <Col span={24}>
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                                className="editRow"
                              >
                                <Col span={12}>
                                  <Title level={4} className="header">
                                    Social
                                  </Title>
                                </Col>
                                <Col span={12}>
                                  <div
                                    style={{ textAlign: 'right' }}
                                    hidden={!buttonVisible.cfnSocialDetails}
                                  >
                                    <EditButton
                                      onClick={getCFNContactQuestions}
                                    ></EditButton>{' '}
                                  </div>
                                </Col>
                              </Row>
                              <Divider />
                              <Row
                                type="flex"
                                justify="space-between"
                                align="left"
                                className="paddedRow"
                              >
                                <Col span={6}>
                                  <Row
                                    type="flex"
                                    justify="space-between"
                                    align="top"
                                    style={{ paddingBottom: '8px' }}
                                  >
                                    <div className="blue">Twitter</div>
                                  </Row>
                                  <Row
                                    type="flex"
                                    justify="space-between"
                                    align="top"
                                  >
                                    <div className="boldText">
                                      {cfnInfo.twitter}
                                    </div>
                                  </Row>
                                </Col>
                                <Col span={6}>
                                  <Row
                                    type="flex"
                                    justify="space-between"
                                    align="top"
                                    style={{ paddingBottom: '8px' }}
                                  >
                                    <div className="blue">Linkedin</div>
                                  </Row>
                                  <Row
                                    type="flex"
                                    justify="space-between"
                                    align="top"
                                  >
                                    <div className="boldText">
                                      {cfnInfo.linkedIn}
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Divider />
                          <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={{ background: 'white' }}
                            onMouseEnter={(e) =>
                              toggleEdit('cfnVisibilityDetails', true)
                            }
                            onMouseLeave={(e) =>
                              toggleEdit('cfnVisibilityDetails', false)
                            }
                          >
                            <Col span={24}>
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                                className="editRow"
                              >
                                <Col span={12}>
                                  <Title level={4} className="header">
                                    Visibility
                                  </Title>
                                </Col>
                                <Col span={12}>
                                  <div
                                    style={{ textAlign: 'right' }}
                                    hidden={!buttonVisible.cfnVisibilityDetails}
                                  >
                                    <EditButton
                                      onClick={() =>
                                        setCfnVisibilityQuestionsVisible(true)
                                      }
                                    ></EditButton>{' '}
                                  </div>
                                </Col>
                              </Row>
                              <Divider />
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                                className="paddedRow"
                              >
                                <Col span={24}>
                                  {visibilityQuestions.map((row) => (
                                    <>
                                      <Row
                                        type="flex"
                                        justify="space-between"
                                        align="middle"
                                        className="paddedRow"
                                      >
                                        <Col span={12}>
                                          <div>{row.label}: </div>
                                        </Col>
                                        <Col span={12}>
                                          <div className="boldText">
                                            {row.value === true ? 'Yes' : 'No'}
                                          </div>
                                        </Col>
                                      </Row>
                                    </>
                                  ))}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Divider />
                        </TabPane>
                      ))}
                    </Tabs>
                  </TabPane>
                )}
            </Tabs>
          </Col>
        </Row>
        <Modal
          className="cc-modal-accept"
          title={`Edit CFN Contact Details`}
          visible={cfnContactQuestionsVisible}
          okText={`Save Changes`}
          cancelText="Close"
          onOk={onSubmit}
          onCancel={() => setCfnContactQuestionsVisible(false)}
          okButtonProps={{
            disabled: false,
            style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
          }}
          centered={true}
          cancelButtonProps={{ disabled: false }}
        >
          {cfnInfo && cfnContactQuestions && (
            <GenericForm
              stepNumber={0}
              onChange={onChange}
              currentQuestions={cfnContactQuestions}
              step={{
                innerStep: innerStep,
                title: 'Investments',
                renderAction: cfnContactQuestions,
                customRender: true,
                customRenderParameter: innerStep,
              }}
              fromSettings={true}
            />
          )}
        </Modal>
        <Modal
          className="cc-modal-accept"
          title={`Edit CFN Contact Details`}
          visible={cfnInvestorStatusVisible}
          okText={`Save Changes`}
          cancelText="Close"
          onOk={onSubmit}
          onCancel={() => setCfnInvestorStatusVisible(false)}
          okButtonProps={{
            disabled: false,
            style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
          }}
          centered={true}
          cancelButtonProps={{ disabled: false }}
        >
          {cfnInfo && cfnInvestorStatus && (
            <FamilyNetworkDealsForm
              fromCfnSettings={true}
              isCFNIterestedInDeals={cfnInvestorStatus[0].answer}
              enableNext={false}
              currentQuestions={cfnInvestorStatus}
              companyId={cfnInfo.companyId}
              contactId={contactId}
              step={{
                innerStep: 3,
                title: 'Investor Status',
                renderAction: () =>
                  http.get(
                    `companies/cfn/questions/contact/7/${contactId}/${cfnInfo.companyContactId}`
                  ),
                customRender: true,
                customRenderParameter: 3,
              }}
              onChange={onChange}
            />
          )}
        </Modal>
        <Modal
          destroyOnClose
          className="cc-modal-accept"
          title={`Edit CFN Contact Details`}
          visible={cfnVisibilityQuestionsVisible}
          okText={`Save Changes`}
          cancelText="Close"
          onOk={onSubmitVisibility}
          onCancel={() => setCfnVisibilityQuestionsVisible(false)}
          okButtonProps={{
            disabled: false,
            style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
          }}
          centered={true}
          cancelButtonProps={{ disabled: false }}
        >
          {cfnInfo && visibilityQuestions && (
            <CFNVisibilityEditor
              values={visibilityQuestions}
              onChange={onChange}
            />
          )}
        </Modal>
      </div>
    </>
  )
}

const formatDate = (dateString) => {
  return moment(dateString).format('MMMM D, YYYY')
}

const contactAddress = (contactInfo, buttonVisible, toggleEdit) => {
  var contact = {}
  var contactId = {}

  if (contactInfo) {
    contact = contactInfo
    contactId = contact.contactId
  }

  return (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      style={{ background: 'white' }}
      onMouseEnter={(e) => toggleEdit('userDetails', true)}
      onMouseLeave={(e) => toggleEdit('userDetails', false)}
    >
      <Col span={24}>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="editRow"
        >
          <Col span={12}>
            <Title level={4} className="header">
              User Details
            </Title>
          </Col>
          <Col span={12}>
            <div
              style={{ textAlign: 'right' }}
              hidden={!buttonVisible.userDetails}
            >
              <EditButton onClick={`/contactdetails/${contactId}`}></EditButton>{' '}
            </div>
          </Col>
        </Row>
        <Divider />
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="paddedRow"
        >
          <Col span={6}>
            <Avatar
              src={contact.imageUrl}
              style={{
                fontSize: '14px',
                textAlign: 'center',
                verticalAlign: 'bottom',
              }}
              shape="circle"
              size={80}
            >
              {contact.firstName}
            </Avatar>
          </Col>
          <Col span={2} />
          <Col span={16}>
            <Row type="flex" justify="space-between" align="middle">
              <div className="boldText">
                {isNil(contact.firstName)
                  ? ''
                  : contact.firstName + ' ' + contact.lastName}
              </div>
            </Row>
            <Row type="flex" justify="space-between" align="middle">
              <div className="lighterText">
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
              </div>
            </Row>
            <Row type="flex" justify="space-between" align="middle">
              <div className="lighterText">
                Registered on {formatDate(contact.registeredDate)}
              </div>
            </Row>
            <Row type="flex" justify="space-between" align="middle">
              <div className="lighterText">
                Last login:{' '}
                {isNil(contact.lastLoginDate)
                  ? 'Never'
                  : formatDate(contact.lastLoginDate)}
              </div>
            </Row>
          </Col>
        </Row>
        <Row type="flex" justify="space-between" align="middle">
          <h4 className="blueHeader">Contact &amp; Address</h4>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="bottom"
          className="paddedRow"
        >
          <Col span={12} style={{ padding: '0px' }}>
            <div>SF Contact ID: </div>
          </Col>
          <Col span={12}>
            <div className="boldText">
              {isEmpty(contact.sfContactId) ? (
                'N/A'
              ) : (
                <a
                  href={`https://contextcapitalpartners.lightning.force.com/lightning/r/Contact/${contact.sfContactId}/view`}
                  target="_blank"
                >
                  {contact.sfContactId}
                </a>
              )}
            </div>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="bottom"
          className="paddedRow"
        >
          <Col span={12} style={{ padding: '0px' }}>
            <div>Work Phone: </div>
          </Col>
          <Col span={12}>
            <div className="boldText">
              {isNil(contact.workPhone) ? 'N/A' : contact.workPhone}
            </div>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="bottom"
          className="paddedRow"
        >
          <Col span={12} style={{ padding: '0px' }}>
            <div>Work Extension: </div>
          </Col>
          <Col span={12}>
            <div className="boldText">
              {isNil(contact.workExtension) ? 'N/A' : contact.workExtension}
            </div>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="bottom"
          className="paddedRow"
        >
          <Col span={12} style={{ padding: '0px' }}>
            <div>Mobile Phone: </div>
          </Col>
          <Col span={12}>
            <div className="boldText">
              {isNil(contact.mobilePhone) ? 'N/A' : contact.mobilePhone}
            </div>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="bottom"
          className="paddedRow"
        >
          <Col span={12} style={{ padding: '0px' }}>
            <div>Continent: </div>
          </Col>
          <Col span={12}>
            <div className="boldText">
              {isNil(contact.continent) ? 'N/A' : contact.continent}
            </div>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="bottom"
          className="paddedRow"
        >
          <Col span={12} style={{ padding: '0px' }}>
            <div>Country: </div>
          </Col>
          <Col span={12}>
            <div className="boldText">
              {isNil(contact.country) ? 'N/A' : contact.country}
            </div>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="bottom"
          className="paddedRow"
        >
          <Col span={12} style={{ padding: '0px' }}>
            <div>State: </div>
          </Col>
          <Col span={12}>
            <div className="boldText">
              {isNil(contact.state) ? 'N/A' : contact.state}
            </div>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="bottom"
          className="paddedRow"
        >
          <Col span={12} style={{ padding: '0px' }}>
            <div>City: </div>
          </Col>
          <Col span={12}>
            <div className="boldText">
              {isNil(contact.city) ? 'N/A' : contact.city}
            </div>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="bottom"
          className="paddedRow"
        >
          <Col span={12} style={{ padding: '0px' }}>
            <div>Postal Code: </div>
          </Col>
          <Col span={12}>
            <div className="boldText">
              {isNil(contact.postalCode) ? 'N/A' : contact.postalCode}
            </div>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="bottom"
          className="paddedRow"
        >
          <Col span={12} style={{ padding: '0px' }}>
            <div>Address 1: </div>
          </Col>
          <Col span={12}>
            <div className="boldText">
              {isNil(contact.address1) ? 'N/A' : contact.address1}
            </div>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="bottom"
          className="paddedRow"
        >
          <Col span={12} style={{ padding: '0px' }}>
            <div>Address 2: </div>
          </Col>
          <Col span={12}>
            <div className="boldText">
              {isNil(contact.address2) ? 'N/A' : contact.address2}
            </div>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="bottom"
          className="paddedRow"
        >
          <Col span={12} style={{ padding: '0px' }}>
            <div>Address 3: </div>
          </Col>
          <Col span={12}>
            <div className="boldText">
              {isNil(contact.address3) ? 'N/A' : contact.address3}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const fundManagerDetails = (
  managerInfo,
  buttonVisible,
  toggleEdit,
  contactId
) => {
  return (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      style={{ background: 'white' }}
      onMouseEnter={(e) => toggleEdit('managerDetails', true)}
      onMouseLeave={(e) => toggleEdit('managerDetails', false)}
    >
      <Col span={24}>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="editRow"
        >
          <Col span={12}>
            <Title level={4} className="header">
              Manager Details
            </Title>
          </Col>
          <Col span={12}>
            <div
              style={{ textAlign: 'right' }}
              hidden={!buttonVisible.managerDetails}
            >
              <EditButton
                onClick={
                  '/editManagerDetails/' +
                  managerInfo.companyId +
                  '/' +
                  contactId
                }
              />
            </div>
          </Col>
        </Row>
        <Divider />
        <Row type="flex" justify="space-between" align="top">
          <Col span={4}>
            <div>Firm AUM: </div>
          </Col>
          <Col span={8}>
            <div>{isNil(managerInfo.firmAum) ? '-' : managerInfo.firmAum}</div>
          </Col>
          <Col span={4}>
            <div>CIK: </div>
          </Col>
          <Col span={8}>
            <div>{isNil(managerInfo.cik) ? '-' : managerInfo.cik}</div>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="top"
          className="paddedRow"
        >
          <Col span={4}>
            <div>CRD: </div>
          </Col>
          <Col span={8}>
            <div>{isNil(managerInfo.crd) ? '-' : managerInfo.crd}</div>
          </Col>
          <Col span={4}>
            <div>Fund Attributes: </div>
          </Col>
          <Col span={8}>
            <div>
              {isNil(managerInfo.fundAttributes)
                ? '-'
                : managerInfo.fundAttributes}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const companySection = (contactInfo, buttonVisible, toggleEdit) => {
  var companies = []
  var contactId = {}

  if (contactInfo) {
    companies = contactInfo.companies
    contactId = contactInfo.contactId
  }

  return (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      style={{ background: 'white' }}
      onMouseEnter={(e) => toggleEdit('companies', true)}
      onMouseLeave={(e) => toggleEdit('companies', false)}
    >
      <Col span={24}>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="editRow"
        >
          <Col span={12}>
            <Title level={4} className="header">
              Companies
            </Title>
          </Col>
          <Col span={12}>
            <div
              style={{ textAlign: 'right' }}
              hidden={!buttonVisible.companies}
            >
              <EditButton
                onClick={`/contactcompanies/${contactId}`}
              ></EditButton>
            </div>
          </Col>
        </Row>
        <Divider />
        {companies.map((company, index) => (
          <Row
            type="flex"
            justify="space-between"
            align="top"
            className="paddedRow"
          >
            <Col span={14}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={24}>
                  <div className="boldText">{company.name}</div>
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={24}>
                  <div className="lighterText">{company.jobFunction}</div>
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={24}>
                  <div className="roleLabel">{company.category}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  )
}

const summitSection = (contactInfo) => {
  var upcoming = []
  var past = []

  if (contactInfo) {
    upcoming = contactInfo.upcomingEvents
    past = contactInfo.pastEvents
  }

  return (
    <>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={24}>
          <div className="blueHeader">UPCOMING SUMMITS</div>
        </Col>
      </Row>
      <Divider />
      {upcoming.map((event, index) => {
        return (
          <>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="paddedRow"
            >
              <Col span={24}>
                <div style={{ fontSize: '16px', fontWeight: 600 }}>
                  {event.eventName}
                </div>
                <div className="lighterText">
                  {event.packageName}{' '}
                  <span style={{ fontStyle: 'italic' }}>
                    ({event.companyName})
                  </span>
                </div>
              </Col>
            </Row>
            {index !== upcoming.length - 1 && <Divider />}
          </>
        )
      })}
      <Divider style={{ backgroundColor: 'transparent' }} />
      <Row type="flex" justify="space-between" align="middle">
        <Col span={24}>
          <div style={{ fontSize: '12px' }} className="lighterText">
            PAST SUMMITS
          </div>
        </Col>
      </Row>
      <Divider />
      {past.map((event, index) => {
        return (
          <>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="paddedRow"
            >
              <Col span={24}>
                <div style={{ fontSize: '16px', fontWeight: 600 }}>
                  {event.eventName}
                </div>
                <div className="lighterText">
                  {event.packageName}{' '}
                  <span style={{ fontStyle: 'italic' }}>
                    ({event.companyName})
                  </span>
                </div>
              </Col>
            </Row>
            {index !== past.length - 1 && <Divider />}
          </>
        )
      })}
    </>
  )
}

UserInfo.propTypes = {
  contactId: PropTypes.number.isRequired,
}

export default UserInfo
