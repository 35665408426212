import PropTypes from 'prop-types'
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Empty } from 'antd'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { downloadDocument } from '../../actions/files'

const FileComponent = ({ files, title, dealId }) => (
  <div>
    {files &&
      files.length > 0 &&
      map(files, (x) => (
        <div className="FundAttachments">
          <div className="FundAttachments-image">
            <FontAwesomeIcon icon={faFilePdf} />
          </div>
          <div className="FundAttachments-body">
            {isNil(x.mediaUrl) || x.mediaUrl === ''
              ? 'UNTITLED'
              : decodeURI(x?.mediaUrl.split('/').pop())}
          </div>
          <div className="FundAttachments-download-body">
            <Button
              type="link"
              onClick={() => {
                downloadDocument(x.mediaUrl).then((response) => {
                  window.open(response.data, '_blank')
                })
              }}
              // onClick={() => {
              //   window.open(
              //     `/attachment/${encodeURIComponent(
              //       title
              //     )}/${dealId}/true?file=${encodeURIComponent(x.mediaUrl)}`,
              //     '_blank'
              //   );
              // }}
            >
              View
            </Button>
          </div>
        </div>
      ))}
    {files.length === 0 && (
      <div className="FundAttachments-no-documents">
        <Empty description="No documents attached." />
      </div>
    )}
  </div>
)

FileComponent.propTypes = {
  files: PropTypes.object.isRequired,
}

export default FileComponent
