import { useEffect, useState } from 'react'
import { Alert, Button, Modal, Radio, Row, Select, message } from 'antd'
import isNil from 'lodash/isNil'
import http from '../../utils/api'
import './BoothManagement2.less'

const { Option } = Select

const DeleteBoothFund = ({
  visible,
  title,
  handleCancel,
  closeModal,
  fundBoothId,
  availableBooths,
  setLoading,
  meetingsNo,
}) => {
  const [enableDelete, setEnableDelete] = useState(false)
  const [enableMoveDelete, setEnableMoveDelete] = useState(true)
  const [value, setValue] = useState(2)
  const [hasAvailableBooths, setHasAvailableBooths] = useState(true)
  const [moveToBoothId, setMoveToBoothId] = useState(null)

  const handleFundDelete = (buttonType) => {
    if (buttonType === 1) {
      http
        .post('/booths/fund/' + fundBoothId + '/cancel?api-version=2')
        .then(function (response) {
          message.success(
            'Fund and associated meetings are canceled successfully !',
            3
          )
          setLoading()
        })
        .catch(function (error) {
          message.error('An error has occurred!', 3)
        })
        .finally(() => {
          closeModal()
        })
    }
    if (buttonType === 2) {
      http
        .post(
          '/booths/fund/' + fundBoothId + '/booth' + '/move?api-version=2',
          { moveToBoothId }
        )
        .then(function (response) {
          message.success('Fund and the meetings are moved successfully!', 3)
          setLoading()
        })
        .catch(function (error) {
          message.error('An error has occurred!', 3)
        })
        .finally(() => {
          closeModal()
        })
    }
  }

  const onChange = (e) => {
    setValue(e.target.value)

    if (e.target.value === 1) {
      setEnableDelete(true)
      setEnableMoveDelete(false)
    } else {
      if (e.target.value === 2) {
        setEnableMoveDelete(true)
        setEnableDelete(false)
      }
    }
  }

  const handleMenuClick = (e) => {
    setMoveToBoothId(e)
  }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  useEffect(() => {
    if (availableBooths.length === 0) {
      setHasAvailableBooths(false)
    }
  }, [availableBooths])

  return (
    <Modal
      visible={visible}
      width="628px"
      title={'Change meeting schedule for ' + title}
      bodyStyle={{ padding: 0 }}
      onCancel={() => {
        handleCancel()
      }}
      footer={[
        <Button
          onClick={() => {
            handleCancel()
          }}
          className="cancelBtn"
        >
          Cancel
        </Button>,
        <span></span>,

        enableDelete && (
          <Button
            type="primary"
            onClick={() => handleFundDelete(1)}
            disabled={!enableDelete}
            style={{ cursor: 'pointer', backgroundColor: '#D62B34' }}
          >
            Delete
          </Button>
        ),
        enableMoveDelete && (
          <Button
            visible={false}
            type="primary"
            onClick={() => handleFundDelete(2)}
            disabled={!enableMoveDelete}
            style={{ cursor: 'pointer', backgroundColor: '#F19F00' }}
          >
            {meetingsNo > 0 ? 'Move Meetings' : 'Remove Fund'}
          </Button>
        ),
      ]}
    >
      {meetingsNo > 0 ? (
        <div style={{ padding: '13px 16px 32px 32px' }}>
          <div style={{ display: 'flex' }}>
            <div style={{}}>
              <div style={{ marginTop: '16px' }} className="cc-heading">
                <span style={{ fontWeight: 'bold' }}>{title}</span> has
                confirmed and pending meetings. What do you want to do with
                these meetings?
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '16px',
                }}
              >
                <Radio.Group onChange={onChange} value={value}>
                  <Row type="flex" justify="space-between" align="middle">
                    {' '}
                    <Radio style={radioStyle} value={1}>
                      Cancel all the meetings for this fund at this booth
                    </Radio>
                    <Alert
                      message="Not recommended"
                      type="warning"
                      showIcon
                      closable={false}
                    />
                  </Row>

                  <Radio style={radioStyle} value={2}>
                    <>Move confirmed and pending meetings to another booth</>
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
          {enableMoveDelete && hasAvailableBooths && (
            <>
              <div style={{ margin: '15px', marginLeft: '40px' }}>
                <Select
                  defaultValue="Available Booths"
                  style={{
                    width: 250,
                  }}
                  onChange={handleMenuClick}
                >
                  {!isNil(availableBooths) &&
                    availableBooths.map((item, index) => [
                      <Option value={item.EventCompanyBoothId}>
                        {item.BoothId}
                      </Option>,
                    ])}
                </Select>
              </div>
              <div>
                {' '}
                <Alert
                  message="Warning"
                  description={
                    'When not decided, meetings stay at this booth, but move to another fund.' +
                    ' All free slots for the ' +
                    title +
                    ' fund on the destination booth will get filled. '
                  }
                  type="warning"
                  showIcon
                  closable
                />
              </div>
            </>
          )}
          {enableMoveDelete && !hasAvailableBooths && (
            <>
              <div style={{ margin: '15px', marginLeft: '40px' }}>
                <span>No Available Booths to choose from </span>
              </div>
            </>
          )}
        </div>
      ) : (
        <div style={{ padding: '13px 16px 32px 32px' }}>
          <div style={{ display: 'flex' }}>
            <div style={{}}>
              <div style={{ marginTop: '16px' }} className="cc-heading">
                Are you sure you want to remove this fund ?
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default DeleteBoothFund
