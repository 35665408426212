import PropTypes from 'prop-types'
import { faDownload, faFilePdf } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isNil from 'lodash/isNil'
import { downloadDocument } from '../../actions/files'

const InvestmentPolicy = ({ label, value, className }) => {
  const investmentPolicy = JSON.parse(value)

  // Don't return investmentPolicy section if Content is null or empty
  if (isNil(investmentPolicy.Content) || investmentPolicy.Content.trim() === '')
    return null

  return investmentPolicy?.Type.toLowerCase().trim() === 'file' ? (
    <div>
      {!isNil(value) && (
        <div className="cc-grey-text cc-tabletitle-text">{label}</div>
      )}
      <div className="FundAttachments" style={{ width: '100%' }}>
        <div className="FundAttachments-image">
          <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>
        </div>
        <div className="FundAttachments-body">
          {isNil(investmentPolicy?.Content) || investmentPolicy?.Content === ''
            ? 'UNTITLED'
            : decodeURI(investmentPolicy?.Content?.split('/').pop())}
        </div>
        <div className="FundAttachments-download-body">
          <a
            onClick={() => {
              downloadDocument(investmentPolicy?.Content).then((response) => {
                window.open(response.data.message, '_blank')
              })
            }}
          >
            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
          </a>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="cc-grey-text cc-body-text">{label}</div>
      <div className={className}>{investmentPolicy?.Content}</div>
    </div>
  )
}
InvestmentPolicy.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
}
export default InvestmentPolicy
