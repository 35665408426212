import PropTypes from 'prop-types'
import { Spin } from 'antd'
import loaderImage from '../../assets/context_loading120.gif'

const LoadingGif = ({ spinning, children, style, imgStyle }) => (
  <Spin
    style={style}
    spinning={spinning}
    size="large"
    indicator={<img style={imgStyle} src={loaderImage} alt="Loading..." />}
  >
    {children}
  </Spin>
)

LoadingGif.propTypes = {
  style: PropTypes.object,
  spinning: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default LoadingGif
