import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { getEventSchedule, getNOEventSchedule } from '../../actions/events'
import { MEETING_STATUS } from '../../constants/types/meetingStatusIds'
import ProfilePictureGroup from '../ProfilePictureGroup'

/**
 * Moves an item from one list to another list.
 */
const move = (meetings, source, destination, meetingSelected) => {
  const currentArrayIndex = source.split('-')[1]
  const destinationArrayIndex = destination.split('-')[1]
  const target = meetings[currentArrayIndex].rows.find(
    (x) => x.meetingId == meetingSelected
  )
  const targetIndex = meetings[currentArrayIndex].rows.findIndex(
    (x) => x.meetingId == meetingSelected
  )

  meetings[currentArrayIndex].rows.splice(targetIndex, 1)
  meetings[destinationArrayIndex].rows.push(target)
  return meetings
}

const grid = 4

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#FFFFFF',

  // styles we need to apply on draggables
  ...draggableStyle,
  borderRadius: '5px',
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#EFF1F4',
  padding: grid,
  minWidth: 300,
  margin: '15px',
  marginLeft: '0px',
  borderRadius: '5px',
})

const MeetingDragAndDrop = ({
  eventId,
  companyEventId,
  contacts,
  sendSelected,
  eventDate,
  dayIndex,
  isEventOptimized,
  pendingMeetings = false,
}) => {
  // const [items, setItems] = useState([]);
  // const [selected, setSelected] = useState([]);
  // const [scheduleDays, setScheduleDays] = useState(null);
  const [allContactsLength, setAllContactsLength] = useState(contacts.length)
  const [allContacts, setAllContacts] = useState(contacts)
  const [allMeetings, setAllMeetings] = useState(null)

  useEffect(() => {
    if (allContactsLength < contacts.length) {
      setAllContactsLength(contacts.length)
      setAllContacts(contacts)
      setAllMeetings([...allMeetings, { rows: [] }])
      sendSelected([...allMeetings, { rows: [] }], dayIndex)
    }
  }, [contacts])

  useEffect(() => {
    if (allMeetings !== null) {
      sendSelected(allMeetings, dayIndex)
    }
  }, [allMeetings])

  useEffect(() => {
    if (allMeetings === null && isEventOptimized) {
      getEventSchedule(
        eventId,
        companyEventId,
        eventDate,
        moment.tz.guess()
      ).then((response) => {
        const toUpdate = []
        contacts.forEach((contact) => {
          let meetingRows = []
          contact.rows.forEach((row) => {
            if (response.data != null && response.data.length > 0) {
              const checker = response.data[0].items.filter(
                (m) =>
                  m.meeting != null &&
                  m.meeting.members.some((c) => c.contactId == row.contactId)
              )
              if (checker) {
                checker.forEach((chk) => {
                  meetingRows.push(chk.meeting)
                })
              }
            }
          })
          meetingRows = Array.from(new Set(meetingRows))
          toUpdate.push({ rows: meetingRows })
        })
        setAllMeetings(toUpdate)
      })
    }
    if (allMeetings === null && !isEventOptimized) {
      getNOEventSchedule(eventId, companyEventId).then((response) => {
        const toUpdate = []
        contacts.forEach((contact) => {
          let meetingRows = []
          contact.rows.forEach((row) => {
            const checker = response.data[0].items.filter(
              (m) =>
                m.meeting != null &&
                m.meeting.members.some((c) => c.contactId == row.contactId) &&
                ((pendingMeetings &&
                  m.meeting.meetingStatusId == MEETING_STATUS.PENDING) ||
                  (!pendingMeetings &&
                    m.meeting.meetingStatusId == MEETING_STATUS.CONFIRMED))
            )
            if (checker) {
              checker.forEach((chk) => {
                meetingRows.push(chk.meeting)
              })
            }
          })
          meetingRows = Array.from(new Set(meetingRows))
          toUpdate.push({ rows: meetingRows })
        })
        setAllMeetings(toUpdate)
      })
    }
  }, [allMeetings])

  const onDragEnd = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId !== destination.droppableId) {
      sendSelected(
        move(
          allMeetings,
          source.droppableId,
          destination.droppableId,
          result.draggableId
        ),
        dayIndex
      )
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {allMeetings &&
        allMeetings.length > 0 &&
        allMeetings.map((value, key) => (
          <Droppable droppableId={`meeting-${key}`}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <h4
                  style={{ textAlign: 'left', borderBottom: '1px solid white' }}
                >{`Schedule ${key + 1}`}</h4>

                {value.rows.map((item, index) => (
                  <Draggable
                    key={`${item.meetingId}`}
                    draggableId={`${item.meetingId}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div className="schedule-day-meeting">
                          <div className="schedule-day-meeting-image">
                            <ProfilePictureGroup
                              diameter={32}
                              profiles={item.members.map((x) => ({
                                name: x.contactName,
                                firstName: x.firstName,
                                lastName: x.lastName,
                                image: x.imageURL,
                              }))}
                              maxImages="5"
                            ></ProfilePictureGroup>
                          </div>
                          <div className="schedule-day-meeting-card">
                            <span className="schedule-day-meeting-name">
                              {item.company.companyName}
                            </span>
                            <span className="cc-small-font schedule-day-meeting-company">
                              {item.company.companyName}
                            </span>
                            {item.meetingDateTime !== null && (
                              <span className="cc-small-font schedule-day-meeting-company">
                                {moment(item.meetingDateTime).isValid()
                                  ? moment
                                      .utc(item.meetingDateTime)
                                      .local()
                                      .format('YYYY-MM-DD hh:mm a')
                                  : ''}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
    </DragDropContext>
  )
}

export default MeetingDragAndDrop
