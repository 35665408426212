const ProfileDisclaimerTab = () => {
  return (
    <>
      <div className="fund-details-disclaimer" style={{ marginLeft: '26px' }}>
        IMPORTANT DISCLAIMER
      </div>
      <div style={{ paddingBottom: '16px', marginLeft: '26px' }}>
        The provision of any information regarding any fund, fund manager,
        investment advisor, entity that holds interests in real estate or other
        classes of assets, or any other investment vehicle (collectively, the
        &quot;Entity Information &quot;) to you is for informational purposes
        only and does not constitute any offer to sell or the solicitation of an
        offer to buy any securities or investments. Context Summits LLC (
        &quot;Context &quot;) makes no representation about, and does not
        independently verify, the Entity Information and shall not be
        responsible for its accuracy, completeness or your reliance thereon. The
        Entity Information is self-reported by the associated fund, fund
        manager, investment advisor, general partner, member, or sponsor to
        Context. Context is not making any recommendation, express or implied,
        as to the suitability or otherwise of any investment with, and is not
        acting as agent, promoter, underwriter or placement agent for, any fund,
        fund manager, investment advisor, entity that holds interests in real
        estate or other classes of assets, or any other investment vehicle, or
        their respective associated general partners, members or sponsors, or
        any other client represented by a fund manager or investment advisor.
        You must assess the suitability of any particular asset, fund or
        strategy and carry out appropriate due diligence on your own behalf in
        relation to the same and Context provides no advice in that regard.
        Context makes no representation as to the fund’s, fund manager’s,
        investment advisor’s, general partner’s, member’s, or sponsor’s
        compliance with any applicable laws or regulations. Context shall not be
        liable for any losses that may arise, directly or indirectly, from your
        introduction to, or investment with, any fund, fund manager, investment
        advisor, entity that holds interests in real estate or other classes of
        assets, or any other investment vehicle, or their respective general
        partners, members or sponsors.
      </div>
    </>
  )
}

export default ProfileDisclaimerTab
