import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row } from 'antd'
import { getEventCompaniesAndBooths } from '../../actions/booths'
import BoothCompaniesList from '../BoothCompaniesList/BoothCompaniesList'
import SeatsContainer from '../SeatsContainer/SeatsContainer'
import './BoothSelector.less'

const BoothSelectorContainer = ({ eventId, getEvent }) => {
  const [loading, setLoading] = useState(true)
  const [tableParameters, setTableParameters] = useState({
    pagination: {},
    filters: {},
    sorter: {},
    searchTerm: {},
  })
  const [companiesAndBooths, setCompaniesAndBooths] = useState(null)
  const [showCompanies, setShowCompanies] = useState(true)

  const getCompaniesForList = useCallback(() => {
    setLoading(true)
    getEventCompaniesAndBooths(
      eventId,
      tableParameters.pagination,
      tableParameters.filters,
      tableParameters.sorter,
      tableParameters.searchTerm
    )
      .then((response) => {
        setCompaniesAndBooths(response.data.result ?? [])
      })
      .finally(() => {
        setLoading(false)
      })
  }, [
    eventId,
    tableParameters.filters,
    tableParameters.pagination,
    tableParameters.searchTerm,
    tableParameters.sorter,
  ])

  useEffect(() => {
    getCompaniesForList()
  }, [getCompaniesForList])

  return (
    <>
      <Row>
        <Col span={6}>
          {showCompanies ? (
            <Button
              onClick={() => {
                setShowCompanies(false)
              }}
              className={'boothSelector-button'}
            >
              Hide Companies List
              <span className={'boothSelector-expandIcon'}>
                <FontAwesomeIcon icon={faAngleUp} />
              </span>
            </Button>
          ) : (
            <Button
              onClick={() => {
                setShowCompanies(true)
              }}
              className={'boothSelector-button'}
            >
              Show Companies List
              <span className={'boothSelector-expandIcon'}>
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          {showCompanies && (
            <BoothCompaniesList
              setTableParameters={setTableParameters}
              companiesAndBooths={companiesAndBooths}
              loading={loading}
            />
          )}
        </Col>
        <Col span={showCompanies ? 18 : 24}>
          {companiesAndBooths && (
            <SeatsContainer
              eventId={eventId}
              companiesAndBooths={companiesAndBooths}
              getCompaniesForList={getCompaniesForList}
              allowSelectingBooked={true}
              getEvent={getEvent}
            ></SeatsContainer>
          )}
        </Col>
      </Row>
    </>
  )
}
BoothSelectorContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
  allowSelectingBooked: PropTypes.bool,
  getEvent: PropTypes.func,
}
export default BoothSelectorContainer
