import { render } from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import {
  every,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  isNull,
  isUndefined,
  map,
} from 'lodash'
import { Provider } from 'react-redux'
import { logout } from './actions/auth'
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  TrackingAnalytics,
} from './config'
import * as serviceWorker from './serviceWorker'
import configureStore, { history } from './store'
import {
  getAdminStatus,
  getCompany,
  getEmail,
  getMarketingStatus,
  getUserId,
} from './utils'
import http from './utils/api'
import App from './views/App'
import './styles/index.less'

const { analytics } = window

const store = configureStore({
  auth: {
    accessToken: localStorage.getItem(ACCESS_TOKEN_KEY),
    refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
    userId: getUserId(localStorage.getItem(ACCESS_TOKEN_KEY)),
    isAdmin: getAdminStatus(localStorage.getItem(ACCESS_TOKEN_KEY)),
    isMarketing: getMarketingStatus(localStorage.getItem(ACCESS_TOKEN_KEY)),
    openSignInAs: [],
  },
  user: getCompany(localStorage.getItem(ACCESS_TOKEN_KEY)),
})

let currentUser
let currentEmail
store.subscribe(() => {
  const prevUser = currentUser
  const prevEmail = currentEmail

  const auth = store.getState().auth
  const { userId } = auth
  const email = getEmail(auth.accessToken)
  currentUser = userId
  currentEmail = email

  if (ACCESS_TOKEN_KEY) {
    if (
      every(map([currentUser, currentEmail], isNull)) &&
      every(map([prevUser, prevEmail], (obj) => !isNull(obj)))
    ) {
    } else {
      if (TrackingAnalytics && analytics) {
        analytics.identify(userId, {
          email: isArray(email) ? email[0] : email,
        })
      }
    }
  }
})

store.subscribe(() => {
  const { accessToken, refreshToken } = store.getState().auth
  const { companyId, role } = store.getState().user

  if (accessToken === null) {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
    delete http.defaults.headers.common.Authorization
  } else {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
    http.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    http.interceptors.response.use(null, (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch(logout())
        history.push('/login')
      }
      return Promise.reject(error)
    })
  }

  if (refreshToken === null) {
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  } else {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
  }

  if (companyId <= 0) {
    delete http.defaults.headers.common['X-AUTH-COMPANY_ID']
    delete http.defaults.headers.common['X-AUTH-COMPANY_ROLE']
  } else {
    http.defaults.headers.common['X-AUTH-COMPANY_ID'] = companyId.toString()
    http.defaults.headers.common['X-AUTH-COMPANY_ROLE'] = role
  }
})

if (TrackingAnalytics.enabled) {
  const { analytics } = window
  if (analytics) {
    analytics.page()
  }

  let prevPath = null
  history.listen((location) => {
    if (location.pathname !== prevPath) {
      prevPath = location.pathname
      analytics.page()
    }
  })
}

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
