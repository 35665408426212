import http from '../utils/api'

export const getBackstageUsers = (pagination, filters, sorter, searchTerm) =>
  http.post(`/users`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const addBackstageUser = (user) => http.post('/users/add', { ...user })

export const updateBackstageUser = (userId, user) =>
  http.put(`/users/edit/${userId}`, {
    ...user,
  })

export const deleteBackstageUser = (userId) => http.delete(`/users/${userId}`)
