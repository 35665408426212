import { Component } from 'react'
import ReactDOM from 'react-dom'
import { Icon } from 'antd'
import { isNil } from 'lodash'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}`,
    content: `item ${k + offset}`,
  }))

const getItems1 = (options, offset, selectedValues, prefix = '') => {
  if (!isNil(selectedValues)) {
    options = options.filter((x) =>
      isNil(selectedValues.find((v) => v.content === x.value))
    )
    offset = selectedValues.length
  }
  return Array.from({ length: options.length }, (v, k) => k).map((k) => ({
    id: `${prefix + prefix == '' ? '' : '-'}${k + offset}`,
    content: `${prefix + prefix == '' ? '' : ' '}${options[k].text}`,
  }))
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#FFFFFF',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#EFF1F4',
  padding: grid,
  width: '40%',
  margin: '15px',
  display: 'inline-block',
  verticalAlign: 'top',
})

export default class App extends Component {
  state = {
    items: getItems1(this.props.options, 0, this.props.value),
    //selected: getItems1(this.props.value, 0),
    selected: this.props.value,
  }

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the Ids of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    droppable: 'items',
    droppable2: 'selected',
  }

  getList = (id) => this.state[this.id2List[id]]

  onDragEnd = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      )

      let state = { items }

      if (source.droppableId === 'droppable2') {
        state = { selected: items }
      }

      this.setState(state)

      if (typeof this.props.onChange === 'function') {
        this.props.onChange(items)
      }
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      )

      this.setState({
        items: result.droppable,
        selected: result.droppable2,
      })

      if (typeof this.props.onChange === 'function') {
        this.props.onChange(result.droppable2)
      }
    }
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <h4 style={{ textAlign: 'left' }}>Available</h4>
              {this.state.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {item.content}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Icon type="right" style={{ marginTop: '150px' }} />
        <Droppable style={{ width: '20%' }} droppableId="droppable2">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <h4 style={{ textAlign: 'left' }}>Selected</h4>
              {this.state.selected.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {item.content}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {/* {console.log(this.state.items)}
              {console.log(this.state.selected)} */}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
}
