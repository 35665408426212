import { useContext } from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import FormCtx from '../../../utils/contexts/FormCtx'
import getConditionalQuestions from '../../../utils/form/getConditionalQuestions'
import getConditionalRadioFieldComponent from '../../../utils/form/getConditionalRadioFieldComponent'
import useFormAnswers from '../../../utils/hooks/useFormAnswers'
import '../Fields.less'

const ConditionalRadioField = ({ question }) => {
  const { questions } = useContext(FormCtx)
  const { answer, handleOptionsAnswerChange } = useFormAnswers(question)
  const chosenAnswerId = answer.questionAnswerIds[0]

  return (
    <>
      {question.text && <div className="Text">{question.text}</div>}
      <Radio.Group
        className="BooleanField-radio"
        value={chosenAnswerId}
        onChange={(e) => handleOptionsAnswerChange([e.target.value])}
      >
        {question.questionAnswers.map((q, key) => (
          <div key={key}>
            <Radio value={q.questionAnswerId}>{q.answer}</Radio>
            <div className="BooleanField-radio-conditional">
              {chosenAnswerId === q.questionAnswerId &&
                getConditionalQuestions(
                  q.questionAnswerId,
                  questions,
                  question,
                  getConditionalRadioFieldComponent
                )}
            </div>
          </div>
        ))}
      </Radio.Group>
    </>
  )
}

ConditionalRadioField.propTypes = {
  question: PropTypes.shape({
    questionId: PropTypes.number,
    text: PropTypes.string,
    questionAnswers: PropTypes.array,
    attributes: PropTypes.object,
    shortName: PropTypes.string,
  }),
}

export default ConditionalRadioField
