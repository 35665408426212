import {
  FETCH_INVESTORS_CATEGORIES_DONE,
  FETCH_INVESTORS_CATEGORIES_ERROR,
  FETCH_INVESTORS_CATEGORIES_START,
  FETCH_INVESTORS_CATEGORIES_SUCCESS,
  FETCH_INVESTORS_COUNTRIES_DONE,
  FETCH_INVESTORS_COUNTRIES_ERROR,
  FETCH_INVESTORS_COUNTRIES_START,
  FETCH_INVESTORS_COUNTRIES_SUCCESS,
  FETCH_INVESTORS_INVESTMENT_SIZES_DONE,
  FETCH_INVESTORS_INVESTMENT_SIZES_ERROR,
  FETCH_INVESTORS_INVESTMENT_SIZES_START,
  FETCH_INVESTORS_INVESTMENT_SIZES_SUCCESS,
  FETCH_INVESTORS_LIST_DONE,
  FETCH_INVESTORS_LIST_ERROR,
  FETCH_INVESTORS_LIST_START,
  FETCH_INVESTORS_LIST_SUCCESS,
} from '../constants/types'

const initialState = {
  investorList: [],
  investorCategories: [],
  investmentSizes: [],
  countries: [],
  columns: [],
  investorListLoading: false,
  investorCategoriesLoading: false,
  investmentSizesLoading: false,
  countriesLoading: false,
  investorListError: null,
  investorCategoriesError: null,
  investmentSizesError: null,
  countriesError: null,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_INVESTORS_LIST_START:
      return { ...state, investorListLoading: true }
    case FETCH_INVESTORS_LIST_SUCCESS:
      return { ...state, investorList: action.payload }
    case FETCH_INVESTORS_LIST_ERROR:
      return { ...state, investorListLoading: false }
    case FETCH_INVESTORS_LIST_DONE:
      return { ...state, investorListLoading: false }

    case FETCH_INVESTORS_CATEGORIES_START:
      return { ...state, investorCategoriesLoading: true }
    case FETCH_INVESTORS_CATEGORIES_SUCCESS:
      return { ...state, investorCategories: action.payload }
    case FETCH_INVESTORS_CATEGORIES_ERROR:
      return { ...state, investorCategoriesLoading: false }
    case FETCH_INVESTORS_CATEGORIES_DONE:
      return { ...state, investorCategoriesLoading: false }

    case FETCH_INVESTORS_INVESTMENT_SIZES_START:
      return { ...state, investmentSizesLoading: true }
    case FETCH_INVESTORS_INVESTMENT_SIZES_SUCCESS:
      return { ...state, investmentSizes: action.payload }
    case FETCH_INVESTORS_INVESTMENT_SIZES_ERROR:
      return { ...state, investmentSizesLoading: false }
    case FETCH_INVESTORS_INVESTMENT_SIZES_DONE:
      return { ...state, investmentSizesLoading: false }

    case FETCH_INVESTORS_COUNTRIES_START:
      return { ...state, countriesLoading: true }
    case FETCH_INVESTORS_COUNTRIES_SUCCESS:
      return { ...state, countries: action.payload }
    case FETCH_INVESTORS_COUNTRIES_ERROR:
      return { ...state, countriesLoading: false }
    case FETCH_INVESTORS_COUNTRIES_DONE:
      return { ...state, countriesLoading: false }

    default:
      return state
  }
}
