import { useState } from 'react'
import { faTrash, faUpload } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Input, Radio, Upload, message } from 'antd'

const { Dragger } = Upload

const MediaStep = (props) => {
  const { values, handleChange, touched, errors, setFieldValue } = props

  const [fileList, setFileList] = useState([])

  const getBase64 = (file, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(file)
  }

  const onBeforeUpload = (file) => {
    const isPdf = file.type === 'application/pdf'
    if (isPdf) {
      getBase64(file, (pdf) => {
        setFieldValue('mediaContent', pdf)
        setFieldValue('defaultPdf', null)
      })
      const list = [file]
      setFileList(list)
    } else {
      message.error('Error file must be a PDF')
      setFileList([])
    }

    return false
  }

  const onRemovePdf = () => {
    setFileList([])
    setFieldValue('mediaContent', null)
  }

  return (
    <>
      <label>
        What type of media would you like to add?{' '}
        <span className="required">*</span>
      </label>
      <div>
        <Form.Item
          name="mediaTypeId"
          validateStatus={
            touched.mediaTypeId && errors.mediaTypeId ? 'error' : 'success'
          }
          help={
            touched.mediaTypeId && errors.mediaTypeId ? errors.mediaTypeId : ''
          }
        >
          <Radio.Group
            onChange={(e) => {
              setFieldValue('mediaContent', null)
              handleChange(e)
            }}
            value={values.mediaTypeId}
            name="mediaTypeId"
          >
            <div className="radio-option">
              <Radio value={1} style={{ paddingRight: '12px' }} />
              <span>
                Webpage Link (blog post, article, press release). This media
                will open in a new tab.
              </span>
            </div>
            {values.mediaTypeId === 1 && (
              <Form.Item
                name="mediaContent"
                validateStatus={
                  touched.mediaContent && errors.mediaContent
                    ? 'error'
                    : 'success'
                }
                help={
                  touched.mediaContent && errors.mediaContent
                    ? errors.mediaContent
                    : ''
                }
              >
                <Input
                  name="mediaContent"
                  value={values.mediaContent}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Item>
            )}
            <div className="radio-option">
              <Radio value={2} style={{ paddingRight: '12px' }} />
              <span>
                Video (YouTube, Vimeo, AWS Video Link). This media will be
                displayed in a lightbox.
              </span>
            </div>
            {values.mediaTypeId === 2 && (
              <Form.Item
                name="mediaContent"
                validateStatus={
                  touched.mediaContent && errors.mediaContent
                    ? 'error'
                    : 'success'
                }
                help={
                  touched.mediaContent && errors.mediaContent
                    ? errors.mediaContent
                    : ''
                }
              >
                <Input
                  name="mediaContent"
                  value={values.mediaContent}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Item>
            )}
            <div className="radio-option">
              <Radio value={3} style={{ paddingRight: '12px' }} />
              <span>PDF. This media will be displayed in the reader view.</span>
            </div>
            {values.mediaTypeId === 3 && (
              <Form.Item
                name="mediaContent"
                validateStatus={
                  touched.mediaContent && errors.mediaContent
                    ? 'error'
                    : 'success'
                }
                help={
                  touched.mediaContent && errors.mediaContent
                    ? errors.mediaContent
                    : ''
                }
              >
                <Dragger
                  accept=".pdf"
                  name="mediaContent"
                  fileList={fileList}
                  beforeUpload={onBeforeUpload}
                  onRemove={onRemovePdf}
                >
                  <p className="ant-upload-drag-icon">
                    <FontAwesomeIcon
                      icon={faUpload}
                      size="3x"
                      color="#C2C6CC"
                    />
                  </p>
                  <p className="ant-upload-text">
                    Drag or click here to upload a PDF document
                  </p>
                  <p className="ant-upload-hint">(PDF / Max file size 100mb)</p>
                </Dragger>
                {values.defaultPdf && (
                  <div>
                    <a href={values.defaultPdf}>Download PDF</a>
                    <Button
                      type="link"
                      onClick={() => {
                        setFieldValue('defaultPdf', null)
                        setFieldValue('mediaContent', null)
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="content-field-delete"
                      />
                    </Button>
                  </div>
                )}
              </Form.Item>
            )}
          </Radio.Group>
        </Form.Item>
      </div>
    </>
  )
}

MediaStep.propTypes = {}

export default MediaStep
