import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faPencilAlt,
  faSignInAlt,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons'
import { Avatar, Button, Col, Divider, Row, Typography } from 'antd'
import isNil from 'lodash/isNil'
import http from '../../utils/api'
import { VerticalActionButton } from '../ActionButton/ActionButton'

const { Title, Paragraph } = Typography

const CompanyScheduleDetails = ({ scheduleDetails, refresh }) => {
  return (
    <>
      <div className="ScheduleDetails-Wrapper">
        <Row type="flex" align="top" style={{ background: 'white' }}>
          <Col span={24}>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={12}>
                <Title level={4} className="header">
                  Schedule Details
                </Title>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Divider />
            {contactRows(scheduleDetails, refresh)}
            <Divider style={{ backgroundColor: 'transparent' }} />
            <Row type="flex" justify="space-between" align="middle">
              <Col span={12}>
                <Title level={4} className="header">
                  Company Description
                </Title>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="paddedRow"
            >
              <Col span={24}>
                <div className="boldText">
                  {scheduleDetails.company.companyDescription}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  )
}

//create new array of contacts grouped in rows of two
const groupContacts = (scheduleDetails) => {
  var grouped = []
  var row = []
  var contact

  for (let i = 0; i < scheduleDetails.contacts.length; i++) {
    contact = scheduleDetails.contacts[i]
    contact.companyEventId = scheduleDetails.companyEventId
    contact.eventId = scheduleDetails.eventId

    if (i % 2 === 0) {
      row.push(contact)
    } else {
      row.push(contact)
      grouped.push(row)
      row = []
    }
  }

  //if there is one extra contact
  if (row.length > 0) grouped.push(row)

  return grouped
}

const contactRows = (scheduleDetails, refresh) => {
  const contactRows = groupContacts(scheduleDetails)

  return contactRows.map((column, index) => (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      className="doublePaddedRow"
    >
      <Col span={12}>{contactProfile(column[0], refresh)}</Col>
      {column.length > 1 ? (
        <Col span={12}>{contactProfile(column[1], refresh)}</Col>
      ) : null}
    </Row>
  ))
}

const contactMenus = (contact) => {
  return [
    {
      text: 'Edit User Profile',
      icon: faPencilAlt,
      link: '/contactdetails/' + contact.contactId,
    },
    {
      text: 'Sign in as ' + contact.firstName + ' ' + contact.lastName,
      icon: faSignInAlt,
      func: 'signisas_' + contact.contactId,
    },
  ]
}

const contactProfile = (contact, refresh) => {
  return (
    <Row
      type="flex"
      justify="space-between"
      align="top"
      style={{ paddingBottom: '10px' }}
      className="borderRow"
    >
      <Col span={4}>
        <Avatar
          src={contact.imageURL}
          style={{
            fontSize: '14px',
            textAlign: 'center',
            verticalAlign: 'bottom',
          }}
          shape="circle"
          size={64}
        >
          {contact.contactName}
        </Avatar>
      </Col>
      <Col span={15}>
        <Row type="flex" justify="space-between" align="middle">
          <div className="contactName">{contact.contactName}</div>
        </Row>
        <Row type="flex" justify="space-between" align="middle">
          <div className="contactDetail">{contact.jobTitle}</div>
        </Row>
        <Row type="flex" justify="space-between" align="middle">
          <div className="contactDetail">{contact.email}</div>
        </Row>
      </Col>
      <Col span={3}>
        <Row type="flex" justify="space-between" align="middle">
          <div className="ActionButton">
            <VerticalActionButton
              menus={contactMenus(contact)}
              tag={contact}
              refresh={refresh}
            />
          </div>
        </Row>
      </Col>
    </Row>
  )
}

CompanyScheduleDetails.propTypes = {
  eventParticipantId: PropTypes.number,
  fundEventId: PropTypes.number,
}

export default CompanyScheduleDetails
