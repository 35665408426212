import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NavButton, NavList } from '@context365/tabs'
import { message } from 'antd'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import some from 'lodash/some'
import sum from 'lodash/sum'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import {
  getAllocatorWizard,
  getAllocatorWizardOptions,
  saveAllocationWizard,
} from '../../actions/allocator'
import LoadingGif from '../LoadingGif'
import { ADD_NEW_ID } from './constants'
import Step from './Step'
import Allocations from './steps/Allocations'
import BoardMeetings from './steps/BoardMeetings'
import Documents from './steps/Documents'
import ManagersFunds from './steps/managers/ManagersFunds'
import Media from './steps/Media'
import Overview from './steps/Overview'
import People from './steps/People'
import Returns from './steps/Returns'
import ServiceProviders from './steps/ServiceProviders'

const validateAssets = (assets) => {
  if (isEmpty(assets)) {
    return true
  }

  if (
    some(
      flatMap(assets, (a) => a.amounts),
      (d) => isNil(d.valuationDate)
    )
  ) {
    message.error(
      'You have some incomplete assets. Valuation date is required.'
    )
    return false
  }

  if (
    some(
      flatMap(assets, (a) => a.amounts),
      (d) => isNil(d.amount)
    )
  ) {
    message.error('You have some incomplete assets. Asset amount is required.')
    return false
  }

  return true
}

const validateAllocations = (company) => {
  const missingDate = find(company.allocations, (a) => isNil(a.valuationDate))

  if (missingDate) {
    message.error('One or more allocations are missing dates')
    return false
  }

  const invalidPercent = find(
    company.allocations,
    (a) =>
      a.alternativeAssetsTargetPercentage > 100 ||
      a.alternativeAssetsActualPercentage > 100
  )

  if (invalidPercent) {
    message.error(
      'Alternative assets target or actual percentage is greater than 100'
    )
    return false
  }

  const percentYears = filter(company.allocations, (a) => a.isPercentage)

  const invalidPercentTotal = find(
    percentYears,
    (p) =>
      sum(map(p.amounts, (am) => am.targetAmount)) > 100 ||
      sum(map(p.amounts, (am) => am.actualAmount)) > 100
  )

  if (invalidPercentTotal) {
    message.error('Target or actual allocation percentages total more than 100')
    return false
  }

  if (
    some(
      flatMap(company.allocations, (bm) => bm.amounts),
      (d) => isNil(d.alternativeCategoryId) && !d.alternativeCategoryName
    )
  ) {
    message.error(
      'One or more Other category allocations are missing category names'
    )
    return false
  }

  if (
    some(
      flatMap(
        flatMap(company.allocations, (a) => a.amounts),
        (aa) => aa.strategies
      ),
      (m) =>
        m.vettedFundStrategyId === ADD_NEW_ID &&
        m.unvettedFundStrategyId === ADD_NEW_ID &&
        isNil(m.name)
    )
  ) {
    message.error(
      'You have some incomplete Allocation Strategies. Write-In Name is required.'
    )
    return false
  }

  if (
    some(
      flatMap(
        flatMap(company.allocations, (a) => a.amounts),
        (aa) => aa.strategies
      ),
      (m) => isNil(m.vettedFundStrategyId) && isNil(m.unvettedFundStrategyId)
    )
  ) {
    message.error(
      'You have some incomplete Allocation Strategies. Select a strategies or remove the selection'
    )
    return false
  }

  return true
}

const validateReturns = (company) => {
  const missingDate = find(company.returns, (a) => isNil(a.valuationDate))

  if (missingDate) {
    message.error('One or more returns are missing valuation dates')
    return false
  }

  if (
    some(
      flatMap(company.returns, (bm) => bm.amounts),
      (d) => isNil(d.alternativeCategoryId) && !d.alternativeCategoryName
    )
  ) {
    message.error(
      'One or more Other category returns are missing category names'
    )
    return false
  }

  return true
}

const validateBoardMeetings = (boardMeetings) => {
  if (isEmpty(boardMeetings)) {
    return true
  }

  if (some(boardMeetings, (bm) => isNil(bm.meetingDateTime))) {
    message.error(
      'You have some incomplete board meetings. Meeting date is required.'
    )
    return false
  }

  if (
    some(
      flatMap(boardMeetings, (bm) => bm.documents),
      (d) => isNil(d.url)
    )
  ) {
    message.error(
      'You have some incomplete board meeting documents. A document upload is required.'
    )
    return false
  }

  return true
}

const validateDocuments = (documents) => {
  if (isEmpty(documents)) {
    return true
  }

  if (some(documents, (d) => isNil(d.url))) {
    message.error(
      'You have some incomplete documents. A document upload is required.'
    )
    return false
  }

  return true
}

const validateMedia = (media) => {
  if (isEmpty(media)) {
    return true
  }

  if (some(media, (d) => isNil(d.url))) {
    message.error(
      'You have some incomplete media. A media upload or url is required.'
    )
    return false
  }

  if (some(media, (d) => isNil(d.mediaCategoryId))) {
    message.error(
      'You have some incomplete media. A media category is required.'
    )
    return false
  }

  return true
}

const validateManagers = (managers) => {
  if (
    some(
      managers,
      (m) =>
        (isNil(m.companyAllocatorManagerId) &&
          m.vettedCompanyManagerId === ADD_NEW_ID &&
          m.unvettedCompanyManagerId === ADD_NEW_ID &&
          isNil(m.name)) ||
        (isNil(m.companyAllocatorManagerId) &&
          isNil(m.vettedCompanyManagerId) &&
          isNil(m.unvettedCompanyManagerId)) ||
        (!isNil(m.companyAllocatorManagerId) &&
          isNil(m.vettedCompanyManagerId) &&
          isNil(m.unvettedCompanyManagerId) &&
          isNil(m.name))
    )
  ) {
    message.error(
      'You have some incomplete managers. Investment manager name is required.'
    )
    return false
  }

  if (
    some(
      flatMap(managers, (m) => m.funds),
      (f) =>
        (isNil(f.companyAllocatorManagerFundId) &&
          f.vettedFundId === ADD_NEW_ID &&
          f.unvettedFundId === ADD_NEW_ID &&
          isNil(f.name)) ||
        (isNil(f.companyAllocatorManagerFundId) &&
          isNil(f.vettedFundId) &&
          isNil(f.unvettedFundId)) ||
        (!isNil(f.companyAllocatorManagerFundId) &&
          isNil(f.vettedFundId) &&
          isNil(f.unvettedFundId) &&
          isNil(f.name))
    )
  ) {
    message.error('You have some incomplete funds. Fund name is required.')
    return false
  }
  if (
    some(
      flatMap(
        flatMap(managers, (a) => a.funds),
        (aa) => aa.strategies
      ),
      (m) =>
        m.vettedFundStrategyId === ADD_NEW_ID &&
        m.unvettedFundStrategyId === ADD_NEW_ID &&
        isNil(m.name)
    )
  ) {
    message.error(
      'You have some incomplete Allocation Strategies. Write-In Name is required.'
    )
    return false
  }

  if (
    some(
      flatMap(
        flatMap(managers, (a) => a.funds),
        (aa) => aa.strategies
      ),
      (m) => isNil(m.vettedFundStrategyId) && isNil(m.unvettedFundStrategyId)
    )
  ) {
    message.error(
      'You have some incomplete Allocation Strategies. Select a strategies or remove the selection'
    )
    return false
  }

  return true
}

const validateServiceProviders = (serviceProviders) => {
  if (isEmpty(serviceProviders)) {
    return true
  }

  if (
    some(
      serviceProviders,
      (m) =>
        m.vettedServiceProviderId === ADD_NEW_ID &&
        m.unvettedServiceProviderId === ADD_NEW_ID &&
        isNil(m.name)
    )
  ) {
    message.error(
      'You have some incomplete Service Providers. Name is required.'
    )
    return false
  }

  if (
    some(
      serviceProviders,
      (m) =>
        isNil(m.vettedServiceProviderId) && isNil(m.unvettedServiceProviderId)
    )
  ) {
    message.error(
      'You have some incomplete Service Providers. Select a service provider or remove the selection'
    )
    return false
  }

  return true
}

const AllocatorProfileWizard = ({ companyId }) => {
  const [company, setCompany] = useState(null)
  const [options, setOptions] = useState(null)
  const [isLoadingCompany, setIsLoadingCompany] = useState(true)
  const [isLoadingOptions, setIsLoadingOptions] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const { path, url } = useRouteMatch()
  const history = useHistory()

  const allocatorProfilePath = `/companies/allocator/${companyId}`
  const submitCompanyProfile = (nextStep) => {
    if (
      validateAssets(company.assets) &&
      validateAllocations(company) &&
      validateReturns(company) &&
      validateDocuments(company.documents) &&
      validateBoardMeetings(company.boardMeetings) &&
      validateMedia(company.medias) &&
      validateManagers(company.managers) &&
      validateServiceProviders(company.serviceProviders)
    ) {
      const formattedCompany = {
        ...company,
        managers: map(company.managers, (m) => {
          return {
            ...m,
            vettedCompanyManagerId:
              m.vettedCompanyManagerId === ADD_NEW_ID
                ? null
                : m.vettedCompanyManagerId,
            unvettedCompanyManagerId:
              m.unvettedCompanyManagerId === ADD_NEW_ID
                ? null
                : m.unvettedCompanyManagerId,
            funds: map(m.funds, (f) => {
              return {
                ...f,
                vettedFundId:
                  f.vettedFundId === ADD_NEW_ID ? null : f.vettedFundId,
                unvettedFundId:
                  f.unvettedFundId === ADD_NEW_ID ? null : f.unvettedFundId,
                strategies: map(f.strategies, (s) => {
                  return {
                    ...s,
                    vettedFundStrategyId:
                      s.vettedFundStrategyId === ADD_NEW_ID
                        ? null
                        : s.vettedFundStrategyId,
                    unvettedFundStrategyId:
                      s.unvettedFundStrategyId === ADD_NEW_ID
                        ? null
                        : s.unvettedFundStrategyId,
                  }
                }),
              }
            }),
          }
        }),
        serviceProviders: map(company.serviceProviders, (m) => {
          return {
            ...m,
            vettedServiceProviderId:
              m.vettedServiceProviderId === ADD_NEW_ID
                ? null
                : m.vettedServiceProviderId,
            unvettedServiceProviderId:
              m.unvettedServiceProviderId === ADD_NEW_ID
                ? null
                : m.unvettedServiceProviderId,
          }
        }),
        allocations: map(company.allocations, (a) => {
          return {
            ...a,
            amounts: map(a.amounts, (aa) => {
              return {
                ...aa,
                strategies: map(aa.strategies, (s) => {
                  return {
                    ...s,
                    vettedFundStrategyId:
                      s.vettedFundStrategyId === ADD_NEW_ID
                        ? null
                        : s.vettedFundStrategyId,
                    unvettedFundStrategyId:
                      s.unvettedFundStrategyId === ADD_NEW_ID
                        ? null
                        : s.unvettedFundStrategyId,
                  }
                }),
              }
            }),
          }
        }),
      }

      return saveAllocationWizard(companyId, formattedCompany)
        .then(() => {
          message.success('Company profile saved successfully', 3)

          if (nextStep) {
            history.push(`${allocatorProfilePath}/${nextStep}`)
            window.scrollTo(0, 0)
          }
        })
        .catch(() => {
          message.error('An error ocurred while saving to the database')
          return Promise.reject()
        })
    } else {
      return Promise.reject()
    }
  }

  useEffect(() => {
    getAllocatorWizard(companyId)
      .then((data) => setCompany(data))
      .finally(() => setIsLoadingCompany(false))
  }, [companyId, refresh])

  useEffect(() => {
    getAllocatorWizardOptions()
      .then((data) => setOptions(data))
      .finally(() => setIsLoadingOptions(false))
  }, [])

  const handleChange = useCallback(
    (key, value) => {
      setCompany({ ...company, [key]: value })
    },
    [company]
  )

  const saveOnTabChange = () =>
    submitCompanyProfile(null)
      .then(() => setRefresh(!refresh))
      .catch(() => {
        // do nothing
      })

  return (
    <div className="bg-white p-4 flex flex-row">
      <div className="flex w-64 mr-8 self-start">
        <NavList variant="vertical">
          <NavButton
            to={`${allocatorProfilePath}/overview`}
            onClick={saveOnTabChange}
          >
            Overview
          </NavButton>
          <NavButton
            to={`${allocatorProfilePath}/allocations`}
            onClick={saveOnTabChange}
          >
            Allocations
          </NavButton>
          <NavButton
            to={`${allocatorProfilePath}/returns`}
            onClick={saveOnTabChange}
          >
            Returns
          </NavButton>
          <NavButton
            to={`${allocatorProfilePath}/managersAndFunds`}
            onClick={saveOnTabChange}
          >
            Managers & Funds
          </NavButton>
          <NavButton
            to={`${allocatorProfilePath}/boardMeetings`}
            onClick={saveOnTabChange}
          >
            Board Meetings
          </NavButton>
          <NavButton
            to={`${allocatorProfilePath}/documents`}
            onClick={saveOnTabChange}
          >
            Documents
          </NavButton>
          <NavButton
            to={`${allocatorProfilePath}/media`}
            onClick={saveOnTabChange}
          >
            Media
          </NavButton>
          <NavButton
            to={`${allocatorProfilePath}/people`}
            onClick={saveOnTabChange}
          >
            People
          </NavButton>
          <NavButton
            to={`${allocatorProfilePath}/serviceProvider`}
            onClick={saveOnTabChange}
          >
            Service Provider
          </NavButton>
        </NavList>
      </div>
      <div className="w-full h-full self-start p-4">
        <LoadingGif spinning={isLoadingCompany || isLoadingOptions}>
          <div>
            {company && options && (
              <Switch>
                <Route path={`${path}/overview`}>
                  <Step
                    heading="Overview"
                    onForward={() => {
                      submitCompanyProfile('allocations')
                    }}
                    onSave={() => submitCompanyProfile(null)}
                    companyId={companyId}
                  >
                    <Overview
                      company={company}
                      options={options}
                      updateCompany={handleChange}
                    />
                  </Step>
                </Route>
                <Route path={`${path}/allocations`}>
                  <Step
                    heading="Allocations"
                    onBack={() =>
                      submitCompanyProfile('overview').then(() =>
                        setRefresh(!refresh)
                      )
                    }
                    onForward={() =>
                      submitCompanyProfile('returns').then(() =>
                        setRefresh(!refresh)
                      )
                    }
                    onSave={() =>
                      submitCompanyProfile(null).then(() =>
                        setRefresh(!refresh)
                      )
                    }
                    companyId={companyId}
                  >
                    <Allocations
                      allocations={company?.allocations}
                      alternativeCategories={
                        options?.alternativeAllocationCategories
                      }
                      updateCompany={handleChange}
                      visible={url?.endsWith('allocations')}
                      refreshStrategies={refresh}
                    />
                  </Step>
                </Route>
                <Route path={`${path}/returns`}>
                  <Step
                    heading="Returns"
                    onBack={() => submitCompanyProfile('allocations')}
                    onForward={() => submitCompanyProfile('managersAndFunds')}
                    onSave={() => submitCompanyProfile(null)}
                    companyId={companyId}
                  >
                    <Returns
                      returns={company?.returns}
                      alternativeCategories={
                        options?.alternativeAllocationCategories
                      }
                      strategies={options?.fundStrategies}
                      returnCadences={options?.returnCadences}
                      updateCompany={handleChange}
                    />
                  </Step>
                </Route>
                <Route path={`${path}/managersAndFunds`}>
                  <Step
                    heading="Managers & Funds"
                    onBack={() =>
                      submitCompanyProfile('returns').then(() =>
                        setRefresh(!refresh)
                      )
                    }
                    onForward={() =>
                      submitCompanyProfile('boardMeetings').then(() =>
                        setRefresh(!refresh)
                      )
                    }
                    onSave={() =>
                      submitCompanyProfile(null).then(() =>
                        setRefresh(!refresh)
                      )
                    }
                    companyId={companyId}
                  >
                    <ManagersFunds
                      managers={company.managers}
                      updateManagers={(newManagers) =>
                        handleChange('managers', newManagers)
                      }
                      visible={url?.endsWith('managersAndFunds')}
                      refreshStrategies={refresh}
                      companyId={companyId}
                    />
                  </Step>
                </Route>
                <Route path={`${path}/boardMeetings`}>
                  <Step
                    heading="Board Meetings"
                    onBack={() =>
                      submitCompanyProfile('managersAndFunds').then(() =>
                        setRefresh(!refresh)
                      )
                    }
                    onForward={() =>
                      submitCompanyProfile('documents').then(() =>
                        setRefresh(!refresh)
                      )
                    }
                    onSave={() =>
                      submitCompanyProfile(null).then(() =>
                        setRefresh(!refresh)
                      )
                    }
                    companyId={companyId}
                  >
                    <BoardMeetings
                      boardMeetings={company.boardMeetings}
                      updateBoardMeetings={(newBoardMeetings) =>
                        handleChange('boardMeetings', newBoardMeetings)
                      }
                      documentCategories={options?.documentCategories}
                    />
                  </Step>
                </Route>
                <Route path={`${path}/documents`}>
                  <Step
                    heading="Documents"
                    onBack={() => submitCompanyProfile('boardMeetings')}
                    onForward={() => submitCompanyProfile('media')}
                    onSave={() => submitCompanyProfile(null)}
                    companyId={companyId}
                  >
                    <Documents
                      documents={company.documents}
                      updateDocuments={(newDocuments) =>
                        handleChange('documents', newDocuments)
                      }
                      documentCategories={options?.documentCategories}
                    />
                  </Step>
                </Route>
                <Route path={`${path}/media`}>
                  <Step
                    heading="Media"
                    onBack={() => submitCompanyProfile('documents')}
                    onForward={() => submitCompanyProfile('people')}
                    onSave={() => submitCompanyProfile(null)}
                    companyId={companyId}
                  >
                    <Media
                      media={company.medias}
                      updateMedia={(newMedias) =>
                        handleChange('medias', newMedias)
                      }
                      mediaCategories={options?.mediaTypes}
                    />
                  </Step>
                </Route>
                <Route path={`${path}/people`}>
                  <Step
                    heading="People"
                    onBack={() => submitCompanyProfile('media')}
                    onForward={() => submitCompanyProfile('serviceProvider')}
                    onSave={() => submitCompanyProfile(null)}
                    companyId={companyId}
                  >
                    <People
                      contacts={company.contacts}
                      updateContacts={(newContacts) =>
                        handleChange('contacts', newContacts)
                      }
                      jobLevels={options?.jobLevels}
                      jobFunctions={options?.jobFunctions}
                    />
                  </Step>
                </Route>
                <Route path={`${path}/serviceProvider`}>
                  <Step
                    heading="Service Provider"
                    onBack={() =>
                      submitCompanyProfile('people').then(() =>
                        setRefresh(!refresh)
                      )
                    }
                    onSave={() =>
                      submitCompanyProfile(null).then(() => {
                        setRefresh(!refresh)
                      })
                    }
                    companyId={companyId}
                  >
                    <ServiceProviders
                      serviceProviders={company.serviceProviders}
                      updateServiceProviders={(newServiceProviders) =>
                        handleChange('serviceProviders', newServiceProviders)
                      }
                      visible={url?.endsWith('serviceProvider')}
                      refreshServiceProviders={refresh}
                    />
                  </Step>
                </Route>
                <Redirect to={`${url}/overview`} />
              </Switch>
            )}
          </div>
        </LoadingGif>
      </div>
    </div>
  )
}

AllocatorProfileWizard.propTypes = {
  companyId: PropTypes.number.isRequired,
}

export default AllocatorProfileWizard
