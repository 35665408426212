import PropTypes from 'prop-types'
import { Col, Divider, Row } from 'antd'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import numeral from 'numeral'
import Holdings from './Holdings'
import InvestmentPolicy from './InvestmentPolicy'
import './ProfileNestedSectionsTab.less'

const ProfileNestedSectionsTab = ({ sections, sideSections }) => {
  const TitleAndValue = (label, value, type, className, key) => {
    if (isArray(value) && value.length > 1) {
      value = join(value, ', ')
    }

    if (type.indexOf('INT') > -1) {
      value = numeral(value).format('($0a)').toUpperCase()
    }

    if (key?.toLowerCase().trim() === 'holdings') {
      return <Holdings label={label} value={value} />
    }

    if (key?.toLowerCase().trim() === 'investmentpolicy') {
      return (
        <InvestmentPolicy label={label} value={value} className={className} />
      )
    }

    if (type === 'Child Checkbox') {
      return (
        <div
          style={{
            marginBottom: '24px',
            marginLeft: '24px',
          }}
        >
          <div className="cc-grey-text cc-body-text">{label}</div>
          <div className={className}>{value}</div>
        </div>
      )
    } else {
      return (
        <div style={{ marginBottom: '24px' }}>
          <span
            className={
              type === 'Child Checkbox'
                ? 'cc-grey-text cc-body-text'
                : 'cc-grey-text cc-tabletitle-text'
            }
          >
            {label}
          </span>
          <div className={className}>{value}</div>
        </div>
      )
    }
  }

  return (
    <Row>
      <Col span={!isEmpty(sideSections) && !isNil(sideSections) ? 15 : 24}>
        {map(sortBy(sections, 'order'), (x) =>
          TitleAndValue(x.label, x.value, x.type, 'cc-profile-info', x.key)
        )}
      </Col>
      {!isEmpty(sideSections) && !isNil(sideSections) && (
        <>
          <Col span={2}>
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
          <Col span={7}>
            {map(sideSections, (x) =>
              TitleAndValue(
                x.label,
                x.value,
                x.type,
                'cc-heading3 profile-link',
                x.key
              )
            )}
          </Col>
        </>
      )}
    </Row>
  )
}

ProfileNestedSectionsTab.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.arrayOf(PropTypes.string).isRequired,
      type: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
    })
  ).isRequired,
  sideSections: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.arrayOf(PropTypes.string).isRequired,
      type: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
    })
  ),
}
export default ProfileNestedSectionsTab
