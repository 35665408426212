import { useCallback, useState } from 'react'
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Menu, message } from 'antd'
import { forEach, get, isNil } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { updateBackstageUser } from '../../actions/admin'
import { addSignInAsHandle, removeSignInAsHandles } from '../../actions/auth'
import {
  markContentEntryFeatured,
  publishContentEntry,
} from '../../actions/contentEntries'
import { updateSponsorContentEvent } from '../../actions/events'
import { updateSponsorContent } from '../../actions/sponsorContent'
import { ALLOW_CONTACT_CREATION, REACT_APP_365_URL } from '../../config'
import http from '../../utils/api'
import BackstageUserModal from '../BackstageUserModal/BackstageUserModal'
import ContactName from '../ContactName'
import DeleteBackstageUserModal from '../DeleteBackstageUserModal/DeleteBackstageUserModal'
import DeleteCompanyUserModal from '../DeleteCompanyUserModal/DeleteCompanyUserModal'
import DeleteModal from '../DeleteModal/DeleteModal'
import DeleteSponsorContentEventModal from '../DeleteSponsorContentEventModal/DeleteSponsorContentEventModal'
import DeleteSponsorContentModal from '../DeleteSponsorContentModal/DeleteSponsorContentModal'
import DeleteSummitUserModal from '../DeleteSummitUserModal/DeleteSummitUserModal'
import DeleteUserModal from '../DeleteUserModal/DeleteUserModal'
import DiscoverAllocatorMeetingRequest from '../DiscoverAllocatorMeetingRequest/DiscoverAllocatorMeetingRequest'
import DiscoverFundMeetingRequest from '../DiscoverFundMeetingRequest/DiscoverFundMeetingRequest'
import ExportLogs from '../ExportLogs/ExportLogs'
import MessageModal from '../MessageModal'
import NewContactModal from '../NewContactModal/NewContactModal'
import RequestOnlineMeeting from '../RequestOnlineMeetingModal/RequestOnlineMeeting'
import RequestOnlineMeetingToAllocator from '../RequestOnlineMeetingToAllocator/RequestOnlineMeetingToAllocator'
import SponsorContentEventModal from '../SponsorContentEventModal/SponsorContentEventModal'
import SponsorContentModal from '../SponsorContentModal/SponsorContentModal'
import SummitAllocatorMeetingRequest from '../SummitAllocatorMeetingRequest/SummitAllocatorMeetingRequest'
import SummitFundMeetingRequest from '../SummitFundMeetingRequest/SummitFundMeetingRequest'
import SummitSPMeetingRequest from '../SummitSPMeetingRequest/SummitSPMeetingRequest'
import VisibilityModal from '../VisibilityModal/VisibilityModal'
import './ActionButton.less'

const { SubMenu } = Menu

export const signinas = (contactId) => {
  const method = 'Email'
  http.post('/auth/signinas', { contactId, method }).then((response) => {
    const accessToken = get(response, 'data', '')
    window.open(`${REACT_APP_365_URL}/sso?token=${accessToken}`, '_blank')
  })
}

const ActionButton = ({ menus, tag, companyType, userType, refresh }) => {
  const openSignInAs = useSelector((state) => state.auth.openSignInAs)
  const dispatch = useDispatch()

  const [meetingRequestModalVisible, setMeetingRequestModalVisible] =
    useState(false)
  const [messageModalVisible, setMessageModalVisible] = useState(false)
  const [messageLoading, setMessageLoading] = useState(false)
  const [messageBody, setMessageBody] = useState('')
  const [redirect, setRedirect] = useState(false)
  const history = useHistory()

  const [
    meetingRequestToAllocatorModalVisible,
    setMeetingRequestToAllocatorModalVisible,
  ] = useState(false)

  const [
    summitMeetingRequestModalVisible,
    setSummitMeetingRequestModalVisible,
  ] = useState(false)

  const [
    summitMeetingRequestToFundModalVisible,
    setSummitMeetingRequestToFundModalVisible,
  ] = useState(false)

  const [
    summitMeetingRequestToSPModalVisible,
    setSummitMeetingRequestToSPModalVisible,
  ] = useState(false)

  const [visibilityModal, setVisibilityModal] = useState(false)

  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false)
  const [exportModalVisible, setExportModalVisible] = useState(false)

  const [newContactModalVisible, setNewContactModalVisible] = useState(false)

  const [sponsorContentEventModalVisible, setSponsorContentEventModalVisible] =
    useState(false)
  const [
    deleteSponsorContentEventModalVisible,
    setDeleteSponsorContentEventModalVisible,
  ] = useState(false)

  const [sponsorContentModalVisible, setSponsorContentModalVisible] =
    useState(false)
  const [
    deleteSponsorContentModalVisible,
    setDeleteSponsorContentModalVisible,
  ] = useState(false)

  const [discoverFundMeetingRequestModal, setDiscoverFundMeetingRequestModal] =
    useState(false)

  const [
    discoverAllocatorMeetingRequestModal,
    setDiscoverAllocatorMeetingRequestModal,
  ] = useState(false)

  const [discoverSPMeetingRequestModal, setDiscoverSPMeetingRequestModal] =
    useState(false)

  const [discoverCFNMeetingRequestModal, setDiscoverCFNMeetingRequestModal] =
    useState(false)

  const [deleteCompanyModal, setDeleteCompanyModal] = useState(false)

  const [deleteBackstageUserModal, setDeleteBackstageUserModal] =
    useState(false)
  const [editBackstageUserModal, setEditBackstageUserModal] = useState(false)

  const signInAs = useCallback(
    (contactId) => {
      forEach(openSignInAs, (handle) => {
        handle.close()
      })
      dispatch(removeSignInAsHandles())
      const method = 'Email'
      http
        .post('/auth/signinas', { contactId, method })
        .then((response) => {
          const accessToken = get(response, 'data', '')
          const handle = window.open(
            `${REACT_APP_365_URL}/sso?token=${accessToken}`,
            '_blank'
          )
          dispatch(addSignInAsHandle(handle))
        })
        .catch((error) => {
          console.log(error)
        })
    },
    [openSignInAs, addSignInAsHandle, removeSignInAsHandles]
  )

  const editSponsorContentEvent = (sponsorContentEventId, sponsorContent) => {
    updateSponsorContentEvent(sponsorContentEventId, sponsorContent)
      .then((res) => {
        if (res.status === 200) {
          message.success('Sponsor content updated successfully.')
          setSponsorContentEventModalVisible(false)
          refresh()
        } else {
          message.error('An error has occurred.')
        }
      })
      .catch(() => message.error('An error has occurred.'))
  }

  const editSponsorContent = (sponsorContentId, sponsorContent) => {
    updateSponsorContent(sponsorContentId, sponsorContent)
      .then((res) => {
        if (res.status === 200) {
          message.success('Sponsor content updated successfully.')
          setSponsorContentModalVisible(false)
          refresh()
        } else {
          message.error('An error has occurred.')
        }
      })
      .catch(() => message.error('An error has occurred.'))
  }

  const actionFunction = (value) => {
    if (value === 1) {
      setRedirect(true)
    } else if (value === 3) {
      if (ALLOW_CONTACT_CREATION) {
        setNewContactModalVisible(true)
      } else {
        alert('Please use Salesforce to create a new user in 365')
      }
    } else if (value === 4) {
      if (companyType === 'fundmanagers') {
        setMeetingRequestModalVisible(true)
      }
      if (companyType === 'allocators') {
        setMeetingRequestToAllocatorModalVisible(true)
      }
    } else if (value === 5) {
      if (userType === 'allocator') {
        setSummitMeetingRequestModalVisible(true)
      }
      if (userType === 'manager') {
        setSummitMeetingRequestToFundModalVisible(true)
      }
      if (userType === 'serviceprovider') {
        setSummitMeetingRequestToSPModalVisible(true)
      }
      if (userType === 'onlineFundManager') {
        setDiscoverFundMeetingRequestModal(true)
      }
      if (userType === 'onlineAllocator') {
        setDiscoverAllocatorMeetingRequestModal(true)
      }
      if (userType === 'onlineSP') {
        setDiscoverSPMeetingRequestModal(true)
      }
      if (userType === 'onlineCFN') {
        setDiscoverCFNMeetingRequestModal(true)
      }
    } else if (value === 6) {
      setDeleteUserModalVisible(true)
    } else if (value === 7) {
      setMessageModalVisible(true)
    } else if (value === 8) {
      setVisibilityModal(true)
    } else if (value === 9) {
      setExportModalVisible(true)
    } else if (value === 10) {
      setSponsorContentEventModalVisible(true)
    } else if (value === 11) {
      setDeleteSponsorContentEventModalVisible(true)
    } else if (value === 12) {
      setSponsorContentModalVisible(true)
    } else if (value === 13) {
      setDeleteSponsorContentModalVisible(true)
    } else if (value === 14) {
      setDeleteCompanyModal(true)
    } else if (value === 15) {
      setEditBackstageUserModal(true)
    } else if (value === 16) {
      setDeleteBackstageUserModal(true)
    } else if (value === 17) {
      history.push(`/announcements/edit/${tag.announcementId}`)
    } else if (value === 19) {
      history.push(`/contententries/edit/${tag.contentEntryId}`)
    } else if (value === 20) {
      markContentEntryFeatured(tag.contentEntryId)
        .then((res) => refresh())
        .catch((err) => message.error('An error has occurred.'))
    } else if (value === 21) {
      publishContentEntry(tag.contentEntryId, !tag.isPublished)
        .then((res) => refresh())
        .catch((err) => message.error('An error has occurred.'))
    } else if (value.startsWith('signisas_')) {
      //signinsas
      const splits = value.split('_')
      if (splits.length > 0) {
        const contactId = Number(splits[1])
        signinas(contactId)
      }
    }
  }

  const renderRedirect = () => {
    if (redirect) {
      if (companyType === 'allocators')
        return <Redirect to={`/companies/allocator/${tag.company.companyId}`} />
      else if (companyType === 'fundmanagers')
        return <Redirect to={`/companies/fund/${tag.companyId}`} />
      else if (companyType === 'serviceproviders')
        return <Redirect to={`/companies/serviceprovider/${tag.companyId}`} />
      else if (companyType === 'mediaprofiles')
        return <Redirect to={`/companies/media/${tag.companyId}`} />
      else if (companyType === 'user')
        return <Redirect to={`/users/${tag.contactId}`} />
      else if (
        userType === 'allocator' ||
        userType === 'fund' ||
        userType === 'media' ||
        userType === 'serviceprovider' ||
        userType === 'manager'
      )
        return <Redirect to={`/schedule/${tag.companyEventId}`} />
    }
  }

  const menu = (
    <Menu className="action-menu">
      {menus.map((item, index) => [
        (item.submenu && (
          <SubMenu
            style={{ padding: '8px 0px' }}
            key="sub4"
            title={
              <span style={{ color: item.color }}>
                <FontAwesomeIcon
                  icon={item.icon}
                  style={{ color: item.color || '#C2C6CC' }}
                />{' '}
                {item.text}
              </span>
            }
          >
            {tag.contacts.map((contact, index) => [
              <Menu.Item
                key={index}
                className="action-menu-item"
                //onClick={() => actionFunction(item.func)}
              >
                <span
                  className="action-menu-text"
                  onClick={() => signInAs(contact.contactId)}
                  style={{ color: item.color }}
                >
                  <ContactName
                    name={contact.contactName}
                    imageUrl={contact.imageURL}
                    fontClass="cc-body-text"
                  />
                </span>
              </Menu.Item>,
              index < menus.length - 1 ? (
                <Menu.Divider className="action-menu-divider" />
              ) : (
                <></>
              ),
            ])}
          </SubMenu>
        )) || (
          <Menu.Item
            className="action-menu-item"
            onClick={(e) =>
              !isNil(item.onClick) ? item.onClick(e) : actionFunction(item.func)
            }
          >
            {!isNil(item.link) && item.link !== '#' ? (
              <a href={item.link}>
                <span
                  className="action-menu-text"
                  style={{ color: item.color }}
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    style={{ color: item.color || '#C2C6CC' }}
                  />{' '}
                  {item.text}
                </span>
              </a>
            ) : (
              <span className="action-menu-text" style={{ color: item.color }}>
                <FontAwesomeIcon
                  icon={item.icon}
                  style={{ color: item.color || '#C2C6CC' }}
                />{' '}
                {item.text}
              </span>
            )}
          </Menu.Item>
        ),
        index < menus.length - 1 ? (
          <Menu.Divider className="action-menu-divider" />
        ) : (
          <></>
        ),
      ])}
    </Menu>
  )

  return (
    <>
      {renderRedirect()}
      {tag && companyType && companyType == 'fundmanagers' && (
        <RequestOnlineMeeting
          visible={meetingRequestModalVisible}
          handleCancel={() => setMeetingRequestModalVisible(false)}
          companyTo={tag}
        />
      )}
      {tag && companyType && companyType == 'allocators' && (
        <RequestOnlineMeetingToAllocator
          visible={meetingRequestToAllocatorModalVisible}
          handleCancel={() => setMeetingRequestToAllocatorModalVisible(false)}
          companyTo={tag}
        />
      )}
      {tag &&
        userType &&
        userType == 'onlineFundManager' &&
        discoverFundMeetingRequestModal === true && (
          <DiscoverFundMeetingRequest
            visible={discoverFundMeetingRequestModal}
            handleCancel={() => setDiscoverFundMeetingRequestModal(false)}
            fund={tag.fund}
            company={tag.company}
            contacts={tag.contacts}
            onClose={() => setDiscoverFundMeetingRequestModal(false)}
            onRefresh={() => refresh()}
            //companyTo={tag}
          />
        )}
      {tag &&
        userType &&
        userType == 'onlineAllocator' &&
        discoverAllocatorMeetingRequestModal === true && (
          <DiscoverAllocatorMeetingRequest
            visible={discoverAllocatorMeetingRequestModal}
            handleCancel={() => setDiscoverAllocatorMeetingRequestModal(false)}
            //fund={tag.fund}
            companyContactId={tag.companyContactId}
            company={tag.company}
            contacts={tag.contacts}
            onClose={() => setDiscoverAllocatorMeetingRequestModal(false)}
            onRefresh={() => refresh()}
            isServiceProvider={false}
            isCfn={false}
            includeAllocators={true}
            //companyTo={tag}
          />
        )}
      {tag &&
        userType &&
        userType == 'onlineSP' &&
        discoverSPMeetingRequestModal === true && (
          <DiscoverAllocatorMeetingRequest
            visible={discoverSPMeetingRequestModal}
            handleCancel={() => setDiscoverSPMeetingRequestModal(false)}
            companyContactId={tag.companyContactId}
            company={tag.company}
            contacts={tag.contacts}
            onClose={() => setDiscoverSPMeetingRequestModal(false)}
            onRefresh={() => refresh()}
            isServiceProvider={true}
            isCfn={false}
            includeAllocators={true}
          />
        )}
      {tag &&
        userType &&
        userType == 'onlineCFN' &&
        discoverCFNMeetingRequestModal === true && (
          <DiscoverAllocatorMeetingRequest
            visible={discoverCFNMeetingRequestModal}
            handleCancel={() => setDiscoverCFNMeetingRequestModal(false)}
            companyContactId={tag.companyContactId}
            company={tag.company}
            contacts={tag.contacts}
            onClose={() => setDiscoverCFNMeetingRequestModal(false)}
            onRefresh={() => refresh()}
            isServiceProvider={false}
            isCfn={true}
            includeAllocators={false}
          />
        )}
      {tag && userType && userType === 'allocator' && (
        <SummitAllocatorMeetingRequest
          visible={
            summitMeetingRequestModalVisible
            //activeModal === MEETING_REQUEST_MODAL && !isNil(selectedCompany)
          }
          onClose={() => setSummitMeetingRequestModalVisible(false)}
          onRefresh={() => {
            //onCancelOrAccept();
            //setRefresh(!refresh);
            refresh()
          }}
          allocator={tag}
          contact={tag.contacts[0]}
          eventId={tag.eventId}
          //creditsAvailable={creditsAvailable}
          hasCredits={true}
        />
      )}
      {tag && (
        <VisibilityModal
          contactName={`${tag.firstName} ${tag.lastName}`}
          companies={tag.companies}
          visible={visibilityModal}
          handleCancel={() => setVisibilityModal(false)}
          handleOk={() => setVisibilityModal(false)}
        />
      )}

      {tag && userType && (
        <ExportLogs
          visible={exportModalVisible}
          handleCancel={() => setExportModalVisible(false)}
          logType="companyEvent"
          recordId={tag.eventCompanyId}
          companyId={tag.company.companyId}
          eventId={tag.eventId}
        />
      )}
      {tag && userType && userType === 'manager' && (
        <SummitFundMeetingRequest
          visible={summitMeetingRequestToFundModalVisible}
          onClose={() => setSummitMeetingRequestToFundModalVisible(false)}
          onRefresh={() => {
            //setRefreshData(!refreshData);
            refresh()
          }}
          fund={tag}
          eventId={tag.eventId}
          isVirtualSummit={tag.isVirtualSummit}
        />
      )}
      {tag && userType && (
        <MessageModal
          visible={messageModalVisible}
          loading={messageLoading}
          eventId={tag.eventId}
          companyName={isNil(tag) ? '' : tag.name}
          messageBody={messageBody}
          toCompanyId={tag.companyId}
          handle={() => {
            setMessageModalVisible(false)
            setMessageLoading(false)
            setMessageBody('')
          }}
          theMessage={messageBody}
          toCompanyEventId={tag.companyEventId}
          handleCancel={() => setMessageModalVisible(false)}
          onChange={(e) => setMessageBody(e.target.value)}
          investors={
            isNil(tag)
              ? []
              : tag.contacts.map((c) => ({
                  id: c.contactId,
                  contactName: c.contactName,
                  position: c.jobTitle,
                  avatar: c.imageURL,
                }))
          }
          userType={userType}
        />
      )}
      {tag && userType && userType == 'serviceprovider' && (
        <SummitSPMeetingRequest
          visible={summitMeetingRequestToSPModalVisible}
          onClose={() => setSummitMeetingRequestToSPModalVisible(false)}
          onRefresh={() => {
            //onCancelOrAccept();
            //setRefresh(!refresh);
            refresh()
          }}
          serviceProvider={tag}
          eventId={tag.eventId}
          //creditsAvailable={creditsAvailable}
          hasCredits={true}
          isVirtualSummit={tag.isVirtualSummit}
        />
      )}
      {tag && (
        <NewContactModal
          companyId={
            tag.company == undefined ? tag.companyId : tag.company.companyId
          }
          userType={companyType === undefined ? '' : companyType}
          visible={newContactModalVisible}
          handleCancel={() => {
            setNewContactModalVisible(false)
          }}
        />
      )}
      {tag && (
        <DeleteUserModal
          visible={deleteUserModalVisible}
          contactName={`${tag.firstName} ${tag.lastName}`}
          contactId={tag.contactId}
          handleCancel={() => setDeleteUserModalVisible(false)}
          handleOk={() => {
            setDeleteUserModalVisible(false)
            refresh()
          }}
        />
      )}
      {tag && sponsorContentEventModalVisible && (
        <SponsorContentEventModal
          visible={sponsorContentEventModalVisible}
          editContent={tag}
          handleCancel={() => setSponsorContentEventModalVisible(false)}
          handleOk={(sContent) =>
            editSponsorContentEvent(tag.sponsorContentEventId, sContent)
          }
        />
      )}
      {tag && deleteSponsorContentEventModalVisible && (
        <DeleteSponsorContentEventModal
          visible={deleteSponsorContentEventModalVisible}
          sponsorContentEventId={tag.sponsorContentEventId}
          handleCancel={() => setDeleteSponsorContentEventModalVisible(false)}
          handleOk={() => {
            setDeleteSponsorContentEventModalVisible(false)
            refresh()
          }}
        />
      )}
      {tag && sponsorContentModalVisible && (
        <SponsorContentModal
          visible={sponsorContentModalVisible}
          editContent={tag}
          handleCancel={() => setSponsorContentModalVisible(false)}
          handleOk={(sContent) =>
            editSponsorContent(tag.sponsorContentId, sContent)
          }
        />
      )}
      {tag && deleteSponsorContentModalVisible && (
        <DeleteSponsorContentModal
          visible={deleteSponsorContentModalVisible}
          sponsorContentId={tag.sponsorContentId}
          handleCancel={() => setDeleteSponsorContentModalVisible(false)}
          handleOk={() => {
            setDeleteSponsorContentModalVisible(false)
            refresh()
          }}
        />
      )}
      {tag && deleteCompanyModal && (
        <DeleteModal
          visible={deleteCompanyModal}
          deleteEndPoint={`/companies/${tag.company.companyId}/delete`}
          deleteSuccessMessage={'Company deleted successfully'}
          modalTitle={'Delete Company'}
          modalOkText={'Delete Company'}
          modalBody={`${tag.company.name}`}
          handleCancel={() => setDeleteCompanyModal(false)}
          handleOk={() => {
            setDeleteCompanyModal(false)
            refresh()
          }}
        />
      )}
      {tag && deleteBackstageUserModal && (
        <DeleteBackstageUserModal
          visible={deleteBackstageUserModal}
          userName={`${tag.firstName} ${tag.lastName}`}
          userId={tag.userId}
          handleCancel={() => setDeleteBackstageUserModal(false)}
          handleOk={() => {
            setDeleteBackstageUserModal(false)
            refresh()
          }}
        />
      )}
      {tag && editBackstageUserModal && (
        <BackstageUserModal
          visible={editBackstageUserModal}
          editUser={tag}
          userId={tag.userId}
          handleCancel={() => setEditBackstageUserModal(false)}
          handleOk={(user) => {
            updateBackstageUser(tag.userId, user).finally(() => {
              setEditBackstageUserModal(false)
              refresh()
            })
          }}
        />
      )}
      <Dropdown trigger="click" overlay={menu} placement="bottomRight">
        <Button className="table-action-button">
          <span>•••</span>
        </Button>
      </Dropdown>
    </>
  )
}

const VerticalActionButton = ({ menus, tag, companyId, refresh }) => {
  const [, setMeetingRequestModalVisible] = useState(false)

  const [deleteCompanyUserModalVisible, setDeleteCompanyUserModalVisible] =
    useState(false)

  const [deleteSummitUserModalVisible, setDeleteSummitUserModalVisible] =
    useState(false)

  const actionFunction = (value) => {
    value === 4 && setMeetingRequestModalVisible(true)
    if (value === 5 && tag && companyId) {
      setDeleteCompanyUserModalVisible(true)
    }
    if (value === 6 && tag) {
      setDeleteSummitUserModalVisible(true)
    }

    if (typeof value === 'string' && value.indexOf('signisas') >= 0) {
      const splits = value.split('_')
      if (splits.length > 0) {
        const contactId = Number(splits[1])
        signinas(contactId)
      }
    }
  }

  const menu = (
    <Menu className="action-menu">
      {menus.map((item, index) => [
        <Menu.Item className="action-menu-item" key={index}>
          <span
            className="action-menu-text"
            onClick={() => actionFunction(item.func)}
            style={{ color: item.color }}
          >
            <FontAwesomeIcon
              icon={item.icon}
              style={{ color: item.color || '#C2C6CC' }}
            />{' '}
            {item.link ? (
              <a
                className="action-menu-text"
                rel="noreferrer"
                href={item.link}
                target={item.newtab ? '_blank' : ''}
              >
                {item.text}
              </a>
            ) : (
              <>{item.text}</>
            )}
          </span>
        </Menu.Item>,
        index < menus.length - 1 ? (
          <Menu.Divider className="action-menu-divider" />
        ) : (
          <></>
        ),
      ])}
    </Menu>
  )
  return (
    <>
      {tag && (
        <DeleteCompanyUserModal
          visible={deleteCompanyUserModalVisible}
          contactName={`${tag.firstName} ${tag.lastName}`}
          contactId={tag.contactId}
          companyId={companyId}
          handleCancel={() => setDeleteCompanyUserModalVisible(false)}
          handleOk={() => {
            setDeleteCompanyUserModalVisible(false)
            refresh()
          }}
        />
      )}
      {tag && (
        <DeleteSummitUserModal
          visible={deleteSummitUserModalVisible}
          contactName={`${tag.firstName} ${tag.lastName}`}
          contactId={tag.contactId}
          companyEventId={tag.companyEventId}
          eventId={tag.eventId}
          handleCancel={() => setDeleteSummitUserModalVisible(false)}
          handleOk={() => {
            setDeleteSummitUserModalVisible(false)
            refresh()
          }}
        />
      )}

      <Dropdown trigger="hover" overlay={menu} placement="bottomRight">
        <Button className="table-action-button-vertical">
          <FontAwesomeIcon icon={faEllipsisV} size="2x" />
        </Button>
      </Dropdown>
    </>
  )
}

export default ActionButton

export { VerticalActionButton }
