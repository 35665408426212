import * as React from 'react'
import PropTypes from 'prop-types'
import { Select } from '@context365/forms'
import { isNil } from 'lodash'
import find from 'lodash/find'
import map from 'lodash/map'
import some from 'lodash/some'
import { getVettedAndUnvettedCompanyFunds } from '../../../../actions/funds'
import { ADD_NEW_ID } from '../../constants'

const generateFundInputId = (fund) =>
  fund?.vettedFundId && fund?.unvettedFundId
    ? 'new'
    : fund?.vettedFundId
    ? `vettedFund-${fund.vettedFundId}`
    : fund?.unvettedFundId
    ? `unvettedFund-${fund.unvettedFundId}`
    : null

const FundDropdown = ({
  vettedCompanyManagerId,
  unvettedCompanyManagerId,
  fund,
  fundStrategies,
  handleChange,
}) => {
  const [funds, setFunds] = React.useState([])

  React.useEffect(() => {
    if (
      vettedCompanyManagerId !== ADD_NEW_ID ||
      unvettedCompanyManagerId !== ADD_NEW_ID
    ) {
      getVettedAndUnvettedCompanyFunds(
        vettedCompanyManagerId === ADD_NEW_ID ? null : vettedCompanyManagerId,
        unvettedCompanyManagerId === ADD_NEW_ID
          ? null
          : unvettedCompanyManagerId
      ).then((res) => {
        const funds = res.data

        const fundsWithStrategies = map(funds, (f) => {
          return {
            ...f,
            subStrategy: find(
              fundStrategies,
              (fs) => fs.vettedFundStrategyId === f.fundStrategyId
            )?.name,
          }
        })

        fundsWithStrategies.push({
          vettedFundId: ADD_NEW_ID,
          unvettedFundId: ADD_NEW_ID,
          name: '+ Add new fund',
        })

        setFunds(fundsWithStrategies)
      })
    } else {
      setFunds([
        {
          vettedFundId: ADD_NEW_ID,
          unvettedFundId: ADD_NEW_ID,
          name: '+ Add new fund',
        },
      ])
    }
  }, [vettedCompanyManagerId, unvettedCompanyManagerId])

  React.useEffect(() => {
    if (
      fund.unvettedFundId !== ADD_NEW_ID &&
      !isNil(fund.unvettedFundId) &&
      some(funds, (f) => f.unvettedFundId !== fund.unvettedFundId)
    ) {
      setFunds([...funds, fund])
    }
  }, [fund])

  return (
    <Select
      required
      label="Name of fund"
      placeholder={
        isNil(vettedCompanyManagerId) &&
        isNil(unvettedCompanyManagerId) &&
        '+ Add new fund'
      }
      value={generateFundInputId(fund)}
      onChange={(val) => {
        const newFund = find(funds, (f) => generateFundInputId(f) === val)
        handleChange([
          { field: 'vettedFundId', value: newFund.vettedFundId },
          { field: 'unvettedFundId', value: newFund.unvettedFundId },
          { field: 'broadStrategy', value: newFund?.broadStrategy },
          { field: 'subStrategy', value: newFund?.subStrategy },
        ])
      }}
    >
      {map(funds, (x) => (
        <Select.Option
          key={generateFundInputId(x)}
          value={generateFundInputId(x)}
        >
          {x.name}
        </Select.Option>
      ))}
    </Select>
  )
}

FundDropdown.propTypes = {
  vettedCompanyManagerId: PropTypes.number,
  unvettedCompanyManagerId: PropTypes.number,
  fund: PropTypes.object,
  fundStrategies: PropTypes.array,
  handleChange: PropTypes.func,
}

export default FundDropdown
