import {
  //, useEffect, useState
  Component,
} from 'react'
import {
  faClock,
  faEnvelope,
  faUserClock,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import PropTypes from "prop-types";
//import moment from "moment";
//import { connect } from "react-redux";
import { Button, Menu, Modal, Tooltip, message } from 'antd'
import { isEmpty, isNil, map } from 'lodash'
import { switchMeetingToOffPlatform } from '../../actions/contextMeetings'
import {
  getReceivedContextList, //getReceivedOnlineList,
} from '../../actions/events'
import { getInitials } from '../../utils/helpers'
import AcceptMeetingComponent from '../AcceptMeetingComponent'
import AcceptOnlineMeeting from '../AcceptOnlineMeeting'
import AvatarGroup from '../AvatarGroup'
import CancelMeetingButton from '../CancelMeetingButton'
//import FollowUpMessages from "../FollowUpMessages/FollowUpMessages";
import ContactName from '../ContactName'
import ContextTable from '../ContextTable'
import DeclineAllSummitMeetingsModal from '../DeclineAllSummitMeetingsModal/DeclineAllSummitMeetingsModal'
import DeclineMeetingComponent from '../DeclineMeetingComponent'
import {
  //ResponsiveCard,
  CONTACT_NAME,
} from '../Responsive/ResponsiveCards/OnlineMeetingRecivedRequestResponsiveCard'
import TimeDisplay from '../TimeDisplay'
import './ReceivedRequestsTable.less'

class ReceivedRequestsTable extends Component {
  state = {
    showRescheduleOnlineMeetingModal: false,
    showAcceptSummitMeetingModal: false,
    showAcceptOnlineMeetingModal: false,
    showDeclineOnlineModal: false,
    showOffPlatformModal: false,
    showFollowUpMessages: false,
    showDeclineAllModal: false,
    // selectedRecord: {},
    refreshTable: false,
    requestsList: null,
  }

  handleRescheduleClick = (record) => {
    this.setState({
      showRescheduleOnlineMeetingModal: true,
      selectedRecord: record,
    })
  }

  handleFollowUpClick = (record) => {
    this.setState({
      showFollowUpMessages: true,
      selectedRecord: record,
    })
  }

  handleAcceptClick = (record) => {
    this.setState({
      showAcceptSummitMeetingModal:
        record.meetingType.toLowerCase() === 'summit',
      showAcceptOnlineMeetingModal:
        record.meetingType.toLowerCase() === 'online',
      selectedRecord: record,
    })
  }

  handleCancelClick = (record) => {
    this.setState({
      showDeclineOnlineModal: true,
      selectedRecord: record,
    })
  }

  handleOffPlatformClick = (record) => {
    this.setState({
      showOffPlatformModal: true,
      selectedRecord: record,
    })
  }

  closeFollowUp = (shouldRefresh) => {
    this.setState((state) => ({
      showFollowUpMessages: false,
    }))
    if (shouldRefresh) this.props.refresh(!this.state.refreshTable)
  }

  closeModal = () => {
    this.setState({
      showRescheduleOnlineMeetingModal: false,
    })
  }

  closeAcceptModal = () => {
    this.setState({
      showAcceptOnlineMeetingModal: false,
      showAcceptSummitMeetingModal: false,
    })
  }

  onOffPlatformOk = () => {
    switchMeetingToOffPlatform(this.state.selectedRecord.meetingId)
      .then(() => {
        message.success('Meeting successfully changed to off platform')
        this.props.refresh(!this.state.refreshTable)
        this.setState((state) => ({
          refreshTable: !state.refreshTable,
          showOffPlatformModal: false,
        }))
      })
      .catch(() =>
        message.error('An error has occurred while trying to update meeting')
      )
  }

  renderMember = (record) => (
    <ContactName
      name={record.member.contactName}
      imageUrl={record.member.imageURL}
      fontClass="cc-body-text"
    />
  )

  renderActions = (record) => {
    const { meetingType, contacts, companyId, type } = this.props
    return (
      <div className="cc-meeting-actions">
        {record.meetingType.toLowerCase() === 'summit' && (
          <>
            {' '}
            <AcceptMeetingComponent
              isOptimized={record?.isOptimized}
              isVirtualSummit={record?.virtualSummit}
              timezone={record?.meetingTimeZone}
              contacts={
                !isEmpty(this.state.selectedRecord)
                  ? this.state.selectedRecord.colleagues
                  : []
              }
              companyId={companyId}
              buttonType="primary"
              //carryContextPoints={this.state.selectedRecord.carryContextPoints}
              visible={this.state.showAcceptSummitMeetingModal}
              closeModal={() => {
                this.setState({
                  showAcceptSummitMeetingModal: false,
                  selectedRecord: {},
                })
              }}
              categoryName={
                !isEmpty(this.state.selectedRecord)
                  ? this.state.selectedRecord.categoryName
                  : ''
              }
              meetingType={meetingType}
              company={
                isEmpty(this.state.selectedRecord) ||
                isNil(this.state.selectedRecord.company)
                  ? ''
                  : this.state.selectedRecord.company.companyName
              }
              meetingId={
                !isEmpty(this.state.selectedRecord)
                  ? this.state.selectedRecord.meetingId
                  : 0
              }
              meetingDateTime={
                !isEmpty(this.state.selectedRecord)
                  ? this.state.selectedRecord.meetingDateTime
                  : 0
              }
              eventId={
                !isEmpty(this.state.selectedRecord)
                  ? this.state.selectedRecord.eventId
                  : 0
              }
              onAccept={() => {
                this.props.onAcceptRequest()
                this.props.refresh(!this.state.refreshTable)
                this.setState({
                  refreshTable: !this.state.refreshTable,
                })
              }}
              participants={
                isEmpty(this.state.selectedRecord)
                  ? 0
                  : this.state.selectedRecord.participants
                  ? this.state.selectedRecord.participants.map(
                      (x) => x.contactId
                    )
                  : []
              }
              toParticipants={[this.props.contacts[0].contactId]}
              initiatorRole={type}
            />
            <DeclineMeetingComponent
              contacts={
                !isEmpty(this.state.selectedRecord)
                  ? this.state.selectedRecord.colleagues
                  : []
              }
              companyId={companyId}
              //initiatorRole={type}
              visible={this.state.showDeclineOnlineModal}
              closeModal={() => {
                this.setState({
                  showDeclineOnlineModal: false,
                  selectedRecord: {},
                })
              }}
              company={
                isNil(this.state.selectedRecord) ||
                isNil(this.state.selectedRecord.company)
                  ? ''
                  : this.state.selectedRecord.company.companyName
              }
              meetingId={
                !isEmpty(this.state.selectedRecord)
                  ? this.state.selectedRecord.meetingId
                  : 0
              }
              onCancel={() => {
                this.props.onCancelRequest()
                this.props.refresh(!this.state.refreshTable)
                this.setState((state) => ({
                  refreshTable: !state.refreshTable,
                }))
              }}
              //meetingType={this.state.selectedRecord.meetingType}
            />
          </>
        )}

        {!this.props.isPast && (
          <Button
            type="primary"
            onClick={this.handleAcceptClick.bind(this, record)}
            className="cc-btn cc-btn-success"
          >
            <i className="cc-btn-bg" />
            {'Accept'}
          </Button>
        )}
        {record.meetingType === 'online' && (
          <Button
            type="primary"
            onClick={this.handleRescheduleClick.bind(this, record)}
            className="cc-btn cc-btn-notice"
          >
            <i className="cc-btn-bg" />
            {'Reschedule'}
          </Button>
        )}
        {!this.props.isPast && record.meetingType.toLowerCase() === 'summit' && (
          <Button
            onClick={this.handleCancelClick.bind(this, record)}
            className="cc-btn cc-btn-danger"
          >
            <i className="cc-btn-bg" />
            {'Decline'}
          </Button>
        )}

        {!this.props.isPast && record.meetingType.toLowerCase() === 'online' && (
          <CancelMeetingButton
            contacts={record.colleagues}
            company={record.company.companyName}
            meetingType={record.meetingType}
            cancelText="Decline"
            meetingId={record.meetingId}
            companyId={this.props.companyId}
            carryContextPoints={record.carryContextPoints}
            onCancel={() => {
              this.props.onCancelRequest()
              this.props.refresh(!this.state.refreshTable)
              this.setState((state) => ({
                refreshTable: !state.refreshTable,
              }))
            }}
            initiator={record.member}
          />
        )}
        {record.meetingType.toLowerCase() === 'online' && (
          <Button
            onClick={this.handleOffPlatformClick.bind(this, record)}
            className="cc-btn cc-btn-notice"
          >
            <i className="cc-btn-bg" />
            {'Mark Off Platform'}
          </Button>
        )}
        {this.state.showOffPlatformModal && (
          <Modal
            className="cc-modal-accept"
            title={'Off Platform Meeting'}
            okText="Mark as off platform"
            onOk={this.onOffPlatformOk}
            visible={this.state.showOffPlatformModal}
            onCancel={() => this.setState({ showOffPlatformModal: false })}
          >
            Are you sure you want to switch this to an off platform meeting?
          </Modal>
        )}
      </div>
    )
  }

  renderCompany = (record) => {
    if (!isNil(record.companies)) {
      return record.companies.map((company) => (
        <>
          {company.companyName}
          <span>{' (' + company.category + ')'}</span>
          {record.carryContextPoints && (
            <span className="cc-context-points-label">
              <span>+1000 Context Points</span>
            </span>
          )}
          <br />
        </>
      ))
    } else {
      return (
        <div>
          {record.company.companyName}
          {record.carryContextPoints && (
            <span className="cc-context-points-label">
              <span>+1000 Context Points</span>
            </span>
          )}
        </div>
      )
    }
  }

  renderParticipantsAvatars = (record) =>
    (record.allmembers && record.allmembers.length > 0 && (
      <AvatarGroup
        showInitials
        showStatusBadge
        avatars={record.allmembers.map((c) => ({
          id: c.contactId,
          img: c.imageUrl,
          description: c.contactName,
          initials: getInitials(c.contactName),
          firstName: c.firstName,
          lastName: c.lastName,
          participantStatus: c.participantStatus,
          participantStatusId: c.participantStatusId,
          position: c.jobTitle,
        }))}
        style={{ height: '24px' }}
      />
    )) || (
      <AvatarGroup
        showInitials
        avatars={map(record.participants, (c) => ({
          id: c.contactId,
          img: c.imageUrl,
          description: c.contactName,
          initials: getInitials(c.contactName),
          firstName: c.firstName,
          lastName: c.lastName,
        }))}
        style={{ height: '24px' }}
      />
    )

  getColumns = () => {
    const menu = (
      <Menu>
        <Menu.Item
          key={TimeDisplay.ORIGINAL}
          onClick={(e) =>
            this.setState({
              displayTimezone: e.key,
              displayTimezoneLabel: 'Event Timezone',
            })
          }
        >
          <FontAwesomeIcon icon={faClock} />
          <span>
            Show in <b>Event</b> Timezone
          </span>
        </Menu.Item>
        <Menu.Item
          key={TimeDisplay.LOCAL}
          onClick={(e) =>
            this.setState({
              displayTimezone: e.key,
              displayTimezoneLabel: 'Local Timezone',
            })
          }
        >
          <FontAwesomeIcon icon={faUserClock} />
          <span>
            Show in your <b>Local</b> Timezone
          </span>
        </Menu.Item>
      </Menu>
    )

    return [
      {
        title: 'REQUEST FROM',
        render: this.renderMember,
        cardType: CONTACT_NAME,
        contactDataIndex: 'member',
      },
      // {
      //   title: "MEMBERS",
      //   render: this.renderParticipantsAvatars,
      //   align: "center",
      //   contactDataIndex: "allmembers",
      //   cardType: CONTACT_NAME,
      // },
      {
        title: 'COMPANIES',
        className: 'cc-body-text',
        render: this.renderCompany,
      },

      {
        title: 'MESSAGE',
        dataIndex: 'meetingMessage',
        align: 'center',
        render: (value) =>
          isEmpty(value) ? (
            <Tooltip overlayClassName="MessageTooltip" title="No message">
              <FontAwesomeIcon size="2x" icon={faEnvelope} />
            </Tooltip>
          ) : (
            <Tooltip overlayClassName="MessageTooltip" title={value}>
              <FontAwesomeIcon size="2x" color="#2FC5D2" icon={faEnvelope} />
            </Tooltip>
          ),
      },
      {
        title: this.props.eventId ? (
          <div>
            ACTIONS{' '}
            <Button
              className="cc-btn cc-btn-danger"
              onClick={() => this.setState({ showDeclineAllModal: true })}
            >
              Decline All
            </Button>
          </div>
        ) : (
          'ACTIONS'
        ),
        render: this.renderActions,
        align: 'center',
        notRenderInCard: true,
      },
    ]
  }

  render() {
    //console.log("ev", this.props.eventParticipantId);

    // if (+width < 1025) {
    //   return (
    //     <ResponsiveCard
    //       columns={this.getColumns}
    //       requests={requestsList.result}
    //       meetingType={meetingType}
    //     />
    //   );
    // }

    return (
      <div>
        {/* <RescheduleMeeting
          visible={showRescheduleOnlineMeetingModal}
          closeModal={this.closeModal}
          onlineMeetingId={selectedRecord.meetingId}
          oginRescheduleMeeting={() => {
            this.setState(state => ({
              refreshTable: !state.refreshTable,
            }));
          }}
        /> */}

        {/* <AcceptEventMeetingModal
          contacts={this.props.contacts}
          meetingId={
            this.state.selectedRecord ? this.state.selectedRecord.meetingId : 0
          }
          visible={this.state.showAcceptSummitMeetingModal}
          closeModal={this.closeAcceptModal}
          companyId={this.props.companyId}
          eventId={this.props.eventId}
          companyContactEventId={this.props.companyEventId}
          // companyEventId={
          //   isNil(this.state.selectedRecord)
          //     ? ""
          //     : this.state.selectedRecord.companyEventId
          // }
          eventParticipantId={
            isNil(this.state.selectedRecord)
              ? ""
              : this.state.selectedRecord.eventParticipantId
          }
          onAcceptMeeting={() => {
            this.props.onAcceptRequest();
            this.props.refresh(!this.state.refreshTable);
            this.setState((state) => ({
              refreshTable: !state.refreshTable,
            }));
          }}
        />
        <FollowUpMessages
          visible={this.state.showFollowUpMessages}
          meetingId={
            this.state.selectedRecord ? this.state.selectedRecord.meetingId : 0
          }
          contactId={this.props.contacts[0].contactId}
          closeFollowUp={(shouldRefresh) => this.closeFollowUp(shouldRefresh)}
          companyId={this.props.companyId}
          contacts={this.props.contacts}
          isReceived={true}
          conversationId={
            this.state.selectedRecord
              ? this.state.selectedRecord.conversationId
              : 0
          }
        /> */}
        {this.state.showAcceptOnlineMeetingModal && (
          <AcceptOnlineMeeting
            visible={this.state.showAcceptOnlineMeetingModal}
            closeModal={this.closeAcceptModal}
            categoryName={
              this.state.selectedRecord
                ? this.state.selectedRecord.categoryName
                : ''
            }
            role={
              this.state.selectedRecord &&
              this.state.selectedRecord.categoryName
                ? this.state.selectedRecord.categoryName.toLowerCase() ==
                  'allocator'
                  ? 'allocator'
                  : 'manager'
                : ''
            }
            meetingDateTime={
              this.state.selectedRecord
                ? this.state.selectedRecord.meetingDateTime
                : null
            }
            meetingId={
              this.state.selectedRecord
                ? this.state.selectedRecord.meetingId
                : 0
            }
            companyId={this.props.companyId}
            contacts={
              this.state.selectedRecord
                ? this.state.selectedRecord.colleagues
                : []
            }
            participants={
              this.state.selectedRecord
                ? this.state.selectedRecord.colleagues
                : []
            }
            toParticipants={
              this.state.selectedRecord
                ? this.state.selectedRecord.participants
                : []
            }
            onAccept={() => {
              this.props.refresh(!this.state.refreshTable)
              this.setState((state) => ({
                refreshTable: !state.refreshTable,
              }))
            }}
            company={
              this.state.selectedRecord
                ? this.state.selectedRecord.company.companyName
                : ''
            }
            meetingStatusId={
              this.state.selectedRecord
                ? this.state.selectedRecord.meetingStatusId
                : null
            }
          ></AcceptOnlineMeeting>
        )}

        {this.state.showDeclineAllModal && (
          <DeclineAllSummitMeetingsModal
            visible={this.state.showDeclineAllModal}
            eventId={this.props.eventId}
            companyId={this.props.companyId}
            contactId={
              !isEmpty(this.props.contacts) && this.props.contacts[0]?.contactId
            }
            handleCancel={() => this.setState({ showDeclineAllModal: false })}
            handleOk={() => {
              this.props.refresh(!this.state.refreshTable)
              this.setState({
                showDeclineAllModal: false,
                refreshTable: !this.state.refreshTable,
              })
            }}
          />
        )}

        <ContextTable
          tableTitle="Meetings"
          //key="id"
          columns={this.getColumns()}
          dataMethod={(pagination, filters, sorter, searchTerm) =>
            getReceivedContextList(
              pagination,
              filters,
              sorter,
              searchTerm,
              null,
              this.props.eventParticipantId,
              this.props.fundEventId,
              this.props.eventCompanyId,
              this.props.itemId ?? 0,
              this.props.type,
              this.props.eventCompanyBoothFundId,
              this.props.eventCompanyBoothId
            )
          }
          eventParticipantId={this.props.eventParticipantId}
          fundEventId={this.props.fundEventId}
          eventCompanyId={this.props.eventCompanyId}
          fundId={this.props.fundId}
          companyContactId={this.props.companyContactId}
          companyId={this.props.companyId}
          refresh={this.props.refreshMeetings}
          refreshData={this.props.refreshMeetings}
        />
      </div>
    )
  }
}
// const mapStateToProps = (state, ownProps) => ({
//   requestsList:
//     ownProps.meetingType.toLowerCase() === 'online'
//       ? state.meetings.onlineRequestedMeetings
//       : ownProps.fromSummit
//       ? state.meetings.requestedMeetingsCompanyEventId
//       : state.meetings.requestedMeetings,
// });

// const mapDispatchToProps = {
//   fetchMeetingRequestsByCompany,
//   fetchMeetingRequestsByCompanyEventId,
// };

export default ReceivedRequestsTable
