import PropTypes from 'prop-types'

const StaticText = ({ labelText, label }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <span className="cc-heading5 cc-lightblack-text">{labelText}</span>
    <span
      style={{ padding: '16px 0px' }}
      className="cc-body-text cc-text-color-summit"
    >
      {label}
    </span>
  </div>
)

StaticText.propTypes = {
  label: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
}

export default StaticText
