import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Col, Divider, Modal, Row, Switch } from 'antd'
import { isNil } from 'lodash'
import forEach from 'lodash/forEach'
import moment from 'moment'
import numeral from 'numeral'
import FloatingInput from '../../components/FloatingInput/FloatingInput'
import FloatingInputNumber from '../../components/FloatingInputNumber/FloatingInputNumber'
import FloatingMultiSelect from '../../components/FloatingMultiSelect/FloatingMultiSelect'
import FloatingSelect from '../../components/FloatingSelect/FloatingSelect'
import http from '../../utils/api'
import CFNVisibilityEditor from '../CompanyDetails/CFNVisibilityEditor'
import DomicileSection from '../OnboardingComponents/DomicileSection'
import GenericForm from '../OnboardingComponents/GenericForm'

const DetailsModal = ({
  visible,
  handleOk,
  handleCancel,
  company,
  companyId,
  stepNumber,
  visibility,
  visibilityQuestions,
}) => {
  const [data, setData] = useState(company)
  const [testQ, setTestQ] = useState(null)
  const [allocatorQuestions, setAllocatorQuestions] = useState([])
  const [managerQuestions, setManagerQuestions] = useState([])
  const [serviceProviderQuestions, setServiceProviderQuestions] = useState([])
  const [cfnQuestions, setCFNQuestions] = useState([])
  const [answers, setAnswers] = useState(null)

  const [canSendMeetingRequest, setCanSendMeetingRequest] = useState(
    company.canSendMeetingRequest
  )

  const contactComponents = [
    { stepNumber: 5, innerStep: 4, title: 'Investments', key: 'investments' },
  ]

  useEffect(() => {
    http
      .get(`/companies/allocator/questions`)
      .then((response) => {
        setAllocatorQuestions(response.data.allocatorQuestions)
      })
      .catch((error) => {
        console.log(error)
      })

    http
      .get(`/funds/questions/fundcompanies`)
      .then((response) => {
        setManagerQuestions(response.data)
      })
      .catch((error) => {
        console.log(error)
      })

    http
      .get(`/companies/questions/serviceprovider`)
      .then((response) => {
        setServiceProviderQuestions(response.data)
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error)
      })

    if (visibility === false) {
      http
        .get(`/companies/questions/cfn/${stepNumber}/${companyId}`)
        .then((response) => {
          setCFNQuestions(response.data)
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  const updateAllocatorData = (field, value) => {
    var newState = { ...data }
    newState.companyAllocator[field] = value
    setData(newState)
  }

  const updateManagerData = (field, value) => {
    var newState = { ...data }
    newState.companyManager[field] = value
    setData(newState)
  }

  const updateServiceProviderData = (field, value) => {
    var newState = { ...data }
    newState.companyServiceProvider[field] = value
    setData(newState)
  }

  const updateCFNData = (field, value) => {
    var newState = { ...data }
    newState.companyCFN[field] = value
    setData(newState)
  }

  const saveChanges = () => {
    if (data.companyCFN && visibility === false) {
      if (!isNil(answers)) {
        http
          .post(`/companies/company/${stepNumber}/${companyId}`, answers)
          .then((response) => {
            handleOk()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
    if (data.companyCFN && visibility === true) {
      if (!isNil(answers)) {
        http
          .post(`/companies/cfn/visibility/company/${companyId}/0/0`, answers)
          .then((response) => {
            handleCancel()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
    http
      .put(`/companies/edit/${companyId}`, data)
      .then((response) => {
        handleOk()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onClose = () => {
    setData(company)
    handleCancel()
  }

  const handleMultiSelectChange = (dataField, e, updateFunction) => {
    console.log('dataField', dataField)
    console.log('e', e)
    if (dataField === 'secondaryInvestorCategory') {
      if (e.length > 2) return
    }

    if (e.length > 0) updateFunction(dataField, e.join(','))
    else updateFunction(dataField, null)
  }

  const handleDomicileChange = (dataField, e, updateFunction) => {
    if (e.length > 0) updateFunction(dataField, e)
    else updateFunction(dataField, null)
  }

  const checkChildVisibility = useCallback(
    (question) => {
      if (cfnQuestions) {
        const attributes = JSON.parse(question.attributes)
        const dependentQuestion = cfnQuestions.find(
          (x) => x.questionID == attributes.dependentOnQuestion
        )
        if (dependentQuestion) {
          const selectedAnswers = dependentQuestion.answer
          if (selectedAnswers) {
            const answers = selectedAnswers.split(',')
            const answerIds = []
            forEach(answers, (a) => {
              const answerId = dependentQuestion.questionAnswers.find(
                (x) => x.questionAnswer === a
              )
              if (answerId) answerIds.push(`${answerId.questionAnswerID}`)
            })
            const hasDependentAnswers = answerIds.includes(
              attributes.dependentOnQuestionAnswer
            )
            return hasDependentAnswers
          }
        }
      }
      return false
    },
    [cfnQuestions]
  )

  const isLastChild = useCallback(
    (question) => {
      if (cfnQuestions) {
        const attributes = JSON.parse(question.attributes)
        const dependentQuestion = cfnQuestions.find(
          (x) => x.questionID == attributes.dependentOnQuestion
        )
        if (dependentQuestion) {
          if (dependentQuestion && dependentQuestion.questionTypeID === 23)
            return true
        }
      }
      return false
    },
    [cfnQuestions]
  )

  const onQuestionChange = useCallback(
    (questionTypeID, questionID, shortName, e) => {
      const value = isNil(e.target) ? e : e.target.value
      if (shortName === 'SecondaryInvestorCategory') {
        if (value.length > 2) return
      }
      const currentQuestion = cfnQuestions.find(
        (x) => x.questionID === questionID
      )
      if (
        questionTypeID === 5 ||
        questionTypeID === 6 ||
        shortName === 'MaxPercentageOfAFundsAUMTheyWouldBeComfortableComprising'
      ) {
        if (!numeral.validate(value) && value !== '') {
          currentQuestion.tooltip = 'Numeric values allowed only'
        } else {
          currentQuestion.answer =
            value === '' ? value : numeral(value).format('0')
          currentQuestion.tooltip = null
        }
      } else if (questionTypeID === 3 || questionTypeID === 23) {
        currentQuestion.answer = value.join(',')
      } else if (questionTypeID === 24 || questionTypeID === 27) {
        currentQuestion.answer =
          value === '' || isNil(value) ? null : numeral(value).format('0')
      } else {
        currentQuestion.answer = value
        currentQuestion.tooltip = null
      }
      currentQuestion.currentlyValid = currentQuestion.validation(value)
      currentQuestion.isValid = currentQuestion.validation(value)
      currentQuestion.tooltip = null
      setCFNQuestions(
        cfnQuestions.map((x) =>
          x.questionID === questionID ? currentQuestion : x
        )
      )
      //checkAllQuestions();
    },
    [cfnQuestions]
  )

  const getGroupQuestionLabel = useCallback((question) => {
    const attributes = JSON.parse(question.attributes)
    if (attributes.groupName) {
      return attributes.groupName
    }
  }, [])

  const getGroupQuestions = useCallback(
    (question) => {
      const attributes = JSON.parse(question.attributes)
      const groupQuestions = [question]
      if (attributes.groupName) {
        const childQuestions = cfnQuestions.filter(
          (x) => x.questionTypeID === 27
        )
        forEach(childQuestions, (child) => {
          const childAttributes = JSON.parse(child.attributes)
          if (
            childAttributes.groupName &&
            childAttributes.groupName === attributes.groupName
          )
            groupQuestions.push(child)
        })
      }
      return groupQuestions
    },
    [cfnQuestions]
  )

  const onChange = useCallback((answer) => {
    setAnswers(answer)
    console.log(answer)
  }, [])

  const questionRows = (type, questions) => {
    var companyField = 'companyAllocator'
    var questionField = 'companyAllocatorQuestionAnswers'
    var update = updateAllocatorData

    if (type === 'manager') {
      companyField = 'companyManager'
      questionField = 'managerCompanyQuestionAnswers'
      update = updateManagerData
    }

    if (type === 'serviceprovider') {
      companyField = 'companyServiceProvider'
      questionField = 'managerCompanyQuestionAnswers'
      update = updateServiceProviderData
    }

    if (type === 'cfn') {
      companyField = 'companyCFN'
      questionField = 'cfnCompanyQuestionAnswers'
      update = updateCFNData
    }

    if (type === 'cfn' && visibility === true) {
      return (
        <CFNVisibilityEditor values={visibilityQuestions} onChange={onChange} />
      )
    }

    if (type === 'cfn' && visibility === false) {
      return (
        <GenericForm
          stepNumber={0}
          onChange={onChange}
          currentQuestions={questions}
          step={{
            innerStep: 5,
            title: 'Investments',
            renderAction: questions,
            customRender: true,
            customRenderParameter: 5,
          }}
          fromSettings={true}
        ></GenericForm>
      )
    }

    return questions.map((q, index) => {
      var dataField =
        q.shortName.substring(0, 1).toLowerCase() + q.shortName.substring(1)
      if (q.shortName === 'CIK') {
        dataField = 'cik'
      }
      if (q.shortName === 'CRD') {
        dataField = 'crd'
      }
      if (q.shortName === 'FirmAUM') {
        dataField = 'firmAum'
      }
      var answers = []

      if (q.questionTypeId === 7) {
        answers = [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ]
        return (
          <Col span={24}>
            <FloatingSelect
              value={data[companyField][dataField]}
              name={q.name}
              nameField={q.shortName}
              options={answers}
              onChange={(e) => {
                update(dataField, e)
              }}
            />
          </Col>
        )
      } else if (q.questionTypeId === 3) {
        answers = q[questionField].map((item, index) => {
          return { text: item.answer, value: item.answer }
        })
        let checked = data[companyField][dataField]

        if (checked !== null && checked !== undefined) {
          checked = checked.split(',')
        }

        return (
          <Col span={24}>
            <FloatingMultiSelect
              value={checked}
              name={q.name}
              nameField={q.shortName}
              options={answers}
              onChange={(e) => {
                console.log('dataField', dataField)
                console.log('e', e)
                handleMultiSelectChange(dataField, e, update)
              }}
            />
          </Col>
        )
      } else if (
        q.questionTypeId === 15 &&
        q.shortName === 'FundDomicileInterest'
      ) {
        answers = q[questionField].map((item, index) => {
          return { text: item.answer, value: item.answer }
        })
        const checked = data[companyField][dataField]

        // if (checked !== null && checked !== undefined) {
        //   checked = checked.split(",");
        // }
        return (
          <DomicileSection
            onChange={(e) => {
              handleDomicileChange(dataField, e, update)
            }}
            value={checked}
          />
        )
      } else if (q.questionTypeId === 4) {
        answers = q[questionField].map((item, index) => {
          return { text: item.answer, value: item.answer }
        })

        return (
          <Col span={24}>
            <FloatingSelect
              value={data[companyField][dataField]}
              name={q.name}
              nameField={q.shortName}
              options={answers}
              onChange={(e) => {
                update(dataField, e)
              }}
            />
          </Col>
        )
      } else if (q.questionTypeId === 5) {
        return (
          <Col span={24}>
            <FloatingInputNumber
              value={data[companyField][dataField]}
              name={q.name}
              nameField={q.shortName}
              min={0}
              type="currency"
              onChange={(e) => {
                update(dataField, e)
              }}
            />
          </Col>
        )
      } else if (q.questionTypeId === 6) {
        return (
          <Col span={24}>
            <FloatingInputNumber
              value={data[companyField][dataField]}
              name={q.name}
              nameField={q.shortName}
              min={0}
              max={100}
              type="percentage"
              onChange={(e) => {
                update(dataField, e)
              }}
            />
          </Col>
        )
      } else if (q.questionTypeId === 1) {
        return (
          <Col span={24}>
            <FloatingInput
              value={data[companyField][dataField]}
              name={q.name}
              nameField={q.shortName}
              type="string"
              onChange={(e) => {
                update(dataField, e)
              }}
            />
          </Col>
        )
      }
    })
  }

  const onCanSendMRChange = (record, e) => {
    var newState = { ...data }
    newState.canSendMeetingRequest = e
    setData(newState)
  }

  return (
    <Modal
      className="cc-modal-accept"
      title={`Edit Company Details`}
      visible={visible}
      okText={`Save Changes`}
      cancelText="Close"
      onOk={saveChanges}
      onCancel={onClose}
      okButtonProps={{
        disabled: false,
        style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
      }}
      centered={true}
      cancelButtonProps={{ disabled: false }}
    >
      <div style={{ padding: '14px 32px 0px 32px' }}>
        <div>
          <Row>
            {data.companyAllocator &&
              questionRows('allocator', allocatorQuestions)}
            {data.companyManager && questionRows('manager', managerQuestions)}
            {data.companyCFN && questionRows('cfn', cfnQuestions)}
            {data.companyServiceProvider &&
              questionRows('serviceprovider', serviceProviderQuestions)}
            {data.companyServiceProvider && (
              <div>
                <Col span={12}>Can Send Meeting Request:</Col>
                <Col span={12}>
                  <Switch
                    onChange={onCanSendMRChange.bind(null, data)}
                    className="schedule-day-switch"
                    size="large"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={data.canSendMeetingRequest}
                  ></Switch>
                </Col>
              </div>
            )}
          </Row>
        </div>
      </div>
    </Modal>
  )
}

DetailsModal.GeneralInfoModal = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
}

export default DetailsModal
