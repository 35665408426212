import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse } from 'antd'
import { isEmpty, isNil, map } from 'lodash'
import moment from 'moment'
import http from '../../utils/api'
import LoadingGif from '../LoadingGif'
import './MeetingQuestionAnswer.less'

const MeetingQuestionAnswer = ({
  meetingId,
  acceptingRequest = false,
  companyName = '',
  meetingDateTime = null,
}) => {
  const { Panel } = Collapse
  const [answers, setAnswers] = useState(null)
  const [loading, setLoading] = useState(false)
  const [hasAnswers, setHasAnswers] = useState(false)

  useEffect(() => {
    setLoading(true)
    http
      .get(`/contextmeetings/questions/answers/${meetingId}`)
      .then((response) => {
        setAnswers(response.data)
        if (!isEmpty(response.data)) {
          const res = response.data.filter((x) => x.answer !== '')
          setHasAnswers(res.length > 0)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [meetingId])

  return (
    <div className="MeetingAnswers-Container">
      {(isEmpty(answers) || !hasAnswers) && !loading ? (
        acceptingRequest ? (
          <p>
            Are you sure you want to accept the online meeting with{' '}
            {companyName}
            {moment(meetingDateTime).isValid() && !isNil(meetingDateTime)
              ? ` on
           ${moment
             .utc(meetingDateTime)
             .local()
             .format('MMMM D, YYYY [at] h:mm A')}?`
              : '?'}
          </p>
        ) : (
          <div></div>
        )
      ) : (
        <LoadingGif spinning={loading}>
          {hasAnswers && (
            <div>
              <div
                style={{ marginBottom: '16px' }}
                className="online-meeting-date-time-text"
              >
                Answers
              </div>
              {map(
                answers,
                (a) =>
                  a.answer !== '' && (
                    <Collapse
                      bordered={false}
                      expandIconPosition="right"
                      className="MeetingAnswers-CollapseHearder"
                      key={a.question}
                      expandIcon={({ isActive }) => (
                        <FontAwesomeIcon
                          icon={isActive ? faAngleUp : faAngleDown}
                          className="MeetingAnswers-ExpandIcon"
                        />
                      )}
                    >
                      <Panel
                        key={a.question}
                        header={a.question}
                        className="MeetingAnswers-QuestionHeader"
                      >
                        <div className="MeetingAnswers-AnswerBody">
                          {a.answer}
                        </div>
                      </Panel>
                    </Collapse>
                  )
              )}
            </div>
          )}
        </LoadingGif>
      )}
    </div>
  )
}

MeetingQuestionAnswer.propTypes = {
  meetingId: PropTypes.number.isRequired,
  acceptingRequest: PropTypes.bool,
  companyName: PropTypes.string,
}

export default MeetingQuestionAnswer
