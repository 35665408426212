import http from '../utils/api'

export const deleteAnnouncement = (announcementId) =>
  http.delete(`/announcements/${announcementId}`)

export const getAnnouncementList = (pagination, filters, sorter, searchTerm) =>
  http.post('/companies/announcements', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })
