import { useEffect, useState } from 'react'
import { Col, Divider, Row, Spin, Table } from 'antd'
import loaderImage from '../../assets/context_loader.gif'
import http from '../../utils/api'

const Charts = () => {
  const [charts, setCharts] = useState([])
  const [loading, setLoading] = useState(false)

  const getCharts = () => {
    setLoading(true)
    http
      .get('/charts')
      .then((res) => setCharts(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => getCharts(), [])

  const imageChart = (chartUrl) => (
    <>
      <Row>
        <Col span={24} style={{ background: 'white', textAlign: 'center' }}>
          <img src={chartUrl} alt="chart" />
        </Col>
      </Row>
      <Divider />
    </>
  )

  const jsonChart = (jsonData) => {
    const columns = jsonData.schema.fields.map((x) => ({
      title: x.name,
      dataIndex: x.name,
      key: x.name,
    }))
    const dataSource = jsonData.data

    return (
      <>
        <Row>
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </Row>
        <Divider />
      </>
    )
  }

  const htmlChart = (chartHtml) => (
    <>
      <Row style={{ background: 'white', textAlign: 'center' }}>
        <iframe
          title="Chart"
          src={chartHtml}
          width="100%"
          height="500px"
          frameBorder="0"
        />
      </Row>
      <Divider />
    </>
  )

  const mapCharts = () =>
    charts.map((c) => {
      if (c.type === 'Image') return imageChart(c.chart)
      else if (c.type === 'Json') return jsonChart(c.chart)
      else if (c.type === 'Html') return htmlChart(c.chart)
      else return null
    })

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Spin
          size="large"
          spinning={loading}
          indicator={
            <img
              style={{ marginLeft: '-50px', width: '100px', height: '100px' }}
              src={loaderImage}
              alt="loading"
            />
          }
        />
      </div>
      {mapCharts()}
    </>
  )
}

export default Charts
