import http from '../utils/api'

export const getFundAllocatorEngagement = (
  companyId,
  days,
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post(`/funds/${companyId}/allocatorengagement/${days}`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getFundHealthScores = (
  companyId,
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post(`/funds/healthscore/${companyId}`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getFundsByCompanyContact = (companyContactId) =>
  http.get(`/funds/companycontact/${companyContactId}`)

export const getFundCompliance = (fundId) =>
  http.get(`/funds/${fundId}/compliance`)

export const updateFundCompliance = (fundId, compliance) =>
  http.post(`funds/${fundId}/compliance`, compliance)

export const getVettedAndUnvettedCompanyFunds = (
  vettedCompanyManagerId,
  unvettedCompanyManagerId
) =>
  http.get('/funds/all', {
    params: {
      vettedCompanyManagerId,
      unvettedCompanyManagerId,
    },
  })

export const getAllStrategyOptions = () => http.get('/funds/strategies/options')
