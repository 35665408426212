import { useState } from 'react'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import { getContentEntryList } from '../../actions/contentEntries'
import { getContentEntryColumns } from '../../utils/columns'
import ContextTable from '../ContextTable/ContextTable'

const defaultSort = {
  order: 'descend',
  field: 'publishDateTimeUtc',
  columnKey: 'publishDateTimeUtc',
}

const ContentEntries = () => {
  const [refresh, setRefresh] = useState(false)

  const history = useHistory()

  const refreshData = () => {
    setRefresh(!refresh)
  }

  const columns = getContentEntryColumns(refreshData)

  return (
    <>
      <Row style={{ paddingBottom: '16px' }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            size="large"
            onClick={() => history.push('contententries/new')}
          >
            <span>
              <FontAwesomeIcon icon={faPlus} />
              {'  '}Add Content
            </span>
          </Button>
        </Col>
      </Row>
      <ContextTable
        tableTitle="Content"
        columns={columns}
        dataMethod={getContentEntryList}
        sorter={defaultSort}
        refresh={refresh}
        refreshData={refresh}
      />
    </>
  )
}

ContentEntries.propTypes = {}

export default ContentEntries
