import PropTypes from 'prop-types'
import { isEmpty, isNil, trim } from 'lodash'
import { validURL } from '../../../utils/helpers'
import BetterFloatingInput from '../../BetterFloatingInput'

const LinkField = ({ item, onItemChanged, itemIndex }) => (
  <div className="LinkField-container">
    <div className="LinkField-fields-column">
      <div className="LinkField-fields-upper">
        <div className="LinkField-title">
          <BetterFloatingInput
            onChange={(e) => {
              onItemChanged(itemIndex, 'title', e.target.value)
            }}
            value={item.title}
            isValid={!isEmpty(trim(item.title)) && !isEmpty(trim(item.url))}
            validationMessage="Both title and url are required"
            name="Title"
          />
        </div>
        <div className="LinkField-url">
          <BetterFloatingInput
            onChange={(e) => {
              onItemChanged(itemIndex, 'url', e.target.value)
            }}
            isValid={validURL(item.url)}
            validationMessage="Please enter a valid url"
            value={item.url}
            name="Url"
          />
        </div>
      </div>
    </div>
  </div>
)

LinkField.propTypes = {
  item: PropTypes.object.isRequired,
  onItemChanged: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
}
export default LinkField
