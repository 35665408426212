import { RESET_APP } from '../constants/types'
import {
  ADD_SIGN_IN_AS,
  REMOVE_SIGN_IN_AS,
  SIGNIN_USER,
  SIGNOUT_USER,
  SWITCH_COMPANY,
} from '../constants/types/auth'
import {
  getEmail, //getCompany,
  getUserId,
} from '../utils'
import http from '../utils/api'

export const signInUser = ({ accessToken, refreshToken }) => {
  const userId = getUserId(accessToken)
  const email = getEmail(accessToken)
  return {
    type: SIGNIN_USER,
    payload: {
      accessToken,
      refreshToken,
      userId,
      email,
    },
  }
}

export const signOutUser = () => ({
  type: SIGNOUT_USER,
  payload: {
    accessToken: null,
    refreshToken: null,
  },
})

export const reset = () => ({
  type: RESET_APP,
})

export const switchCompany = (companyId, role) => ({
  type: SWITCH_COMPANY,
  payload: {
    companyId,
    role,
  },
})

export const login = (userId, token) => async (dispatch) => {
  const response = await http.post('/auth/login', { userId, token })
  dispatch(signInUser(response.data))
  //const { companyId, role } = getCompany(response.data.result.accessToken);
  //dispatch(switchCompany(companyId, role));
}

export const logout = () => (dispatch) => {
  dispatch(signOutUser())
  dispatch(reset())
  // dispatch(switchCompany(0, null));
}

export const addSignInAsHandle = (handle) => ({
  type: ADD_SIGN_IN_AS,
  payload: handle,
})

export const removeSignInAsHandles = () => ({
  type: REMOVE_SIGN_IN_AS,
})
