import PropTypes from 'prop-types'
import { Avatar } from '@context365/avatar'
import { Button, IconButton } from '@context365/button'
import { Input, Option, Select, TextArea } from '@context365/forms'
import { Cancel, CheckCircle, Edit, OpenInNew } from '@context365/icons'
import produce from 'immer'
import map from 'lodash/map'
import { getInitials } from '../../../utils'
import { signinas as signInAs } from '../../ActionButton/ActionButton'

const ViewContact = ({ contact, onEdit }) => (
  <div className="flex space-between gap-4">
    <IconButton
      icon={<Edit className="text-primary-110" />}
      label="edit"
      variant="link"
      onClick={() => onEdit()}
    />
    <Avatar
      profileImageUrl={contact.avatarImageUrl}
      initials={getInitials(contact.name)}
    />
    <div className="type-body-semibold-sm text-primary-100">{contact.name}</div>
    <div className="text-primary-100">{contact.jobTitle}</div>
    {contact.hasOnboarded ? (
      <div className="flex space-between type-body-semibold-sm text-green-100">
        <CheckCircle />
        <div>Onboarded</div>
      </div>
    ) : (
      <div className="flex space-between type-body-semibold-sm text-red-100">
        <Cancel />
        <div>Not Onboarded</div>
      </div>
    )}
    <Button
      variant="link"
      onClick={() => signInAs(contact.contactId)}
      iconRight={<OpenInNew />}
    >
      Sign In As
    </Button>
  </div>
)

const EditContact = ({
  contact,
  jobLevels,
  jobFunctions,
  handleChange,
  onSave,
}) => (
  <>
    <div className="grid grid-cols-2 gap-6">
      <Input label="Name" value={contact.name} disabled />
      <Avatar
        profileImageUrl={contact.avatarImageUrl}
        initials={getInitials(contact.name)}
      />
      <Input label="Job Title" value={contact.jobTitle} disabled />
      <Select value={contact.jobFunctionId} label="Job Function" disabled>
        {map(jobFunctions, (jf) => (
          <Option value={jf.id}>{jf.name}</Option>
        ))}
      </Select>
      <Select value={contact.jobLevelId} label="Job Level" disabled>
        {map(jobLevels, (jl) => (
          <Option value={jl.id}>{jl.name}</Option>
        ))}
      </Select>
      <Input label="Phone Number" value={contact.phoneNumber} disabled />
      <Input label="Email" value={contact.Email} disabled />
      <TextArea
        className="col-start-1"
        label="Bio"
        value={contact.biography}
        rows={5}
        onChange={(e) => handleChange('biography', e.target.value)}
      />
    </div>
    <Button variant="filled" className="col-start-1" onClick={onSave}>
      Close Contact Editor
    </Button>
  </>
)

const People = ({ contacts = [], updateContacts, jobLevels, jobFunctions }) => {
  const handleChange = (index, field, val) => {
    const newContacts = produce(contacts, (newState) => {
      newState[index][field] = val
    })
    updateContacts(newContacts)
  }

  return (
    <>
      {map(contacts, (contact, i) => (
        <>
          <div className="type-subtitle-sm text-primary-90">
            Contact {i + 1}
          </div>
          {contact.isEdit ? (
            <EditContact
              contact={contact}
              jobLevels={jobLevels}
              jobFunctions={jobFunctions}
              handleChange={(field, val) => handleChange(i, field, val)}
              onSave={() => handleChange(i, 'isEdit', false)}
            />
          ) : (
            <ViewContact
              contact={contact}
              onEdit={() => handleChange(i, 'isEdit', true)}
            />
          )}
        </>
      ))}
    </>
  )
}

People.propTypes = {
  contacts: PropTypes.array,
  updateContacts: PropTypes.func.isRequired,
  jobLevels: PropTypes.array,
  jobFunctions: PropTypes.array,
}

export default People
