import PropTypes from 'prop-types'
import './CountDetails.less'

const CountDetails = ({ count, label }) => (
  <span className="cc-count-details">
    <span className="cc-count-details-label">{label}</span>
    <span className="cc-count-details-count">{count}</span>
  </span>
)

CountDetails.propTypes = {
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
}

export default CountDetails
