import { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faClock,
  faPencil,
  faTrash,
  faUserClock,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Menu } from 'antd'
import moment from 'moment'
import DeleteModal from '../../components/DeleteModal/DeleteModal'
import TimeDisplay from '../TimeDisplay'
import AgendaDayDetails from './AgendaDayDetails'
import ScheduleTable from './ScheduleTable'
import './AgendaDay.less'

function AgendaDay(props) {
  const [showNewAgendaModal, setShowNewAgendaModal] = useState(false)
  const [agendaId, setAgendaId] = useState(0)
  const [agenda, setAgenda] = useState(null)

  const [deleteAgendaModal, setDeleteAgendaModal] = useState(false)

  const [displayTimezone, setDisplayTimezone] = useState(TimeDisplay.ORIGINAL)

  const [displayTimezoneLabel, setDisplayTimezoneLabel] =
    useState('Event Timezone')

  const renderSpeakers = (record) => (
    <div className="agenda-day-speakers-container">
      {record === null
        ? '-'
        : record
            //  .filter(s => s.agendaSpeakerId != 0)
            .map((x) => renderSpeakerRole(x))}
    </div>
  )

  const renderSpeakerRole = (speakerRole) => (
    <div className="agenda-day-speaker-role">
      <span className="agenda-day-speaker-role-title">
        {speakerRole.speakerRoleName}
      </span>
      <div className="agenda-day-speakers-people-container">
        {renderSpeakerPerson(speakerRole)}
      </div>
    </div>
  )

  const renderSpeakerPerson = (person) => (
    <div className="agenda-day-speakers-person">
      <span className="cc-body-text agenda-day-speakers-person-name">
        {person.speakerName}
      </span>
      <span className="cc-small-font agenda-day-speakers-person-title">
        {person.position}
      </span>
    </div>
  )

  const getColumns = () => {
    const menu = (
      <Menu>
        <Menu.Item
          key={TimeDisplay.ORIGINAL}
          onClick={(e) => {
            setDisplayTimezone(e.key)
            setDisplayTimezoneLabel('Event Timezone')
          }}
        >
          <FontAwesomeIcon icon={faClock} />
          <span>
            Show in <b>Event</b> Timezone
          </span>
        </Menu.Item>
        <Menu.Item
          key={TimeDisplay.LOCAL}
          onClick={(e) => {
            setDisplayTimezone(e.key)
            setDisplayTimezoneLabel('Local Timezone')
          }}
        >
          <FontAwesomeIcon icon={faUserClock} />
          <span>
            Show in your <b>Local</b> Timezone
          </span>
        </Menu.Item>
      </Menu>
    )

    return [
      {
        title: () => (
          <Dropdown overlay={menu}>
            <span className="agenda-day-timezone-column">
              TIME ({displayTimezoneLabel})
            </span>
          </Dropdown>
        ),
        render: (record) => (
          <>
            <TimeDisplay
              time={record.startDate}
              timezone={props.eventTimezone}
              displayInTimezone={displayTimezone}
              displayFormat="h:mm A"
            />
            <span> - </span>
            <TimeDisplay
              time={record.endDate}
              timezone={props.eventTimezone}
              displayInTimezone={displayTimezone}
              displayFormat="h:mm A"
            />
          </>
        ),
        width: '20%',
      },
      {
        title: 'EVENT',
        dataIndex: 'title',
        width: '25%',
      },
      {
        title: 'LOCATION',
        dataIndex: 'location',
        width: '20%',
      },
      {
        title: 'SPEAKERS',
        render: renderSpeakers,
        dataIndex: 'speakers',
        width: '35%',
      },
      {
        render: (record) => (
          <>
            <Button
              style={{ backgroundColor: '#2FC5D2', color: 'white' }}
              onClick={(e) => {
                e.stopPropagation()
                record.startDate = moment
                  .utc(record.startDate)
                  .tz(props.eventTimezone)
                record.endDate = moment
                  .utc(record.endDate)
                  .tz(props.eventTimezone)

                setAgendaId(record.eventAgendaId)
                setAgenda(record)
                setShowNewAgendaModal(true)
              }}
            >
              <span>
                <FontAwesomeIcon icon={faPencil} />
                {/* {'Edit'} */}
              </span>
            </Button>
          </>
        ),
        width: '5%',
      },
      {
        render: (record) => (
          <>
            <Button
              style={{ backgroundColor: '#D62B34', color: 'white' }}
              onClick={(e) => {
                e.stopPropagation()
                setAgendaId(record.eventAgendaId)
                setAgenda(record)
                setDeleteAgendaModal(true)
              }}
            >
              <span>
                <FontAwesomeIcon icon={faTrash} />
              </span>
            </Button>
          </>
        ),
        width: '5%',
      },
    ]
  }

  return (
    <>
      <ScheduleTable
        pagination={false}
        dataSource={props.items}
        columns={getColumns()}
        tableTitle={`${props.dayText}’s Agenda`}
        searchVisible={false}
        eventId={props.eventId}
        eventTimezone={props.eventTimezone}
        handleDataRefresh={props.handleAgendaData}
        speakerRoles={props.speakerRoles}
      />

      {showNewAgendaModal && (
        <AgendaDayDetails
          visible={true}
          agendaId={agendaId}
          eventId={props.eventId}
          agenda={agenda}
          handleCancel={() => {
            setShowNewAgendaModal(false)
            setAgendaId(0)
            setAgenda(null)
          }}
          handleOk={() => {
            setShowNewAgendaModal(false)
            setAgendaId(0)
            setAgenda(null)
          }}
          handleDataRefresh={props.handleAgendaData}
          modalTitle={'Edit Agenda'}
          speakerRoles={props.speakerRoles}
          eventTimezone={props.eventTimezone}
        />
      )}

      {deleteAgendaModal && (
        <DeleteModal
          visible={deleteAgendaModal}
          deleteEndPoint={`/events/agenda/${agendaId}/delete`}
          deleteSuccessMessage={'Agenda deleted successfully'}
          modalTitle={'Delete Agenda'}
          modalOkText={'Delete Agenda'}
          modalBody={`${agenda.title}`}
          handleCancel={() => setDeleteAgendaModal(false)}
          handleOk={() => {
            setDeleteAgendaModal(false)
            props.handleAgendaData()
          }}
        />
      )}
    </>
  )
}

export default AgendaDay
