import React, { useEffect, useState } from 'react'
import { faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Col, Dropdown, Menu, Row, message } from 'antd'
import './DragAndDrop.less'

function DragNDropContacts({ data, dataChanged, participants, companyId }) {
  const [list, setList] = useState(data)
  const [contactMenu, setContactMenu] = useState(null)

  useEffect(() => {
    setList(data)
  }, [setList, data])

  const showContactsAvailable = (index) => {
    const scheduleContacts = list[index].items.fundEvent.eventFundParticipants
    const menuContacts = []
    participants.map((value) => {
      if (
        scheduleContacts.findIndex(
          (p) => p.eventParticipant.contact.contactId == value.contactId
        ) === -1
      ) {
        menuContacts.push(value)
      }
    })

    if (menuContacts.length <= 0) {
      message.warning('All contacts are added to this fund', 3)
      return <></>
    } else {
      return (
        <Menu
          style={{ maxWidth: '286px' }}
          onClick={(e) => addNewUserFromList(index, parseInt(e.key))}
        >
          {menuContacts.map((item) => (
            <Menu.Item key={item.contactId}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={3}>
                  <Avatar
                    src={item.imageURL}
                    style={{
                      fontSize: '14px',
                      textAlign: 'center',
                      verticalAlign: 'bottom',
                    }}
                    shape="circle"
                    size={32}
                  >
                    {item.contactName}
                  </Avatar>
                </Col>
                <Col span={21}>
                  <div className="contactName">{item.contactName}</div>
                  <div className="contactDetail">
                    {item.jobTitle != null && item.jobTitle.length > 30
                      ? `${item.jobTitle.substring(0, 30)}...`
                      : item.jobTitle}
                  </div>
                </Col>
              </Row>
            </Menu.Item>
          ))}
        </Menu>
      )
    }
  }

  const addNewUserFromList = (fundIndex, contactId) => {
    const modifiedArray = [...list]
    const contactToAdd = participants.find((x) => x.contactId == contactId)
    contactToAdd['imageUrl'] = contactToAdd.imageURL
    contactToAdd['companyId'] = companyId
    const eventFundParticipantToAdd = {
      eventFundParticipantId: -1,
      eventParticipantId: -1,
      fundEventId: -1,
      eventParticipant: { contact: contactToAdd },
      companyContactId: -1,
    }
    modifiedArray[fundIndex].items.fundEvent.eventFundParticipants.push(
      eventFundParticipantToAdd
    )
    setList(modifiedArray)
  }

  const removeContactFromSchedule = (index, contactId) => {
    const modifiedArray = [...list]
    const indexToRemove = data[
      index
    ].items.fundEvent.eventFundParticipants.findIndex(
      (x) => x.eventParticipant.contact.contactId === contactId
    )
    if (modifiedArray[index].items.fundEvent.eventFundParticipants.length > 1)
      modifiedArray[index].items.fundEvent.eventFundParticipants.splice(
        indexToRemove,
        1
      )
    else message.warning('Fund must have at least 1 participant')
    setList(modifiedArray)
  }

  return (
    <Row style={{ overflowX: 'auto', display: 'flex', paddingBottom: '10px' }}>
      {list &&
        list.map((grp, grpI) => (
          <Col key={grp.title} className="dnd-group" span={7}>
            {grp.items && grp.items.fundEvent && (
              <>
                <p className="group-title">{grp.items.fundEvent.fund.name}</p>
                {grp.items.fundEvent.eventFundParticipants.map(
                  (item, itemI) => (
                    <div key={item} className="dnd-item">
                      {item && (
                        <>
                          <Col span={3}>
                            <Avatar
                              src={item.eventParticipant.contact.imageUrl}
                              style={{
                                fontSize: '14px',
                                textAlign: 'center',
                                verticalAlign: 'bottom',
                              }}
                              shape="circle"
                              size={32}
                            >
                              {item.eventParticipant.contact.imageUrl}
                            </Avatar>
                          </Col>
                          <Col span={17}>
                            <Row
                              type="flex"
                              justify="space-between"
                              align="middle"
                            >
                              <div className="contactName">
                                {`${item.eventParticipant.contact.firstName} ${item.eventParticipant.contact.lastName}`}
                              </div>
                            </Row>
                            <Row
                              type="flex"
                              justify="space-between"
                              align="middle"
                            >
                              <div className="contactDetail">
                                {item.eventParticipant.contact.jobTitle}
                              </div>
                            </Row>
                          </Col>
                          <Col span={3} style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              onClick={() => {
                                removeContactFromSchedule(
                                  grpI,
                                  item.eventParticipant.contact.contactId
                                )
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </div>
                  )
                )}
                <Row>
                  <Dropdown overlay={contactMenu} trigger={['click']}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => {
                        setContactMenu(showContactsAvailable(grpI))
                        e.preventDefault()
                      }}
                      style={{ fontWeight: 600, paddingTop: '6px' }}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add new
                    </a>
                  </Dropdown>
                </Row>
              </>
            )}
          </Col>
        ))}
    </Row>
  )
}

export default DragNDropContacts
