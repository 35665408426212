import jwtDecode from 'jwt-decode'
import find from 'lodash/find'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import moment from 'moment'
import { createSelector } from 'reselect'
import { getInitials } from '../utils'

const accessToken = (state) => state.auth.accessToken
const companyId = (state) => state.user.companyId
const contact = (state) => state.auth.contact

export const getCurrentUser = createSelector(accessToken, (token) =>
  token === null ? null : jwtDecode(token)
)

export const tokenExpiryDate = createSelector(getCurrentUser, (user) =>
  user === null
    ? moment().utc().subtract(1, 'year')
    : moment.utc((user.exp || 0) * 1000)
)

export const getUserFullname = createSelector(getCurrentUser, (user) =>
  user === null ? '' : `${user.given_name} ${user.family_name}`
)

export const getUserInitials = createSelector(getUserFullname, (fullname) =>
  getInitials(fullname)
)

export const isAuthenticated = createSelector(tokenExpiryDate, (expiryDate) =>
  expiryDate.isAfter(moment.utc())
)

export const getUserId = createSelector(getCurrentUser, (user) =>
  user === null ? 0 : user.sub
)

export const getUserRoles = createSelector(getCurrentUser, (user) => {
  if (user === null) {
    return []
  }

  if (isArray(user.role)) {
    return user.role
  }
  return [user.role]
})

export const getUserRole = createSelector(
  getCurrentUser,
  companyId,
  (user, compId) => {
    if (user === null) {
      return null
    }

    if (compId === 0) {
      return get(user, 'role[0]', null)
    }

    return get(
      find(user.companies, (company) => compId === company.id),
      'category',
      null
    )
  }
)

export const getUserCompanies = createSelector(getCurrentUser, (user) => {
  if (user === null) {
    return []
  }

  return user.companies
})

export const getUserEmail = createSelector(getCurrentUser, (user) => {
  if (user === null) {
    return null
  }

  return user.email
})

export const getAdminStatus = createSelector(getCurrentUser, (user) => {
  if (user === null) {
    return false
  }

  return user.is_admin === 'True'
})

export const getMarketingStatus = createSelector(getCurrentUser, (user) => {
  if (user === null) {
    return false
  }

  return user.is_marketing === 'True'
})
