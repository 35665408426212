import PropTypes from 'prop-types'
import { Button } from 'antd'
import './EditButton.less'

const EditButton = ({ onClick }) => (
  <Button
    icon="edit"
    className="EditButton"
    type="primary"
    href={onClick}
    onClick={onClick}
  >
    Edit
  </Button>
)

EditButton.propTypes = {
  onClick: PropTypes.func,
}

export default EditButton
