import { useEffect, useState } from 'react'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Divider, PageHeader, Row, Spin, message } from 'antd'
import { isNil } from 'lodash'
import * as yup from 'yup'
import loaderImage from '../../assets/context_loader.gif'
import FloatingInput from '../../components/FloatingInput'
import http from '../../utils/api'

const EditManagerDetails = ({
  match: {
    params: { companyId, contactId },
  },
  history: { goBack },
}) => {
  const selectedCompanyId = parseInt(companyId, 10)
  const selectedContactId = parseInt(contactId, 10)
  const [managerInfo, setManagerInfo] = useState({
    name: '',
    firmAUM: 0,
    cik: 0,
    crd: 0,
    fundAttributes: '',
    companyId: 0,
  })

  const [isValid, setIsValid] = useState(true)
  const [valuesChanged, setValuesChanged] = useState(false)

  const validator = yup.object().shape({
    firmAUM: yup.number().min(0).nullable(),
    cik: yup.number().min(0).nullable(),
    crd: yup.number().min(0).nullable(),
  })

  useEffect(() => {
    http
      .get(`/funds/managers/${selectedContactId}/details`)
      .then(function (response) {
        setManagerInfo(response.data)
      })
  }, [])

  const onValueChanged = (value, name) => {
    if (['cik', 'crd', 'firmAum'].includes(name)) {
      value = value != '' ? Number(value) : 0
    }
    const newManagerInfo = { ...managerInfo, [name]: value }
    const valid = validator.isValidSync(newManagerInfo)
    setIsValid(valid)
    setManagerInfo({ ...managerInfo, [name]: value })
    setValuesChanged(true)
  }

  const postData = () => {
    if (valuesChanged) {
      if (isValid) {
        http
          .put(
            `/contacts/${selectedContactId}/editfund/${selectedCompanyId}`,
            managerInfo
          )
          .then((response) => {
            if (response.status == 200) {
              message.success('Fund updated successfully')
              setValuesChanged(false)
            } else {
              message.error('Something went wrong, please try again!')
            }
          })
          .catch(() => {
            message.error('Something went wrong, please try again!')
          })
      }
    }
  }

  return (
    <>
      <Spin
        size="large"
        spinning={isNil(managerInfo)}
        indicator={
          <img
            style={{ marginLeft: '-50px', width: '100px', height: '100px' }}
            src={loaderImage}
            alt="LoaderImage"
          />
        }
      >
        <Row type="flex" justify="space-between">
          <Col span={11} style={{ display: 'flex' }}>
            <Button
              onClick={goBack}
              style={{ marginRight: '10px', padding: '0px 10px' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <PageHeader title={`${'Edit' + ' '}${managerInfo.name}`} />
          </Col>
        </Row>
        <Row style={{ marginTop: '20px', backgroundColor: 'white' }}>
          <Row>
            <Col span={24}>
              <h2
                style={{
                  marginBottom: '24px',
                  marginTop: '24px',
                  fontWeight: '600',
                  marginLeft: '24px',
                  fontSize: '18px',
                  lineHeight: '22px',
                }}
              >
                Manager Details
              </h2>
            </Col>
            <Divider />
          </Row>
          <Col span={10} offset={7} style={{ padding: '20px' }}>
            <h2
              style={{
                marginBottom: '56px',
                marginTop: '23px',
                textAlign: 'center',
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '18px',
              }}
            >
              Edit Manager Details
            </h2>

            <FloatingInput
              onChange={onValueChanged}
              value={managerInfo.firmAum}
              key="FirmAum"
              name="Firm AUM"
              id="firmAum"
            />
            <FloatingInput
              onChange={onValueChanged}
              value={managerInfo.crd}
              key="CRD"
              name="CRD"
              id="crd"
            />
            <FloatingInput
              onChange={onValueChanged}
              value={managerInfo.cik}
              key="CIK"
              name="CIK"
              id="cik"
            />
            <FloatingInput
              onChange={onValueChanged}
              value={managerInfo.fundAttributes}
              key="FundAttributes"
              name="Fund Attributes"
              id="fundAttributes"
            />
            <Row style={{ paddingTop: '40px' }}>
              <Col span={24}>
                <Button
                  htmlType="submit"
                  block
                  size="large"
                  style={{
                    background: '#2FC5D2',
                    boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.06);',
                    color: 'white',
                  }}
                  disabled={!isValid}
                  onClick={postData}
                >
                  <span style={{ background: '#2FC5D2', opacity: '1' }}>
                    Save
                  </span>
                </Button>
                {!isValid && (
                  <div style={{ color: '#f5222d' }}>
                    One or more values are not valid
                  </div>
                )}
              </Col>
            </Row>
            <Row style={{ paddingTop: '24px' }}>
              <Col span={24}>
                <Button
                  type="link"
                  htmlType="reset"
                  block
                  size="large"
                  style={{
                    background: 'White',
                    color: '#2FC5D2',
                  }}
                  onClick={goBack}
                >
                  <span style={{ background: 'White', opacity: '1' }}>
                    Cancel
                  </span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </>
  )
}

export default EditManagerDetails
