import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Modal, Radio, Row, message } from 'antd'
import { isEmpty, isNil } from 'lodash'
import { rescheduleMeeting } from '../../actions/contextMeetings'
import ContactName from '../ContactName'
import LoadingGif from '../LoadingGif'
import SelectOnlineMeetingDateTime from '../SelectOnlineMeetingDateTime'
import '../../styles/index.less'
import './RescheduleEventMeeting.less'

const RadioGroup = Radio.Group
const RadioButton = Radio.Button

const RescheduleEventMeeting = ({
  contacts,
  companyId,
  company,
  visible,
  closeModal,
  meetingId,
  eventId,
  onRescheduleMeeting,
  participants,
  toParticipants,
  meetingDateTime,
  meetingDuration,
  isVirtualSummit = false,
  timezone,
}) => {
  // const [selectedDay, setSelectedDay] = useState(0);
  // const [selectedMeetingTime, setSelectedMeetingTime] = useState(0);
  // const [availableTimes, setAvailableTimes] = useState([]);
  // const [companyContacts, setCompanyContacts] = useState({
  //   contacts: contacts,
  // });
  // const [loading, setLoading] = useState(true);
  // const [checkValue, setCheckValue] = useState(null);
  // const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  //const [selectedTimezone, setSelectedTimezone] = useState(moment.tz.guess());
  const [reschedulingInProgress, setReschedulingInProgress] = useState(false)
  const [selectedDateTime, setSelectedDateTime] = useState({})
  const [duration, setDuration] = useState()
  const [selectedHour, setSelectedHour] = useState({})
  const [senderId, setSenderId] = useState(null)

  const onDurationChanged = (value) => {
    setDuration(value)
  }

  const onTimeChanged = (changed, newDate) => {
    if (changed === 'date') {
      setSelectedHour({})
    }
    if (changed === 'hour') {
      setSelectedHour(newDate)
    }
    if (!isNil(newDate)) {
      setSelectedDateTime({
        startDateTime: newDate.startTime,
        endDateTime: newDate.endTime,
      })
    }
  }

  // useEffect(() => {
  //   if (companyEventId > 0)
  //     http
  //       .get(`companies/event/contacts/${contactCompanyEventId}`)
  //       .then((response) => setCompanyContacts(response.data))
  //       .finally(() => setLoading(false));
  // }, [companyEventId]);

  // const handleRescheduleClick = () => {
  //   setRescheduleModalVisible(true);
  // };

  // const handleModalCancel = () => {
  //   setSubmitButtonDisabled(true);
  //   setAvailableTimes([]);
  //   setSelectedMeetingTime(0);
  //   setCheckValue(null);
  //   setSelectedTimezone(moment.tz.guess());
  //   setSenderId(null);
  //   closeModal();
  // };

  const onModalClosed = useCallback(() => {
    setSelectedDateTime({})
    setDuration(30)
    setSelectedHour({})
    setSenderId(null)
    closeModal()
  }, [closeModal])

  const onRadioChange = (e) => {
    setSenderId(e.target.value)
    // setLoading(true);
    // getNewEventAvailableTimes(
    //   companyId,
    //   eventId,
    //   companyEventId,
    //   e.target.value,
    //   selectedTimezone
    // )
    //   .then((response) => setAvailableTimes(response.data))
    //   .finally(() => setLoading(false));
  }

  // const handleModalOk = () => {
  //   setSubmitButtonDisabled(true);
  //   rescheduleMeeting(checkValue, meetingId, selectedMeetingTime)
  //     .then(() => {
  //       message.success("The meeting has been rescheduled.");
  //       closeModal();
  //       onRescheduleMeeting();
  //       setAvailableTimes([]);
  //     })
  //     .catch((err) => {
  //       setSubmitButtonDisabled(false);
  //       if (err.response !== undefined) {
  //         message.error(err.response.data);
  //       }
  //     });
  // };

  const handleModalOk = useCallback(() => {
    setReschedulingInProgress(true)
    rescheduleMeeting(
      companyId,
      senderId,
      meetingId,
      selectedDateTime.startDateTime,
      duration
    )
      .then(() => {
        message.success('The meeting has been rescheduled.')
        onModalClosed()
        onRescheduleMeeting(selectedDateTime.startDateTime)
      })
      .catch((err) => {
        var allErr = err.response.data.message
        message.error(
          allErr.substring(allErr.indexOf("'") + 1, allErr.indexOf("'."))
        )
        //tier === "Free" && onForbidden() && onCancel();
      })
      .finally(() => {
        setReschedulingInProgress(false)
      })
  }, [
    onModalClosed,
    duration,
    onRescheduleMeeting,
    meetingId,
    selectedDateTime.startDateTime,
    senderId,
    companyId,
  ])

  // const onTimeZoneChange = (e) => {
  //   console.log(e);
  //   setSelectedTimezone(e);
  //   getNewEventAvailableTimes(companyId, eventId, companyEventId, checkValue, e)
  //     .then((response) => setAvailableTimes(response.data))
  //     .finally(() => setLoading(false));
  // };

  // const onRadioChange = (e) => {
  //   setCheckValue(e.target.value);
  //   setLoading(true);
  //   getNewEventAvailableTimes(
  //     companyId,
  //     eventId,
  //     companyEventId,
  //     e.target.value,
  //     selectedTimezone
  //   )
  //     .then((response) => setAvailableTimes(response.data))
  //     .finally(() => setLoading(false));
  // };

  // const renderMeetingHours = () => (
  //   <div>
  //     {availableTimes[selectedDay] && (
  //       <div style={{ margin: "10px 18px 10px 10px" }}>
  //         <Timezone
  //           value={selectedTimezone}
  //           handleTimezoneChange={onTimeZoneChange}
  //         />
  //       </div>
  //     )}
  //     <RadioGroup
  //       onChange={(e) => {
  //         setSelectedMeetingTime(e.target.value);
  //         setSubmitButtonDisabled(false);
  //       }}
  //       value={selectedMeetingTime}
  //       className="cc-meeting-hours-radiogroup"
  //       size="large"
  //     >
  //       {availableTimes[selectedDay] &&
  //         availableTimes[selectedDay].eventScheduleSlots.map(
  //           ({ meetingTimeId, eventSlotTime }) => (
  //             <Col span={12}>
  //               <div className="cc-meeting-hours-buttons">
  //                 <RadioButton
  //                   key={meetingTimeId.toString()}
  //                   value={meetingTimeId}
  //                   style={{ minWidth: "155px" }}
  //                 >{`${moment(eventSlotTime).format("h:mm a")} - ${moment(
  //                   eventSlotTime
  //                 )
  //                   .add(30, "m")
  //                   .format("h:mm a")}`}</RadioButton>
  //               </div>
  //             </Col>
  //           )
  //         )}
  //     </RadioGroup>
  //   </div>
  // );

  useEffect(() => {
    if (contacts && contacts.length === 1) {
      setSenderId(contacts[0].contactId)
    }
  }, [contacts])
  return (
    <div className="cc-meeting-actions">
      <Modal
        destroyOnClose
        className="cc-modal-reschedule"
        title="Reschedule the Meeting"
        visible={visible}
        okText="Reschedule the Meeting"
        onOk={handleModalOk}
        onCancel={onModalClosed}
        okButtonProps={{
          loading: reschedulingInProgress,
          disabled:
            isEmpty(selectedDateTime) ||
            isEmpty(selectedHour) ||
            isNil(senderId)
              ? true
              : false,
          size: 'large',
          style: {
            marginLeft: '24px',
            backgroundColor: '#F19F00',
            borderStyle: 'none',
          },
        }}
        cancelButtonProps={{ disabled: false, size: 'large' }}
        width={'758px'}
        bodyStyle={{ padding: '30px' }}
      >
        <Row>
          <p>Reschedule meeting with {company} on behalf of:</p>
          <Col span="24">
            <Radio.Group
              onChange={onRadioChange}
              value={senderId}
              style={{ marginLeft: '15px' }}
            >
              {contacts &&
                contacts.map((row) => {
                  return (
                    <div style={{ paddingBottom: '6px' }}>
                      <Radio value={row.contactId} />
                      <ContactName
                        style={{ display: 'inline' }}
                        name={row.contactName}
                        imageUrl={row.imageURL}
                      />
                    </div>
                  )
                })}
            </Radio.Group>
          </Col>
        </Row>

        <LoadingGif spinning={reschedulingInProgress}>
          <SelectOnlineMeetingDateTime
            onTimeChanged={onTimeChanged}
            onDurationChanged={onDurationChanged}
            toParticipants={toParticipants}
            participants={participants}
            dateTime={meetingDateTime}
            meetingDuration={meetingDuration}
            eventId={eventId}
            physicalSummit={!isVirtualSummit && !isNil(eventId)}
            defaultTimezone={timezone}
            meetingId={meetingId}
          />
        </LoadingGif>
      </Modal>
      {/* <Modal
        className="cc-modal-reschedule"
        title="Reschedule the Meeting"
        visible={visible}
        okText="Reschedule the Meeting"
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okButtonProps={{
          disabled: submitButtonDisabled,
          size: "large",
          style: {
            marginLeft: "24px",
            backgroundColor: "#F19F00",
            borderStyle: "none",
          },
        }}
        cancelButtonProps={{ disabled: false, size: "large" }}
        width="820px"
        bodyStyle={{ padding: "0" }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div style={{ marginLeft: "30px", marginTop: "22px" }}>
              <span
                className="cc-heading5"
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "#4D4F56",
                }}
              >
                Choose the user on behalf you want to reschedule the meeting:
              </span>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  paddingTop: "16px",
                }}
              >              

                <Radio.Group onChange={onRadioChange} value={checkValue}>
                  {companyContacts.contacts &&
                    companyContacts.contacts.length > 0 &&
                    companyContacts.contacts.map((contact) => (
                      <Col
                        span={24}
                        style={{ paddingBottom: "16px", paddingLeft: "0" }}
                      >
                        <div
                          key={contact.contactId.toString()}
                          style={{ width: "100%" }}
                        >
                          <Radio
                            value={contact.contactId}
                            style={{ paddingRight: "12px" }}
                          />
                          <ContactName
                            name={contact.contactName}
                            imageUrl={contact.imageURL}
                          />
                        </div>
                      </Col>
                    ))}
                </Radio.Group>
              </div>
            </div>
            <Divider
              type="horizontal"
              style={{
                backgroundColor: "#F7F8FA",
                margin: "14px 0px 0px 0px",
              }}
            />
            <div>
              {isEmpty(availableTimes) ? (
                <Empty
                  style={{ textAlign: "center" }}
                  description="No available times"
                />
              ) : (
                <RadioGroup
                  value={selectedDay}
                  onChange={(e) => setSelectedDay(e.target.value)}
                >
                  {availableTimes.map(({ eventDate }, index) => (
                    <RadioButton
                      key={eventDate}
                      className="cc-text-color-summit cc-eventdays-buttons"
                      value={index}
                    >
                      <div className="cc-tabletitle-text">
                        {`Day ${index + 1}`}
                      </div>
                      <div className="cc-heading1">
                        {moment(eventDate).format("D")}
                      </div>
                      <div className="cc-heading6">
                        {moment(eventDate).format("MMM").toUpperCase()}
                      </div>
                    </RadioButton>
                  ))}
                </RadioGroup>
              )}
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#F7F8FA",
              width: "50%",
              padding: "16px 14px 3px",
              overflowY: "auto",
              maxHeight: "415px",
            }}
          >
            <div>{renderMeetingHours()}</div>
          </div>
        </div>
      </Modal> */}
    </div>
  )
}

RescheduleEventMeeting.defaultProps = {
  contacts: '',
  buttonType: '',
}

RescheduleEventMeeting.propTypes = {
  buttonType: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onRescheduleMeeting: PropTypes.func.isRequired,
  meetingId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  participants: PropTypes.array,
  toParticipants: PropTypes.array,
  meetingDateTime: PropTypes.string,
  meetingDuration: PropTypes.number,
  company: PropTypes.string.isRequired,

  contacts: PropTypes.array.isRequired,
  //company: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
}

export default RescheduleEventMeeting
