import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isArray } from 'util'
import { Col, Divider, Row } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment'
import numeral from 'numeral'
import http from '../../utils/api'

const EventFunds = ({ companyEventId, fundId }) => {
  const [funds, setFunds] = useState([])
  const [buttonVisible, setButtonVisible] = useState({})
  const [fundToDelete, setFundToDelete] = useState(null)

  //use section fundToDelete to set details of fund for use in modal
  const toggleState = (section, value) => {
    if (section === 'fundToDelete') {
      console.log(value)
      setFundToDelete(value)
    } else {
      var newState = {}
      newState[section] = value
      setButtonVisible(newState)
    }
  }

  //updates whenever fundToDelete is changed
  useEffect(() => {
    http
      .get('/funds/details/' + fundId)
      .then(function (response) {
        setFunds([...funds, response.data])
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }, [fundToDelete])

  return (
    <div className="CompanyDetails-Wrapper">
      {fundRows(funds, buttonVisible, toggleState)}
    </div>
  )
}

const fundAttributes = (values, className) => {
  var valArray = []

  if (!isArray(values) && !isNil(values)) {
    valArray = values.split(',')
  } else if (values !== null) {
    valArray = values
  }

  var attrRows = groupAttrs(valArray)

  return attrRows.map((column, index) => (
    <Row type="flex" justify="start" align="middle">
      <Col style={{ paddingLeft: '0px', marginRight: '2px' }}>
        <div className={className}>{column[0]}</div>
      </Col>
      {column.length > 1 ? (
        <Col style={{ paddingRight: '0px' }}>
          <div className={className}>{column[1]}</div>
        </Col>
      ) : null}
    </Row>
  ))
}

//create new array of funds grouped in rows of two
const groupAttrs = (attrs) => {
  var grouped = []
  var row = []

  if (!isNil(attrs))
    for (let i = 0; i < attrs.length; i++) {
      if (i % 2 === 0) {
        row.push(attrs[i])
      } else {
        row.push(attrs[i])
        grouped.push(row)
        row = []
      }
    }

  //if there is one extra fund
  if (row.length > 0) {
    grouped.push(row)
  }

  return grouped
}

const fundProfile = (fund, buttonState, toggleState) => {
  if (fund === 'add') {
    return <Row type="flex" justify="space-around" align="middle"></Row>
  } else {
    return (
      <Row
        type="flex"
        justify="space-between"
        align="top"
        style={{ marginBottom: '24px', background: 'white' }}
        onMouseEnter={(e) => toggleState(fund.fundId, true)}
        onMouseLeave={(e) => toggleState(fund.fundId, false)}
      >
        <Col span={24}>
          <Row type="flex" justify="space-between" align="middle">
            <Col span={16} style={{ paddingLeft: '24px' }}>
              <div className="fundName">{fund.name}</div>
            </Col>
          </Row>
          <Divider />
          <Row style={{ paddingBottom: '32px' }}>
            <Col span={8} style={{ paddingLeft: '24px' }}>
              <Row>
                <Col span={24}>
                  <div className="fundHeading">FUND AUM</div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="fundValue">
                    {numeral(fund.fundAUM).format('($0,0[.]0 a)').toUpperCase()}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <Col span={24}>
                  <div className="fundHeading">INCEPTION DATE</div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="fundValue">
                    {checkEmpty(
                      moment(fund.fundInceptionDate).format('MMM YYYY')
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <Col span={24}>
                  <div className="fundHeading">SHARPE RATIO</div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="fundValue">{fund.sharpe}</div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ paddingBottom: '28px' }}>
            <Col span={12} style={{ paddingLeft: '24px' }}>
              <Row>
                <Col span={24}>
                  <div className="fundHeading">BROAD STRATEGY GROUP</div>
                </Col>
              </Row>
              {fundAttributes(fund.broadStrategy, 'roundBlue')}
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <div className="fundHeading">SUB STRATEGY</div>
                </Col>
              </Row>
              {fundAttributes(fund.subStrategy, 'roundPurple')}
            </Col>
          </Row>
          <Row style={{ paddingBottom: '42px' }}>
            <Col span={16} style={{ paddingLeft: '24px' }}>
              <Row>
                <Col span={24}>
                  <div className="fundHeading">INVESTMENT VEHICLES</div>
                </Col>
              </Row>
              {fundAttributes(fund.investmentVehicle, 'roundRed')}
            </Col>
            <Col span={8}></Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

const fundRows = (funds, buttonState, toggleState) => {
  const fundRows = groupFunds(funds)

  return fundRows.map((column, index) => (
    <Row type="flex" justify="space-between" align="middle">
      <Col span={12} style={{ paddingLeft: '0px' }}>
        {fundProfile(column[0], buttonState, toggleState)}
      </Col>
      {column.length > 1 ? (
        <Col span={12} style={{ paddingRight: '0px' }}>
          {fundProfile(column[1], buttonState, toggleState)}
        </Col>
      ) : null}
    </Row>
  ))
}

//create new array of funds grouped in rows of two
const groupFunds = (funds) => {
  var grouped = []
  var row = []

  for (let i = 0; i < funds.length; i++) {
    if (i % 2 === 0) {
      row.push(funds[i])
    } else {
      row.push(funds[i])
      grouped.push(row)
      row = []
    }
  }

  //if there is one extra fund
  if (row.length > 0) {
    row.push('add')
    grouped.push(row)
  } else {
    //add 'add' to end for add fund square
    grouped.push(['add'])
  }

  return grouped
}

const checkEmpty = (value) => {
  if (!value) return 'N/A'
  else return value
}

EventFunds.propTypes = {
  companyEventId: PropTypes.number,
  fundId: PropTypes.number.isRequired,
}

export default EventFunds
