import PropTypes from 'prop-types'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row } from 'antd'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import { getStatusTag } from '../../utils/helpers'
import './ProfileHeaderWithButtons.less'

const ProfileHeaderWithButtons = ({
  name,
  status = null,
  image = null,
  position = null,
  role = null,
  address = null,
  city = null,
  state = null,
  chapter = null,
  hideActions = true,
  onSendMessage,
  canSendMeetingRequest,
  onSendRequest,
  sendRequestText = 'Send meeting request',
  sendMessageText = 'Send Message',
  showEditButton = false,
  editDealText = 'Edit Deal',
  onEditDeal,
}) => (
  <Row className="Profile-container">
    <Col span={14}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {image && <img src={image} className="Profile-Picture" />}
        <div
          style={{
            margin: image ? '0px 16px' : '0px',
          }}
        >
          <span className="cc-heading5">
            {name} {status && getStatusTag(status)}
          </span>
          <br />
          <span className="cc-grey-text cc-small-font">
            {position && `${position} • `}
            {role && `${role} • `}
            {join(
              filter([address, city, state], (f) => !isNil(f)),
              ', '
            )}
          </span>
          {chapter && (
            <div className="Profile-chapter-label cc-tabletitle-text">
              {chapter}
            </div>
          )}
        </div>
      </div>
    </Col>
    <Col span={10}>
      {!hideActions && (
        <div className="Profile-buttons-container">
          <Button type="primary" ghost onClick={onSendMessage}>
            <FontAwesomeIcon icon={faPaperPlane} />
            {sendMessageText}
          </Button>
          {canSendMeetingRequest && (
            <Button type="primary" onClick={onSendRequest}>
              {sendRequestText}
            </Button>
          )}
        </div>
      )}

      {showEditButton && (
        <div className="Profile-buttons-container">
          <Button type="primary" onClick={onEditDeal}>
            {editDealText}
          </Button>
        </div>
      )}
    </Col>
  </Row>
)

ProfileHeaderWithButtons.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  position: PropTypes.string,
  role: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  status: PropTypes.string,
  chapter: PropTypes.string,
  state: PropTypes.string,
  hideActions: PropTypes.bool,
  onSendMessage: PropTypes.func,
  canSendMeetingRequest: PropTypes.bool,
  onSendRequest: PropTypes.func,
  sendRequestText: PropTypes.string,
}

export default ProfileHeaderWithButtons
