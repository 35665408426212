import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Form, Row, Select } from 'antd'
import { isEmpty, isNil, map } from 'lodash'
import { getContactCompanies } from '../../../actions/company'
import { getFundsByCompanyContact } from '../../../actions/funds'
import { getAllUsers } from '../../../actions/users'

const ContactField = ({
  name,
  values,
  setFieldValue,
  isArray = false,
  itemKey,
  errors,
  onDelete,
}) => {
  const getFieldName = (val) =>
    isArray ? `${name}[${itemKey}].${val}` : `${name}.${val}`

  const getFieldValue = (val) => {
    if (isArray) {
      return !isNil(values[name][itemKey]) ? values[name][itemKey][val] : null
    } else {
      return !isNil(values[name]) ? values[name][val] : null
    }
  }

  const getFieldErrors = (val) => {
    if (isArray) {
      return !isNil(errors[name]) && !isNil(errors[name][itemKey])
        ? errors[name][itemKey][val]
        : false
    } else {
      return !isNil(errors[name]) ? errors[name][val] : false
    }
  }

  const [filteredUsers, setFilteredUsers] = useState([])
  const [companies, setCompanies] = useState([])
  const [funds, setFunds] = useState([])

  const retrieveUsers = (keyword) => {
    getAllUsers(keyword).then((response) => {
      const usersObject = map(response.data, (item) => ({
        value: item.contactId,
        label: `${item.firstName} ${item.lastName}`,
      }))
      setFilteredUsers(usersObject)
    })
  }

  const retrieveCompanies = (contactId) => {
    getContactCompanies(contactId).then((response) => {
      const companiesObject = map(response.data, (item) => ({
        value: item.companyContactId,
        label: item.name,
      }))
      setCompanies(companiesObject)
    })
  }

  const retrieveFunds = (companyContactId) => {
    getFundsByCompanyContact(companyContactId).then((response) => {
      const fundsObject = map(response.data, (item) => ({
        value: item.fundId,
        label: item.name,
      }))
      setFunds(fundsObject)
    })
  }

  useEffect(() => {
    isNil(getFieldValue('contactName'))
      ? retrieveUsers('')
      : retrieveUsers(getFieldValue('contactName'))

    if (!isNil(getFieldValue('contactId'))) {
      retrieveCompanies(getFieldValue('contactId'))
    }

    if (!isNil(getFieldValue('companyContactId'))) {
      retrieveFunds(getFieldValue('companyContactId'))
    }
  }, [])

  const handleSearch = (value) => {
    value = value.toLowerCase()
    if (value.length > 2) {
      retrieveUsers(value)
    }
  }

  const clearFields = (i) => {
    setCompanies([])
    setFunds([])
    onDelete(i)
  }

  return (
    <Row type="flex" style={{ alignItems: 'center' }}>
      <Col span={7}>
        <label>{name === 'authorContact' ? 'Author' : 'Contact'}</label>
        <Form.Item
          name={getFieldName('contactId')}
          validateStatus={getFieldErrors('contactId') ? 'error' : 'success'}
          help={getFieldErrors('contactId') ? getFieldErrors('contactId') : ''}
        >
          <Select
            showSearch
            name={getFieldName('contactId')}
            className="searchbox author-select"
            optionFilterProp="children"
            onChange={(val) => {
              setFieldValue(getFieldName('contactId'), val)
              setFieldValue(getFieldName('companyContactId'), null)
              setFieldValue(getFieldName('fundId'), null)
              setCompanies([])
              setFunds([])
              retrieveCompanies(val)
            }}
            onSearch={handleSearch}
            filterOption={false}
            value={getFieldValue('contactId')}
            suffixIcon={<FontAwesomeIcon icon={faSearch} />}
          >
            {map(filteredUsers, (user, key) => (
              <Select.Option key={key} value={user.value}>
                {user.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {!isNil(getFieldValue('contactId')) && (
        <Col span={7}>
          <label>Company</label>
          <Form.Item
            name={getFieldName('companyContactId')}
            validateStatus={
              getFieldErrors('companyContactId') ? 'error' : 'success'
            }
            help={
              getFieldErrors('companyContactId')
                ? getFieldErrors('companyContactId')
                : ''
            }
          >
            <Select
              name={getFieldName('companyContactId')}
              className="searchbox author-select"
              optionFilterProp="children"
              onChange={(val) => {
                setFieldValue(getFieldName('companyContactId'), val)
                setFieldValue(getFieldName('fundId'), null)
                setFunds([])
                retrieveFunds(val)
              }}
              filterOption={false}
              value={getFieldValue('companyContactId')}
            >
              {map(companies, (company, key) => (
                <Select.Option key={key} value={company.value}>
                  {company.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      {!isNil(getFieldValue('companyContactId')) && !isEmpty(funds) && (
        <Col span={7}>
          <label>Fund</label>
          <Form.Item name={getFieldName('fundId')}>
            <Select
              name={getFieldName('fundId')}
              className="searchbox author-select"
              optionFilterProp="children"
              onChange={(val) => setFieldValue(getFieldName('fundId'), val)}
              filterOption={false}
              value={getFieldValue('fundId')}
            >
              {map(funds, (fund, key) => (
                <Select.Option key={key} value={fund.value}>
                  {fund.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      <Col span={2}>
        <Button
          type="link"
          className="content-field-delete"
          onClick={() => clearFields(itemKey)}
        >
          {isArray ? 'Delete' : 'Clear'}
        </Button>
      </Col>
    </Row>
  )
}

ContactField.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  isArray: PropTypes.bool,
  itemKey: PropTypes.number,
  onDelete: PropTypes.func,
}

export default ContactField
