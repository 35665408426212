import { useEffect, useState } from 'react'
import { faTimesHexagon } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  PageHeader,
  Row,
  Spin,
  message,
} from 'antd'
import isNil from 'lodash/isNil'
import http from '../../utils/api'
import AddContactToCompany from '../AddContactToCompany'
import ContactHeaderBackLink from '../ContactHeaderBackLink/ContactHeaderBackLink'
import './ContactCompanies.less'
import '../../styles/forms.less'

const ContactCompanies = ({
  match: {
    params: { contactId },
  },
  history: { goBack },
}) => {
  const [companies, setcompanies] = useState([])
  const [refreshList, setrefreshList] = useState(false)
  const selectedContactId = parseInt(contactId, 10)
  const [isLoading, setIsLoading] = useState(true)
  const [setAddToCompanyModalVisible] = useState(false)

  useEffect(() => {
    http
      .get('/contacts/details/' + selectedContactId)
      .then(function (response) {
        setcompanies(response.data[0].companies)
        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        setIsLoading(true)
      })
  }, [refreshList])

  if (isNil(companies)) {
    return (
      <Spin spinning={isLoading}>
        <Empty />
      </Spin>
    )
  }

  return (
    <>
      <Spin size="large" spinning={isLoading || companies === null}>
        <ContactHeaderBackLink contactId={contactId}></ContactHeaderBackLink>

        <Row style={{ marginTop: '20px', backgroundColor: 'white' }}>
          <Row>
            <Col span={24}>
              <h2
                style={{
                  marginBottom: '24px',
                  marginTop: '24px',
                  fontWeight: '600',
                  marginLeft: '24px',
                  fontSize: '18px',
                  lineHeight: '22px',
                }}
              >
                Companies
              </h2>
            </Col>
          </Row>
        </Row>
        <Row
          style={{ borderTop: '1px solid #EFF1F4', backgroundColor: 'white' }}
        >
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              {companies.map((company, index) => (
                <Col span={8}>
                  <div className="companyContainer">
                    {isNil(company.imageURL) ? (
                      <div className="companyContainerImg">
                        <FontAwesomeIcon
                          icon={faTimesHexagon}
                          size="5x"
                          color="#EFF1F4"
                        />{' '}
                      </div>
                    ) : (
                      <img src={company.imageURL} alt={company.name}></img>
                    )}

                    <span className="companyName">{company.name}</span>
                    <br></br>
                    <span className="positionName">{company.jobFunction}</span>
                  </div>
                </Col>
              ))}
              <Col span={8}>
                <AddContactToCompany
                  contactId={selectedContactId}
                  onSave={() => {
                    //this.props.onAddToCompany();
                    setrefreshList(!refreshList)
                  }}
                />
              </Col>
            </Col>

            <Col span={2}></Col>
            <Divider />
          </Row>
        </Row>
      </Spin>
    </>
  )
}

export default ContactCompanies
