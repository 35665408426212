import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './CompanySelectContainer.less'

const ContactsText = ({ contacts, length, contactsNumber }) => {
  const [text, setText] = useState(null)
  const [total, setTotal] = useState(contactsNumber)

  useEffect(() => {
    setText(
      contacts
        .filter((_, id) => id < total)
        .map((contact) => contact.contactName)
        .join(', ')
    )
  }, [contacts, total])

  useEffect(() => {
    contacts
      .filter((_, id) => id < contactsNumber)
      .map((contact) => contact.contactName)
      .join(', ').length > length
      ? setTotal(2)
      : setTotal(contactsNumber)
  }, [contacts, length, contactsNumber])

  return (
    <div className="select-company-text-contacts cc-small-font">
      {text}

      {(contacts.length - total > 0 && (
        <span style={{ marginLeft: '5px' }}> + {contacts.length - total}</span>
      )) ||
        ''}
    </div>
  )
}

ContactsText.propTypes = {
  contacts: PropTypes.array,
  length: PropTypes.number,
  contactsNumber: PropTypes.number,
}
export default ContactsText
