import PropTypes from 'prop-types'
import CCUpload from '../../CCUpload'
import FloatingInput from '../../FloatingInput'
import FloatingTextArea from '../../FloatingTextArea'

const PersonField = ({ item, onItemChanged, itemIndex }) => (
  <div className="PersonField-container">
    <div className="PersonField-image-column">
      <CCUpload
        placeholder="Add Profile Photo"
        onChange={(e) => {
          onItemChanged(itemIndex, 'imageUrl', e)
        }}
        imageUrl={item.imageUrl}
      />
    </div>
    <div className="PersonField-fields-column">
      <div className="PersonField-fields-upper">
        <div className="PersonField-name">
          <FloatingInput
            onChange={(e) => {
              onItemChanged(itemIndex, 'fullName', e)
            }}
            value={item.fullName}
            name="Full Name"
          />
        </div>
        <div className="PersonField-position">
          <FloatingInput
            onChange={(e) => {
              onItemChanged(itemIndex, 'jobPosition', e)
            }}
            value={item.jobPosition}
            name="Job Position"
          />
        </div>
      </div>
      <div className="PersonField-description">
        <FloatingTextArea
          onChange={(e) => {
            onItemChanged(itemIndex, 'description', e)
          }}
          value={item.description}
          name="Profile Description"
        />
      </div>
    </div>
  </div>
)

PersonField.propTypes = {
  item: PropTypes.object.isRequired,
  onItemChanged: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
}
export default PersonField
