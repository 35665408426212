import { useEffect, useState } from 'react'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Modal, Radio, Row, Select } from 'antd'
import isNil from 'lodash/isNil'
import { useHistory } from 'react-router-dom'
import { isNumericLiteral } from 'typescript'
import {
  getDealsActivityGraphList,
  getDealsActivityList,
} from '../../actions/deals'
import Activities from '../../components/ActivityDetails/Activities'
import ContextTable from '../../components/ContextTable'
import DealContentModal from '../../components/Deals/DealContentModal'
import DeleteModal from '../../components/DeleteModal/DeleteModal'
import http from '../../utils/api'
import { getDealsActivityColumns, getDealsColumns } from '../../utils/columns'
import DealsWizardContainer from '../../views/DealsWizardContainer'
import './Deals.less'

const { OptGroup } = Select
const RadioGroup = Radio.Group

const Deals = ({
  displayTitle,
  displayAddButton,
  tableTitle,
  getDealListData,
  companyIdProp = undefined,
  newDealForCompanyTitle = 'Choose a Company',
  newDealForCompanyContacts = [],
}) => {
  const [refreshData, setRefreshData] = useState(false)
  const [columns, setColumns] = useState(null)

  const [showDealCompanyModal, setshowDealCompanyModal] = useState(false)
  const [showCreateDealsProfile, setShowCreateDealsProfile] = useState(false)
  const [showDealActivity, setShowDealActivity] = useState(false)
  const [companies, setCompanies] = useState([])
  const [contacts, setContacts] = useState(newDealForCompanyContacts)
  const [companyId, setcompanyId] = useState(companyIdProp)
  const [contactId, setcontactId] = useState(null)
  const [showContacts, setShowContacts] = useState(false)
  const [deleteCompanyModal, setDeleteCompanyModal] = useState(false)

  const [showDealProfileForView, setshowDealProfileForView] = useState(false)

  const [dealState, setDealState] = useState('create')
  const [dealId, setDealId] = useState(null)
  const [dealName, setDealName] = useState(null)
  const [currentDealStatus, setCurrentDealStatus] = useState('draft')
  const history = useHistory()
  const [dealCategoryName, setDealCategoryName] = useState(null)

  const refresh = () => {
    setRefreshData(!refreshData)
  }

  const showDealModalFromGrid = (dealId, status, categoryName) => {
    setDealState('edit')
    setDealId(dealId)
    setCurrentDealStatus(status)
    setshowDealProfileForView(!showDealProfileForView)

    if (categoryName === 'CFN') {
      setDealCategoryName('Context Family Network')
    } else {
      setDealCategoryName(categoryName)
    }
  }

  const showDealActivityFromGrid = (dealId, title) => {
    setDealId(dealId)
    setDealName(title)
    setShowDealActivity(!showDealActivity)
  }

  const showEditDealModal = () => {
    setshowDealProfileForView(false)
    setShowCreateDealsProfile(true)
  }

  const showDealDeleteModal = (dealId, title) => {
    setDealId(dealId)
    setDealName(title)
    setDeleteCompanyModal(true)
  }

  useEffect(() => {
    if (contacts && contacts.length > 0) {
      setShowContacts(true)
    }
  }, [contacts])

  useEffect(() => {
    var cols = getDealsColumns(
      refresh,
      showDealModalFromGrid,
      showDealActivityFromGrid,
      showDealDeleteModal
    )

    setColumns(cols)
  }, [refreshData])

  const handleFilterOption = (e) => {
    if (e.length > 2)
      http.post(`/companies/search/online/-1?keyword=${e}`).then((res) => {
        setCompanies(res.data.response)
      })
  }

  const handleSelectChange = (e) => {
    let selectedCompany = companies.cfNs.filter(
      (obj) => obj.company.companyId === e
    )
    if (selectedCompany.length === 0) {
      selectedCompany = companies.allocators.filter(
        (obj) => obj.company.companyId === e
      )
      if (selectedCompany.length === 0) {
        selectedCompany = companies.serviceProviders.filter(
          (obj) => obj.company.companyId === e
        )
      }
    }
    setcompanyId(selectedCompany[0].company.companyId)

    http
      .get(`/companies/` + selectedCompany[0].company.companyId + `/contacts`)
      .then((res) => {
        setContacts(res.data)
      })
  }

  return (
    <div>
      <Row
        className={
          displayTitle || displayAddButton
            ? 'deal-row-with-padding'
            : 'deal-row-with-no-padding'
        }
      >
        <Col span={12}>
          {displayTitle && (
            <h1
              className="cc-section-header-title"
              style={{ lineHeight: '30px' }}
            >
              Deals
            </h1>
          )}
        </Col>
        {displayAddButton && (
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button
              block
              size="large"
              style={{
                background: '#2FC5D2',
                boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.06);',
                color: 'white',
                width: '208px',
              }}
              onClick={(e) => {
                e.stopPropagation()
                setDealState('create')
                setshowDealCompanyModal(true)
              }}
            >
              <span style={{ background: '#2FC5D2', opacity: '1' }}>
                <FontAwesomeIcon icon={faPlus} /> New Deal
              </span>
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col id="dealsList" span={24}>
          {columns && (
            <ContextTable
              tableTitle={tableTitle}
              columns={columns}
              dataMethod={getDealListData}
              refresh={refreshData}
              refreshData={refreshData}
              scroll={{ x: 1900 }}
              companyId={companyIdProp}
            />
          )}
        </Col>
      </Row>

      <Modal
        className="cc-modal-accept"
        title={newDealForCompanyTitle}
        visible={showDealCompanyModal}
        centered={true}
        destroyOnClose
        okText="Continue"
        cancelText="Close"
        okButtonProps={{
          disabled: isNil(companyId) || isNil(contactId),
          style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
        }}
        cancelButtonProps={{ disabled: false }}
        onCancel={() => {
          setshowDealCompanyModal(false)
          setCurrentDealStatus('draft')
        }}
        onOk={() => {
          setshowDealCompanyModal(false)
          setShowCreateDealsProfile(true)
        }}
      >
        {isNil(companyIdProp) && (
          <div className="form-container">
            <Select
              showSearch
              size="large"
              style={{ width: '100%', height: '48px' }}
              placeholder="Search here"
              showArrow={true}
              optionFilterProp="children"
              onChange={handleSelectChange}
              onSearch={handleFilterOption}
              filterOption={false}
              value={companyId}
              disabled={false}
            >
              <OptGroup label="Allocator Companies">
                {companies.allocators &&
                  companies.allocators.map((row) => {
                    if (row.company.category.toLowerCase() === 'allocator') {
                      return (
                        <Select.Option value={row.company.companyId}>
                          {row.company.name}
                        </Select.Option>
                      )
                    }
                  })}
              </OptGroup>
              <OptGroup label="Service Providers">
                {companies &&
                  companies.serviceProviders &&
                  companies.serviceProviders.map((row) => {
                    if (
                      row.company.category.toLowerCase() === 'service provider'
                    ) {
                      return (
                        <Select.Option
                          value={row.company.companyId}
                          key={row.company.companyId}
                        >
                          {row.company.name}
                        </Select.Option>
                      )
                    }
                  })}
              </OptGroup>
              <OptGroup label="CFN Companies">
                {companies.cfNs &&
                  companies.cfNs.map((row) => {
                    if (row.company.category.toLowerCase() === 'cfn') {
                      return (
                        <Select.Option value={row.company.companyId}>
                          {row.company.name}
                        </Select.Option>
                      )
                    }
                  })}
              </OptGroup>
            </Select>
          </div>
        )}
        <div id="userDiv" style={{ display: showContacts ? 'block' : 'none' }}>
          <p>Select user</p>
          <div>
            <RadioGroup
              value={contactId}
              onChange={(e) => {
                setcontactId(e.target.value)
              }}
            >
              {contacts.map((row) => {
                return (
                  <>
                    <Radio value={row.contactId}>
                      {row.firstName} {row.lastName}
                    </Radio>
                    <br />
                  </>
                )
              })}
            </RadioGroup>
          </div>
        </div>
      </Modal>

      <DealContentModal
        showCreateDealsProfile={showCreateDealsProfile}
        handleModalCancel={() => {
          setShowCreateDealsProfile(false)
          setCurrentDealStatus('draft')
        }}
        handleWizardClose={() => setShowCreateDealsProfile(false)}
        usage={dealState}
        type="deals"
        contactId={contactId}
        compId={companyId}
        dealId={dealId}
        status={currentDealStatus}
        refreshDealList={refresh}
        openedForView={showDealProfileForView}
        handleViewClose={() => setshowDealProfileForView(false)}
        dealCategoryName={dealCategoryName}
        handleEditDeal={showEditDealModal}
      />

      {showDealActivity && (
        <Activities
          moduleCode="Deal"
          showActivityModal={showDealActivity}
          entityId={dealId}
          entityName={dealName}
          handleActivityModalClose={() => setShowDealActivity(false)}
          getActivityColumns={getDealsActivityColumns}
          getActivityData={getDealsActivityList(dealId)}
          getActivityGraphData={getDealsActivityGraphList(dealId)}
          graphData={'dealDate*dealViews'}
        />
      )}

      {deleteCompanyModal && (
        <DeleteModal
          visible={deleteCompanyModal}
          deleteEndPoint={`/deals/${dealId}/delete`}
          deleteSuccessMessage={'Deal deleted successfully'}
          modalTitle={'Delete Deal'}
          modalOkText={'Delete Deal'}
          modalBody={`${dealName}`}
          handleCancel={() => setDeleteCompanyModal(false)}
          handleOk={() => {
            setDeleteCompanyModal(false)
            refresh()
          }}
        />
      )}
    </div>
  )
}

Deals.propTypes = {}

export default Deals
