import { useState } from 'react'
import PropTypes from 'prop-types'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Empty, Timeline } from 'antd'
import camelCase from 'lodash/camelCase'
import difference from 'lodash/difference'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import FileComponent from '../../components/FileComponent'
import ProfileBodyWithTabs from '../../components/ProfileBodyWithTabs'
import ProfileHeaderWithButtons from '../../components/ProfileHeaderWithButtons'
import ProfileOverviewTab from '../../components/ProfileOverviewTab'
import { getClickableLink } from '../../utils/helpers'

const PROFILE_LABELS = {
  overview: ['MandateType', 'TargetCompletionDate'],
  details: ['Size', 'TargetInvestmentRegion'],
  plan: ['PlanAssets'],
}

const getDocumentsTabComponent = (mandate, documentationTab) => {
  switch (mandate.documentAddMean) {
    case 'Upload it':
      return isNil(mandate.documentUpload) ? (
        <Empty description="No documents attached." />
      ) : (
        <FileComponent
          files={[{ mediaUrl: mandate.documentUpload }]}
          title={mandate.title}
          dealId={mandate.mandateId}
        />
      )
    case 'Write it':
    default:
      return isNil(mandate.documentText) ? (
        <Empty description="No documents linked." />
      ) : (
        <div
          style={{
            marginBottom: '24px',
            marginLeft: '30px',
            marginRight: '30px',
          }}
        >
          <span className="cc-grey-text cc-tabletitle-text">
            {documentationTab[0]?.label}
          </span>
          <div className="cc-profile-info">{documentationTab[0]?.value}</div>
        </div>
      )
  }
}

const getUpdatesTabComponent = (mandate) => {
  const updates = [
    ...mandate.mandateUpdates,
    {
      content: 'Mandate added to Context 365',
      addedOn: mandate.addedOn,
    },
  ]
  return (
    <Timeline style={{ marginTop: '24px', paddingLeft: '20px' }}>
      {map(updates, (update) => (
        <Timeline.Item color="grey">
          <p>{moment.utc(update.addedOn).format('MMMM DD, YYYY')}</p>
          <p>{update.content}</p>
        </Timeline.Item>
      ))}
    </Timeline>
  )
}

const MandateProfileContainer = ({
  mandate,
  openDataroom = false,
  hideActions = false,
  onApplyToMandate,
  onAskQuestion,
  canApplyToMandate,
  companyId = null,
  companyCategory = '',
}) => {
  const [activeTab, setActiveTab] = useState(
    openDataroom ? 'documentation' : 'overview'
  )

  const { push } = useHistory()

  const getMandatesTabsData = () =>
    map(mandate.profile, (p) => {
      const key = camelCase(p.groupName)
      const sideSections = filter(
        p.sections,
        (s) => PROFILE_LABELS[key]?.indexOf(s.key) > -1
      )
      const sections = difference(p.sections, sideSections)
      return {
        title: p.groupName,
        sections,
        sideSections,
      }
    })

  const getTabs = () => {
    const tabsData = getMandatesTabsData()

    const documentationTab = filter(
      tabsData,
      (t) => t.title === 'Documentation'
    )[0]

    const tabs = [
      ...map(
        filter(
          tabsData,
          (t) => t.title !== 'Documentation' && t.title !== 'Updates'
        ),
        (d) => ({
          key: camelCase(d.title),
          title: d.title,
          component: (
            <ProfileOverviewTab
              sections={d.sections}
              sideSections={d.sideSections}
            />
          ),
        })
      ),
      !isEmpty(documentationTab) && !isNil(documentationTab)
        ? {
            key: 'documentation',
            title: 'Documentation',
            component: getDocumentsTabComponent(
              mandate,
              documentationTab.sections
            ),
          }
        : null,
      {
        key: 'updates',
        title: 'Updates',
        component: getUpdatesTabComponent(mandate),
      },
    ]
    return filter(tabs, (t) => !isNil(t))
  }

  return (
    <>
      <ProfileHeaderWithButtons
        name={mandate.title}
        hideActions={hideActions}
        status={mandate.status}
        canSendMeetingRequest={
          canApplyToMandate && isEmpty(mandate.mandateApplications)
        }
        sendRequestText="Apply to Mandate"
        sendMessageText="Ask a Question"
        onSendRequest={onApplyToMandate}
        onSendMessage={onAskQuestion}
        showEditButton={true}
        editDealText="Edit Mandate"
        onEditDeal={() => {
          if (companyId == null) {
            push(`/edit-mandate/${mandate.mandateId}`)
          } else {
            push(`/edit-mandate/${mandate.mandateId}`, {
              submitPath:
                '/companies' + '/' + companyCategory + '/' + companyId + '/11',
            })
          }
        }}
      />
      <ProfileBodyWithTabs
        onTabChange={(tabKey) => {
          setActiveTab(tabKey)
        }}
        activeTabKey={activeTab}
        tabs={getTabs()}
      />
    </>
  )
}

MandateProfileContainer.propTypes = {
  mandate: PropTypes.shape({
    mandateId: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
    title: PropTypes.string,
    profile: PropTypes.object,
    status: PropTypes.string,
    mandateApplications: PropTypes.array,
    documentUpload: PropTypes.string,
    documentText: PropTypes.string,
    documentAddMean: PropTypes.string,
  }),
  hideActions: PropTypes.bool,
  openDataroom: PropTypes.bool,
  onApplyToMandate: PropTypes.func,
  onAskQuestion: PropTypes.func,
  canApplyToMandate: PropTypes.bool,
}

export default MandateProfileContainer
