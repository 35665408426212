import { Component } from 'react'
import PropTypes from 'prop-types'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider } from 'antd'
import { isEmpty, isNil, map } from 'lodash'
import moment from 'moment'
import { getConfirmedContextList } from '../../actions/events'
import { getInitials } from '../../utils/helpers'
import AvatarGroup from '../AvatarGroup'
import CancelMeetingButton from '../CancelMeetingButton'
import CancelMeetingComponent from '../CancelMeetingComponent/CancelMeetingComponent'
import ContextTable from '../ContextTable'
import MessageModalPast from '../MessageModal/MessageModalPast'
import ProfilePictureGroup from '../ProfilePictureGroup'
import RatingDisplay from '../RatingDisplay/RatingDisplay'
import RescheduleEventMeeting from '../RescheduleEventMeeting/RescheduleEventMeeting'
import RescheduleOnlineMeeting from '../RescheduleOnlineMeeting'
import { CONTACT_NAME } from '../Responsive/ResponsiveCards/OnlineMeetingRecivedRequestResponsiveCard'

class ConfirmedMeetingsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showRescheduleSummitMeetingModal: false,
      showRescheduleOnlineMeetingModal: false,
      ShowCancelMeetingComponent: false,
      selectedRecord: {},
      refreshTable: false,
      confirmedList: null,
      messageModalVisible: false,
      messageLoading: false,
      messageBody: '',
      cancelForAll: false,
    }
  }
  static propTypes = {
    // fetchConfirmedMeetingsByCompany: PropTypes.func.isRequired,
    // fetchConfirmedMeetingsByCompanyEventId: PropTypes.func.isRequired,
    // confirmedList: PropTypes.object.isRequired,
    //meetingType: PropTypes.string.isRequired,
    // companyEventId: PropTypes.number,
    // width: PropTypes.number,
    // fromSummit: PropTypes.bool,
    refreshTable: PropTypes.bool,
    onCancelRequest: PropTypes.func,
    //companyEventId: PropTypes.number.isRequired,
    eventParticipantId: PropTypes.number,
    fundEventId: PropTypes.number,
    eventCompanyId: PropTypes.number,
  }

  // componentDidMount() {
  //   console.log("prps", this.props);
  //   http
  //     .post(
  //       `/meeting/summit/confirmed?companyEventId=${this.props.companyEventId}`,
  //       {
  //         pagination: { pageSize: 20 },
  //         sorter: {},
  //         searchTerm: {},
  //         filters: {},
  //       }
  //     )
  //     .then((res) => {
  //       //let count = this.state.cnt;
  //       this.setState({ confirmedList: res.data.result });
  //     });
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.refreshTable !== prevState.refreshTable) {
  //     http
  //       .post(
  //         `/meeting/summit/confirmed?companyEventId=${this.props.companyEventId}`,
  //         {
  //           pagination: { pageSize: 20 },
  //           sorter: {},
  //           searchTerm: {},
  //           filters: {},
  //         }
  //       )
  //       .then((res) => {
  //         //let count = this.state.cnt;
  //         this.setState({ confirmedList: res.data.result });
  //       });
  //   }
  // }

  // componentDidUpdate(prevProps) {
  //   const {
  //     fetchConfirmedMeetingsByCompany,
  //     fetchConfirmedMeetingsByCompanyEventId,
  //     meetingType,
  //     fromSummit,
  //     companyEventId,
  //   } = this.props;
  //   if (this.props.refreshTable !== prevProps.refreshTable) {
  //     fromSummit
  //       ? fetchConfirmedMeetingsByCompanyEventId(companyEventId)
  //       : fetchConfirmedMeetingsByCompany(meetingType.toLowerCase());
  //   }
  // }
  handleRescheduleClick = (record) => {
    this.props.meetingType.toLowerCase() === 'online'
      ? this.setState({
          showRescheduleOnlineMeetingModal: true,
          selectedRecord: record,
        })
      : this.setState({
          showRescheduleSummitMeetingModal: true,
          selectedRecord: record,
        })
  }

  handleCancelForAllClick = (record) => {
    this.setState(() => ({
      ShowCancelMeetingComponent: true,
      selectedRecord: record,
      cancelForAll: true,
    }))
  }

  canCancelForAll = (record) => {
    if (record.isInitiator) return true
    else return false
  }

  cancelButtonDisabled = (record) =>
    this.canLeaveMeeting(record) && !this.canCancelForAll(record)

  handleSendMessageClick = (record) => {
    console.log('send message')
    this.setState(() => ({
      messageModalVisible: true,
      selectedRecord: record,
    }))
  }

  handleCancelClick = (record) => {
    this.setState({
      cancelForAll: false,
      selectedRecord: record,
      ShowCancelMeetingComponent: true,
    })
  }

  canLeaveMeeting = (record) => {
    let leave = false

    if (!isEmpty(record.colleagues)) {
      const pendingParticipants = record.colleagues.filter(
        (x) => x.participantStatusId === 2 || x.participantStatusId === 1
      )
      if (!isEmpty(pendingParticipants) && pendingParticipants.length > 1) {
        leave = true
      }
    }

    return leave
  }

  closeModal = () => {
    this.props.meetingType === 'Summit'
      ? this.setState({
          showRescheduleSummitMeetingModal: false,
        })
      : this.setState({
          showRescheduleOnlineMeetingModal: false,
        })
  }

  renderActions = (record) => (
    <div className="cc-meeting-actions">
      {
        //record.isOptimized === true &&
        ((record.meetingType.toLowerCase() != 'summit' &&
          !this.props.isPast &&
          !isNil(record.meetingDateTime) &&
          moment
            .utc(record.meetingDateTime)
            .add(record.meetingDuration, 'minute') > moment.utc()) ||
          (record.meetingType.toLowerCase() === 'summit' &&
            !this.props.isPast)) && (
          <Button
            onClick={this.handleRescheduleClick.bind(this, record)}
            className="cc-btn cc-btn-notice"
          >
            <i className="cc-btn-bg" />
            {'Reschedule'}
          </Button>
        )
      }
      {record.meetingType.toLowerCase() === 'summit' && !this.props.isPast && (
        <>
          <span className={'cc-btn cc-btn-danger'}>
            {this.canLeaveMeeting(record) && (
              <Button
                onClick={this.handleCancelClick.bind(this, record)}
                className={`cc-btn ${
                  !this.canLeaveMeeting(record)
                    ? 'cc-btn-disabled'
                    : 'cc-btn-danger'
                }`}
              >
                <i className="cc-btn-bg" />
                {'Leave Meeting'}
              </Button>
            )}
          </span>
          <span className={'cc-btn cc-btn-danger'}>
            {!this.cancelButtonDisabled(record) && (
              <Button
                onClick={
                  this.canCancelForAll(record)
                    ? this.handleCancelForAllClick.bind(this, record)
                    : this.handleCancelClick.bind(this, record)
                }
                className={`cc-btn ${
                  this.cancelButtonDisabled(record)
                    ? 'cc-btn-disabled'
                    : 'cc-btn-danger'
                }`}
              >
                <i className="cc-btn-bg" />
                Cancel
              </Button>
            )}
          </span>
        </>
      )}
      {record.meetingType.toLowerCase() === 'online' &&
        !this.props.isPast &&
        !isNil(record.meetingDateTime) &&
        moment
          .utc(record.meetingDateTime)
          .add(record.meetingDuration, 'minute') > moment.utc() && (
          <CancelMeetingButton
            contacts={record.colleagues}
            meetingType={record.meetingType}
            company={record.company.companyName}
            companyId={this.props.companyId}
            meetingId={record.meetingId}
            meetingStatus="Confirmed"
            onCancel={() => {
              this.props.onCancelRequest()
              this.props.refresh(!this.state.refreshTable)
              this.setState((state) => ({
                refreshTable: !state.refreshTable,
              }))
            }}
            initiator={record.member}
          />
        )}
      {this.props.isPast && record.meetingType.toLowerCase() === 'summit' && (
        <Button
          onClick={this.handleSendMessageClick.bind(this, record)}
          type="primary"
        >
          <FontAwesomeIcon icon={faEnvelope} />
          <span style={{ marginLeft: '4px' }}>Send Message</span>
        </Button>
      )}
    </div>
  )
  closeReschedule = () => {
    this.setState({
      showRescheduleSummitMeetingModal: false,
    })
  }

  renderCompany = (record) => {
    if (!isNil(record.companies)) {
      return record.companies.map((company) => (
        <>
          {company.companyName}
          <span>{` (${company.category})`}</span>
          {record.carryContextPoints && (
            <span className="cc-context-points-label">
              <span>+1000 Context Points</span>
            </span>
          )}
          <br></br>
        </>
      ))
    } else {
      return (
        <div>
          {record.company.companyName}
          {record.carryContextPoints && (
            <span className="cc-context-points-label">
              <span>+1000 Context Points</span>
            </span>
          )}
        </div>
      )
    }
  }

  renderParticipantsAvatars = (record) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ProfilePictureGroup
        diameter={32}
        profiles={record?.allMembers?.map((c) => ({
          image: c.imageUrl,
          name: c.contactName,
          firstName: c.firstName,
          lastName: c.lastName,
        }))}
        maxImages="3"
        style={{ display: 'inline-block', marginTop: '4px' }}
      />
    </div>
  )

  getColumns = () => {
    console.log('type', this.props.type)

    return [
      {
        title: 'COMPANY',
        //dataIndex: 'company.companyName',
        className: 'cc-body-text',
        render: this.renderCompany,
      },
      {
        title: 'MEMBERS',
        render: this.renderParticipantsAvatars,
        align: 'center',
        contactDataIndex: 'members',
        //cardType: CONTACT_NAME,
      },

      {
        title: ' Meeting Date & Time',
        dataIndex: 'meetingDateTime',
        render: (meetingDateTime, record) =>
          moment(meetingDateTime).isValid()
            ? !isNil(record.meetingTimeZone)
              ? moment
                  .utc(meetingDateTime)
                  .tz(record.meetingTimeZone)
                  .format('YYYY-MM-DD hh:mm a')
              : moment.utc(meetingDateTime).local().format('YYYY-MM-DD hh:mm a')
            : 'TBD',
      },

      this.props.type !== 'sp'
        ? {
            title: () => <span>RATING GIVEN</span>,
            dataIndex: 'meetingRatingGiven',
            align: 'center',
            render: (meetingRatingGiven) =>
              isNil(meetingRatingGiven) ? (
                <>{'N/A'}</>
              ) : (
                <RatingDisplay
                  WouldMeetAgain={meetingRatingGiven.wouldMeetAgain}
                  Rating={meetingRatingGiven.rating}
                  Comment={meetingRatingGiven.comment}
                />
              ),
          }
        : {},
      this.props.type !== 'sp'
        ? {
            title: () => <span>RATING RECEIVED</span>,
            dataIndex: 'meetingRatingReceived',
            align: 'center',
            render: (meetingRatingReceived) =>
              isNil(meetingRatingReceived) ? (
                <>{'N/A'}</>
              ) : (
                <RatingDisplay
                  WouldMeetAgain={meetingRatingReceived.wouldMeetAgain}
                  Rating={meetingRatingReceived.rating}
                  Comment={meetingRatingReceived.comment}
                />
              ),
          }
        : {},
      {
        title: 'ACTIONS',
        render: this.renderActions,
        align: 'left',
        width: 270,
        notRenderInCard: true,
      },
    ]
  }

  render() {
    const { companyId, contacts, meetingType } = this.props
    const {
      selectedRecord,
      showRescheduleSummitMeetingModal,
      showRescheduleOnlineMeetingModal,
    } = this.state

    const RescheduleModal =
      meetingType.toLowerCase() === 'online' &&
      !this.props.isPast &&
      !isNil(selectedRecord.meetingDateTime) &&
      moment
        .utc(selectedRecord.meetingDateTime)
        .add(selectedRecord.meetingDuration, 'minute') > moment.utc() ? (
        <RescheduleOnlineMeeting
          visible={showRescheduleOnlineMeetingModal}
          closeModal={this.closeModal}
          onlineMeetingId={selectedRecord.meetingId}
          onRescheduleMeeting={() => {
            this.props.refresh(!this.state.refreshTable)
            this.setState((state) => ({
              refreshTable: !state.refreshTable,
            }))
          }}
          participants={
            selectedRecord.colleagues
              ? selectedRecord.colleagues.map((x) => x.contactId)
              : []
          }
          toParticipants={
            selectedRecord.participants
              ? selectedRecord.participants.map((x) => x.contactId)
              : []
          }
          colleagues={
            selectedRecord.colleagues ? selectedRecord.colleagues : []
          }
          companyId={companyId}
          meetingDateTime={selectedRecord?.meetingDateTime}
          meetingDuration={selectedRecord?.meetingDuration}
        />
      ) : (
        <RescheduleEventMeeting
          contacts={contacts}
          isVirtualSummit={selectedRecord?.virtualSummit}
          timezone={selectedRecord?.meetingTimeZone}
          visible={showRescheduleSummitMeetingModal}
          closeModal={this.closeReschedule}
          meetingId={selectedRecord.meetingId}
          companyId={companyId}
          company={
            isEmpty(this.state.selectedRecord) ||
            isNil(this.state.selectedRecord.company)
              ? ''
              : this.state.selectedRecord.company.companyName
          }
          eventId={
            !isEmpty(this.state.selectedRecord)
              ? this.state.selectedRecord.eventId
              : 0
          }
          onRescheduleMeeting={() => {
            this.props.refresh(!this.state.refreshTable)
            this.setState((state) => ({
              refreshTable: !state.refreshTable,
            }))
          }}
          participants={
            selectedRecord.colleagues
              ? selectedRecord.colleagues.map((x) => x.contactId)
              : []
          }
          toParticipants={
            selectedRecord.participants
              ? selectedRecord.participants.map((x) => x.contactId)
              : []
          }
          meetingDateTime={
            isEmpty(this.state.meetingDateTime)
              ? ''
              : this.state.selectedRecord.meetingDateTime
          }
          meetingDuration={
            isEmpty(this.state.meetingDuration)
              ? ''
              : this.state.selectedRecord.meetingDuration
          }
        />
      )
    const SendMessageModal = !isNil(selectedRecord.company) ? (
      <MessageModalPast
        visible={this.state.messageModalVisible}
        loading={this.state.messageLoading}
        eventId={this.props.eventId}
        companyName={
          isNil(selectedRecord.company)
            ? ''
            : selectedRecord.company.companyName
        }
        messageBody={this.state.messageBody}
        toCompanyId={selectedRecord.company.companyId}
        handle={() => {
          this.setState(() => ({
            messageModalVisible: false,
            messageLoading: false,
            messageBody: '',
          }))
        }}
        theMessage={this.state.messageBody}
        toCompanyEventId={selectedRecord.companyEventId}
        handleCancel={() => {
          this.setState(() => ({
            messageModalVisible: false,
          }))
        }}
        onChange={(e) => {
          this.setState({
            messageBody: e.target.value,
          })
        }}
        investors={
          isNil(selectedRecord.members)
            ? []
            : selectedRecord.members.map((c) => ({
                id: c.contactId,
                contactName: c.contactName,
                position: c.jobTitle,
                avatar: c.imageURL,
              }))
        }
        fromCompanyId={this.props.companyId}
        fromCompanyEventId={this.props.companyEventId}
        fromCompanyContacts={this.props.contacts}
      />
    ) : (
      <div></div>
    )
    return (
      <div>
        {RescheduleModal}
        {SendMessageModal}
        <CancelMeetingComponent
          visible={this.state.ShowCancelMeetingComponent}
          closeModal={() => {
            this.setState({
              ShowCancelMeetingComponent: false,
            })
          }}
          companyId={this.props.companyId}
          contacts={
            !isEmpty(this.state.selectedRecord)
              ? this.state.selectedRecord.colleagues
              : []
          }
          company={
            isNil(this.state.selectedRecord) ||
            isNil(this.state.selectedRecord.company)
              ? ''
              : this.state.selectedRecord.company.companyName
          }
          meetingId={
            !isEmpty(this.state.selectedRecord)
              ? this.state.selectedRecord.meetingId
              : 0
          }
          meetingType={this.props.meetingType}
          onCancel={() => {
            this.props.onCancelRequest()
            this.props.refresh(!this.state.refreshTable)
            this.setState((state) => ({
              refreshTable: !state.refreshTable,
            }))
          }}
          canDeclineMeeting={!this.canLeaveMeeting(selectedRecord)}
          cancelForAll={this.state.cancelForAll}
          meetingStatus="Confirmed"
        />
        <ContextTable
          tableTitle="Meetings"
          key="id"
          columns={this.getColumns()}
          eventParticipantId={this.props.eventParticipantId}
          fundEventId={this.props.fundEventId}
          eventCompanyId={this.props.eventCompanyId}
          fundId={this.props.fundId}
          companyContactId={this.props.companyContactId}
          dataMethod={(pagination, filters, sorter, searchTerm) =>
            getConfirmedContextList(
              pagination,
              filters,
              sorter,
              searchTerm,
              null,
              this.props.eventParticipantId,
              this.props.fundEventId,
              this.props.eventCompanyId,
              this.props.itemId ?? 0,
              this.props.type,
              this.props.eventCompanyBoothFundId,
              this.props.eventCompanyBoothId
            )
          }
          refresh={this.props.refreshMeetings}
          refreshData={this.props.refreshMeetings}
          companyId={this.props.companyId}
        />
      </div>
    )
  }
}

export default ConfirmedMeetingsTable
