import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Input, Modal, Radio, Row, message } from 'antd'
import { filter, isNil, map, startsWith, trim } from 'lodash'
import {
  cancelMeeting,
  cancelMeetingForAll,
  declineMeeting,
} from '../../actions/contextMeetings'
import { cancellationReasons } from '../../actions/meetings'
import ContactName from '../ContactName'
import LoadingGif from '../LoadingGif'

const RadioGroup = Radio.Group
const { TextArea } = Input

const CancelMeetingComponent = ({
  contacts,

  visible,
  closeModal,
  company,
  companyId,
  cancelText = 'Cancel',
  meetingId,
  meetingStatus = null,
  onCancel,
  canDeclineMeeting = true,
  cancelForAll = false,
}) => {
  const [cancellingInProgress, setCancellingInProgress] = useState(false)
  const [selectedReasonId, setSelectedReasonId] = useState(0)
  const [otherReason, setOtherReason] = useState(false)
  const [otherReasonText, setOtherReasonText] = useState('')
  const [senderId, setSenderId] = useState(null)
  const [cancelReasons, setCancelReasons] = useState([])

  //const tier = useSelector(getTier);

  const reasonRequired =
    meetingStatus &&
    meetingStatus.toLowerCase() == 'confirmed' &&
    startsWith(cancelText, 'Cancel')

  const handleModalCancel = useCallback(() => {
    setSelectedReasonId(0)
    setOtherReason(false)
    setOtherReasonText('')
    setSenderId(null)
    closeModal()
  }, [closeModal])

  const onRadioChange = (e) => {
    setSenderId(e.target.value)
  }

  const cancelMeetingReq = useCallback(() => {
    setCancellingInProgress(true)
    if (startsWith(cancelText, 'Cancel')) {
      if (cancelForAll) {
        cancelMeetingForAll(
          companyId,
          senderId,
          meetingId,
          selectedReasonId,
          otherReasonText
        )
          .then(() => {
            message.success('The meeting has been cancelled')
            handleModalCancel()
            onCancel()
          })
          .catch((err) => {
            const allErr = err.response.data.message
            message.error(
              allErr.substring(allErr.indexOf("'") + 1, allErr.indexOf("'."))
            )
            //tier === "Free" && onCancel();
          })
          .finally(() => {
            setCancellingInProgress(false)
          })
      } else {
        cancelMeeting(
          companyId,
          senderId,
          meetingId,
          selectedReasonId,
          otherReasonText
        )
          .then(() => {
            message.success(
              canDeclineMeeting
                ? 'The meeting has been cancelled'
                : 'Meeting left'
            )
            handleModalCancel()
            onCancel()
          })
          .catch((err) => {
            const allErr = err.response.data.message
            message.error(
              allErr.substring(allErr.indexOf("'") + 1, allErr.indexOf("'."))
            )
            //tier === "Free" && onCancel();
          })
          .finally(() => {
            setCancellingInProgress(false)
          })
      }
    } else {
      declineMeeting(companyId, senderId, meetingId)
        .then(() => {
          message.success(
            canDeclineMeeting
              ? 'The meeting has been declined'
              : 'You have left this meeting'
          )
          handleModalCancel()
          onCancel()
        })
        .catch((err) => {
          const allErr = err.response.data.message
          message.error(
            allErr.substring(allErr.indexOf("'") + 1, allErr.indexOf("'."))
          )
          //tier === "Free" && onCancel();
        })
        .finally(() => {
          setCancellingInProgress(false)
        })
    }
  }, [
    canDeclineMeeting,
    cancelForAll,
    cancelText,
    companyId,
    handleModalCancel,
    meetingId,
    onCancel,
    otherReasonText,
    selectedReasonId,
    senderId,
  ])

  const title = useCallback(() => {
    let text =
      cancelText.indexOf('Meeting') === -1
        ? canDeclineMeeting || cancelForAll
          ? `${cancelText} Meeting`
          : 'Leave Meeting'
        : cancelText
    if (!isNil(meetingStatus)) {
      if (meetingStatus === 'Confirmed' && startsWith(cancelText, 'Cancel')) {
        text =
          canDeclineMeeting || cancelForAll ? 'Cancel Meeting' : 'Leave Meeting'
      }
      return text
    }
  }, [canDeclineMeeting, cancelForAll, cancelText, meetingStatus])

  useEffect(() => {
    cancellationReasons().then((res) => {
      setCancelReasons(res.data.filter((r) => r.meetingTypeId === 1))
    })
  }, [])

  useEffect(() => {
    if (contacts && contacts.length === 1) {
      setSenderId(contacts[0].contactId)
    }
  }, [contacts])

  return (
    <Modal
      className="cc-modal-accept"
      title={title()}
      visible={visible}
      okText={title()}
      cancelText="Close"
      onOk={cancelMeetingReq}
      onCancel={handleModalCancel}
      okButtonProps={{
        loading: cancellingInProgress,
        disabled: isNil(senderId)
          ? true
          : reasonRequired &&
            cancelReasons.length > 0 &&
            (canDeclineMeeting || cancelForAll) &&
            (selectedReasonId === 0 ||
              (otherReason && trim(otherReasonText) === '')),
        style: { backgroundColor: '#D62B34', borderStyle: 'none' },
      }}
      cancelButtonProps={{ disabled: false }}
    >
      <LoadingGif spinning={cancellingInProgress}>
        {cancelReasons.length > 0 &&
        reasonRequired &&
        (canDeclineMeeting || cancelForAll) ? (
          <>
            <Row>
              <p>
                {cancelText.indexOf('Meeting') === -1
                  ? canDeclineMeeting || cancelForAll
                    ? 'Cancel' //cancelText.toLowerCase()
                    : 'Leave'
                  : 'Cancel'}{' '}
                meeting with {company} on behalf of:
              </p>
              <Col span="24">
                <Radio.Group
                  onChange={onRadioChange}
                  value={senderId}
                  style={{ marginLeft: '15px' }}
                >
                  {contacts &&
                    contacts.map((row, key) => (
                      <div key={key} style={{ paddingBottom: '6px' }}>
                        <Radio value={row.contactId} />
                        <ContactName
                          style={{ display: 'inline' }}
                          name={row.contactName}
                          imageUrl={row.imageURL}
                        />
                      </div>
                    ))}
                </Radio.Group>
              </Col>
            </Row>
            <div>
              <p className="cc-body-text cc-lightblack-text">{`Please select the reason you want to ${
                cancelText.indexOf('Meeting') === -1
                  ? cancelText.toLowerCase()
                  : 'cancel'
              } the meeting${
                cancelText.indexOf('Decline') !== -1 ? ' request' : ''
              }:`}</p>
              <RadioGroup
                style={{ width: '100%' }}
                value={selectedReasonId}
                onChange={(e) => {
                  setSelectedReasonId(e.target.value)
                  const reason = filter(
                    cancelReasons,
                    (r) => r.cancellationReasonId == e.target.value
                  )
                  setOtherReason(reason[0].requiresInput)
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '16px',
                  }}
                >
                  {map(cancelReasons, (c) => (
                    <span
                      style={{ marginBottom: '16px' }}
                      className="cancellingReasons"
                    >
                      <Radio
                        key={c.cancellationReasonId.toString()}
                        value={c.cancellationReasonId}
                      >
                        {c.description}
                      </Radio>
                    </span>
                  ))}
                  {otherReason && (
                    <TextArea
                      placeholder="Please type another reason"
                      rows={4}
                      value={otherReasonText}
                      onChange={({ target: { value } }) => {
                        setOtherReasonText(value)
                      }}
                    />
                  )}
                </div>
              </RadioGroup>
            </div>
          </>
        ) : (
          <Row>
            <p>
              {cancelText.indexOf('Meeting') === -1
                ? canDeclineMeeting || cancelForAll
                  ? 'Cancel' //cancelText.toLowerCase()
                  : 'Leave'
                : 'Cancel'}{' '}
              meeting with {company} on behalf of:
            </p>
            <Col span="24">
              <Radio.Group
                onChange={onRadioChange}
                value={senderId}
                style={{ marginLeft: '15px' }}
              >
                {contacts &&
                  contacts.map((row, key) => (
                    <div key={key} style={{ paddingBottom: '6px' }}>
                      <Radio value={row.contactId} />
                      <ContactName
                        style={{ display: 'inline' }}
                        name={row.contactName}
                        imageUrl={row.imageURL}
                      />
                    </div>
                  ))}
              </Radio.Group>
            </Col>
          </Row>
        )}
      </LoadingGif>
    </Modal>
    // </div>
  )
}

CancelMeetingComponent.propTypes = {
  contacts: PropTypes.array.isRequired,

  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  cancelText: PropTypes.oneOf(['Decline', 'Cancel']),
  company: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
  meetingStatus: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  canDeclineMeeting: PropTypes.bool,
  cancelForAll: PropTypes.bool,
  meetingType: PropTypes.string,
}

export default CancelMeetingComponent
