import http from '../utils/api'

export const bookBooth = (
  eventId,
  boothId,
  eventCompanyId,
  companyId,
  switchCompanies,
  boothName,
  eventCompanyBoothFundId
) =>
  http.post('/booths/book?api-version=2', {
    eventId,
    boothId,
    eventCompanyId,
    companyId,
    switchCompanies,
    boothName,
    eventCompanyBoothFundId,
  })

export const releaseBooth = (eventId, boothId) =>
  http.post('/booths/release?api-version=2', {
    eventId,
    boothId,
  })

export const getEventCompaniesAndBooths = (
  eventId,
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post(`booths/companies/${eventId}?api-version=2`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const updateBooth = (
  eventId,
  currentBoothId,
  newBoothId,
  eventCompanyBoothId,
  eventCompanyBoothFundId
) =>
  http.patch('/booths?api-version=2', {
    eventId,
    currentBoothId,
    newBoothId,
    eventCompanyBoothId,
    eventCompanyBoothFundId,
  })

export const getHeldBooths = (eventId) => http.get(`booths/${eventId}/held`)
