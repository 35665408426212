import cx from 'classnames'

const DisabledLabel = ({ className, label, value }) => (
  <div className={cx('form-label', className)}>
    <label htmlFor="name-label">{label}</label>
    <div id="name-label" className="font-semibold">
      {value}
    </div>
  </div>
)

export default DisabledLabel
