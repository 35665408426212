import { Component } from 'react'
import { faUpload } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Upload } from 'antd'
import './CCUpload.less'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

class CCUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imagePreview: null,
      //imagePreviewVisible: null,
      imagePreviewVisible: false,
      loading: false,
      imageUrl: null,
    }
  }

  componentDidMount() {
    if (this.state.imageUrl !== this.props.imageUrl) {
      this.setState({
        imagePreviewVisible:
          this.props.imageUrl === null ||
          typeof this.props.imageUrl === 'undefined'
            ? false
            : true,
        imageUrl: this.props.imageUrl,
      })
    }
  }

  componentDidUpdate() {
    if (this.state.imageUrl !== this.props.imageUrl) {
      this.setState({
        imagePreviewVisible:
          this.props.imageUrl === null ||
          typeof this.props.imageUrl === 'undefined'
            ? false
            : true,
        imageUrl: this.props.imageUrl,
      })
    }
  }

  handleChange = (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      this.setState({
        imageUrl,
        loading: false,
        imagePreviewVisible: true,
      })

      if (typeof this.props.onChange === 'function') {
        this.props.onChange(imageUrl)
      }
    })
  }

  handleCancel = () => {
    this.setState({
      imagePreview: null,
      imagePreviewVisible: false,
      imageUrl: null,
    })
    this.props.onChange(null)
  }

  fileRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  getClassname = () => {
    if (this.state.imagePreviewVisible) {
      return this.props.rect ? 'upload-icon-rect' : 'upload-icon-empty'
    } else {
      return this.props.rect ? 'upload-icon-empty-rect' : 'upload-icon-empty'
    }
  }

  render() {
    const placeholder =
      this.props.placeholder === null ? 'Upload Photo' : this.props.placeholder
    const imageContent = this.state.imagePreviewVisible ? (
      [
        <img
          key="img"
          alt="Failed"
          style={{
            width: this.props.rect ? '100%' : '137px',
            height: this.props.rect ? 'auto' : '137px',
            borderRadius: this.props.rect ? 'none' : '64px',
            objectFit: 'cover',
          }}
          src={this.state.imageUrl}
        />,
        <span
          key="span"
          style={{ position: 'absolute', zIndex: '9999' }}
          onClick={this.handleCancel}
        >
          x
        </span>,
      ]
    ) : (
      <div className="upload-icon-text cc-body-text">
        <FontAwesomeIcon icon={faUpload} />
        <span>{placeholder}</span>
      </div>
    )

    return (
      <div className="upload-container" style={this.props.style}>
        <Upload
          name="upload"
          className={this.getClassname()}
          showUploadList={false}
          listType="picture-card"
          onChange={this.handleChange}
          customRequest={this.fileRequest}
        >
          {imageContent}
        </Upload>
      </div>
    )
  }
}

export default CCUpload
