import PropTypes from 'prop-types'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, message } from 'antd'
import http from '../../utils/api'

const DeleteFundModal = ({
  visible,
  fundId,
  fundName,
  handleOk,
  handleCancel,
}) => {
  const deleteFund = () => {
    http
      .delete(`/fund/${fundId}/delete`)
      .then(() => {
        message.open({
          content: 'Fund deleted successfully',
          icon: (
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={{ marginRight: '10px', color: '#D62B34' }}
            />
          ),
        })
        handleOk()
      })
      .catch((error) => {
        console.log(error)
        handleOk()
      })
  }

  return (
    <Modal
      className="cc-modal-accept"
      title={`Delete Fund`}
      visible={visible}
      okText={`Delete Fund`}
      cancelText="Cancel"
      onOk={deleteFund}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: false,
        style: { backgroundColor: '#D62B34', borderStyle: 'none' },
      }}
      centered={true}
      cancelButtonProps={{ disabled: false }}
    >
      <div>
        Are you sure you want to delete the <strong>{fundName}</strong>?
      </div>
    </Modal>
  )
}

DeleteFundModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  fundName: PropTypes.string.isRequired,
  fundId: PropTypes.number.isRequired,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
}

export default DeleteFundModal
