import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import http from '../../utils/api'
import MainInfo from './MainInfo'
import './SummitOverview.less'

const SummitOverview = ({ eventId }) => {
  const [data, setData] = useState({})

  useEffect(() => {
    loadEventOverview()
  }, [])

  const loadEventOverview = () => {
    http
      .get(`/events/${eventId}/overview`)
      .then(function (response) {
        setData(response.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }

  return (
    <>
      <div className="SummitOverview-Wrapper">
        <MainInfo
          event={data}
          handleSubmit={() => {
            loadEventOverview()
          }}
        />
      </div>
    </>
  )
}

SummitOverview.propTypes = {
  eventId: PropTypes.number.isRequired,
}

export default SummitOverview
