import { Component } from 'react'
import { Input } from 'antd'
import './FloatingInput.less'

class FloatingInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
  }

  valueChanged = (e) => {
    this.setState({
      value: e.target.value,
    })

    if (typeof this.props.onChange === 'function')
      this.props.onChange(e.target.value, this.props.id)
  }

  render() {
    const value =
      typeof this.props.value === 'undefined' || this.props.value === null
        ? this.state.value
        : this.props.value

    const anyText = value !== null && value !== ''
    let classes = !anyText
      ? 'cc-floating-input-notext'
      : 'cc-floating-input-text'
    classes += ' cc-floating-input'
    let placeholderClasses = !anyText
      ? 'placeholder-movement-notext'
      : 'placeholder-movement-text'
    placeholderClasses += ' cc-floating-placeholder'

    return (
      <div className="floating-input-row">
        <Input
          size="large"
          value={value}
          disabled={this.props.disabled}
          onKeyPress={this.props.onKeyPress}
          placeholder={this.props.name}
          name={this.props.id ? this.props.id : this.props.nameField}
          onChange={this.valueChanged}
          className={classes}
          id={this.props.id}
        />
        <span className={placeholderClasses}>{this.props.name}</span>
      </div>
    )
  }
}

export default FloatingInput
