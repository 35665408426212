import PropTypes from 'prop-types'
import { Steps } from 'antd'
import { isNil, join, map } from 'lodash'

const { Step } = Steps

const StepList = ({
  className = null,
  steps,
  current = 0,
  onStepChange,
  stepsInError,
}) => {
  const classNames = ['StepList']
  if (!isNil(className)) {
    classNames.push(className)
  }
  const finalClassName = join(classNames, ' ')

  return (
    <div className={finalClassName}>
      <Steps current={current} direction="vertical" onChange={onStepChange}>
        {map(steps, (step) => (
          <Step
            key={step}
            title={step}
            description={step}
            status={stepsInError.includes(step) ? 'error' : ''}
          />
        ))}
      </Steps>
    </div>
  )
}

StepList.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  current: PropTypes.number,
  onStepChange: PropTypes.func,
  stepsInError: PropTypes.array,
}

export default StepList
