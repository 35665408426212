import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip, message } from 'antd'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import http from '../../utils/api'
import LoadingGif from '../LoadingGif'
import Timezone from '../SelectTimezoneComponent/SelectTimezoneComponent'
import './TimeSlotSelection.less'

const TimeSlotSelection = ({ meetingId, onTimeSlotChanged = () => {} }) => {
  const [proposedTimeSlots, setProposedTimeSlots] = useState(null)
  const [selectedProposedTimeSlotId, setSelectedProposedTimeSlotId] =
    useState(null)
  const [loadingProposedTimeSlots, setLoadingProposedTimeSlots] =
    useState(false)
  const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess())
  const userFullname = ''

  useEffect(() => {
    http
      .get(`/contextmeetings/${meetingId}/proposed-times`)
      .then((response) => {
        setProposedTimeSlots(response.data.proposedTimeSlots)
      })
      .catch(() => {
        message.error('There was an error while attempting to fetch timeslots')
      })
      .finally(() => {
        setLoadingProposedTimeSlots(false)
      })
  }, [meetingId])

  const onTimeZoneChanged = useCallback(
    (e) => {
      setSelectedTimeZone(e)
    },
    [setSelectedTimeZone]
  )

  const onTimeSlotClicked = useCallback(
    (timeSlotId) => {
      if (proposedTimeSlots) {
        var proposedTimeSlot = proposedTimeSlots.find(
          (el) => el.contextMeetingProposedTimeId === timeSlotId
        )
        if (
          proposedTimeSlot &&
          !moment
            .utc(proposedTimeSlot.proposedMeetingStartTime)
            .isBefore(moment())
        ) {
          setSelectedProposedTimeSlotId(timeSlotId)
          onTimeSlotChanged(timeSlotId)
        }
      }
    },
    [onTimeSlotChanged]
  )

  const getTooltipText = useCallback(
    (info) => {
      if (!isEmpty(info.busyContacts)) {
        const busyNumber = info.busyContacts.length - 1
        const isYou =
          `${info.busyContacts[0].firstName} ${info.busyContacts[0].lastName}` ===
          userFullname

        return (
          (isYou &&
            `You are already booked on this time slot ${
              busyNumber > 0
                ? `+ ${busyNumber} other${busyNumber > 1 ? 's' : ''}`
                : ''
            }`) ||
          `${info.busyContacts[0].firstName} ${
            info.busyContacts[0].lastName
          } is already booked on this time slot ${
            busyNumber > 0
              ? `+ ${busyNumber} other${busyNumber > 1 ? 's' : ''}`
              : ''
          }`
        )
      }
    },
    [userFullname]
  )

  return (
    <LoadingGif spinning={loadingProposedTimeSlots}>
      <div className="proposed-time-slots-container">
        <div className="proposed-time-slots-header">
          <div className="proposed-time-slots-title">SELECT TIMESLOT</div>
          <div className="proposed-time-slots-timezone">
            <Timezone
              value={selectedTimeZone}
              handleTimezoneChange={onTimeZoneChanged}
            />
          </div>
        </div>
        <div className="proposed-time-slots-board">
          {!isEmpty(proposedTimeSlots) &&
            proposedTimeSlots.map((x) => (
              <div
                key={x.contextMeetingProposedTimeId}
                className={`proposed-time-slot ${
                  x.contextMeetingProposedTimeId ===
                    selectedProposedTimeSlotId && 'proposed-time-slot-selected'
                } ${
                  moment.utc(x.proposedMeetingStartTime).isBefore(moment()) &&
                  'proposed-time-slot-expired'
                }`}
                onClick={() => {
                  onTimeSlotClicked(x.contextMeetingProposedTimeId)
                }}
              >
                {moment.utc(x.proposedMeetingStartTime).isBefore(moment()) && (
                  <span className="proposed-time-slot-expired-icon">
                    Expired
                  </span>
                )}
                <span className="proposed-time-slot-busy-icon">
                  {!isEmpty(x.busyContacts) &&
                    moment
                      .utc(x.proposedMeetingStartTime)
                      .isAfter(moment()) && (
                      <Tooltip title={getTooltipText(x)} placement="topRight">
                        <FontAwesomeIcon
                          color={'#F19F00'}
                          icon={faExclamationCircle}
                        />
                      </Tooltip>
                    )}
                </span>
                <div className="proposed-time-slot-month">
                  {moment
                    .tz(
                      moment.utc(x.proposedMeetingStartTime),
                      selectedTimeZone
                    )
                    .format('MMMM')}
                </div>
                <div className="proposed-time-slot-date cc-heading2">
                  {moment
                    .tz(
                      moment.utc(x.proposedMeetingStartTime),
                      selectedTimeZone
                    )
                    .format('D')}
                </div>
                <div className="proposed-time-slot-time cc-heading6">
                  {`${moment
                    .tz(
                      moment.utc(x.proposedMeetingStartTime),
                      selectedTimeZone
                    )
                    .format('h:mm a')} - ${moment
                    .tz(moment.utc(x.proposedMeetingEndTime), selectedTimeZone)
                    .format('h:mm a')}`}
                </div>
              </div>
            ))}
        </div>
      </div>
    </LoadingGif>
  )
}

TimeSlotSelection.propTypes = {
  meetingId: PropTypes.number.isRequired,
  onTimeSlotChanged: PropTypes.func,
}

export default TimeSlotSelection
