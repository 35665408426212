import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { DatePicker, Input, Select, Toggle } from '@context365/forms'
import { Divider } from 'antd'
import produce from 'immer'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { getAllocatorWizardAlternativeCategories } from '../../../../actions/allocator'
import { getVettedAndUnvettedManagers } from '../../../../actions/company'
import { getAllStrategyOptions } from '../../../../actions/funds'
import {
  formatNumber,
  fromMillions,
  toMillions,
} from '../../../../utils/helpers'
import { ADD_NEW_ID } from '../../constants'
import DisabledLabel from '../DisabledLabel'
import FundSection from './FundSection'

const getNewManager = () => {
  return {
    companyAllocatorManagerId: null,
    vettedCompanyManagerId: null,
    unvettedCompanyManagerId: null,
    name: null,
    yearHired: null,
    renewalDate: null,
    isOnWatch: false,
    totalInvestments: null,
    funds: [],
  }
}

const getNewFund = () => {
  return {
    companyAllocatorManagerFundId: null,
    vettedFundId: null,
    unvettedFundId: null,
    name: null,
    strategies: [],
    totalInvestments: null,
    investmentDate: null,
    vintageDate: null,
  }
}

const generateManagerInputId = (manager) =>
  manager?.vettedCompanyManagerId && manager?.unvettedCompanyManagerId
    ? 'new'
    : manager?.vettedCompanyManagerId
    ? `vettedCompanyManager-${manager.vettedCompanyManagerId}`
    : manager?.unvettedCompanyManagerId
    ? `unvettedCompanyManager-${manager.unvettedCompanyManagerId}`
    : null

const ManagerNameInput = ({ manager, onChange }) => {
  if (manager.companyAllocatorManagerId) {
    return (
      <DisabledLabel
        label="Investment Manager Name"
        value={manager.name}
        className="col-span-2"
      />
    )
  }

  return (
    <>
      <Select.AutoComplete
        className="col-span-2"
        required
        label="Investment Manager Name"
        helpMessage={`Select "+Add new manager" if not found`}
        value={generateManagerInputId(manager) ?? manager.name}
        onChange={(val) => {
          let newVettedManagerId = val?.startsWith('vettedCompanyManager')
            ? val.split('vettedCompanyManager-')[1]
            : null
          let newUnvettedManagerId = val?.startsWith('unvettedCompanyManager')
            ? val.split('unvettedCompanyManager-')[1]
            : null

          if (!newVettedManagerId && !newUnvettedManagerId) {
            newVettedManagerId = ADD_NEW_ID
            newUnvettedManagerId = ADD_NEW_ID
          }

          onChange([
            {
              field: 'vettedCompanyManagerId',
              value: newVettedManagerId,
            },
            {
              field: 'unvettedCompanyManagerId',
              value: newUnvettedManagerId,
            },
          ])
        }}
      >
        {({ inputValue }) =>
          inputValue.length > 2
            ? getVettedAndUnvettedManagers(inputValue).then((res) => {
                const companies = res.data
                companies.push({
                  vettedCompanyManagerId: ADD_NEW_ID,
                  unvettedCompanyManagerId: ADD_NEW_ID,
                  name: '+ Add new manager',
                })
                return map(companies, (x) => (
                  <Select.Option
                    key={generateManagerInputId(x)}
                    value={generateManagerInputId(x)}
                  >
                    {x.name}
                  </Select.Option>
                ))
              })
            : 'Begin typing to search'
        }
      </Select.AutoComplete>
      {manager.vettedCompanyManagerId === ADD_NEW_ID && (
        <Input
          required
          className="col-span-3"
          label="New manager name"
          value={manager.name}
          onChange={(e) =>
            onChange([
              {
                field: 'name',
                value: e.target.value,
              },
            ])
          }
        />
      )}
    </>
  )
}

const ManagersFunds = ({
  managers = [],
  updateManagers,
  visible,
  refreshStrategies,
  companyId,
}) => {
  const [alternativeCategories, setAlternativeCategories] = useState([])
  const [strategyOptions, setStrategyOptions] = useState([])

  useEffect(() => {
    getAllocatorWizardAlternativeCategories(companyId).then((data) =>
      setAlternativeCategories(data)
    )
  }, [companyId])

  useEffect(() => {
    if (visible) {
      getAllStrategyOptions().then((res) => setStrategyOptions(res.data))
    }
  }, [visible, refreshStrategies])

  const addManager = () => {
    const newManagers = managers.slice()
    newManagers.push(getNewManager())
    updateManagers(newManagers)
  }

  const addFund = (managerIndex) => {
    const newManagers = produce(managers, (newState) => {
      newState[managerIndex].funds.push(getNewFund())
    })
    updateManagers(newManagers)
  }

  const removeManager = (index) => {
    const newManagers = managers.slice()
    newManagers.splice(index, 1)
    updateManagers(newManagers)
  }

  const removeFund = (managerIndex, fundIndex) => {
    const newManagers = produce(managers, (newState) => {
      newState[managerIndex].funds.splice(fundIndex, 1)
    })
    updateManagers(newManagers)
  }

  const handleManagerChange = (index, changes) => {
    const newManagers = produce(managers, (newState) => {
      forEach(changes, (c) => {
        newState[index][c.field] = c.value
        newState[index].name =
          c.field === 'vettedCompanyManagerId' ||
          c.field === 'unvettedCompanyManagerId'
            ? null
            : newState[index].name
        newState[index].funds =
          c.field === 'vettedCompanyManagerId' ||
          c.field === 'unvettedCompanyManagerId'
            ? []
            : newState[index].funds
      })
    })
    updateManagers(newManagers)
  }

  const handleFundChanges = (managerIndex, fundIndex, changes) => {
    const newManagers = produce(managers, (newState) => {
      forEach(changes, (c) => {
        newState[managerIndex].funds[fundIndex][c.field] = c.value
        newState[managerIndex].funds[fundIndex].fundStrategyId =
          c.field === 'vettedFundId'
            ? null
            : newState[managerIndex].funds[fundIndex].fundStrategyId
      })
    })
    updateManagers(newManagers)
  }

  return (
    <div className="space-y-4 pb-24">
      {map(managers, (manager, mIndex) => (
        <>
          <div className="flex space-between">
            <div className="type-subtitle-sm text-primary-90">
              Manager {mIndex + 1}
            </div>
            <Button
              variant="link"
              status="error"
              onClick={() => removeManager(mIndex)}
            >
              Remove
            </Button>
          </div>
          <div className="grid grid-cols-5 gap-6">
            <ManagerNameInput
              manager={manager}
              onChange={(changes) => handleManagerChange(mIndex, changes)}
            />
            <DatePicker
              className="col-start-1"
              label="Year hired"
              mode="year"
              placeholder="Select year"
              maxDate={new Date()}
              clearable
              value={
                isNil(manager.yearHired) ? null : new Date(manager.yearHired, 0)
              }
              onChange={(date) =>
                handleManagerChange(mIndex, [
                  {
                    field: 'yearHired',
                    value: date.getFullYear(),
                  },
                ])
              }
            />
            <DatePicker
              label="Renewal date"
              mode="day"
              placeholder="Select date"
              clearable
              value={
                isNil(manager.renewalDate)
                  ? null
                  : new Date(manager.renewalDate)
              }
              onChange={(date) =>
                handleManagerChange(mIndex, [
                  {
                    field: 'renewalDate',
                    value: date,
                  },
                ])
              }
            />
            <div>
              <div className="form-label">Is on watch?</div>
              <Toggle
                label={manager.isOnWatch ? 'Yes' : 'No'}
                checked={manager.isOnWatch}
                onChange={(e) =>
                  handleManagerChange(mIndex, [
                    {
                      field: 'isOnWatch',
                      value: e.target.checked,
                    },
                  ])
                }
              />
            </div>

            <Input
              label="Total investments in manager (millions)"
              className="col-start-1 col-span-2"
              type="number"
              prefix="US$"
              value={toMillions(manager.totalInvestments)}
              onChange={(e) =>
                handleManagerChange(mIndex, [
                  {
                    field: 'totalInvestments',
                    value: fromMillions(e.target.value),
                  },
                ])
              }
              helpMessage={formatNumber(manager.totalInvestments)}
            />
          </div>
          <div className="px-8">
            <FundSection
              funds={manager.funds}
              vettedCompanyManagerId={manager.vettedCompanyManagerId}
              unvettedCompanyManagerId={manager.unvettedCompanyManagerId}
              companyAllocatorManagerId={manager.companyAllocatorManagerId}
              removeFund={(i) => removeFund(mIndex, i)}
              addFund={() => addFund(mIndex)}
              handleFundChanges={(index, changes) =>
                handleFundChanges(mIndex, index, changes)
              }
              fundStrategies={strategyOptions}
              alternativeCategories={alternativeCategories}
            />
          </div>
          <Divider />
        </>
      ))}
      <Button variant="link" className="justify-start" onClick={addManager}>
        + Add Manager
      </Button>
    </div>
  )
}

ManagersFunds.propTypes = {
  managers: PropTypes.array,
  updateManagers: PropTypes.func.isRequired,
  companyId: PropTypes.number,
}

export default ManagersFunds
