import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faUpload } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Upload } from 'antd'
import classNames from 'classnames'
import { isArray, isEmpty, isNil, join, last, map, split } from 'lodash'
import { downloadDocument } from '../../../actions/files'
import { BASE_URL } from '../../../config'
import http from '../../../utils/api'
import LoadingGif from '../../LoadingGif'

const { Dragger } = Upload

const createHtml = (texts) => ({ __html: join(texts, '<br>') })

const UploadMultipleFileField = ({
  action = null,
  method = 'post',
  name,
  label = null,
  formFieldName = 'file',
  value = null,
  accept = '.pdf',
  help = null,
  hint = null,
  info = null,
  icon = null,
  listType = 'text',
  onChange,
  listLength,
  disabled,
}) => {
  const [fileList, setFileList] = useState([])
  const [urlList, setUrlList] = useState([])
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const [uploading, setUploading] = useState(false)

  if (isNil(action)) {
    action = `${BASE_URL}uploads`
  }

  useEffect(() => {
    if (!isNil(value) && value != '') {
      const cleanedUrls = []
      const listFiles = []
      let ind = 1
      try {
        JSON.parse(decodeURI(value)).forEach((item) => {
          const urlString = decodeURI(item).replace('[', '').replace(']', '')
          try {
            let url = ''
            if (urlString.startsWith('"')) {
              url = new URL(urlString.slice(1, -1))
            } else {
              url = new URL(urlString)
            }
            cleanedUrls.push(url.href)
            listFiles.push({
              uid: `uff-${ind}`,
              name: last(split(decodeURI(url.pathname), '/')),
              status: 'done',
              url: decodeURI(url.href),
            })
          } catch (e) {
            console.log(e)
          }
          ind++
        })
      } catch (e) {
        try {
          const url = new URL(decodeURI(value))
          cleanedUrls.push(url.href)
          listFiles.push({
            uid: `uff-${ind}`,
            name: last(split(decodeURI(url.pathname), '/')),
            status: 'done',
            url: decodeURI(url.href),
          })
        } catch (ex) {
          console.log(ex)
        }
      }
      setUrlList(cleanedUrls)
      setFileList(listFiles)
      onChange(JSON.stringify(cleanedUrls))
    }
  }, [onChange, value])

  const handleUpload = (fileInfo) => {
    const currentFile = fileInfo.file
    setUploading(true)
    const extension = /[.]/.exec(currentFile.name)
      ? /[^.]+$/.exec(currentFile.name)[0]
      : undefined

    //Below condition changed to handle multiple file extensions for eg. images
    const acceptedExtensions = accept.replaceAll(' ', '').split(',')
    const isIncludedInAccept = !isNil(
      acceptedExtensions.filter((x) => x === `.${extension}`)
    )
    if (!isIncludedInAccept) {
      currentFile.status = 'error'
      currentFile.response = 'Incorrect file type'
      setFileList([...fileList, currentFile])
      setUploading(false)
      return
    }
    const formData = new FormData()
    formData.append('name', 'MarketingMaterial')
    formData.append('file', currentFile)

    http
      .post('uploads', formData, { timeout: 600000 })
      .then((response) => {
        currentFile.status = 'done'
        currentFile.response = '{"status": "success"}'
        currentFile.url = encodeURI(response.data.url)
        urlList.length === listLength && setShowDisclaimer(true)
        urlList.length !== listLength && setShowDisclaimer(false)
        const cleanedUrls = urlList.slice(-1 * listLength + 1)
        cleanedUrls.push(encodeURI(response.data.url))
        setFileList([...fileList, currentFile])
        setUrlList([...urlList, encodeURI(response.data.url)])
        onChange(JSON.stringify(cleanedUrls))
      })
      .catch((err) => {})
      .finally(() => {
        setUploading(false)
      })
  }

  const handleRemove = (info) => {
    const index = fileList.indexOf(info)

    const newFileList = fileList.slice()
    newFileList.splice(index, 1)

    const newUrlList = urlList.slice()
    newUrlList.splice(index, 1)

    setUrlList(newUrlList)
    setFileList(newFileList)

    onChange(JSON.stringify(newUrlList))
  }

  if (isNil(icon)) {
    icon = <FontAwesomeIcon icon={faUpload} size="3x" color="#C2C6CC" />
  }
  const handlePreview = (file) => {
    downloadDocument(file.url).then((response) => {
      window.open(response.data, '_blank')
    })
  }

  let helpComponent = null
  if (!(isNil(help) || isEmpty(help))) {
    if (isArray(help)) {
      helpComponent = (
        <p
          className="ant-upload-text"
          dangerouslySetInnerHTML={createHtml(help)}
        ></p>
      )
    } else {
      helpComponent = <p className="ant-upload-text">{help}</p>
    }
  }

  let hintComponent = null
  if (!(isNil(hint) || isEmpty(hint))) {
    if (isArray(hint)) {
      hintComponent = (
        <p
          className="ant-upload-hint"
          dangerouslySetInnerHTML={createHtml(hint)}
        ></p>
      )
    } else {
      hintComponent = <p className="ant-upload-hint">{hint}</p>
    }
  }

  let infoComponent = null
  if (!(isNil(info) || isEmpty(info))) {
    infoComponent = (
      <ul className="Field-info">
        {map(info, (row) => (
          <li key={row} dangerouslySetInnerHTML={{ __html: row }}></li>
        ))}
      </ul>
    )
  }

  const dragger = (
    <>
      <Dragger
        customRequest={handleUpload}
        //method={method}
        accept={accept}
        name={formFieldName}
        data={{ name }}
        fileList={fileList}
        onRemove={handleRemove}
        onPreview={handlePreview}
        listType={listType}
        disabled={disabled}
      >
        <p className="ant-upload-drag-icon">{icon}</p>
        {helpComponent}
        {hintComponent}
        <LoadingGif
          spinning={uploading}
          style={{ width: '100%', height: '10px' }}
        />
      </Dragger>
      {infoComponent}
    </>
  )

  if (isNil(label)) {
    return dragger
  }

  return (
    <div className={classNames('Field', 'UploadFileField')}>
      <label className="Field-label">{label}</label>
      {listLength && showDisclaimer && (
        <Alert
          style={{ textAlign: 'center' }}
          message={<strong>Cannot upload more than {listLength} items.</strong>}
          type="error"
          showIcon
        />
      )}
      {dragger}
    </div>
  )
}

UploadMultipleFileField.propTypes = {
  action: PropTypes.string,
  method: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  formFieldName: PropTypes.string,
  value: PropTypes.string,
  accept: PropTypes.string,
  className: PropTypes.string,
  help: PropTypes.oneOfType(
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ),
  hint: PropTypes.oneOfType(
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ),
  info: PropTypes.oneOfType(
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ),
  icon: PropTypes.node,
  listType: PropTypes.string,
  onChange: PropTypes.func,
  listLength: PropTypes.number,
}

export default UploadMultipleFileField
