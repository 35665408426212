import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { isNil } from 'lodash'
import {
  getEventAllManagersList,
  getEventSortFilters,
} from '../../actions/events'
import ContextTable from '../../components/ContextTable'
import { CATEGORY_MANAGER } from '../../constants/types/categories'
import http from '../../utils/api'
import { getSummitAllManagersDefaultColumns } from '../../utils/columns'

const SummitAttendeeManagers = ({ eventId, isPast }) => {
  const [data, setData] = useState(null)
  const [refreshData, setRefreshData] = useState(false)
  const [columns, setColumns] = useState(null)

  const refresh = () => {
    setRefreshData(!refreshData)
  }

  useEffect(() => {
    var cols = getSummitAllManagersDefaultColumns(isPast, refresh)
    setColumns(cols)
  }, [refreshData])

  return (
    <>
      {columns && (
        <ContextTable
          tableTitle="All Managers"
          columns={columns}
          eventId={eventId}
          dataMethod={getEventAllManagersList}
          refresh={refreshData}
          refreshData={refreshData}
        />
      )}
    </>
  )
}

SummitAttendeeManagers.propTypes = {
  eventId: PropTypes.number.isRequired,
}
export default SummitAttendeeManagers
