import { API_DONE, API_LOADING } from '../constants/types/api'

const initialState = {
  isLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_LOADING:
      return { isLoading: true }
    case API_DONE:
      return { isLoading: false }
    default:
      return state
  }
}
