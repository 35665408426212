import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import { declineAllSummitReceivedMeetings } from '../../actions/contextMeetings'

const DeclineAllSummitMeetingsModal = ({
  visible,
  handleCancel,
  handleOk,
  eventId,
  companyId,
  contactId,
}) => {
  const [loading, setLoading] = useState(false)

  const declineAllMeetings = useCallback(() => {
    setLoading(true)
    declineAllSummitReceivedMeetings(eventId, companyId, contactId)
      .then(() => {
        message.success('All meetings have been declined.')
        handleOk()
      })
      .catch((err) => {
        message.error('There was an error declining one or more requests.')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [eventId, companyId, contactId, handleOk])

  return (
    <Modal
      className="cc-modal-accept"
      title="Decline All Summit Meetings"
      visible={visible}
      okText="Decline All"
      cancelText="Close"
      onOk={declineAllMeetings}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: loading,
        style: { backgroundColor: '#D62B34', borderStyle: 'none' },
      }}
      centered={true}
    >
      <div> Are you sure you want to decline all received requests? </div>
    </Modal>
  )
}

DeclineAllSummitMeetingsModal.propTypes = {
  eventId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
}

export default DeclineAllSummitMeetingsModal
